import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import FormattedTypography from '../common/FormattedTypography';
import { ASSET_ICON_LOOKUP, ASSET_ICON_ALT_LOOKUP } from '../../constants';
import { convertInDigits } from '../../helpers/formattingHelpers';
import CustomModal from '../common/CustomModal';
import { DARK_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  assetsListWrapper: {
    margin: '0 auto',
    width: '90%',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  assetsList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  assetsListItem: {
    flexBasis: '45%',
    padding: '1.5rem 1rem',
  },
  assetIcon: {
    fontWeight: 'normal',
    color: DARK_MEDIUM_GREY,
  },
  greyText: {
    color: DARK_MEDIUM_GREY,
    margin: '0 0.5rem',
  },
  assetContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ChannelAssets = ({ channelDetails, open, onClose }) => {
  const classes = useStyles();

  // if assets present in channel
  const assets = channelDetails.training_materials.length ? channelDetails.training_materials : [];

  return (
    <CustomModal open={open} onClose={onClose} breakpoint="md">
      <DialogTitle>
        <FormattedTypography
          variant="subtitle2"
          prefix={convertInDigits(assets.length, 2)}
          body="assets in"
          suffix={channelDetails.name}
          className={classes.greyText}
          dense
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row" wrap className={classes.assetsListWrapper}>
          <List className={classes.assetsList}>
            {assets.map((asset, index) => (
              <ListItem key={asset} divider className={classes.assetsListItem} alignItems="center">
                <img
                  src={
                    ASSET_ICON_LOOKUP[asset.type.replace(/\W/g, '').toUpperCase()]
                    || ASSET_ICON_LOOKUP.DEFAULT_CONTENT
                  }
                  className={classes.assetIcon}
                  alt={ASSET_ICON_ALT_LOOKUP[asset.type.toUpperCase()] || 'Unknown'}
                  width={26}
                  height={26}
                />
                <Box className={classes.assetContent}>
                  <Typography
                    display="inline"
                    variant="body1"
                    className={classes.greyText}
                    align="center"
                  >
                    {convertInDigits(index + 1, 2)}
                  </Typography>
                  <Typography variant="body1">{asset.name}</Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </CustomModal>
  );
};

export default ChannelAssets;

ChannelAssets.propTypes = {
  channelDetails: PropTypes.shape({
    name: PropTypes.number.isRequired,
    channel_id: PropTypes.string.isRequired,
    training_materials: PropTypes.array,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
