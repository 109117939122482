import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import { STATES } from '../../constants';

const { SUCCESS, ERROR, INFO } = STATES;

const NotificationContext = createContext({
  notification: SUCCESS,
  notificationText: 'successfully achieved',
  notifySuccess: () => { },
  notifyError: () => { },
  notifyInfo: () => { },
  clear: () => { },
});

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const [notificationText, setNotificationText] = useState(null);

  const notifySuccess = (text) => {
    setNotificationText(text);
    setNotification(SUCCESS);
  };
  const notifyError = (text) => {
    setNotificationText(text);
    setNotification(ERROR);
  };
  const notifyInfo = (text) => {
    setNotificationText(text);
    setNotification(INFO);
  };
  const clear = () => {
    setNotificationText(null);
    setNotification(null);
  };
  return (
    <NotificationContext.Provider
      value={{
        notification,
        notificationText,
        notifySuccess,
        notifyError,
        notifyInfo,
        clear,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NotificationProvider };
export default NotificationContext;
