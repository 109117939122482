import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogTitle } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import FormattedTypography from '../common/FormattedTypography';
import { COMPLETED, COMPLETED_WITH_ERRORS, FAILED } from '../../constants';
import { ACCORDION_GREY, MEDIUM_GREY } from '../../stylesheets/colors';
import ReportContent from '../ActivityLogs/ReportContent';
import { getStatusLogo } from '../../helpers/utils';
import StatusModalWrapper from '../ActivityLogs/StatusModalWrapper';
import { LoaderInner } from '../Loader';
import GenericProgressModal from '../common/GenericProgressModal';

const useStyles = makeStyles(() => ({
  circularProgressPadding: {
    padding: '1rem',
  },
  logoIcon: {
    '& > :nth-child(1)': {
      margin: '1rem 0 0 0',
    },
  },
  headingWrapper: {
    paddingTop: '0 !important',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
    padding: '0.25rem 0',
  },
  headingBoldContent: {
    color: MEDIUM_GREY,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  contentBox: {
    marginBottom: '0',
  },
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
  contentWrapper: {
    marginBottom: '0',
  },
}));

const MarkAllIUsersStatusModal = ({
  open, onClose, modalState, transactionId, getReport, progressData,
  progressErrorMsg, onDownloadLog,
}) => {
  const classes = useStyles();

  const getModalTitle = (reportStatus, course_name) => {
    let header;
    let subTitle;
    if (reportStatus === COMPLETED) {
      header = 'Workshop completion was marked for all users successfully.';
      subTitle = `Workshop completion was marked for all users in ${course_name}.`;
    } else if (reportStatus === COMPLETED_WITH_ERRORS) {
      header = 'Workshop completion for all users was updated with errors.';
    } else {
      header = 'Workshop completion for all users failed to update.';
      subTitle = `Workshop completion was not marked for any users in ${course_name}.`;
    }
    return {
      header,
      subTitle,
    };
  };

  const applyReportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const {
      status, created_at, success_count, fail_count, total_rows, course_name,
    } = result;
    const { header, subTitle } = getModalTitle(status, course_name);

    return {
      component: ReportContent,
      wrapperProps: {
        logo: (
          <img
            src={getStatusLogo(status)}
            alt="status-icon"
            width={50}
            height={50}
            className={classes.circularProgressPadding}
          />
        ),
        heading: (
          <DialogTitle className={classes.headingWrapper}>
            <FormattedTypography
              variant="subtitle1"
              body={header}
              subHeading={(
                <>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={`${classes.headingBoldContent} ${classes.nestedText}`}
                  >
                    {subTitle}
                  </Typography>
                  {status === FAILED ? (
                    <Typography
                      variant="subtitle2"
                      className={`${classes.headingBoldContent} ${classes.infoHelperText}`}
                      align="center"
                    >
                      Please try again later.
                    </Typography>
                  ) : null}
                </>
                  )}
            />
          </DialogTitle>
        ),
        primaryBtnText: status !== COMPLETED ? 'Download Log' : '',
        breakpoint: 'sm',
        primaryBtnProps: {
          onClick: () => {
            onDownloadLog({
              transaction_id: transactionId,
              created_at,
            });
          },
        },
        secondaryBtnText: 'Close',
        secondaryBtnProps: {
          onClick: () => onClose(),
        },
        logoWrapper: classes.logoIcon,
        contentWrapper: classes.contentBox,
        headingDivider: true,
        contentDivider: true,
        disableGutters: true,
      },
      props: {
        success_count,
        fail_count,
        total_rows,
        status,
        updatedValue: 'users',
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getModalTitle, getReport, onClose, onDownloadLog, transactionId]);

  const progressDataModal = useCallback(() => ({
    component: GenericProgressModal,
    wrapperProps: {
      heading: (
        <DialogTitle>
          <FormattedTypography
            body="Workshop Management"
            subHeading={`Marking all users complete for ${progressData?.course_name}`}
            subHeadingSection={classes.subHeadingSection}
          />
        </DialogTitle>
      ),
      logo: (
        <div className={classes.progressRow}>
          <LoaderInner
            progress={progressData?.percentage ? progressData?.percentage : 0}
            scaleFactor={1.5}
          />
        </div>
      ),
      secondaryBtnText: 'Close',
      secondaryBtnProps: {
        onClick: () => onClose(),
      },
      breakpoint: 'md',
    },
    props: {
      initiatedBy: progressData?.created_by,
      initiatedAt: progressData?.created_at,
    },
  }), [classes.progressRow, classes.subHeadingSection, onClose, progressData]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={progressDataModal}
      reportModal={applyReportModal}
      feature="Mark all users complete"
    />
  );
};

MarkAllIUsersStatusModal.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

MarkAllIUsersStatusModal.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
};

export default MarkAllIUsersStatusModal;
