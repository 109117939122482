import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormattedTypography from '../common/FormattedTypography';
import {
  COMPLETED, COMPLETED_WITH_ERRORS, FAILED,
} from '../../constants';
import AlertIcon from '../../assets/icons/alert-status-icon.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import { MEDIUM_GREY } from '../../stylesheets/colors';
import { LoaderInner } from '../Loader';
import StatusModalWrapper from '../ActivityLogs/StatusModalWrapper';
import GenericProgressModal from '../common/GenericProgressModal';
import GenericReportOverview from '../common/GenericReportOverview';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    color: MEDIUM_GREY,
    textAlign: 'center',
  },
  subtitleStyles: {
    marginTop: '1.25rem',
  },
  headingWrapper: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  completedSubheading: {
    display: 'flex',
    flexDirection: 'column',
  },
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
  contentWrapper: {
    marginBottom: '0',
  },
}));

const USER_ACCESS_BOOL = {
  published: 'Locked',
  locked: 'Published',
};

const BulkUserAccessUpdateStatuses = ({
  open, onClose, modalState, transactionId, getReport, progressData,
  progressErrorMsg, onDownloadLog,
}) => {
  const classes = useStyles();

  const getStatus = useCallback(({ reportStatus }) => {
    if (reportStatus === COMPLETED) {
      return {
        src: CheckRoundIcon,
        heading: 'User Access update completed successfully!',
        subHeading: (
          <Box className={classes.completedSubheading}>
            <Typography variant="subtitle2" component="span" className={classes.subtitle}>
              User Access for selected components have been updated successfully
            </Typography>
          </Box>
        ),
        primaryBtnText: 'Close',
        primaryBtnClick: () => onClose(),
      };
    }
    if (reportStatus === COMPLETED_WITH_ERRORS) {
      return {
        src: AlertIcon,
        heading: 'User Access update was completed with errors.',
        subHeading: 'User access of the components was completed with errors',
        primaryBtnText: 'Download Log',
        primaryBtnClick: (created_at) => onDownloadLog({
          transaction_id: transactionId,
          created_at,
        }),
      };
    }

    return {
      src: ErrorRoundIcon,
      heading: 'User access update failed.',
      subHeading: 'Failed to update user access for selected components.',
      primaryBtnText: 'Download Log',
      primaryBtnClick: (created_at) => onDownloadLog({
        transaction_id: transactionId,
        created_at,
      }),
    };
  }, [classes.completedSubheading, classes.subtitle, onDownloadLog, onClose, transactionId]);

  const getStatusByCount = useCallback((totalScorms, failedScorms) => {
    if (failedScorms === 0) {
      return COMPLETED;
    } if (failedScorms !== 0 && failedScorms < totalScorms) {
      return COMPLETED_WITH_ERRORS;
    }
    return FAILED;
  }, []);

  const reportModal = useCallback(async () => {
    const result = await getReport(transactionId);

    const totalComponent = result?.total_rows;
    const failedComponent = result?.fail_count;
    const succeedComponent = result?.success_count;
    const reportStatus = getStatusByCount(totalComponent, failedComponent);

    const statusObj = getStatus(
      { totalComponent, failedComponent, reportStatus },
    );
    return ({
      component: GenericReportOverview,
      wrapperProps: {
        disableGutters: true,
        logo: (
          <Box>
            <img src={statusObj?.src} alt="copied-status" width={40} height={40} />
          </Box>
        ),
        heading: (
          <Box className={classes.headingWrapper}>
            <Typography variant="subtitle1" component="span" className={classes.title}>{statusObj?.heading}</Typography>
            <Typography variant="subtitle2" component="span" className={`${classes.subtitle} ${classes.subtitleStyles}`}>{statusObj?.subHeading}</Typography>
          </Box>
        ),
        headingDivider: reportStatus === COMPLETED_WITH_ERRORS,
        contentDivider: true,
        primaryBtnText: statusObj?.primaryBtnText,
        primaryBtnProps: {
          onClick: () => statusObj?.primaryBtnClick(result?.created_at),
        },
        breakpoint: 'md',
        contentWrapper: classes.contentWrapper,
      },
      props: {
        status: reportStatus,
        total: totalComponent,
        failed: failedComponent,
        succeed: succeedComponent,
      },
    });
  }, [getReport, transactionId, getStatus, classes.headingWrapper,
    classes.title, classes.subtitle, classes.subtitleStyles, getStatusByCount,
    classes.contentWrapper]);

  const progressDataModal = useCallback(() => ({
    component: GenericProgressModal,
    wrapperProps: {
      heading: (
        <DialogTitle>
          <FormattedTypography
            body="Updating User Access"
            subHeading={`Updating user access of selected components from ${USER_ACCESS_BOOL[progressData?.action_performed_status.toLowerCase()]} to ${progressData?.action_performed_status}`}
            subHeadingSection={classes.subHeadingSection}
          />
        </DialogTitle>
      ),
      logo: (
        <div className={classes.progressRow}>
          <LoaderInner
            progress={progressData?.percentage ? progressData?.percentage : 0}
            scaleFactor={1.5}
          />
        </div>
      ),
      secondaryBtnText: 'Close',
      secondaryBtnProps: {
        onClick: () => onClose(),
      },
      breakpoint: 'md',
    },
    props: {
      initiatedBy: progressData?.created_by,
      initiatedAt: progressData?.created_at,
    },
  }), [classes.progressRow, classes.subHeadingSection, onClose, progressData]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={progressDataModal}
      reportModal={reportModal}
    />
  );
};

BulkUserAccessUpdateStatuses.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

BulkUserAccessUpdateStatuses.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
};

export default BulkUserAccessUpdateStatuses;
