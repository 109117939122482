import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import _map from 'lodash/map';
import { ContentDetailsSection, ContentDetailsLabel } from '../ContentManagement/ContentDetailsSection';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import {
  ROUTE_REPORT, SETTINGS, METADATA_MANAGEMENT, CLIENT_LMS_MAX_LIMIT,
} from '../../constants';
import StagesMap from '../StageBadge/constants';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';

const OverviewSection = ({
  values, isEdit, setFieldValue, handleChange, programId, coursePath, dividerStyle, customLabel,
  isLoading, isDDAdmin,
}) => (
  <ContentDetailsBlock headerText="Overview" noPaper>
    {isLoading ? <LoadingCircle labelText="Loading Overview" /> : (
      <>
        <ContentDetailsSection
          label="Configuration ID"
          name="config_id"
          type="text"
          fieldType="text"
          value={values.config_id}
          fieldRedirectOnLabel
          fieldRedirectLink={`/${ROUTE_REPORT}/${programId}/${coursePath}/${SETTINGS}?tab=1&mode=view`}
        />
        <ContentDetailsSection
          label="Parent Program Config ID"
          name="parent_program_config_id"
          type="text"
          fieldType="text"
          value={values.parent_program_config_id}
          fieldRedirectOnLabel
          fieldRedirectLink={values?.replicated_program_id ? `/${ROUTE_REPORT}/${values?.replicated_program_id}/${coursePath}/overview` : false}
        />
        <ContentDetailsSection
          label="Learning Plan Name"
          name="lp_name"
          type="text"
          fieldType="text"
          value={values.lp_name}
          fieldRedirectOnLabel
          fieldRedirectLink={`/${ROUTE_REPORT}/${programId}/${coursePath}/${SETTINGS}?tab=3&mode=view`}
        />
        <ContentDetailsSection
          label="Status"
          name="status"
          type="text"
          fieldType="text"
          value={values?.status ? StagesMap[values.status] : 'N/A'}
          fieldRedirectOnLabel
          fieldRedirectLink={`/${ROUTE_REPORT}/${programId}/${coursePath}/${SETTINGS}?tab=1&mode=view`}
        />
        <ContentDetailsSection
          label="Primary Experience"
          name="primary_experience"
          type="text"
          fieldType="text"
          value={values.primary_experience}
          fieldRedirectOnLabel
          fieldRedirectLink={`/${ROUTE_REPORT}/${programId}/${coursePath}/${SETTINGS}?tab=2&mode=view`}
        />
        <ContentDetailsSection
          label="Platform URL"
          name="platform_url"
          type="text"
          fieldType="text"
          havingLinkText
          value={values.platform_url}
          fieldRedirectOnLabel
          fieldRedirectLink={`/${ROUTE_REPORT}/${programId}/${coursePath}/${SETTINGS}?tab=2&mode=view`}
        />
        <ContentDetailsSection
          label="Build Type"
          name="overview_buid_type"
          type="text"
          fieldType="select"
          isEdit={isEdit}
          options={values?.buid_type_list?.length > -1
            && values?.buid_type_list?.map((a) => ({ label: a, value: a }))}
          value={values.overview_buid_type}
          handleChange={(n, v, b) => {
            setFieldValue(n, v, b);
            if (v !== 'Source') {
              setFieldValue('overview_replicable.value', null);
              setFieldValue('overview_maintainance_log', null);
            }
          }}
        />
        {values?.overview_buid_type === 'Source' && (
          <>
            <ContentDetailsSection
              label="Replicable As-Is"
              name="overview_replicable"
              type="text"
              fieldType="radio"
              options={_map(values?.overview_replicable?.options,
                (item) => ({ title: item, val: item }))}
              isEdit={isEdit}
              value={values?.overview_replicable?.value !== null ? values?.overview_replicable?.value : ''}
              handleChange={(val) => {
                setFieldValue('overview_replicable.value', val);
              }}
            />
            {/* <ContentDetailsSection
              label="Maintenance Change Log"
              havingLinkText
              name="overview_maintainance_log"
              type="text"
              fieldType="textarea"
              isEdit={isEdit}
              handleChange={(e) => {
                if (e.target.value.length <= MAINTENANCE_LOG_MAX_LIMIT) {
                  handleChange(e);
                }
              }}
              value={values.overview_maintainance_log}
              showTextLimit
              maxTextLimit={MAINTENANCE_LOG_MAX_LIMIT}
            /> */}
          </>
        )}

        <ContentDetailsSection
          label="Language"
          name="language"
          type="text"
          fieldType="text"
          value={values.language}
          fieldRedirectOnLabel
          fieldRedirectLink={`/${ROUTE_REPORT}/${programId}/${coursePath}/${SETTINGS}?tab=2&mode=view`}
        />
        <ContentDetailsSection
          label="Client Build Type"
          name="overview_client_build_type"
          type="text"
          fieldType="select"
          isEdit={isEdit}
          options={values?.client_build_type_list?.length > -1
            && values?.client_build_type_list?.map((a) => ({ label: a, value: a }))}
          value={values.overview_client_build_type}
          handleChange={(n, v, b) => {
            setFieldValue(n, v, b);
          }}
        />
        <ContentDetailsSection
          label="Elucidat Project Type"
          name="overview_elucidat_project_type"
          type="text"
          fieldType="radio"
          options={_map(values?.overview_elucidat_project_type?.options,
            (item) => ({ title: item, val: item }))}
          isEdit={isEdit}
          value={values?.overview_elucidat_project_type?.value !== null ? values?.overview_elucidat_project_type?.value : ''}
          handleChange={(val) => {
            setFieldValue('overview_elucidat_project_type.value', val);
          }}
        />
        <ContentDetailsSection
          label="Closeout Date"
          name="overview_closeout_date"
          type="text"
          fieldType="calendar"
          isEdit={isEdit}
          value={values.overview_closeout_date}
          handleChange={setFieldValue}
        />
        <ContentDetailsSection
          label="Last Date of Docebo Standardization"
          name="overview_docebo_std_date"
          type="text"
          fieldType="calendar"
          isEdit={isEdit}
          value={values.overview_docebo_std_date}
          handleChange={setFieldValue}
        />
        <ContentDetailsSection
          label="Last Date of Elucidat Standardization"
          name="overview_elucidat_std_date"
          type="text"
          fieldType="calendar"
          isEdit={isEdit}
          value={values.overview_elucidat_std_date}
          handleChange={setFieldValue}
        />
        <ContentDetailsSection
          label="LMS Integration"
          name="overview_lms_integration"
          type="text"
          fieldType="radio"
          options={_map(values?.overview_lms_integration?.options,
            (item) => ({ title: item, val: item }))}
          isEdit={isEdit}
          value={values?.overview_lms_integration?.value ? values?.overview_lms_integration?.value : 'No'}
          handleChange={(val) => {
            setFieldValue('overview_lms_integration.value', val);
          }}
        />
        {values?.overview_lms_integration?.value === 'Yes' && (
          <ContentDetailsSection
            label="Client LMS"
            name="overview_client_lms"
            type="text"
            fieldType="text"
            isEdit={isEdit}
            handleChange={(e) => {
              if (e.target.value.length <= CLIENT_LMS_MAX_LIMIT) {
                handleChange(e);
              }
            }}
            value={values.overview_client_lms}
            showTextLimit
            maxTextLimit={CLIENT_LMS_MAX_LIMIT}
          />
        )}
        <Divider className={dividerStyle} variant="middle" light />
        <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
          <ContentDetailsLabel
            label="Metadata"
            labelCustomCss={customLabel}
            fieldRedirectLink={!isDDAdmin && `/${ROUTE_REPORT}/${programId}/${coursePath}/${METADATA_MANAGEMENT}?tab=2`}
            fieldRedirectOnLabel
          />
        </Grid>
        <ContentDetailsSection
          label="Portfolio"
          name="metadata_portfolio"
          type="text"
          fieldType="text"
          value={values.metadata_portfolio}
        />
        <ContentDetailsSection
          label="Offering"
          name="metadata_offering"
          type="text"
          fieldType="text"
          value={values.metadata_offering}
        />
        <ContentDetailsSection
          label="Program"
          name="metadata_program"
          type="text"
          fieldType="text"
          value={values.metadata_program}
        />
      </>
    )}

  </ContentDetailsBlock>
);

OverviewSection.propTypes = {
  programId: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  coursePath: PropTypes.string.isRequired,
  dividerStyle: PropTypes.string.isRequired,
  customLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
};

export default OverviewSection;
