import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { isCancel } from 'axios';
import XlsxValidationModal from '../../components/XlsxValidationModal';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import UserUnenrollmentOperationsPage from '../../components/UserUnenrollment';
import ConflictErrorModal from '../../components/ConflictErrorModal/ConflictErrorModal';
import { parseValidationResult } from '../../helpers/xlsxValidation';
import CancelSuggestionModal from '../../components/common/CancelSuggestionModal';
import {
  COURSE_OPS,
  BULK_UNENROLLMENT,
  ROUTE_HOME,
  BULK_UNENROLL_TEMPLATE,
  THRESHOLD_TIME,
  snackBarInitialState, AUTO_HIDE_DURATION,
} from '../../constants';

import {
  validateXLSXApiCall,
  uploadXlsx,
  downloadFile,
  getProgress,
  cancelTransaction, getReport,
} from '../common/apis';
import { internalServerErrorModalLogic } from '../common/utils';
import { getErrorMessage } from '../../helpers/apiHelper';
import AlertReleaseInfo from '../../components/ContentManagement/AlertReleaseInfo';
import CustomSnackbar from '../../components/common/CustomSnackbar';
import UnEnrollStatusModal from '../../components/UserUnenrollment/UnEnrollStatusModal';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  uploadWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadWrapperInner: {
    display: 'flex',
    flex: 0.75,
    minHeight: '30rem',
    height: '75%',
  },
  cancelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '35rem',
    alignItems: 'center',
  },
});

const onDownloadSample = () => {
  window.open(BULK_UNENROLL_TEMPLATE, '_blank');
};

const BulkUnenrollReportPage = (props) => {
  const { transactionIds, match, programMetadata } = props;
  const programType = COURSE_OPS;
  const programSubType = BULK_UNENROLLMENT;

  const history = useHistory();
  const classes = useStyles();
  const programId = get(match, 'params.programId');
  const configId = get(programMetadata, 'config_id', '');

  const [progress, setProgress] = useState({
    done: true, percentage: 0, transaction_status: '', queue_position: '',
  });
  const initialValidationResult = { status: 'none', errors: [] };
  const [validationResult, setValidationResult] = useState(initialValidationResult);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [pollProgress, setPollProgress] = useState(true);
  const [cancellationRequested, setCancellationRequested] = useState(false);
  const [isConflictErrorModalOpen, setIsConflictErrorModalOpen] = useState(false);
  const [openCancelSuggestionModal, setOpenCancelSuggestionModal] = useState(false);
  const [userClosedCancelSuggestionModal, setUserClosedCancelSuggestionModal] = useState(false);
  const [filename, setFilename] = useState('');
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [modalState, setModalState] = useState(null);
  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const { done } = progress;

  const resetCancellationStatus = () => {
    setCancellationRequested(false);
  };

  useEffect(() => {
    if (transactionIds.length) {
      resetCancellationStatus();
      setTransactionId(transactionIds[0]);
      setPollProgress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionIds.length]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!transactionId || !pollProgress) {
        return;
      }
      try {
        const res = await getProgress(transactionId);
        const { done: progressDone, filename: uploadFileName, transaction_status } = res?.data;

        if (progressDone) {
          batchUpdates(() => {
            setProgress(res?.data);
            setModalState('REPORT');
            setPollProgress(false);
          });
        } else {
          if (!userClosedCancelSuggestionModal && res.data.percentage > 0
            && differenceInSeconds(new Date(), new Date(`${res.data.updated_at}Z`)) > THRESHOLD_TIME.USER_UNENROLLMENT) {
            setOpenCancelSuggestionModal(true);
          }
          batchUpdates(() => {
            setModalState(transaction_status);
            setProgress(res?.data);
            setFilename(uploadFileName);
            setShowAlertBar(true);
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        batchUpdates(() => {
          setModalState(null);
          setShowAlertBar(false);
          setOpenStatusModal(false);
        });
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();

    return () => {
      clearTimeout(timer);
    };
  }, [pollProgress, transactionId, programSubType, history, userClosedCancelSuggestionModal]);

  const onDownload = async (data) => {
    try {
      const { transaction_id: tId, created_at: createdAt } = data;
      await downloadFile(tId, configId, BULK_UNENROLLMENT, createdAt);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    }
  };

  const onCancelTransaction = useCallback(async () => (
    cancelTransaction(transactionId)
  ), [transactionId]);

  useEffect(() => {
    let timerObj;
    if (done) {
      timerObj = setTimeout(() => setShowAlertBar(false), AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(timerObj);
    };
  }, [done]);

  const onCancelSuggestionClose = () => {
    setUserClosedCancelSuggestionModal(true);
    setOpenCancelSuggestionModal(false);
  };

  const onCancelProcess = useCallback(async () => {
    await onCancelTransaction();
    setCancellationRequested(true);
    onCancelSuggestionClose();
  }, [onCancelTransaction]);

  const generateFormData = useCallback(
    (file, unenrollReason) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('program_id', programId);
      formData.append('unenrollment_reason', unenrollReason);

      return formData;
    },
    [programId],
  );

  const uploadFile = useCallback(
    async (formData) => {
      try {
        setModalState(null);
        const uploadResult = await uploadXlsx(
          programType,
          programSubType,
          formData,
        );
        const tid = uploadResult?.data?.transaction_id;
        if (tid) {
          setTransactionId(tid);
          setPollProgress(true);
        } else {
          setShowAlertBar(false); // sometimes tId comes null as response
        }
      } catch (e) {
        setShowAlertBar(false);
      }
    },
    [programSubType, programType],
  );

  const resetFileUpload = (e) => {
    setValidationResult({ status: 'none', errors: [] });
    e.target.value = '';
  };

  const onUpload = async (e, unenrollReason) => {
    const file = e.target.files[0];
    batchUpdates(() => {
      setFilename(file?.name);
      setShowAlertBar(true);
      setProgress({ done: null, percentage: 0 });
    });
    const formData = generateFormData(file, unenrollReason);
    resetFileUpload(e);
    resetCancellationStatus();
    let result;
    try {
      result = await validateXLSXApiCall(programType, programSubType, formData);
    } catch (err) {
      batchUpdates(() => {
        setShowAlertBar(false);
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      });
      return; // Early exit
    }
    const errorMessages = parseValidationResult(result);
    if (errorMessages.errors.length) {
      setValidationResult(errorMessages);
      setShowAlertBar(false);
      return;
    }
    await uploadFile(formData);
  };

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
    history.replace(`/${ROUTE_HOME}/${COURSE_OPS}`);
  };

  const onViewStatus = (status) => {
    batchUpdates(() => {
      setModalState(status);
      setOpenStatusModal(true);
    });
  };

  const onConflictModalClose = () => {
    setIsConflictErrorModalOpen(false);
  };

  const bodyDom = (
    <div className={classes.bodyContainer}>
      {showAlertBar ? (
        <AlertReleaseInfo
          progress={progress}
          showElement={showAlertBar}
          progressMessage={`Processing User Unenrollment file "${filename}"`}
          withErrorsMessage={`User Unenrollment file "${filename}" upload was completed with errors.`}
          failedMessage={`Failed to upload user unenrollment file "${filename}". Please try again.`}
          successMessage={`User unenrollment file "${filename}" was successfully uploaded`}
          cancellingMessage="Cancelling pending user unenrollment tasks..."
          cancelledMessage={`User Unenrollment file "${filename}" upload was cancelled.`}
          onViewStatus={onViewStatus}
          setShowElement={setShowAlertBar}
        />
      ) : null}

      {openStatusModal && done !== null ? (
        <UnEnrollStatusModal
          open={openStatusModal}
          onClose={() => { setOpenStatusModal(false); }}
          modalState={modalState}
          transactionId={transactionId}
          getReport={getReport}
          progressData={progress}
          onDownloadLog={onDownload}
          type={programSubType}
          onCancelTransaction={onCancelTransaction}
          cancellationRequested={cancellationRequested}
          setCancellationRequested={setCancellationRequested}
        />
      ) : null}
      <UserUnenrollmentOperationsPage
        onUpload={onUpload}
        pollProgress={pollProgress}
        onDownloadSample={onDownloadSample}
        showAlertBar={showAlertBar}
      />
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {bodyDom}
      <XlsxValidationModal
        open={validationResult.status === 'error'}
        onClose={() => setValidationResult(initialValidationResult)}
        onOk={() => setValidationResult(initialValidationResult)}
        errors={validationResult.errors}
        type="Unenrollment"
        filename={filename}
      />
      <ErrorModal open={isErrorModalOpen} onClose={onErrorModalClose} />
      <ConflictErrorModal open={isConflictErrorModalOpen} onClose={onConflictModalClose} />
      <CancelSuggestionModal
        open={openCancelSuggestionModal}
        onCancel={onCancelProcess}
        onClose={onCancelSuggestionClose}
      />
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
    </div>
  );
};

BulkUnenrollReportPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
  }).isRequired,
};

export default BulkUnenrollReportPage;
