import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import { useStyles } from '../../stylesheets/commonModal.styles';
import ErrorIcon from '../../assets/icons/red-cross.svg';
import { ERROR_RED, WHITE } from '../../stylesheets/colors';

const failureModalStyles = makeStyles(() => ({
  paperFullWidth: {
    width: '580px',
  },
  title: {
    height: '50px',
    fontWeight: 'normal',
  },
  dialogContent: {
    fontSize: '14px',
  },
  primaryErrorAction: {
    backgroundColor: ERROR_RED,
    color: WHITE,
    borderRadius: '0px',
    border: '0px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: WHITE,
      color: ERROR_RED,
    },
  },
  secondaryErrorAction: {
    borderRadius: '0px',
    textTransform: 'capitalize',
    '&:hover': {
      color: ERROR_RED,
    },
  },
  imageBlock: {
    height: '46px',
    width: '46px',
    marginBottom: '20px',
  },
  buttonActions: {
    marginBottom: '20px',
    justifyContent: 'center',
    marginTop: '0px',
  },
}));

const DialogTitle = (props) => {
  const classes = useStyles();
  const {
    children, onClose,
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleBlock}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography className={classes.titleText}>{children}</Typography>
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

const FailureModal = ({
  open, onClose, errors, selectedCourseCount,
}) => {
  const classes = useStyles();
  const modalClasses = failureModalStyles();
  const errorJsx = errors.map((error) => (<li key={error}>{error}</li>));

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      disableBackdropClick
      onClose={onClose}
      open={open}
      classes={{ paperFullWidth: modalClasses.paperFullWidth }}
    >
      <DialogTitle onClose={onClose} className={classes.dialogTitle}>
        <img className={modalClasses.imageBlock} src={ErrorIcon} alt="Spreadsheet" width={26} height={26} />
        <span className={`${classes.headingText} ${modalClasses.title}`}>
          {errors.length}
          /
          {selectedCourseCount}
          {' '}
          Content not assigned
        </span>
      </DialogTitle>
      <MuiDialogContent className={`${classes.dialogContent} ${modalClasses.dialogContent}`} style={{ marginBottom: '0' }}>
        <ul style={{ maxHeight: '500px', overflow: 'auto' }}>
          Sincere apologies! We were not able to assign
          {errorJsx}
          due to some technical error.
        </ul>
      </MuiDialogContent>
      <MuiDialogActions className={modalClasses.buttonActions}>
        {/* Commenting out for now. Option causes issues with docebo content assign api */ }
        {/* <Button onClick={onRetry} className={modalClasses.secondaryErrorAction}
          variant="outlined">
          Try Again
        </Button> */}
        <Button onClick={onClose} className={modalClasses.primaryErrorAction} variant="outlined">
          Proceed
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

FailureModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectedCourseCount: PropTypes.number.isRequired,
};

FailureModal.defaultProps = {
  errors: ['Internal Server Error: Please contact the System Administrator'],
};

export default FailureModal;
