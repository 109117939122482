import get from 'lodash/get';
import {
  USER_INFO_KEY,
  TOKEN_KEY,
  ENVIRONMENT_KEY, LTI_TOKEN,
} from '../constants';

export const getUserFromLocalStorage = () => {
  const sample = {
    teams: [],
  };
  try {
    return JSON.parse(localStorage.getItem(USER_INFO_KEY)) || sample;
  } catch (e) {
    return sample;
  }
};

export const getUserNameFromLocalStorage = () => {
  const userName = {
    first_name: '',
    last_name: '',
  };
  try {
    const userInfo = JSON.parse(localStorage.getItem(USER_INFO_KEY)) || userName;
    const firstName = get(userInfo, 'first_name');
    const lastName = get(userInfo, 'last_name');
    return `${firstName} ${lastName}`;
  } catch (e) {
    return '';
  }
};

export const getEnvironmentFromLocalStorage = () => {
  const sample = ' ';
  try {
    return JSON.parse(localStorage.getItem(ENVIRONMENT_KEY)) || sample;
  } catch (e) {
    return sample;
  }
};

export const setInLocalStorage = (key, payload) => {
  localStorage.setItem(key, JSON.stringify(payload));
};

export const setTokenInSessionStorage = (token) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

export const clearTokenFromSessionsStorage = () => {
  sessionStorage.removeItem(TOKEN_KEY);
};

export const getUserFromMid = (mid) => {
  const name = get(mid, 'user.name');
  if (!name) {
    return {
      firstName: 'LOADING',
      lastName: 'LOADING',
    };
  }
  const [firstName, lastName] = name.split(' ');
  return {
    firstName,
    lastName,
  };
};

export const getDefaultHeaders = (timeout = 50) => {
  const midTenant = sessionStorage.getItem('_mid-tenant');
  const midAccessToken = sessionStorage.getItem('_mid-access-token');

  return (!midTenant || !midAccessToken)
    ? new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          // max timeout limit
          if (timeout > 1000) throw new Error('Token not found');
          const resp = await getDefaultHeaders(timeout + 10);
          resolve(resp);
        } catch (e) {
          reject(e);
        }
      }, timeout);
    }) : {
      'x-tenant': JSON.parse(midTenant).tenantId,
      Authorization: `Bearer ${midAccessToken}`,
    };
};

export const getDefaultLTIHeaders = (timeout = 50) => {
  const tokenObj = JSON.parse(localStorage.getItem(LTI_TOKEN));

  return (!tokenObj?.token)
    ? new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          // max timeout limit
          if (timeout > 1000) throw new Error('Token not found');
          const resp = await getDefaultLTIHeaders(timeout + 10);
          resolve(resp);
        } catch (e) {
          reject(e);
        }
      }, timeout);
    }) : {
      Authorization: `Token ${tokenObj.token}`,
    };
};

export const isAuthenticated = () => {
  const token = sessionStorage.getItem(TOKEN_KEY);
  if (token) {
    return true;
  }

  return false;
};

export const formatUserCircle = (user) => `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
