import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  BLACK, MEDIUM_GREY,
} from '../../stylesheets/colors';
import ConfirmationModal from '../common/ConfirmationModal';
import ConfirmationIcon from '../../assets/icons/confirmation.svg';
import { USER_ACCESS } from '../../constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:nth-child(4)': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
  modalHeight: {
    minHeight: '35rem',
    height: 'auto',
  },
});

const BulkUserAccessConfirmation = ({
  open, onClose, loading, onConfirm, status,
}) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyle}
      isLoading={loading}
      minHeightClass={classes.modalHeight}
      content={(
        <Container className={classes.wrapper}>
          <img src={ConfirmationIcon} alt="confirmation-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>{`Update User access value to ${USER_ACCESS[status].label}`}</Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            {`This will update the user access to "${USER_ACCESS[status].label}" for the selected components.`}
          </Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            Click on Confirm to proceed.
          </Typography>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => { onConfirm(); }}>
            Confirm
          </Button>
        </>
      )}
    />
  );
};

BulkUserAccessConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default BulkUserAccessConfirmation;
