import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import JournalingDeleteIcon from '../../assets/img/deleteIconJournaling.svg';
import ConfirmationModal from '../common/ConfirmationModal';
import { BLACK, MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:last-child': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
}));

const RestrictReplicationModal = ({
  onClose,
}) => {
  const classes = useStyles();
  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>
            This learning plan cannot be replicated
          </Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            A Journal Review page cannot be replicated without a Journal Standard Page.
          </Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            Please select at least one Standard page  to proceed with Replication.
          </Typography>
        </Container>
            )}
      actions={(
        <Button color="primary" variant="contained" onClick={onClose}>
          Go back to Modify Learning Objects
        </Button>
      )}
    />
  );
};

RestrictReplicationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RestrictReplicationModal;
