import React, { } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FileInput from '../FileInput';
import MainButton from '../MainButton';

import {
  MCKINSEY_BLUE,
  ACCORDION_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  border: {
    border: `1px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
  disabledBorder: {
    border: `1px dashed ${ACCORDION_GREY}`,
    width: '40%',
  },
  downloadSampleWrapper: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  downloadSampleButton: {
    marginTop: '0.65rem',
  },
  helptext: {
    marginTop: '1.5rem',
  },
  divider: {
    marginTop: '1.5rem',
  },
}));
const WorkshopUserUpload = ({ onFileInput, onDownloadSample, fileInputdisabled }) => {
  const classes = useStyles();
  return (
    <>
      <div className={`${fileInputdisabled ? classes.disabledBorder : classes.border}`}>
        <FileInput idx={0} onFileInput={onFileInput} isDisabled={fileInputdisabled}>
          <MainButton
            title="Upload XLS file"
            subtitle="We will update the users' status as completed for this workshop"
            isDisabled={fileInputdisabled}
          />
        </FileInput>
      </div>
      <Divider variant="fullWidth" className={classes.divider} light />
      <Typography variant="body1" className={classes.helptext}>Need  help configuring the file? Download the template below.</Typography>

      <div className={classes.downloadSampleWrapper}>
        <Button
          className={classes.downloadSampleButton}
          color="primary"
          variant="outlined"
          onClick={onDownloadSample}
        >
          Download XLS Template
        </Button>
      </div>
    </>
  );
};
WorkshopUserUpload.propTypes = {
  onFileInput: PropTypes.func.isRequired,
  onDownloadSample: PropTypes.func.isRequired,
  fileInputdisabled: PropTypes.bool.isRequired,
};

export default WorkshopUserUpload;
