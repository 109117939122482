import {
  PORTFOLIO_DATA_FETCH,
  PORTFOLIO_DATA_FETCH_SUCCESS,
  PORTFOLIO_DATA_FETCH_FAILED,
  ADD_NEW_PORTFOLIO,
  ADD_NEW_PORTFOLIO_SUCCESS,
  ADD_NEW_PORTFOLIO_FAILURE,
  DELETE_PORTFOLIO,
  DELETE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_FAILURE,
  UPDATE_PORTFOLIO,
  UPDATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_FAILURE,
  GET_PORTFOLIO_OFFERING_LIST,
  GET_PORTFOLIO_OFFERING_LIST_SUCCESS,
  GET_PORTFOLIO_OFFERING_LIST_FAILURE,
} from '../constants';

export function getPortfolioData(data) {
  return {
    type: PORTFOLIO_DATA_FETCH,
    data,
  };
}

export function getPortfolioDataSuccess(results) {
  return {
    type: PORTFOLIO_DATA_FETCH_SUCCESS,
    results,
  };
}

export function getPortfolioDataFailure(response) {
  return {
    type: PORTFOLIO_DATA_FETCH_FAILED,
    response,
  };
}

export function getPortfolioOfferingData(data) {
  return {
    type: GET_PORTFOLIO_OFFERING_LIST,
    data,
  };
}

export function getPortfolioOfferingDataSuccess(results) {
  return {
    type: GET_PORTFOLIO_OFFERING_LIST_SUCCESS,
    results,
  };
}

export function getPortfolioOfferingDataFailure(response) {
  return {
    type: GET_PORTFOLIO_OFFERING_LIST_FAILURE,
    response,
  };
}

export function createNewPortfolio(data) {
  return {
    type: ADD_NEW_PORTFOLIO,
    data,
  };
}

export function createNewPortfolioSuccess(response) {
  return {
    type: ADD_NEW_PORTFOLIO_SUCCESS,
    response,
  };
}

export function createNewPortfolioFailure(response) {
  return {
    type: ADD_NEW_PORTFOLIO_FAILURE,
    response,
  };
}

export function updatePortFolio(data) {
  return {
    type: UPDATE_PORTFOLIO,
    data,
  };
}

export function updatePortfolioSuccess(response) {
  return {
    type: UPDATE_PORTFOLIO_SUCCESS,
    response,
  };
}

export function updatePortfolioFailure(response) {
  return {
    type: UPDATE_PORTFOLIO_FAILURE,
    response,
  };
}

export function deletePortfolio(id) {
  return {
    type: DELETE_PORTFOLIO,
    id,
  };
}

export function deletePortfolioSuccess(response) {
  return {
    type: DELETE_PORTFOLIO_SUCCESS,
    response,
  };
}

export function deletePortfolioFailure(response) {
  return {
    type: DELETE_PORTFOLIO_FAILURE,
    response,
  };
}
