import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormattedTypography from '../common/FormattedTypography';

import { MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  containerClassName: {
    flexWrap: 'wrap',
  },
  titleColor: {
    color: MEDIUM_GREY,
    marginRight: '0.4rem',
  },
  bodyColor: {
    color: MEDIUM_GREY,
  },
  suffix: {
    color: MEDIUM_GREY,
    flexBasis: '100%',
    textAlign: 'center',
  },
});

const DeleteWaveModal = ({ waveTitle }) => {
  const classes = useStyles();
  return (
    <FormattedTypography
      containerClassName={classes.containerClassName}
      prefixClassName={classes.titleColor}
      prefix="You are about to delete cohort "
      className={classes.bodyColor}
      body={` ${waveTitle}`}
      suffix="Please note, while this cohort will be deleted, the cohort title may not be used for any new cohorts."
      suffixClassName={classes.suffix}
      dense
      variant="subtitle2"
      justifyContent="center"
    />
  );
};
DeleteWaveModal.propTypes = {
  waveTitle: PropTypes.string.isRequired,
};

export default memo(DeleteWaveModal, (() => true));
