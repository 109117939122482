import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Header from '../common/Header';

const BulkUpdateAccountsHeader = ({ className }) => (
  <Header
    className={className}
    heading="Activate / Deactivate Users"
    subHeading="Upload XLS file below to activate or deactivate users from Docebo"
  />
);

BulkUpdateAccountsHeader.defaultProps = {
  className: '',
};

BulkUpdateAccountsHeader.propTypes = {
  className: PropTypes.string,
};

export default memo(BulkUpdateAccountsHeader, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
));
