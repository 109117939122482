import React, { memo } from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import {
  ERROR_RED, ERROR_ORANGE1, RED_DARK, MODERATE_LIGHT_GREY,
} from '../../../stylesheets/colors';

const useStyles = makeStyles({
  iconWrapper: {
    height: '2rem',
    width: '4rem',
    backgroundColor: ERROR_ORANGE1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    color: ERROR_RED,
  },
  indicator: {
    color: RED_DARK,
  },
  listPadding: {
    padding: '1rem 10rem 1rem 10rem',
  },
  multiLine: {
    marginTop: '1.5rem',
    marginBottom: '0.75rem',
    paddingLeft: '2rem',
  },
  singleLine: {
    marginBottom: '0.75rem',
    paddingLeft: '2rem',
  },
  listBorder: {
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    paddingLeft: '0px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
});

const MetadataUsersValidation = ({ errors }) => {
  const classes = useStyles();

  const errorsList = errors.reduce((list, item) => {
    if (item.msg_head) {
      const matchItem = list.filter((listitem) => item.msg_head === listitem.msg_head);
      if (matchItem.length) {
        matchItem[0].row.push(item.row);
      } else {
        list.push({ ...item, row: [item.row] });
      }
      return list;
    }
    list.push({ ...item });
    return list;
  }, []);

  return (
    <List component="nav" aria-label="main" dense classes={{ padding: classes.listPadding }}>
      {errorsList.map((err, i) => {
        const errRow = err.row || '';
        const errTitle = err.msg_head || '';
        return (
          <ListItem key={err.message} className={classes.listBorder}>
            <ListItemIcon className={classes.iconWrapper}>
              <Typography component="span" className={classes.indicator}>
                {errRow ? `${errRow.length} rows` : `E${i + 1}`}
              </Typography>
            </ListItemIcon>
            <ListItemText
              classes={{ multiline: errRow ? classes.multiLine : classes.singleLine }}
              primary={err.message}
              secondary={errTitle ? `Row ${errRow.join(',')}` : ''}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

MetadataUsersValidation.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default memo(MetadataUsersValidation, () => true);
