import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';

import SubMenu from '../common/SubMenu';

import { MODERATE_LIGHT_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    borderRight: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
});

const EditProgramSidebar = ({ items, className }) => {
  const classes = useStyles();

  return (
    <Container className={`${classes.sidebar} ${className}`}>
      <SubMenu items={items} heading="Process Outline" />
    </Container>
  );
};

EditProgramSidebar.defaultProps = {
  className: '',
};

EditProgramSidebar.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
  })).isRequired,
};

export default memo(EditProgramSidebar, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
   && _isEqual(prevProps.items, nextProps.items)
));
