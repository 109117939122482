/* eslint-disable react/sort-comp */
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ThemeShape, DefaultTheme } from '../default-theme';
import { DropdownList } from './DropdownList';
import { OptionShape } from './option-shape';

const DropdownWrapper = styled.div`
  font-family: ${(props) => props.theme.font_family};
  position: relative;
  display: inline-block;
  height: 100%;

  .dsrc_dropdown_selector {
    &.disabled {
      pointer-events: none;
    }
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .dsrc_dropdown_list_container {
    background-color: ${(props) => props.theme.white}
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 200px;
   };

  ${(props) => props.menuPosition === 'left'
    && css`
      .dsrc_dropdown_list_container {
        left: 0;
      }
    `}
  ${(props) => props.menuPosition === 'full'
    && css`
      .dsrc_dropdown_list_container {
        width: 100%;
      }
    `}
  ${(props) => props.menuPosition === 'right'
    && css`
      .dsrc_dropdown_list_container {
        right: 0;
      }
    `}
`;

export class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleOnButtonClick = this.handleOnButtonClick.bind(this);
    this.handleOnOptionsClick = this.handleOnOptionsClick.bind(this);
    this.handleOuterClick = this.handleOuterClick.bind(this);
    this.setRef = this.setRef.bind(this);
    this.selectorRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleOuterClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOuterClick);
  }

  setRef(componentNode) {
    this.node = componentNode;
  }

  handleOnOptionsClick(option) {
    const { onChange, handleOnOptionsChange } = this.props;

    if (handleOnOptionsChange) {
      handleOnOptionsChange(option);
    } else {
      onChange(option);
    }

    this.setState({
      open: false,
    });
  }

  handleOuterClick(event) {
    if (!this.node.contains(event.target)) {
      this.setState(() => ({ open: false }));
    }
  }

  handleOnButtonClick() {
    const { open } = this.state;
    const { handleOnButtonClick } = this.props;
    const dropdownStateIsOpen = handleOnButtonClick ? handleOnButtonClick(open) : !open;

    this.setState({
      open: dropdownStateIsOpen,
    });
  }

  render() {
    const {
      children, disabled, options, ...other
    } = this.props;
    const { open } = this.state;

    return (
      <DropdownWrapper
        className={`dsrc_dropdown_wrapper ${open ? 'open' : ''}`}
        ref={this.setRef}
        {...other}
        {...this.props}
      >
        <div
          role="button"
          className={`dsrc_dropdown_selector ${disabled ? 'disabled' : ''}`}
          onClick={this.handleOnButtonClick}
          onKeyDown={this.handleClick}
          disabled={disabled}
        >
          {children}
        </div>
        <DropdownList onClick={this.handleOnOptionsClick} options={options} open={!!open} />
      </DropdownWrapper>
    );
  }
}

const menuPositions = ['left', 'right', 'full'];

Dropdown.defaultProps = {
  options: [],
  onChange: () => { },
  onKeyDown: () => { },
  handleOnButtonClick: undefined,
  handleOnOptionsChange: undefined,
  disabled: false,
  theme: DefaultTheme,
  menuPosition: 'full',
};

Dropdown.propTypes = {
  theme: ThemeShape,
  options: PropTypes.arrayOf(OptionShape),
  onChange: PropTypes.func,
  handleOnButtonClick: PropTypes.func,
  handleOnOptionsChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  menuPosition: PropTypes.oneOf(menuPositions),
  children: PropTypes.element.isRequired,
};

export default Dropdown;
