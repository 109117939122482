import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Popper } from '@mui/material';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import { SWITCH_GREY, ACCORDION_GREY } from '../../stylesheets/colors';
import ThreeDotButton from './ThreeDotButton';

const useStyles = makeStyles({
  disabledList: {
    background: `${SWITCH_GREY} !important`,
    pointerEvents: 'none',
    color: `${ACCORDION_GREY} !important`,
  },
  popperWrapper: {
    zIndex: 100,
  },
});
const CustomPopper = ({
  list, button: Button, disabled, additionalClass,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const toggleMenu = (event) => {
    event.stopPropagation();
    setAnchorEl((prevState) => (prevState ? null : event.currentTarget));
  };

  const onListItemClick = (e, passedMethod) => {
    passedMethod();
    toggleMenu(e);
  };

  return (
    <>
      <Button disabled={disabled} onClick={toggleMenu} anchorEl={anchorEl} />
      <Popper open={!!anchorEl} anchorEl={anchorEl} placement="top-end" className={classes.popperWrapper}>
        <ClickAwayListener onClickAway={toggleMenu}>
          <Paper elevation={3}>
            <List className={additionalClass}>
              {list.map((item) => (item ? (
                <ListItem key={item.title} disablePadding>
                  <ListItemButton
                    data-testid={`popperActionBtn_${item?.value}`}
                    className={item?.disabled ? classes.disabledList : ''}
                    onClick={(e) => onListItemClick(e, item.onClick)}
                    key={item.title}
                    disable={item.disabled}
                  >
                    <ListItemText primary={item?.title} />
                  </ListItemButton>
                </ListItem>
              ) : null))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

CustomPopper.defaultProps = {
  disabled: false,
  additionalClass: '',
  button: ThreeDotButton,
};
CustomPopper.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  button: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  disabled: PropTypes.bool,
  additionalClass: PropTypes.string,
};

export default CustomPopper;
