import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { DialogActions, DialogContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography/Typography';
import LabledTextField from '../../common/LabledTextField';
import { DEFAULT_GROUP_COUNT, validateForm } from '../common/utils';
import { FEATURE_CLICKED } from '../../../constants';
import { MEDIUM_GREY } from '../../../stylesheets/colors';
import CustomModal from '../../common/CustomModal';
import FormattedTypography from '../../common/FormattedTypography';

const useStyles = makeStyles({
  modalStyles: {
    maxWidth: '73rem',
  },
  dialogContentClass: {
    padding: '0 13rem 0.5rem 13rem',
    width: '100%',
    '& > div': {
      marginBottom: '2rem',
    },

    '& > div:last-child': {
      marginBottom: '1rem',
    },
  },
  modalHeader: {
    textAlign: 'center',
  },
  divider: {
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',

    '& > button:nth-child(1)': {
      width: '11rem',
    },
  },
  subHeading: {
    color: MEDIUM_GREY,
  },
  formWrapper: {
    width: '100%',
  },
});

const AddAssignmentModal = ({
  open, configId, onClose, onCreate, programSubType, startingIndex,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const classes = useStyles({ buttonDisabled });
  const [startingAt, setStartingAt] = useState(startingIndex + 1);
  const [count, setCount] = useState(DEFAULT_GROUP_COUNT);

  const onCreateInner = () => {
    onClose();

    // adding heap tracking script to track actual feature click
    window.heap.track(FEATURE_CLICKED, programSubType);
    onCreate(startingAt, count);
  };

  useEffect(() => {
    // TODO: Proper form validation
    const isFormValid = validateForm(configId, startingAt, count);
    setButtonDisabled(!isFormValid);
  }, [setButtonDisabled, configId, startingAt, count]);

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyles}
    >
      <Box className={classes.formWrapper}>
        <DialogTitle disableTypography className={classes.modalHeader}>
          <FormattedTypography
            body="Create Groups"
            dense
          />
          <Typography className={classes.subHeading} variant="body1">
            Groups created from this portal do not exist on Docebo and can only be updated here
          </Typography>
        </DialogTitle>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogContent className={`${classes.dialogContentClass}`}>
          <LabledTextField
            label="Starting at"
            value={startingAt}
            onChange={(e) => setStartingAt(Number.parseInt(e.target.value, 10))}
            placeholder="1"
            type="number"
            disabled
          />
          <LabledTextField
            label="Count (Max: 2000)"
            value={count}
            onChange={(e) => setCount(Number.parseInt(e.target.value, 10))}
            placeholder={DEFAULT_GROUP_COUNT}
            type="number"
          />
        </DialogContent>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogActions className={classes.centerAction}>
          <Button
            color="primary"
            variant="contained"
            onClick={onCreateInner}
            disabled={buttonDisabled}
          >
            Create
          </Button>
        </DialogActions>
      </Box>
    </CustomModal>
  );
};

AddAssignmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  programSubType: PropTypes.string.isRequired,
  startingIndex: PropTypes.number.isRequired,
};

export default AddAssignmentModal;
