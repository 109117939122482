import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ACCORDION_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
});

const LabeledView = ({
  className, label, value,
}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.wrapper} ${className}`}>
      <Typography variant="subtitle2" className={classes.infoHelperText}>{label}</Typography>
      <Typography variant="subtitle2">{value}</Typography>
    </Box>
  );
};

LabeledView.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

LabeledView.defaultProps = {
  label: '-',
  value: '-',
  className: '',
};

export default LabeledView;
