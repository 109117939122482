import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@material-ui/core/Typography';

import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import NoMedia from '../../assets/img/temp_logo.png';
import WorkshopManagementIltModal from './WorkshopManagementIltModal';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '78rem',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sessionCount: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '7px',
    padding: '0 1rem',
    fontSize: '0.9rem',
    color: theme.palette.primary.main,
  },
  image: {
    '&.image': {
      width: '3.125rem',
      height: '3.125rem',
    },
  },
}));

const WorkshopManagementCard = ({ data, onClick }) => {
  const classes = useStyles();
  const [openSessionView, setOpenSessionView] = useState(false);

  const actionMenu = [{
    title: 'View workshop details',
    onClick: () => setOpenSessionView(true),
  }];

  return (
    <>
      <Card className={`${classes.card} ${classes.content}`} onClick={onClick}>
        <CardContent className={classes.content}>
          <CardMedia
            component="img"
            className="image"
            classes={{ root: classes.image }}
            image={data.thumbnail ? data.thumbnail : NoMedia}
            alt={data.name}
          />
          <div className={classes.textContent}>
            <Typography noWrap variant="subtitle1">
              {data.name}
            </Typography>
            <Typography noWrap variant="subtitle2">
              {data.course_code}
            </Typography>
          </div>
        </CardContent>
        <div className={classes.action}>
          <Typography className={classes.sessionCount}>
            {data.all_sessions}
            {' '}
            {data.all_sessions > 1 ? 'sessions' : 'session'}
          </Typography>
          <CardActions>
            <CustomPopover
              list={actionMenu}
              button={ThreeDotButton}
            />
          </CardActions>
        </div>
      </Card>
      <WorkshopManagementIltModal
        open={openSessionView}
        data={data}
        onClose={() => setOpenSessionView(false)}
      />
    </>
  );
};

WorkshopManagementCard.propTypes = {
  data: PropTypes.shape({
    idCourse: PropTypes.number.isRequired,
    all_sessions: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    course_code: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(WorkshopManagementCard,
  (prevProps, nextProps) => _isEqual(prevProps.data, nextProps.data));
