import React from 'react';
import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography/Typography';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import EmptyInstruction from '../common/EmptyInstruction';
import {
  INSTRUCTION,
} from '../../constants';
import {
  DARK_MEDIUM_GREY, MODERATE_DARK_GREY, MCKINSEY_LIGHT_BLUE, MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  instructionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  instructionHeading: {
    padding: '0 2rem',
    fontWeight: 'bold',
  },
  instructionSubHeading: {
    padding: '.5rem 2rem 1rem',
    color: DARK_MEDIUM_GREY,
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  menuItems: {
    whiteSpace: 'normal',
    lineHeight: 2,
    cursor: 'auto',
    color: MEDIUM_GREY,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  menusubItems: {
    whiteSpace: 'normal',
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  listItemIcon: {
    minWidth: '2.5rem',
    marginTop: '0.4rem',
    alignSelf: 'flex-start',
    color: MODERATE_DARK_GREY,
  },
  sublistItemIcon: {
    minWidth: '2.5rem',
    padding: '0 1rem 0 1.5rem',
  },
  menuSubtitleMedGrey: {
    color: DARK_MEDIUM_GREY,
  },
  emptyWrapper: {
    padding: '10rem 5rem',
  },
});

const Instruction = ({ feature }) => {
  const classes = useStyles();
  const { value } = feature;
  return (
    <Box className={classes.instructionWrapper}>
      {
        INSTRUCTION[value]
          ? (
            <>
              { INSTRUCTION[value].heading && <Typography className={classes.instructionHeading} variant="body2">{INSTRUCTION[value].heading}</Typography>}
              { INSTRUCTION[value].subheading && <Typography className={classes.instructionSubHeading} variant="h3">{INSTRUCTION[value].subheading}</Typography>}
              { INSTRUCTION[value].content
                                              && (
                                              <MenuList>
                                                {INSTRUCTION[value].content.map((item) => (
                                                  <MenuItem className={classes.menuItems} key={`content-${item[0]}`}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                      <TrendingFlatIcon style={{ fontSize: '1.5rem', color: MCKINSEY_LIGHT_BLUE }} />
                                                    </ListItemIcon>
                                                    <Box>
                                                      {item.map((contentItem) => <Typography key={contentItem} component="p" variant="body1">{parser(contentItem)}</Typography>)}
                                                    </Box>
                                                  </MenuItem>
                                                ))}
                                              </MenuList>
                                              )}
            </>
          ) : (
            <Box className={classes.emptyWrapper}>
              <EmptyInstruction message="This feature has no instructions." />
            </Box>
          )

      }

    </Box>
  );
};

Instruction.propTypes = {
  feature: PropTypes.object.isRequired,
};

export default Instruction;
