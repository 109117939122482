import * as React from 'react';
import PropTypes from 'prop-types';

import {
  useField,
} from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { MODERATE_LIGHT_GREY, BLACK } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    '& .MuiTableCell-head': {
      backgroundColor: MODERATE_LIGHT_GREY,
      color: BLACK,
    },
  },
});

const EnhancedTableHead = ({
  headCells,
}) => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [selectAllField, selectAllMeta, selectAllHelpers] = useField({
    name: 'selectAll',
    type: 'checkbox',
  });

  return (
    <TableHead className={classes.wrapper}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            id={selectAllField.name}
            name={selectAllField.name}
            checked={selectAllField.checked}
            onChange={(event) => {
              selectAllHelpers.setValue(event.target.checked);
            }}
            inputProps={{
              'aria-label': 'select-all',
            }}
          />
        </TableCell>
        {headCells.map(({ title, ...otherProps }) => (
          <TableCell
            key={title}
            size="medium"
            {...otherProps}
          >
            {title}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
};

export default EnhancedTableHead;
