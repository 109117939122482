import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _map from 'lodash/map';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isCancel } from 'axios';
import {
  downloadFile, getProgress, getReport, uploadXlsx, cancelTransaction,
} from '../common/apis';
import {
  updateEnrollmentTransaction, validateUserEnrollmentXLSXApiCall,
  getClientIdsRecord, getEnrollmentCohort,
} from './apis';
import {
  WAVE_MANAGEMENT, COURSE_OPS, ROUTE_HOME, ROUTE_REPORT,
  USER_MANAGEMENT_TEMPLATE, THRESHOLD_TIME, DEFAULT, MCKINSEY_ACADEMY,
  USER_MANAGEMENT_RECOMMENDED_LIMIT, FAILED, COMPLETED_WITH_ERRORS,
  COMPLETED, AUTO_HIDE_DURATION,
  snackBarInitialState, PENDING, REPORT, CANCELLED, modalIntitalSchema, AUTO_ENROLLMENT,
  MASTER_DATA, CLIENT_MASTER,
} from '../../constants';
import { parseValidationResult } from '../../helpers/xlsxValidation';
import { zeroAppender } from '../../helpers/formattingHelpers';
import { internalServerErrorModalLogic } from '../common/utils';
import FormattedTypography from '../../components/common/FormattedTypography';
import UserManagementOperationsPage from '../../components/UserEnrollment/OperationsPage';
import EnrollmentError from '../../components/UserEnrollment/OperationsPage/EnrollmentError';
import EnrollmentUploadError from '../../components/UserEnrollment/OperationsPage/EnrollmentUploadError';
import EnrollmentUserLimitWarning from '../../components/UserEnrollment/OperationsPage/EnrollmentUserLimitWarning';
import ConflictErrorModal from '../../components/ConflictErrorModal/ConflictErrorModal';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import AlertBarWithAction from '../../components/common/AlertBarWithAction';
import CancelSuggestionModal from '../../components/common/CancelSuggestionModal';
import ValidationError from '../../assets/img/validation-error.webp';
import UserWarning from '../../assets/img/group-3.svg';
import AlertReleaseInfo from '../../components/ContentManagement/AlertReleaseInfo';
import userEnrollmentStyles from './styles';
import UserEnrollmentStatusModal from './UserEnrollmentStatusModal';
import { getErrorMessage } from '../../helpers/apiHelper';
import CustomSnackbar from '../../components/common/CustomSnackbar';
import CustomUploadModal from '../../components/common/CustomUploadModal';

const useStyles = makeStyles({
  ...userEnrollmentStyles,
});

const fetchEnrollmentCohort = async (
  programId, { setCohortList, setEnrollmentWave },
) => {
  try {
    setEnrollmentWave(null);
    const response = await getEnrollmentCohort(programId);
    if (response?.data?.data) {
      const cohortData = response?.data?.data;
      if (cohortData.length) {
        const cohortList = _map(cohortData, (obj) => ({
          label: obj?.ec_code,
          value: obj?.ec_code,
          waveId: obj?.wave_id,
          experience_type: obj?.experience_type,
          clientId: obj?.client_id ?? [],
        }));
        setCohortList(cohortList);
      } else {
        const defaultCohortlist = [{
          label: 'No cohort available', value: 'No cohort available', waveId: null, isdisabled: true,
        }];
        setCohortList(defaultCohortlist);
      }
    }
  } catch (e) {
    setCohortList([]);
  }
};

const UserEnrollmentPage = (props) => {
  const { match, location, programMetadata } = props;
  const configId = get(programMetadata, 'config_id', '');
  const extendedEnterprise = get(programMetadata, 'extended_enterprise', '');
  const programId = Number.parseInt(get(match, 'params.programId'), 10);
  const programType = get(match, 'params.programType');
  const programSubType = get(match, 'params.programSubType');
  const fromViewDetails = get(location, 'state.isViewDetails', false);
  const history = useHistory();
  const classes = useStyles();

  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [filename, setFilename] = useState('');
  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);
  const [cancellationRequested, setCancellationRequested] = useState(false);
  const [schema, setSchema] = useState(modalIntitalSchema);
  const [isConflictErrorModalOpen, setIsConflictErrorModalOpen] = useState(false);
  const [progress, setProgress] = useState({
    done: null, percentage: 0, transaction_status: '', queue_position: '',
  });
  const { done, transaction_status } = progress;
  const [pollProgress, setPollProgress] = useState(true);
  const [cohortList, setCohortList] = useState([]);
  const [transactionId, setTransactionId] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [openCancelSuggestionModal, setOpenCancelSuggestionModal] = useState(false);
  const [userClosedCancelSuggestionModal, setUserClosedCancelSuggestionModal] = useState(false);
  const [userSelection, setUserSelection] = useState({ type: 'SINGLE', configId: null });
  const [modalState, setModalState] = useState(null);
  const [enrollmentWave, setEnrollmentWave] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigateToEnrollmentCohortPage = useCallback(() => {
    history.push(`/${ROUTE_REPORT}/${programId}/${COURSE_OPS}/${WAVE_MANAGEMENT}`);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigateToClientMaster = () => {
    history.push(`/${ROUTE_HOME}/${COURSE_OPS}/${MASTER_DATA}/${CLIENT_MASTER}`);
  };

  useEffect(() => {
    if (location.search.includes('vn=old')) {
      sessionStorage.setItem('vn', 'old');
    }
  }, [location.search]);

  useEffect(() => {
    // Load the Cohort
    fetchEnrollmentCohort(programId, {
      setCohortList,
      setEnrollmentWave,
    });
  }, [programId]);

  useEffect(() => {
    if (fromViewDetails) {
      setPollProgress(true);
    }
  }, [fromViewDetails]);

  useEffect(() => {
    const allEnrollmentTransactionIds = get(
      programMetadata,
      'activities_status.course_ops.user_management.auto_enrollment.transaction_ids',
      null,
    );
    if (allEnrollmentTransactionIds[0]) {
      batchUpdates(() => {
        setTransactionId(allEnrollmentTransactionIds[0]);
        setPollProgress(true);
      });
    }
  }, [programMetadata]);

  const onDownloadLog = async (transaction, q) => {
    try {
      const tid = transaction.transaction_id;
      const createdAt = transaction.created_at;
      await downloadFile(tid, configId, AUTO_ENROLLMENT, createdAt, q);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onExistingUsersUpdate = async (payload) => {
    try {
      await updateEnrollmentTransaction(payload);
      batchUpdates(() => {
        setOpenStatusModal(true);
        setPollProgress(true);
        setShowAlertBar(true);
      });
    } catch (e) {
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      }
    }
  };

  useEffect(() => {
    let timerObj;
    if (done
        && [COMPLETED, FAILED, COMPLETED_WITH_ERRORS, CANCELLED].includes(transaction_status)) {
      timerObj = setTimeout(() => setShowAlertBar(false), AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(timerObj);
    };
  }, [done, transaction_status]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!transactionId || !pollProgress) {
        setPollProgress(false);
        return;
      }
      try {
        const res = await getProgress(transactionId);
        const {
          done: progressDone, filename: uploadFileName,
          transaction_status: transactionStatus,
        } = res?.data;

        if (progressDone) {
          if (transactionStatus === PENDING) {
            setShowAlertBar(true);
          }
          batchUpdates(() => {
            setProgress(res?.data);
            setModalState(transactionStatus === PENDING ? PENDING : REPORT);
            setPollProgress(false);
            setFilename(uploadFileName);
          });
        } else {
          if (!userClosedCancelSuggestionModal && res.data.percentage > 0
              && differenceInSeconds(new Date(), new Date(`${res.data.updated_at}Z`)) > THRESHOLD_TIME.USER_UNENROLLMENT) {
            setOpenCancelSuggestionModal(true);
          }
          batchUpdates(() => {
            setModalState(transactionStatus);
            setProgress(res?.data);
            setFilename(uploadFileName);
            setShowAlertBar(true);
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        batchUpdates(() => {
          setSnackbarObj({ open: true, message: getErrorMessage(err), severity: 'error' });
          setModalState(null);
          setShowAlertBar(false);
          setOpenStatusModal(false);
        });
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();

    return () => {
      clearTimeout(timer);
    };
  }, [pollProgress, transactionId, programSubType, history, userClosedCancelSuggestionModal]);

  const resetFileUpload = useCallback(
    (e) => {
      e.target.value = '';
    },
    [],
  );

  const generateFormData = useCallback(
    (file) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('program_id', programId);
      if (enrollmentWave?.waveId) {
        formData.append('wave_id', enrollmentWave?.waveId);
      }
      if (extendedEnterprise
        && extendedEnterprise !== DEFAULT
        && extendedEnterprise !== MCKINSEY_ACADEMY) {
        formData.append('client_id', extendedEnterprise);
        // EE selection is equivalent to single client program selection
        formData.append('is_single_client_program', true);
      } else {
        formData.append('is_single_client_program', enrollmentWave.experience_type === 'Single Client');
      }

      return formData;
    },
    [programId, extendedEnterprise, enrollmentWave],
  );

  const uploadFile = useCallback(
    async (formData) => {
      try {
        setShowAlertBar(true);
        setModalState(null);
        setSchema(modalIntitalSchema);
        const uploadResult = await uploadXlsx(programType, programSubType, formData);
        const tid = uploadResult?.data?.transaction_id;
        if (tid) {
          batchUpdates(() => {
            setTransactionId(tid);
            setPollProgress(true);
          });
        } else {
          setShowAlertBar(false); // sometimes tId comes null as response
        }
      } catch (e) {
        batchUpdates(() => {
          setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
          setShowAlertBar(false);
        });
      }
    },
    [programSubType, programType],
  );

  const resetCancellationStatus = () => {
    setCancellationRequested(false);
  };

  const applyValidationModal = useCallback((errorMessages) => {
    const errorMessageCount = errorMessages.errors?.length || 0;
    setSchema({
      component: EnrollmentError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: (
          <FormattedTypography
            prefix={`Scanning file ${filename}`}
            subHeading={`${zeroAppender(errorMessageCount)} errors found. Please make the required changes and re-upload your file.`}
          />
        ),
        primaryBtnText: 'Close',
        primaryBtnProps: { onClick: () => { setSchema(modalIntitalSchema); } },
      },
      props: errorMessages,
    });
  }, [filename]);

  const applyLimitModal = useCallback((formData) => {
    setSchema({
      component: EnrollmentUserLimitWarning,
      wrapperProps: {
        logo: <img src={UserWarning} alt="warning" width={120} height={120} />,
        heading: (
          <FormattedTypography
            prefix={`Alert! Your file has more than ${USER_MANAGEMENT_RECOMMENDED_LIMIT} users`}
          />
        ),
        primaryBtnText: 'Proceed',
        primaryBtnProps: {
          disabled: false,
          onClick: () => { uploadFile(formData); },
        },
        secondaryBtnText: 'Cancel',
        secondaryBtnProps: { onClick: () => { setSchema(modalIntitalSchema); } },
      },
      props: {},
    });
  }, [uploadFile]);

  const handleProceed = useCallback((formData) => {
    uploadFile(formData);
  }, [uploadFile]);

  const applyUploadHasErrorModal = useCallback((formData, result) => {
    setSchema({
      component: EnrollmentUploadError,
      wrapperProps: {
        logo: <img src={UserWarning} alt="warning" width={120} height={120} />,
        heading: (
          <FormattedTypography prefix={'Discrepancies found in uploaded file'} />
        ),
        contentDivider: false,
        showActionButtons: false,
      },
      props: {
        setSchema,
        handleProceed: () => { handleProceed(formData); },
        totalUsers: result?.count,
        warnings: result?.warnings || [],
        formData,
        navigateToEnrollmentCohortPage,
        navigateToClientMaster,
      },
    });
  }, [handleProceed, navigateToClientMaster, navigateToEnrollmentCohortPage]);

  const onConfirmUpload = useCallback(async (formData) => {
    let result;
    try {
      result = await validateUserEnrollmentXLSXApiCall(programType, programSubType,
        formData, programId);
    } catch (err) {
      batchUpdates(() => {
        setShowAlertBar(false);
        setSnackbarObj({ open: true, message: getErrorMessage(err), severity: 'error' });
      });
      return; // Early exit
    }
    const errorMessages = parseValidationResult(result);

    if (errorMessages.errors.length) {
      applyValidationModal(errorMessages);
      setShowAlertBar(false);
      return;
    }

    if (result.count > USER_MANAGEMENT_RECOMMENDED_LIMIT) {
      applyLimitModal(formData);
      setShowAlertBar(false);
      return;
    }

    if (result.warnings && result.warnings.length) {
      applyUploadHasErrorModal(formData, result);
      setShowAlertBar(false);
      return;
    }
    await uploadFile(formData);
  }, [applyLimitModal, applyUploadHasErrorModal, applyValidationModal, programId,
    programSubType, programType, uploadFile]);

  const onUpload = async (e) => {
    const file = e.target.files[0];
    batchUpdates(() => {
      setFilename(file?.name);
      setShowAlertBar(true);
      setProgress({ done: null, percentage: 0 });
    });
    const formData = generateFormData(file);
    resetFileUpload(e);
    resetCancellationStatus();
    await onConfirmUpload(formData);
  };

  const onDownloadSample = async () => {
    window.open(USER_MANAGEMENT_TEMPLATE, '_blank');
  };

  const onConflictModalClose = () => {
    setIsConflictErrorModalOpen(false);
  };
  const operationsPageDom = (
    <UserManagementOperationsPage
      onUpload={onUpload}
      onDownloadSample={onDownloadSample}
      getClientIdsRecord={getClientIdsRecord}
      extendedEnterprise={extendedEnterprise}
      userSelection={userSelection}
      setUserSelection={setUserSelection}
      cohortList={cohortList}
      setEnrollmentWave={setEnrollmentWave}
      enrollmentWave={enrollmentWave}
      navigateToEnrollmentCohortPage={navigateToEnrollmentCohortPage}
      isButtonDisabled={pollProgress}
      warningElement={!showAlertBar}
    />
  );

  const onCancelTransaction = useCallback(async () => cancelTransaction(transactionId), [
    transactionId,
  ]);

  const onCancelSuggestionClose = useCallback(async () => {
    await onCancelTransaction();
    setOpenCancelSuggestionModal(false);
  }, [onCancelTransaction]);

  const onViewStatus = (status) => {
    batchUpdates(() => {
      setModalState(status);
      setOpenStatusModal(true);
    });
  };

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
    history.replace(`/${ROUTE_HOME}/${COURSE_OPS}`);
  };

  const ModalComponent = schema.component ? schema.component : null;

  return (
    <div className={classes.wrapper}>
      {showAlertBar ? (
        <AlertReleaseInfo
          programSubType={programSubType}
          progress={progress}
          showElement={showAlertBar}
          progressMessage={`Processing User Enrollment file "${filename}"`}
          withErrorsMessage={`User Enrollment file "${filename}" upload was completed with errors.`}
          failedMessage={`Failed to upload user Enrollment file "${filename}". Please try again.`}
          successMessage={`User Enrollment file "${filename}" was successfully uploaded`}
          cancellingMessage="Cancelling pending user Enrollment tasks..."
          cancelledMessage={`User Enrollment file "${filename}" upload was cancelled.`}
          onViewStatus={onViewStatus}
          setShowElement={setShowAlertBar}
          pendingAlertBar={(
            <AlertBarWithAction
              variant="pending"
              percentage={null}
              labelText={`"${progress.filename}" file upload is pending. Please complete the action to successfully upload the file. `}
              actionButtonText="Complete Now"
              onActionClick={() => { onViewStatus(transaction_status); }}
              actionButtonIcon={<CloseIcon onClick={() => setShowAlertBar(false)} />}
            />
          )}
        />
      ) : null}
      {openStatusModal && done !== null ? (
        <UserEnrollmentStatusModal
          open={openStatusModal}
          onClose={() => { setOpenStatusModal(false); }}
          modalState={modalState}
          transactionId={transactionId}
          getReport={getReport}
          progressData={progress}
          onDownloadLog={onDownloadLog}
          type={programSubType}
          onCancelTransaction={onCancelTransaction}
          cancellationRequested={cancellationRequested}
          setCancellationRequested={setCancellationRequested}
          extendedEnterprise={extendedEnterprise}
          onExistingUsersUpdate={onExistingUsersUpdate}
        />
      ) : null}
      {schema.component
      && (
      <CustomUploadModal
        open={schema?.component}
        onClose={() => setSchema(modalIntitalSchema)}
        {...schema.wrapperProps}
      >
        <ModalComponent {...schema.props} />
      </CustomUploadModal>
      )}
      {operationsPageDom}
      <ConflictErrorModal open={isConflictErrorModalOpen} onClose={onConflictModalClose} />
      <ErrorModal
        open={isErrorModalOpen}
        onClose={onErrorModalClose}
        message="Could not enroll users due to some technical error."
      />
      <CancelSuggestionModal
        open={openCancelSuggestionModal}
        onCancel={onCancelSuggestionClose}
        onClose={() => {
          setUserClosedCancelSuggestionModal(true);
          setOpenCancelSuggestionModal(false);
        }}
      />
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
    </div>
  );
};

UserEnrollmentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserEnrollmentPage;
