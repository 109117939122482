import { MCKINSEY_LIGHT_BLUE, WHITE, MEDIUM_GREY } from '../../../stylesheets/colors';

export default {
  transactionLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: MEDIUM_GREY,
    padding: '10px',
  },
  transactionRow: {
    backgroundColor: MCKINSEY_LIGHT_BLUE,
    color: WHITE,
    padding: '4px',
    fontSize: '75%',
    lineHeight: '1.33em',
    verticalAlign: 'middle',
    minWidth: '45px',
  },
  transactionColumn: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    padding: '0px 10px 0px 10px',
    textTransform: 'capitalize',
    lineHeight: '1em',
    verticalAlign: 'middle',
  },
  transactionDownload: {
    alignSelf: 'flex-end',
    marginLeft: '3rem',
    cursor: 'pointer',
  },
};
