import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import AlertBarWithAction from '../common/AlertBarWithAction';
import {
  REPORT, COMPLETED_WITH_ERRORS, FAILED, CANCELLED,
  CANCELLING, PROCESSING, PENDING, AUTO_ENROLLMENT,
} from '../../constants';

const AlertReleaseInfo = ({
  progress, showElement, progressMessage, failedMessage, withErrorsMessage,
  successMessage, onViewStatus, setShowElement, showCTAForSuccess, showCTAForLoading,
  cancelledMessage, cancellingMessage, pendingAlertBar, programSubType,
}) => {
  const {
    status, transaction_status, done, percentage,
  } = progress;

  if (!done && done !== null) {
    if ([CANCELLING].includes(status || transaction_status)) {
      return (
        <AlertBarWithAction
          variant="loading-without-percentage"
          labelText={cancellingMessage}
          actionButtonText={showCTAForLoading ? 'View Status' : ''}
          onActionClick={() => onViewStatus(status || transaction_status)}
        />
      );
    }
    return (
      <AlertBarWithAction
        variant="with-percentage"
        percentage={!done ? percentage : null}
        labelText={progressMessage}
        actionButtonText={showCTAForLoading ? 'View Status' : ''}
        onActionClick={() => onViewStatus(status || transaction_status || PROCESSING)}
      />
    );
  }
  if (done) {
    if ([COMPLETED_WITH_ERRORS].includes(status || transaction_status)) {
      return (
        <>
          {showElement ? (
            <AlertBarWithAction
              variant="error"
              percentage={null}
              labelText={withErrorsMessage}
              actionButtonText="View Status"
              onActionClick={() => onViewStatus(REPORT)}
              actionButtonIcon={<CloseIcon onClick={() => setShowElement(false)} />}
            />
          ) : null}
        </>
      );
    }
    if ([FAILED].includes(status || transaction_status)) {
      return (
        <>
          {showElement ? (
            <AlertBarWithAction
              variant="error"
              percentage={null}
              labelText={failedMessage}
              actionButtonText="View Status"
              onActionClick={() => onViewStatus(REPORT)}
              actionButtonIcon={<CloseIcon onClick={() => setShowElement(false)} />}
            />
          ) : null}
        </>
      );
    }
    if ([CANCELLED].includes(status || transaction_status)) {
      return (
        <>
          {showElement ? (
            <AlertBarWithAction
              variant="error"
              percentage={null}
              labelText={cancelledMessage}
              actionButtonText="View Status"
              onActionClick={() => onViewStatus(REPORT)}
              actionButtonIcon={<CloseIcon onClick={() => setShowElement(false)} />}
            />
          ) : null}
        </>
      );
    }
    if ([PENDING].includes(status || transaction_status) && programSubType === AUTO_ENROLLMENT) {
      return pendingAlertBar;
    }
    return (
      <>
        {showElement ? (
          <AlertBarWithAction
            variant="success"
            percentage={null}
            labelText={successMessage}
            actionButtonText={showCTAForSuccess ? 'View Status' : ''}
            onActionClick={() => onViewStatus(REPORT)}
            actionButtonIcon={<CloseIcon onClick={() => setShowElement(false)} />}
          />
        ) : null}
      </>
    );
  }
  return (
    <AlertBarWithAction
      variant="loading-without-percentage"
      labelText={progressMessage}
    />
  );
};

AlertReleaseInfo.defaultProps = {
  showCTAForSuccess: false,
  showCTAForLoading: true,
  cancelledMessage: '',
  cancellingMessage: '',
  pendingAlertBar: <> </>,
  programSubType: '',
};

AlertReleaseInfo.propTypes = {
  progress: PropTypes.object.isRequired,
  showElement: PropTypes.bool.isRequired,
  progressMessage: PropTypes.string.isRequired,
  failedMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  withErrorsMessage: PropTypes.string.isRequired,
  cancelledMessage: PropTypes.string,
  cancellingMessage: PropTypes.string,
  onViewStatus: PropTypes.func.isRequired,
  setShowElement: PropTypes.func.isRequired,
  showCTAForSuccess: PropTypes.bool,
  showCTAForLoading: PropTypes.bool,
  pendingAlertBar: PropTypes.node,
  programSubType: PropTypes.string,
};

export default AlertReleaseInfo;
