import React, { memo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';
import { LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  usersPageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  usersCount: {
    paddingRight: '1rem',
    borderRight: `1px solid ${LIGHT_MEDIUM_GREY}`,
  },
  root: {
    '& ul > li > button': {
      fontSize: '1.125rem',
    },
  },
}));

const PaginationComponent = ({
  totalUsers, currentPage, handlePageClick, pageSize,
}) => {
  const classes = useStyles();

  let lastUserCount;
  const firstUserCount = (pageSize * (currentPage - 1) + 1);
  const totalPages = Math.ceil(totalUsers / pageSize);

  lastUserCount = pageSize * currentPage;
  if (totalUsers < lastUserCount) {
    const diff = lastUserCount - totalUsers;
    lastUserCount -= diff;
  }

  return (
    <Box className={classes.usersPageWrapper}>
      <Typography variant="body1" className={classes.usersCount}>
        {firstUserCount}
        -
        {lastUserCount}
      </Typography>
      <Pagination
        className={classes.root}
        count={totalPages}
        shape="rounded"
        page={currentPage}
        onChange={(e, val) => { handlePageClick(val); }}
      />
    </Box>
  );
};

PaginationComponent.propTypes = {
  totalUsers: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default memo(PaginationComponent, (prevProps, nextProps) => (
  prevProps.currentPage === nextProps.currentPage
));
