import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import AlertWarning from '../../assets/icons/alert-red-warning.svg';
import { PALE_GREY, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  infoText: {
    color: MODERATE_DARK_GREY,
  },
  infoValue: {
    fontWeight: 900,
    paddingRight: '2rem',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const CountCard = ({
  text, value, isError,
}) => {
  const classes = useStyles();
  return (
    <Card sx={{ minWidth: 200, mr: '3rem', backgroundColor: PALE_GREY }}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="subtitle2" className={classes.infoText}>
            {text}
          </Typography>
          <Box className={classes.infoWrapper}>
            <Typography variant="subtitle1" className={classes.infoValue}>
              {value}
            </Typography>
            {isError && (
              <img src={AlertWarning} alt="alert" />
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CountCard.defaultProps = {
  isError: false,
};

CountCard.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isError: PropTypes.bool,
};

export default CountCard;
