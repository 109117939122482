import _debounce from 'lodash.debounce';

const useAsyncSelectLoad = (getData, handleRespone) => {
  const loadOptions = async (inputText, callback) => {
    const result = await getData(inputText);
    if (!result.data.success) callback([]);
    const newPrograms = handleRespone(result);
    callback(newPrograms);
  };

  const debouncedFetch = _debounce(loadOptions, 1000);

  const getOptions = (input, callback) => debouncedFetch(input, callback);

  return {
    getOptions,
  };
};

export default useAsyncSelectLoad;
