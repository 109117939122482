import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WarningIcon from '../../assets/icons/icon-yellow-alert.svg';
import {
  LIGHT_YELLOW, DARK_YELLOW, MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  bodyText: {
    padding: '0 5rem',
    color: MEDIUM_GREY,
    marginTop: '2rem',
  },
  leftSpacing: {
    fontWeight: 'bold',
    marginLeft: '.3rem',
  },
  infoSection: {
    display: 'flex',
    backgroundColor: LIGHT_YELLOW,
    padding: '1rem 1.375rem 1rem 1.5rem',
    alignItems: 'flex-start',
    margin: '2rem auto 0',
    width: '90%',
  },
  info: {
    marginLeft: '1rem',
    color: DARK_YELLOW,
    fontWeight: 'bold',
  },
});

const SetDefaultEnrollmentCohort = ({ cohortTitle, defaultCohortTitle }) => {
  const classes = useStyles();
  return (
    <Container className={classes.bodyText}>
      <Typography variant="subtitle2" align="center">{`Would you like to change the Default Enrollment Cohort from '${defaultCohortTitle}' to '${cohortTitle}'`}</Typography>
      <Box variant="subtitle2" className={classes.infoSection}>
        <img src={WarningIcon} alt="warningIcon" />
        <Typography variant="subtitle2" className={classes.info}>
          This action will update the Default Enrollment Cohort for this learning plan.
          Wherever the Enrollment Cohort Value is not provided during enrollment,
          the users would be tagged to the Default Cohort.
        </Typography>
      </Box>
    </Container>
  );
};

SetDefaultEnrollmentCohort.propTypes = {
  cohortTitle: PropTypes.string.isRequired,
  defaultCohortTitle: PropTypes.string.isRequired,
};

export default memo(SetDefaultEnrollmentCohort, (() => true));
