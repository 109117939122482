import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';

import { SWITCH_GREY, WHITE } from '../../../stylesheets/colors';

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: '2.5rem',
    height: '1.25rem',
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: '0.175rem',
    color: WHITE,
    '&$checked': {
      transform: 'translateX(1.2rem)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: '0.875rem',
    height: '0.875rem',
    boxShadow: 'none',
  },
  track: {
    borderRadius: `${1.125 / 2}rem`,
    opacity: 1,
    backgroundColor: SWITCH_GREY,
  },
  checked: {},
}))(Switch);

export default StyledSwitch;
