import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  MODERATE_LIGHT_GREY, DARK_MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  listWrapper: {
    background: MODERATE_LIGHT_GREY,
  },
  footerText: {
    color: DARK_MEDIUM_GREY,
    marginTop: '2rem',
    padding: 0,
  },
  bodyText: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
  },
  normalText: {
    fontSize: '1.125rem',
  },
});

const ActivityInitiatedInstruction = ({
  username, createDateTime,
}) => {
  const classes = useStyles();

  return (
    <Container className={classes.footerText}>
      <Typography variant="body1" align="center">
        Activity initiated by
        {' '}
        {username}
        {' '}
        on
        {' '}
        {createDateTime}
        {' '}
        is in process.
      </Typography>
      <Typography variant="body1" align="center">Please hold on a bit, we are working on it...</Typography>
    </Container>
  );
};

ActivityInitiatedInstruction.propTypes = {
  username: PropTypes.string.isRequired,
  createDateTime: PropTypes.string.isRequired,
};

export default memo(ActivityInitiatedInstruction, (() => true));
