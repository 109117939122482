import React, {
  useEffect, useState, useCallback,
} from 'react';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import CustomCreateSelect from '../common/CustomCreateSelect';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import LabledTextField from '../common/LabledTextField';
import LabeledTextArea from '../common/LabeledTextArea';
import Loading from '../common/Loading';
import {
  ERROR_RED, MODERATE_LIGHT_GREY,
  SWITCH_GREY, ACCORDION_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '0 48px 20px',
    width: '87%',
    margin: 'auto',
  },
  width100: {
    width: '85%',
    margin: '0 auto',
  },
  modalHeight: {
    minHeight: '400px',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  red: {
    color: ERROR_RED,
  },
  errorBlock: {
    display: 'flex',
  },
  rootLabelText: {
    '& > div': {
      borderRadius: 'unset',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  select_label: {
    marginBottom: '8px',
    fontSize: '1.125rem',
    fontWeight: 'normal',
  },
  highliteErrorInput: {
    '& > div': {
      border: `1px solid ${ERROR_RED}`,
    },
  },
  input: {
    width: '80%',
    margin: '8px 0',
    '& > div': {
      borderRadius: 'unset',
    },
    '& input': {
      paddingRight: '4.5rem',
      '&:disabled': {
        backgroundColor: MODERATE_LIGHT_GREY,
        opacity: 0.5,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  addOffering: {
    '&:disabled': {
      color: ACCORDION_GREY,
      boxShadow: 'none',
      backgroundColor: SWITCH_GREY,
    },
  },
}));

const AddEditPortfolioModal = ({
  open,
  onClose,
  isLoading,
  onUpdatePortfolio,
  onCreatePortfolio,
  portFolioData,
  isPortfolioEdit,
  offeringOptions,
  portfolioOptions,
  getPortfolioOfferingsList,
}) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});
  const [drodownLoading, setDropdownLoading] = useState(false);
  const [offeringValues, setOfferingValues] = useState([]);
  const formType = isPortfolioEdit ? 'edit' : 'add';
  const portfolioForm = {
    add: {
      heading: 'Add/Create Portfolio and Offering Information',
      subHeading: 'Add/Create a Portfolio and Offerings information for Learning Programs',
    },
    edit: {
      heading: 'Edit Offering Information',
      subHeading: 'Edit the Offering information for Learning Programs',
    },
  };

  useEffect(() => {
    if (!isLoading) {
      setOfferingValues(offeringOptions);
      setDropdownLoading(false);
    }
  }, [isLoading, offeringOptions]);

  const prepareFormValues = useCallback(() => ({
    portfolio: portFolioData?.portfolio ?? '',
    offering: portFolioData?.offering ?? '',
    program: portFolioData?.program ?? '',
    program_code: portFolioData?.program_code ?? '',
    notes: portFolioData?.notes ?? '',
  }), [portFolioData]);

  useEffect(() => {
    setFormValues(prepareFormValues());
  }, [prepareFormValues]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formValues,
    onSubmit: (values) => {
      const finalValues = {
        portfolio: values.portfolio,
        offering: values.offering,
        program: values.program,
        program_code: values.program_code,
        notes: values.notes,
      };
      if (isPortfolioEdit) {
        onUpdatePortfolio({ ...finalValues, id: portFolioData.id });
      } else {
        onCreatePortfolio({ ...finalValues });
      }
    },
  });

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    resetForm,
    errors,
  } = formik;
  useEffect(() => {
    setValues(formValues);
  }, [formValues, setValues]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  const onSelectChange = (e, key) => {
    // eslint-disable-next-line no-underscore-dangle
    if (e && !e.__isNew__ && key === 'portfolio') {
      getPortfolioOfferingsList({ [key]: e.value });
      setDropdownLoading(true);
    }
    if (!e && key === 'portfolio') {
      setOfferingValues([]);
      setFieldValue('offering', '');
      setFieldValue('portfolio', '');
    } else {
      setFieldValue(key, e?.value);
    }
  };

  return (
    <ConfirmationModal
      open={open}
      isContainForm
      onClose={onClose}
      onSubmit={handleSubmit}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      modalWidth={80}
      title={(
        <FormattedTypography
          variant="subtitle1"
          subheadingVariant="body1"
          body={portfolioForm[formType].heading}
          subHeading={portfolioForm[formType].subHeading}
          suffixClassName={classes.bold}
        />
          )}
      content={
            isLoading && !drodownLoading ? (
              <Loading />
            ) : (
              <Grid container justifyContent="space-between" className={classes.modalWrapper}>
                <Grid item xs={12}>
                  <CustomCreateSelect
                    name="portfolio"
                    options={portfolioOptions}
                    className={`${classes.fieldPadding} ${classes.select_label}`}
                    placeholder="Select/Add a portfolio"
                    value={values.portfolio ? [{ value: values.portfolio, label: values.portfolio }] : ''}
                    disabled={!!isPortfolioEdit}
                    type="select"
                    isClearable
                    label={(
                      <div className={`${classes.label} ${classes.select_label}`}>
                        <Typography>Portfolio</Typography>
                        <span className={classes.red}>*</span>
                      </div>
                    )}
                    onChange={(e) => onSelectChange(e, 'portfolio')}
                    isOptionsNotLoading
                  />
                  <Box className={classes.errorBlock}>
                    {errors.portfolio && (
                      <Typography className={classes.red}>{errors.portfolio}</Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <CustomCreateSelect
                    name="offering"
                    options={offeringValues}
                    className={`${classes.fieldPadding} ${classes.select_label}`}
                    value={values.offering ? [{ value: values.offering, label: values.offering }] : ''}
                    placeholder="Select/Add an offering"
                    type="select"
                    isClearable
                    label={(
                      <div className={`${classes.label} ${classes.select_label}`}>
                        <Typography>Offerings</Typography>
                        <span className={classes.red}>*</span>
                      </div>
                    )}
                    onChange={(e) => onSelectChange(e, 'offering')}
                    optionLoading={drodownLoading}
                  />
                  <Box className={classes.errorBlock}>
                    {errors.offering && (
                      <Typography className={classes.red}>{errors.offering}</Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LabledTextField
                    label="Program"
                    className={classes.fieldPadding}
                    placeholder="Enter program name"
                    inputProps={{
                      name: 'program',
                      classes: { root: classes.rootLabelText },
                    }}
                    value={values.program || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LabledTextField
                    label={(
                      <Box className={classes.label}>
                        <Typography>Program Code</Typography>
                        <span className={classes.red}>*</span>
                      </Box>
                    )}
                    placeholder="Enter program code"
                    className={classes.fieldPadding}
                    inputProps={{
                      name: 'program_code',
                      classes: {
                        root: `${classes.rootLabelText} ${(errors.program_code)
                          ? ` ${classes.highliteErrorInput}`
                          : ''
                        }`,
                      },
                    }}
                    value={values.program_code || ''}
                    onChange={handleChange}
                  />
                  <Box className={classes.errorBlock}>
                    {errors.program_code && (
                      <Typography className={classes.red}>{errors.program_code}</Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LabeledTextArea
                    name="notes"
                    label="Notes"
                    value={values.notes || ''}
                    onChange={handleChange}
                    className={classes.fieldPadding}
                    placeholder="Enter notes (if any)"
                    inputProps={{
                      name: 'notes',
                      classes: { root: classes.rootLabelText },
                    }}
                  />
                </Grid>
              </Grid>
            )
          }
      actions={(
        <>
          <Button className={classes.cancelModal} color="primary" variant="outlined" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button className={classes.addOffering} color="primary" disabled={!values.portfolio || !values.offering || !values.program_code} variant="contained" type="submit">
            Save
          </Button>
        </>
          )}
    />
  );
};

export default AddEditPortfolioModal;

AddEditPortfolioModal.defaultProps = {
  portFolioData: {},
};

AddEditPortfolioModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdatePortfolio: PropTypes.func.isRequired,
  onCreatePortfolio: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  portFolioData: PropTypes.shape({
    id: PropTypes.number,
    portfolio: PropTypes.string,
    offering: PropTypes.string,
    program: PropTypes.string,
    program_code: PropTypes.string,
    notes: PropTypes.string,
  }),
  isPortfolioEdit: PropTypes.bool.isRequired,
  offeringOptions: PropTypes.array.isRequired,
  portfolioOptions: PropTypes.array.isRequired,
  getPortfolioOfferingsList: PropTypes.func.isRequired,
};
