import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import { BLACK } from '../../../../../stylesheets/colors';
import PageInfo from './pageInfo';
import PromptOverviewBox from './PromptOverviewBox';
import AddPromptModal from './AddPromptModal';
import { handlePromptCreteAndEdit, handlePromptDelete } from './utils';
import DeletePromptModal from './PageDetail/DeletePromptModal';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: '1.5rem',
    '& hr': {
      marginBottom: '1.2rem',
    },
  },
  promptsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  emptyStateWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '8rem',

    '& > p': {
      color: BLACK,
      fontSize: '1.5rem',
      fontWeight: 600,
    },

    '& > button': {
      marginLeft: '1.25rem',
      fontSize: '1rem',
      height: '2.5rem',
    },
  },
  reviewPageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '8rem',
    textAlign: 'center',
    justifyContent: 'center',

    '& > p:nth-child(1)': {
      fontWeight: 600,
      marginBottom: '0.625rem',
    },
    '& > p:nth-child(2)': {
      lineHeight: '1.5rem',
    },
  },
}));

const PageOverview = ({
  pages, setSnackbarObj, journal_id, handlePageEdit, is_locked,
  handlePageDelete, selectedPage, setSelectedPage, fetchPages, setAlertBarConfig,
}) => {
  const classes = useStyles({});
  const [showAddPromptModal, setShowAddPromptModal] = useState(false);
  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);
  const [savedPromptValues, setSavedPromptValues] = useState({});
  const [selectedPrompt, setSelectedPrompt] = useState({});
  const history = useHistory();
  const { programType } = useParams();

  const {
    id: pageId, journal_prompts: prompts, page_id,
  } = selectedPage;

  const emptyPromptsState = (page) => (
    <Paper className={classes.emptyStateWrapper} square>
      <Typography>
        This page has no prompts yet.
      </Typography>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={() => {
          setSelectedPage(page);
          setShowAddPromptModal(true);
        }}
        disabled={is_locked}
      >
        Start Adding Prompts
      </Button>
    </Paper>
  );

  const getReviewPageText = () => (
    <Paper className={classes.reviewPageWrapper} square>
      <Typography variant="subtitle1" component="p">
        This is a review page.
      </Typography>
      <Typography variant="h3" component="p">
        Prompts cannot be added to a review page.
        Participants will be able to review all of their responses
        to prompts within this journal on this page.
        <br />
        To restore previously added prompts, revert the page type back to Standard
      </Typography>
    </Paper>
  );

  const onPromptModalClose = () => {
    batchUpdates(() => {
      setShowAddPromptModal(false);
      setSavedPromptValues({});
      setSelectedPrompt({});
    });
  };

  const handleOnOk = async (values) => {
    handlePromptCreteAndEdit({
      setSnackbarObj,
      setAlertBarConfig,
      values,
      savedPromptValues,
      selectedPrompt,
      pageId,
      prompts,
      onPromptModalClose,
      cbFunction: () => {},
      history,
      programType,
      allowRedirect: true,
    });
  };

  const onDeletePrompt = async ({ promptId }) => {
    setShowDeletePromptModal(false);
    handlePromptDelete({
      promptId,
      setSnackbarObj,
      setAlertBarConfig,
      cbFunction: fetchPages,
    });
  };

  const onClickDeletePrompt = (selectedPromptObj, selectedPageObj) => {
    setSelectedPrompt(selectedPromptObj);
    setSelectedPage(selectedPageObj);
    setShowDeletePromptModal(true);
  };

  const onCloseDeletePromptModal = () => {
    setSelectedPrompt({});
    setSelectedPage({});
    setShowDeletePromptModal(false);
  };

  const onEditPrompt = async (selectedPromptObj, selectedPageObj) => {
    const { prompt: promptText, is_required } = selectedPromptObj;
    const filledFormData = {
      promptText,
      promptType: is_required ? 'mandatory' : 'optional',
    };
    setSelectedPrompt(selectedPromptObj);
    setSelectedPage(selectedPageObj);
    setSavedPromptValues(filledFormData);
    setShowAddPromptModal(true);
  };

  return (
    <Box className={classes.wrapper} square>
      { showDeletePromptModal && (
      <DeletePromptModal
        onClose={onCloseDeletePromptModal}
        onConfirm={onDeletePrompt}
        prompt={selectedPrompt}
        pageId={page_id}
      />
      )}
      {showAddPromptModal && (
      <AddPromptModal
        open={showAddPromptModal}
        onClose={() => { onPromptModalClose(); }}
        onOk={handleOnOk}
        selectedPage={selectedPage}
        journal_id={journal_id}
        savedPromptValues={savedPromptValues}
        prompt_id={selectedPrompt?.prompt_id}
      />
      )}
      {
        pages.map((page) => {
          const { id } = page;
          return (
            <Box key={id}>
              <Divider variant="fullWidth" light />
              <PageInfo
                key={id}
                page={page}
                handlePageDelete={handlePageDelete}
                handlePageEdit={handlePageEdit}
                allowRedirection={page.journal_type !== 'review'}
                is_locked={is_locked}
              />
              {
                // eslint-disable-next-line no-nested-ternary
              page.journal_type === 'review' ? getReviewPageText() : !page.journal_prompts.length ? emptyPromptsState(page) : (
                <Box className={classes.promptsWrapper}>
                  {
                    page.journal_prompts.length
                  && page.journal_prompts.slice(0, 4).map((prompt) => {
                    const { id: promptId } = prompt;
                    return (
                      <PromptOverviewBox
                        is_locked={is_locked}
                        prompt={prompt}
                        key={promptId}
                        onDeletePrompt={(promptObj) => { onClickDeletePrompt(promptObj, page); }}
                        onEditPrompt={(promptObj) => { onEditPrompt(promptObj, page); }}
                      />
                    );
                  })
}
                </Box>
              )
            }
            </Box>
          );
        })
      }
    </Box>
  );
};

PageOverview.propTypes = {
  pages: PropTypes.array.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  journal_id: PropTypes.string.isRequired,
  handlePageEdit: PropTypes.func.isRequired,
  handlePageDelete: PropTypes.func.isRequired,
  selectedPage: PropTypes.object.isRequired,
  setSelectedPage: PropTypes.func.isRequired,
  fetchPages: PropTypes.func.isRequired,
  setAlertBarConfig: PropTypes.func.isRequired,
  is_locked: PropTypes.bool.isRequired,
};

export default PageOverview;
