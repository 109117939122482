import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import Typography from '@material-ui/core/Typography/Typography';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@mui/material/Paper';
import { cloneDeep } from 'lodash/lang';
import _find from 'lodash/find';
import { isEmpty } from 'lodash';
import LockedIcon from '../../../../assets/icons/lock-shape.svg';
import FormattedTypography from '../../../common/FormattedTypography';
import CustomModal from '../../../common/CustomModal';
import {
  ACCORDION_GREY, LIGHT_MEDIUM_GREY, MCKINSEY_BLUE,
  MEDIUM_GREY, MODERATE_DARK_GREY, SWITCH_GREY, WHITE,
} from '../../../../stylesheets/colors';
import LtiModalComponents from '../../LtiModalComponents';
import { JOURNAL_TYPE_OPTIONS } from '../../../../constants';
import CustomRadio from '../../../UserEnrollment/common/CustomRadio';
import { getComponents } from '../../../../containers/ContentLibrary/api';
import Loading from '../../../common/Loading';
import { getErrorMessage } from '../../../../helpers/apiHelper';
import WarningInfoAlert from '../../../common/WarningInfoAlert';
import commonStyles from '../../../../stylesheets/common.styles';

const { optionTitle, optionSubtitle, dropdownOptions } = commonStyles;

const useStyles = makeStyles(() => ({
  modalStyles: {
    maxWidth: '73rem',
  },
  dialogContentClass: {
    padding: '0 13rem 0.5rem 13rem',
    width: '100%',
    '& > div': {
      marginBottom: '2rem',
    },

    '& > div:last-child': {
      marginBottom: '1rem',
    },

    '& > hr': {
      marginBottom: '1.25rem',
    },
  },
  modalHeader: {
    margin: '0 auto',
    width: '100%',
    maxHeight: '50vh',
    overflow: 'auto',
    alignItems: 'center',

    '& span': {
      textAlign: 'center',
    },
  },
  divider: {
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',

    '& > button:nth-child(1)': {
      width: '11rem',

      '&:hover': {
        backgroundColor: MCKINSEY_BLUE,
        color: WHITE,
        opacity: 0.6,
      },

      '&  > span': {
        fontSize: '1rem',
      },
    },
    '& > button:nth-child(2)': {
      width: '11rem',
      marginLeft: '1.25rem',

      '&:disabled': {
        backgroundColor: SWITCH_GREY,
        color: ACCORDION_GREY,
      },
      '&:hover': {
        backgroundColor: MCKINSEY_BLUE,
        color: WHITE,
        opacity: 0.6,
      },

      '&  > span': {
        fontSize: '1rem',
        fontWeight: 600,
      },
    },
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  customModalTitle: {
    margin: '1rem 0 0 0 ',
  },
  subHeading: {
    color: MEDIUM_GREY,
    fontSize: '1.125rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    fontSize: '1rem',
    fontWeight: 600,
    color: MODERATE_DARK_GREY,
    marginBottom: '1rem',
  },
  journalTypeLabel: {
    fontSize: '1rem',
    fontWeight: 600,
    color: MODERATE_DARK_GREY,
  },
  formWrapper: {
    width: '100%',
  },
  linkedLp: {
    display: 'flex',
    marginTop: '1rem',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '1.25rem',
    },
  },
  title: {
    fontSize: '0.875rem',
    color: ACCORDION_GREY,
  },
  value: {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginLeft: '2px',
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  radioOptions: {
    fontSize: ' 1rem',
  },
  dataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      padding: '1.25rem',
      width: '100%',
      '& > div': {
        display: 'flex',
        '& > div': {
          width: '50%',
        },

      },
      '& > div:nth-child(2)': {
        marginTop: '0.75rem',
      },
    },
  },
  optionTitle,
  optionSubtitle,
  dropdownOptions,
}
));

const defaultData = {
  selectedComponent: '',
  pageTitle: '',
  pageId: '',
  pageDesc: '',
  journalType: 'standard',
};

const AddPageModal = ({
  open, onClose, onOk, journalInfo, setSnackbarObj,
  savedValues, pageTitle: originalPageTitle, standardPagesLength,
}) => {
  const classes = useStyles();
  const {
    program_name,
    learning_plan_code,
    learning_plan_id,
    journalId,
    journal_id,
  } = journalInfo;

  const [isLoading, setIsLoading] = useState(true);
  const [components, setComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState({});
  const { selectedComponent: selectedComponentId } = savedValues;
  const isEditView = !isEmpty(savedValues);
  const { title: cmpTitle, code: cmpCode } = selectedComponent;

  const formik = useFormik({
    initialValues: !isEmpty(savedValues) ? { ...savedValues } : { ...defaultData },
    onSubmit: (values) => {
      onOk(values);
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit, handleChange, values, resetForm, setFieldValue,
  } = formik;

  const getTextColor = useCallback(({ isOptionDisabled }) => {
    let textColor;
    if (isOptionDisabled) {
      textColor = LIGHT_MEDIUM_GREY;
    }
    return textColor;
  }, []);

  const modifyComponents = useCallback((data) => {
    const updatedComponents = cloneDeep(data);
    updatedComponents.forEach((obj) => {
      const {
        is_exisiting_page, course_sequence_num_in_lp, title,
        code, id, page_id, course_type,
      } = obj;
      const isOptionDisabled = course_type !== 'elearning' || is_exisiting_page;
      const textColor = getTextColor({ isOptionDisabled });
      obj.label = (
        <Box className={classes.dropdownOptions} data-testid="select-option">
          <Typography className={classes.optionTitle} style={{ color: textColor }}>{`${(course_sequence_num_in_lp).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}: ${title}`}</Typography>
          <Typography className={classes.optionSubtitle} style={{ color: textColor }}>
            {code}
          </Typography>
        </Box>
      );
      obj.value = id;
      obj.id = page_id;
      obj.isDisabled = isOptionDisabled;
    });

    setComponents(updatedComponents);
  }, [classes.dropdownOptions, classes.optionSubtitle, classes.optionTitle, getTextColor]);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  useEffect(() => {
    setIsLoading(true);
    async function getComp() {
      try {
        const response = await getComponents(journalId);
        const resp = response?.data?.data?.components;
        modifyComponents(resp);
        if (selectedComponentId) {
          const selectedCompObj = _find(resp, { id: selectedComponentId });
          setSelectedComponent(selectedCompObj);
        }
      } catch (e) {
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        setIsLoading(false);
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
    getComp();
  }, [selectedComponentId, journalId, modifyComponents, setSnackbarObj]);

  const checkFormDisabled = () => {
    const {
      selectedComponent: selectedCompObj, pageTitle, pageId, pageDesc, journalType,
    } = values;
    return !(selectedCompObj && pageTitle && pageId && pageDesc && journalType);
  };

  const handleComponentChange = (e, value) => {
    setFieldValue(e, value);
    const selectedComponentObj = _find(components, { value });
    setFieldValue('pageId', selectedComponentObj?.page_id);
    setSelectedComponent(selectedComponentObj);
  };
  const showStandardPageValidation = (!standardPagesLength && !isEditView)
      || (standardPagesLength === 1 && isEditView && savedValues.journalType === 'standard');

  return (
    <CustomModal open={open} onClose={handleClose} modalStyle={classes.modalStyles}>
      {isLoading ? <Loading />
        : (
          <form onSubmit={handleSubmit} className={classes.formWrapper}>
            <DialogTitle disableTypography className={classes.modalHeader}>
              <FormattedTypography
                body={isEditView ? `Edit ${originalPageTitle} of ${journal_id}` : 'Add a new Page to'}
                dense
                className={classes.heading}
                containerClassName={classes.customModalTitle}
              />
              {!isEditView && (
              <FormattedTypography
                body={`${journal_id}`}
                className={classes.heading}
              />
              )}
              <FormattedTypography
                body={isEditView ? 'Edit the page details and settings here. The page will be saved and updated.' : 'Set up your page details and settings here, add prompts to this page when you’re ready.'}
                dense
                className={classes.subHeading}
              />
            </DialogTitle>
            <Divider variant="fullWidth" className={classes.divider} light />
            <DialogContent className={`${classes.dialogContentClass}`}>
              <Typography className={classes.label}>
                Journal Details
              </Typography>
              <Paper className={classes.dataWrapper} square elevation={1}>
                <Box>
                  <Box>
                    <LtiModalComponents inBox label="Linked LP:" value={program_name} />
                    <LtiModalComponents inBox label="No. of Components:" value={components.length} />
                  </Box>
                  <Box>
                    <LtiModalComponents inBox label="Config ID:" value={learning_plan_code} isId />
                    <LtiModalComponents inBox label="Docebo Id:" value={learning_plan_id} />
                  </Box>
                </Box>
              </Paper>
              <Divider variant="fullWidth" light />
              {isEditView ? (
                <>
                  <Typography className={classes.label}>
                    Linked Component
                  </Typography>
                  <Paper className={classes.dataWrapper} square elevation={1}>
                    <Box>
                      <Box>
                        <LtiModalComponents inBox label="Component Name:" value={cmpTitle} />
                      </Box>
                      <Box>
                        <LtiModalComponents inBox label="Component Code:" value={cmpCode} />
                      </Box>
                    </Box>
                  </Paper>
                </>
              ) : (
                <LtiModalComponents
                  label="Select Component"
                  value={values.selectedComponent}
                  isEdit
                  name="selectedComponent"
                  options={components}
                  fieldType="dropdown"
                  handleChange={handleComponentChange}
                  placeHolder="Select component"
                  isRequired
                />
              )}
              <LtiModalComponents
                label="Page Title"
                placeHolder="Enter title here"
                name="pageTitle"
                value={values.pageTitle}
                isRequired
                isEdit
                fieldType="text"
                handleChange={handleChange}
                charLimit={150}
              />
              <LtiModalComponents
                inputFieldIcon={<img src={LockedIcon} alt="Locked" />}
                label="Page ID"
                name="pageId"
                value={values.pageId}
                isEdit
                fieldType="text"
                handleChange={() => {
                }}
              />
              <LtiModalComponents
                label="Page Description"
                placeHolder="Enter the page description here"
                value={values.pageDesc}
                isRequired
                fieldType="textarea"
                isEdit
                name="pageDesc"
                handleChange={handleChange}
                charLimit={200}
              />
              <Divider variant="fullWidth" light />
              <LtiModalComponents
                label="Page Type"
                isRequired
                fieldType="custom"
              >
                <RadioGroup
                  row
                  className={classes.radioGroup}
                  aria-label="channel"
                  name="journalType"
                  value={values.journalType}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  {JOURNAL_TYPE_OPTIONS.map(
                    ({
                      title, value,
                    }) => (
                      <FormControlLabel
                        data-testid={`checkbox_${value}`}
                        key={value}
                        value={value}
                        control={<CustomRadio />}
                        label={<span className={classes.radioOptions}>{title}</span>}
                        disabled={value === 'review' && showStandardPageValidation}
                      />
                    ),
                  )}
                </RadioGroup>
                {values.journalType === 'review' || showStandardPageValidation ? (
                  <WarningInfoAlert severity={showStandardPageValidation ? 'warning' : 'info'}>
                    {showStandardPageValidation ? 'There must be at least one standard page in this journal to create a review page' : 'Review pages display all prompts and responses in the journal. No new prompts can be added to this page.'}
                  </WarningInfoAlert>
                ) : null}
              </LtiModalComponents>
            </DialogContent>
            <Divider variant="fullWidth" className={classes.divider} light />
            <DialogActions className={classes.centerAction}>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={checkFormDisabled()}
                onClick={() => {
                  onOk(values, selectedComponent);
                }}
              >
                Save and Publish
              </Button>
            </DialogActions>
          </form>
        )}
    </CustomModal>
  );
};

AddPageModal.defaultProps = {
  pageTitle: '',
};

AddPageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  journalInfo: PropTypes.object.isRequired,
  savedValues: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  standardPagesLength: PropTypes.number.isRequired,

};

export default AddPageModal;
