/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import { Button, Typography } from '@material-ui/core';
import _remove from 'lodash/remove';
import _map from 'lodash/map';
import _last from 'lodash/last';
import _has from 'lodash/has';

import { ContentDetailsLabel } from '../ContentManagement/ContentDetailsSection';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import TableRC from '../common/TableRC';
import Select from '../common/SelectMui';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import {
  SCORED_ASSESSMENT_OPTIONS, ROUTE_REPORT, OVERVIEW, CONTENT_MANAGEMENT,
} from '../../constants';
import AsyncSelect from '../CreateTestUsers/AsyncSelect';
import NonElucidatDetails from './NonElucidatDetails';
import DeleteIcon from '../../assets/icons/trash.svg';
import { reportPageDateTimeFormatter } from '../../helpers/formattingHelpers';
import DeleteConfirmationSocrm from './DeleteConfirmationSocrm';
import { MCKINSEY_BLUE, FILL_ERROR_RED } from '../../stylesheets/colors';

const ScormDetails = ({
  values, customLabel, isLoading, setFieldValue, isEdit, dividerStyle, programType,
  configId, linkToLp, setLinkToLp, coursePath, formikRef, nonElucidatLoading, programId,
}) => {
  const [openConfirmation, setConfirmatin] = useState(false);
  const [confirmDeleteRow, setConfirmDeleteRow] = useState({});

  const isExist = (key) => (typeof key !== 'string' || key === '');

  const transformedData = _map(values?.elucidatDetails, (obj, index) => ({
    ...obj,
    project_id: <Typography variant="subtitle2" style={isExist(obj?.project_id) ? { color: FILL_ERROR_RED } : {}}>{obj?.project_id ? obj?.project_id : 'Details missing'}</Typography>,
    release_name: <Typography variant="subtitle2" style={isExist(obj?.release_name) ? { color: FILL_ERROR_RED } : {}}>{obj?.release_name ? obj?.release_name : 'Details missing'}</Typography>,
    release_version: <Typography variant="subtitle2" style={isExist(obj?.release_version) ? { color: FILL_ERROR_RED } : {}}>
      {obj?.release_version ? (
        <Typography variant="subtitle2">
          Version
          {' '}
          {obj.release_version}
          {' '}
          (
          {obj.release_id}
          )
          created
          {' '}
          {reportPageDateTimeFormatter(new Date(obj.last_released))}
        </Typography>
      ) : 'Details missing'}
    </Typography>,
    project_link: obj?.project_link && (<a target="_blank" rel="noopener noreferrer" href={obj.project_link || '#'} style={{ fontSize: '1.25rem', color: MCKINSEY_BLUE }}>{obj.project_link}</a>),
    parent_project: obj?.parent_project ? (<a target="_blank" rel="noopener noreferrer" href={obj.parent_project || '#'} style={{ fontSize: '1.25rem', color: MCKINSEY_BLUE }}>{obj.parent_project}</a>) : <Typography variant="subtitle2">N/A</Typography>,
    scored_assessment: isEdit ? (
      <Select
        name="select"
        defaultValue={SCORED_ASSESSMENT_OPTIONS.find((el) => el.value === obj?.scored_assessment)}
        options={SCORED_ASSESSMENT_OPTIONS}
        placeholder="Select..."
        type="select"
        isOptionsNotLoading
        onChange={(v) => {
          setFieldValue(`elucidatDetails[${index}].scored_assessment`, v?.value, true);
        }}
      />
    ) : obj?.scored_assessment,
  }));

  useEffect(() => {
    if (values.link_to_lps) {
      const lpConfigData = _map(values?.link_to_lps, (obj) => ({
        ...obj,
        lp_config_id: obj?.config_id,
        config_id: <Typography variant="subtitle2">{obj?.config_id}</Typography>,
        lp_link: <a target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.25rem', color: MCKINSEY_BLUE }} href={`${ROUTE_REPORT}/${obj?.id}/${coursePath}/${OVERVIEW}`}>{`${window.location.origin}/${ROUTE_REPORT}/${obj?.id}/${coursePath}/${OVERVIEW}`}</a>,
      }));
      setLinkToLp(lpConfigData);
    }
  }, [values, setLinkToLp, coursePath]);

  const updateLpLink = (value) => {
    setLinkToLp([...linkToLp, {
      lp_config_id: value?.value,
      config_id: <Typography variant="subtitle2">{value?.value}</Typography>,
      id: value?.program_id,
      lp_link: <a target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.25rem', color: MCKINSEY_BLUE }} href={`${ROUTE_REPORT}/${value?.program_id}/${coursePath}/${OVERVIEW}`}>{`${window.location.origin}/${ROUTE_REPORT}/${value?.program_id}/${coursePath}/${OVERVIEW}`}</a>,
    }]);
  };

  const addNewRow = useCallback(() => {
    const lastRow = _last(linkToLp);
    if (!lastRow || _has(lastRow, 'id')) {
      const newData = [...linkToLp, {
        config_id: <AsyncSelect
          placeholder="Select Program"
          menuPosition="absolute"
          programType={programType}
          onChange={(value) => updateLpLink(value)}
          configId={configId}
          defaultOptions={false}
          disabledLP={_map(linkToLp, 'lp_config_id') || []}
          isMulti={false}
        />,
      }];
      setLinkToLp([...newData]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configId, programType, updateLpLink]);

  const deleteRow = () => {
    const updatedData = _remove(linkToLp, (obj) => {
      if (_has(obj, 'id')) {
        return obj.id !== confirmDeleteRow.id;
      }
      return false;
    });
    setConfirmatin(false);
    setLinkToLp(updatedData);
  };

  const confirmDelete = (row) => {
    if (row?.lp_config_id) {
      setConfirmatin(true);
      setConfirmDeleteRow(row);
    } else {
      deleteRow();
    }
  };

  return (
    <ContentDetailsBlock headerText="SCORM Details" noPaper>
      <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
        <ContentDetailsLabel
          label="Elucidat Details"
          labelCustomCss={customLabel}
          fieldRedirectOnLabel
          fieldRedirectLink={`/${ROUTE_REPORT}/${programId}/${coursePath}/${CONTENT_MANAGEMENT}`}
        />
      </Grid>
      <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
        {
          isLoading ? (
            <LoadingCircle labelText="Loading Elucidat Details" />
          ) : (
            <TableRC
              tableStyle={isEdit ? { paddingBottom: '6rem' } : {}}
              columns={[
                { title: 'Component Title', property: 'component_title' },
                { title: 'Project Title', property: 'project_title' },
                { title: 'Project ID', property: 'project_id' },
                { title: 'Project Link', property: 'project_link' },
                { title: 'Release Name', property: 'release_name' },
                { title: 'Release Version', property: 'release_version', width: '15%' },
                { title: 'Parent Project', property: 'parent_project' },
                { title: 'Scored Assessment', property: 'scored_assessment', width: '13%' },
              ]}
              data={transformedData}
              noDataMessage="Elucidat details unavailable for this learning plan."
            />
          )
        }
      </Grid>
      <Divider className={dividerStyle} variant="middle" light />
      <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
        <ContentDetailsLabel
          label="Links to other LPs using shared Elucidat projects"
          labelCustomCss={customLabel}
        />
      </Grid>
      <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
        <TableRC
          columns={[
            { title: 'LP Config ID', property: 'config_id', width: '50%' },
            { title: 'Link', property: 'lp_link' },
          ]}
          data={linkToLp}
          noDataMessage="Links to other other LPs using shared projects unavailable for this learning plan. Click on “Add Link” to add links for respective learning plan config ID."
          actions={isEdit && [{ name: 'Delete', icon: <img src={DeleteIcon} alt="del" />, onClick: (event, rowData) => confirmDelete(rowData) }]}
        />
        {isEdit && (
          <Button color="primary" variant="contained" style={{ margin: '2rem 0' }} onClick={addNewRow}>
            Add New
          </Button>
        )}
      </Grid>
      <Divider className={dividerStyle} variant="middle" light />
      <NonElucidatDetails
        formValues={values}
        isLoading={nonElucidatLoading}
        formikRef={formikRef}
        isEdit={isEdit}
        setFieldValue={setFieldValue}
        customLabel={customLabel}
      />
      {openConfirmation && confirmDeleteRow?.lp_config_id
        && (
          <DeleteConfirmationSocrm
            open={openConfirmation}
            onClose={() => setConfirmatin(false)}
            onDelete={deleteRow}
            headingFirst="Deletion would remove the details for LP config ID"
            headingSecond={`"${confirmDeleteRow?.lp_config_id}" along with associated link. Proceed?`}
          />
        )}
    </ContentDetailsBlock>
  );
};

ScormDetails.propTypes = {
  customLabel: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  dividerStyle: PropTypes.string.isRequired,
  programType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  linkToLp: PropTypes.array.isRequired,
  setLinkToLp: PropTypes.func.isRequired,
  coursePath: PropTypes.string.isRequired,
  formikRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  nonElucidatLoading: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
};

export default ScormDetails;
