import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LIGHT_MEDIUM_GREY, REPORT_ERROR_RED, MCKINSEY_BLUE } from '../../stylesheets/colors';
import theme from '../../stylesheets/theme';
import MCKINSEY_SANS from '../../stylesheets/fonts';
import { getOptionsBgColor } from '../../helpers/utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  labelWrapper: ({ required }) => ({
    display: 'flex',
    flex: 0.25,
    fontWeight: 'bold',
    alignItems: 'flex-end',
    '&::after': {
      content: required ? "'*'" : "''",
      color: REPORT_ERROR_RED,
    },
  }),
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    maxHeight: '2.5rem',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  CustomOption: {
    backgroundColor: 'transparent',
    color: MCKINSEY_BLUE,
    cursor: 'pointer',
    padding: '8px 12px',
    width: '100%',
    textDecoration: 'underline',
  },
}));

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: '32px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: 'unset',
    fontFamily: MCKINSEY_SANS,
    fontSize: '1.125rem',

    'p, span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '1px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (provided, { isDisabled, isFocused, isSelected }) => (
    {
      ...provided,
      backgroundColor: getOptionsBgColor({ isDisabled, isFocused, isSelected }),
      cursor: isDisabled ? 'not-allowed' : 'default',
    }
  ),
};

const CustomCreateSelect = ({
  label, placeholder, options, onChange, maxMenuHeight,
  menuPlacement, name, className, selectRef, disabled, required, labelClass,
  isSearchable, isOptionsNotLoading, isClearable, value, optionLoading,
}) => {
  const classes = useStyles({ required });
  const isLoading = isOptionsNotLoading ? false : optionLoading;
  return (
    <div className={`${classes.wrapper} ${className}`}>
      {typeof label === 'string' || typeof label === 'number' ? (
        <Typography variant="body1" className={`${classes.labelWrapper} ${labelClass}`}>
          {label}
        </Typography>
      ) : label}
      <CreatableSelect
        ref={selectRef}
        styles={selectStyles}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isLoading={isLoading}
        name={name.length > 0 ? name : label}
        options={options}
        isClearable={isClearable}
        classNamePrefix="react-select"
        onChange={onChange}
        value={value}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        isOptionDisabled={(option) => option.isdisabled}
        noOptionsMessage={() => 'No Options Found'}
      />
    </div>
  );
};

CustomCreateSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selectRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  required: PropTypes.bool,
  labelClass: PropTypes.string,
  isSearchable: PropTypes.bool,
  isOptionsNotLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  optionLoading: PropTypes.bool,
};

CustomCreateSelect.defaultProps = {
  label: null,
  value: [] || '',
  maxMenuHeight: 300,
  menuPlacement: 'bottom',
  name: '',
  className: '',
  disabled: false,
  required: false,
  selectRef: null,
  labelClass: '',
  isSearchable: true,
  isOptionsNotLoading: false,
  isClearable: false,
  optionLoading: false,
};

export default CustomCreateSelect;
