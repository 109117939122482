import React, { useState, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Table, TableContainer, TableBody, TableRow, TableCell,
} from '@mui/material';
import Typography from '@material-ui/core/Typography';
import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import EnhancedTableHeader from '../Table/EnhancedTableHeader';
import LoadMoreComponent from '../common/LoadMore';
import { tableSort, getComparator } from '../../helpers/utils';
import {
  MCKINSEY_BLUE,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
    },
    '& th.MuiTableCell-root:first-child > div': {
      marginLeft: '1.5rem',
    },
    '& td.MuiTableCell-root:first-child p': {
      marginLeft: '1.5rem',
    },
    '& th.MuiTableCell-root': {
      lineHeight: '1.92rem',
    },
  },
  showDots: {
    position: 'relative',
    '&:before': {
      width: '.85rem',
      height: '.85rem',
      background: MCKINSEY_BLUE,
      borderRadius: '5px',
      position: 'absolute',
      content: '""',
      left: '-1.5rem',
      top: 'calc(50% - 0.43rem)',
    },
  },
});

const MasterDataTable = forwardRef(({
  tabledata, updatedId, isAdmin, tableColumn, actionList,
  loadMoreButton, totalItems, onLoadMore, loadMoreText, hasMore, isLoading, adminRestriction,
}, ref) => {
  const classes = useStyles();
  const [orderSort, setOrderSort] = useState('');
  const sortingColumn = tableColumn.filter((item) => item.sortable)[0].property;

  const tableData = orderSort
    ? tableSort(tabledata, getComparator(orderSort, sortingColumn)) : tabledata;
  const showDot = (id) => {
    if (!!updatedId && updatedId === parseInt(id, 10)) {
      return true;
    }
    return false;
  };

  const handleRequestSort = () => {
    const isAsc = (orderSort && orderSort === 'asc');
    setOrderSort(isAsc ? 'desc' : 'asc');
  };

  const renderAction = (row) => {
    if (adminRestriction) {
      if (isAdmin) {
        return (
          <CustomPopover
            list={actionList.map((item) => ({
              title: item.title, onClick: () => item.handler(row),
            }))}
            button={ThreeDotButton}
          />
        );
      }
      return null;
    }
    return (
      <CustomPopover
        list={actionList.map((item) => ({
          title: item.title, onClick: () => item.handler(row),
        }))}
        button={ThreeDotButton}
      />
    );
  };

  return (
    <>
      <TableContainer style={{ maxHeight: 400, marginBottom: '2rem' }} ref={ref}>
        <Table aria-label="sticky table" className={classes.table} padding="normal" stickyHeader>
          <EnhancedTableHeader
            headCells={tableColumn}
            order={orderSort}
            orderBy={orderSort ? sortingColumn : ''}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {tableData && tableData.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {tableColumn.map(({ property, style }, index) => (
                  <TableCell className={`tableCell${index + 1}`} key={property} align="left" style={style && { ...style }}>
                    {property !== 'actions'
                      ? <Typography className={showDot(row.id) && !index ? classes.showDots : ''} variant="body1">{row[property]}</Typography>
                      : renderAction(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        loadMoreButton && (
        <LoadMoreComponent
          totalItems={totalItems}
          onLoadMore={onLoadMore}
          loadMoreText={loadMoreText}
          itemsShown={tableData.length}
          hasMore={hasMore}
          isLoading={!!isLoading}
        />
        )
      }
    </>
  );
});

MasterDataTable.displayName = 'MasterDataTable';

MasterDataTable.defaultProps = {
  loadMoreButton: false,
  totalItems: 0,
  onLoadMore: () => {},
  loadMoreText: 'Items',
  hasMore: false,
};

MasterDataTable.propTypes = {
  tabledata: PropTypes.array.isRequired,
  updatedId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  adminRestriction: PropTypes.bool.isRequired,
  tableColumn: PropTypes.array.isRequired,
  actionList: PropTypes.array.isRequired,
  loadMoreButton: PropTypes.bool,
  totalItems: PropTypes.number,
  onLoadMore: PropTypes.func,
  loadMoreText: PropTypes.string,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(MasterDataTable);
