import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CSVIcon from '../../assets/icons/xlsx.svg';
import XlsxIcon from '../../assets/icons/xlsx.png';
import { LIGHT_PURPLE } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  bannerBox: {
    height: '3.438rem',
    padding: '1rem 1.5rem',
    marginTop: '2.75rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: LIGHT_PURPLE,
  },
  infoIcon: {
    marginRight: '1rem',
    width: '2rem',
    height: '2rem',
  },
}));

const FileInfoBanner = ({ name }) => {
  const classes = useStyles();
  const ext = name.toLowerCase().split('.').pop();
  const icon = ext === 'xlsx' || ext === 'xls' ? XlsxIcon : CSVIcon;

  return (
    <div className={classes.bannerBox}>
      <img alt="file" src={icon} className={classes.infoIcon} />
      {`${name} uploaded sucessfully.`}
    </div>
  );
};

FileInfoBanner.propTypes = {
  name: PropTypes.string,
};

FileInfoBanner.defaultProps = {
  name: '',
};

export default FileInfoBanner;
