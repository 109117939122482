import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useStyles, useDialogStyles } from '../../../stylesheets/commonModal.styles';
import transactionLineStyles from './TransactionListModal.styles';
import DownloadIcon from '../../../assets/icons/download.svg';

export const additionalDialogStyles = makeStyles(() => ({
  dialogTitle: {
    justifyContent: 'center',
    marginTop: '5.188rem',
  },
  dialogContent: {
    overflow: 'auto',
    height: '20rem',
    fontSize: '1.125rem',
  },
}));

const DialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleBlock}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography className={classes.titleText}>{children}</Typography>
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

const TransactionListModal = ({
  open, onClose, onDownloadclick, transactions, configId,
}) => {
  const classes = useStyles();
  const additionalStyles = additionalDialogStyles();
  const dialogClasses = useDialogStyles();

  const getTransactionSummary = (transaction) => `${transaction.success}/${transaction.total} users updated`;

  const Transactions = (transaction, index) => {
    const { created_at, action_type } = transaction;
    return (
      <div key={index} style={transactionLineStyles.transactionLine}>
        <span style={transactionLineStyles.transactionRow}>
          {new Date(created_at).toLocaleDateString()}
        </span>
        <span style={transactionLineStyles.transactionColumn}>{`${action_type}: `}</span>
        {getTransactionSummary(transaction)}
        <span
          role="button"
          tabIndex="0"
          onClick={() => onDownloadclick(transaction)}
          onKeyDown={() => onDownloadclick(transaction)}
          style={transactionLineStyles.transactionDownload}
        >
          <img src={DownloadIcon} alt="download" />
        </span>
      </div>
    );
  };
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      disableBackdropClick
      onClose={onClose}
      open={open}
      classes={dialogClasses}
    >
      <DialogTitle onClose={onClose} className={classes.dialogTitle}>
        <span className={`${classes.headingText} ${additionalStyles.dialogTitle}`}>
          {`Actions updated for ${configId}`}
        </span>
        Given below is the list of actions updated over a period of time
      </DialogTitle>
      <MuiDialogContent className={`${classes.dialogContent} ${additionalStyles.dialogContent}`}>
        <ul>{transactions.map(Transactions)}</ul>
      </MuiDialogContent>
      <MuiDialogActions className={classes.buttonActions}>
        <Button variant="contained" color="primary" onClick={onClose}>
          Ok, got it
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

TransactionListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadclick: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      action_type: PropTypes.string,
      message: PropTypes.string,
    }),
  ).isRequired,
  configId: PropTypes.string.isRequired,
};

export default TransactionListModal;
