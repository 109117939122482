import config from '../../config';
import request from '../../helpers/apiHelper';

export const getUsersCohort = async (programId, update) => {
  const url = `${config.ROUTES.OVERVIEW_USERS_COHORTS}/${programId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result.data;

  return data.total_users;
};

export const getUsersEnrolled = async (programId, update = false) => {
  const url = `${config.ROUTES.OVERVIEW_USERS_ENROLLED}/${programId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result.data;

  return data;
};

export const getUsersActive = async (programId, update) => {
  const url = `${config.ROUTES.OVERVIEW_USERS_ACTIVE}/${programId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result.data;

  return {
    total: data.total_users,
    active: data.active_users,
    inactive: data.inactive_users,
    lastRefreshed: result.data.last_refreshed,
  };
};
