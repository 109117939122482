import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import FormattedTypography from '../common/FormattedTypography';
import SelectedUserTable from './SelectedUserTable';
import ConfirmationModal from '../common/ConfirmationModal';

const useStyles = makeStyles(() => ({
  marginBottom: {
    marginBottom: '2rem',
  },
}));

const ConfirmTestUserResetPassword = ({
  users, open, onClose, onResetAccess,
}) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      title={(
        <FormattedTypography
          variant="subtitle2"
          body="Reset password for selected test users"
          subHeading={`You are about to reset password for ${users.length} selected active users.`}
          dense
        />
      )}
      content={<SelectedUserTable title="Test Users" list={users} className={classes.marginBottom} />}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onResetAccess}>
            Reset password
          </Button>
        </>
      )}
    />
  );
};

export default ConfirmTestUserResetPassword;

ConfirmTestUserResetPassword.propTypes = {
  users: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onResetAccess: PropTypes.func.isRequired,
};
