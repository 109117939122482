import {
  USERS_FETCH, USERS_FETCH_SUCCESS, USERS_FETCH_FAILED, SET_LOADING, CLEAR_ALERTBAR,
} from '../../constants';

export function getUsers(data) {
  return {
    type: USERS_FETCH,
    data,
  };
}

export function getUsersSuccess(results) {
  return {
    type: USERS_FETCH_SUCCESS,
    results,
  };
}

export function getUsersFailure(response) {
  return {
    type: USERS_FETCH_FAILED,
    response,
  };
}

export function setLoading(response) {
  return {
    type: SET_LOADING,
    response,
  };
}

export function clearAlertBar(response) {
  return {
    type: CLEAR_ALERTBAR,
    response,
  };
}
