import React, { memo } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  DARK_MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  footerText: {
    color: DARK_MEDIUM_GREY,
    marginTop: '2rem',
    padding: 0,
    textAlign: 'center',
  },
});

const InProgressInstructions = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.footerText}>
        <Typography variant="body1">**You can close this popup and navigate away from this screen if you wish.</Typography>
        <Typography variant="body1">The process will continue in the background and we will inform you when its completed</Typography>
      </Container>
    </>
  );
};

export default memo(InProgressInstructions, (() => true));
