import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { snackBarInitialState } from '../../constants';

const useStyles = makeStyles(() => ({
  alertSnackBar: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomSnackbar = ({ snackbarObj, setSnackbarObj, autoHideDuration }) => {
  const classes = useStyles();
  const { open, severity, message } = snackbarObj;

  return (
    open && (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={() => setSnackbarObj(snackBarInitialState)}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={() => setSnackbarObj(snackBarInitialState)}
          severity={severity}
        >
          {message}
        </Alert>
      </Snackbar>
    )
  );
};

CustomSnackbar.defaultProps = {
  autoHideDuration: 6000,
};

CustomSnackbar.propTypes = {
  snackbarObj: PropTypes.object.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
};

export default CustomSnackbar;
