import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';
import EmptyComponentTitle from '../../assets/img/empty-component-title.svg';
import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import InfoTooltip from '../common/InfoTooltip';
import EOPIcon from '../../assets/icons/eop-icon.svg';

const useStyles = makeStyles({
  wrapper: {
    '& .MuiTableCell-body': {
      fontSize: '1.25rem',
      height: '1.25rem',
    },
  },
  componentTitle: {
    display: 'flex',
    alignItems: 'center',

    '& :hover': {
      cursor: 'pointer',
    },

    '& img': {
      height: '1.75rem',
      width: '3.125rem',
    },

    '& span': {
      marginLeft: '2.25rem',
    },
  },
  contentType: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      height: '1.25rem',
      width: '1.25rem',
    },
    '& span': {
      marginLeft: '4px',
    },
  },
  userAccess: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  trainingMatCount: {
    cursor: 'pointer',
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
  },
  orderIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  indicator: {
    height: '1.25rem',
    width: ' 1.25rem',
    borderRadius: ' 50%',
  },
  orderText: {
    paddingLeft: '0.5rem',
  },
});

const EnhancedTableBody = ({
  rows, onTmSelect, goToView, openCopyComponentModal, order, orderBy, copiedComponentData,
  openEOPComponentModal, isDDAdmin, handleCheckboxChange, selectedComponents, updatedComponentsId,
}) => {
  const classes = useStyles();

  const myRef = useRef(null);

  function descendingComparator(a, b, compOrderBy) {
    if (b[compOrderBy] < a[compOrderBy]) {
      return -1;
    }
    if (b[compOrderBy] > a[compOrderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(compOrder, compOrderBy) {
    return compOrder === 'desc'
      ? (a, b) => descendingComparator(a, b, compOrderBy)
      : (a, b) => -descendingComparator(a, b, compOrderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const sortOrder = comparator(a[0], b[0]);
      if (sortOrder !== 0) return sortOrder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const executeScroll = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (updatedComponentsId.length > 0
      || copiedComponentData.new_component_code) {
      executeScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef.current]);

  return (
    <TableBody className={classes.wrapper}>
      {stableSort(rows, getComparator(order, orderBy)).map((row) => (
        <TableRow key={row.docebo_id}>
          <TableCell>
            <Checkbox
              className={classes.checkbox}
              checked={
                selectedComponents.map((item) => item.id).includes(row.id)
              }
              onChange={(e) => handleCheckboxChange(e, row)}
              name="checkComponent"
              color="primary"
              data-testid="selectrow"
              classes={{
                checked: classes.checkboxChecked,
              }}
            />
          </TableCell>
          <TableCell>
            <Box className={classes.orderIndicator}>
              {updatedComponentsId.indexOf(row.docebo_course_id) > -1
                || (copiedComponentData?.new_component_code
                  && row.course_code === copiedComponentData?.new_component_code)
                ? (
                  <Box
                    ref={myRef}
                    className={classes.indicator}
                    style={{ backgroundColor: MCKINSEY_BLUE }}
                  />
                ) : (
                  <Box
                    className={classes.indicator}
                  />
                )}

              <Typography className={classes.orderText}>{row.order}</Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Box
              className={classes.componentTitle}
              onClick={() => goToView(row)}
            >
              {row.course_graphic ? <img src={row.course_graphic} alt="img" /> : <img src={EmptyComponentTitle} alt="img" />}
              <span>{row.component_title || '-'}</span>
            </Box>
          </TableCell>
          <TableCell align="left">{row.code}</TableCell>
          <TableCell align="left">
            <div className={classes.contentType}>
              <img src={row.type.icon} alt="img" />
              <span>{row?.type?.label.includes('classroom') ? '-' : row.type.label}</span>
            </div>
          </TableCell>
          <TableCell align="left">
            <Typography
              className={row.no_of_tm ? classes.trainingMatCount : {}}
              onClick={row.no_of_tm ? () => onTmSelect(row) : () => { }}
            >
              {row.no_of_tm}
            </Typography>
            <span />
          </TableCell>
          <TableCell align="left">{row.estimated_time}</TableCell>
          <TableCell align="left">{row.docebo_id}</TableCell>
          <TableCell align="left">
            <div className={classes.userAccess}>
              <img src={row.user_access.icon} alt="img" />
              <span style={{ marginLeft: '4px' }}>{row.user_access.label}</span>
            </div>

          </TableCell>
          <TableCell align="left">
            {row.eop && row.eop === true && (
              <InfoTooltip title="Feedback required at the end of this course">
                <img src={EOPIcon} alt="eop" />
              </InfoTooltip>
            )}
          </TableCell>
          <TableCell align="left">
            <CustomPopover
              list={[
                {
                  title: 'View Component',
                  onClick: () => goToView(row),
                },
                (!isDDAdmin ? {
                  title: 'Copy component shell',
                  onClick: () => openCopyComponentModal(row),
                } : null),
                (!isDDAdmin
                  ? {
                    title: row.eop && row.eop === true ? 'Unassign EOP Feedback' : 'Assign EOP Feedback',
                    onClick: () => openEOPComponentModal(row),
                  } : null),
              ]}
              button={ThreeDotButton}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

EnhancedTableBody.defaultProps = {
  copiedComponentData: {
    new_component_title: '',
    new_component_code: '',
  },
};

EnhancedTableBody.propTypes = {
  rows: PropTypes.array.isRequired,
  onTmSelect: PropTypes.func.isRequired,
  goToView: PropTypes.func.isRequired,
  openCopyComponentModal: PropTypes.func.isRequired,
  openEOPComponentModal: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  copiedComponentData: PropTypes.shape({
    new_component_title: PropTypes.string,
    new_component_code: PropTypes.string,
  }),
  isDDAdmin: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  selectedComponents: PropTypes.array.isRequired,
  updatedComponentsId: PropTypes.array.isRequired,
};

export default EnhancedTableBody;
