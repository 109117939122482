import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import GlobalHeader from '../ds-react-components/GlobalHeader';

import mckAcademyLogo from '../../assets/img/mck-academy-logo.svg';
import { ROUTE_HOME } from '../../constants';
import Config from '../../config';

const SimpleHeader = ({ programType }) => (
  <GlobalHeader>
    <GlobalHeader.Left>
      <Link color="textPrimary" to={`${Config.BASE_PATH}${ROUTE_HOME}/${programType}`}>
        <img src={mckAcademyLogo} title="mckAcademyLogo" alt="mckAcademyLogo" />
      </Link>
    </GlobalHeader.Left>
  </GlobalHeader>
);

SimpleHeader.propTypes = {
  programType: PropTypes.string.isRequired,
};

export default SimpleHeader;
