import { useEffect, useState } from 'react';
import { AUTO_HIDE_DURATION } from '../constants';

const initialAlertBarConfig = {
  labelText: '',
  variant: '',
  open: false,
};

const useAlertBar = () => {
  const [alertBarConfig, setAlertBarConfig] = useState(initialAlertBarConfig);
  const { labelText, variant, open } = alertBarConfig;

  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        setAlertBarConfig(initialAlertBarConfig);
      }, AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  return {
    labelText, variant, open, setAlertBarConfig, initialAlertBarConfig,
  };
};

export default useAlertBar;
