import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '@material-ui/core/Button';

import MainButton from '../../components/MainButton';
import XlsxValidationModal from '../../components/XlsxValidationModal';
import SuccessModal from '../../components/SuccessModal';
import FileInput from '../../components/FileInput';

import { parseValidationResult } from '../../helpers/xlsxValidation';
import { readWB } from '../../helpers/xlsxHelper';
import { uploadXlsx, validateXLSXApiCall } from '../common/apis';
import {
  HOME_PAGE_INTRO_TEXT,
  HOME_PAGE_BUTTON_TEXT,
  SUCCESS_MODAL_TITLE,
  SUCCESS_MODAL_BUTTON_TEXT,
  SUCCESS_MODAL_FOOTER_TEXT,
  PROGRAM_CREATION_TEMPLATE,
  ROUTE_UPLOAD,
  MODIFY_COURSES,
  PROGRAM_CREATION,
  COURSE_PROD,
} from '../../constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'unset',
    flexBasis: 'auto',
    flexGrow: 1,
  },
  greetingText: {
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'unset',
    marginBottom: '4rem',
  },
  helpSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3.625rem',
  },
  uploadBtn: {
    padding: '1.25rem',
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
  },
  downloadBtn: {
    marginTop: '0.5rem',
  },
}));

const UploadPage = (props) => {
  const { match, goToReport, defaultButton } = props;

  const programId = get(match, 'params.programId');
  const programType = get(match, 'params.programType');
  const programSubType = get(match, 'params.programSubType');

  const classes = useStyles();
  const history = useHistory();

  const [file, setFile] = useState(null);
  const [fileTarget, setFileTarget] = useState(null);
  const [validationResult, setValidationResult] = useState({ status: 'none', errors: [] });
  const [modalState, setModalState] = useState('none');
  const [successModalSteps, setSuccessModalSteps] = useState([]);

  const onFileInput = (e) => {
    const selectedFile = e.target.files[0];
    setFileTarget(e.target);
    setFile(selectedFile);
  };

  const resetFileUpload = () => {
    setFile(null);
    setValidationResult({ status: 'none', errors: [] });
    setModalState('none');
    setSuccessModalSteps([]);
    fileTarget.value = '';
    // document.getElementById('fileInput').value = '';
  };

  const reUploadFile = () => {
    resetFileUpload();
    fileTarget.click();
  };

  const onModifyCourse = async () => {
    const fileData = await readWB(file);
    history.push(`/${ROUTE_UPLOAD}/${programType}/${programSubType}/${MODIFY_COURSES}`, {
      fileName: file.name,
      fileData,
      steps: successModalSteps,
    });
  };

  const onBuildProgram = async () => {
    if (file === null) return;
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('program_id', programId);
      const result = await uploadXlsx(programType, programSubType, formData);
      setModalState('none');
      // Navigate to report page
      goToReport(result.data.program_id);
    } catch (e) {
      // TODO: Show toast of failure
      console.error(e);
    }
  };

  useEffect(() => {
    if (file === null) return;

    const validateXLSX = async () => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      const result = await validateXLSXApiCall(programType, programSubType, formData);
      const errorMessages = parseValidationResult(result);
      const { steps } = result;
      setValidationResult(errorMessages);
      setSuccessModalSteps(steps);
    };
    validateXLSX();
  }, [file, programType, programSubType]);

  useEffect(() => {
    setModalState(validationResult.status);
  }, [validationResult]);

  return (
    <>
      {defaultButton ? (
        <div className={classes.wrapper}>
          <Typography variant="h4" className={classes.greetingText}>
            {HOME_PAGE_INTRO_TEXT[programSubType]}
          </Typography>
          <FileInput idx={0} onFileInput={onFileInput}>
            <MainButton subtitle={HOME_PAGE_BUTTON_TEXT[programSubType]} />
          </FileInput>
          <div className={classes.helpSection}>
            <Typography variant="body1">Need some help to configure your file?</Typography>
            <div>
              <a href={PROGRAM_CREATION_TEMPLATE} target="_blank" rel="noopener noreferrer">
                <Button variant="contained" color="primary" className={classes.downloadBtn}>Download sample program template</Button>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <FileInput idx={0} onFileInput={onFileInput}>
          <Typography variant="body1" color="secondary" className={classes.uploadBtn}>
            Upload CSV to un-enroll users
          </Typography>
        </FileInput>
      )}
      <XlsxValidationModal
        open={modalState === 'error'}
        onClose={resetFileUpload}
        onOk={reUploadFile}
        errors={validationResult.errors}
      />
      <SuccessModal
        open={modalState === 'success'}
        onClose={resetFileUpload}
        onExit={resetFileUpload}
        onModify={
          programType === COURSE_PROD && programSubType === PROGRAM_CREATION ? onModifyCourse : null
        }
        onBuildProgram={() => onBuildProgram(file, file.name)}
        steps={successModalSteps}
        xlsxName={file ? file.name : ''}
        heading={SUCCESS_MODAL_TITLE[programSubType]}
        buttonText={SUCCESS_MODAL_BUTTON_TEXT[programSubType]}
        footerText={SUCCESS_MODAL_FOOTER_TEXT[programSubType]}
      />
    </>
  );
};

UploadPage.defaultProps = {
  defaultButton: true,
};

UploadPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  goToReport: PropTypes.func.isRequired,
  defaultButton: PropTypes.bool,
};

export default UploadPage;
