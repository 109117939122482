import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  CANCEL_SUGGESTION_BACKGROUND_COLOR, CANCEL_SUGGESTION_FONT_COLOR, WHITE, MODERATE_LIGHT_GREY,
} from '../../stylesheets/colors';
import catIllustrationIcon from '../../assets/img/cat-illustration.png';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  bodyWrapper: {
    flex: 1,
    padding: '0 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    alignSelf: 'center',
    fontWeight: 'bold',
    margin: '1.5rem 0 3.5rem',
  },
  box: {
    marginBottom: '3.75rem',
  },
  boxHeading: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: CANCEL_SUGGESTION_BACKGROUND_COLOR,
    color: CANCEL_SUGGESTION_FONT_COLOR,
    height: '3.5rem',
    alignItems: 'center',

    '& > span': {
      padding: '0.25rem 0.75rem 0 0.75rem',
      margin: '0 1rem',
      backgroundColor: CANCEL_SUGGESTION_FONT_COLOR,
      color: WHITE,
      fontSize: '14px',
    },
    '& > p': {
      fontWeight: 'bold',
    },
  },
  list: {
    backgroundColor: MODERATE_LIGHT_GREY,
    padding: '1rem 2.5rem 1rem 3.5rem',
  },
  imageWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    '& > :nth-child(1)': {
      margin: '3rem 0 2rem 0',
    },
  },
  closeButton: {
    alignSelf: 'unset',
    height: '46px',
    position: 'absolute',
    right: 0,
    color: theme.palette.grey[500],
  },
}));

const CancelSuggestionModal = ({
  open, onClose, onCancel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.wrapper }}
    >
      <Container className={classes.bodyWrapper}>
        <div className={classes.imageWrapper}>
          <img src={catIllustrationIcon} alt="Process Stuck" width={150} height={150} />
          {onClose && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <DialogContent className={classes.content}>
          <Typography variant="subtitle1" className={classes.heading}>Having trouble in processing task</Typography>
          <div className={classes.box}>
            <div className={classes.boxHeading}>
              <span>i</span>
              <Typography>
                Your process seems to be stuck. We recommend you cancel the process
              </Typography>
            </div>
            <List className={classes.list}>
              <ListItemText primary="- Cancel the process using the button below" />
              <ListItemText primary="- Download the XLS file available in log/ summary and re-upload it as is at a later time to continue" />
              <ListItemText primary="- The changes made so far will be saved & the current state of the program will be reflected on Summary (log) page" />
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close Popup
          </Button>
          <Button color="primary" variant="contained" onClick={onCancel}>
            Cancel Process
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

CancelSuggestionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default memo(CancelSuggestionModal);
