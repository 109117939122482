import { createTheme } from '@material-ui/core/styles';
import {
  ACCORDION_GREY,
  ERROR_RED,
  MCKINSEY_BLUE,
  MCKINSEY_LIGHT_BLUE, MODERATE_DARK_GREY, SWITCH_GREY,
  WHITE,
} from './colors';
import MCKINSEY_SANS from './fonts';

const defaultFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const theme = createTheme({
  typography: {
    fontFamily: [
      'McKinseySans',
      'Bower',
      ...defaultFonts,
    ].join(','),
    fontSize: 18,
    button: {
      fontSize: '1.125rem',
      fontFamily: [
        'McKinseySans',
        'Bower',
        ...defaultFonts,
      ].join(','),
    },
  },
  palette: {
    primary: {
      light: MCKINSEY_LIGHT_BLUE,
      main: MCKINSEY_BLUE,
    },
    secondary: {
      main: WHITE,
    },
    error: {
      main: ERROR_RED,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
        color: WHITE,
        backgroundColor: MODERATE_DARK_GREY,
        maxWidth: 200,
        borderRadius: 'unset',
        lineHeight: '1.43',
      },
      arrow: {
        color: MODERATE_DARK_GREY,
        fontSize: '1.3rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: 'unset',
        fontFamily: `${MCKINSEY_SANS} !important`,
      },
      contained: {
        '&:disabled': {
          backgroundColor: SWITCH_GREY,
          color: ACCORDION_GREY,
        },
      },
      outlined: {
        '&:disabled': {
          backgroundColor: SWITCH_GREY,
          color: ACCORDION_GREY,
        },
      },
      textSecondary: {
        '&.MuiButton-textSecondary': {
          background: 'none !important',
          color: `${MCKINSEY_BLUE} !important`,
          textDecoration: 'underline',
        },
        '&:hover': {
          color: `${MCKINSEY_BLUE} !important`,
          textDecoration: 'underline !important',
          background: 'none !important',
          opacity: 0.6,
        },
        '&:disabled': {
          color: `${ACCORDION_GREY} !important`,
          textDecoration: 'underline',
          opacity: 0.6,
        },
      },
    },
  },
});

theme.typography.h4 = {
  fontSize: '2.25rem',
  fontFamily: [
    'Bower',
    'McKinseySans',
    ...defaultFonts,
  ].join(','),
};

theme.typography.body2 = {
  fontSize: '1.5rem',
  fontFamily: [
    'McKinseySans',
    ...defaultFonts,
  ].join(','),
};

theme.typography.subtitle1 = {
  fontSize: '1.5rem',
  fontFamily: theme.typography.fontFamily,
};

theme.typography.subtitle2 = {
  fontSize: '1.25rem',
  fontFamily: theme.typography.fontFamily,
};

theme.typography.body1 = {
  fontSize: '1.125rem',
  fontFamily: theme.typography.fontFamily,
};

theme.typography.h1 = {
  fontSize: '1.75rem',
  fontFamily: theme.typography.fontFamily,
};

theme.typography.h2 = {
  fontSize: '0.875rem',
  fontFamily: theme.typography.fontFamily,
};

theme.typography.h3 = {
  fontSize: '1rem',
  fontFamily: theme.typography.fontFamily,
};

theme.typography.h4 = {
  fontSize: '2.25rem',
  textTransform: 'unset',
  fontFamily: theme.typography.fontFamily,
};

theme.typography.h5 = {
  fontSize: '0.75rem',
  fontFamily: theme.typography.fontFamily,
};

export default theme;
