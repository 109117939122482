/**
 * This is the UserCircle component.
 * Useful for a profile circle to show a user's initials, like so: (ZM)
 *
 * Usage: <UserCircle>DH</UserCircle>
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeShape, DefaultTheme } from './default-theme';

const UserCircleComponent = styled.div`
   align-items: center;
   background-color: #212121;
   border-radius: 50%;
   color: ${(props) => props.theme.white};
   display: flex;
   font-family: ${(props) => props.theme.font_family};
   font-size: 14px;
   font-weight: 600;
   height: 38px;
   justify-content: center;
   padding-top: 2px;
   text-transform: uppercase;
   width: 38px;
 `;

export class UserCircle extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { children } = this.props;
    return <UserCircleComponent {...this.props}>{children}</UserCircleComponent>;
  }
}

UserCircle.defaultProps = {
  theme: DefaultTheme,
};

UserCircle.propTypes = {
  children: PropTypes.string.isRequired,
  theme: ThemeShape,
};

export default UserCircle;
