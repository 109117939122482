import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import _map from 'lodash/map';
import ConfirmationModal from '../common/ConfirmationModal';
import FormattedTypography from '../common/FormattedTypography';
import AsyncSelectClient from '../common/AsyncSelectClient';
import AsyncProgramSelect from '../common/AsyncProgramSelect';

const useStyles = makeStyles({
  wrapper: {
    padding: '0 1.5rem 2rem 1.5rem',

    '& > div:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  contentWidth: {
    width: '60%',
  },
  modalHeight: {
    minHeight: '30rem',
  },
});

const UsersFilterModal = ({
  open, onClose, isLoading, loadingText, programType,
  selectedPrograms, selectedClients, handleApplyFilters,
}) => {
  const classes = useStyles();

  const [selectedProgramsClone, setSelectedProgramsClone] = useState(selectedPrograms);
  const [selectedClientsClone, setSelectedClientsClone] = useState(selectedClients);

  const handlePickedProgramsClone = (items) => {
    setSelectedProgramsClone(_map(items));
  };

  const handlePickedClientsClone = (items) => {
    setSelectedClientsClone(_map(items));
  };

  const resetFormBeforeClose = () => {
    onClose();
  };

  const onSave = () => {
    handleApplyFilters({ selectedProgramsClone, selectedClientsClone });
  };

  const handleClearAll = () => {
    setSelectedProgramsClone([]);
    setSelectedClientsClone([]);
  };

  return (
    <ConfirmationModal
      open={open}
      isLoading={isLoading}
      onClose={isLoading ? null : resetFormBeforeClose}
      breakpoint="md"
      minHeightClass={classes.modalHeight}
      loadingText={loadingText}
      title={(<FormattedTypography body="Filters" />)}
      dialogContentClass={classes.contentWidth}
      content={(
        <Container className={classes.wrapper}>
          <AsyncProgramSelect
            label="Learning Program Config ID"
            placeholder="Select a Learning Program Config ID"
            programType={programType}
            selectedItems={selectedProgramsClone}
            onChange={handlePickedProgramsClone}
            disableOnLimitReach={false}
          />
          <AsyncSelectClient
            label="Client ID/Client Name"
            placeholder="Select a Client ID or Client Name"
            programType={programType}
            selectedClients={selectedClientsClone}
            onChange={handlePickedClientsClone}
            disableOnLimitReach={false}
          />
        </Container>
      )}
      actions={(
        <>
          <Button color="primary" variant="outlined" onClick={handleClearAll}>
            Clear All
          </Button>
          <Button color="primary" variant="contained" onClick={onSave}>
            Apply Filters
          </Button>
        </>
      )}
    />
  );
};

UsersFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string.isRequired,
  programType: PropTypes.string.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  selectedClients: PropTypes.array.isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
};

export default UsersFilterModal;
