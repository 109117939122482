const Colors = {
  // Brand Colors
  MCK_BLUE: '#1f40e6',
  MCK_DARK_BLUE: '#002960',
  SUPER_BLUE: '#37DEE7',

  // Type Colors
  BLACK: '#000000',
  BLACK_20: 'rgba(0, 0, 0, 0.2)',
  WHITE: '#ffffff',
  LIGHT_GRAY: '#b3b3b3',

  // Background Colors
  PALE_BLUE: '#E2EFF7',
  PALE_GRAY: '#F8F9FA',
  MCK_GRAY: '#4A4A4A',

  // Action Colors
  WARM_RED: '#E14446',
  GOLD: '#F5A623',
  IVY: '#649F29',

  // Accent Colors
  GREEN: '#8ACA49',
  ORANGE: '#FFB945',
  RED: '#F06668',
  BLUE: '#20ACE9',

  // GRAYS
  MCK_SUPER_DARK_GRAY: '#333333',
  MCK_DARK_GRAY: '#4d4d4d',
  MCK_MID_GRAY: '#7f7f7f',
  MCK_LIGHT_GRAY: '#b3b3b3',
  MCK_SUPER_LIGHT_GRAY: '#d0d0d0',
  MCK_PALE_GRAY: '#e6e6e6',
  MCK_SUPER_PALE_GRAY: '#f0f0f0',
  MCK_OFF_WHITE: '#f8f8f8',

  // BLUES
  MCK_DEEP_BLUE: '#051c2c',
  MCK_TRANSITION_BLUE_01: '#034b6f',
  MCK_TRANSITION_BLUE_02: '#027ab1',
  MCK_CYAN: '#00a9f4',
  MCK_TRANSITION_BLUE_03: '#39bdf3',
  MCK_TRANSITION_BLUE_04: '#71d2f1',
  MCK_PALE_BLUE: '#aae6f0',
  MCK_TURQUOISE: '#3c96b4',
  MCK_PALE_ELECTRIC_BLUE: '#afc3ff',

  // CATEGORICAL
  MCK_PURPLE: '#8c5ac8',
  MCK_PINK: '#e6a0c8',
  MCK_RED: '#e5546c',
  MCK_ORANGE: '#faa082',
  MCK_PALE_ORANGE: '#FACBBB',

  HOVER: '#002960',

  STATUS_LIGHT_GREEN: '#d1f4dd',
  STATUS_GREEN: '#3aa573',
  STATUS_DARK_GREEN: '#006200',

  STATUS_LIGHT_YELLOW: '#fff3ce',
  STATUS_YELLOW: '#ffd048',
  STATUS_DARK_YELLOW: '#6a4f00',

  STATUS_LIGHT_RED: '#ffe5ea',
  STATUS_RED: '#f13a2c',
  STATUS_DARK_RED: '#b0001d',

  STATUS_LIGHT_BLUE: '#e6ecff',
  STATUS_BLUE: '#1f40e6',
  STATUS_DARK_BLUE: '#1e32e6',

  GRADIENT_BLUE: 'linear-gradient(315deg, #00263e 100%, #002dbf 100%, #00273f)',

  // Data Visualizations
  DIVERGENT_RED: '#e5546c',
  DIVERGENT_ORANGE: '#faa082',
  DIVERGENT_LIGHT_ORANGE: '#fdcbba',
  DIVERGENT_NEUTRAL: '#f0f0f0',
  DIVERGENT_BLUE: '#71d2f1',
  DIVERGENT_CYAN: '#00a9f4',
  DIVERGENT_ELECTRIC_BLUE: '#1f40e6',

  VALIDATION: '#3AA537',
  ERROR: '#E12E40',
};

export default Colors;
