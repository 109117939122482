import React, { useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import { Tooltip } from '@material-ui/core';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  ACCORDION_GREY, MCKINSEY_BLUE, SUCCESS_GREEN2, SWITCH_GREY, VERY_LIGHT_GREY_V2, WHITE,
} from '../../../../stylesheets/colors';
import JournalBook from '../../../../assets/icons/LTI_journal_thumbnail.svg';
import { NA, ROUTE_HOME, TOOLTIP_TIME } from '../../../../constants';
import { dateFormatter } from '../../../../helpers/formattingHelpers';
import CustomPopover from '../../../common/CustomPopover';
import ThreeDotButton from '../../../common/ThreeDotButton';
import StageBadge from '../../../StageBadge/StageBadge';

const useStyles = makeStyles((theme) => ({
  link: {
    flex: 1,
    cursor: 'pointer',
  },
  wrapper: {
    flexDirection: 'column',
    fontFamily: theme.typography.fontFamily,
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    overflow: 'hidden',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.25rem',
    flex: 1,
  },
  actionWrapper: {
    padding: '0.45rem 1.25rem',
    display: 'flex',
    height: '3.25rem',
    justifyContent: ({ is_locked }) => (is_locked ? 'space-between' : 'flex-end'),
    backgroundColor: VERY_LIGHT_GREY_V2,
    alignItems: 'center',

    '& > div': {

      display: 'flex',
      alignItems: 'center',

      '& > div': {
        marginRight: '1.25rem',
      },
      '& img': {
        cursor: 'not-allowed',
      },
      '& > span': {
        padding: 0,
      },
    },
  },
  dataWrapper: {
    height: '8.75rem',
    padding: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      height: '5rem',
      width: '5rem',
    },
  },
  textInfo: {
    marginLeft: '1rem',

    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      boxOrient: 'vertical',
    },

    '& > p:nth-child(1)': {
      lineClamp: 1,
    },
    '& > p:nth-child(2)': {
      lineClamp: 2,
    },
  },
  journalDescription: {
    fontSize: '1rem',
    color: ACCORDION_GREY,
    lineHeight: 1.5,
    marginTop: '0.125rem',

  },
  journalTitle: {
    fontWeight: 600,
    fontSize: '1.25rem',
  },
  lowerWrapper: {
    paddingTop: '0.563rem',
    display: 'flex',
    textAlign: 'center',
    margin: '0 1.25rem 1.25rem 1.25rem',
    borderTop: `1px solid ${SWITCH_GREY}`,

    '& > div': {
      display: 'flex',

      '& > p:nth-child(1)': {
        maxWidth: '70%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
      },
    },
  },
  title: {
    fontSize: '0.875rem',
    color: ACCORDION_GREY,
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginLeft: '2px',
  },
  linkedLp: {
    display: 'flex',
    marginTop: '0.375rem',
  },
  noOfPages: {
    color: MCKINSEY_BLUE,
    fontSize: '0.75rem',
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MCKINSEY_BLUE}`,
    borderRadius: '10px',
    fontWeight: 600,
    marginRight: '1.313rem',
    textAlign: 'center',
  },
  newJournal: {
    color: WHITE,
    fontSize: '0.75rem',
    padding: '0.125rem 0.5rem',
    borderRadius: '10px',
    backgroundColor: SUCCESS_GREEN2,
    fontWeight: 600,
    marginRight: '1.313rem',
    width: '4rem',
    textAlign: 'center',
  },

  doubleLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
  },
}));

const JournalsCard = ({
  journal, match, onDeleteJournal, onEditJournal, onLockJournal, onUnlockJournal,
}) => {
  const {
    learning_plan_code, journal_title, description,
    num_pages, id, modified, modified_by, badge, is_locked,
  } = journal;
  const classes = useStyles({ is_locked });
  const history = useHistory();
  const programType = get(match, 'params.programType');
  const { ENTER_DELAY, ENTER_NEXT_DELAY } = TOOLTIP_TIME;

  const actionMenu = useCallback(() => {
    const actionMenuList = [{
      title: 'Edit Journal',
      value: 'edit_journal',
      onClick: () => { onEditJournal(journal); },
      disabled: is_locked,
    },
    {
      title: 'Delete Journal',
      value: 'delete_journal',
      onClick: () => { onDeleteJournal({ journalId: id }); },
      disabled: is_locked,
    }];

    if (is_locked) {
      actionMenuList.push({
        title: 'Unlock Journal',
        value: 'unlock_journal',
        onClick: () => { onUnlockJournal(journal); },
      });
    } else {
      actionMenuList.push({
        title: 'Lock Journal',
        value: 'lock_journal',
        onClick: () => { onLockJournal(journal); },
      });
    }
    return actionMenuList;
  }, [id, is_locked, journal, onDeleteJournal, onEditJournal, onLockJournal, onUnlockJournal]);

  return (
    <Box
      className={classes.link}
      onClick={() => {
        history.push(`/${ROUTE_HOME}/${programType}/contentLibrary/lti/journals/${id}`);
      }}
    >
      <Paper className={classes.wrapper} square>
        <Box className={classes.actionWrapper}>
          {is_locked ? <LockOutlinedIcon /> : null}
          <Box>
            {badge ? <StageBadge stage={badge} /> : null}
            <Typography className={num_pages ? classes.noOfPages : classes.newJournal}>
              {num_pages ? `${num_pages} pages` : 'New'}
            </Typography>
            <CustomPopover
              list={actionMenu()}
              button={ThreeDotButton}
            />
          </Box>
        </Box>
        <Box className={classes.dataWrapper}>
          <img src={JournalBook} alt="dots" />
          <Box className={classes.textInfo}>
            <Tooltip title={journal_title} arrow placement="top" enterDelay={ENTER_DELAY} enterNextDelay={ENTER_NEXT_DELAY}>
              <Typography className={classes.journalTitle}>
                {journal_title}
              </Typography>
            </Tooltip>
            <Tooltip title={description} arrow placement="top" enterDelay={ENTER_DELAY} enterNextDelay={ENTER_NEXT_DELAY}>
              <Typography className={classes.journalDescription}>
                {description}
              </Typography>
            </Tooltip>
            <Box className={classes.linkedLp}>
              <Typography className={classes.title}>
                Linked LP:
              </Typography>
              <Tooltip title={learning_plan_code} arrow placement="top" enterDelay={ENTER_DELAY} enterNextDelay={ENTER_NEXT_DELAY}>
                <Typography className={`${classes.doubleLineEllipsis} ${classes.value}`}>
                  {learning_plan_code}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box className={classes.lowerWrapper}>
          <Typography className={classes.title}>
            Updated By:
          </Typography>
          <Box>
            <Typography className={classes.value}>
              {modified_by || NA}
            </Typography>
            <Typography className={classes.value}>
              |
            </Typography>
            <Typography className={classes.value}>
              {modified ? dateFormatter(new Date(modified)) : NA}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

JournalsCard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      platformType: PropTypes.string,
      programSubType: PropTypes.string,
      feature: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
  journal: PropTypes.object.isRequired,
  onDeleteJournal: PropTypes.func.isRequired,
  onEditJournal: PropTypes.func.isRequired,
  onLockJournal: PropTypes.func.isRequired,
  onUnlockJournal: PropTypes.func.isRequired,
};

export default JournalsCard;
