import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { reportPageDateTimeFormatter } from '../../helpers/formattingHelpers';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
});

const MarkedPrompt = ({ markedAt }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      Marked completed at
      {' '}
      {reportPageDateTimeFormatter(new Date(markedAt))}
    </div>
  );
};

MarkedPrompt.propTypes = {
  markedAt: PropTypes.string.isRequired,
};

export default MarkedPrompt;
