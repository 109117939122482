import config from '../../config';
import request from '../../helpers/apiHelper';

export const getEnrollmentTransactions = async (programId) => {
  const url = `${config.ROUTES.USER_ENROLLMENT}/${programId}`;
  const method = 'GET';

  const options = { url, method };
  const result = await request(options);

  return result;
};

export const getEnrollmentCohort = async (programId) => {
  const url = `${config.ROUTES.GET_WAVES}/${programId}?fetch_deleted=false`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);

  return result;
};

export const updateEnrollmentTransaction = async (payload) => {
  const isVersionOld = sessionStorage.getItem('vn');
  const automationRoute = !isVersionOld ? config.ROUTES.USER_ENROLLMENT.replace('automation', 'v2/automation') : config.ROUTES.USER_ENROLLMENT;
  const url = `${automationRoute}/${payload.transactionId}`;
  const method = 'PUT';

  const data = {
    transaction_id: payload.transactionId,
    should_append_config_ids: payload.shouldAppendConfigIds,
    should_use_excel_data_for_password_change: payload.shouldUseExcelDataForPasswordChange,
    update_name: payload.updateName,
    update_lang: payload.updateLang,
    ...(payload?.wave_id && { wave_id: payload.wave_id }),
  };

  const options = { url, method, data };
  const result = await request(options);

  return result;
};

export const validateUserEnrollmentXLSXApiCall = async (
  automationType,
  programSubType,
  data,
  programId,
) => {
  const url = `${config.ROUTES.VALIDATE_XLSX}/${automationType}/${programSubType}?program_id=${programId}`;
  const options = { url, method: 'POST', data };
  try {
    const result = await request(options);
    return result.data;
  } catch (e) {
    if (e.response.status === 400) {
      return e.response.data;
    }
    return { errors: [{ message: e.toString() }] };
  }
};

export const getClientIdsRecord = async (searchText) => {
  const url = `${config.ROUTES.USER_ENROLLMENT_SEARCH}?search_text=${encodeURIComponent(
    searchText,
  )}`;
  const options = { url, method: 'GET' };
  try {
    const { data } = await request(options);
    return data;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};
