import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import LoaderIcon from '../../../assets/icons/loader-icon.svg';
import * as COLORS from '../../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  loaderIconCls: {
    animation: '$spinner 1s linear infinite',
    width: '50px',
    height: '50px',
  },
  loaderContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
  loaderBaseCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '150px',
    height: '150px',
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.MODERATE_LIGHT_GREY}`,
    borderRadius: '50%',
  },
  loaderSemiCircle: {
    position: 'absolute',
    // width: ({ scaleFactor }) => `${500 * scaleFactor}px`,
    // height: ({ scaleFactor }) => `${500 * scaleFactor}px`,
    border: `25px solid ${COLORS.MCKINSEY_BLUE}`,
    borderRadius: '50%',
    transform: 'rotate(45deg)',
  },
  loaderLabelCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '130px',
    height: '130px',
    backgroundColor: COLORS.WHITE,
    borderRadius: '50%',
    boxShadow: `0px 3px 10px -2px ${COLORS.LIGHT_MEDIUM_GREY}`,
  },
  loadingText: {
    marginTop: '3rem',
  },
}));

const LoadingCircle = ({ labelText }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.loaderContainer} elevation={0}>
      <div className={classes.loaderBaseCircle}>
        <div className={classes.loaderLabelCircle}>
          <img src={LoaderIcon} alt="loader" className={classes.loaderIconCls} />
        </div>
      </div>
      {labelText.length > 0 && (
        <Typography variant="subtitle1" className={classes.loadingText}>{labelText}</Typography>
      )}
    </Paper>
  );
};

LoadingCircle.defaultProps = {
  labelText: '',
};

LoadingCircle.propTypes = {
  labelText: PropTypes.string,
};

export default LoadingCircle;
