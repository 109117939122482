import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { BLACK, MEDIUM_GREY } from '../../../../../stylesheets/colors';
import ConfirmationModal from '../../../../common/ConfirmationModal';
import JournalingDeleteIcon from '../../../../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:nth-child(4)': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
}));

const DeleteJournalModal = ({
  onClose,
  onConfirm,
  selectedJournal,
}) => {
  const classes = useStyles();
  const { id, journal_title } = selectedJournal;

  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>Delete Journal</Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            You are about to delete journal
            {' '}
            <span style={{ fontWeight: 600 }}>{journal_title}</span>
          </Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>All pages and prompts within this journal will also be deleted.</Typography>
        </Container>
            )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="delete_journal_action" color="primary" variant="contained" onClick={() => { onConfirm({ journalId: id }); }}>
            Delete
          </Button>
        </>
      )}
    />
  );
};

DeleteJournalModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedJournal: PropTypes.object.isRequired,
};

export default DeleteJournalModal;
