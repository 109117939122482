export const TEST_FETCH = 'TEST_FETCH';
export const TEST_FETCH_SUCCESS = 'TEST_FETCH_SUCCESS';

export const ANTIVIRUS_CHECK = 'ANTIVIRUS_CHECK';
export const ANTIVIRUS_CHECK_SUCCESS = 'ANTIVIRUS_CHECK_SUCCESS';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const CLIENT_DATA_FETCH = 'CLIENT_DATA_FETCH';
export const CLIENT_DATA_FETCH_SUCCESS = 'CLIENT_DATA_FETCH_SUCCESS';
export const CLIENT_DATA_FETCH_FAILED = 'CLIENT_DATA_FETCH_FAILED';
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const ADD_NEW_CLIENT_SUCCESS = 'ADD_NEW_CLIENT_SUCCESS';
export const ADD_NEW_CLIENT_FAILURE = 'ADD_NEW_CLIENT_FAILURE';
export const UPDATE_CLIENT_INFO = 'UPDATE_CLIENT_INFO';
export const UPDATE_CLIENT_INFO_SUCCESS = 'UPDATE_CLIENT_INFO_SUCCESS';
export const UPDATE_CLIENT_INFO_FAILURE = 'UPDATE_CLIENT_INFO_FAILURE';

export const PORTFOLIO_DATA_FETCH = 'PORTFOLIO_DATA_FETCH';
export const PORTFOLIO_DATA_FETCH_SUCCESS = 'PORTFOLIO_DATA_FETCH_SUCCESS';
export const PORTFOLIO_DATA_FETCH_FAILED = 'PORTFOLIO_DATA_FETCH_FAILED';
export const ADD_NEW_PORTFOLIO = 'ADD_NEW_PORTFOLIO';
export const ADD_NEW_PORTFOLIO_SUCCESS = 'ADD_NEW_PORTFOLIO_SUCCESS';
export const ADD_NEW_PORTFOLIO_FAILURE = 'ADD_NEW_PORTFOLIO_FAILURE';
export const DELETE_PORTFOLIO = 'DELETE_PORTFOLIO';
export const DELETE_PORTFOLIO_SUCCESS = 'DELETE_PORTFOLIO_SUCCESS';
export const DELETE_PORTFOLIO_FAILURE = 'DELETE_PORTFOLIO_FILURE';
export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO';
export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS';
export const UPDATE_PORTFOLIO_FAILURE = 'UPDATE_PORTFOLIO_FAILURE';
export const GET_PORTFOLIO_OFFERING_LIST = 'GET_PORTFOLIO_OFFERING_LIST';
export const GET_PORTFOLIO_OFFERING_LIST_SUCCESS = 'GET_PORTFOLIO_OFFERING_LIST_SUCCESS';
export const GET_PORTFOLIO_OFFERING_LIST_FAILURE = 'GET_PORTFOLIO_OFFERING_LIST_FAILURE';

// PLATFORM LEVEL USER MANAGEMENT

export const USERS_FETCH = 'USERS_FETCH';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_ALERTBAR = 'CLEAR_ALERTBAR';
export const SET_SNACKBAR = 'SET_SNACKBAR';

// PLATFORM LEVEL USER MANAGEMENT

export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILED = 'FETCH_USER_DETAILS_FAILED';
export const SET_USER_DETAILS_SUCCESS = 'SET_USER_DETAILS_SUCCESS';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const UPDATE_USER_DETAILS_STORE = 'UPDATE_USER_DETAILS_STORE';
export const SET_PLATFORM_DETAILS_LOADING = 'SET_PLATFORM_DETAILS_LOADING';
export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS';
export const TOGGLE_USER_DETAIL_ALERTBAR = 'TOGGLE_USER_DETAIL_ALERTBAR';
