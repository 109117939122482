import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import isEqual from 'lodash/isEqual';

import { WHITE } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    height: '2.5rem',
  },
  selectBox: {
    backgroundColor: WHITE,
    padding: '0px 1rem',
    borderRadius: '0px',
  },
  applyFilterButton: {
    marginLeft: '1rem',
    minWidth: '9rem',
  },
  selectAllOrNoneButton: {
    margin: '5px 1rem',
    flex: 1,
  },
  customListIndex: {
    display: 'flex',
  },
});

const LiButton = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <li className={classes.customListIndex}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.selectAllOrNoneButton}
        onClick={onClick}
      >
        {children}
      </Button>
    </li>
  );
};

LiButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const StageSelectionDropdown = ({
  selections,
  options,
  onChange,
  onButtonClick,
  defaultSelections,
}) => {
  const classes = useStyles();
  const [selectOpen, setSelectOpen] = useState(false);

  const renderFunction = (s) => {
    if (s.length === options.length || isEqual(s.sort(), defaultSelections.sort())) return 'All selected';
    return `${s.length} stages selected`;
  };

  const onChangeInner = (e, child) => {
    // A button was clicked instead of a menu item
    // Early exit as we dont want this same event triggered twice
    if (!child.props.value) return;

    // Drop undefined
    // TODO: Find source of undefined
    onChange(e.target.value.filter((v) => v));
  };

  const onSelectAll = () => onChange(defaultSelections);
  const onDeselectAll = () => onChange([]);

  return (
    <div className={classes.wrapper}>
      <Select
        id="stage-selection-select"
        multiple
        value={selections}
        onChange={onChangeInner}
        className={classes.selectBox}
        renderValue={renderFunction}
        variant="outlined"
        onOpen={() => setSelectOpen(true)}
        onClose={() => setSelectOpen(false)}
      >
        <LiButton onClick={onSelectAll}>Select All</LiButton>
        <LiButton onClick={onDeselectAll}>Deselect All</LiButton>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} style={{ backgroundColor: WHITE }}>
            <Checkbox color="primary" checked={selections.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      <Button
        type="button"
        color="primary"
        variant="contained"
        className={classes.applyFilterButton}
        onClick={onButtonClick}
        disabled={selectOpen}
      >
        Apply filters
      </Button>
    </div>
  );
};

StageSelectionDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  selections: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultSelections: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StageSelectionDropdown;
