import config from '../../config';
import { COURSE_PROD, TEST_USERS_CREATION, TEST_USERS_ENROLLMENT } from '../../constants';
import request from '../../helpers/apiHelper';

export const addTestUsers = async (programId, primaryExperience, start, count, isDefaultExperience,
  branchId, selectedPrograms) => {
  const url = `${config.ROUTES.AUTOMATION}/${COURSE_PROD}/${TEST_USERS_CREATION}/${programId}`;
  const data = {
    primary_experience: primaryExperience,
    user_id_start: start,
    users_count: count,
    is_default_experience: isDefaultExperience,
    branch_id: branchId,
    learning_plans: selectedPrograms,
  };
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const updateTestUsers = async (transactionId, primaryExperience, start, count, branchId,
  selectedPrograms) => {
  const url = `${config.ROUTES.AUTOMATION}/${COURSE_PROD}/${TEST_USERS_CREATION}/${transactionId}`;
  const data = {
    primary_experience: primaryExperience,
    user_id_start: start,
    users_count: count,
    branch_id: branchId,
    learning_plans: selectedPrograms,
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const activateTestUsers = async (transactionId, users) => {
  const url = `${config.ROUTES.AUTOMATION}/${COURSE_PROD}/${TEST_USERS_CREATION}/${transactionId}/activate`;
  const data = {
    users,
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const deactivateTestUsers = async (transactionId, users) => {
  const url = `${config.ROUTES.AUTOMATION}/${COURSE_PROD}/${TEST_USERS_CREATION}/${transactionId}/deactivate`;
  const data = {
    users,
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const updateTestUsersProperty = async (transactionId, users, reset = false, language = '') => {
  const url = `${config.ROUTES.AUTOMATION}/${COURSE_PROD}/${TEST_USERS_CREATION}/${transactionId}/update`;
  const data = {
    users,
    reset,
    language,
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const deleteTestUsers = async (transactionId, users) => {
  const url = `${config.ROUTES.AUTOMATION}/${COURSE_PROD}/${TEST_USERS_CREATION}/${transactionId}/delete`;
  const data = {
    users,
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const fetchActiveLanguages = async () => {
  const url = `${config.ROUTES.SERVICE}/active_languages`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const getAllPrograms = async (programType, searchText, currentPage, stage, cancelToken) => {
  const url = `${config.ROUTES.PROGRAM}/${programType}?search=${encodeURIComponent(
    searchText,
  )}&page=${currentPage}&stage=${stage}`;
  const options = { url, method: 'GET', cancelToken };
  const result = await request(options);

  return result;
};

export const updateTestUsersByLPs = async (transactionId, users, primaryExperience,
  learningPlans) => {
  const url = `${config.ROUTES.AUTOMATION}/${COURSE_PROD}/${TEST_USERS_ENROLLMENT}/${transactionId}`;
  const data = {
    users,
    primary_experience: primaryExperience,
    learning_plans: learningPlans,
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};
