/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import WorkshopManagementCourseNavigation from '../../components/WorkshopManagement/WorkshopManagementCourseNavigation';
import WorkshopManagementSessionCard from '../../components/WorkshopManagement/WorkshopManagementSessionCard';
import WorkshopManagementCreateSession from '../../components/WorkshopManagement/WorkshopManagementCreateSession';
import WorkshopManagementSessionModal from '../../components/WorkshopManagement/WorkshopManagementSessionModal';
import ConfirmModal from '../../components/common/ConfirmModal';
import NoDataComponent from '../../components/common/NoDataComponent';
import Loading from '../../components/common/Loading';

import NoData from '../../assets/img/no-data.svg';

import { ROUTE_REPORT, WORKSHOP_MANAGEMENT_MODELS } from '../../constants';
import {
  getSessionsAndEvents,
  createSession,
  updateSession,
  getSessionDetails,
  deleteSessionByID,
  deleteEventByID,
} from './apis';
import MarkSetUserCompleteConfirmationModal from './MarkSetUserCompleteConfirmationModal';

const WorkshopManagementSessionPage = ({
  match,
  setModel,
  setInfoMessage,
  fetchLatestData,
  setFetchLatestData,
  onSingleSessionSyncClick,
  syncInitiatedSessionId,
  isDisabled,
  onAllUsersProceed,
}) => {
  const history = useHistory();
  const { state } = useLocation();

  const [sessionsAndEvents, setSessionAndEvetsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [eventsDataLoading, setEventsDataLoading] = useState(true);
  const [createSessionModalState, setCreateSessionModalState] = useState(false);
  const [viewSessionModalState, setViewSessionModalState] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [eventConfirmationModalOpen, setEventConfirmationModalOpen] = useState(false);
  const [isSessionEdit, setIsSessionEdit] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const [alertMessage, setAlertMessage] = useState({ type: 'warning', message: '' });
  const [session_ID, setSessionID] = useState('');
  const [session_name, setSessionName] = useState('');
  const [event_ID, setEventID] = useState('');
  const [event_name, setEventName] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const [snackBarSeverity, setSnackBarSeverity] = useState('info');
  const [showMarkUsersCompleteModal, setShowMarkUsersCompleteModal] = useState(false);

  const programId = get(match, 'params.programId');
  const programType = get(match, 'params.programType');
  const programSubType = get(match, 'params.programSubType');
  const course_id = get(match, 'params.transactionId');
  const uploadUsers = 'uploadUsers';
  const { courseName } = state;
  const { course_sessions = [], enrolled_users = 0 } = sessionsAndEvents;

  const goBackToCourse = (replace = false) => {
    history[replace ? 'replace' : 'push'](
      `/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}`,
    );
  };

  const goToMarkUser = () => {
    history.push(`/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}/${course_id}/${uploadUsers}`, {
      courseName,
    });
  };

  if (!state) {
    goBackToCourse(true);
  }

  const hideSnackbar = useCallback(() => {
    setTimeout(() => {
      setSnackBarMessage(null);
    }, 6000);
  }, []);

  const fetchData = useCallback(async () => {
    setEventsDataLoading(true);
    try {
      const response = await getSessionsAndEvents(programId, course_id);
      setSessionAndEvetsData(response?.data);
      setEventsDataLoading(false);
    } catch (e) {
      setEventsDataLoading(false);
    }
  }, [programId, course_id]);

  useEffect(() => {
    if (fetchLatestData) {
      fetchData();
      setFetchLatestData(false);
    }
  }, [fetchLatestData, fetchData, setFetchLatestData]);

  const onCreateSession = async (values) => {
    setIsLoading(true);
    try {
      const response = await createSession(course_id, values);
      if (response && response.data && response.data.success) {
        setIsLoading(false);
        setCreateSessionModalState(false);
        setInfoMessage({
          type: 'success',
          message: `Session '${values.session_name}' created successfully.`,
        });
        fetchData();
      }
    } catch (e) {
      setIsLoading(false);
      setAlertMessage({
        type: 'error',
        message: e?.response?.data?.message || 'Unable to create session',
      });
      console.error(e);
    }
  };

  const onUpdateSession = async (values) => {
    setIsLoading(true);
    const sessionId = values?.id;
    try {
      const response = await updateSession(sessionId, values);
      if (response && response.data && response.data.success) {
        setIsLoading(false);
        setCreateSessionModalState(false);
        setInfoMessage({
          type: 'success',
          message: `Session '${values.session_name}' updated successfully.`,
        });
        fetchData();
      }
    } catch (e) {
      setIsLoading(false);
      setAlertMessage({
        type: 'error',
        message: e?.response?.data?.message || 'Unable to update session',
      });
      console.error(e);
    }
  };

  const fetchSessiondata = async (sessionId) => {
    setIsLoading(true);
    try {
      const response = await getSessionDetails(sessionId);
      if (response?.data && response.data.success) {
        setIsLoading(false);
        setSessionData(response.data?.session);
      }
    } catch (e) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const closeModal = useCallback(() => {
    setAlertModalOpen(false);
  }, [setAlertModalOpen]);

  const closeEventDeletionModal = useCallback(() => {
    setEventConfirmationModalOpen(false);
  }, [setEventConfirmationModalOpen]);

  const deleteSession = useCallback(async () => {
    closeModal();
    setSnackBarSeverity('info');
    setSnackBarMessage('Deleting the session, this may take a few seconds');
    hideSnackbar();
    try {
      const response = await deleteSessionByID(session_ID);
      if (response?.data && response.data.success) {
        if (response.data.delete_session) {
          setSnackBarSeverity('success');
          setSnackBarMessage('Session deleted successfully');
          fetchData();
          hideSnackbar();
        } else {
          setSnackBarSeverity('error');
          setSnackBarMessage('Failed to delete the session, please try again');
          hideSnackbar();
        }
        setSessionData(response.data?.session);
      }
    } catch (e) {
      setSnackBarSeverity('error');
      setSnackBarMessage('Failed to delete the event, please try again');
      hideSnackbar();
      console.error(e);
    }
  }, [session_ID, closeModal, fetchData, hideSnackbar]);

  const deleteEvent = useCallback(async () => {
    closeEventDeletionModal();
    setSnackBarSeverity('info');
    setSnackBarMessage('Deleting the event, this may take a few seconds');
    hideSnackbar();
    try {
      const response = await deleteEventByID(event_ID);
      if (response?.data && response.data.success) {
        if (response.data.delete_event) {
          setSnackBarSeverity('success');
          setSnackBarMessage('Event deleted successfully');
          fetchData();
          hideSnackbar();
        } else {
          setSnackBarSeverity('error');
          setSnackBarMessage('Failed to delete the event, please try again');
          hideSnackbar();
        }
        setSessionData(response.data?.event);
      }
    } catch (e) {
      setSnackBarSeverity('error');
      setSnackBarMessage('Failed to delete the event, please try again');
      hideSnackbar();
      console.error(e);
    }
  }, [event_ID, closeEventDeletionModal, fetchData, hideSnackbar]);

  const onEditSessionClick = (sessionId) => {
    setIsSessionEdit(true);
    if (_isEmpty(setSessionData)) fetchSessiondata(sessionId);
    setCreateSessionModalState(true);
  };

  const onViewSessionClick = (sessionId) => {
    if (_isEmpty(setSessionData)) fetchSessiondata(sessionId);
    setViewSessionModalState(true);
  };

  const preCloseModal = () => {
    setCreateSessionModalState(false);
    setAlertMessage({ type: 'warning', message: '' });
    setIsSessionEdit(false);
  };

  const onEditDetailsClick = () => {
    setViewSessionModalState(false);
    setIsSessionEdit(true);
    setCreateSessionModalState(true);
  };

  const onDeleteSessionClick = (sessionId, sessionName) => {
    setAlertModalOpen(true);
    setSessionID(sessionId);
    setSessionName(sessionName);
  };

  const onDeleteEventClick = (eventID, eventName) => {
    setEventConfirmationModalOpen(true);
    setEventID(eventID);
    setEventName(eventName);
  };

  useEffect(() => {
    fetchData();

    return () => {
      window.history.replaceState(null, '');
    };
  }, [programId, fetchData]);

  const sessionsAndEventsExists = course_sessions?.length > 0;

  const getSessionsJsx = () => {
    if (eventsDataLoading) {
      return <Loading />;
    }
    if (sessionsAndEventsExists) {
      return (
        course_sessions.map((session) => (
          <WorkshopManagementSessionCard
            key={session.id}
            enrolled_users={enrolled_users}
            data={session}
            onViewSessionClick={() => onViewSessionClick(session?.id)}
            editSession={() => onEditSessionClick(session?.id)}
            onDeleteSessionClick={() => onDeleteSessionClick(session?.id, session?.name)}
            onDeleteEventClick={(eventId, eventName) => onDeleteEventClick(eventId, eventName)}
            addEvent={() => setModel({
              view: WORKSHOP_MANAGEMENT_MODELS.ADD_EVENT,
              sessionId: session.id,
            })}
            editEvent={(eventId) => setModel({
              view: WORKSHOP_MANAGEMENT_MODELS.EDIT_EVENT,
              sessionId: session.id,
              eventId,
            })}
            onSingleSessionSyncClick={() => onSingleSessionSyncClick(session.id)}
            syncInitiatedSessionId={syncInitiatedSessionId}
            isDisabled={isDisabled}
          />
        ))
      );
    }
    return (<NoDataComponent imgSrc={NoData} primaryText="No sessions added yet!" />);
  };

  const handleMarkUsersProceed = () => {
    setShowMarkUsersCompleteModal(false);
    onAllUsersProceed(course_id);
  };

  return (
    <>
      <WorkshopManagementCourseNavigation
        match={match}
        goBack={goBackToCourse}
        courseName={courseName}
        onAddSessionClick={() => setCreateSessionModalState(true)}
        onAddEventClick={() => {}}
        onMarkUserClick={goToMarkUser}
        disabled={isDisabled}
        sessionsAndEventsExists={sessionsAndEventsExists}
        setShowMarkUsersCompleteModal={setShowMarkUsersCompleteModal}
      />
      {getSessionsJsx()}
      <WorkshopManagementCreateSession
        open={createSessionModalState}
        onClose={preCloseModal}
        isSessionEdit={isSessionEdit}
        sessionData={sessionData}
        isLoading={isLoading}
        onCreateSession={onCreateSession}
        onUpdateSession={onUpdateSession}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
      />
      <WorkshopManagementSessionModal
        open={viewSessionModalState}
        onClose={() => setViewSessionModalState(false)}
        onEditDetailsClick={onEditDetailsClick}
        data={sessionData}
        isLoading={isLoading}
        isEditDisabled={isDisabled}
      />
      <ConfirmModal
        open={eventConfirmationModalOpen}
        message={`Are you sure you want to delete ${event_name} ?`}
        onClose={closeEventDeletionModal}
        onConfirm={deleteEvent}
        sessionID={session_ID}
        eventID={event_ID}
        eventName={event_name}
      />
      <ConfirmModal
        open={alertModalOpen}
        message={`Are you sure you want to delete ${session_name}?`}
        onClose={closeModal}
        onConfirm={deleteSession}
        sessionID={session_ID}
        sessionName={session_name}
      />
      {showMarkUsersCompleteModal && (
      <MarkSetUserCompleteConfirmationModal
        open={showMarkUsersCompleteModal}
        onClose={() => setShowMarkUsersCompleteModal(false)}
        onConfirm={handleMarkUsersProceed}
        courseName={courseName}
      />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackBarMessage}
        onClose={() => setSnackBarMessage(null)}
      >
        <Alert onClose={() => setSnackBarMessage(null)} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

WorkshopManagementSessionPage.defaultProps = {
  isDisabled: false,
};

WorkshopManagementSessionPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  setModel: PropTypes.func.isRequired,
  setInfoMessage: PropTypes.func.isRequired,
  fetchLatestData: PropTypes.bool.isRequired,
  setFetchLatestData: PropTypes.func.isRequired,
  onSingleSessionSyncClick: PropTypes.func.isRequired,
  syncInitiatedSessionId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  onAllUsersProceed: PropTypes.func.isRequired,
};

export default WorkshopManagementSessionPage;
