import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import {
  MCKINSEY_BLUE, MODERATE_DARK_GREY, MODERATE_LIGHT_GREY, WHITE,
} from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    minHeight: '7.5rem',
    padding: '0.75rem',
  },
  title: {
    fontSize: '1rem',
    marginBottom: '0.5rem',
    marginTop: '0.375rem',
  },
  inputField: {
    height: '2.5rem',
    marginBottom: '1.25rem',
  },
  saveButton: {
    backgroundColor: MCKINSEY_BLUE,
    color: WHITE,
    width: '5rem',
    height: '2.5rem',
    fontSize: '1rem',
    // marginBottom: '1.75rem', // On hold
    opacity: ({ buttonDisabled }) => (buttonDisabled ? '50%' : '100%'),
  },
  anchorRow: {
    display: 'flex',
    flex: 0.5,
  },
  linkAnchor: {
    flex: 1,
  },
  editButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
  },
  editIcon: {
    height: '1rem',
    width: '1rem',
    color: MODERATE_DARK_GREY,
  },
});

const EditLinkTooltip = ({ title, defaultUrl, onSave }) => {
  const [url, setUrl] = useState(defaultUrl);
  const [isEditing, setIsEditing] = useState(!`${defaultUrl}`.length);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const classes = useStyles({ buttonDisabled });

  const onSaveInner = () => {
    onSave(url);
    setIsEditing(false);
  };

  useEffect(() => {
    // https://stackoverflow.com/a/3809435/1217998
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);

    setButtonDisabled(!url.match(regex));
  }, [url]);

  if (isEditing) {
    return (
      <div className={classes.wrapper}>
        <Typography className={classes.title}>{title}</Typography>
        <input
          className={classes.inputField}
          onChange={(e) => setUrl(e.target.value)}
          value={url}
          placeholder="http://www.google.com"
        />
        <button
          disabled={buttonDisabled}
          className={classes.saveButton}
          type="button"
          onClick={onSaveInner}
        >
          Save
        </button>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.anchorRow}>
        <a target="_blank" rel="noopener noreferrer" className={classes.linkAnchor} href={url}>
          {url}
        </a>
        <button className={classes.editButton} type="button" onClick={() => setIsEditing(true)}>
          <EditOutlinedIcon className={classes.editIcon} />
        </button>
      </div>
    </div>
  );
};

EditLinkTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  defaultUrl: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditLinkTooltip;
