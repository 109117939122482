import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useStyles, useDialogStyles } from '../../../stylesheets/commonModal.styles';
import XlsxImage from '../../../assets/icons/xlsx.png';
import CustomSelect from '../../common/CustomSelect';

const useAdditionalStyles = makeStyles({
  banner: {
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    fontSize: '1rem',
    height: '23rem',
  },
  select: {
    width: '23rem',
  },
  rowCountText: {
    fontSize: '1.5rem',
    width: '30rem',
    marginBottom: '2.188rem',
  },
});

const Banner = ({ xlsxName }) => {
  const classes = useStyles();
  const additionalClasses = useAdditionalStyles();
  return (
    <div className={`${classes.banner} ${additionalClasses.banner}`}>
      <img src={XlsxImage} width={33} height={32} alt="XLSX" />
      <Typography className={classes.bannerText}>
        {xlsxName}
        {' '}
        uploaded sucessfully
      </Typography>
    </div>
  );
};

Banner.propTypes = {
  xlsxName: PropTypes.string.isRequired,
};

const StatusSelectModal = ({
  open, onClose, handleChangeStatusAction, selectedFileName, statusValues, excelRowCount,
}) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const additionalClasses = useAdditionalStyles();

  const [selectedStatus, setSelectedStatus] = useState(null);

  const closeAndSetStateNull = () => { onClose(); setSelectedStatus(null); };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      disableBackdropClick
      onClose={closeAndSetStateNull}
      open={open}
      classes={dialogClasses}
    >
      <MuiDialogTitle disableTypography className={classes.titleBlock}>
        {onClose ? (
          <div className={additionalClasses.dialogTitle}>
            <Banner xlsxName={selectedFileName} />
            <div>
              <IconButton aria-label="close" className={classes.closeButton} onClick={closeAndSetStateNull}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        ) : null}

      </MuiDialogTitle>
      <MuiDialogContent className={additionalClasses.dialogContent}>

        <div>
          <Typography className={`${classes.titleText} ${additionalClasses.rowCountText}`}>
            Total Users in Excel:
            {' '}
            {excelRowCount}
          </Typography>
        </div>
        <div className={additionalClasses.select}>
          Change the status of users
          <CustomSelect
            options={statusValues}
            placeholder="Choose Action"
            onChange={(e) => setSelectedStatus(e.value)}
            defaultValue={selectedStatus}
            maxMenuHeight={100}
          />
        </div>

      </MuiDialogContent>
      <MuiDialogActions className={classes.buttonActions}>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedStatus === null}
          onClick={() => { setSelectedStatus(null); handleChangeStatusAction(selectedStatus); }}
        >
          Proceed
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

StatusSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleChangeStatusAction: PropTypes.func.isRequired,
  selectedFileName: PropTypes.string.isRequired,
  statusValues: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  excelRowCount: PropTypes.number.isRequired,
};

export default StatusSelectModal;
