import React, {
  useState, useEffect, memo, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _map from 'lodash/map';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import { MCKINSEY_BLUE_DISABLED } from '../../stylesheets/colors';

import DropDownMenu from './DropdownMenu';
import TestUserConfirmModal from './TestUserConfirmModal';
import ConfirmTestUserResetPassword from './ConfirmTestUserResetPassword';
import EditLanguagePreferences from './EditLanguagePreferences';
import EnrollAdditionalPrograms from './EnrollAdditionalPrograms';
import DeleteUserConfirmModal from './deleteUserConfirmModal';
import { testUsersOptions } from '../../constants';
import CreateMoreUsersModal from './CreateMoreUsersModal';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '3rem',
    marginTop: '2rem',
    alignItems: 'flex-end',
  },
  usersCreateSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.5,
  },
  usersCreateWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.125rem',
  },
  addIcon: {
    color: theme.palette.primary.main,
    width: '1.5rem',
    height: '1.5rem',
  },
  addUsersButton: {
    backgroundColor: 'transparent',
    '&:disabled': {
      '& > svg > path': {
        fill: MCKINSEY_BLUE_DISABLED,
      },
    },
  },
  innerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labels: {
    display: 'flex',
    flex: 0.35,
  },
  buttonWrapper: {
    flex: 0.2,
    display: 'flex',
    alignItems: 'flex-end',
  },
  addButton: {
    flex: 1,
    height: '2.5rem',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalDetail: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalText: {
    color: '#051c2c',
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    marginTop: '0.938rem',
  },
  loader: {
    width: '10.25rem',
    height: '10.25rem',
  },
  mb: {
    marginBottom: '0.875rem',
  },
}));

const AddMoreUsers = ({
  primaryExperience,
  onAddMoreUsers,
  startingValueDefault,
  selectedUsers,
  onStatusChange,
  onTestUserUpdate,
  onTestUserDelete,
  enrollAdditionalProgram,
  experienceTypeOptions,
  activeLanguages,
  programType,
  configId,
}) => {
  const [startingAt, setStartingAt] = useState(startingValueDefault);
  const [countObj, setCountObj] = useState(testUsersOptions[1]);
  const [addUserVisible, setAddUserVisibility] = useState(false);
  const [userStatusModal, setUserStatusModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [resetAccessModal, setResetAccessModal] = useState(false);
  const [enrollProgramModal, setEnrollProgramModal] = useState(false);
  const [languagePreferencesModal, setLanguagePreferencesModal] = useState(false);

  const count = countObj.value;
  const [users, setUsers] = useState({
    active: [],
    inactive: [],
  });
  const [action, setAction] = useState(null);
  const [filterKey, setFilterKey] = useState('');

  const openModal = (flag) => {
    setAction(flag);
    setUserStatusModal(true);
  };

  const menuItems = [
    {
      key: 'inactive',
      title: 'Activate',
      showTo: 'onlyInactiveUser',
      onClick: () => openModal(true),
    },
    {
      key: 'active',
      title: 'Deactivate',
      showTo: 'onlyActiveUser',
      onClick: () => openModal(false),
    },
    {
      key: 'deleteUsers',
      title: 'Delete Users',
      showTo: 'allUsers',
      onClick: () => setDeleteUserModal(true),
    },
    {
      key: 'resetPassword',
      title: 'Reset Password',
      showTo: 'onlyActiveUser',
      onClick: () => setResetAccessModal(true),
    },
    {
      key: 'enrollProgram',
      title: 'Enroll to additional LPs',
      showTo: 'onlyActiveUser',
      onClick: () => setEnrollProgramModal(true),
    },
    {
      key: 'editLanguagePreferences',
      title: 'Edit Language Preferences',
      showTo: 'onlyActiveUser',
      onClick: () => setLanguagePreferencesModal(true),
    },
  ];

  const classes = useStyles();
  const dropdownRef = useRef();

  const AddUserIcon = addUserVisible ? RemoveCircleOutlineOutlinedIcon : AddCircleOutlineIcon;

  useEffect(() => {
    // Hide the add groups on tab change
    setAddUserVisibility(false);

    // Update starting at on tab change
    setStartingAt(startingValueDefault);
  }, [primaryExperience, startingValueDefault]);

  const closeUserStatusModal = () => {
    setAction(null);
    setUserStatusModal(false);
    dropdownRef.current.handleClose();
  };

  const closeDeleteUserModal = () => {
    setAction(null);
    setDeleteUserModal(false);
    dropdownRef.current.handleClose();
  };

  const closeResetAccessModal = () => {
    setAction(null);
    setResetAccessModal(false);
    dropdownRef.current.handleClose();
  };

  const closeEnrollProgramModal = () => {
    setAction(null);
    setEnrollProgramModal(false);
    dropdownRef.current.handleClose();
  };

  const closeLanguagePreferencesModal = () => {
    setLanguagePreferencesModal(false);
  };

  const onConfirm = () => {
    if (action) {
      onStatusChange(users.inactive, true);
    } else {
      onStatusChange(users.active, false);
    }
    closeUserStatusModal();
  };

  const onDelete = () => {
    onTestUserDelete(_map(selectedUsers, 'username'));
    closeDeleteUserModal();
  };

  const onResetAccess = () => {
    onTestUserUpdate(users.active, true, '');
    closeResetAccessModal();
  };

  const onEnrollProgram = (additionalPrograms) => {
    enrollAdditionalProgram(users.active, primaryExperience, additionalPrograms);
    closeEnrollProgramModal();
  };

  const onlanguageReset = (pickedLanguage) => {
    onTestUserUpdate(users.active, false, pickedLanguage);
    closeLanguagePreferencesModal();
  };

  useEffect(() => {
    if (selectedUsers.length) {
      const userList = selectedUsers.reduce(
        (ini, u) => {
          ini[u.status === 'true' ? 'active' : 'inactive'].push(u.username);
          return ini;
        },
        {
          active: [],
          inactive: [],
        },
      );
      let filterUserType = '';
      const { active, inactive } = userList;
      if (active.length && inactive.length) {
        filterUserType = 'allUsers';
      } else if (active.length && !inactive.length) {
        filterUserType = 'onlyActiveUser';
      } else if (!active.length && inactive.length) {
        filterUserType = 'onlyInactiveUser';
      }

      setUsers(userList);
      setFilterKey(filterUserType);
    } else {
      setFilterKey('');
      dropdownRef.current.handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  const PEExist = useMemo(() => _find(experienceTypeOptions, { value: primaryExperience }), [
    experienceTypeOptions,
    primaryExperience,
  ]);

  const addUsersMessage = `Test Users created (${startingValueDefault - 1})`;
  return (
    <div className={classes.wrapper}>
      <div className={classes.usersCreateSection}>
        <div className={classes.usersCreateWrapper}>
          <span>{addUsersMessage}</span>
          <button
            onClick={() => PEExist && setAddUserVisibility((old) => !old)}
            type="button"
            disabled={!PEExist}
            className={classes.addUsersButton}
          >
            <AddUserIcon className={classes.addIcon} />
          </button>
        </div>
        {addUserVisible ? (
          <CreateMoreUsersModal
            open={addUserVisible}
            onClose={() => setAddUserVisibility(false)}
            onCreate={() => onAddMoreUsers(primaryExperience, startingAt, count)}
            startingValueDefault={startingValueDefault}
            startingAt={startingAt}
            setStartingAt={setStartingAt}
            countObj={countObj}
            setCountObj={setCountObj}
          />
        ) : null}
      </div>
      <div className={classes.actionContainer}>
        <DropDownMenu title="Action on Users" ref={dropdownRef} menuItems={menuItems} disableBtn={!selectedUsers.length}>
          {menuItems.filter((menuItem) => (menuItem.showTo === 'allUsers' || menuItem.showTo === filterKey)).map((value) => (
            <MenuItem
              key={value.key}
              disabled={value.disable}
              onClick={value.onClick}
            >
              {value.title}
            </MenuItem>
          ))}
        </DropDownMenu>
        <TestUserConfirmModal
          action={action}
          users={action ? users.inactive : users.active}
          open={userStatusModal}
          onClose={closeUserStatusModal}
          onConfirm={onConfirm}
        />
        <DeleteUserConfirmModal
          users={_map(selectedUsers, 'username')}
          open={deleteUserModal}
          onClose={closeDeleteUserModal}
          onDelete={onDelete}
        />
        <ConfirmTestUserResetPassword
          users={users.active}
          open={resetAccessModal}
          onClose={closeResetAccessModal}
          onResetAccess={onResetAccess}
        />
        <EnrollAdditionalPrograms
          users={users.active}
          selectedUsers={selectedUsers}
          open={enrollProgramModal}
          programType={programType}
          configId={configId}
          onClose={closeEnrollProgramModal}
          onEnrollProgram={onEnrollProgram}
        />
        {languagePreferencesModal ? (
          <EditLanguagePreferences
            users={users.active}
            open={languagePreferencesModal}
            onClose={closeLanguagePreferencesModal}
            onlanguageReset={onlanguageReset}
            activeLanguages={activeLanguages}
          />
        ) : null}
      </div>
    </div>
  );
};

AddMoreUsers.defaultProps = {
  activeLanguages: [],
};

AddMoreUsers.propTypes = {
  primaryExperience: PropTypes.string.isRequired,
  onAddMoreUsers: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onTestUserUpdate: PropTypes.func.isRequired,
  onTestUserDelete: PropTypes.func.isRequired,
  enrollAdditionalProgram: PropTypes.func.isRequired,
  startingValueDefault: PropTypes.number.isRequired,
  experienceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
      status: PropTypes.string,
      enrolledPrograms: PropTypes.string,
    }),
  ).isRequired,
  activeLanguages: PropTypes.array,
  programType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
};

export default memo(AddMoreUsers);
