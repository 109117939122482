import config from '../../config';
import request from '../../helpers/apiHelper';

export const getOverviewDetails = async (programId) => {
  const url = `${config.ROUTES.PRODUCTION_CHECKLIST}/overview/${programId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const getProgramDetails = async (programId) => {
  const url = `${config.ROUTES.PRODUCTION_CHECKLIST}/program_details/${programId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const getDoceboDetails = async (programId, isRefresh) => {
  const url = `${config.ROUTES.PRODUCTION_CHECKLIST}/docebo_details/${programId}?update=${isRefresh}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const getElucidatDetails = async (programId, isRefresh) => {
  const url = `${config.ROUTES.PRODUCTION_CHECKLIST}/elucidat_details/${programId}?update=${isRefresh}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const getNonElucidatDetails = async (programId) => {
  const url = `${config.ROUTES.PRODUCTION_CHECKLIST}/non_elucidat_details/${programId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const postProdChecklist = async (data, programId) => {
  const url = `${config.ROUTES.PRODUCTION_CHECKLIST}/${programId}`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const getChannels = (programId) => {
  const url = `${config.ROUTES.OVERVIEW_CONTENT}/channel/${programId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const getTestUsersByTid = async (programId) => {
  const url = `${config.ROUTES.PRODUCTION_CHECKLIST}/test_users_details/${programId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const getJournalDetails = async (programId) => {
  const url = `${config.ROUTES.PRODUCTION_CHECKLIST}/journal_details/${programId}`;
  const options = { url, method: 'GET' };
  return request(options);
};
