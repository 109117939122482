import React, {
  useState, useCallback, useEffect,
} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash/lang';
import FormattedTypography from '../common/FormattedTypography';
import SelectedUserTable from './SelectedUserTable';
import ConfirmationModal from '../common/ConfirmationModal';
import CustomSelect from '../common/CustomSelect';

const useStyles = makeStyles(() => ({
  userTable: {
    marginBottom: '2rem',
  },
  width100: {
    width: '100%',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
  },
  menuIcon: {
    flex: '0.2',
    height: '1.5rem',
    lineHeight: '1.5rem',
  },
  menuText: {
    flex: '0.8',
  },
  modalHeight: {
    minHeight: '400px',
  },
}));

const EditLanguagePreferences = ({
  users, open, onClose, onlanguageReset, activeLanguages, userObj,

}) => {
  const classes = useStyles();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [languages, setLanguages] = useState([]);
  const [disableReset, setDisableReset] = useState(true);

  const modifyLanguages = useCallback(() => {
    let updatedLanguages = cloneDeep(activeLanguages);
    updatedLanguages = Object.entries(updatedLanguages).map(([, value]) => {
      const { description } = value;
      const langObj = { ...value };
      langObj.label = description;
      langObj.value = description;
      return langObj;
    });
    setLanguages(updatedLanguages);
  }, [activeLanguages]);

  const changeLanguage = (e) => {
    if (e.code !== defaultLanguage.code) {
      setDisableReset(false);
    } else {
      setDisableReset(true);
    }
    setSelectedLanguage(e);
  };

  useEffect(() => {
    async function getLanguage() {
      modifyLanguages();
      if (!isEmpty(userObj)) {
        const { language: previousSelection } = userObj;
        let selectedLanguageObj = Object.keys(activeLanguages)
          .find((key) => activeLanguages[key]?.code === previousSelection.toLowerCase());
        selectedLanguageObj = activeLanguages[selectedLanguageObj];
        selectedLanguageObj.label = selectedLanguageObj.description;
        selectedLanguageObj.value = selectedLanguageObj.description;
        setSelectedLanguage(selectedLanguageObj);
        setDefaultLanguage(selectedLanguageObj);
      }
    }
    getLanguage();
  }, [activeLanguages, modifyLanguages, userObj, users.length]);

  const onConfirm = () => {
    onlanguageReset(selectedLanguage?.code);
    setSelectedLanguage('');
    setDefaultLanguage('');
  };

  const onCancel = () => {
    setSelectedLanguage('');
    setDefaultLanguage('');
    onClose();
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onCancel}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      title={(
        <FormattedTypography
          variant="subtitle2"
          body="Reset language preferences"
          subHeading={`Language preferences will be reset for ${users.length} selected active users.`}
          dense
        />
      )}
      content={(
        <Grid container justifyContent="space-between">
          <Grid item md={6}><SelectedUserTable title="Test Users" list={users} className={classes.userTable} /></Grid>
          <Grid item md={1}>
            <Divider light orientation="vertical" variant="middle" />
          </Grid>
          <Grid item md={5}>
            <CustomSelect
              options={languages}
              placeholder="Select language"
              label="Language"
              onChange={(e) => changeLanguage(e)}
              defaultValue={selectedLanguage}
            />
          </Grid>
        </Grid>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" disabled={disableReset} onClick={onConfirm}>
            Reset language
          </Button>
        </>
      )}
    />
  );
};

export default EditLanguagePreferences;

EditLanguagePreferences.defaultProps = {
  activeLanguages: [],
  userObj: {},
};

EditLanguagePreferences.propTypes = {
  users: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onlanguageReset: PropTypes.func.isRequired,
  activeLanguages: PropTypes.array,
  userObj: PropTypes.object,
};
