import React from 'react';
import PropTypes from 'prop-types';
import useCheckActivityStatus from '../../../hooks/useCheckActivityStatus';
import UserProfileStatusModal from '../../PlatformLevelUsers/UserProfileStatusModal';

const UserProfileModal = ({
  transactionId, status, open, onClose, onDownloadLog, getReport, type,
  pollProgressValue, updatePollProgress, configId, transactionData,
}) => {
  const {
    modalState,
    preClose,
    progressData,
    progressErrorMsg,
  } = useCheckActivityStatus(status,
    open, onClose, pollProgressValue, transactionId, updatePollProgress);

  return (
    open ? (
      <UserProfileStatusModal
        open={open}
        onClose={preClose}
        modalState={modalState}
        transactionId={transactionId}
        progressData={progressData}
        progressErrorMsg={progressErrorMsg}
        getReport={getReport}
        onDownloadLog={onDownloadLog}
        type={type}
        configId={configId}
        transactionData={transactionData}
      />
    ) : null
  );
};

UserProfileModal.propTypes = {
  transactionId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  pollProgressValue: PropTypes.bool.isRequired,
  updatePollProgress: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  transactionData: PropTypes.object.isRequired,
};

export default UserProfileModal;
