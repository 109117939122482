import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import CustomSelect from '../../common/CustomSelect';
import ContentScreenTable from './ContentScreenTable';

import { CHANNELS, COURSES } from '../../../constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  chooseContentDropdownWrapper: {
    display: 'flex',
    maxWidth: '25vw',
    margin: '2rem 0px',
  },
  tableWrapper: {
    display: 'flex',
    flex: 1,
  },
});

const ContentScreen = ({
  type, setType, data,
}) => {
  const classes = useStyles();

  const optionLookup = {
    [COURSES]: { label: 'Courses', value: COURSES },
    [CHANNELS]: { label: 'Channels', value: CHANNELS },
  };

  const options = [optionLookup[COURSES], optionLookup[CHANNELS]];

  const defaultValue = optionLookup[type];

  return (
    <div className={classes.wrapper}>
      <div className={classes.chooseContentDropdownWrapper}>
        <CustomSelect
          options={options}
          placeholder="Please choose content"
          defaultValue={defaultValue}
          label="Choose content"
          onChange={(e) => setType(e.value)}
        />
      </div>
      <div className={classes.tableWrapper}>
        <ContentScreenTable type={type} data={data} />
      </div>
    </div>
  );
};

ContentScreen.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    enrollment_count: PropTypes.number,
    training_materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};

export default ContentScreen;
