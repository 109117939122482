import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  BLACK, LIGHT_YELLOW, DARK_YELLOW, ACCORDION_GREY,
} from '../../stylesheets/colors';
import ConfirmationModal from '../common/ConfirmationModal';
import YellowAlertIcon from '../../assets/icons/icon-yellow-alert.svg';
import RefreshConfirmation from '../../assets/icons/refreshConfirmation.png';

const useStyles = makeStyles({
  infoContentText: {
    color: BLACK,
  },
  alertWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 0',
  },
  alertBox: {
    backgroundColor: LIGHT_YELLOW,
    width: '100%',
    margin: '1rem auto',
    padding: '1rem 2rem',
  },
  alertText: {
    color: DARK_YELLOW,
    paddingLeft: '1rem',
  },
  confirmationHeading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headText: {
    fontSize: '2rem',
    fontWeight: '800',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  modalHeight: {
    minHeight: '25rem',
  },
});

const ReleaseConfirmationModal = ({
  open, onClose, loading, subTitle, mainContent, initiateRelease,
}) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      open={open}
      isLoading={loading}
      onClose={onClose}
      breakpoint="md"
      minHeightClass={classes.modalHeight}
      title={(
        <Container className={classes.confirmationHeading}>
          <img src={RefreshConfirmation} alt="confirmation" height="100" width="100" />
          <Typography variant="h4" className={classes.headText}>
            Re-release of SCORM
          </Typography>
          <Typography variant="subtitle1" className={classes.infoHelperText}>
            {subTitle}
          </Typography>
        </Container>
      )}
      content={(
        <Container maxWidth="sm">
          <Typography variant="subtitle1" className={classes.infoHelperText} style={{ paddingBottom: '2rem', textAlign: 'center' }}>
            {mainContent}
          </Typography>
          <Box className={`${classes.alertWrapper} ${classes.alertBox}`}>
            <img
              src={YellowAlertIcon}
              alt="Alert-round-yellow"
            />
            <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.alertText}`}>
              Please note initiating a re-release on a Launched program will
              impact progress status of the course and bookmarking.
            </Typography>
          </Box>
        </Container>
      )}
      actions={(
        <>
          <Button color="primary" variant="contained" onClick={() => initiateRelease()}>
            Continue Re-release
          </Button>
        </>
      )}
    />
  );
};

ReleaseConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  subTitle: PropTypes.string.isRequired,
  mainContent: PropTypes.string.isRequired,
  initiateRelease: PropTypes.func.isRequired,
};

export default ReleaseConfirmationModal;
