import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import StagesMap from './constants';

import {
  DARK_GREY,
  MODERATE_LIGHT_GREY,
  STAGE_IN_BUILD_COLOR,
  STAGE_LAUNCHED_COLOR,
  STAGE_LAUNCH_PREP_COLOR,
  STAGE_QA_COLOR,
  STAGE_UNKNOWN_COLOR,
  WHITE,
  STAGE_DELETED_COLOR,
  STAGE_REPLICATED_COLOR,
  SKY_BLUE,
  USER_CIRCLE_DARK_BG,
} from '../../stylesheets/colors';

import {
  STAGE_UNKNOWN,
  STAGE_IN_BUILD,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  STAGE_LAUNCHED,
  STAGE_DELETED,
  STAGE_REPLICATED,
  ACADEMY_GO,
} from '../../constants';

import PreLaunchIcon from '../../assets/img/pre-launch.svg';
import PostLaunchIcon from '../../assets/img/post-launch.svg';
import GoAcademy from '../../assets/icons/go-academy-stage.svg';

const decideBgColor = ({ stage }) => ({
  [STAGE_UNKNOWN]: STAGE_UNKNOWN_COLOR,
  [STAGE_IN_BUILD]: STAGE_IN_BUILD_COLOR,
  [STAGE_QA]: STAGE_QA_COLOR,
  [STAGE_LAUNCH_PREP]: STAGE_LAUNCH_PREP_COLOR,
  [STAGE_LAUNCHED]: STAGE_LAUNCHED_COLOR,
  [STAGE_DELETED]: STAGE_DELETED_COLOR,
  [STAGE_REPLICATED]: STAGE_REPLICATED_COLOR,
  [ACADEMY_GO]: SKY_BLUE,
}[stage] || STAGE_UNKNOWN_COLOR);

const decideFgColor = ({ stage }) => ({
  [STAGE_UNKNOWN]: WHITE,
  [STAGE_IN_BUILD]: WHITE,
  [STAGE_QA]: DARK_GREY,
  [STAGE_LAUNCH_PREP]: DARK_GREY,
  [STAGE_LAUNCHED]: WHITE,
  [STAGE_DELETED]: WHITE,
  [STAGE_REPLICATED]: WHITE,
  [ACADEMY_GO]: USER_CIRCLE_DARK_BG,
}[stage] || MODERATE_LIGHT_GREY);

const WRAPPER_WIDTH = '8rem';

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: decideBgColor,
    color: decideFgColor,
    display: 'flex',
    width: 'auto',
    height: '2rem',
    padding: '0.5rem',
    cursor: 'default',
  },
  stageBadgeIcon: {
    color: decideFgColor,
  },
  stageBadgeTitle: {
    fontSize: '0.875rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandMoreButton: {
    backgroundColor: 'transparent',
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandMoreIcon: {
    width: '1rem',
    height: '1rem',
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    width: WRAPPER_WIDTH,
  },
  dropdownOption: {
    height: '2rem',
    backgroundColor: WHITE,
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
});

const WrappingDom = ({
  stage, editable, children, onClick,
}) => {
  const classes = useStyles({ stage });

  if (editable) {
    return (
      <button
        className={classes.wrapper}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return <div className={classes.wrapper}>{children}</div>;
};

WrappingDom.defaultProps = {
  onClick: () => {},
};

WrappingDom.propTypes = {
  stage: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

const StageBadge = ({
  stage,
  editable,
}) => {
  const classes = useStyles({ stage });

  const [innerStage, setInnerStage] = useState(stage);

  useEffect(setInnerStage.bind(this, stage), [stage]);

  const icon = {
    [STAGE_UNKNOWN]: PostLaunchIcon,
    [STAGE_IN_BUILD]: PostLaunchIcon,
    [STAGE_QA]: PreLaunchIcon,
    [STAGE_LAUNCH_PREP]: PreLaunchIcon,
    [STAGE_LAUNCHED]: PostLaunchIcon,
    [STAGE_REPLICATED]: PostLaunchIcon,
    [ACADEMY_GO]: GoAcademy,
  }[innerStage] || PreLaunchIcon;

  const stageText = StagesMap[innerStage] || 'Loading...';

  return (
    <>
      <WrappingDom stage={innerStage} editable={editable}>
        <img className={classes.stageBadgeIcon} src={icon} alt={stageText} />
        {' '}
        &nbsp;
        <Typography component="span" variant="body1" className={classes.stageBadgeTitle}>
          {stageText}
          &nbsp;
        </Typography>
      </WrappingDom>
    </>
  );
};

StageBadge.propTypes = {
  stage: PropTypes.string.isRequired,
  editable: PropTypes.bool,
};

StageBadge.defaultProps = {
  editable: false,
};

export default StageBadge;
