import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Typography } from '@material-ui/core';

import { LoaderInner } from '../Loader';
import InfiniteLoader from '../common/InfiniteLoader';
import { MODERATE_LIGHT_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  bodyWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    '& > :nth-child(1)': {
      margin: '3rem 0 2rem 0',
    },
  },
  closeButton: {
    alignSelf: 'unset',
    height: '46px',
    position: 'absolute',
    right: 0,
    color: theme.palette.grey[500],
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    padding: '1rem 3rem',
    alignItems: 'center',
  },
  listWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    margin: '3rem 0',
    overflowY: 'scroll',
  },
  listItemText: {
    marginLeft: '1rem',
  },
}));

const AssignmentDownloadModal = ({
  open, startBundling, onClose, percentage, fileName, downloadList,
}) => {
  const classes = useStyles();
  const assignmentListDom = downloadList.map((item) => (
    <div key={item} className={classes.listItem}>
      <CheckCircleOutlineIcon />
      <Typography className={classes.listItemText}>{item}</Typography>
    </div>
  ));

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      classes={{ paper: classes.wrapper }}
    >
      <div className={classes.bodyWrapper}>
        <div className={classes.imageWrapper}>
          {startBundling
            ? <InfiniteLoader />
            : <LoaderInner progress={percentage} scaleFactor={0.75} />}
        </div>
        {onClose && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        )}
        <Typography variant="subtitle1">
          {startBundling
            ? 'Please wait while we prepare your files for download (it may take up to few mins)'
            : `Please wait while we prepare ‘${fileName}.zip’ for download`}
        </Typography>
        <div className={classes.listWrapper}>
          {assignmentListDom}
        </div>
      </div>
    </Dialog>
  );
};

AssignmentDownloadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  startBundling: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  downloadList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AssignmentDownloadModal;
