import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import UserEnrollmentStatusModal from '../../../containers/UserEnrollment/UserEnrollmentStatusModal';
import { updateEnrollmentTransaction } from '../../../containers/UserEnrollment/apis';
import useProgressResult from '../../../hooks/useProgressResult';
import {
  CANCELLING, FOR_REPORT, IN_PROGRESS, PROCESSING, REPORT,
} from '../../../constants';
import { getErrorMessage } from '../../../helpers/apiHelper';

const UserEnrollment = ({
  transactionId, status, open, onClose, onDownloadLog, pollProgressValue,
  updatePollProgress, getReport, onCancelTransaction, disableCancelOperation,
  setCancellingTransaction, setSnackbarObj,
}) => {
  const {
    progressResult, progressError, clearProgressResult, clearProgressError,
  } = useProgressResult(pollProgressValue, transactionId, updatePollProgress);

  const [modalState, setModalState] = useState(null);

  // This hook works for status modal on component mounting
  useEffect(() => {
    if (status.length > 0 && open) {
      const updatedStatus = FOR_REPORT.includes(status) ? REPORT : status;
      setModalState(updatedStatus);
    }
  }, [open, status]);

  /* This hook works whenever progressResult changes so that modal
  can change as per the respective transaction status */
  useEffect(() => {
    const tStatus = progressResult?.data?.transaction_status;
    if (tStatus) {
      // CANCELLING scenario is not included in FOR_REPORT
      if (IN_PROGRESS.includes(tStatus) || tStatus === CANCELLING) {
        batchUpdates(() => {
          updatePollProgress(true);
          setModalState(tStatus);
        });
      } else {
        const updatedStatus = FOR_REPORT.includes(tStatus) ? REPORT : tStatus;
        batchUpdates(() => {
          updatePollProgress(false);
          setModalState(updatedStatus);
        });
      }
    }
  }, [progressResult, updatePollProgress]);

  const clearProgressData = useCallback(() => {
    clearProgressResult();
    clearProgressError();
  }, [clearProgressResult, clearProgressError]);

  const preClose = () => {
    setModalState(null);
    clearProgressData();
    onClose();
  };

  const onExistingUsersUpdate = useCallback(async (payload) => {
    try {
      await updateEnrollmentTransaction(payload);
      clearProgressData();
      setModalState(PROCESSING);
      updatePollProgress(true);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      setModalState(null);
      updatePollProgress(false);
    }
  }, [clearProgressData, setSnackbarObj, updatePollProgress]);

  return (
    open ? (
      <UserEnrollmentStatusModal
        open={open}
        onClose={preClose}
        modalState={modalState}
        transactionId={transactionId}
        getReport={getReport}
        progressData={progressResult?.data}
        progressErrorMsg={progressError?.message}
        onDownloadLog={onDownloadLog}
        onCancelTransaction={onCancelTransaction}
        cancellationRequested={disableCancelOperation}
        setCancellationRequested={setCancellingTransaction}
        onExistingUsersUpdate={onExistingUsersUpdate}
      />
    ) : null
  );
};

UserEnrollment.propTypes = {
  transactionId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pollProgressValue: PropTypes.bool.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  updatePollProgress: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  onCancelTransaction: PropTypes.func.isRequired,
  disableCancelOperation: PropTypes.bool.isRequired,
  setCancellingTransaction: PropTypes.func.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
};

export default UserEnrollment;
