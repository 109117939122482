import { create } from 'axios';
import { getDefaultHeaders, getDefaultLTIHeaders } from './userHelper';
import { DOCEBO_CONNECT_FAIL } from '../constants';

const axiosCreate = create();

export default async (options, useLTI = false) => {
  const headers = useLTI ? await getDefaultLTIHeaders() : await getDefaultHeaders();
  return axiosCreate.request({
    headers,
    ...options,
  });
};

export const blobToJson = async (response) => {
  if (response instanceof Blob && response.type === 'application/json') {
    try {
      const text = await response.text();
      return JSON.parse(text);
    } catch {
      return response;
    }
  }
  return response;
};

export const getErrorMessage = (error, message) => {
  const errorMsg = error?.response?.data?.message || message;
  return errorMsg || DOCEBO_CONNECT_FAIL;
};
