import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Box from '@material-ui/core/Box';
import CustomUploadModal from '../common/CustomUploadModal';
import FormattedTypography from '../common/FormattedTypography';
import ForceEnrollLogo from '../../assets/img/force-enroll-logo.png';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    margin: '0 auto',
  },
}));

const ForceEnrollConfirmation = ({
  open,
  onClose,
  deltaCount,
  onForceEnroll,
}) => {
  const classes = useStyles();

  return (
    <CustomUploadModal
      open={open}
      onClose={onClose}
      contentDivider
      logo={(
        <Box className={classes.logoWrapper}>
          <img src={ForceEnrollLogo} alt="force-alert" width={120} height={120} />
        </Box>
      )}
      primaryBtnText="Force Enroll"
      primaryBtnProps={
        { onClick: onForceEnroll }
      }
      secondaryBtnText="Cancel"
      secondaryBtnProps={
        { onClick: () => onClose() }
      }
      breakpoint="md"
    >
      <FormattedTypography
        body={`We found ${deltaCount} users who are still not enrolled in the Program and it’s components.`}
        subHeading="Click ‘Force Enroll’ to complete their enrollments."
      />
    </CustomUploadModal>
  );
};

ForceEnrollConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deltaCount: PropTypes.number.isRequired,
  onForceEnroll: PropTypes.func.isRequired,
};

export default memo(ForceEnrollConfirmation, (
  (prevProps, nextProps) => prevProps.deltaCount === nextProps.deltaCount
  && prevProps.open === nextProps.open
));
