import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';

import { LoaderInner } from '../Loader';
import ConfirmModalImg from '../../assets/img/confirm-modal.svg';
import { SUCCESS_GREEN, MODERATE_LIGHT_GREY } from '../../stylesheets/colors';

const leftBuffer = '3rem';
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  bodyWrapper: {
    flex: 1,
    padding: '1.75rem 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '6.25rem',
    transform: 'scale(0.4) translate(0, -100%)',
  },
  details: {
    marginTop: '2rem',
  },
  listItem: {
    padding: '16px 0',
    '&::after': {
      position: 'absolute',
      content: '""',
      borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
      bottom: '3px',
      width: `calc(100% - ${leftBuffer})`,
      left: leftBuffer,
    },
  },
  dialogInfo: {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  buttonOutline: {
    height: '1.5rem',
    width: '1.5rem',
    minHeight: 'unset',
    marginRight: '1.25rem',
    cursor: 'unset',
    '& svg': {
      fontSize: '1.25rem',
    },
  },
  buttonOutlineSuccess: {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${SUCCESS_GREEN}`,
    '& svg': {
      color: SUCCESS_GREEN,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${SUCCESS_GREEN}`,
      '& svg': {
        color: SUCCESS_GREEN,
      },
    },
  },
  listIcon: {
    minWidth: 'unset',
  },
  heavyFont: {
    fontWeight: 'bold',
  },
}));

const EditProgramModal = ({
  open, onClose, showProgress, progress, reportData, onProceed, setOpenProgressModal,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.wrapper }}
    >
      <div className={classes.bodyWrapper}>
        {showProgress ? (
          <>
            <div className={classes.progressRow}>
              <LoaderInner progress={progress.percentage} scaleFactor={1} />
            </div>
            <div className={classes.details}>
              <Typography variant="subtitle1">We are editing your Program ID and its components</Typography>
              <List>
                {Object.keys(reportData).map((key) => reportData[key].modalHeading && (
                <ListItem key={key} disableGutters className={classes.listItem}>
                  <ListItemIcon className={classes.listIcon}>
                    <Fab
                      color="primary"
                      component="span"
                      size="small"
                      className={`${classes.buttonOutline} ${classes.buttonOutlineSuccess}`}
                    >
                      <CheckIcon fontSize="small" color="secondary" />
                    </Fab>
                  </ListItemIcon>
                  <ListItemText
                    primary={`Modifying ${reportData[key].modalHeading}`}
                    primaryTypographyProps={{ variant: 'body1', className: classes.heavyFont }}
                  />
                </ListItem>
                ))}
              </List>
            </div>
          </>
        ) : (
          <>
            <DialogTitle disableTypography className={classes.dialogInfo}>
              <img src={ConfirmModalImg} alt="No past records" />
              <Typography variant="body1">Are you sure you want to proceed?</Typography>
              <Typography variant="body1">This action cannot be undone</Typography>
            </DialogTitle>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpenProgressModal(false)}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" onClick={() => onProceed()}>
                Proceed
              </Button>
            </DialogActions>
          </>
        )}
      </div>
    </Dialog>
  );
};

EditProgramModal.defaultProps = {
  onClose: () => {},
};

EditProgramModal.propTypes = {
  open: PropTypes.bool.isRequired,
  showProgress: PropTypes.bool.isRequired,
  onProceed: PropTypes.func.isRequired,
  setOpenProgressModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  progress: PropTypes.shape({
    percentage: PropTypes.number.isRequired,
  }).isRequired,
  reportData: PropTypes.shape({}).isRequired,
};

export default memo(EditProgramModal, (prevProps, nextProps) => prevProps.open === nextProps.open
&& prevProps.showProgress === nextProps.showProgress && prevProps.onProceed === nextProps.onProceed
&& _isEqual(prevProps.progress, nextProps.progress) && prevProps.onClose === nextProps.onClose
&& _isEqual(prevProps.reportData, nextProps.reportData)
&& prevProps.setOpenProgressModal === nextProps.setOpenProgressModal);
