import {
  SET_LOADING,
  CLEAR_ALERTBAR,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILED,
  SET_USER_DETAILS_SUCCESS,
  SET_SNACKBAR,
  FETCH_LANGUAGES,
  UPDATE_USER_DETAILS_STORE,
  SET_PLATFORM_DETAILS_LOADING,
  SAVE_USER_DETAILS,
  TOGGLE_USER_DETAIL_ALERTBAR,
} from '../../constants';

export function getUserDetails(data) {
  return {
    type: FETCH_USER_DETAILS,
    data,
  };
}

export function getUserDetailsSuccess(results) {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    results,
  };
}
export function setUserDetailsSuccess(results) {
  return {
    type: SET_USER_DETAILS_SUCCESS,
    results,
  };
}

export function getUserDetailsFailure(response) {
  return {
    type: FETCH_USER_DETAILS_FAILED,
    response,
  };
}

export function setLoading(payload) {
  return {
    type: SET_LOADING,
    payload,
  };
}

export function setPlatformDetailsLoader(payload) {
  return {
    type: SET_PLATFORM_DETAILS_LOADING,
    payload,
  };
}

export function clearAlertBar() {
  return { type: CLEAR_ALERTBAR };
}

export function setSnackbar(payload) {
  return {
    type: SET_SNACKBAR,
    payload,
  };
}

export function fetchLanguages(payload) {
  return {
    type: FETCH_LANGUAGES,
    payload,
  };
}

export function updateUserDetailsStore(payload) {
  return {
    type: UPDATE_USER_DETAILS_STORE,
    payload,
  };
}

export function saveUserDetails(payload) {
  return {
    type: SAVE_USER_DETAILS,
    payload,
  };
}

export function toggleAlertBar(payload) {
  return {
    type: TOGGLE_USER_DETAIL_ALERTBAR,
    payload,
  };
}
