import { put, call, takeLatest } from 'redux-saga/effects';
import { getUsersSuccess, getUsersFailure, setLoading } from '../PlatformLevelUsers/users';
import fetchUsers from '../async/platformUserAsync';
import { USERS_FETCH } from '../../constants';

export function* getUsersList({ data }) {
  try {
    yield put(setLoading(true));
    const response = yield call(fetchUsers, data);
    yield put(getUsersSuccess(response));
  } catch (err) {
    yield put(getUsersFailure(err.response.data));
  }
}

export function* platformUsersSaga() {
  yield takeLatest(USERS_FETCH, getUsersList);
}
