import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {
  ACCORDION_GREY,
  BLACK, MCKINSEY_BLUE,
  MEDIUM_GREY,
  MODERATE_DARK_GREY,
} from '../../../../../../stylesheets/colors';
import ConfirmationModal from '../../../../../common/ConfirmationModal';
import JournalingDeleteIcon from '../../../../../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:nth-child(4)': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
  promptMetadata: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  optionalTag: {
    fontWeight: 600,
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MCKINSEY_BLUE}`,
    borderRadius: '10px',
    color: MCKINSEY_BLUE,
  },
  promptTitle: {
    color: MODERATE_DARK_GREY,
    fontWeight: 600,
    lineHeight: '1.4em',
  },
  promptsWrapper: {
    width: '100%',
    marginTop: '1.875rem',
    marginBottom: '1.5rem',
    padding: '1.25rem',
  },
}));

const DeletePromptModal = ({
  onClose,
  onConfirm,
  prompt,
  pageId,
}) => {
  const classes = useStyles();
  const { id, is_required, prompt: prompt_text } = prompt;

  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>Delete Prompt</Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            You are about to delete prompt from
            {' '}
            <span style={{ fontWeight: 600 }}>{pageId}</span>
          </Typography>
          <Paper className={classes.promptsWrapper} square>
            <Box className={classes.promptMetadata}>
              <Typography variant="h2" component="p" style={{ color: ACCORDION_GREY }}>Prompt:</Typography>
              {!is_required && (
                <Typography variant="h5" component="p" className={classes.optionalTag}>
                  Optional
                </Typography>
              )}
            </Box>
            <Typography variant="h2" component="p" className={classes.promptTitle}>{prompt_text}</Typography>
          </Paper>

        </Container>
            )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => { onConfirm({ promptId: id }); }}>
            Delete
          </Button>
        </>
      )}
    />
  );
};

DeletePromptModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  prompt: PropTypes.object.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default DeletePromptModal;
