export const statusValues = [
  { label: 'Waiting', value: -2 },
  { label: 'Subscribed', value: 0 },
  { label: 'In progress', value: 1 },
  { label: 'Completed', value: 2 },
  { label: 'Suspended', value: 3 },
];

export const ACTION_CHANGE_STATUS = 'change_status';

export const actionTypeOptions = [
  { label: 'Change Status', value: ACTION_CHANGE_STATUS },
];
