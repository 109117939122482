import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import BackgroundImage from '../../assets/img/error.png';
import { DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  boxContainer: {
    marginLeft: '94px',
    marginTop: '118px',
  },
  backgroundWrapper: {
    backgroundImage: `url(${BackgroundImage})`,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  errorCode: {
    width: '254px',
    height: '199px',
    fontSize: '144px',
    fontWeight: 500,
    color: DARK_GREY,
  },
  errorMessage: {
    width: '491px',
    height: '78px',
    fontSize: '36px',
    fontWeight: '300',
    color: DARK_GREY,
  },
}));

const ErrorPageInner = ({ errorCode, errorText }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.backgroundWrapper} />
      <div className={classes.boxContainer}>
        <div className={classes.errorCode}>{errorCode}</div>
        <div className={classes.errorMessage}>
          {errorText}
        </div>
      </div>
    </div>
  );
};

ErrorPageInner.propTypes = {
  errorCode: PropTypes.string,
  errorText: PropTypes.string,
};

ErrorPageInner.defaultProps = {
  errorCode: '404',
  errorText: 'Ooops! Looks like this page has gone for a ride…',
};

export default ErrorPageInner;
