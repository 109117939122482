import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { DefaultTheme, ThemeShape } from './default-theme';
import colors from './colors';

export const StyledButton = styled.button`
    font-family: ${(props) => props.theme.font_family};
    position: relative;
    font-size: 15px;
    letter-spacing: 0.02rem;
    padding: 12px 16px;
    border-radius: 2px;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translateZ(0);
    text-decoration: none;
    cursor: pointer;
    line-height: 1;
    display: inline-flex;
    align-items: center;

    ${(props) => props.variant === 'primary'
    && css`
        background-color: ${props.theme.primary};
        color: ${props.theme.white};
        border: 1px solid ${props.theme.primary};
        &:hover {
          background-color: ${props.theme.secondary};
        }
      `}

    ${(props) => props.variant === 'secondary'
    && css`
        background-color: transparent;
        color: ${props.theme.primary};
        border: 1px solid ${props.theme.primary};
        &:hover {
          border-color: ${props.theme.secondary};
          color: ${props.theme.secondary};
        }
      `}

    ${(props) => props.variant === 'tertiary'
    && css`
        background-color: transparent;
        color: ${colors.MODERATE_DARK_GREY};
        border: 1px solid ${colors.MCK_SUPER_LIGHT_GRAY};
        &:hover {
          color: ${colors.MCK_SUPER_DARK_GRAY};
        }
      `}

      ${(props) => props.variant === 'danger'
    && css`
          background-color: ${colors.ERROR};
          color: ${props.theme.white};
          border: 1px solid ${colors.ERROR};
          &:hover {
            color: ${props.theme.white};
          }
        `}

    ${(props) => props.size === 'large'
    && css`
        padding: 16px 24px;
        font-size: 16px;
      `}
`;

export const Button = ({
  children, variant, theme, size, onClick, className,
}) => (
  <StyledButton onClick={onClick} variant={variant} theme={theme} size={size} className={className}>
    {children}
  </StyledButton>
);

Button.defaultProps = {
  variant: 'primary',
  theme: DefaultTheme,
  size: 'medium',
  onClick: () => { },
  className: '',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'danger']),
  theme: ThemeShape,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Button;
