import React from 'react';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';

import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import MetadataFormTemplate from './MetadataFormTemplate';
import { DARK_MEDIUM_GREY } from '../../stylesheets/colors';
import filedsToUpdate from './constant';
import { validateEmailSeperatedBySemiColon } from '../../helpers/utils';

const useStyles = makeStyles(() => ({
  userTable: {
    marginBottom: '2rem',
  },
  width100: {
    width: '100%',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
  },
  menuIcon: {
    flex: '0.2',
    height: '1.5rem',
    lineHeight: '1.5rem',
  },
  menuText: {
    flex: '0.8',
  },
  modalHeight: {
    minHeight: '400px',
  },
  textLight: {
    color: DARK_MEDIUM_GREY,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const getValue = (prev, current) => (prev || current);

const validate = (values) => {
  const errors = {};
  if (values['course-runner'] && !validateEmailSeperatedBySemiColon(values['course-runner'])) {
    errors['course-runner'] = 'Component Runner should have valid Email ID/s and separated with a semi colon';
  }
  return errors;
};

const EditMetadata = ({
  open, onClose, data, updateMetadata, isLoading, options,
  status, setStatus, isValidating, setIsValidating,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      componentDoceboId: data.id,
      portfolio: getValue(data.portfolio, ''),
      offering: getValue(data.offering, ''),
      program: getValue(data.program, ''),
      'elucidat-project-id': getValue(data['elucidat-project-id'], ''),
      'course-runner': getValue(data['course-runner'], ''),
      'additional-metadata': getValue(data['additional-metadata'], ''),
      'keep-in-mind': getValue(data['keep-in-mind'], ''),
      'after-you-complete-this-course': getValue(data['after-you-complete-this-course'], ''),
      'progress-logic': getValue(data['progress-logic'], 'Include in reporting'),
      'proficiency-logic': getValue(data['proficiency-logic'], 'Include in reporting'),
    },
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validate,
    onSubmit: (values) => updateMetadata(values),
  });

  const {
    handleSubmit, handleChange, values, setFieldValue, errors, setErrors, resetForm,
  } = formik;

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      isContainForm
      onSubmit={handleSubmit}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      isLoading={isLoading}
      modalWidth={85}
      title={(
        <FormattedTypography
          variant="subtitle2"
          body="Edit metadata for"
          suffix={`\u00A0'${data.courseName}'`}
          subHeading="Any changes made to the metadata here will reflect in Docebo setting too."
          dense
          className={classes.textLight}
          suffixClassName={classes.bold}
          justifyContent="flex-start"
          subHeadingAlignment="left"
        />
      )}
      content={(
        <MetadataFormTemplate
          mode="single"
          values={values}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          options={options}
          selectedFields={filedsToUpdate.map((fld) => fld.key)}
          errors={errors}
          setErrors={setErrors}
          isValidating={isValidating}
          setIsValidating={setIsValidating}
          status={status}
          setStatus={setStatus}
        />
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={errors['elucidat-project-id'] || isValidating || !values.offering || !values.portfolio}>
            Save Changes
          </Button>
        </>
      )}
    />
  );
};

export default EditMetadata;

EditMetadata.defaultProps = {
};

EditMetadata.propTypes = {
  courseName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    courseName: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    portfolio: PropTypes.string.isRequired,
    offering: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    'elucidat-project-id': PropTypes.string,
    'course-runner': PropTypes.string,
    'additional-metadata': PropTypes.string,
    'keep-in-mind': PropTypes.string,
    'after-you-complete-this-course': PropTypes.string,
    'progress-logic': PropTypes.string,
    'proficiency-logic': PropTypes.string,

  }).isRequired,
  updateMetadata: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
  setIsValidating: PropTypes.func.isRequired,
};
