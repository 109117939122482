import config from '../../config';
import request from '../../helpers/apiHelper';

export const getEnrollmentTransactions = async (programId, feature, status, isProcessQueue) => {
  const featureQuery = feature !== 'all' ? `?program_sub_type=${feature}` : '?program_sub_type=';
  const statusQuery = status !== 'all' ? `&status=${status}` : '';
  const url = `${config.ROUTES.PROGRAM}/${programId}/logs${featureQuery}${!isProcessQueue ? statusQuery : ''}${isProcessQueue ? '&request_type=process_queues' : ''}`;
  const method = 'GET';

  const options = { url, method };
  const result = await request(options);

  return result;
};

export const getComponentMetadataTransactions = async () => {
  const url = `${config.API_SERVER}/metadata/transactions`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);
  return result;
};

export const getUsersTransactions = async () => {
  const url = `${config.API_SERVER}/user_profile/transactions`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);
  return result?.data;
};
