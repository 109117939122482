import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CustomSelect from '../common/CustomSelect';
import { MODERATE_LIGHT_GREY, VERY_LIGHT_GREY, WHITE } from '../../stylesheets/colors';
import { headerTitles, tableBodyKeys } from './config';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    backgroundColor: MODERATE_LIGHT_GREY,
    height: '3rem',
  },
  tableRow: {
    height: '3rem',
    backgroundColor: ({ isLast }) => (isLast ? VERY_LIGHT_GREY : WHITE),
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  cell: {
    padding: '0.875rem',
    fontSize: '1.125rem',
  },
  darkCell: {
    padding: '0.875rem',
    backgroundColor: VERY_LIGHT_GREY,
    cursor: 'pointer',
    fontSize: '1.125rem',
  },
});

const WebinarsTableRow = ({ row, onLastActionClick }) => {
  const classes = useStyles();
  const rowDom = tableBodyKeys.map((col) => (
    <td
      className={col === 'lastAction' ? classes.darkCell : classes.cell}
      key={`${row.id}-${col}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(col === 'lastAction' && row[col] !== '') ? { onClick: () => onLastActionClick(row) } : {}}
    >
      {col !== 'lastAction' ? row[col] : (row[col] || 'No action taken yet for this webinar')}
    </td>
  ));

  return (
    <tr className={classes.tableRow} key={row.user}>{rowDom}</tr>
  );
};

WebinarsTableRow.propTypes = {
  row: PropTypes.shape({
    user: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
  onLastActionClick: PropTypes.func.isRequired,
};

const WebinarsTable = ({
  data, actionTypeOptions, onLastActionClick, onActionSelect,
}) => {
  const classes = useStyles();

  const addActionSelectToRow = (row) => (
    <CustomSelect
      options={actionTypeOptions}
      placeholder="Choose Action"
      onChange={(e) => onActionSelect(e.value, row)}
    />
  );

  const dataWithSelector = data.map((row) => ({
    ...row,
    action: addActionSelectToRow(row),
  }));

  const heads = headerTitles.map((column) => (
    <th
      className={classes.cell}
      key={column}
    >
      {column}
    </th>
  ));

  const body = dataWithSelector.map((row, index) => (
    <WebinarsTableRow
      key={row.user}
      row={row}
      index={index}
      onLastActionClick={onLastActionClick}
    />
  ));

  return (
    <div className={classes.wrapper}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableHead}>{heads}</tr>
        </thead>
        <tbody>
          {body}
        </tbody>
      </table>
    </div>
  );
};

WebinarsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    lastAction: PropTypes.string.isRequired,
    enrolled: PropTypes.number.isRequired,
    waitlisted: PropTypes.number.isRequired,
  })).isRequired,
  onLastActionClick: PropTypes.func.isRequired,
  onActionSelect: PropTypes.func.isRequired,
  actionTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

export default WebinarsTable;
