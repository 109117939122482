import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import ReportSidebarCard from './ReportSidebarCard';

import {
  DARK_MEDIUM_GREY,
  MODERATE_LIGHT_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  outerWrapper: {
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    borderStyle: 'hidden solid hidden hidden',
    height: '100%',
  },
  title: {
    color: DARK_MEDIUM_GREY,
    fontSize: '1rem',
    lineHeight: '24px',
    letterSpacing: '0px',
    paddingLeft: '1px',
    paddingTop: '24px',
    marginBottom: '0px',
  },
  innerWrapper: {
    marginLeft: '25px',
    marginRight: '25px',
  },
});

const ReportSidebar = ({
  header, data, onSelect, maxTitleLength, marked,
}) => {
  const classes = useStyles();
  const cardComponents = data.map((card) => (
    <ReportSidebarCard
      key={card.index}
      index={card.index}
      success={card.success}
      title={card.title}
      active={card.active}
      onSelect={onSelect}
      maxTitleLength={maxTitleLength}
      marked={marked}
    />
  ));
  return (
    <div className={classes.outerWrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.title}>
          {header}
        </div>
        {cardComponents}
      </div>
    </div>
  );
};

ReportSidebar.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  maxTitleLength: PropTypes.number.isRequired,
  marked: PropTypes.bool,
};

ReportSidebar.defaultProps = {
  marked: false,
};

export default ReportSidebar;
