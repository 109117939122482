import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ItemsCarousel from 'react-items-carousel';

import { makeStyles } from '@material-ui/core/styles';

import { useWindowWidth } from '@react-hook/window-size';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { DARK_GREY, MCKINSEY_BLUE } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '35px',
    width: '100%',
  },
  title: {
    display: 'flex',
    paddingBottom: '15px',
    fontFamily: 'McKinseySans',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: DARK_GREY,
  },
  chevronBg: {
    background: ({ direction }) => `linear-gradient(to ${direction}, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%)`,
    width: ({ chevronWidth }) => chevronWidth,
    height: '100%',
    display: 'flex',
    justifyContent: ({ direction }) => (direction === 'right' ? 'flex-end' : 'flex-start'),
    alignItems: 'center',
  },
  chevronIcon: {
    color: MCKINSEY_BLUE,
    height: '50px',
    width: '50px',
  },
}));

const Chevron = ({ direction, width }) => {
  const classes = useStyles({ direction, chevronWidth: width });
  const Icon = direction === 'right' ? ChevronRightIcon : ChevronLeftIcon;
  return (
    <div className={classes.chevronBg}>
      <Icon className={classes.chevronIcon} />
    </div>
  );
};

Chevron.propTypes = {
  direction: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

const CarouselCardBlock = ({
  title, cards, CardComponent, onUpdate, doceboCards, onDoceboCardUpdate, DoceboCardComponent,
}) => {
  const classes = useStyles();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const windowWidth = useWindowWidth();
  const [numberOfCards, setNumberOfCards] = useState(4);

  useEffect(() => {
    setNumberOfCards(Number.parseInt((windowWidth * 0.8) / 280, 10));
  }, [windowWidth]);

  if (cards.length === 0 && doceboCards.length === 0) return <div />;

  const cardComponents = cards.map((card) => (
    <CardComponent
      key={card.id}
      id={card.id}
      title={card.title}
      type={card.type}
      releases={card.releases}
      checked={card.checked}
      onUpdate={onUpdate}
      release={card.release}
    />
  ));

  const doceboCardComponents = doceboCards.map((card) => (
    <DoceboCardComponent
      key={card.id}
      id={card.id}
      title={card.title}
      type={card.type}
      versions={card.versions}
      version={card.version}
      checked={card.checked}
      onUpdate={onDoceboCardUpdate}
      createdDates={card.createdDates}
    />
  ));
  const chevronWidth = 200;

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={numberOfCards}
        gutter={1}
        leftChevron={<Chevron direction="left" width={chevronWidth} />}
        rightChevron={<Chevron direction="right" width={chevronWidth} />}
        chevronWidth={chevronWidth}
        showSlither
      >
        {[...cardComponents, ...doceboCardComponents]}
      </ItemsCarousel>
    </div>
  );
};

CarouselCardBlock.propTypes = {
  title: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    releases: PropTypes.arrayOf(PropTypes.any),
    release: PropTypes.any.isRequired,
    checked: PropTypes.bool,
  })).isRequired,
  CardComponent: PropTypes.func.isRequired,
  doceboCards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.number),
    created_by: PropTypes.string.isRequired,
  })).isRequired,
  DoceboCardComponent: PropTypes.func.isRequired,
  onDoceboCardUpdate: PropTypes.func.isRequired,
};

export default CarouselCardBlock;
