import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { CancelToken, isCancel } from 'axios';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { cloneDeep } from 'lodash/lang';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import _debounce from 'lodash/debounce';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  ACCORDION_GREY, MCKINSEY_BLUE, MODERATE_DARK_GREY, SWITCH_GREY,
} from '../../../stylesheets/colors';
import AlertBarWithAction from '../../common/AlertBarWithAction';
import {
  COURSE_OPS, GROUP_ENROLLMENT,
  ROUTE_REPORT,
  UPLOAD_GROUPWORK_ROLES,
} from '../../../constants';
import GroupsTable from '../GroupWorksReportPage/GroupsTable';
import { getErrorMessage } from '../../../helpers/apiHelper';
import {
  createGroupworkGroup,
  getGroupsForAssignment,
  createRemoveGroupWorkTM, removeGroupWorkTM,
} from '../../../containers/GroupWorkPage/apis';
import LabledTextField from '../../common/LabledTextField';
import useAlertBar from '../../../hooks/useAlertBar';
import Select from '../../common/SelectMui';
import Header from '../../common/Header';
import AddAssignmentModal from '../GroupWorksReportPage/AddAssignmentModal';
import RemoveGroupWorkPaxViewModal from '../GroupWorksReportPage/RemoveGroupWorkPaxViewModal';
import AddComponentModal from '../GroupWorksReportPage/AddComponentModal';
import Loading from '../../common/Loading';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  pageHeader: {
    padding: '2rem 4rem 2rem 2rem',
  },
  childrenWrapper: {
    maxWidth: '30%',
  },
  pageWrapper: {
    padding: '1rem 2rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tableWrapper: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0.1rem',
  },
  bold: {
    fontWeight: 'bold',
  },
  componentsBreakdown: {
    fontSize: '0.875rem',
    color: MODERATE_DARK_GREY,
  },
  searchBox: {
    width: '40%',
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > div:nth-child(2)': {
      marginLeft: '1.25rem',
    },
  },
  input: {
    paddingRight: '0 !important',
  },
  inputBox: {
    width: '21rem',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
      height: '31.91px',
    },
    '& input': {
      fontSize: '1rem',
      color: MODERATE_DARK_GREY,
    },
  },
  searchMetadataWrapper: {
    margin: '1rem 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& > span': {
      fontWeight: 'bold',
    },
  },
  groupsCreatedWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  groupInfoContainer: {
    display: 'flex',
    alignItems: 'end',
    marginBottom: '0.7rem',

    '& > button': {
      width: '25rem',
    },
  },
  addGroupsIcon: {
    cursor: 'pointer',
    color: MCKINSEY_BLUE,
    width: '1.2rem',
    height: '1.2rem',
    marginLeft: '0.5rem',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${SWITCH_GREY}`,
    paddingBottom: '1.2rem',

    '& > svg': {
      color: ACCORDION_GREY,
    },

    '& > span': {
      marginLeft: '0.5rem',
      color: ACCORDION_GREY,
    },
  },
  groupsMetadataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '0.875rem',
    color: ACCORDION_GREY,
    whiteSpace: 'nowrap',
  },
  value: {
    fontWeight: 600,
    marginLeft: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
  },
  addRemoveComponentButton: {
    marginBottom: '0.635rem',
  },
  lowerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > div': {
      width: '100%',
      '& > p:nth-child(1)': {
        maxWidth: '98%',
      },
    },
  },
  addRemoveComponent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

let cancelTokenSource = null;

const getGroupsHelper = async (
  searchText,
  searchType,
  groups,
  setGroups,
  hasMore,
  setHasMore,
  currentPage,
  setCurrentPage,
  setSnackbarObj,
  setIsLoading,
  setGroupsCount,
  programId,
  setTotalGroupsLength,
  setGroupRange,
  setComponent,
  setIsPageLoading,
) => {
  if (!hasMore) return;

  // Cancel old request
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }

  try {
    cancelTokenSource = CancelToken.source();
    setIsLoading(true);
    const nextPage = currentPage + 1;
    const result = await getGroupsForAssignment({
      searchText,
      searchType,
      currentPage: nextPage,
      cancelToken: cancelTokenSource.token,
      programId,
      data: {
        program_id: programId,
      },
      all_groups_info: false,
    });
    const newGroups = result?.groups;
    const totalPrograms = result?.total_rows;
    const highestGroupNumber = result?.highest_group_number;
    const lowestGroupNumber = result?.lowest_group_number;
    const component = result?.course_details;
    batchUpdates(() => {
      setGroups(groups.concat(newGroups));
      setHasMore(result.has_more);
      setCurrentPage(nextPage);
      setGroupsCount(totalPrograms);
      setIsLoading(false);
      setIsPageLoading(false);
      setGroupRange({
        highest_group_number: highestGroupNumber,
        lowest_group_number: lowestGroupNumber,
      });
      setComponent(component);
      setTotalGroupsLength(highestGroupNumber || 0);
    });
  } catch (e) {
    if (!isCancel(e)) {
      batchUpdates(() => {
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        setHasMore(true);
        setIsLoading(false);
        setIsPageLoading(false);
      });
    }
  }
};

const AssignmentDetails = ({
  setSnackbarObj, programSubType, configId, programId,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [groups, setGroups] = useState([]);
  const [totalGroupsLength, setTotalGroupsLength] = useState(0);
  const [groupsCount, setGroupsCount] = useState(0);
  const [searchType, setSearchType] = useState('group_name');
  const [component, setComponent] = useState({});
  const [showAddGroupsModal, setShowAddGroupsModal] = useState(false);
  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [showRemoveComponentModal, setShowRemoveComponentModal] = useState(false);
  const [groupRange, setGroupRange] = useState({
    lowest_group_number: 0,
    highest_group_number: 0,
  });

  const { name: componentName, docebo_course_id, course_code } = component;
  const {
    labelText, variant, open, setAlertBarConfig, initialAlertBarConfig,
  } = useAlertBar();

  const padFormatString = (intLike) => `${intLike}`.padStart(2, '0');
  const { lowest_group_number, highest_group_number } = groupRange;

  const getGroups = useCallback(
    (searchVal, searchTypeVal) => {
      setCurrentPage(0);
      setGroups([]);
      setHasMore(false);
      setIsLoading(true);
      const getGroupsInnerFunc = async () => {
        await getGroupsHelper(
          searchVal || '',
          searchTypeVal || 'group_name',
          [],
          setGroups,
          true,
          setHasMore,
          0,
          setCurrentPage,
          setSnackbarObj,
          setIsLoading,
          setGroupsCount,
          programId,
          setTotalGroupsLength,
          setGroupRange,
          setComponent,
          setIsPageLoading,
        );
      };
      getGroupsInnerFunc();
    },
    [programId, setSnackbarObj],
  );

  useEffect(() => {
    try {
      setIsPageLoading(true);
      getGroups();
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.log(e);
    }
  }, [getGroups, setSnackbarObj]);

  const onLoadMore = async () => {
    await getGroupsHelper(
      searchText,
      searchType,
      groups,
      setGroups,
      hasMore,
      setHasMore,
      currentPage,
      setCurrentPage,
      setSnackbarObj,
      setIsLoading,
      setGroupsCount,
      programId,
      setTotalGroupsLength,
      setGroupRange,
      setComponent,
      setIsPageLoading,
    );
  };

  const onUploadExcel = (isUserEnrollment = false) => {
    history.replace(
      `/${ROUTE_REPORT}/${programId}/${COURSE_OPS}/${isUserEnrollment ? GROUP_ENROLLMENT : UPLOAD_GROUPWORK_ROLES}`,
    );
  };

  const updateGroups = (updatedGroup, updatedGroupIndex) => {
    const updatedGroups = cloneDeep(groups);
    updatedGroups[updatedGroupIndex] = updatedGroup;
    setGroups(updatedGroups);
  };

  const debouncedChange = useCallback(
    _debounce((searchVal) => {
      getGroups(searchVal, searchType);
    }, 500),
    [getGroups, searchType],
  );

  const handleSearch = (searchVal) => {
    setIsLoading(true);
    setSearchText(searchVal);
    debouncedChange(searchVal);
  };

  const getSearchMetadata = useCallback(() => {
    let searchMetadataText;
    if (isLoading) {
      searchMetadataText = 'Loading...';
    } else if (!groups.length && searchText) {
      searchMetadataText = 'Showing Results: 0 groups';
    } else if (searchText) {
      searchMetadataText = `Showing Results: ${groups.length} of ${totalGroupsLength} ${totalGroupsLength > 1 ? 'groups' : 'group'}`;
    } else {
      searchMetadataText = `${totalGroupsLength} ${totalGroupsLength > 1 ? 'groups' : 'group'}`;
    }
    return (
      <Typography variant="h1" component="span">{searchMetadataText}</Typography>
    );
  }, [groups.length, isLoading, searchText, totalGroupsLength]);

  const searchTypeOptions = [
    { label: 'Group Number', value: 'group_name' },
    { label: 'Group Member Email', value: 'group_member' },
    { label: 'TA Name / Email', value: 'ta' },
  ];

  const handleChange = (searchTypeVal) => {
    if (searchText) {
      setSearchText('');
      setSearchType(searchTypeVal);
      getGroups('', searchTypeVal);
    } else {
      setSearchType(searchTypeVal);
    }
  };

  const getPlaceholderText = useCallback(() => {
    let placeholderText = '';
    if (searchType === 'group_name') placeholderText = 'Type group number here';
    else if (searchType === 'group_member') placeholderText = 'Type group member email here';
    else placeholderText = 'Type TA name/ email here';
    return placeholderText;
  }, [searchType]);

  const getGroupsCreatedText = useCallback(() => {
    let groupsCreatedMessage = '';
    if (highest_group_number) {
      const lowestGroupNumber = padFormatString(lowest_group_number);
      const highestGroupNumber = padFormatString(highest_group_number);
      groupsCreatedMessage = `Groups created (${lowestGroupNumber}-${highestGroupNumber})`;
    } else {
      groupsCreatedMessage = 'No groups added yet! Create groups';
    }

    return groupsCreatedMessage;
  }, [highest_group_number, lowest_group_number]);

  const onAddAssignment = async (startingAtValue, count) => {
    try {
      const resp = await createGroupworkGroup({
        programId,
        configId,
        startingAt: startingAtValue,
        count,
      });
      const isGroupCreationSuccessfull = resp?.data?.success;
      if (isGroupCreationSuccessfull) {
        await getGroups(searchText, searchType);
        setAlertBarConfig({
          labelText: `${count} ${count > 1 ? 'groups' : 'group'} created successfully in all assignments.`,
          variant: 'success',
          open: true,
        });
      } else {
        setAlertBarConfig({
          labelText: 'There is some error in creating groups.',
          variant: 'error',
          open: true,
        });
      }
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    }
  };

  const handleAddRemoveComponentButton = () => {
    if (componentName) setShowRemoveComponentModal(true);
    else setShowAddComponentModal(true);
  };

  const handleOnRemoveComponent = async () => {
    try {
      batchUpdates(() => {
        setShowRemoveComponentModal(false);
        setIsPageLoading(true);
      });
      const resp = await removeGroupWorkTM({ docebo_course_id });
      const { success } = resp?.data;
      if (success) {
        batchUpdates(() => {
          setAlertBarConfig({
            labelText: 'Participant view of groups removed successfully in Docebo.',
            variant: 'success',
            open: true,
          });
          setComponent({});
        });
      } else {
        setAlertBarConfig({
          labelText: 'Failed to remove participant view of groups in Docebo.',
          variant: 'error',
          open: true,
        });
      }
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    } finally {
      setIsPageLoading(false);
    }
  };

  const handleOnAddComponent = async (values) => {
    try {
      batchUpdates(() => {
        setShowAddComponentModal(false);
        setIsPageLoading(true);
      });
      const {
        title, id, code, docebo_id,
      } = values?.selectedComponent;
      const payload = {
        name: title, id, course_code: code, docebo_course_id: docebo_id, program_id: programId,
      };
      const resp = await createRemoveGroupWorkTM(payload, 'POST');
      const { success } = resp?.data;
      if (success) {
        batchUpdates(() => {
          setAlertBarConfig({
            labelText: 'Participant view of groups created successfully in Docebo.',
            variant: 'success',
            open: true,
          });
          setComponent(payload);
        });
      } else {
        setAlertBarConfig({
          labelText: 'Failed to create participant view of groups in Docebo.',
          variant: 'error',
          open: true,
        });
      }
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    } finally {
      setIsPageLoading(false);
    }
  };

  const getAddRemovePaxViewSection = useCallback(() => (
    <Box className={classes.addRemoveComponent}>
      <Button className={classes.addRemoveComponentButton} onClick={() => { handleAddRemoveComponentButton(); }} variant="outlined" color="primary">
        {componentName ? 'Remove Group View for participants in Docebo' : 'Add Group View for participants in Docebo'}
      </Button>
      <Box className={classes.lowerWrapper}>
        {componentName ? (
          <>
            <Typography className={classes.title}>
              Component:
            </Typography>
            <Box>
              <Typography variant="h2" component="span" className={classes.value}>
                {course_code}
              </Typography>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [componentName, handleAddRemoveComponentButton]);

  const disableSearchAndUpload = !highest_group_number;

  return (
    <>
      {showAddGroupsModal && (
        <AddAssignmentModal
          configId={configId}
          open={showAddGroupsModal}
          onClose={() => setShowAddGroupsModal(false)}
          onCreate={onAddAssignment}
          programSubType={programSubType}
          startingIndex={highest_group_number}
        />
      )}
      {showRemoveComponentModal && (
        <RemoveGroupWorkPaxViewModal
          open={showRemoveComponentModal}
          onClose={() => setShowRemoveComponentModal(false)}
          onConfirm={handleOnRemoveComponent}
        />
      )}
      {showAddComponentModal && (
        <AddComponentModal
          open={showAddComponentModal}
          onClose={() => setShowAddComponentModal(false)}
          onOk={handleOnAddComponent}
          setSnackbarObj={setSnackbarObj}
          programId={programId}
        />
      )}
      <Paper className={classes.wrapper}>
        {open && (
          <AlertBarWithAction
            variant={variant}
            labelText={labelText}
            actionButtonIcon={
              <CloseIcon onClick={() => setAlertBarConfig(initialAlertBarConfig)} />
            }
          />
        )}
        <Header heading="Group Management" subHeading="Create and manage groups within this Learning Plan" className={classes.pageHeader} childrenWrapper={classes.childrenWrapper}>
          {isPageLoading ? null : getAddRemovePaxViewSection()}
        </Header>
        {isPageLoading ? <Loading /> : (
          <>
            <Box className={classes.pageWrapper}>
              <Box className={classes.infoWrapper}>
                <InfoOutlinedIcon fontSize="small" />
                <Typography variant="h3" component="span">
                  Note that moving a member to another group after evaluating a submitted assignment
                  will maintain the original score from that group to the user but any future
                  assignments or ungraded assignments will correlate to the newly assigned group.
                  Already evaluated assignment scores can only be overwritten by
                  a new groups scores for that user by updating the re-upload
                  flag to &quot;TRUE&quot; in the assignment metadata file.
                </Typography>
              </Box>
              <Box className={classes.searchMetadataWrapper}>
                {getSearchMetadata()}
                <Box className={classes.groupsCreatedWrapper}>
                  <Typography variant="body1" component="span">{getGroupsCreatedText()}</Typography>

                  <AddCircleOutlineIcon
                    data-testid="addGroupsIcon"
                    className={classes.addGroupsIcon}
                    onClick={() => { setShowAddGroupsModal(true); }}
                  />
                </Box>
              </Box>
              <Box className={classes.groupsMetadataWrapper}>
                <Box className={classes.searchBox}>
                  <Typography variant="body1">Search by</Typography>
                  <Box className={classes.searchWrapper}>
                    <Select
                      name="search_type"
                      defaultValue={searchTypeOptions.find((el) => el.value === searchType)}
                      options={searchTypeOptions}
                      onChange={(v) => handleChange(v.value)}
                      isMulti={false}
                      isSearchable={false}
                      disabled={disableSearchAndUpload}
                    />
                    <LabledTextField
                      placeholder={getPlaceholderText()}
                      labelClass={classes.componentsBreakdown}
                      customCss={`${classes.inputBox}`}
                      disabled={disableSearchAndUpload}
                      name="configId"
                      inputProps={{
                        inputProps: {
                          name: 'configId',
                          className: classes.input,
                          disabled: disableSearchAndUpload,
                        },
                        InputProps: {
                          endAdornment: searchText ? (
                            <ClearIcon
                              fontSize="small"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleSearch('');
                              }}
                            />
                          ) : (
                            <SearchIcon fontSize="small" />
                          ),
                        },
                      }}
                      value={searchText}
                      onChange={(e) => {
                        handleSearch(e?.target?.value);
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.groupInfoContainer}>
                  <Button onClick={() => { onUploadExcel(); }} variant="outlined" color="primary" disabled={disableSearchAndUpload}>
                    Download/Upload TA/GC XLS File
                  </Button>
                  <Button onClick={() => { onUploadExcel(true); }} variant="contained" color="primary" style={{ marginLeft: '1.5rem' }} disabled={disableSearchAndUpload}>
                    Download/Upload Group Enrollment XLS File
                  </Button>
                </Box>
              </Box>
              <Box className={classes.tableWrapper}>
                <GroupsTable
                  groups={groups}
                  setSnackbarObj={setSnackbarObj}
                  setAlertBarConfig={setAlertBarConfig}
                  getGroups={() => { getGroups(searchText, searchType); }}
                  setGroups={updateGroups}
                  groupsCount={groupsCount}
                  onLoadMore={onLoadMore}
                  hasMore={hasMore}
                  isLoading={isLoading}
                  programId={programId}
                  totalGroupsLength={totalGroupsLength}
                />
              </Box>
            </Box>
          </>
        )}
      </Paper>
    </>
  );
};

AssignmentDetails.propTypes = {
  setSnackbarObj: PropTypes.func.isRequired,
  programSubType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
};

export default AssignmentDetails;
