import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { DialogActions, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@mui/material/Paper';
import get from 'lodash/get';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import _find from 'lodash/find';
import {
  ACCORDION_GREY, BLACK, ERROR_RED, LIGHT_MEDIUM_GREY, MEDIUM_GREY, MODERATE_LIGHT_GREY,
} from '../../../stylesheets/colors';
import CustomModal from '../../common/CustomModal';
import FormattedTypography from '../../common/FormattedTypography';
import LabledTextField from '../../common/LabledTextField';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import { getGroupsMetadataForAssignment } from '../../../containers/GroupWorkPage/apis';
import Loading from '../../common/Loading';
import { getErrorMessage } from '../../../helpers/apiHelper';

const useStyles = makeStyles({
  modalStyles: {
    maxWidth: '70rem',
  },
  dialogContentClass: {
    minHeight: '330px',
    padding: '0 13rem 0.5rem 13rem',
    '& > div': {
      marginBottom: '2rem',
    },
  },
  modalHeader: {
    textAlign: 'center',
  },
  divider: {
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',

    '& > button:nth-child(1)': {
      width: '11rem',
    },
  },
  subHeading: {
    color: MEDIUM_GREY,
  },
  groupMemberTitle: {
    fontWeight: 'bold',
  },
  title: {
    color: ACCORDION_GREY,
  },
  value: {
    marginTop: '0.2rem',
  },
  memberInfoContainer: {
    marginTop: '1rem',
    padding: '1.25rem',
  },
  memberInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    minHeight: '3.1rem',
  },
  dropdownOptions: {
    display: 'flex',
    flexDirection: 'column',

    '& > div:nth-child(2)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  disabledOptionTag: {
    fontWeight: 'bold',
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    backgroundColor: LIGHT_MEDIUM_GREY,
    borderRadius: '10px',
    color: BLACK,
  },
  requiredMark: {
    color: ERROR_RED,
  },
});

const initialSelectedGroupState = {
  group_name: '',
  group_number: '',
  members_count: '',
};

const MoveGroupMemberModal = ({
  open, onClose, onSave, selectedMember, selectedMemberGroup, programId, setSnackbarObj,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const query = qs.parse(location.search);
  const assignment_name = get(query, 'tab', 'null');

  const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroupState);
  const [groupList, setGroupList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { name, email } = selectedMember;
  const { group_number: selectedMemberGroupNumber } = selectedMemberGroup;
  const { group_name: selectedGroupName, members_count } = selectedGroup;

  useEffect(() => {
    setIsLoading(true);
    async function getGroups() {
      try {
        const groupsResp = await getGroupsMetadataForAssignment({
          data: {
            assignment_name,
            program_id: programId,
          },
          all_groups_info: true,
        });

        const groupsList = groupsResp?.groups;

        if (groupsList && groupsList.length) {
          const list = groupsList.length && groupsList.map((group) => {
            const { members_count: count, group_number: number } = group;
            group.isDisabled = count >= 15 || number === selectedMemberGroupNumber;
            group.errorMsg = '';
            if (number === selectedMemberGroupNumber) {
              group.errorMsg = 'This group member is already assigned to this group';
            } else if (count >= 15) {
              group.errorMsg = 'This group is already at capacity (15 members)';
            }

            return group;
          });
          setGroupList(list);
        }
      } catch (e) {
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        setIsLoading(false);
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
    getGroups();
  }, [assignment_name, programId, selectedMemberGroupNumber, setSnackbarObj]);

  const onMoveMember = () => {
    onClose();
    onSave(selectedGroup);
  };

  const getMemberInfoJsx = (title, value) => (
    <Box className={classes.memberInfoWrapper}>
      <Typography variant="h3" component="span" className={classes.title}>{title}</Typography>
      <Typography variant="body1" component="span" className={classes.value}>{value}</Typography>
    </Box>
  );

  const getInputFieldIcon = useCallback(() => {
    if (isValidating) {
      return (<CircularProgress size="2rem" />);
    }
    if (status === 'VALID') {
      return (<img src={CheckRoundIcon} alt="error-round" />);
    }
    if (status === 'INVALID') {
      return (<img src={ErrorRoundIcon} alt="success-round" />);
    }
    return <></>;
  }, [isValidating, status]);

  const getGroupInfo = useCallback(
    async (searchId) => {
      batchUpdates(() => {
        setIsValidating(true);
        setStatus('');
        setErrorMsg('');
        setSelectedGroup(initialSelectedGroupState);
      });

      if (!searchId) {
        setIsValidating(false);
        return;
      }

      try {
        const response = _find(groupList, { group_number: +searchId });

        if (response && !response.isDisabled) {
          const {
            group_name: groupName, group_number: groupMember, members_count: membersCount, id,
          } = response;
          setStatus('VALID');
          setSelectedGroup({
            group_name: groupName,
            group_number: groupMember,
            members_count: membersCount,
            id,
          });
        } else {
          setStatus('INVALID');
          setErrorMsg(response && response.isDisabled ? response.errorMsg : 'The entered group number does not exist');
        }
        setIsValidating(false);
      } catch (e) {
        console.error(e);
        setStatus('INVALID');
        setErrorMsg('');
        setIsValidating(false);
      }
    },
    [groupList],
  );

  const debouncedChange = useCallback(_debounce(getGroupInfo, 500), [getGroupInfo]);

  const onIdChange = useCallback(
    (value) => {
      batchUpdates(() => {
        setSearchText(value);
        debouncedChange(value);
      });
    },
    [debouncedChange],
  );

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyles}
    >
      {isLoading ? <Loading /> : (
        <Box>
          <DialogTitle disableTypography className={classes.modalHeader}>
            <FormattedTypography body="Move selected member to a different group" dense />
            <Typography className={classes.subHeading} variant="body1">
              This action will update the user’s group number across assignments
              but maintain any assignments that have already been evaluated
            </Typography>
          </DialogTitle>
          <Divider variant="fullWidth" className={classes.divider} light />
          <DialogContent className={`${classes.dialogContentClass}`}>
            <Typography variant="subtitle2" component="span" className={classes.groupMemberTitle}>Group member details</Typography>
            <Paper className={classes.memberInfoContainer} square elevation="1">
              {getMemberInfoJsx('Name:', name)}
              {getMemberInfoJsx('Email:', email)}
              {getMemberInfoJsx('Group number:', `${selectedMemberGroupNumber}`.padStart(2, '0'))}
            </Paper>
            <Box>
              <Box>
                <Typography variant="h3" component="span">Enter the new group number</Typography>
                <Typography variant="h3" component="span" className={classes.requiredMark}>*</Typography>
              </Box>
              <LabledTextField
                placeholder="Enter the group number"
                inputProps={{
                  inputProps: {
                    name: 'groupNumber',
                    type: 'number',
                  },
                  InputProps: {
                    endAdornment: getInputFieldIcon(),
                  },
                }}
                value={searchText}
                onChange={(e) => { onIdChange(e.target.value); }}
              />
              {errorMsg ? <Typography variant="body1" component="span" className={classes.requiredMark}>{errorMsg}</Typography>
                : null}
            </Box>
            <Box>
              <Typography variant="subtitle2" component="span" className={classes.groupMemberTitle}>Selected group details</Typography>
              <Paper className={classes.memberInfoContainer} square elevation="1">
                {getMemberInfoJsx('Group:', selectedGroupName)}
                {getMemberInfoJsx('Members currently in this group:', members_count)}
              </Paper>
            </Box>
          </DialogContent>
          <Divider variant="fullWidth" className={classes.divider} light />
          <DialogActions className={classes.centerAction}>
            <Button color="primary" variant="contained" onClick={onMoveMember} disabled={!selectedGroupName}>Move Member</Button>
          </DialogActions>
        </Box>
      )}
    </CustomModal>
  );
};

MoveGroupMemberModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedMember: PropTypes.object.isRequired,
  selectedMemberGroup: PropTypes.object.isRequired,
  programId: PropTypes.number.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
};

export default MoveGroupMemberModal;
