import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { DialogActions, DialogContent } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Typography from '@material-ui/core/Typography/Typography';
import Box from '@material-ui/core/Box';
import LabledTextField from '../../common/LabledTextField';
import { MEDIUM_GREY, REPORT_ERROR_RED } from '../../../stylesheets/colors';
import CustomModal from '../../common/CustomModal';
import FormattedTypography from '../../common/FormattedTypography';

const useStyles = makeStyles({
  modalStyles: {
    maxWidth: '70rem',
  },
  dialogContentClass: {
    padding: '0 13rem 0.5rem 13rem',
    '& > div': {
      marginBottom: '2rem',
    },
  },
  modalHeader: {
    textAlign: 'center',
  },
  divider: {
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',

    '& > button:nth-child(1)': {
      width: '11rem',
    },
  },
  subHeading: {
    color: MEDIUM_GREY,
  },
  error: {
    color: REPORT_ERROR_RED,
  },
});

const EditGroupDetailsModal = ({
  open, onClose, onSave, group,
}) => {
  const classes = useStyles();
  const { group_name, ta_name, ta_email } = group;

  const [taInfo] = useState({
    name: ta_name || '',
    email: ta_email || '',
  });

  const validationSchema = Yup.object({
    name: Yup.string().trim(),
    email: Yup.string().email('Invalid email'),
  });

  const {
    values, handleSubmit, errors, touched, handleChange, resetForm, handleBlur,
  } = useFormik({
    initialValues: taInfo,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (obj) => {
      // eslint-disable-next-line no-use-before-define
      onSaveGroupInfo(obj);
    },
  });

  const onSaveGroupInfo = (taDetails) => {
    onClose();
    resetForm();
    onSave(taDetails);
  };

  const { name, email } = values;

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyles}
    >
      <Box>
        <DialogTitle disableTypography className={classes.modalHeader}>
          <FormattedTypography
            body="Edit group details"
            dense
          />
          <Typography
            className={classes.subHeading}
            variant="body1"
          >
            Edit Teaching Assistant (TA) name and email ID for
            {' '}
            {group_name}
            . This action will update the group details across assignments.
          </Typography>
        </DialogTitle>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogContent className={`${classes.dialogContentClass} form-content`}>
          <Box>
            <LabledTextField
              label="TA Name"
              value={name}
              name="name"
              onChange={handleChange}
              placeholder="Enter TA name here"
              inputProps={{ onBlur: handleBlur }}
            />
          </Box>
          <Box>
            <LabledTextField
              label="TA Email ID"
              name="email"
              value={email}
              onChange={handleChange}
              placeholder="Enter TA email ID here"
              inputProps={{ onBlur: handleBlur }}
              type="email"
            />
            {touched.email && errors.email ? (
              <Typography variant="body1" className={classes.error}>{errors.email}</Typography>
            ) : null}
          </Box>
        </DialogContent>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogActions className={classes.centerAction}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={errors.name || errors.email}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </CustomModal>
  );
};

EditGroupDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
};

export default EditGroupDetailsModal;
