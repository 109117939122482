import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ENROLLMENT_RULE_COURSES_GROUP, ENROLLMENT_RULE_LP_GROUP, ENROLLMENT_RULE_COURSES_GROUP_REUPLOAD_MESSAGE } from '../../../constants';
import {
  MODERATE_LIGHT_GREY, LIGHT_MEDIUM_GREY, VERY_LIGHT_GREY, SUCCESS_GREEN, ERROR_RED,
} from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '-40px',
    marginBottom: '60px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,

  },
  tableHead: {
    backgroundColor: MODERATE_LIGHT_GREY,
    height: '3rem',
  },
  tableRow: {
    height: '3rem',
    fontSize: '1.125rem',
  },
  cell: {
    padding: '0.875rem',
  },
  footer: {
    height: '3rem',
    fontSize: '1.125rem',
    fontStyle: 'italic',
    // marginTop: '0.875rem',
    padding: '0.875rem',
  },
});

const useRowStyles = makeStyles({
  tableRow: {
    height: '3rem',
    fontSize: '1.125rem',
    backgroundColor: ({ isFailedCourse }) => (isFailedCourse ? VERY_LIGHT_GREY : 'inherit'),
  },
  cell: {
    padding: '0.875rem',
    color: ({ isFailedCourse }) => (isFailedCourse ? LIGHT_MEDIUM_GREY : 'inherit'),

  },
  statusCell: {
    color: ({ success }) => (success ? SUCCESS_GREEN : ERROR_RED),
    padding: '0.875rem',
  },
});

const EnrollmentRulesRow = ({
  title, groupName, status, success, ruleType,
}) => {
  const isFailedCourse = ruleType === ENROLLMENT_RULE_COURSES_GROUP && !success;
  const classes = useRowStyles({ success, isFailedCourse });
  return (
    <tr className={classes.tableRow}>
      <td className={classes.cell}>{title}</td>
      <td className={classes.cell}>{groupName}</td>
      <td className={classes.statusCell}>{status}</td>
    </tr>
  );
};

EnrollmentRulesRow.propTypes = {
  title: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  ruleType: PropTypes.string.isRequired,
};

const EnrollmentRulesTable = ({
  ruleType, groupName, lpCode, coursesDetails, success, allCoursesExist,
}) => {
  const classes = useStyles();
  const generateLpGroupRuleTable = () => (
    <EnrollmentRulesRow
      title={lpCode}
      groupName={groupName}
      ruleType={ruleType}
      success={success}
      status={success ? 'Added' : 'Not added'}
    />
  );

  const generateCourseGroupRuleTable = () => {
    const rows = (
      coursesDetails.map((course) => (
        <EnrollmentRulesRow
          key={course.course_code}
          title={course.course_code}
          groupName={groupName}
          ruleType={ruleType}
          success={course.success}
          status={course.success ? 'Added' : 'Not added'}
        />
      ))
    );

    return rows;
  };

  const enrollToHeader = ruleType === ENROLLMENT_RULE_LP_GROUP ? 'LP Code' : 'Course Code';
  const body = ruleType === ENROLLMENT_RULE_LP_GROUP
    ? generateLpGroupRuleTable()
    : generateCourseGroupRuleTable();

  const headerTitles = [enrollToHeader, 'Group', 'Status'];
  const headers = headerTitles.map((title) => (
    <th key={title} className={classes.cell}>{title}</th>
  ));
  const coursesPending = ruleType === ENROLLMENT_RULE_COURSES_GROUP && allCoursesExist === false;

  return (
    <div className={classes.wrapper}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableHead}>{headers}</tr>
        </thead>
        <tbody>
          {body}
        </tbody>
      </table>
      {
        coursesPending
        && (
        <p className={classes.footer}>
          {`**${ENROLLMENT_RULE_COURSES_GROUP_REUPLOAD_MESSAGE}`}
        </p>
        )
      }
    </div>
  );
};

EnrollmentRulesTable.propTypes = {
  ruleType: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  lpCode: PropTypes.string,
  coursesDetails: PropTypes.arrayOf(PropTypes.shape({
    course_code: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
  })),
  success: PropTypes.bool.isRequired,
  allCoursesExist: PropTypes.bool.isRequired,
};

EnrollmentRulesTable.defaultProps = {
  lpCode: 'LP CODE',
  coursesDetails: [],
};

export default EnrollmentRulesTable;
