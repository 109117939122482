import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import SuccessIcon from '../../assets/icons/ready-to-go.svg';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '13.125rem',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    cursor: 'pointer',
    border: `1px dashed ${MCKINSEY_BLUE}`,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logo: {
    height: '4rem',
    width: '4rem',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: '0.5rem',
  },
}));

const CreateNewProgramCard = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.wrapper} onClick={onClick}>
      <div className={classes.logoWrapper}>
        <img
          className={classes.logo}
          src={SuccessIcon}
          alt="Spreadsheet"
          width={119}
          height={100}
        />
      </div>
      <Typography className={classes.title}>Create a new Program</Typography>
    </Paper>
  );
};

CreateNewProgramCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateNewProgramCard;
