import { ERROR_RED } from './colors';

const commonStyles = {
  optionTitle: {
    fontSize: ' 1rem',
  },
  optionSubtitle: {
    fontSize: ' 0.875rem',
  },
  dropdownOptions: {
    display: 'flex',
    flexDirection: 'column',
  },
  requiredMark: {
    color: ERROR_RED,
  },
};

export default commonStyles;
