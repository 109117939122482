/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import MuiTextField from '@mui/material/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import LabledTextField from '../common/LabledTextField';
import LabeledTextArea from '../common/LabeledTextArea';
import Select from '../common/SelectMui';
import {
  MODERATE_DARK_GREY, ACCORDION_GREY, MODERATE_LIGHT_GREY, DARK_GREY, REPORT_ERROR_RED,
} from '../../stylesheets/colors';
import NoComponentThumbnail from '../../assets/img/empty-component-title.svg';
import InfoTooltip from '../common/InfoTooltip';
import { stripHtmlTags, capitalizeFirstLetter } from '../../helpers/formattingHelpers';
import WarningInfoAlert from '../common/WarningInfoAlert';
import CustomRadio from '../UserEnrollment/common/CustomRadio';
import RightArrowIcon from '../../assets/icons/right-arrow-blue.svg';
import { NA } from '../../constants';

const useStyles = makeStyles({
  formLable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  width100: {
    width: '100%',
    position: 'relative',
  },
  datePickerInput: {
    width: '100%',
    '& input': {
      height: '0rem',
    },
  },
  relativeParent: {
    position: 'relative',
  },
  switchWrapper: {
    paddingBottom: '1rem',
  },
  error: {
    color: REPORT_ERROR_RED,
  },
  thumbnailImage: {
    maxWidth: '100%',
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'bottom',
  },
  required: {
    '&, &:after': {
      color: REPORT_ERROR_RED,
      content: '"*"',
    },
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
  arrowAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  leftMargin: {
    marginLeft: '1rem',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  label: {
    fontSize: '1.125rem',
    color: MODERATE_DARK_GREY,
    textTransform: 'unset',
    padding: '0.25rem 0',
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    color: MODERATE_DARK_GREY,
    marginTop: '0.5rem',
    paddingRight: '1.25rem',
    wordBreak: 'break-word',
  },
});

const Error = ({ errorText }) => {
  const classes = useStyles();

  return (
    <span className={classes.error}>{errorText || 'This field is required'}</span>
  );
};
Error.propTypes = {
  errorText: PropTypes.string.isRequired,
};

const UserEditCell = ({
  name, label, value, infoText, alertText, required,
  isEdit, fieldType, handleChange, disabled, options, hasError, errorText, showTextLimit,
  maxTextLimit, addToRefs, isMulti,
}) => {
  const classes = useStyles();

  return (
    <Box style={{ paddingBottom: '1rem' }}>

      <Typography component="span" variant="h3" className={classes.label}>
        {label}
        {required && <Typography component="span" variant="body1" className={classes.requiredMark}>*</Typography>}
        {infoText && <InfoTooltip style={{ marginLeft: '0.5rem' }} title={infoText} />}
      </Typography>

      {!isEdit && (
      <Box>
        <Typography component="span" variant="h3" className={classes.value}>
          {value || NA}
        </Typography>

      </Box>
      )}

      {isEdit && (
      <>
        {fieldType === 'text' && (
        <Box className="fld-input">
          <LabledTextField
            name={name}
            value={value}
            onChange={handleChange}
            disabled={disabled}
          />
          {hasError && <Error errorText={errorText} />}
          {showTextLimit
                  && (
                    <Typography className={classes.rightText}>
                      {(value || '').length}
                      /
                      {maxTextLimit}
                    </Typography>
                  )}
        </Box>
        )}

        {fieldType === 'select' && (
        <div className="fld-input">
          <Select
            name={name}
                  // defaultValue={options.find((el) => el.value === value)}
            options={options}
            placeholder="Select an option"
            type="select"
            className={`${classes.select_label}`}
            labelClass={`${classes.labelClass}`}
            label={required ? (
              <Box className={classes.label}>
                <Typography>{label}</Typography>
                <span className={classes.red}>*</span>
              </Box>
            ) : label}
            defaultValue={
                    value ? [{ value, label: value }] : null
                  }
            selectStyles={classes.dropdown}
            isOptionsNotLoading
            selectRef={(el) => addToRefs(el)}
            onChange={(v) => {
              if (isMulti) {
                const multiValue = v ? v.map((el) => el.value).join(',') : '';
                handleChange(name, multiValue, true);
              } else {
                handleChange(name, v?.value, true);
              }
            }}
            disabled={disabled}
            isMulti={isMulti}
          />
          {hasError && <Error errorText={errorText} />}
        </div>
        )}
      </>
      )}
      {alertText.length > 0 && (
      <WarningInfoAlert severity="info">
        {alertText}
      </WarningInfoAlert>
      )}
    </Box>
  );
};

UserEditCell.defaultProps = {
  infoText: '',
  alertText: '',
  disabled: false,
  name: PropTypes.string,
  isEdit: false,
  hasError: false,
  isMulti: false,
  options: [],
  errorText: '',
  required: false,
  showTextLimit: false,
  maxTextLimit: 50,
  addToRefs: () => { },
};

UserEditCell.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  alertText: PropTypes.string,
  fieldType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isEdit: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  errorText: PropTypes.string,
  required: PropTypes.bool,
  showTextLimit: PropTypes.bool,
  maxTextLimit: PropTypes.number,
  addToRefs: PropTypes.func,
};

export default UserEditCell;
