import { makeStyles } from '@material-ui/core';
import { FADED_BLUE, ENV_BANNER_COLOR, WHITE } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  bannerBox: {
    height: '3.438rem',
    padding: '1rem 1.5rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: FADED_BLUE,
    color: ENV_BANNER_COLOR,
    fontSize: '1rem',
  },
  infoIcon: {
    width: '1.5rem',
    height: '1.5rem',
    backgroundColor: ENV_BANNER_COLOR,
    padding: '4px 2px 2px 2px',
    color: WHITE,
    marginRight: 18,
    textAlign: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
