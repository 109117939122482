import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    textDecoration: 'underline',
    color: MCKINSEY_BLUE,
    backgroundColor: 'transparent',
    padding: '0px',
    fontSize: '1.125rem',
  },
});

const HyperlinkButton = ({ onClick, children, className }) => {
  const classes = useStyles();

  return (
    <button onClick={onClick} type="button" className={`${className} ${classes.wrapper}`}>
      {children}
    </button>
  );
};

HyperlinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

HyperlinkButton.defaultProps = {
  className: '',
};

export default HyperlinkButton;
