import React, { useState, useCallback, useEffect } from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash/lang';
import FileInput from '../../FileInput';
import MainButton from '../../MainButton';
import ValidationError from '../../../assets/img/validation-error.webp';
import FormattedTypography from '../../common/FormattedTypography';
import CustomUploadModal from '../../common/CustomUploadModal';
import ErrorModal from '../../ErrorModal/ErrorModal';

import { parseValidationResult } from '../../../helpers/xlsxValidation';
import { zeroAppender } from '../../../helpers/formattingHelpers';
import {
  ACTIVITIES_STATUS, AUTO_HIDE_DURATION, COMPLETED,
  COMPLETED_WITH_ERRORS,
  COURSE_OPS,
  GROUP_WORK_RECOMMENDED_LIMIT,
  GROUPWORK_GROUPS,
  ROUTE_REPORT, STATUS_FAILED,
  USER_MANAGEMENT_RECOMMENDED_LIMIT,
} from '../../../constants';
import BreadCrumbHOC from '../../BreadCrumbHOC';
import Header from '../../common/Header';
import AlertBarWithAction from '../../common/AlertBarWithAction';
import GWUploadError from '../common/GWUploadError';
import UserWarning from '../../../assets/img/group-3.svg';
import ExcelRowsLimitWarning from '../../common/File Upload/ExcelRowsLimitWarning';
import ReportOverviewModal from '../../ActivityLogs/ReportOverviewModal';
import { uploadXlsx } from '../../../containers/GroupWorkPage/apis';
import { getErrorMessage } from '../../../helpers/apiHelper';
import { validateXLSXApiCall } from '../../../containers/common/apis';
import GroupWorkReportModal from '../common/GroupWorkReportModal';
import commonGWStyles from '../common/styles';
import { onDownloadGWData } from '../common/utils';

const useStyles = makeStyles({
  ...commonGWStyles,
});

const GroupWorksUploadPage = ({
  match, programId, setSnackbarObj, programMetadata, programSubType,
}) => {
  const classes = useStyles();
  const configId = get(programMetadata, 'config_id', '');
  const programType = get(match, 'params.programType');
  const initialValidationResult = { status: 'none', errors: [] };
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [modalState, setModalState] = useState(null);
  const [uploadResp, setUploadResp] = useState({});
  const [fileName, setFileName] = useState('');
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [showDownloadGWBtn, setShowDownloadGWBtn] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: GWUploadError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={110} height={110} />,
        heading: null,
        primaryBtnText: 'Close',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
        contentDivider: true,
      },
      props: initialValidationResult,
    },
    UPLOAD_EXCEEDS_LIMIT: {
      component: ExcelRowsLimitWarning,
      wrapperProps: {
        logo: <img src={UserWarning} alt="warning" width={120} height={120} />,
        heading: (
          <FormattedTypography
            prefix={`Alert! Your file has more than ${GROUP_WORK_RECOMMENDED_LIMIT} users`}
          />
        ),
        primaryBtnText: 'Proceed',
        secondaryBtnText: 'Cancel',
      },
      props: {
        moduleName: 'Groupwork files',
        rowDenotion: 'groups',
        rowsLimit: GROUP_WORK_RECOMMENDED_LIMIT,
      },
    },
    REPORT: {
      component: ReportOverviewModal,
      wrapperProps: {
        logo: null,
        heading: null,
        breakpoint: 'md',
        primaryBtnText: 'Download Log',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: {
        steps: [],
      },
    },
  });

  const { status } = uploadResp;

  useEffect(() => {
    const enableDownloadBtn = get(
      programMetadata,
      `activities_status.${ACTIVITIES_STATUS[programSubType] ? ACTIVITIES_STATUS[programSubType] : ''
      }.status`,
    );
    setShowDownloadGWBtn(enableDownloadBtn);
  }, [programMetadata, programSubType]);

  const breadCrumbList = [
    { label: 'Group Management', redirectionUrl: `/${ROUTE_REPORT}/${programId}/${COURSE_OPS}/${GROUPWORK_GROUPS}`, isActive: false },
    { label: 'Download/Upload XLS file', redirectionUrl: '', isActive: true },
  ];

  useEffect(() => {
    if (showAlertBar && !isEmpty(uploadResp)) {
      setTimeout(() => {
        setShowAlertBar(false);
      }, AUTO_HIDE_DURATION);
    }
  }, [showAlertBar, uploadResp]);

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  const resetFileUpload = useCallback(
    (e) => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          props: initialValidationResult,
        },
      }));
      e.target.value = '';
    },
    [initialValidationResult],
  );

  const generateFormData = useCallback(
    (file) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('program_id', programId);
      return formData;
    },
    [programId],
  );

  const uploadFile = useCallback(
    async (formData) => {
      try {
        setModalState(null); // To close the "UPLOAD_EXCEEDS_LIMIT" modal if user clicks on Proceed
        // adding heap tracking script to track actual feature click
        // window.heap.track(FEATURE_CLICKED, programSubType);
        console.log('tracking FEATURE_CLICKED => ', programSubType);
        const resp = await uploadXlsx(programType, programSubType, formData, programId);
        const result = resp?.data?.result;
        batchUpdates(() => {
          setIsValidating(false);
          setUploadResp(result);
          if (!showDownloadGWBtn) {
            setShowDownloadGWBtn(result?.status === COMPLETED);
          }
        });
      } catch (e) {
        console.error(e);
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        setIsValidating(false);
      }
    },
    [programId, programSubType, programType, setSnackbarObj, showDownloadGWBtn],
  );

  const handleUsersLimitModalClose = () => {
    batchUpdates(() => {
      setIsValidating(false);
      setShowAlertBar(false);
      setModalState(null);
    });
  };

  const onUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      batchUpdates(() => {
        setFileName(file?.name);
        setIsValidating(true);
        setShowAlertBar(true);
        setUploadResp({});
      });
      const formData = generateFormData(file);
      resetFileUpload(event);
      let result;
      try {
        result = await validateXLSXApiCall(
          programType,
          programSubType,
          formData,
          programId,
        );
      } catch (e) {
        console.error(e);
        setIsValidating(false);
        setShowAlertBar(false);
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        return; // Early exit
      }
      const errorMessages = parseValidationResult(result);
      const errorMessageCount = errorMessages.errors?.length || 0;

      const [extension, ...nameParts] = file.name.split('.').reverse();

      batchUpdates(() => {
        setModalScheme((schema) => ({
          ...schema,
          VALIDATION: {
            ...schema.VALIDATION,
            wrapperProps: {
              ...schema.VALIDATION.wrapperProps,
              heading: (
                <FormattedTypography
                  prefix="Scanning file&nbsp;"
                  body={nameParts.join('.')}
                  suffix={`.${extension}`}
                  subHeading={`${zeroAppender(
                    errorMessageCount,
                  )} errors found. Please make the required changes and re-upload your file.`}
                />
              ),
            },
            props: errorMessages,
          },
          UPLOAD_EXCEEDS_LIMIT: {
            ...schema.UPLOAD_EXCEEDS_LIMIT,
            wrapperProps: {
              ...schema.UPLOAD_EXCEEDS_LIMIT.wrapperProps,
              primaryBtnProps: {
                onClick: uploadFile.bind(null, formData),
              },
              secondaryBtnProps: {
                onClick: handleUsersLimitModalClose,
              },
            },
          },
        }));
      });

      if (errorMessages.errors.length) {
        setModalState('VALIDATION');
        setShowAlertBar(false);
        setIsValidating(false);
        return;
      }

      if (result.count > USER_MANAGEMENT_RECOMMENDED_LIMIT) {
        setModalState('UPLOAD_EXCEEDS_LIMIT');
        return;
      }

      await uploadFile(formData);
    },
    [generateFormData, programId, programSubType, programType,
      resetFileUpload, setSnackbarObj, uploadFile],
  );

  const handleViewDetails = () => {
    setShowAlertBar(false);
    setShowReportModal(true);
  };

  const body = (
    <Container className={classes.innerWrapper} disableGutters maxWidth={false}>
      <Box className={classes.uploadSection}>
        <Box>
          <Typography variant="body1" className={classes.downloadGWDataBtn}> Ensure you have the last updated TA and GC roles for each group before you choose to edit/add these roles. This can be used as a template before you have uploaded any data to this field as well.</Typography>
          <Button
            variant="contained"
            style={{
              marginTop: '0.75rem',
            }}
            color="primary"
            onClick={() => { onDownloadGWData({ programId, programSubType, setSnackbarObj }); }}
            disabled={!showDownloadGWBtn}
          >
            Download Latest TA/GC Data
          </Button>
        </Box>
        <Divider light flexItem sx={{ mt: '1rem' }} />
        <Box className={classes.border}>
          <FileInput
            idx={0}
            onFileInput={onUpload}
            isDisabled={isValidating}
          >
            <MainButton
              isDisabled={isValidating}
              title="Upload XLS file"
              subtitle="We will upload Group Coordinators and TAs to update Group information for this LP"
            />
          </FileInput>
        </Box>
      </Box>
    </Container>
  );

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  const handleReportModalClose = () => {
    setShowReportModal(false);
    setUploadResp({});
  };

  const AlertComp = () => {
    if (isValidating) {
      return (
        <AlertBarWithAction
          variant="loading-without-percentage"
          labelText={`Uploading groupwork file "${fileName}"`}
        />
      );
    }
    if (status === STATUS_FAILED || status === COMPLETED_WITH_ERRORS) {
      return (
        <AlertBarWithAction
          variant="error"
          labelText={status === STATUS_FAILED ? `Failed to upload groupwork file "${fileName}". Please try again.`
            : `Groupwork file "${fileName}" was uploaded with errors.`}
          actionButtonText="View Details"
          onActionClick={() => {
            handleViewDetails();
          }}
          actionButtonIcon={<CloseIcon onClick={() => setShowAlertBar(false)} />}
        />
      );
    }
    if (status === COMPLETED) {
      return (
        <AlertBarWithAction
          variant="success"
          labelText={`Groupwork file "${fileName}" was successfully uploaded`}
          actionButtonText="View Details"
          onActionClick={() => {
            handleViewDetails();
          }}
          actionButtonIcon={<CloseIcon onClick={() => setShowAlertBar(false)} />}
        />
      );
    }

    return null;
  };

  return (
    <Box className={classes.uploadPageWrapper}>
      {showReportModal && (
      <GroupWorkReportModal
        programSubType={programSubType}
        open={status}
        onClose={handleReportModalClose}
        progress={uploadResp}
        fileName={fileName}
        configId={configId}
        label="Groupwork"
      />
      )}
      <Paper className={classes.wrapper}>
        {showAlertBar && <AlertComp />}
        <Box className={classes.breadCrumbWrapper}>
          <BreadCrumbHOC list={breadCrumbList} />
        </Box>
        <Box className={classes.mainWrapper}>
          <Header heading="Download/Upload Group Coordinator and TA information" className={classes.pageHeader} />
          {body}
        </Box>
        {ModalComponent && (
          <CustomUploadModal
            open={modalSchema[modalState] !== undefined}
            onClose={handleUsersLimitModalClose}
            className={classes.modalHeight}
            breakpoint="sm"
            {...modalSchema[modalState].wrapperProps}
          >
            <ModalComponent {...modalSchema[modalState].props} />
          </CustomUploadModal>
        )}
        <ErrorModal
          open={isErrorModalOpen}
          onClose={onErrorModalClose}
          message="Could not update users due to some technical error."
        />
      </Paper>
    </Box>
  );
};

GroupWorksUploadPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.number,
    }),
  }).isRequired,
  programId: PropTypes.number.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  programMetadata: PropTypes.object.isRequired,
  programSubType: PropTypes.string.isRequired,
};

export default GroupWorksUploadPage;
