import React from 'react';
import PropTypes from 'prop-types';
import CopyComponentReportModal from '../../ContentManagement/CopyComponentReportModal';
import {
  INDIVIDUAL_SCORM_REFRESH, MULTIPLE_SCORM_REFRESH, IMPORT_COURSE, BULK_UPDATE_COMPONENT_ACCESS,
  REORDER_TRAINING_MATERIALS, CLONE_COURSE,
} from '../../../constants';
import useCheckActivityStatus from '../../../hooks/useCheckActivityStatus';
import BulkScormReleaseStatuses from '../../ContentManagement/BulkScormRelease/BulkScormReleaseStatuses';
import IndividualScormReleaseStatuses from '../../ContentManagement/IndividualScormReleaseStatuses';
import ImportLogStatuses from '../../ContentManagement/ImportComponentCopy/ImportLogStatuses';
import BulkUserAccessUpdateStatuses from '../../ContentManagement/BulkUserAccessUpdateStatuses';
import ReorderTrainingMaterialsStatuses from '../../ContentManagement/ReorderTrainingMaterialsStatuses';

const ContentManagement = ({
  transactionId, status, open, onClose, onDownloadLog, getReport, type, configId,
  pollProgressValue, updatePollProgress, programId, programType,
}) => {
  const {
    modalState,
    preClose,
    progressData,
    progressErrorMsg,
  } = useCheckActivityStatus(status,
    open, onClose, pollProgressValue, transactionId, updatePollProgress);

  const getModalType = {
    [INDIVIDUAL_SCORM_REFRESH]: IndividualScormReleaseStatuses,
    [MULTIPLE_SCORM_REFRESH]: BulkScormReleaseStatuses,
    [IMPORT_COURSE]: ImportLogStatuses,
    [BULK_UPDATE_COMPONENT_ACCESS]: BulkUserAccessUpdateStatuses,
    [REORDER_TRAINING_MATERIALS]: ReorderTrainingMaterialsStatuses,
    [CLONE_COURSE]: CopyComponentReportModal,
  };

  const Body = getModalType[type] || (() => null);

  return (
    <>
      {open ? (
        <Body
          open={open}
          onClose={preClose}
          modalState={modalState}
          status={status}
          transactionId={transactionId}
          progressData={progressData}
          progressErrorMsg={progressErrorMsg}
          getReport={getReport}
          onDownloadLog={onDownloadLog}
          configId={configId}
          programId={programId}
          programType={programType}
        />

      ) : null}
    </>
  );
};

ContentManagement.propTypes = {
  transactionId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  pollProgressValue: PropTypes.bool.isRequired,
  updatePollProgress: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  programType: PropTypes.string.isRequired,
};

export default ContentManagement;
