import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ContentSearchCard from './ContentSearchCard';
import DoceboContentSearchCard from './DoceboContentSearchCard';
import { DOCEBO, ELUCIDAT, ALL } from '../../constants';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '35px',
  },
  title: {
    display: 'flex',
    paddingBottom: '15px',
  },
  cardsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: '20px',
  },
}));

const GridCardBlock = ({
  cards, onUpdate, doceboCards, onDoceboCardUpdate, selectedResource,
}) => {
  const classes = useStyles();

  if (cards.length === 0 && doceboCards.length === 0) return <div />;

  const cardComponents = cards.map((card) => (
    <ContentSearchCard
      key={card.id}
      id={card.id}
      title={card.title}
      type={card.type}
      releases={card.releases}
      release={card.release}
      checked={card.checked}
      onUpdate={onUpdate}
    />
  ));

  const doceboCardComponents = doceboCards.map((card) => (
    <DoceboContentSearchCard
      key={card.id}
      id={card.id}
      title={card.title}
      type={card.type}
      versions={card.versions}
      version={card.version}
      checked={card.checked}
      onUpdate={onDoceboCardUpdate}
      createdDates={card.createdDates}
    />
  ));

  return (
    <div className={classes.wrapper}>
      {cards.length > 0 && (selectedResource === ELUCIDAT || selectedResource === ALL)
        && (
          <div>
            <div className={classes.title}>Showing results from Elucidat</div>
            <div className={classes.cardsWrapper}>{cardComponents}</div>
          </div>
        )}
      {doceboCards.length > 0 && (selectedResource === DOCEBO || selectedResource === ALL)
        && (
        <div>
          <div className={classes.title}>Showing results from Docebo</div>
          <div className={classes.cardsWrapper}>{doceboCardComponents}</div>
        </div>
        )}
    </div>
  );
};

GridCardBlock.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    releases: PropTypes.arrayOf(PropTypes.any).isRequired,
    release: PropTypes.any.isRequired,
    checked: PropTypes.bool.isRequired,
  })).isRequired,
  doceboCards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.number),
    created_by: PropTypes.string.isRequired,
  })).isRequired,
  onDoceboCardUpdate: PropTypes.func.isRequired,
  selectedResource: PropTypes.string.isRequired,
};

export default GridCardBlock;
