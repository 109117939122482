import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';

import LabledTextField from '../../GroupWorksPages/common/LabledTextField';
import { LoaderInner } from '../../Loader';

import {
  BACKDROP_BLUE,
  LIGHT_MEDIUM_GREY,
  MCKINSEY_BLUE,
  WHITE,
} from '../../../stylesheets/colors';
import {
  BOX_URL,
  DOCEBO_URL,
  DOCEBO_URL_2,
  PLATFORMS_URL,
  WRIKE_URL,
  DEFAULT,
} from '../../../constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: '80rem',
    minHeight: '40rem',
    width: '50vw',
    height: '50vh',
  },
  closeRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.09,
    justifyContent: 'flex-end',
  },
  closeButton: {
    backgroundColor: 'transparent',
    paddingRight: '2rem',
    paddingTop: '2rem',
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '6.25rem',
    transform: 'scale(0.4) translate(0, -100%)',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.09,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  bodyRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.64,
  },
  spacerLeft: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.18,
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 0.73,
    fontSize: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.27,
    justifyContent: 'center',
    alignItems: 'center',
  },
  skipButton: {
    backgroundColor: WHITE,
    border: `1px solid ${LIGHT_MEDIUM_GREY}`,
    padding: '0.5rem 1rem',
    margin: '0.15rem',
    height: '2.5rem',
  },
  saveButton: {
    backgroundColor: MCKINSEY_BLUE,
    color: WHITE,
    padding: '0.5rem 1rem',
    margin: '0.15rem',
    height: '2.5rem',
  },
  spacerRight: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.18,
  },
  errorText: {
    color: 'red',
    margin: '0.15rem',
    fontSize: '1rem',
  },
  linksRow: {
    display: 'flex',
  },
  textFieldWrapper: {
    flex: 1,
    margin: '1rem',
  },
  modalBackground: {
    backgroundColor: BACKDROP_BLUE,
    boxShadow: 'none',
  },
});

const LinkModal = ({
  open, onClose, onSkip, progress, onSave, programMetadata, errorText,
}) => {
  const classes = useStyles();
  const [platformsUrl, setPlatformsUrl] = React.useState(
    programMetadata.links[PLATFORMS_URL] && programMetadata.links[PLATFORMS_URL] !== DEFAULT
      ? programMetadata.links[PLATFORMS_URL]
      : '',
  );
  const [doceboUrl, setDoceboUrl] = React.useState(programMetadata.links[DOCEBO_URL] || '');
  // Requirement on hold
  // const [doceboUrl2, setDoceboUrl2] = React.useState(programMetadata.links[DOCEBO_URL_2] || '');
  const [boxUrl, setBoxUrl] = React.useState(programMetadata.links[BOX_URL] || '');
  const [wrikeUrl, setWrikeUrl] = React.useState(programMetadata.links[WRIKE_URL] || '');

  const payload = {
    [DOCEBO_URL]: doceboUrl || null,
    // [DOCEBO_URL_2]: doceboUrl2, // Requirement on hold
    [BOX_URL]: boxUrl || null,
    [WRIKE_URL]: wrikeUrl || null,
    [PLATFORMS_URL]: platformsUrl || null,
  };

  const onSaveInner = () => onSave(payload);
  const onSkipInner = () => onSkip(payload);
  const onCloseInner = () => onClose(payload);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onCloseInner}
      disableBackdropClick
      BackdropProps={{ className: classes.modalBackground }}
    >
      <div className={classes.wrapper}>
        <div className={classes.closeRow}>
          <button type="button" className={classes.closeButton} onClick={onCloseInner}>
            <CloseIcon />
          </button>
        </div>

        <div className={classes.progressRow}>
          <LoaderInner progress={progress} scaleFactor={1} />
        </div>

        <div className={classes.titleRow}>
          We are creating program components. Meanwhile, could you please update the links below?
        </div>

        <div className={classes.bodyRow}>
          <div className={classes.spacerLeft} />

          <div className={classes.centerContainer}>
            <div className={classes.linksContainer}>
              <div className={classes.linksRow}>
                <div className={classes.textFieldWrapper}>
                  <LabledTextField
                    label="Launch Checklist"
                    value={doceboUrl}
                    onChange={setDoceboUrl}
                    placeholder="Enter link : e.g: http://www.google.com"
                  />
                </div>
                <div className={classes.textFieldWrapper}>
                  <LabledTextField
                    label="Box Folder"
                    onChange={setBoxUrl}
                    value={boxUrl}
                    placeholder="Enter link : e.g: http://www.google.com"
                  />
                </div>
              </div>
              <div className={classes.linksRow}>
                <div className={classes.textFieldWrapper}>
                  <LabledTextField
                    label="Wrike Link"
                    onChange={setWrikeUrl}
                    value={wrikeUrl}
                    placeholder="Enter link : e.g: http://www.google.com"
                  />
                </div>

                <div className={classes.textFieldWrapper}>
                  <LabledTextField
                    label="Platform URL"
                    onChange={setPlatformsUrl}
                    value={platformsUrl}
                    placeholder="Enter link : e.g: http://www.google.com"
                  />
                </div>
              </div>
            </div>

            <div className={classes.errorText}>{errorText}</div>

            <div className={classes.buttonContainer}>
              <button type="button" className={classes.skipButton} onClick={onSkipInner}>
                Skip this step
              </button>

              <button type="button" className={classes.saveButton} onClick={onSaveInner}>
                Save links
              </button>
            </div>
          </div>

          <div className={classes.spacerRight} />
        </div>
      </div>
    </Dialog>
  );
};

LinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  programMetadata: PropTypes.shape({
    links: PropTypes.shape({
      [PLATFORMS_URL]: PropTypes.string,
      [BOX_URL]: PropTypes.string,
      [DOCEBO_URL]: PropTypes.string,
      [DOCEBO_URL_2]: PropTypes.string,
      [WRIKE_URL]: PropTypes.string,
    }),
  }).isRequired,
  errorText: PropTypes.string.isRequired,
};

export default LinkModal;
