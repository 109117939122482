import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { useHistory } from 'react-router-dom';
import {
  TEXT_PROGRAM_CREATION,
  TEXT_DIGITAL_PRODUCTION,
  ROUTE_HOME,
  COURSE_PROD,
  ROUTE_REPORT,
  ASSIGN_COURSE_CONTENT,
} from '../../constants';

import { MCKINSEY_BLUE, DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  breadCrumbsWrapper: {
    display: 'flex',
    width: '100%',
    paddingTop: '40px',
    paddingBottom: '20px',
  },
  breadCrumbText: {
    fontSize: '14px',
    color: DARK_GREY,
    background: 'none',
  },
}));

const CustomBreadCrumb = ({ programId, transactionId }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.breadCrumbsWrapper}>
      <Breadcrumbs aria-label="breadcrumb">
        <button className={classes.breadCrumbText} type="button" onClick={() => history.push(`/${ROUTE_HOME}/${COURSE_PROD}`)}>
          {TEXT_DIGITAL_PRODUCTION}
        </button>
        <button className={classes.breadCrumbText} type="button" onClick={() => history.push(`/${ROUTE_REPORT}/${programId}/${COURSE_PROD}/${ASSIGN_COURSE_CONTENT}/${transactionId}`)}>
          {TEXT_PROGRAM_CREATION}
        </button>
        <div className={classes.breadCrumbText} style={{ color: MCKINSEY_BLUE }}>Find Content</div>
      </Breadcrumbs>
    </div>
  );
};

CustomBreadCrumb.propTypes = {
  programId: PropTypes.number.isRequired,
  transactionId: PropTypes.number.isRequired,
};

export default CustomBreadCrumb;
