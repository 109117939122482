import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import BackgroundImage from '../../assets/img/error.png';
import SimpleHeader from '../../components/SimpleHeader';
import EnvironmentBanner from '../../components/EnvironmentBanner';
import { getEnvironmentFromLocalStorage } from '../../helpers/userHelper';
import { DARK_GREY } from '../../stylesheets/colors';
import ErrorPageInner from '../../components/ErrorPageInner';

const useStyles = makeStyles(() => ({
  boxContainer: {
    marginLeft: '94px',
    marginTop: '118px',
  },
  backgroundWrapper: {
    backgroundImage: `url(${BackgroundImage})`,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  errorCode: {
    width: '254px',
    height: '199px',
    fontSize: '144px',
    fontWeight: 500,
    color: DARK_GREY,
  },
  errorMessage: {
    width: '491px',
    height: '78px',
    fontSize: '36px',
    fontWeight: '300',
    color: DARK_GREY,
  },
}));

const ErrorPage = () => {
  const classes = useStyles();
  const environment = getEnvironmentFromLocalStorage();
  return (
    <div style={{ height: 'calc(100vh - 124px)' }}>
      <div className={classes.backgroundWrapper} />
      <SimpleHeader programType="course_prod" programSubType="program_creation" />
      <EnvironmentBanner environment={environment.environment} />
      <ErrorPageInner />
    </div>
  );
};

export default ErrorPage;
