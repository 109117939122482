import React, { memo } from 'react';
import PropTypes from 'prop-types';

import _isEqual from 'lodash/isEqual';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { SUCCESS_GREEN, ERROR_RED, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 0,
  },
  infoHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    height: '2rem',
    width: '2rem',
    minHeight: 'unset',
    marginRight: '1rem',
  },
  buttonSuccess: {
    '&:disabled': {
      backgroundColor: SUCCESS_GREEN,
    },
  },
  buttonError: {
    '&:disabled': {
      backgroundColor: ERROR_RED,
    },
  },
  buttonIcon: {
    '& > svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  listIcon: {
    minWidth: 'unset',
  },
  heavyFont: {
    fontWeight: 'bold',
  },
  buttonOutline: {
    height: '1.25rem',
    width: '1.25rem',
    minHeight: 'unset',
    marginRight: '1rem',
  },
  buttonOutlineSuccess: {
    '&:disabled': {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${SUCCESS_GREEN}`,
      '& svg': {
        fontSize: '0.875rem',
        color: SUCCESS_GREEN,
      },
    },
  },
  buttonOutlineError: {
    '&:disabled': {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${ERROR_RED}`,
      '& svg': {
        fontSize: '0.875rem',
        color: ERROR_RED,
      },
    },
  },
  listItemIcon: {
    minWidth: '2.5rem',
    marginTop: '0.8rem',
    alignSelf: 'flex-start',
    color: MODERATE_DARK_GREY,
  },
  listRecordIcon: {
    fontSize: '0.5625rem',
  },
}));

const InstructionCard = ({
  className, success, heading, items,
}) => {
  const classes = useStyles();

  return (
    <Container className={`${classes.wrapper} ${className}`}>
      <div className={classes.infoHeader}>
        <Fab
          color="primary"
          size="small"
          disabled
          className={`${classes.button} ${success ? classes.buttonSuccess : classes.buttonError}`}
          classes={{ label: classes.buttonIcon }}
        >
          {success ? (
            <CheckIcon fontSize="small" color="secondary" />
          ) : (
            <CloseIcon fontSize="small" color="secondary" />
          )}
        </Fab>
        <Typography>{heading}</Typography>
      </div>
      <List component="nav" dense>
        {items.map((item) => (
          <ListItem key={item.title}>
            <ListItemIcon className={classes.listItemIcon}>
              <FiberManualRecordIcon className={classes.listRecordIcon} />
            </ListItemIcon>
            <ListItemText primary={item.title} classes={{ primary: classes.heavyFont }} />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

InstructionCard.defaultProps = {
  className: '',
};

InstructionCard.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      success: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default memo(
  InstructionCard,
  (prevProps, nextProps) => prevProps.className === nextProps.className
    && prevProps.success === nextProps.success
    && prevProps.heading === nextProps.heading
    && _isEqual(prevProps.items, nextProps.items),
);
