/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import {
  FieldArray,
  useField,
} from 'formik';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@mui/material/Chip';
import ElucidatProjectInput from './ElucidatProjectInput';
import { MODERATE_LIGHT_GREY, BLACK } from '../../stylesheets/colors';
import { getMaterialType } from '../../helpers/utils';
import WarningInfoAlert from '../common/WarningInfoAlert';
import { REPLICATION_TMS_IN_COMPONENT_LIMIT } from '../../constants';

const useStyles = makeStyles({
  noDisplay: {
    display: 'none',
  },
  warningBox: {
    padding: '0.5rem 0',
  },
});

const LoRow = ({
  loIndex,
  lo,
  coureseRow,
  isDisabled,
  courseIndex,
  checkElucidatValidity,
  updateCourseErrorStatus,
  formik,
  courseChecked,
  isExpanded,
  courseLimitExceeded,
  destinationConfigId,
}) => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [selectedField, selectedMeta, selectedHelpers] = useField({
    name: `${coureseRow}.lo[${loIndex}].selected`,
    type: 'checkbox',
  });

  const { values, setFieldValue } = formik;

  // To enable LO's checkbox when course checked
  useEffect(() => {
    if (!values?.courses[courseIndex].selected) {
      selectedHelpers.setValue(false);
    }
  }, [values]);

  // To make scorm inavlid on load
  useEffect(() => {
    if (lo?.type?.includes('scorm')) {
      setFieldValue('isScormValidated', false);
    }
  }, []);

  useEffect(() => {
    selectedHelpers.setValue(courseChecked);
  }, [courseChecked]);

  useEffect(() => {
    if (_filter(_get(values, `courses[${courseIndex}].lo`, []), { selected: true }).length > REPLICATION_TMS_IN_COMPONENT_LIMIT) {
      courseLimitExceeded(true);
    } else {
      courseLimitExceeded(false);
    }
  }, [selectedField.value, courseIndex]);

  const hideClass = isExpanded ? '' : classes.noDisplay;
  const { label, icon } = getMaterialType(lo);

  return (
    <TableRow key={lo.name} className={hideClass}>
      <TableCell />
      <TableCell />
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          id={selectedField.name}
          name={selectedField.name}
          checked={selectedField.checked}
          onClick={(event) => {
            selectedHelpers.setValue(event.target.checked);
          }}
          disabled={isDisabled}
        />
      </TableCell>
      <TableCell>
        {lo.name}
        {lo?.type?.includes('scorm') && lo?.elucidatSelect === 'duplicate' && (
          <WarningInfoAlert severity="warning" customStyles={classes.warningBox}>
            Duplicated projects are added to the elucidat folder of the parent project
            and to any additional folders that were selected
          </WarningInfoAlert>
        )}
      </TableCell>
      <TableCell>
        <Chip
          style={{
            backgroundColor: MODERATE_LIGHT_GREY,
            color: BLACK,
            fontSize: '1rem',
            padding: '0 0.5rem',
          }}
          icon={<img src={icon} alt="lo-type-icon" />}
          label={label}
          size="small"
        />
      </TableCell>
      <TableCell>
        {
          lo?.type?.includes('scorm')
          && (
            <ElucidatProjectInput
              key={lo.id}
              scormInputPath={`courses[${courseIndex}].lo[${loIndex}]`}
              lo={{ ...lo, lo_id: lo?.id?.split(':')[0] }}
              formik={formik}
              updateCourseErrorStatus={updateCourseErrorStatus}
              checkElucidatValidity={checkElucidatValidity}
              selected={selectedField.checked}
              disable={isDisabled}
              destinationConfigId={destinationConfigId}
              feature="Replication"
            />
          )
        }
      </TableCell>
    </TableRow>
  );
};

const ExpandedComponent = ({
  isDisabled,
  courseIndex,
  coureseRow,
  checkElucidatValidity,
  updateCourseErrorStatus,
  formik,
  courseChecked,
  isExpanded,
  courseLimitExceeded,
  destinationConfigId,
}) => (
  <FieldArray name={`${coureseRow}.lo`}>
    {() => (
      <>
        {formik?.values?.courses[courseIndex].lo.map((loObj, index) => (
          <LoRow
            key={loObj.name}
            loIndex={index}
            lo={loObj}
            formik={formik}
            courseChecked={courseChecked}
            coureseRow={coureseRow}
            isExpanded={isExpanded}
            isDisabled={isDisabled}
            updateCourseErrorStatus={updateCourseErrorStatus}
            courseIndex={courseIndex}
            checkElucidatValidity={checkElucidatValidity}
            courseLimitExceeded={courseLimitExceeded}
            destinationConfigId={destinationConfigId}
          />
        ))}
      </>

    )}
  </FieldArray>

);

export default ExpandedComponent;

ExpandedComponent.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      courses: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  courseIndex: PropTypes.string.isRequired,
  coureseRow: PropTypes.string.isRequired,
  updateCourseErrorStatus: PropTypes.func.isRequired,
  courseChecked: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  courseLimitExceeded: PropTypes.func.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
};

LoRow.propTypes = {
  lo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    original_project_id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]),
    ]),
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    project_id_status: PropTypes.string.isRequired,
    project_name: PropTypes.string.isRequired,
    elucidatSelect: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      courses: PropTypes.array.isRequired,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  loIndex: PropTypes.string.isRequired,
  courseIndex: PropTypes.string.isRequired,
  coureseRow: PropTypes.string.isRequired,
  updateCourseErrorStatus: PropTypes.func.isRequired,
  courseChecked: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  courseLimitExceeded: PropTypes.func.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
};
