import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ACCORDION_GREY, SWITCH_GREY, BLACK } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  displayContainer: {
    padding: '0 1.5rem',
  },
  displayLabelText: {
    padding: '0.25rem 0',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  contentHeadingHelper: {
    padding: '1rem 0',
    borderBottom: `1px solid ${SWITCH_GREY}`,
  },
  subHeading: {
    paddingTop: '2rem',
    fontWeight: 800,
  },
  infoContentText: {
    color: BLACK,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
}));

const CopyComponentReportOverview = ({
  status, componentTitle, componentCode,
}) => {
  const classes = useStyles();

  return (
    <>
      {status === 'FAILED' ? (
        <Box className={classes.displayContainer}>
          <Box className={classes.contentHeadingHelper} />
        </Box>
      ) : (
        <Box className={classes.displayContainer}>
          <Typography variant="subtitle2" className={classes.subHeading}>New Component Shell Created:</Typography>
          <Box className={classes.contentHeadingHelper}>
            <Box className={classes.displayLabelText}>
              <Typography variant="subtitle2" className={classes.infoHelperText}>
                Component Title:
                <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                  {componentTitle}
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.displayLabelText}>
              <Typography variant="subtitle2" className={classes.infoHelperText}>
                Component Code:
                <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                  {componentCode}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

CopyComponentReportOverview.propTypes = {
  status: PropTypes.string.isRequired,
  componentTitle: PropTypes.array.isRequired,
  componentCode: PropTypes.array.isRequired,
};

export default CopyComponentReportOverview;
