import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const InfoTooltip = ({
  title, placement, children, tooltipClasses,
}) => (
  <Tooltip
    title={title}
    arrow
    placement={placement}
    classes={tooltipClasses}
  >
    {children}
  </Tooltip>
);

InfoTooltip.defaultProps = {
  placement: 'right',
  children: <InfoOutlined fontSize="small" />,
  tooltipClasses: {},
};
InfoTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.element,
  tooltipClasses: PropTypes.object,
};

export default InfoTooltip;
