import React, {
  useState, useEffect, useCallback, memo,
} from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/EditOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import MoveIcon from '../../assets/icons/move-icon.svg';
import {
  DROPPABLE_COLOR,
  WHITE,
  DARK_MEDIUM_GREY,
  DARK_GREEN,
  LIGHT_MEDIUM_GREY,
  MCKINSEY_BLUE,
} from '../../stylesheets/colors';
import { convertInDigits, stringTrim } from '../../helpers/formattingHelpers';
import ActionModal from './ActionModal';
import ConfirmModal from '../common/ConfirmModal';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '4rem 0 0',
  },
  droppableContainer: {
    background: DROPPABLE_COLOR,
    flex: 0.49,
    padding: '2rem 4rem',
  },
  dropTitle: {
    lineHeight: '2.25rem',
    background: DROPPABLE_COLOR,
    fontWeight: 700,
    margin: '0 0 1.563rem 0',
  },
  dropper: {},
  dragContainer: {
    margin: '0 0 1.5rem 0',
  },
  dragger: {
    width: '1.85rem',
    height: '1.85rem',
    '&:focus': {
      outline: 'unset',
    },
  },
  serial: {
    width: '5.563rem',
    height: '2.25rem',
    backgroundColor: theme.palette.primary.light,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  cardWrapper: {
    padding: '0 1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    margin: '1rem 0 0',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > :not(div)': {
      margin: '0.4rem 0',
    },
  },
  cardIcons: {
    margin: '0.5rem 0',
    width: '30%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  courseTitle: {
    fontWeight: 900,
  },
  noCard: {
    background: WHITE,
    height: '155px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noCardContent: {
    opacity: 0.8,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: DARK_MEDIUM_GREY,
    '& $move': {
      filter: 'invert(54%) sepia(13%) saturate(0%) hue-rotate(163deg) brightness(89%) contrast(82%)',
    },
  },
  newFlag: {
    fontSize: '0.875rem',
    color: DARK_GREEN,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > svg': {
      marginRight: '0.25rem',
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  move: {
    width: '20px',
    height: '20px',
  },
}));

const getItemStyle = (isDragging, isClone, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? LIGHT_MEDIUM_GREY : WHITE,

  // add border if clone
  border: isClone && !isDragging ? `2px solid ${MCKINSEY_BLUE}` : 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const remove = Array.from(list);
  const [removed] = remove.splice(startIndex, 1);
  remove.splice(endIndex, 0, removed);
  return remove;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  };
};

const Card = ({
  item, index, onEdit, onClone, onDelete,
}) => {
  const classes = useStyles();

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Draggable key={item.course_code} draggableId={item.course_code} index={index}>
      {(provided, snapshot) => (
        <div
          className={classes.dragContainer}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, item.isClone, provided.draggableProps.style)}
        >
          <div className={classes.serial}>
            <Typography color="secondary">{convertInDigits(index + 1, 2)}</Typography>
          </div>
          <div className={classes.cardWrapper}>
            <div className={classes.cardContent}>
              <Typography>{item.course_code}</Typography>
              <div className={classes.title}>
                <Typography variant="subtitle1" className={classes.courseTitle}>
                  {item.course_name}
                </Typography>
                {item.isClone && (
                  <div className={classes.newFlag}>
                    <CheckCircleOutlineIcon />
                    <Typography>New course added</Typography>
                  </div>
                )}
              </div>
              <Typography>{stringTrim(item.course_desc, 30)}</Typography>
              <div className={classes.cardIcons}>
                <IconButton onClick={() => onEdit(index)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onClone(index)}>
                  <FileCopyIcon />
                </IconButton>
                <IconButton onClick={() => onDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.dragger} {...provided.dragHandleProps}>
              <img className={classes.move} src={MoveIcon} alt="drag" />
            </div>
          </div>
        </div>
      )}
    </Draggable>
    /* eslint-enable react/jsx-props-no-spreading */
  );
};

Card.propTypes = {
  item: PropTypes.shape({
    lp_config_id: PropTypes.string.isRequired,
    lp_desc: PropTypes.string.isRequired,
    lp_icon: PropTypes.string,
    lp_name: PropTypes.string.isRequired,
    connected: PropTypes.string.isRequired,
    course_code: PropTypes.string.isRequired,
    course_desc: PropTypes.string.isRequired,
    course_icon: PropTypes.string,
    course_name: PropTypes.string.isRequired,
    course_type: PropTypes.string.isRequired,
    isClone: PropTypes.bool.isRequired,
    originalCourseCode: PropTypes.string.isRequired,
    metadata: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onClone: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const DraggableContainer = ({ data, setUpdatedData }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({ item: {} });
  const [isClone, setClone] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [modalData, setModalData] = useState({});
  const [configSuffix, setConfigSuffix] = useState({});
  const [draggableContainers, setDraggableContainers] = useState({
    internalLP: {
      heading: 'Learning Plan',
      courses: [],
    },
    externalLP: {
      heading: 'External to Learning Plan',
      courses: [],
    },
  });

  useEffect(() => {
    const courses = [];
    const suffix = {};

    data.forEach((v) => {
      suffix[v.course_code] = 0;
      courses.push({ ...v, isClone: false, originalCourseCode: v.course_code });
    });

    setConfigSuffix(suffix);
    setDraggableContainers((prevState) => ({
      internalLP: {
        ...prevState.internalLP,
        courses,
      },
      externalLP: {
        ...prevState.externalLP,
        courses: [],
      },
    }));
  }, [data]);

  useEffect(() => {
    setUpdatedData(
      // eslint-disable-next-line no-unused-vars
      Object.entries(draggableContainers).reduce((ini, [key, { courses }]) => {
        courses.forEach((v) => {
          ini.push(_omit(v, ['isClone', 'originalCourseCode']));
        });
        return ini;
      }, []),
    );
  }, [draggableContainers, setUpdatedData]);

  const generateConfigId = useCallback(
    (code) => `${code}_${convertInDigits(configSuffix[code] + 1, 3)}`,
    [configSuffix],
  );

  const onModalSave = useCallback(
    (droppableId, index, item) => {
      const sourceClone = Array.from(draggableContainers[droppableId].courses);
      sourceClone[index].course_name = item.course_name;
      sourceClone[index].course_desc = item.course_desc;

      setOpen(false);
      setDraggableContainers((prevState) => ({
        ...prevState,
        [droppableId]: {
          ...prevState[droppableId],
          courses: sourceClone,
        },
      }));
    },
    [draggableContainers],
  );

  const onModalClone = useCallback(
    (droppableId, index, item) => {
      const sourceClone = Array.from(draggableContainers[droppableId].courses);
      sourceClone.splice(index, 0, item);

      setOpen(false);
      setConfigSuffix((prevState) => ({
        ...prevState,
        [item.originalCourseCode]: prevState[item.originalCourseCode] + 1,
      }));
      setDraggableContainers((prevState) => ({
        ...prevState,
        [droppableId]: {
          ...prevState[droppableId],
          courses: sourceClone,
        },
      }));
    },
    [draggableContainers],
  );

  const onModalDelete = useCallback(
    (droppableId, index) => {
      const sourceClone = Array.from(draggableContainers[droppableId].courses);
      sourceClone.splice(index, 1);

      setDeleteModalOpen(false);
      setDraggableContainers((prevState) => ({
        ...prevState,
        [droppableId]: {
          ...prevState[droppableId],
          courses: sourceClone,
        },
      }));
    },
    [draggableContainers],
  );

  const onEdit = useCallback(
    (droppableId, index) => {
      const item = draggableContainers[droppableId].courses[index];
      setDeleteModalOpen(false);
      setOpen(true);
      setClone(false);
      setModalHeading(`Edit Course: ${item.course_name}`);
      setModalData({ item, droppableId, index });
    },
    [draggableContainers],
  );

  const onClone = useCallback(
    (droppableId, index) => {
      const item = draggableContainers[droppableId].courses[index];
      setDeleteModalOpen(false);
      setOpen(true);
      setClone(true);
      setModalHeading('Course copy created. Change the course name and description');

      setModalData({
        item: {
          ...item,
          course_code: generateConfigId(item.originalCourseCode),
          isClone: true,
        },
        droppableId,
        index,
      });
    },
    [draggableContainers, generateConfigId],
  );

  const onDelete = useCallback(
    (droppableId, index) => {
      const item = draggableContainers[droppableId].courses[index];
      setOpen(false);
      setDeleteModalOpen(true);
      setDeleteData({ item, droppableId, index });
    },
    [draggableContainers],
  );

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const courses = reorder(
        draggableContainers[source.droppableId].courses,
        source.index,
        destination.index,
      );

      setDraggableContainers((prevState) => ({
        ...prevState,
        [source.droppableId]: {
          ...prevState[source.droppableId],
          courses,
        },
      }));
    } else {
      const resp = move(
        draggableContainers[source.droppableId].courses,
        draggableContainers[destination.droppableId].courses,
        source,
        destination,
      );

      setDraggableContainers((prevState) => ({
        internalLP: {
          ...prevState.internalLP,
          courses: resp.internalLP.map((v) => ({ ...v, connected: 'YES' })),
        },
        externalLP: {
          ...prevState.externalLP,
          courses: resp.externalLP.map((v) => ({ ...v, connected: 'NO' })),
        },
      }));
    }
  };

  return (
    <div className={classes.wrapper}>
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.entries(draggableContainers).map(([key, value]) => (
          <div key={key} className={classes.droppableContainer}>
            <Typography variant="subtitle1" className={classes.dropTitle}>
              {value.heading}
            </Typography>
            <Droppable droppableId={key}>
              {(provided) => (
                <div className={classes.dropper} ref={provided.innerRef}>
                  {value.courses.map((item, index) => (
                    <Card
                      key={item.course_code}
                      item={item}
                      index={index}
                      onClone={(...args) => onClone(key, ...args)}
                      onEdit={(...args) => onEdit(key, ...args)}
                      onDelete={(...args) => onDelete(key, ...args)}
                    />
                  ))}
                  {provided.placeholder && (
                    <div className={classes.noCard}>
                      <div className={classes.noCardContent}>
                        <img className={classes.move} src={MoveIcon} alt="drag" />
                        <Typography>
                          {`Drag course to ${key.slice(0, -2)} learning plan`}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </DragDropContext>
      {!_isEmpty(modalData) && (
        <ActionModal
          open={open}
          title={modalHeading}
          data={modalData}
          isClone={isClone}
          onClose={() => setOpen(false)}
          onSave={isClone ? onModalClone : onModalSave}
        />
      )}
      <ConfirmModal
        open={deleteModalOpen}
        message={`Are you sure to delete ${deleteData.item.course_name} (${deleteData.item.course_code})?`}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={() => onModalDelete(deleteData.droppableId, deleteData.index)}
      />
    </div>
  );
};

DraggableContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      lp_config_id: PropTypes.string.isRequired,
      lp_desc: PropTypes.string.isRequired,
      lp_icon: PropTypes.string,
      lp_name: PropTypes.string.isRequired,
      connected: PropTypes.string.isRequired,
      course_code: PropTypes.string.isRequired,
      course_desc: PropTypes.string.isRequired,
      course_icon: PropTypes.string,
      course_name: PropTypes.string.isRequired,
      course_type: PropTypes.string.isRequired,
      metadata: PropTypes.string,
    }),
  ).isRequired,
  setUpdatedData: PropTypes.func.isRequired,
};

export default memo(DraggableContainer);
