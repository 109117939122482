import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { MODERATE_LIGHT_GREY, REPORT_ERROR_RED } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  labelWrapper: ({ required }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&.MuiTypography-root::after': {
      content: required ? "'*'" : "''",
      color: REPORT_ERROR_RED,
    },
  }),
  inputWrapper: {
    display: 'flex',
    fontSize: '1.125rem',
  },
  input: {
    flex: 1,
    margin: '8px 0',
    '& input': {
      paddingRight: '4.5rem',
      '&:disabled': {
        backgroundColor: MODERATE_LIGHT_GREY,
        opacity: 0.5,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
      fontFamily: 'inherit',
      color: 'inherit',
      fontSize: '1.125rem',
    },
  },
}));

const LabledTextField = ({
  name, label, value, className, labelClass, onChange, type, placeholder, inputProps, customCss,
  disabled, required, error, textFieldCharLimit,
}) => {
  const classes = useStyles({ required });

  return (
    <div className={`${classes.wrapper} ${className}`}>
      {typeof label === 'string' || typeof label === 'number' ? (
        <Typography variant="body1" className={`${classes.labelWrapper} ${labelClass}`}>
          {label}
        </Typography>
      ) : label}
      <TextField
        name={name}
        error={error}
        className={`${classes.input} ${customCss}`}
        variant="outlined"
        margin="dense"
        value={typeof value === 'string' ? value.replace(/\s\s+/g, ' ') : value}
        helperText={textFieldCharLimit.length > 0 ? `${value?.length}/${textFieldCharLimit}` : ''}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
      />
    </div>
  );
};

LabledTextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  name: PropTypes.string,
  inputProps: PropTypes.shape({}),
  customCss: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  textFieldCharLimit: PropTypes.string,
};

LabledTextField.defaultProps = {
  label: null,
  value: '',
  className: '',
  labelClass: '',
  name: '',
  type: 'text',
  placeholder: '',
  inputProps: {},
  onChange: () => { },
  customCss: '',
  disabled: false,
  required: false,
  error: false,
  textFieldCharLimit: '',
};

export default memo(LabledTextField, (prevProps, nextProps) => (
  prevProps.value === nextProps.value
  && prevProps.disabled === nextProps.disabled
  && _isEqual(prevProps.inputProps, nextProps.inputProps)
  && _isEqual(prevProps.placeholder, nextProps.placeholder)
));
