import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Header from '../common/Header';
import FlyoutMenu from '../common/FlyoutMenu';

import { MCKINSEY_LIGHT_BLUE, DARK_MEDIUM_GREY } from '../../stylesheets/colors';

const content = [
  'If there is a change in metadata, the relevant fields will be updated in DOCEBO as well.',
  'If you are not able to see the values of Component metadata fields which were changed in DOCEBO, please try refreshing them.',
  'To edit metadata for multiple components together, please select them and click on Edit Metadata button at the top of the table. This will update the same values for the selected components.',
  // eslint-disable-next-line max-len
  // 'The Enrollment metadata could be changed for Enrolled Users one by one. It cannot be done for multiples Enrollments together.',
];

const useStyles = makeStyles({
  menuItems: {
    whiteSpace: 'normal',
    lineHeight: 2,
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  rightArrow: {
    minWidth: '2.5rem',
    marginTop: '0.3rem',
    alignSelf: 'flex-start',
    color: MCKINSEY_LIGHT_BLUE,
  },
  menuSubtitleMedGrey: {
    color: DARK_MEDIUM_GREY,
  },
});

const MetaDataHeader = ({ className, shouldShow }) => {
  const classes = useStyles();
  return (
    <Header
      className={className}
      heading="Metadata Management"
      subHeading="Edit and manage metadata for Courses, ILT and User enrollment fields"
    >
      <FlyoutMenu
        buttonTitle="Instructions"
        subTitle="Somethings you should know for using metadata management effectively"
        initialState={shouldShow}
        classes={{
          subtitle: classes.menuSubtitleMedGrey,
        }}
      >
        {content.map((item) => (
          <MenuItem className={classes.menuItems} key={item}>
            <ListItemIcon className={classes.rightArrow}>
              <ArrowForwardIcon />
            </ListItemIcon>
            <Typography variant="inherit">{item}</Typography>
          </MenuItem>
        ))}
      </FlyoutMenu>
    </Header>
  );
};

MetaDataHeader.defaultProps = {
  className: '',
};

MetaDataHeader.propTypes = {
  className: PropTypes.string,
  shouldShow: PropTypes.bool.isRequired,
};

export default memo(MetaDataHeader, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
    && prevProps.shouldShow === nextProps.shouldShow
));
