import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Header from '../common/Header';

const UploadGradesHeader = ({ className }) => (
  <Header
    className={className}
    heading="Evaluation"
    subHeading="Evaluate assignments and re-submissions or modify grades in the metadata excel file."
  />
);

UploadGradesHeader.defaultProps = {
  className: '',
};

UploadGradesHeader.propTypes = {
  className: PropTypes.string,
};

export default memo(UploadGradesHeader, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
    && prevProps.shouldShow === nextProps.shouldShow
));
