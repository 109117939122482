/* eslint-disable max-len */
import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import WarningIcon from '@mui/icons-material/Warning';
import FileInput from '../../FileInput';
import MainButton from '../../MainButton';
import { USER_MANAGEMENT_RECOMMENDED_LIMIT } from '../../../constants';
import EnrollmentLeftBar from './EnrollmentLeftBar';
import WarningAlert from '../../common/WarningInfoAlert';
import AlertBarWithAction from '../../common/AlertBarWithAction';

import {
  DARK_GREY,
  KEY_BLACK,
  MCKINSEY_BLUE,
  MODERATE_DARK_GREY,
  MODERATE_LIGHT_GREY,
  DARK_YELLOW,
  LIGHT_YELLOW,
  ACCORDION_GREY,
} from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    boxShadow: 'none',
  },
  titleWrapper: {
    padding: '4.375rem 3.1875rem 2rem',
    borderBottom: `0.5px solid ${MODERATE_LIGHT_GREY}`,
  },
  tabTitle: {
    fontSize: '2.25rem',
    fontWeight: '500',
    lineHeight: '1',
    color: KEY_BLACK,
  },
  tabSubTitle: {
    fontSize: '1.5rem',
    color: DARK_GREY,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
    paddingLeft: '2rem',
  },
  downloadSampleWrapper: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  downloadSampleButton: {
    marginTop: '1.875rem',
  },
  mainWrapper: {
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
  border: {
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '55%',
  },
  disabledborder: {
    border: `0.5px dashed ${ACCORDION_GREY}`,
    width: '55%',
  },
  menuItems: {
    whiteSpace: 'pre-line',
    lineHeight: 1.5,
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  listItemIcon: {
    minWidth: '2.5rem',
    marginTop: '0.8rem',
    alignSelf: 'flex-start',
    color: MODERATE_DARK_GREY,
  },
  listRecordIcon: {
    fontSize: '0.5625rem',
  },
  menuSubtitleBold: {
    fontWeight: 'bold',
  },
  middleWrapper: {
    flexDirection: 'row',
    display: 'flex',
    padding: 0,
  },
  customAlert: {
    '& .MuiPaper-root': {
      color: DARK_YELLOW,
      backgroundColor: LIGHT_YELLOW,
    },
  },
  uploadHelperText: {
    fontSize: '1.125rem',
    paddingBottom: '0.25rem',
    color: DARK_GREY,
  },
  mainhelpertext: {
    padding: '0.5rem 0',
    fontWeight: 'bold',
  },
});

const UserManagementOperationsPage = ({
  onUpload,
  onDownloadSample,
  getClientIdsRecord,
  extendedEnterprise,
  userSelection,
  setUserSelection,
  cohortList,
  setEnrollmentWave,
  navigateToEnrollmentCohortPage,
  isButtonDisabled,
  warningElement,
  enrollmentWave,
}) => {
  const classes = useStyles();
  const [enrollmentError, setEnrollmentError] = useState(false);
  const userLimitAlert = `To successfully enroll users into this learning program, make sure your XLS file has less than ${USER_MANAGEMENT_RECOMMENDED_LIMIT} users.`;

  useEffect(() => {
    if (enrollmentWave?.experience_type === '' || enrollmentWave?.clientId.length === 0) {
      setEnrollmentError(true);
    } else {
      setEnrollmentError(false);
    }
  }, [enrollmentWave]);

  const getErrorMessage = () => {
    if (enrollmentWave?.experience_type === '') {
      return 'To successfully enroll users into this Enrollment Cohort, make sure you choose the appropriate Cohort type and map valid Client(s) to it.';
    }
    return 'To successfully enroll users into this Enrollment Cohort, make sure you map a valid Client to it.';
  };

  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      {
        !enrollmentError && warningElement && (
        <WarningAlert icon={<WarningIcon fontSize="medium" />} className={classes.customAlert}>
          {userLimitAlert}
        </WarningAlert>
        )
      }
      {
        enrollmentError && (
          <AlertBarWithAction
            variant={'error'}
            labelText={getErrorMessage()}
            actionButtonText={'Manage Enrolment Cohort'}
            onActionClick={navigateToEnrollmentCohortPage}
          />
        )
      }
      <Grid container direction="column" className={classes.titleWrapper}>
        <Grid item className={classes.tabTitle}>
          User Enrollment
        </Grid>
        <Grid item container direction="row">
          <Grid item className={classes.tabSubTitle} xs={11}>
            Fill the fields below before uploading your xls file
            <Box component="span" fontWeight="500" m={0.5}>
              for faster processing
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Paper className={classes.wrapper}>
        <Container className={classes.middleWrapper}>
          <EnrollmentLeftBar
            userSelection={userSelection}
            setUserSelection={setUserSelection}
            getClientIdsRecord={getClientIdsRecord}
            cohortList={cohortList}
            setEnrollmentWave={setEnrollmentWave}
            enrollmentWave={enrollmentWave}
            navigateToEnrollmentCohortPage={navigateToEnrollmentCohortPage}
            extendedEnterprise={extendedEnterprise}
          />
          <div className={classes.formWrapper}>
            <Grid item container direction="row">
              <Grid item className={classes.uploadHelperText} xs={10}>
                <Typography className={classes.bold} variant="subtitle1">
                  Step 2
                </Typography>
                <Typography className={classes.mainhelpertext} variant="body1">
                  Upload XLS file with erollments for selected cohort.
                </Typography>
              </Grid>
              <Grid item className={classes.uploadHelperText} xs={10}>
                Points to note for the successful enrollments:
              </Grid>
              <Grid item className={classes.uploadHelperText} xs={10}>
                - All the existing users should be ‘Active’. All new users will automatically be tagged as &quot;active&quot;.
              </Grid>
              <Grid item className={classes.uploadHelperText} xs={11}>
                - If users are already enrolled in this learning plan within a different cohort than selected,
                they will be left out of the enrollment.
              </Grid>
            </Grid>
            <div className={classes.border}>
              <FileInput idx={0} onFileInput={onUpload} isDisabled={isButtonDisabled || !(enrollmentWave && enrollmentWave?.clientId?.length && enrollmentWave?.experience_type)}>
                <MainButton
                  title="Upload XLS template"
                  subtitle="We will add users to the selected client ID"
                  isDisabled={isButtonDisabled || !(enrollmentWave && enrollmentWave?.clientId?.length && enrollmentWave?.experience_type)}
                />
              </FileInput>
            </div>
            <div className={classes.downloadSampleWrapper}>
              <Button
                className={classes.downloadSampleButton}
                color="primary"
                variant="outlined"
                onClick={onDownloadSample}
              >
                Download sample program template
              </Button>
            </div>
          </div>
        </Container>
      </Paper>
    </Paper>
  );
};

UserManagementOperationsPage.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onDownloadSample: PropTypes.func.isRequired,
  getClientIdsRecord: PropTypes.func.isRequired,
  extendedEnterprise: PropTypes.string.isRequired,
  userSelection: PropTypes.shape({
    type: PropTypes.string.isRequired,
    configId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf([null]).isRequired])
      .isRequired,
  }).isRequired,
  setUserSelection: PropTypes.func.isRequired,
  cohortList: PropTypes.array.isRequired,
  setEnrollmentWave: PropTypes.func.isRequired,
  navigateToEnrollmentCohortPage: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  warningElement: PropTypes.bool.isRequired,
  enrollmentWave: PropTypes.object.isRequired,
};

export default memo(
  UserManagementOperationsPage,
  (prevProps, nextProps) => prevProps.userSelection === nextProps.userSelection
    && prevProps.extendedEnterprise === nextProps.extendedEnterprise
    && prevProps.enrollmentWave === nextProps.enrollmentWave
    && prevProps.cohortList === nextProps.cohortList
    && prevProps.isButtonDisabled === nextProps.isButtonDisabled
    && prevProps.warningElement === nextProps.warningElement,
);
