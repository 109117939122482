import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

/* Right now this component is specifically for showing empty state in content library module.
    This component accepts:
    onAdd: Function that will be called on add Journal/Page/Prompt
    level: String value i.e at which level(Journal/Page/Prompt) we are showing this empty state
    emptyIcon: Icon for page,journal or prompt
    disableButton: Tells whether the CTA button should be disabled or not
*/

const useStyles = makeStyles(() => ({
  noResultsWrapper: {
    display: 'flex',
    flex: 1,
    margin: ' 1.5rem 0.1rem 0.1rem 0.1rem',
  },
  noResultsBody: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem 0px',

    '& > img': {
      marginBottom: '2rem',
      height: '12.5rem',
      width: '12.5rem',
    },
    '& > p': {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    '& > button': {
      marginTop: '2rem',
    },
  },
}));

const NoDataComponent = ({
  onAdd, level, emptyIcon, disableButton,
}) => {
  const classes = useStyles({});

  return (
    <Paper square elevation={1} className={classes.noResultsWrapper}>
      <Box className={classes.noResultsBody}>
        <img src={emptyIcon} alt={`No ${level}`} />
        <Typography>{`No ${level.toLowerCase()} yet!`}</Typography>
        <Button type="button" variant="contained" color="primary" onClick={() => { onAdd(); }} disabled={disableButton}>
          {`Add ${level}`}
        </Button>
      </Box>
    </Paper>
  );
};

NoDataComponent.defaultProps = {
  disableButton: false,
};
NoDataComponent.propTypes = {
  onAdd: PropTypes.func.isRequired,
  level: PropTypes.string.isRequired,
  emptyIcon: PropTypes.node.isRequired,
  disableButton: PropTypes.bool,
};

export default NoDataComponent;
