import React, {
  useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select/async';
import _debounce from 'lodash.debounce';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@mui/material/Collapse';
import Alert from '@material-ui/lab/Alert';
import Box from '@mui/material/Box';

import { LIGHT_MEDIUM_GREY, MODERATE_DARK_GREY, WHITE } from '../../stylesheets/colors';
import theme from '../../stylesheets/theme';
import useAsyncSelectLoad from '../../hooks/useAsyncSelectLoad';
import InfoTooltip from './InfoTooltip';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& .MuiCollapse-entered': {
      marginBottom: '1rem',
    },
  },
  labelWrapper: {
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'flex-end',
  },
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: 'auto',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: MODERATE_DARK_GREY,
    padding: '0.25rem 0.5rem',
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: WHITE,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: WHITE,
  }),
};

const AutoMultiSelect = ({
  label, placeholder, onChange,
  maxMenuHeight, selectedItems, disableSelect, fetchData, handleMultiSelect,
  maxLimit, limitExceedMsg, tooltipMessage,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState('');
  const [openCollapse, setOpenCollapse] = useState(false);

  const isSelectedItemExceed = selectedItems && selectedItems.length >= maxLimit;

  useEffect(() => {
    if (disableSelect || isSelectedItemExceed) {
      setOpenCollapse(true);
    } else {
      setOpenCollapse(false);
    }
  }, [disableSelect, isSelectedItemExceed]);

  const debouncedChangeHandler = useMemo(
    () => _debounce((value) => setQuery(value), 1000),
    [],
  );

  const check = disableSelect || isSelectedItemExceed;

  const handleDisableOption = (option) => (check ? option.value : '');

  const { getOptions } = useAsyncSelectLoad(fetchData, handleMultiSelect);

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Collapse in={openCollapse}>
        <Alert severity="warning">
          {limitExceedMsg}
        </Alert>
      </Collapse>

      <Box className={classes.labelContainer}>
        <Typography variant="body1" className={classes.labelWrapper}>
          {label}
        </Typography>
        {tooltipMessage && (
          <InfoTooltip title={tooltipMessage} />
        )}
      </Box>

      <div className={classes.selectWrapper}>
        <Select
          defaultOptions
          cacheOptions
          styles={selectStyles}
          placeholder={placeholder}
          onInputChange={debouncedChangeHandler}
          loadOptions={getOptions}
          value={selectedItems}
          name={label}
          onChange={onChange}
          maxMenuHeight={maxMenuHeight}
          isMulti
          isClearable={false}
          isSearchable={!check}
          isOptionDisabled={handleDisableOption}
          menuPosition="fixed"
        />
      </div>
    </div>
  );
};

AutoMultiSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  handleMultiSelect: PropTypes.func.isRequired,
  maxMenuHeight: PropTypes.number,
  selectedItems: PropTypes.array,
  disableSelect: PropTypes.bool,
  maxLimit: PropTypes.number,
  limitExceedMsg: PropTypes.string,
  tooltipMessage: PropTypes.string,
};

AutoMultiSelect.defaultProps = {
  maxMenuHeight: 300,
  selectedItems: [],
  disableSelect: false,
  maxLimit: 20,
  limitExceedMsg: '',
  tooltipMessage: '',
};

export default AutoMultiSelect;
