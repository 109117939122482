import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '@material-ui/core/Button';
import { useStyles } from '../../stylesheets/commonModal.styles';
import ErrorIcon from '../../assets/icons/red-cross.svg';
import { ERROR_RED, WHITE } from '../../stylesheets/colors';

const errorModalStyles = makeStyles(() => ({
  paperFullWidth: {
    width: '580px',
  },
  title: {
    height: '50px',
    fontWeight: 'normal',
  },
  dialogContent: {
    fontSize: '14px',
    marginTop: '0px',
    width: '75%',
  },
  primaryErrorAction: {
    backgroundColor: ERROR_RED,
    color: WHITE,
    borderRadius: '0px',
    border: '0px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: WHITE,
      color: ERROR_RED,
    },
  },
  imageBlock: {
    height: '46px',
    width: '46px',
    marginBottom: '20px',
  },
  buttonActions: {
    marginBottom: '20px',
    justifyContent: 'center',
    marginTop: '0px',
  },
  conflictText: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
}));

const DialogTitle = (props) => {
  const classes = useStyles();
  const {
    children, onClose,
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleBlock}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography className={classes.titleText}>{children}</Typography>
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultMessage = 'Another user is performing the same activity.Please continue to see the progress.';

const ConflictErrorModal = ({
  open, onClose, refreshPage, conflictMessage,
}) => {
  const classes = useStyles();
  const modalClasses = errorModalStyles();

  const onCloseAction = () => {
    onClose();
    if (refreshPage) {
      window.location.reload();
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      disableBackdropClick
      onClose={onClose}
      open={open}
      classes={{ paperFullWidth: modalClasses.paperFullWidth }}
    >
      <DialogTitle onClose={onCloseAction} className={classes.dialogTitle}>
        <img className={modalClasses.imageBlock} src={ErrorIcon} alt="Error Icon" width={26} height={26} />
        <span className={`${classes.headingText} ${modalClasses.title}`}>
          Conflict
        </span>
      </DialogTitle>
      <MuiDialogContent className={`${classes.dialogContent} ${modalClasses.dialogContent}`} style={{ marginBottom: '0' }}>
        <Typography variant="subtitle2" className={modalClasses.conflictText}>
          {conflictMessage.length > 0
            ? conflictMessage : defaultMessage}
        </Typography>
      </MuiDialogContent>
      <MuiDialogActions className={modalClasses.buttonActions}>
        <Button onClick={onCloseAction} className={modalClasses.primaryErrorAction} variant="outlined">
          Okay
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

ConflictErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.bool,
  conflictMessage: PropTypes.string,
};

ConflictErrorModal.defaultProps = {
  refreshPage: true,
  conflictMessage: '',
};

export default ConflictErrorModal;
