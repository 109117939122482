import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _isEqual from 'lodash/isEqual';
import GroupsTableRow from './GroupsTableRow';
import EmptySearchComponent from '../../common/NoDataComponent';
import NoChannel from '../../../assets/img/noChannel.svg';
import LoadMoreComponent from '../../common/LoadMore';

const useStyles = makeStyles({
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    '& > div:nth-child(1)': {
      flex: 1,
    },
  },
});

const GroupsTable = ({
  groups, setSnackbarObj, setAlertBarConfig, getGroups,
  setGroups, groupsCount, onLoadMore, hasMore, isLoading, programId, totalGroupsLength,
}) => {
  const classes = useStyles();

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {(groups.length > 0 || isLoading) ? (
        <Box className={classes.tableContainer}>
          <Box>
            { groups.map((group, groupIndex) => (
              <Box key={group?.group_name}>
                <GroupsTableRow
                  group={group}
                  setSnackbarObj={setSnackbarObj}
                  setAlertBarConfig={setAlertBarConfig}
                  getGroups={getGroups}
                  setGroups={setGroups}
                  groupIndex={groupIndex}
                  programId={programId}
                />
              </Box>
            ))}
          </Box>
          <LoadMoreComponent
            totalItems={groupsCount}
            onLoadMore={onLoadMore}
            loadMoreText="groups"
            itemsShown={groups.length}
            hasMore={hasMore}
            isLoading={isLoading}
          />
        </Box>

      ) : !totalGroupsLength ? (
        <EmptySearchComponent
          imgSrc={NoChannel}
          primaryText="This learning plan has no groups yet."
          secondaryText="Please contact a Course Production member for further assistance."
        />
      ) : (
        <EmptySearchComponent
          imgSrc={NoChannel}
          primaryText="Oops! Could not find the group you were looking for."
          secondaryText="Adjust your search to see more results."
        />
      )}
    </>
  );
};

GroupsTable.propTypes = {
  groups: PropTypes.array.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  setAlertBarConfig: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  setGroups: PropTypes.func.isRequired,
  groupsCount: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  programId: PropTypes.number.isRequired,
  totalGroupsLength: PropTypes.number.isRequired,
};

export default memo(GroupsTable,
  (prevProps, nextProps) => _isEqual(prevProps.groups, nextProps.groups)
    && prevProps.groupsCount === nextProps.groupsCount
    && prevProps.hasMore === nextProps.hasMore
    && prevProps.isLoading === nextProps.isLoading
    && prevProps.programId === nextProps.programId);
