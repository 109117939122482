import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import PieChart from '../../common/PieChart';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    // fontFamily: 'McKinseySans',
  },
  fallbackWrapper: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2rem',
  },
});

const UsersScreen = ({
  activeUsers,
  inactiveUsers,
  participantUsers,
  totalEnrolledUsers,
  usersWithCohortSet,
  loading,
  error,
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.fallbackWrapper}>
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.fallbackWrapper}>
        Unable to connect to docebo. Please try later.
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <PieChart
        primaryCount={totalEnrolledUsers}
        secondaryCount={0}
        title={totalEnrolledUsers}
        subtitle="Total"
        label="Total users enrolled"
      />
      <PieChart
        primaryCount={usersWithCohortSet}
        secondaryCount={totalEnrolledUsers - usersWithCohortSet}
        title={`${usersWithCohortSet}/${totalEnrolledUsers}`}
        subtitle="Cohort Value"
        label="Users with cohort value set"
      />
      <PieChart
        primaryCount={activeUsers}
        secondaryCount={inactiveUsers}
        title={`${activeUsers}/${inactiveUsers}`}
        subtitle="Active Users"
        label="Active/Inactive Users"
      />
      <PieChart
        primaryCount={participantUsers}
        secondaryCount={totalEnrolledUsers}
        title={`${participantUsers}/${totalEnrolledUsers}`}
        subtitle="Participants"
        label="Participants/Observers"
      />
    </div>
  );
};

UsersScreen.propTypes = {
  activeUsers: PropTypes.number.isRequired,
  inactiveUsers: PropTypes.number.isRequired,
  participantUsers: PropTypes.number.isRequired,
  totalEnrolledUsers: PropTypes.number.isRequired,
  usersWithCohortSet: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

UsersScreen.defaultProps = {
  loading: false,
  error: false,
};

export default UsersScreen;
