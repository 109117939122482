import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button, Typography } from '@material-ui/core';

import ConfirmModal from '../common/ConfirmModal';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.7,
  },
  title: {
    fontWeight: 700,
  },
  action: {
    flex: 0.3,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
  },
  proceedButton: {
    flex: 0.72,
  },
  resetButton: {
    flex: 0.24,
  },
}));

const Titlebar = ({
  title, onReset, onProceed, enableBuild,
}) => {
  const classes = useStyles();
  const [resetModalOpen, setResetModalOpen] = useState(false);

  const closeModal = () => {
    setResetModalOpen(false);
  };

  const onConfirm = () => {
    closeModal();
    onReset();
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>
        <Typography className={classes.title} variant="h6">
          {`Modify Courses: ${title}`}
        </Typography>
        <Typography>
          You can drag and drop courses in-between learning plans and external
        </Typography>
      </div>
      <div className={classes.action}>
        <Button
          className={classes.resetButton}
          variant="outlined"
          onClick={() => setResetModalOpen(true)}
        >
          Reset
        </Button>
        <Button
          className={classes.proceedButton}
          variant="contained"
          color="primary"
          disabled={!enableBuild}
          onClick={onProceed}
        >
          Proceed with program building
        </Button>
      </div>
      <ConfirmModal
        open={resetModalOpen}
        message="All your changes until now will be lost. Are you sure you want to revert the program to its original state?"
        onClose={closeModal}
        onConfirm={onConfirm}
      />
    </div>
  );
};

Titlebar.propTypes = {
  title: PropTypes.string.isRequired,
  enableBuild: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
};

export default memo(Titlebar);
