import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogTitle } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import FormattedTypography from '../common/FormattedTypography';
import { COMPLETED, FAILED } from '../../constants';
import { ACCORDION_GREY, MEDIUM_GREY } from '../../stylesheets/colors';
import ReportContent from '../ActivityLogs/ReportContent';
import { getStatusLogo } from '../../helpers/utils';
import StatusModalWrapper from '../ActivityLogs/StatusModalWrapper';

const useStyles = makeStyles(() => ({
  circularProgressPadding: {
    padding: '1rem',
  },
  logoIcon: {
    '& > :nth-child(1)': {
      margin: '1rem 0 0 0',
    },
  },
  headingWrapper: {
    paddingTop: '0 !important',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
    padding: '0.25rem 0',
  },
  headingBoldContent: {
    color: MEDIUM_GREY,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  contentBox: {
    marginBottom: '0',
  },
  contentWrapper: {
    marginBottom: '0',
  },
}));

const UserProfileStatusModal = ({
  open, onClose, modalState, transactionId, getReport, progressData,
  progressErrorMsg, onDownloadLog, transactionData,
}) => {
  const classes = useStyles();

  const getModalTitle = (reportStatus, email) => {
    let header;
    let subTitle;
    if (reportStatus === COMPLETED) {
      header = `Update for ${email} complete!`;
      subTitle = 'User Details were successfully updated';
    } else { // FAILED
      header = `Update for ${email} failed!`;
      subTitle = 'User Details failed to update.';
    }
    return { header, subTitle };
  };

  const applyReportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const { email } = result;
    const { status, created_at } = transactionData;
    const { header, subTitle } = getModalTitle(status, email);

    return {
      component: ReportContent,
      wrapperProps: {
        logo: (
          <img
            src={getStatusLogo(status)}
            alt="status-icon"
            width={50}
            height={50}
            className={classes.circularProgressPadding}
          />
        ),
        heading: (
          <DialogTitle className={classes.headingWrapper}>
            <FormattedTypography
              variant="subtitle1"
              body={header}
              subHeading={(
                <>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={`${classes.headingBoldContent} ${classes.nestedText}`}
                  >
                    {subTitle}
                  </Typography>
                  {status === FAILED ? (
                    <Typography
                      variant="subtitle2"
                      className={`${classes.headingBoldContent} ${classes.infoHelperText}`}
                      align="center"
                    >
                      Please try again later.
                    </Typography>
                  ) : null}
                </>
                  )}
            />
          </DialogTitle>
        ),
        primaryBtnText: 'Download Log',
        breakpoint: 'sm',
        primaryBtnProps: {
          onClick: () => {
            onDownloadLog({
              transaction_id: transactionId,
              created_at,
            });
          },
        },
        logoWrapper: classes.logoIcon,
        contentWrapper: classes.contentBox,
        headingDivider: true,
        contentDivider: true,
        disableGutters: true,
      },
      props: { status, updatedValue: 'users' },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getModalTitle, getReport, onClose, onDownloadLog, transactionId]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={() => {}}
      reportModal={applyReportModal}
      feature="User profile"
    />
  );
};

UserProfileStatusModal.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

UserProfileStatusModal.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
  transactionData: PropTypes.object.isRequired,
};

export default UserProfileStatusModal;
