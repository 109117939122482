import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  DARK_MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  footerText: {
    color: DARK_MEDIUM_GREY,
    marginTop: '2rem',
    padding: 0,
    textAlign: 'center',
  },
});

const ErrorModal = ({ message }) => {
  const classes = useStyles();

  return (
    <Container className={classes.footerText}>
      <Typography variant="body1">{`${message || 'Unable to fetch data'}`}</Typography>
    </Container>
  );
};

ErrorModal.propTypes = {
  message: PropTypes.string.isRequired,
};

export default memo(ErrorModal, (() => true));
