import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import WorkshopManagementEventTable from './WorkshopManagementEventTable';
import LoaderIcon from '../../assets/icons/loader-icon.svg';
import LinkButton from '../common/LinkButton';
import { MODERATE_LIGHT_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  accordion: {
    '&.accordionRoot': {
      '&$expanded': {
        marginTop: 0,
        marginBottom: '6px',
      },
    },
  },
  expanded: {},
  accordionSummary: {
    '&.accordionSummaryRoot': {
      '&$accordionSummaryExpanded': {
        minHeight: '48px',
      },
      '& > $accordionSummaryContent': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > h6': {
          fontWeight: 'bold',
        },
        '&$accordionSummaryExpanded': {
          marginTop: '12px',
          marginBottom: '12px',
          '& svg': {
            transform: 'rotate(180deg)',
            transition: 'transform 150ms cubic-bezier(0.4,0,0.2,1) 0ms',
          },
        },
      },
    },
  },
  accordionSummaryContent: {},
  accordionSummaryExpanded: {},
  action: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    '&.active': {
      color: theme.palette.primary.main,
    },
    '& svg': {
      width: '5px',
      margin: '0 1px',
    },
  },
  detail: {
    '&.accordionSummaryDetail': {
      padding: 'unset',
    },
  },
  textContent: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '80%',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  loaderIconCls: {
    marginBottom: '0 !important',
    marginLeft: '1rem',
    animation: '$spinner 1s linear infinite',
  },
  syncButtonCls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 1.5rem',
    borderLeft: `thin solid ${MODERATE_LIGHT_GREY}`,
    borderRight: `thin solid ${MODERATE_LIGHT_GREY}`,
    padding: '0 1rem',
  },
}));

const WorkshopManagementSessionCard = ({
  data,
  editSession,
  addEvent,
  editEvent,
  onDeleteEventClick,
  onViewSessionClick,
  onDeleteSessionClick,
  onSingleSessionSyncClick,
  syncInitiatedSessionId,
  isDisabled,
  enrolled_users,
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  const actionMenu = [
    {
      title: 'View session details',
      onClick: onViewSessionClick,
    },
    {
      title: 'Edit session details',
      onClick: editSession,
      disabled: isDisabled,
    },
    {
      title: 'Add Event',
      onClick: addEvent,
      disabled: isDisabled,
    },
    {
      title: 'Delete Session',
      onClick: onDeleteSessionClick,
      disabled: isDisabled,
    },
  ];
  const canExpand = data?.events?.length > 0;

  useEffect(() => {
    if (data?.events?.length === 0) {
      setExpand(false);
    }
  }, [data]);

  const syncingUsersInprogress = syncInitiatedSessionId && syncInitiatedSessionId === data.id;
  return (
    <Accordion
      className="accordionRoot"
      classes={{ root: classes.accordion, expanded: classes.expanded }}
      expanded={expand}
      square
    >
      <AccordionSummary
        className="accordionSummaryRoot"
        classes={{
          root: classes.accordionSummary,
          content: classes.accordionSummaryContent,
          expanded: classes.accordionSummaryExpanded,
        }}
        aria-controls={`${data.id}-content`}
        id={`${data.id}-header`}
        onClick={() => canExpand && setExpand((prevState) => !prevState)}
      >
        <div className={classes.textContent}>
          <Typography variant="subtitle2">{data.name}</Typography>
          <Typography variant="h3" component="span" className={classes.syncButtonCls}>
            {data?.synced_users}
            /
            {enrolled_users}
            {' '}
            users synced
          </Typography>
          <div>
            {syncingUsersInprogress ? (
              <>
                <img src={LoaderIcon} alt="loader" className={classes.loaderIconCls} />
                <Typography variant="subtitle1" sx={{ pl: 2 }}>
                  Sync in progress
                </Typography>
              </>
            ) : (
              <LinkButton disabledSynchBtn={isDisabled} onClick={() => onSingleSessionSyncClick()} text="Sync users" />
            )}
          </div>
        </div>
        <div className={classes.action}>
          {canExpand && <ExpandMoreIcon />}
          <CustomPopover
            list={actionMenu}
            button={ThreeDotButton}
            disabledList={isDisabled}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails className="accordionSummaryDetail" classes={{ root: classes.detail }}>
        <WorkshopManagementEventTable
          editEvent={editEvent}
          deleteEvent={onDeleteEventClick}
          events={data.events}
          disabledList={isDisabled}
        />
      </AccordionDetails>
    </Accordion>
  );
};
WorkshopManagementSessionCard.defaultProps = {
  isDisabled: false,
};

WorkshopManagementSessionCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    synced_users: PropTypes.number.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
        venue: PropTypes.string.isRequired,
        attendance: PropTypes.number.isRequired,
        schedule: PropTypes.object.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  editSession: PropTypes.func.isRequired,
  addEvent: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  onViewSessionClick: PropTypes.func.isRequired,
  onDeleteSessionClick: PropTypes.func.isRequired,
  onDeleteEventClick: PropTypes.func.isRequired,
  onSingleSessionSyncClick: PropTypes.func.isRequired,
  syncInitiatedSessionId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  enrolled_users: PropTypes.number.isRequired,
};

export default memo(
  WorkshopManagementSessionCard,
  (prevProps, nextProps) => _isEqual(prevProps.data, nextProps.data)
    && _isEqual(prevProps.syncInitiatedSessionId, nextProps.syncInitiatedSessionId)
    && _isEqual(prevProps.isDisabled, nextProps.isDisabled)
  ,
);
