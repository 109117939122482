import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import {
  MODERATE_LIGHT_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  tableContainer: {
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    borderBottom: '0px',
    minWidth: '15rem',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3rem',
  },
  tableRow: {
    height: '3rem',
  },
  tableCell: {
    padding: '0px 16px',
  },
}));

const TestUserConfirmModal = ({ title, list, className }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.tableHead}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="subtitle2">{list.length}</Typography>
      </div>

      <TableContainer className={`${classes.tableContainer} ${className}`}>
        <Table className={classes.table} aria-label="simple table">
          {list.length > 0 && (
            <TableBody>
              {list.map((user) => (
                <TableRow key={user} className={classes.tableRow}>
                  <TableCell variant="body" align="left" className={classes.tableCell}>
                    {user}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default TestUserConfirmModal;

TestUserConfirmModal.defaultProps = {
  className: '',
};

TestUserConfirmModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};
