import React, { memo } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Chip from '@mui/material/Chip';
import { LOG_STATUS_LOOKUP, ACTIVITY_LOG_FEATURE, ACTIVITY_LOG_STATUS } from '../../constants';
import { MODERATE_LIGHT_GREY, BLACK, DARK_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    padding: '1.5rem 0',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    lineHeight: '1.75rem',
  },
  container: {
    marginLeft: '2rem',
  },
  detailWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    lineHeight: '2rem',
  },
  highlite: {
    fontWeight: 'bold',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
    alignItems: 'baseline',
    '& > button': {
      marginRight: '2rem',
    },
  },
  illustration: {
    width: '6.5rem',
  },
  breakText: {
    wordBreak: 'break-all',
  },
  statusText: {
    color: DARK_MEDIUM_GREY,
  },
  boldText: {
    fontWeight: 800,
  },
});

const TimelineActivity = ({
  data: {
    // eslint-disable-next-line react/prop-types
    created_at, created_by, transaction_id, type, status,
  },
  onViewDetails,
  hideView,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <img className={classes.illustration} src={LOG_STATUS_LOOKUP[status]} alt="date" />
      <Box className={classes.container}>
        <Box className={classes.highlite}>
          <Typography variant="subtitle2" className={classes.boldText}>
            {ACTIVITY_LOG_FEATURE[type].MODULE}
          </Typography>
          <Chip
            style={{
              backgroundColor: MODERATE_LIGHT_GREY,
              color: BLACK,
              fontWeight: 400,
              fontSize: '1rem',
              borderRadius: '4px',
            }}
            label={ACTIVITY_LOG_FEATURE[type].LABEL}
            size="small"
          />
          <Typography variant="body1" className={classes.boldText}>
            {`${created_by} on`}
          </Typography>
          <Typography variant="body1" className={`${classes.breakText}${classes.boldText}`}>
            {`${format(new Date(`${created_at}+00:00`),
              'do MMM yy hh:mm a')}`}
          </Typography>
          <Typography variant="body1" className={`${classes.breakText} ${classes.statusText}`}>
            Status:
            <Typography display="inline" component="span" style={{ color: ACTIVITY_LOG_STATUS[status]?.COLOR, paddingLeft: '0.5rem' }}>
              {ACTIVITY_LOG_STATUS[status]?.LABEL}
            </Typography>
          </Typography>
        </Box>
        <Box className={classes.detailWrapper}>
          <Box className={classes.footer}>
            {!hideView && (
              <Button color="primary" variant="outlined" onClick={() => onViewDetails(transaction_id, type, status)}>
                {ACTIVITY_LOG_STATUS[status]?.CTA}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

TimelineActivity.defaultProps = {
  hideView: false,
};

TimelineActivity.propTypes = {
  data: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    created_by: PropTypes.string,
  }).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  hideView: PropTypes.bool,
};

export default memo(
  TimelineActivity,
  (prevProps, nextProps) => _isEqual(prevProps.data, nextProps.data),
);
