import React, { memo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@material-ui/core/Button';
import FileInput from '../FileInput';
import MainButton from '../MainButton';
import BreadCrumbHOC from '../BreadCrumbHOC';
import {
  ROUTE_REPORT, COURSE_OPS, WAVE_MANAGEMENT, ENROLLMENT_META_DATA_TEMPLATE,
} from '../../constants';
import {
  MODERATE_DARK_GREY,
  MCKINSEY_BLUE,
  ERROR_RED,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    boxShadow: 'none',
  },
  breadCrumbSection: {
    display: 'flex',
    padding: '1.25rem 2rem',
  },
  headingSection: {
    padding: '0rem 2rem 1rem',
  },

  mainWrapper: {
    flexGrow: 1,
  },
  middleWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    padding: '1rem 2rem',
  },
  boldText: {
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
  },
  userEnrollMentButton: {
    fontSize: '1.5rem',
  },
  border: {
    margin: '1.2rem 0',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
  downloadWaveDataLbl: {
    marginTop: '1rem',
  },
  transferInfo: {
    marginBottom: '1rem',
    fontWeight: 'bold',
  },
  DownloadMetaDataBtn: {
    width: '20rem',
    marginBottom: '1rem',
  },
  divider: {
    height: '0rem',
  },
  errorInfo: {
    color: ERROR_RED,
    marginBottom: '1rem',
  },
}));

const EnrollmentMetaData = ({
  programId,
  waveId,
  onUploadMetaData,
  isButtonDisabled,
  onDownloadMetaData,
}) => {
  const classes = useStyles();

  const onDownloadSample = async () => {
    window.open(ENROLLMENT_META_DATA_TEMPLATE, '_blank');
  };

  const breadCrumbList = [
    { label: 'All Enrollment Cohorts', redirectionUrl: `/${ROUTE_REPORT}/${programId}/${COURSE_OPS}/${WAVE_MANAGEMENT}`, isActive: false },
    { label: 'Cohort Details', redirectionUrl: `/${ROUTE_REPORT}/${programId}/${COURSE_OPS}/${WAVE_MANAGEMENT}/${waveId}`, isActive: false },
    { label: 'Download/Upload Metadata XLS File', redirectionUrl: '', isActive: true },
  ];
  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      <Box className={classes.breadCrumbSection}>
        <BreadCrumbHOC
          list={breadCrumbList}
        />
      </Box>
      <Box className={classes.headingSection}>
        <Typography
          variant="h1"
        >
          Download/Upload Enrollment Metadata
        </Typography>
      </Box>
      <Divider variant="fullWidth" className={classes.divider} light />
      <Paper className={classes.wrapper}>
        <Container maxWidth={false} className={classes.middleWrapper}>
          <Typography
            variant="body1"
            className={classes.transferInfo}
          >
            Ensure you have the last updated data for all
            users before you choose to edit the enrollment metadata
          </Typography>
          <Button className={classes.DownloadMetaDataBtn} data-testid="downloadLatestInfoBtn" color="primary" variant="contained" onClick={onDownloadMetaData}>
            Download Latest User Information
          </Button>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Box className={classes.border}>
            <FileInput
              idx={0}
              onFileInput={onUploadMetaData}
              isDisabled={isButtonDisabled}
            >
              <MainButton
                title="Upload XLS file"
                subtitle="We will upload the Enrollment Metadata fields to update user data for this LP "
                isDisabled={isButtonDisabled}
              />
            </FileInput>
          </Box>
          <Typography
            variant="body1"
            className={classes.errorInfo}
          >
            The Custom User Fields won’t be updated as of now.
          </Typography>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Box className={classes.downloadTempBtn}>
            <Typography variant="body1" className={classes.downloadWaveDataLbl}>Need help configuring the file? Download the template below.</Typography>
            <Button
              variant="outlined"
              style={{ marginTop: '0.75rem', width: 'fit-content' }}
              color="primary"
              onClick={onDownloadSample}
              data-testid="downloadtemplateBtn"
            >
              Download Enrollment Metadata Template
            </Button>
          </Box>
        </Container>
      </Paper>
    </Paper>
  );
};

EnrollmentMetaData.propTypes = {
  programId: PropTypes.number.isRequired,
  waveId: PropTypes.string.isRequired,
  onUploadMetaData: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  onDownloadMetaData: PropTypes.func.isRequired,
};
export default memo(
  EnrollmentMetaData,
  () => {},
);
