import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import _intersection from 'lodash/intersection';
import { get } from 'lodash';
import { getUserFromLocalStorage } from './helpers/userHelper';
import {
  ROUTE_ERROR,
  USER_INFO_KEY,
} from './constants';

/* eslint-disable react/jsx-props-no-spreading */
// https://stackoverflow.com/a/47476903/1217998
const AuthorizeRoute = ({
  component: Component, roles, path, ...rest
}) => {
  const userInfo = getUserFromLocalStorage(USER_INFO_KEY);
  const userRoles = get(userInfo, 'teams');
  const isAuthorized = !!_intersection(roles, userRoles).length;

  useEffect(() => {
    if (userInfo?.email) {
      window.heap.identify(userInfo.email);
      window.heap.addUserProperties({
        roles: userRoles,
        name: `${userInfo.first_name} ${userInfo.last_name}`,
        nvtUserId: userInfo.nvt_user_id,
      });
    }
  }, [userInfo, userRoles]);

  return (
    <Route
      {...rest}
      path={path}
      render={(props) => (isAuthorized ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: `/${ROUTE_ERROR}`, state: { from: path } }} />
      ))}
    />
  );
};
/* eslint-enable react/jsx-props-no-spreading */

AuthorizeRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default AuthorizeRoute;
