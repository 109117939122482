import React, { useState, useCallback, useEffect } from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import { isCancel } from 'axios';
import { useHistory } from 'react-router-dom';
import FileInput from '../../FileInput';
import MainButton from '../../MainButton';
import ValidationError from '../../../assets/img/validation-error.webp';
import FormattedTypography from '../../common/FormattedTypography';
import CustomUploadModal from '../../common/CustomUploadModal';
import { parseValidationResult } from '../../../helpers/xlsxValidation';
import { zeroAppender } from '../../../helpers/formattingHelpers';
import {
  ACTIVITIES_STATUS, AUTO_HIDE_DURATION, COURSE_OPS,
  GROUPWORK_GROUPS, ROUTE_REPORT,
} from '../../../constants';
import BreadCrumbHOC from '../../BreadCrumbHOC';
import Header from '../../common/Header';
import GWUploadError from '../common/GWUploadError';
import { uploadXlsx } from '../../../containers/GroupWorkPage/apis';
import { getErrorMessage } from '../../../helpers/apiHelper';
import {
  downloadFile, getProgress, getReport, validateXLSXApiCall,
} from '../../../containers/common/apis';
import { internalServerErrorModalLogic } from '../../../containers/common/utils';
import ErrorModal from '../../ErrorModal/ErrorModal';
import { onDownloadGWData } from '../common/utils';
import commonGWStyles from '../common/styles';
import GroupEnrollStatusModal from '../common/GroupEnrollStatusModal';
import AlertReleaseInfo from '../../ContentManagement/AlertReleaseInfo';

const useStyles = makeStyles({
  ...commonGWStyles,
});

const UserEnrollmentUpload = ({
  match, programId, setSnackbarObj, programMetadata, programSubType,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const configId = get(programMetadata, 'config_id', '');
  const programType = get(match, 'params.programType');
  const initialValidationResult = { status: 'none', errors: [] };

  const [isLoading, setIsLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [modalState, setModalState] = useState(null);
  const [pollProgress, setPollProgress] = useState(true);
  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: GWUploadError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={110} height={110} />,
        heading: null,
        primaryBtnText: 'Close',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
        contentDivider: true,
      },
      props: initialValidationResult,
    },
  });

  const breadCrumbList = [
    { label: 'Group Management', redirectionUrl: `/${ROUTE_REPORT}/${programId}/${COURSE_OPS}/${GROUPWORK_GROUPS}`, isActive: false },
    { label: 'Download/Upload Group Enrollment XLS File', redirectionUrl: '', isActive: true },
  ];

  useEffect(() => {
    const groupManagementTransactionId = get(
      programMetadata,
      `activities_status.${ACTIVITIES_STATUS[programSubType] ? ACTIVITIES_STATUS[programSubType] : ''
      }.transaction_ids`,
      null,
    );

    if (groupManagementTransactionId && groupManagementTransactionId[0]) {
      setTransactionId(groupManagementTransactionId[0]);
    }
  }, [programMetadata, programSubType]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        if (!transactionId || !pollProgress) return;
        const res = await getProgress(transactionId);
        const { done, file_name } = res?.data;
        if (done) {
          batchUpdates(() => {
            setProgress(res?.data);
            setModalState('REPORT');
            setPollProgress(false);
            setIsLoading(false);
          });
        } else {
          batchUpdates(() => {
            setModalState('PROCESSING');
            setProgress(res?.data);
            setFileName(file_name);
            setIsLoading(true);
            setShowAlertBar(true);
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        batchUpdates(() => {
          setModalState(null);
          setShowAlertBar(false);
          setShowReportModal(false);
        });
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
  }, [history, pollProgress, transactionId]);

  useEffect(() => {
    let timerObj;
    if (progress?.done) {
      timerObj = setTimeout(() => setShowAlertBar(false), AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(timerObj);
    };
  }, [progress]);

  const resetFileUpload = useCallback(
    (e) => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          props: initialValidationResult,
        },
      }));
      e.target.value = '';
    },
    [initialValidationResult],
  );

  const generateFormData = useCallback(
    (file) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('program_id', programId);
      return formData;
    },
    [programId],
  );

  const uploadFile = useCallback(
    async (formData) => {
      try {
        setModalState(null);
        const uploadResult = await uploadXlsx(programType, programSubType, formData, programId);
        const tid = uploadResult?.data?.transaction_id;
        setTransactionId(tid);
        setPollProgress(true);
      } catch (e) {
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        setIsLoading(false);
        setShowAlertBar(false);
      }
    },
    [programId, programSubType, programType, setSnackbarObj],
  );

  const onUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      batchUpdates(() => {
        setFileName(file?.name);
        setIsLoading(true);
        setShowAlertBar(true);
        setProgress({ done: null, percentage: 0 });
      });
      const formData = generateFormData(file);
      resetFileUpload(event);
      let result;
      try {
        result = await validateXLSXApiCall(
          programType,
          programSubType,
          formData,
          programId,
        );
      } catch (e) {
        setIsLoading(false);
        setShowAlertBar(false);
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        return; // Early exit
      }
      const errorMessages = parseValidationResult(result);
      const errorMessageCount = errorMessages.errors?.length || 0;
      const [extension, ...nameParts] = file.name.split('.').reverse();

      batchUpdates(() => {
        setModalScheme((schema) => ({
          ...schema,
          VALIDATION: {
            ...schema.VALIDATION,
            wrapperProps: {
              ...schema.VALIDATION.wrapperProps,
              heading: (
                <FormattedTypography
                  prefix="Scanning file&nbsp;"
                  body={nameParts.join('.')}
                  suffix={`.${extension}`}
                  subHeading={`${zeroAppender(
                    errorMessageCount,
                  )} errors found. Please make the required changes and re-upload your file.`}
                />
              ),
            },
            props: errorMessages,
          },
        }));
      });

      if (errorMessages.errors.length) {
        setModalState('VALIDATION');
        setShowAlertBar(false);
        setIsLoading(false);
        return;
      }

      await uploadFile(formData);
    },
    [generateFormData, programId, programSubType, programType,
      resetFileUpload, setSnackbarObj, uploadFile],
  );

  const onDownloadLog = async (data) => {
    try {
      await downloadFile(transactionId, configId, programSubType, data?.created_at || new Date());
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const onViewStatus = (reportStatus) => {
    batchUpdates(() => {
      setModalState(reportStatus);
      setShowReportModal(true);
    });
  };

  const body = (
    <Container className={classes.innerWrapper} disableGutters maxWidth={false}>
      <Box className={classes.uploadSection}>
        <Box>
          <Typography variant="body1" className={classes.downloadGWDataBtn}>Ensure you have the last updated data for all groups before you choose to edit/add the users. This can be used as a template before you have uploaded any data to this field as well.</Typography>
          <Button
            variant="contained"
            style={{
              marginTop: '0.75rem',
            }}
            color="primary"
            onClick={() => { onDownloadGWData({ programId, programSubType, setSnackbarObj }); }}
          >
            Download Latest Group Information
          </Button>
        </Box>
        <Divider light flexItem sx={{ mt: '1rem' }} />
        <Box className={classes.border}>
          <FileInput
            idx={0}
            onFileInput={onUpload}
            isDisabled={isLoading}
          >
            <MainButton
              isDisabled={isLoading}
              title="Upload XLS file"
              subtitle="We will upload the users to update group enrollments for this LP"
            />
          </FileInput>
        </Box>
      </Box>
    </Container>
  );

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  const modalJsx = (
    <>
      {showReportModal && (
      <GroupEnrollStatusModal
        open={showReportModal}
        onClose={() => {
          setShowReportModal(false);
        }}
        modalState={modalState}
        transactionId={transactionId}
        getReport={getReport}
        progressData={progress}
        onDownloadLog={onDownloadLog}
        type={programSubType}
        configId={configId}
      />
      )}
      {ModalComponent && (
      <CustomUploadModal
        open={modalSchema[modalState] !== undefined}
        onClose={() => setModalState(null)}
        className={classes.modalHeight}
        breakpoint="sm"
        {...modalSchema[modalState].wrapperProps}
      >
        <ModalComponent {...modalSchema[modalState].props} />
      </CustomUploadModal>
      )}
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </>
  );

  return (
    <Box className={classes.uploadPageWrapper}>
      <Paper className={classes.wrapper}>
        {modalJsx}
        {showAlertBar ? (
          <AlertReleaseInfo
            progress={progress}
            showElement={showAlertBar}
            progressMessage={`Uploading group enrollment file "${fileName}"`}
            withErrorsMessage={`Group enrollment file "${fileName}" upload was completed with errors.`}
            failedMessage={`Failed to upload group enrollment file "${progress.file_name}". Please try again.`}
            successMessage={`Group enrollment file "${fileName}" was successfully uploaded`}
            onViewStatus={onViewStatus}
            setShowElement={setShowAlertBar}
            showCTAForSuccess={false}
            showCTAForLoading={false}
          />
        ) : null}
        <Box className={classes.breadCrumbWrapper}>
          <BreadCrumbHOC list={breadCrumbList} />
        </Box>
        <Box className={classes.mainWrapper}>
          <Header heading="Download/Upload Group Information" className={classes.pageHeader} />
          {body}
        </Box>
      </Paper>
    </Box>
  );
};

UserEnrollmentUpload.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.number,
    }),
  }).isRequired,
  programId: PropTypes.number.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  programMetadata: PropTypes.object.isRequired,
  programSubType: PropTypes.string.isRequired,
};

export default UserEnrollmentUpload;
