import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';

import Header from '../common/Header';
import FlyoutMenu from '../common/FlyoutMenu';
import EditProgramInstructionCard from './EditProgramInstructionCard';
import { DARK_MEDIUM_GREY } from '../../stylesheets/colors';

const getInstructions = (isDoceboProgram) => {
  const defaultData = {
    dos: {
      heading: 'Components that can be changed',
      items: [{
        title: 'Learning Plan',
        editable: true,
        success: true,
      }, {
        title: 'Course Code',
        editable: true,
        success: true,
      },
      {
        title: 'Cohort names and rules',
        editable: !isDoceboProgram,
        success: true,
      },
      {
        title: 'Channel name  and visibility',
        editable: !isDoceboProgram,
        success: true,
      },
      {
        title: 'Enrollment Rules',
        editable: !isDoceboProgram,
        success: true,
      }],
    },
    donts: {
      heading: 'Components that cannot be changed',
      items: [{
        title: 'Groupworks',
        success: false,
      }, {
        title: 'Catalogues',
        success: false,
      },
      {
        title: 'Already enrolled users',
        success: false,
      }],
    },
  };

  defaultData.dos.items = defaultData.dos.items.filter((v) => v.editable);
  return defaultData;
};

const useStyles = makeStyles({
  instructionWrapper: {
    display: 'flex',
    flex: 0.34,
    flexDirection: 'column',
  },
  menuSubtitleMedGrey: {
    color: DARK_MEDIUM_GREY,
  },
});

const EditProgramHeader = ({ className, shouldShow, isDoceboProgram }) => {
  const classes = useStyles();
  const instructions = getInstructions(isDoceboProgram);

  return (
    <Header
      className={className}
      heading="Modify Config ID"
      subHeading="Modify your Config ID along with some of its components"
    >
      <FlyoutMenu
        buttonTitle="Instructions"
        subTitle="Modifying a Config ID triggers changes for various components within a Program."
        initialState={shouldShow}
        classes={{
          subtitle: classes.menuSubtitleMedGrey,
        }}
      >
        <Container className={className}>
          <EditProgramInstructionCard
            className={classes.componentDetails}
            success
            heading={instructions.dos.heading}
            items={instructions.dos.items}
          />
          <EditProgramInstructionCard
            className={classes.componentDetails}
            success={false}
            heading={instructions.donts.heading}
            items={instructions.donts.items}
          />
        </Container>
      </FlyoutMenu>
    </Header>
  );
};

EditProgramHeader.defaultProps = {
  className: '',
  isDoceboProgram: false,
};

EditProgramHeader.propTypes = {
  className: PropTypes.string,
  shouldShow: PropTypes.bool.isRequired,
  isDoceboProgram: PropTypes.bool,
};

export default memo(EditProgramHeader, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
  && prevProps.shouldShow === nextProps.shouldShow
));
