import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import get from 'lodash/get';

import MainBlock from '../ProgramCreationReportBody/MainBlock';

import {
  KEY_COURSE_CREATION,
} from '../../../constants';
import { filterSteps } from '../ProgramCreationReportBody/utils';

const useStyles = makeStyles(() => ({
  outerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  prompt: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
  },
}));

const AssignContentBody = ({
  data, onAssignClick, location,
}) => {
  const classes = useStyles();
  const {
    overall_success: isSuccessful,
    marked_at: markedAt,
  } = data.meta;

  // Course id on assignment
  const modifiedCourseId = Number.parseInt(get(location, 'state.courseId', '-1'), 10);

  // Explicit equation to false to not allow nulls or undefineds
  const completed = !(isSuccessful === false && !markedAt);
  const isMarked = !!markedAt;

  const filteredStep = filterSteps(data.steps, KEY_COURSE_CREATION);

  if (!completed) {
    return (
      <Paper className={classes.outerWrapper}>
        <div className={classes.prompt}>
          Only completed programs can have courses assigned
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.outerWrapper}>
      <MainBlock
        data={filteredStep}
        onAssignClick={onAssignClick}
        completed={completed}
        marked={isMarked}
        modifiedCourseId={modifiedCourseId}
        showFindContent
      />
    </Paper>
  );
};

AssignContentBody.propTypes = {
  data: PropTypes.shape({
    meta: PropTypes.shape({
      overall_success: PropTypes.bool.isRequired,
      marked_at: PropTypes.string,
    }).isRequired,
    steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  onAssignClick: PropTypes.func.isRequired,
  // transactionId: PropTypes.number.isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
};

export default AssignContentBody;
