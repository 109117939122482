import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Chip } from '@mui/material';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormattedTypography from '../../common/FormattedTypography';
import InfoIcon from '../../../assets/icons/icons-alert-info.svg';
import {
  MODERATE_LIGHT_GREY, DARK_YELLOW, MCKINSEY_BLUE, FILL_ERROR_RED,
  PALE_GREY, BLACK, MODERATE_DARK_GREY, LIGHT_PURPLE, BANNER_FOREGROUND, ERROR_ORANGE1, WHITE,
} from '../../../stylesheets/colors';
import { modalIntitalSchema } from '../../../constants';

const useStyles = makeStyles({
  listWrapper: {
    background: MODERATE_LIGHT_GREY,
  },
  iconWrapper: {
    minWidth: '3rem',
    marginLeft: '1rem',
  },
  errorIcon: {
    color: FILL_ERROR_RED,
    fontSize: '1.8rem',
  },
  errorListWrapper: {
    background: ERROR_ORANGE1,
    color: FILL_ERROR_RED,
    padding: '1rem 0',
    marginBottom: '1rem',
    alignItems: 'start',
  },
  bold: {
    fontWeight: 900,
  },
  centerAction: {
    justifyContent: 'center',
    marginTop: '2rem',
  },
  wrapperContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem',
  },
  boxContainer: {
    width: 'auto',
    minWidth: '16rem',
    height: '6rem',
    margin: '0 1.25rem 0 0',
    padding: '1rem 3.063rem 1rem 1.25rem',
    background: PALE_GREY,
    boxShadow: '0 0 1px 0 rgba(5, 28, 44, 0.15), 0 4px 8px -1px rgba(5, 28, 44, 0.2)',
  },
  childContainer: {
    flexDirection: 'column',
    color: MODERATE_DARK_GREY,
  },
  textContent: {
    fontSize: '2rem',
    color: BLACK,
    fontWeight: '500',
  },
  listButton: {
    '&.MuiListItemButton-root': {
      flexGrow: '0',
      paddingTop: '0',
      paddingBottom: '0',
      textAlign: 'right',
      color: MCKINSEY_BLUE,
      '&:hover': {
        background: 'inherit',
        color: DARK_YELLOW,
      },
    },
  },
  infoSection: {
    display: 'flex',
    color: BANNER_FOREGROUND,
    background: LIGHT_PURPLE,
    padding: '1rem 1.375rem 1rem 1.5rem',
    alignItems: 'flex-start',
  },
  info: {
    marginLeft: '1rem',
    color: BANNER_FOREGROUND,
    fontWeight: 'bold',
  },
  listContainer: {
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  listItemText: {
    margin: 0,
    fontWeight: '500',
    fontSize: '1.125rem',
  },
  chipContainer: {
    height: 'auto !important',
    margin: '0 5px',
  },
  chipLabel: {
    padding: '0.2rem 0',
    borderRadius: '5px',
    background: FILL_ERROR_RED,
    color: WHITE,
  },
});

const CheckboxRow = ({ onChange, name, checked }) => (
  <FormControlLabel
    control={<Checkbox color="primary" name={name} onChange={onChange} checked={checked} />}
    label="Ignore errors and proceed with upload. (Selected client value will override the client values(ID, Name and Branch) in XLS)"
  />
);

CheckboxRow.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

const EnrollmentUploadError = ({
  totalUsers, warnings, setSchema, formData, navigateToEnrollmentCohortPage,
  navigateToClientMaster,
}) => {
  const file = formData.get('file');
  const classes = useStyles();

  return (
    <>
      <Box className={classes.wrapperContainer}>
        <Box className={classes.boxContainer}>
          <FormattedTypography
            prefix="Total users found"
            body={totalUsers}
            variant="body1"
            justifyContent="left"
            containerClassName={classes.childContainer}
            className={classes.textContent}
          />
        </Box>
        <Box className={classes.boxContainer}>
          <FormattedTypography
            prefix="File name"
            body={file.name}
            variant="body1"
            justifyContent="left"
            containerClassName={classes.childContainer}
            className={classes.textContent}
          />
        </Box>
      </Box>
      <List component="nav" aria-label="main" dense>
        {warnings.map((warn) => (
          <ListItem key={warn.message} className={classes.errorListWrapper}>
            <ListItemIcon className={`${classes.iconWrapper} ${classes.errorListIcon}`}>
              <CancelIcon className={classes.errorIcon} sx={{ fontSize: '1.8rem' }} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={classes.listItemText}
              primary={<Typography className={classes.bold} variant="body1">{warn.message}</Typography>}
              secondary={(
                <FormattedTypography
                  prefix={`${warn.validation === 'client_id' ? 'Client' : 'Domain'} not mapped:`}
                  body={warn?.invalid_value?.map((item) => (
                    <Chip
                      className={classes.chipContainer}
                      classes={{
                        label: classes.chipLabel,
                      }}
                      key={item}
                      label={item}
                    />
                  ))}
                  variant="body1"
                  justifyContent="left"
                  containerClassName={classes.listContainer}
                  prefixClassName={classes.bold}
                />
              )}
            />
          </ListItem>
        ))}
      </List>
      <Box variant="subtitle2" className={classes.infoSection}>
        <img src={InfoIcon} alt="infoIcon" />
        <Typography variant="subtitle2" className={classes.info}>
          Managing EC and Domain Information will redirect you to their
          respective pages and cancel the current process.
        </Typography>
      </Box>
      <DialogActions className={classes.centerAction}>
        <Button variant="outlined" color="primary" onClick={() => { setSchema(modalIntitalSchema); }}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={warnings[0].validation === 'client_id' ? navigateToEnrollmentCohortPage : navigateToClientMaster}
        >
          {warnings[0].validation === 'client_id' ? 'Manage Enrollment Cohort' : 'Manage Client Master Data'}
        </Button>
      </DialogActions>

    </>
  );
};

EnrollmentUploadError.propTypes = {
  totalUsers: PropTypes.number.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.shape({
    column: PropTypes.string,
    message: PropTypes.string.isRequired,
    proceed: PropTypes.bool.isRequired,
    validation: PropTypes.string.isRequired,
  })).isRequired,
  setSchema: PropTypes.func.isRequired,
  navigateToEnrollmentCohortPage: PropTypes.func.isRequired,
  navigateToClientMaster: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default memo(EnrollmentUploadError, (() => true));
