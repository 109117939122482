// import { LOGOUT } from '@nvt/mid-react/dist/store/constants';

import {
  COURSE_PROD,
  COURSE_OPS,
  PROGRAM_CREATION,
  BULK_UNENROLLMENT,
  AUTO_ENROLLMENT,
  TEXT_PROGRAM_CREATION,
  TEXT_BULK_UNENROLLMENT,
  TEXT_AUTO_ENROLLMENT,
  TEXT_DIGITAL_PRODUCTION,
  TEXT_DIGITAL_DELIVERY,
  TEXT_GROUPWORK_GROUPS,
  GROUPWORK_GROUPS,
  WEBINAR_MANAGEMENT,
  TEXT_WEBINAR_MANAGEMENT,
} from '../../constants';

export const programSubtypeLabelLut = {
  // course_prod
  [PROGRAM_CREATION]: TEXT_PROGRAM_CREATION,

  // course_ops
  [BULK_UNENROLLMENT]: TEXT_BULK_UNENROLLMENT,
  [AUTO_ENROLLMENT]: TEXT_AUTO_ENROLLMENT,
  [GROUPWORK_GROUPS]: TEXT_GROUPWORK_GROUPS,
  [WEBINAR_MANAGEMENT]: TEXT_WEBINAR_MANAGEMENT,
};

export const programTypeToSubtypeMapping = {
  [COURSE_PROD]: [PROGRAM_CREATION],
  [COURSE_OPS]: [BULK_UNENROLLMENT, AUTO_ENROLLMENT, GROUPWORK_GROUPS, WEBINAR_MANAGEMENT],
};

export const dropdownLabelLut = {
  [COURSE_PROD]: TEXT_DIGITAL_PRODUCTION,
  [COURSE_OPS]: TEXT_DIGITAL_DELIVERY,
};

export const dropDownOptions = [
  { label: dropdownLabelLut[COURSE_PROD], value: COURSE_PROD },
  { label: dropdownLabelLut[COURSE_OPS], value: COURSE_OPS },
];

export const userDropdownOptions = [{ label: 'Logout', value: 'LOGOUT' }];
