import React, { useState, useEffect, useCallback } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Typography from '@mui/material/Typography';
import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import LoaderIcon from '../../assets/icons/loader-icon-white.svg';
import CheckRoundIconDark from '../../assets/icons/green-round-check-dark.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import WarningIcon from '../../assets/icons/warningIcon.svg';
import {
  DARK_GREEN1, ERROR_ORANGE1, MCKINSEY_BLUE, RED_DARK, SUCCESS_GREEN3, DARK_YELLOW,
  LIGHT_YELLOW,
} from '../../stylesheets/colors';
import useNotifications from '../../hooks/useNotifications';

const useStyles = makeStyles({
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bodyContainer: {
    display: 'flex',
    minWidth: '9.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: MCKINSEY_BLUE,
    marginRight: '1rem',
  },
  statusIcon: {
    padding: '1.125rem',
  },
});

const AppAlertBar = () => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const {
    clear,
    notification: variant,
    notificationText,
  } = useNotifications();

  useEffect(() => {
    if (variant && notificationText && variant.length > 0 && notificationText.length > 0) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
        clear();
      }, 3000);
    }
  }, [variant, notificationText, clear]);

  const getStylingProps = useCallback(() => {
    if (variant === 'success') {
      return {
        statusIcon: CheckRoundIconDark,
        colorOuter: SUCCESS_GREEN3,
        innerContainerCls: classes.flexCenter,
        imgClassName: classes.statusIcon,
        labelTextColor: DARK_GREEN1,
      };
    }
    if (variant === 'error') {
      return {
        statusIcon: ErrorRoundIcon,
        colorOuter: ERROR_ORANGE1,
        innerContainerCls: classes.flexCenter,
        imgClassName: classes.statusIcon,
        labelTextColor: RED_DARK,
      };
    }
    if (variant === 'info') {
      return {
        statusIcon: WarningIcon,
        colorOuter: LIGHT_YELLOW,
        innerContainerCls: classes.flexCenter,
        imgClassName: classes.statusIcon,
        labelTextColor: DARK_YELLOW,
      };
    }
    return {
      statusIcon: LoaderIcon,
      colorOuter: 'transparent',
      innerContainerCls: classes.flexCenter,
      imgClassName: classes.statusIcon,
      labelTextColor: 'inherit',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  const {
    statusIcon, colorOuter, imgClassName, innerContainerCls, labelTextColor,
  } = getStylingProps();

  return (
    <>
      {
        show ? (
          <Grid container className={classes.flexCenter} sx={{ boxShadow: 1, bgcolor: colorOuter }}>
            <Grid item className={classes.flexCenter}>
              <Box component="div" className={innerContainerCls}>
                <img
                  alt="status-icons"
                  src={statusIcon}
                  width="40rem"
                  height="40rem"
                  className={imgClassName}
                />
              </Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: '1.125rem',
                  color: `${labelTextColor}`,
                  padding: '0 1rem',
                }}
              >
                {notificationText}
              </Typography>
            </Grid>
          </Grid>
        ) : null
      }
    </>
  );
};

export default AppAlertBar;
