import config from '../../config';
import request from '../../helpers/apiHelper';

export const getComponents = async (programId, update) => {
  const url = `${config.ROUTES.OVERVIEW_COMPONENTS}/${programId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result;

  return data;
};

export const getLpEnrollments = async (lpId, update) => {
  const url = `${config.ROUTES.OVERVIEW_COMPONENTS_LP_COUNT}/${lpId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result.data;

  return data;
};

export const getCourseEnrollments = async (courseId, update) => {
  const url = `${config.ROUTES.OVERVIEW_COMPONENTS_COURSE_COUNT}/${courseId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result.data;

  return data;
};
