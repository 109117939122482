import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getUserDetails,
  clearAlertBar,
  setUserDetailsSuccess,
  setSnackbar, fetchLanguages, saveUserDetails, updateUserDetailsStore, toggleAlertBar,
} from '../../store/actions/PlatformLevelUsers/userDetail';
import UserDetail from '../../components/PlatformLevelUsers/UserDetail';

const mapStateToProps = (state) => ({
  userDetails: state.platformUserDetailsData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getUserDetails,
    clearAlertBar,
    setUserDetailsSuccess,
    setSnackbar,
    fetchLanguages,
    saveUserDetails,
    updateUserDetailsStore,
    toggleAlertBar,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
