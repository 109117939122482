import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import { COMPLETED_WITH_ERRORS } from '../../constants';

const useStyles = makeStyles(() => ({
  contentListWrapper: {
    width: '100%',
  },
  loCountWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 0',

    '& > span': {
      marginLeft: '1rem',
    },
  },
}));

const ReportContent = ({
  fail_count, success_count, total_rows, status, updatedValue, updateType,
}) => {
  const classes = useStyles();
  return (
    [COMPLETED_WITH_ERRORS].includes(status) ? (
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
          <Box className={classes.contentListWrapper}>
            <Container>
              <Box className={classes.loCountWrapper}>
                <img src={CheckRoundIcon} alt="check-round-green" />
                <Typography variant="subtitle2" component="span">
                  {`${success_count}/${total_rows} ${updatedValue} successfully ${updateType}`}
                </Typography>
              </Box>
              <Divider component="li" />
              <Box className={classes.loCountWrapper}>
                <img src={ErrorRoundIcon} alt="Error-round-red" />
                <Typography variant="subtitle2" component="span">
                  {`${fail_count}/${total_rows} ${updatedValue} not ${updateType} successfully`}
                </Typography>
              </Box>
            </Container>
          </Box>
        </ListItem>
      </List>
    ) : null
  );
};

export default ReportContent;

ReportContent.defaultProps = {
  updatedValue: 'rows',
  updateType: 'updated',
};

ReportContent.propTypes = {
  fail_count: PropTypes.number.isRequired,
  success_count: PropTypes.number.isRequired,
  total_rows: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  updatedValue: PropTypes.string,
  updateType: PropTypes.string,
};
