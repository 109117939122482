import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import FormattedTypography from '../FormattedTypography';

const ExcelRowsLimitWarning = ({ moduleName, rowDenotion, rowsLimit }) => (
  <>
    <List component="nav" aria-label="main" dense>
      <ListItem>
        <ListItemText
          disableTypography
          primary={<FormattedTypography prefix={`${moduleName} with less than ${rowsLimit} ${rowDenotion} in unique excel file have a higher success rate`} dense variant="subtitle2" justifyContent="center" />}
        />
      </ListItem>
    </List>
  </>
);

ExcelRowsLimitWarning.propTypes = {
  moduleName: PropTypes.string.isRequired,
  rowsLimit: PropTypes.number.isRequired,
  rowDenotion: PropTypes.string.isRequired,
};

export default ExcelRowsLimitWarning;
