import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { ROUTE_REPORT, OVERVIEW } from '../../constants';

import { MCKINSEY_BLUE, MODERATE_DARK_GREY } from '../../stylesheets/colors';

import StageBadge from '../StageBadge/StageBadge';

import DoceboLogo from '../../assets/img/docebo-logo.png';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    flex: 1,
    userSelect: 'none',
    '-webkit-user-select': 'none',
    '-moz-select': 'none',
    '-ms-select': 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '13.125rem',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    marginTop: '2rem',
    marginBottom: '2rem',
    overflow: 'hidden',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    paddingTop: '0px',
    flex: 1,
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '1.75rem',
  },
  doceboLogo: {
    width: '5.625rem',
    visibility: ({ docebo }) => (docebo ? 'visible' : 'hidden'),
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.62,
    color: MODERATE_DARK_GREY,
  },
  updatedByWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '0.875rem',
    minHeight: '1.25rem',
    color: MCKINSEY_BLUE,
  },
  configIdWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    userSelect: 'text',
    '-webkit-user-select': 'text',
    '-moz-select': 'text',
    '-ms-select': 'text',
  },
  statusWrapper: {
    flex: 1,
    display: 'flex',
    minHeight: '1.5rem',
    fontSize: '1rem',
  },
  bottomWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.38,
  },
  bottomLeftWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
  },
  stageWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  bottomRightWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    backgroundColor: 'transparent',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  gotoDetailsOuterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  goToDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.84,
    justifyContent: 'flex-end',
    fontWeight: 'bold',
  },
  arrowIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  reportButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  arrowIcon: {
    color: MCKINSEY_BLUE,
    height: '1rem',
    width: '1rem',
  },
}));

const LandingPageCard = ({
  updatedBy,
  configId,
  status,
  stage,
  docebo,
  programType,
  programId,
  currentSearchText,
  isAcademyGo,
}) => {
  const classes = useStyles({ docebo });

  const updatedByText = `${updatedBy}`.length > 2 ? `Updated by ${updatedBy}` : '';

  return (
    <Link
      className={classes.link}
      color="inherit"
      component={RouterLink}
      to={{ pathname: `/${ROUTE_REPORT}/${programId}/${programType}/${OVERVIEW}`, state: { currentSearchText } }}
    >
      <Paper className={classes.wrapper}>
        <div className={classes.logoWrapper}>
          <img src={DoceboLogo} alt="Docebo" className={classes.doceboLogo} />
        </div>
        <div className={classes.innerWrapper}>
          <div className={classes.titleWrapper}>
            <Typography className={classes.updatedByWrapper}>{updatedByText}</Typography>
            <Typography className={classes.configIdWrapper}>{configId}</Typography>
            <Typography className={classes.statusWrapper}>{status}</Typography>
          </div>

          <div className={classes.bottomWrapper}>
            <div className={classes.bottomLeftWrapper}>
              <div className={classes.stageWrapper}>
                <StageBadge stage={stage} />
                {isAcademyGo && (
                  <Box style={{ paddingLeft: '1rem' }}>
                    <StageBadge stage="ACADEMY_GO" />
                  </Box>
                )}
              </div>
            </div>

            <div className={classes.bottomRightWrapper}>
              <div className={classes.gotoDetailsOuterWrapper}>
                <Typography className={classes.goToDetailsWrapper}>Go to the details</Typography>

                <div className={classes.arrowIconWrapper}>
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Link>
  );
};

LandingPageCard.propTypes = {
  updatedBy: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
  docebo: PropTypes.bool.isRequired,
  programId: PropTypes.number.isRequired,
  programType: PropTypes.string.isRequired,
  currentSearchText: PropTypes.string,
  isAcademyGo: PropTypes.bool.isRequired,
};

LandingPageCard.defaultProps = {
  currentSearchText: '',
};

export default LandingPageCard;
