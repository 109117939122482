import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TimelineStep from '../../common/TimelineStep';
import EmptyTimeline from '../../common/EmptyTimeline';
import ComponentLogs from './ComponentLogs';
import { zeroAppender } from '../../../helpers/formattingHelpers';
import { SUCCESS_GREEN, ERROR_RED } from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '1rem',
  },
  messageWrapper: {
    padding: '1rem 0',
  },
  accordianDetails: {
    maxHeight: '40vh',
    overflowX: 'auto',
  },
  success: {
    color: SUCCESS_GREEN,
  },
  error: {
    color: ERROR_RED,
  },
});

const MetaDataLogs = ({ report, onDownloadLog }) => {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const classes = useStyles();

  const {
    enrollments, users,
  } = report;

  return (
    <Box className={classes.wrapper}>
      {Object.keys(report).length ? (
        <>
          <Accordion disableGutters expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Users</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianDetails} data-testid="metadata-users-activity">
              {users?.length > 0 && users?.map((data) => (
                <TimelineStep
                  key={data?.transaction_id}
                  data={{
                    ...data,
                    completed: data?.done,
                  }}
                  hideView
                  isCustomMsg
                  downloadLog={data?.failed > 0 ? () => onDownloadLog(data) : undefined}
                >
                  {data?.successed > 0
                    && (
                      <Typography variant="body1" gutterBottom className={classes.success}>
                        {`Users updated: < ${zeroAppender(data.successed)} Users updated successfully>`}
                      </Typography>
                    )}
                  {data?.failed > 0
                    && (
                      <Typography variant="body1" gutterBottom className={classes.error}>
                        {`Users not updated: < ${zeroAppender(data.failed)} Users not updated>`}
                      </Typography>
                    )}
                </TimelineStep>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Courses & ILT</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianDetails} data-testid="metadata-component-activity">
              <ComponentLogs components={report?.components} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            style={{ visibility: 'hidden' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Enrollment</Typography>
            </AccordionSummary>
            <AccordionDetails
              className={classes.accordianDetails}
              data-testid="metadata-enrollment-activity"
            >
              {enrollments.length > 0 && enrollments.map((data) => (
                <TimelineStep
                  key={data?.transaction_id}
                  data={{
                    ...data,
                    completed: data?.done,
                  }}
                  hideView
                  successMsg={`The role of the user
                   ${data?.entity_secondary_id} was changed to ${data?.value}`}
                  errorMsg="Process could not be completed"
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <EmptyTimeline message="No action log..." />
      )}
    </Box>
  );
};

MetaDataLogs.defaultProps = {
  report: {
    components: [],
    enrollments: [],
    users: [],
  },
};

MetaDataLogs.propTypes = {
  report: PropTypes.shape({
    components: PropTypes.array,
    users: PropTypes.array,
    enrollments: PropTypes.array,
  }),
  onDownloadLog: PropTypes.func.isRequired,
};

export default MetaDataLogs;
