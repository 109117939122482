import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import { zeroAppender } from '../../../helpers/formattingHelpers';

const useStyles = makeStyles(() => ({
  iconWrapper: {
    height: '2rem',
    width: '4rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgressPadding: {
    padding: '1rem',
  },
  listPadding: {
    padding: '1rem 0',
    width: '70%',
    margin: '0 auto',
  },
}));

const MetadataUsersOverview = ({
  successCount, errorCount,
}) => {
  const classes = useStyles();
  return (
    <List aria-label="main" dense classes={{ padding: classes.listPadding }}>
      {successCount > 0 && (
        <ListItem>
          <ListItemIcon className={classes.iconWrapper}>
            <img
              src={CheckRoundIcon}
              alt="check-round-green"
              className={classes.circularProgressPadding}
            />
          </ListItemIcon>
          <ListItemText id="switch-list-label-bluetooth" primary={`${zeroAppender(successCount)} rows were successfully updated`} />
        </ListItem>
      )}
      {
        errorCount > 0 && (
          <ListItem>
            <ListItemIcon className={classes.iconWrapper}>
              <img
                src={ErrorRoundIcon}
                alt="error-round"
                className={classes.circularProgressPadding}
              />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-bluetooth"
              primary={`${zeroAppender(errorCount)} rows had errors`}
              secondary="Please download the log file for details"
            />
          </ListItem>
        )
      }
    </List>
  );
};

export default MetadataUsersOverview;

MetadataUsersOverview.defaultProps = {
  successCount: 0,
  errorCount: 0,
};

MetadataUsersOverview.propTypes = {
  successCount: PropTypes.number,
  errorCount: PropTypes.number,
};
