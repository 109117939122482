import Dropdown from '../ds-react-components/Dropdown';

class CustomDropdown extends Dropdown {
  constructor(props) {
    super(props);
    this.handleOuterClick = () => null; // Disable
  }
}

export default CustomDropdown;
