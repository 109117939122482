import React from 'react';
import PropTypes from 'prop-types';

import UserCircle from '../ds-react-components/UserCircle';
import { formatUserCircle } from '../../helpers/userHelper';

const HeaderUserCircle = ({ user }) => (
  <UserCircle>
    {formatUserCircle(user)}
  </UserCircle>
);

HeaderUserCircle.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
};

export default HeaderUserCircle;
