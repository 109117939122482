import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { DARK_GREY, ERROR_RED } from '../../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
  },
  highlite: {
    fontWeight: 'bold',
    color: DARK_GREY,
  },
  errorHighlight: {
    fontWeight: 'bold',
    color: ERROR_RED,
  },
}));

const LoBlock = ({ lo }) => {
  const classes = useStyles();
  const errors = lo.result || [];

  return (
    <div className={classes.wrapper}>
      {lo.elucidat_project_id ? (
        <>
          <div className={errors.length !== 0 ? classes.errorHighlight : classes.highlite}>
            {`Elucidat project ID: ${lo.elucidat_project_id} ${
              errors.length !== 0 ? '-' : ''
            } ${errors.join(', ')}`}
          </div>
          <div className={errors.length !== 0 ? classes.errorHighlight : classes.highlite}>
            {`Replication type: ${lo.replication_type}`}
          </div>
          <div>{`Release ID: ${lo.release_id}`}</div>
        </>
      ) : (
        <div>No scorm object found for this course</div>
      )}
    </div>
  );
};

LoBlock.propTypes = {
  lo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    elucidat_project_id: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.string.isRequired,
    ]),
    replication_type: PropTypes.string.isRequired,
    release_id: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string.isRequired]),
    result: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.array.isRequired]),
  }).isRequired,
};

export default LoBlock;
