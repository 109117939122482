import { MCKINSEY_BLUE, MODERATE_DARK_GREY } from '../../../stylesheets/colors';

const commonGWStyles = {
  uploadPageWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  innerWrapper: {
    padding: '0 2rem',
    marginTop: '1.25rem',
  },
  uploadSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  border: {
    marginTop: '1.2rem',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
  modalHeight: {
    minHeight: '400px',
  },
  pageHeader: {
    padding: '1rem 1.25rem 1.25rem 2rem',
  },
  breadCrumbWrapper: {
    margin: '2rem 0rem 0rem 2rem',
  },
  mainWrapper: {
    flex: 1,
  },
  downloadGWDataBtn: {
    color: MODERATE_DARK_GREY,
    fontWeight: 600,
  },
  downloadTempBtn: {
    marginTop: '1.2rem',
  },
};

export default commonGWStyles;
