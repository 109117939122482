import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import {
  BANNER_FOREGROUND, DARK_YELLOW, ERROR_ORANGE1, LIGHT_PURPLE, LIGHT_YELLOW, RED_DARK,
} from '../../stylesheets/colors';
import WarningIcon from '../../assets/icons/warningIcon.svg';
import InfoIcon from '../../assets/icons/icons-alert-info.svg';
import ErrorIcon from '../../assets/icons/error-round.svg';
import MCKINSEY_SANS from '../../stylesheets/fonts';

const commonStyles = {
  '& .MuiAlert-message': {
    fontSize: '1.125rem',
    fontFamily: MCKINSEY_SANS,
  },
};

const useStyles = makeStyles((customStyles) => ({
  warningAlert: {
    '& .MuiAlert-root': {
      color: DARK_YELLOW,
      backgroundColor: LIGHT_YELLOW,
      alignItems: 'center',
      borderRadius: '0',
      padding: '2px 6px',
    },
    ...commonStyles,
    ...customStyles,
  },
  infoAlert: {
    '& .MuiAlert-root': {
      color: BANNER_FOREGROUND,
      backgroundColor: LIGHT_PURPLE,
      alignItems: 'center',
      borderRadius: '0',
      padding: '2px 6px',
    },
    ...commonStyles,
    ...customStyles,
  },
  errorAlert: {
    '& .MuiAlert-root': {
      color: RED_DARK,
      backgroundColor: ERROR_ORANGE1,
      alignItems: 'center',
      borderRadius: '0',
      padding: '2px 6px',
    },
    ...commonStyles,
    ...customStyles,
  },
}
));

const WarningInfoAlert = ({
  children, customStyles, severity,
}) => {
  const classes = useStyles({ customStyles });

  const getSeverityProps = () => {
    let icon;
    let styles;
    if (severity === 'warning') {
      icon = WarningIcon;
      styles = classes.warningAlert;
    } else if (severity === 'error') {
      icon = ErrorIcon;
      styles = classes.errorAlert;
    } else {
      icon = InfoIcon;
      styles = classes.infoAlert;
    }
    return {
      icon, styles,
    };
  };

  const { icon, styles } = getSeverityProps();

  return (
    <Box className={`${styles} ${customStyles}`} data-testid="alert">
      <Alert icon={<img src={icon} alt="alertIcon" />} severity={severity}>
        {children}
      </Alert>
    </Box>
  );
};

WarningInfoAlert.defaultProps = {
  customStyles: {},
};

WarningInfoAlert.propTypes = {
  customStyles: PropTypes.object,
  children: PropTypes.node.isRequired,
  severity: PropTypes.string.isRequired,

};

export default WarningInfoAlert;
