import config from '../../../config';
import request from '../../../helpers/apiHelper';

export const fetchClientData = async (requestParam) => {
  const url = `${config.ROUTES.MASTER_DATA_CLIENT}?page=${requestParam.pageNum}&page_size=${requestParam.pageSize}&search=${requestParam.searchValue}`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);
  return result;
};

export const updateClientCode = async (data) => {
  const url = `${config.ROUTES.MASTER_DATA_CLIENT}`;
  const method = 'PUT';
  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const addClient = async (data) => {
  const url = `${config.ROUTES.MASTER_DATA_CLIENT}`;
  const method = 'POST';
  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const fetchPortfolioData = async (requestParam) => {
  const url = `${config.ROUTES.MASTER_DATA_PORTFOLIO}?page=${requestParam.pageNum}&page_size=${requestParam.pageSize}&search=${requestParam.searchValue}`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);
  return result;
};

export const fetchPortfolioAndOffering = async (requestParam) => {
  let url = `${config.ROUTES.MASTER_DATA_PORTFOLIO}/offerings`;
  if (requestParam) {
    url += `?portfolio=${requestParam.portfolio}`;
  }
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);
  return result;
};

export const addNewPortfolio = async (data) => {
  const url = `${config.ROUTES.MASTER_DATA_PORTFOLIO}`;
  const method = 'POST';
  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const updatePortfolio = async (payload) => {
  const url = `${config.ROUTES.MASTER_DATA_PORTFOLIO}/${payload.id}`;
  const method = 'PUT';
  const data = {
    portfolio: payload.portfolio,
    offering: payload.offering,
    program: payload.program,
    program_code: payload.program_code,
    notes: payload.notes,
  };
  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const deletePortfolio = async (id) => {
  const url = `${config.ROUTES.MASTER_DATA_PORTFOLIO}/${id}`;
  const method = 'DELETE';
  const options = { url, method };
  const result = await request(options);
  return result;
};
