import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './ReportFooterBanner.styles';

const ReportFooterBanner = ({ doceboUrl }) => {
  const classes = useStyles();
  return (
    <div className={classes.bannerBox}>
      <span className={classes.infoIcon}>i</span>
      <span className={classes.content}>
        Program shell is set for you in Docebo!
        <br />
        {'Add course content,edit learning plans or set enrollment rules there if you\'d like '}
        <a href={doceboUrl} target="_blank" rel="noopener noreferrer" className={classes.bannerUrl}>{doceboUrl}</a>
      </span>
    </div>
  );
};

ReportFooterBanner.propTypes = {
  doceboUrl: PropTypes.string.isRequired,
};

export default ReportFooterBanner;
