import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@material-ui/core';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import NoMedia from '../../assets/img/temp_logo.png';
import LockIcon from '../../assets/icons/lock-shape.svg';

const useStyles = makeStyles(() => ({
  fixedWidthHeight: {
    height: '6rem',
    width: '6rem',
  },
  lockedIconPadding: {
    padding: '0 0 0 1rem',
  },
}));

const WorkshopManagementIltModal = ({ open, onClose, data }) => {
  const classes = useStyles();
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      breakpoint="sm"
      title={(
        <FormattedTypography
          body={data.name}
          subHeading="View the details for this Workshop"
          dense
        />
      )}
      content={(
        <Stack spacing={4}>
          <ListItemText primary={<Typography variant="subtitle1" component="span">Workshop Name</Typography>} secondary={<Typography variant="subtitle1" component="span">{data?.name}</Typography>} />
          <ListItemText primary={<Typography variant="subtitle1" component="span">Thumbnail</Typography>} secondary={<img alt="workshop-thumbnail" className={classes.fixedWidthHeight} src={data.thumbnail ? data.thumbnail : NoMedia} />} />
          {data?.course_code && (
            <ListItemText
              primary={<Typography variant="subtitle1" component="span">Workshop Code</Typography>}
              secondary={(
                <Typography variant="subtitle1" component="span">
                  {data?.course_code || ''}
                  {' '}
                  <img src={LockIcon} alt="lock-icon" className={classes.lockedIconPadding} />
                </Typography>
              )}
            />
          )}
          <ListItemText primary={<Typography variant="subtitle1" component="span">Workshop Description</Typography>} secondary={<Typography variant="subtitle1" component="span">{data?.description || ''}</Typography>} />
        </Stack>
      )}
      actions={(
        <Button className={classes.button} color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      )}
    />
  );
};

export default WorkshopManagementIltModal;

WorkshopManagementIltModal.propTypes = {
  data: PropTypes.shape({
    idCourse: PropTypes.number.isRequired,
    all_sessions: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    course_code: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
