import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  MODERATE_LIGHT_GREY, DARK_MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  listWrapper: {
    background: MODERATE_LIGHT_GREY,
  },
  footerText: {
    color: DARK_MEDIUM_GREY,
    marginTop: '2rem',
    padding: 0,
  },
  bodyText: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
  },
  normalText: {
    fontSize: '1.125rem',
  },
});

const CheckboxRow = ({ value }) => (
  <FormControlLabel
    value={value}
    control={<Checkbox color="primary" />}
    label="Ignore errors and proceed with upload"
  />
);

CheckboxRow.propTypes = {
  value: PropTypes.string.isRequired,
};

const WorkshopUploadProgress = ({
  username, createDateTime, courseName,
}) => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.bodyText}>
        <Typography className={classes.normalText} variant="body2">
          for
          {' '}
          {courseName}
        </Typography>
      </Container>
      <Container className={classes.footerText}>
        <Typography variant="body1" align="center">
          Activity initiated by
          {' '}
          {username}
          {' '}
          on
          {' '}
          {createDateTime}
          {' '}
          is in process.
        </Typography>
        <Typography variant="body1" align="center">Please hold on a bit, we are working on it...</Typography>
      </Container>
    </>
  );
};

WorkshopUploadProgress.propTypes = {
  username: PropTypes.string.isRequired,
  createDateTime: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
};

export default memo(WorkshopUploadProgress, (() => true));
