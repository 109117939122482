const accordianStyles = {
  accordion: {
    '&.accordionRoot': {
      marginBottom: '1rem',
      '&$expanded': {
        marginTop: 0,
        marginBottom: '1rem',
      },
    },
  },
  accordionSummary: {
    '&.accordionSummaryRoot': {
      '&$accordionSummaryExpanded': {},
      '& > $accordionSummaryContent': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 0,
        alignItems: 'center',
        '&$accordionSummaryExpanded': {
          '& svg': {
            transform: 'rotate(180deg)',
            transition: 'transform 150ms cubic-bezier(0.4,0,0.2,1) 0ms',
          },
        },
      },
    },
  },
  detail: {
    '&.accordionSummaryDetail': {
      padding: 'unset',
    },
  },
  accordionSummaryContent: {},
  accordionSummaryExpanded: {},
  expanded: {},
};

export default accordianStyles;
