import React, {
  useEffect, useState, memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import EditProgramModal from './EditProgramModal';
import EditProgramRetryWarningModal from './EditProgramRetryWarningModal';

import {
  ERROR_RED, MODERATE_LIGHT_GREY, SUCCESS_GREEN, DARK_MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  tableHead: {
    backgroundColor: MODERATE_LIGHT_GREY,
  },
  expandArrow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  successText: {
    color: SUCCESS_GREEN,
  },
  failureText: {
    color: ERROR_RED,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleWrapper: {
    color: DARK_MEDIUM_GREY,
    fontWeight: 'bold',
  },
  headerButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    marginLeft: '1rem',
    maxHeight: '3rem',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  },
});

const keyToHeading = {
  learning_plan: {
    heading: 'Learning Plan codes',
    accordion: false,
  },
  course: {
    heading: 'Course Details',
    accordion: true,
  },
  group: {
    heading: 'Cohort names and rules',
    accordion: false,
  },
  channel: {
    heading: 'Channel name',
    accordion: false,
  },
  enrollment_rule: {
    heading: 'Enrollment Rule',
    accordion: false,
  },
  groupwork: {
    heading: 'Groupworks',
    accordion: false,
  },
  catalogue: {
    heading: 'Catalogues',
    accordion: false,
  },
  enrolled_user: {
    heading: 'Already enrolled users',
    accordion: false,
  },
};

const EditProgramSummaryAccordionRow = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const isAllCourseSuccess = data.data.every((course) => course.success);
  return (
    <>
      <TableRow>
        <TableCell><Typography variant="body1">{data.heading}</Typography></TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell className={classes.expandArrow}>
          <Typography variant="body1" className={isAllCourseSuccess ? classes.successText : classes.failureText}>{isAllCourseSuccess ? 'Completed' : 'Not Created; API failure'}</Typography>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen((op) => !op)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {open && data.data.map((course) => (
        <TableRow className={classes.tableRowAccordion} key={`${course.component}-${course.new_name}`}>
          <TableCell />
          <TableCell><Typography variant="body1">{course.old_name}</Typography></TableCell>
          <TableCell><Typography varinat="body1" className={course.success ? classes.successText : classes.failureText}>{course.success ? course.new_name : '-'}</Typography></TableCell>
          <TableCell><Typography variant="body1" className={course.success ? classes.successText : classes.failureText}>{course.success ? 'Completed' : 'Not Created'}</Typography></TableCell>
          {/* <TableCell /> */}
        </TableRow>
      ))}
    </>
  );
};

EditProgramSummaryAccordionRow.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      component: PropTypes.string.isRequired,
      old_name: PropTypes.string.isRequired,
      new_name: PropTypes.string.isRequired,
      success: PropTypes.bool.isRequired,
    })).isRequired,
  }).isRequired,
};

const summaryTableColumnNames = ['Details', 'Old ID/ name', 'New ID/ name', 'Status'];

const EditProgramSummary = ({
  report, onProceedWithErrors, onProceed, openProgressModal,
  showProgress, progress, setOpenProgressModal, className,
}) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState(report);
  const [retryCounter, setRetryCounter] = useState(1);
  const [openRetryWarningModal, setOpenRetryWarningModal] = useState(false);

  useEffect(() => {
    setReportData(report);
  }, [report]);

  const reportMap = useMemo(() => report.reduce((ini, v) => ({
    ...ini,
    [v.component]: {
      ...keyToHeading[v.component],
      data: [..._get(ini[v.component], 'data', []), v],
    },
  }), {}), [report]);

  const allSuccess = useMemo(() => reportData.every((v) => v.success), [reportData]);

  const summaryTableHeaders = summaryTableColumnNames
    .map((columnName) => <TableCell key={columnName}>{columnName}</TableCell>);

  const tableRows = Object.entries(reportMap).map(([key, data]) => (
    <>
      {!data.accordion
        ? (
          <TableRow key={key}>
            <TableCell><Typography varinat="body1">{data.heading}</Typography></TableCell>
            <TableCell><Typography varinat="body1">{data.data[0].old_name}</Typography></TableCell>
            <TableCell><Typography varinat="body1" className={data.data[0].success ? classes.successText : ''}>{data.data[0].success ? data.data[0].new_name : '-'}</Typography></TableCell>
            <TableCell className={data.data[0].success ? classes.successText : classes.failureText}><Typography varinat="body1">{data.data[0].success ? 'Completed' : 'Not Created; API failure'}</Typography></TableCell>
            {/* <TableCell>{data.data[0].success ? '-' : <a href="/">Retry</a>}</TableCell> */}
          </TableRow>
        )
        : <EditProgramSummaryAccordionRow key={key} data={data} />}
    </>
  ));

  return (
    <div className={`${classes.bodyWrapper} ${className}`}>
      <div className={classes.headerWrapper}>
        <Typography className={classes.titleWrapper}>Result</Typography>
        {!allSuccess && (
        <div className={classes.headerButtonsWrapper}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={onProceedWithErrors}
            color="primary"
          >
            Proceed with Errors
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => ((retryCounter % 5)
              ? setOpenProgressModal(true)
              : setOpenRetryWarningModal(true))}
          >
            Retry
          </Button>
        </div>
        )}
      </div>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {summaryTableHeaders}
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </TableContainer>
      <EditProgramModal
        open={openProgressModal}
        showProgress={showProgress}
        progress={progress}
        reportData={reportData}
        onProceed={() => {
          setRetryCounter((val) => val + 1);
          onProceed(true);
        }}
        setOpenProgressModal={setOpenProgressModal}
      />
      <EditProgramRetryWarningModal
        open={openRetryWarningModal}
        onClose={() => setOpenRetryWarningModal(false)}
      />
    </div>
  );
};

EditProgramSummary.defaultProps = {
  className: '',
};

EditProgramSummary.propTypes = {
  onProceedWithErrors: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  className: PropTypes.string,
  report: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.string.isRequired,
    old_name: PropTypes.string.isRequired,
    new_name: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
  })).isRequired,
  setOpenProgressModal: PropTypes.func.isRequired,
  showProgress: PropTypes.bool.isRequired,
  openProgressModal: PropTypes.bool.isRequired,
  progress: PropTypes.shape({
    percentage: PropTypes.number.isRequired,
  }).isRequired,
};

export default memo(EditProgramSummary,
  (prevProps, nextProps) => _isEqual(prevProps.report, nextProps.report)
  && prevProps.className === nextProps.className && prevProps.onProceed === nextProps.onProceed
  && prevProps.onProceedWithErrors === nextProps.onProceedWithErrors
  && prevProps.setOpenProgressModal === nextProps.setOpenProgressModal
  && prevProps.showProgress === nextProps.showProgress
  && prevProps.openProgressModal === nextProps.openProgressModal
  && _isEqual(prevProps.progress, nextProps.progress));
