const tooltipMessages = {
  CONFIG_ID: 'Learning plan Configuration ID',
  DOCEBO_LPID: 'Unique Learning ID generated by Docebo',
  LINEAGE: 'How was the Learning plan created',
  STATUS: 'Current phase of the Learning plan lifecycle',
  DD_ANALYST: 'Digital Delivery SPOC for the current Learning plan',
  COURSE_DELIVERY_ANALYST: 'Course Delivery SPOC for the current Learning plan',
  CP_POC: 'Course Production SPOC for the current Learning plan',
  EXPERIENCE: 'Design of Landing page',
  LANGUAGE: 'Learning plan language',
  CLIENT: 'Client for whom the Learning plan was created',
  DOCEBO_LEARNING_PLAN_URL: 'Participant side Learning plan URL',
  PLATFORM_URL: 'Domain specific URL',
  ADDITIONAL_FIELDS: 'Field to add additional information such as links, text etc.',
  TAGS: 'Keywords to associate Learning plan which can be used for searching',
};

export default tooltipMessages;
