import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';
import DownloadSuccessBanner from '../AssignmentManagement/DownloadSuccessBanner';
import LoadingCircle from './LoadingCircle/LoadingCircle';

const useStyles = makeStyles(() => ({
  modalHeader: {
    margin: '0 auto',
    width: ({ modalWidth }) => `${modalWidth}%`,
    maxHeight: '50vh',
    overflow: 'auto',
  },
  divider: {
    width: '100%',
    marginBottom: ({ hasContentHeader }) => (hasContentHeader ? '0rem' : '2rem'),
  },
  form: {
    width: '100%',
  },
  centerAction: {
    justifyContent: 'center',
  },
  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ConfirmationModal = ({
  title, content, actions, open, onClose, dialogContentClass,
  minHeightClass, breakpoint, isContainForm, onSubmit, isLoading,
  modalWidth, contentHeader, alertMessage, setAlertMessage, modalStyle, loadingText,
}) => {
  const classes = useStyles({ modalWidth, hasContentHeader: !!contentHeader });
  const getElement = () => (
    <>
      {title && (
        <DialogTitle disableTypography className={classes.modalHeader}>
          {title}
        </DialogTitle>
      )}
      <Divider variant="fullWidth" className={classes.divider} light />
      {contentHeader}
      {content && (
        <DialogContent className={dialogContentClass}>
          {content}
        </DialogContent>
      )}
      <Divider variant="fullWidth" className={classes.divider} light />
      {
        actions && (
          <DialogActions className={classes.centerAction}>
            {actions}
          </DialogActions>
        )
      }
      {alertMessage.message && (
        <DownloadSuccessBanner
          type={alertMessage.type}
          text={alertMessage.message}
          onClose={() => {
            setAlertMessage({ ...alertMessage, message: '' });
          }}
        />
      )}
    </>
  );

  const modalContent = () => (
    <>
      {isContainForm ? (
        <form className={classes.form} onSubmit={onSubmit}>
          {getElement()}
        </form>
      ) : getElement()}
    </>
  );

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      className={minHeightClass}
      breakpoint={breakpoint}
      modalStyle={modalStyle}
    >
      {isLoading ? <LoadingCircle labelText={loadingText} /> : modalContent()}
    </CustomModal>
  );
};

export default ConfirmationModal;

ConfirmationModal.defaultProps = {
  title: null,
  content: null,
  actions: null,
  dialogContentClass: '',
  minHeightClass: '',
  breakpoint: 'sm',
  isContainForm: false,
  onSubmit: undefined,
  isLoading: false,
  modalWidth: 80,
  contentHeader: null,
  alertMessage: {
    type: '',
    message: '',
  },
  setAlertMessage: () => { },
  modalStyle: '',
  loadingText: '',
};

ConfirmationModal.propTypes = {
  title: PropTypes.element,
  content: PropTypes.element,
  actions: PropTypes.element,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogContentClass: PropTypes.string,
  minHeightClass: PropTypes.string,
  breakpoint: PropTypes.string,
  isContainForm: PropTypes.bool,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  modalWidth: PropTypes.number,
  contentHeader: PropTypes.element,
  alertMessage: PropTypes.object,
  setAlertMessage: PropTypes.func,
  modalStyle: PropTypes.string,
  loadingText: PropTypes.string,
};
