import React, { } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  DARK_MEDIUM_GREY,
} from '../../../stylesheets/colors';
import { reportPageDateTimeFormatter } from '../../../helpers/formattingHelpers';

const useStyles = makeStyles({
  footerText: {
    color: DARK_MEDIUM_GREY,
    marginTop: '2rem',
    padding: 0,
    textAlign: 'center',
  },
});

const ActivityInitiatedInstructions = ({ created_by, created_time }) => {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.footerText}>
        <Typography variant="body1">
          Activity initiated by
          {' '}
          {created_by}
          {' '}
          on
          {' '}
          {reportPageDateTimeFormatter(new Date(created_time))}
          {' '}
          is in process.
        </Typography>
        <Typography variant="body1">Please hold on a bit, we are working on it…</Typography>
      </Container>
    </>
  );
};

ActivityInitiatedInstructions.propTypes = {
  created_by: PropTypes.string.isRequired,
  created_time: PropTypes.string.isRequired,
};

export default ActivityInitiatedInstructions;
