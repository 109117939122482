import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableCell, TableRow, IconButton,
} from '@mui/material';
import { Button } from '@material-ui/core';
import {
  Formik, Form, FieldArray, getIn,
} from 'formik';
import _find from 'lodash/find';
import * as yup from 'yup';
import { ContentDetailsLabel } from '../ContentManagement/ContentDetailsSection';
import TableRC from '../common/TableRC';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import Select from '../common/SelectMui';
import LabledTextField from '../common/LabledTextField';
import DeleteIcon from '../../assets/icons/trash.svg';
import DeleteConfirmationSocrm from './DeleteConfirmationSocrm';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';
import Error from '../common/Error';

const NonElucidatDetails = ({
  formValues, customLabel, isLoading, isEdit, formikRef,
}) => {
  const {
    scorm_type_option,
    course_data,
    scored_assessment,
    non_elucidat_data,
  } = formValues?.nonElucidatDetails || {};

  const initialValues = useMemo(() => ({
    data: non_elucidat_data?.length > 0 ? [...non_elucidat_data] : [],
  }), [non_elucidat_data]);

  const [openConfirmation, setConfirmatin] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setRowCount(initialValues?.data?.length);
  }, [initialValues]);

  const ScormSelect = ({
    name, options, value, handleChange, hasError, errorText,
  }) => (
    <>
      {
        isEdit ? (
          <>
            <Select
              name={name}
              defaultValue={options.find((el) => el.value === value)}
              options={options}
              placeholder="Select..."
              isOptionsNotLoading
              onChange={(v) => {
                handleChange(name, v?.value, true, v);
              }}
              isError={hasError}
            />
            {hasError && <Error errorText={errorText} />}
          </>
        ) : (
          <ContentDetailsLabel
            label={options?.length > -1 ? options?.find((el) => el.value === value)?.label : 'N/A'}
          />
        )
      }
    </>
  );

  ScormSelect.defaultProps = {
    errorText: '',
    hasError: false,
  };

  ScormSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    errorText: PropTypes.string,
    hasError: PropTypes.bool,
  };

  const ScormInput = ({
    name, value, handleChange,
  }) => (
    <>
      {
        isEdit ? (
          <LabledTextField
            name={name}
            value={value}
            onChange={handleChange}
          />
        ) : (
          <a target="_blank" rel="noopener noreferrer" href={value} style={{ fontSize: '1.25rem', color: MCKINSEY_BLUE }}>{value}</a>
        )
      }
    </>
  );

  ScormInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
  };

  return (
    <>
      <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
        <ContentDetailsLabel
          label="Non Elucidat Details"
          subHeading="Values entered for SCORM Link and Box Link will be updated for respective SCORM"
          subheadingVariant="body1"
          labelCustomCss={customLabel}
          subHeadingRight={`Total SCORM Files - ${rowCount}`}
        />
      </Grid>
      <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          enableReinitialize
          validateOnChange
          validationSchema={yup.object().shape({
            data: yup.array()
              .of(yup.object().shape({
                type: yup.string().required('This is a required field'),
                component_id: yup.string().required('This is a required field'),
                tm_id: yup.string().required('This is a required field'),
              })),
          })}
        >
          {({
            values, errors, setFieldValue, handleChange,
          }) => (
            <Form>
              <FieldArray name="data">
                {({ remove, push }) => (
                  <>
                    {isLoading ? (<LoadingCircle labelText="Loading Non Elucidat Details" />) : (
                      <>
                        <TableRC
                          columns={[
                            { title: 'SCORM Type', property: 'sc_type', width: '10%' },
                            { title: 'Component Title', property: 'component_title', width: '15%' },
                            { title: 'SCORM URL', property: 'sc_url', width: '20%' },
                            { title: 'SCORM Title', property: 'sc_title', width: '20%' },
                            { title: 'Box Link', property: 'box_link', width: '20%' },
                            { title: 'Scored Assessment', property: 'sc_assessment', width: '15%' },
                            { title: '', property: 'actions', width: '10%' },
                          ]}
                          data={[]}
                          noDataMessage="Non Elucidat SCORMS unavailable for this learning plan. Click on “Add Non Elucidat SCORM” to add SCORMS from other authoring tools."
                          customBody={values.data.length > 0 ? (
                            <>
                              {
                                values.data.map((row, index) => (
                                  <TableRow key={row?.tm_id}>
                                    <TableCell>
                                      <ScormSelect
                                        name={`data.${index}.type`}
                                        options={scorm_type_option?.length > -1
                                          && scorm_type_option?.map(
                                            (a) => ({ label: a, value: a }),
                                          )}
                                        value={row.type}
                                        handleChange={(n, v, b) => {
                                          setFieldValue(n, v, b);
                                        }}
                                        hasError={!!getIn(errors, `data[${index}].type`)}
                                        errorText={getIn(errors, `data[${index}].type`)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <ScormSelect
                                        name={`data.${index}.component_id`}
                                        options={course_data?.length > -1 ? course_data?.map(
                                          (a) => (
                                            {
                                              label: a.course_name,
                                              value: a.course_id,
                                              tms: a.tm_ids,
                                            }
                                          ),
                                        ) : []}
                                        value={row.component_id}
                                        handleChange={(n, v, b, others) => {
                                          setFieldValue(n, v, b);
                                          setFieldValue(`data.${index}.component_tms`, others?.tms, b);
                                        }}
                                        hasError={!!getIn(errors, `data[${index}].component_id`)}
                                        errorText={getIn(errors, `data[${index}].component_id`)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <ScormInput
                                        name={`data.${index}.url`}
                                        value={values.data[index].url}
                                        handleChange={handleChange}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <ScormSelect
                                        name={`data.${index}.tm_id`}
                                        options={_find(course_data,
                                          { course_id: row.component_id })?.tm_ids?.length > -1
                                          ? _find(course_data,
                                            { course_id: row.component_id }).tm_ids
                                            ?.map(
                                              (a) => (
                                                {
                                                  label: a.tm_name,
                                                  value: a.tm_id,
                                                }
                                              ),
                                            )
                                          : []}
                                        value={row.tm_id}
                                        handleChange={(n, v, b, others) => {
                                          setFieldValue(n, v, b);
                                          setFieldValue(`data.${index}.tm_name`, others?.label, b);
                                        }}
                                        hasError={!!getIn(errors, `data[${index}].tm_id`)}
                                        errorText={getIn(errors, `data[${index}].tm_id`)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <ScormInput
                                        name={`data.${index}.box_link`}
                                        value={values.data[index].box_link}
                                        handleChange={handleChange}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <ScormSelect
                                        name={`data.${index}.scored_assessment`}
                                        options={scored_assessment?.length > -1
                                          && scored_assessment?.map(
                                            (a) => ({ label: a, value: a }),
                                          )}
                                        value={row?.scored_assessment}
                                        handleChange={(n, v, b) => {
                                          setFieldValue(n, v, b);
                                        }}
                                      />
                                    </TableCell>
                                    {isEdit && (
                                      <TableCell
                                        key="delete"
                                        component="th"
                                        scope="row"
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            if (values.data[index].tm_id) {
                                              setConfirmatin(true);
                                              setConfirmData({ id: index });
                                            } else {
                                              remove(index);
                                            }
                                          }}
                                        >
                                          <img src={DeleteIcon} alt="del" />
                                        </IconButton>
                                      </TableCell>
                                    )}
                                    {openConfirmation
                                      && (
                                        <DeleteConfirmationSocrm
                                          open={openConfirmation}
                                          onClose={() => setConfirmatin(false)}
                                          onDelete={() => {
                                            remove(confirmData?.id);
                                            setConfirmatin(false);
                                            setRowCount(values.data.length - 1);
                                          }}
                                          headingFirst={`"Deletion would remove the details for selected ${values.data[confirmData?.id].type} SCORM"`}
                                          headingSecond={`"${values.data[confirmData?.id].tm_name}". Proceed?`}
                                        />
                                      )}
                                  </TableRow>
                                ))
                              }
                            </>
                          ) : null}
                        />
                        {
                          isEdit && (
                            <Button
                              color="primary"
                              variant="contained"
                              style={{ margin: '2rem 0' }}
                              onClick={() => {
                                push({ type: '', component_id: '', tm_id: '' });
                                setRowCount(values.data.length + 1);
                              }}
                            >
                              Add Non Elucidat SCORM
                            </Button>
                          )
                        }
                      </>
                    )}
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

NonElucidatDetails.propTypes = {
  formValues: PropTypes.object.isRequired,
  customLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  formikRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default NonElucidatDetails;
