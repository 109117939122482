import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { DARK_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  bold: {
    fontWeight: 900,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    justifyContent: ({ justifyContent }) => justifyContent,
    margin: ({ dense }) => (dense ? '1rem 0' : '0'),
  },
  subHeadingText: {
    color: DARK_MEDIUM_GREY,
  },
});

const FormattedTypography = ({
  prefix,
  body,
  suffix,
  dense,
  variant,
  justifyContent,
  subHeading,
  containerClassName,
  className,
  subHeadingSection,
  prefixClassName,
  suffixClassName,
  subHeadingAlignment,
  helperNode,
  subheadingVariant,
  subHeadingClass,
}) => {
  const classes = useStyles({ justifyContent, dense });
  return (
    <>
      <Container maxWidth={false} className={`${classes.heading} ${containerClassName}`}>
        {prefix && (
          <Typography variant={variant} component="span" className={`${prefixClassName}`}>
            {prefix}
          </Typography>
        )}
        <Typography variant={variant} component="span" className={`${classes.bold} ${className}`}>
          {body}
        </Typography>
        {suffix && (
          <Typography variant={variant} component="span" className={`${suffixClassName}`}>
            {suffix}
          </Typography>
        )}
      </Container>
      {/* For future modifications to this subheading section, create a wrapper component */}
      <Box className={subHeadingSection}>
        {subHeading && (
          <Typography
            variant={subheadingVariant}
            align={subHeadingAlignment}
            component="div"
            className={`${classes.subHeadingText} ${subHeadingClass}`}
          >
            {subHeading}
          </Typography>
        )}
        {helperNode && (
          <Typography
            variant={subheadingVariant}
            align={subHeadingAlignment}
            component="div"
            className={classes.subHeadingText}
          >
            {helperNode}
          </Typography>
        )}
      </Box>
    </>
  );
};

FormattedTypography.defaultProps = {
  prefix: '',
  suffix: '',
  dense: false,
  variant: 'subtitle1',
  subheadingVariant: 'subtitle1',
  justifyContent: 'center',
  subHeading: '' || null,
  containerClassName: '',
  className: '',
  prefixClassName: '',
  suffixClassName: '',
  subHeadingAlignment: 'center',
  subHeadingSection: '',
  helperNode: null,
  subHeadingClass: '',
};

FormattedTypography.propTypes = {
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variant: PropTypes.string,
  subheadingVariant: PropTypes.string,
  justifyContent: PropTypes.string,
  dense: PropTypes.bool,
  subHeading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  prefixClassName: PropTypes.string,
  suffixClassName: PropTypes.string,
  subHeadingAlignment: PropTypes.string,
  subHeadingSection: PropTypes.string,
  helperNode: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  subHeadingClass: PropTypes.string,
};

export default memo(FormattedTypography, (prevProps, nextProps) => (
  prevProps.prefix === nextProps.prefix && prevProps.suffix === nextProps.suffix
  && prevProps.body === nextProps.body
));
