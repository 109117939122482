import config from '../../config';
import request from '../../helpers/apiHelper';

export const getAllPrograms = async (programType, searchText, currentPage, stage, cancelToken) => {
  const url = `${config.ROUTES.PROGRAM}/${programType}?search=${encodeURIComponent(
    searchText,
  )}&page=${currentPage}&stage=${stage}`;
  const options = { url, method: 'GET', cancelToken };
  const result = await request(options);

  return result;
};

export const logoutUser = async () => {
  const url = `${config.ROUTES.LOGOUT}`;
  const options = { url, method: 'DELETE' };
  const result = await request(options);

  return result;
};

export const getEnvironment = async () => {
  const url = `${config.ROUTES.ENVIRONMENT}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result.data;
};

export const getLtiToken = async () => {
  const url = `${config.ROUTES.LTI_TOKEN}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result.data;
};
