import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@mui/material/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  BLACK,
  MEDIUM_GREY,
} from '../../stylesheets/colors';
import ConfirmationModal from '../common/ConfirmationModal';
import JournalingDeleteIcon from '../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
}));

const DeletePortfolioModal = ({
  onClose,
  onConfirmDelete,
  portfolioData,
}) => {
  const classes = useStyles();
  const { id, offering, portfolio } = portfolioData;
  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img width={100} height={100} src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="body2" component="p" className={classes.subHeading}>Delete Offering</Typography>
          <Typography variant="body1" component="p" className={`${classes.infoContentText}`}>
            You are about to delete the offering
            {' '}
            <span style={{ fontWeight: 'bold' }}>{offering}</span>
            {' '}
            from portfolio
            {' '}
            <span style={{ fontWeight: 'bold' }}>{portfolio}</span>
          </Typography>
          <Typography variant="body1" component="p" className={`${classes.infoContentText}`}>
            Please note, the offering once deleted cannot be restored back.
          </Typography>
        </Container>
            )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => { onConfirmDelete({ id }); }}>
            Delete
          </Button>
        </>
      )}
    />
  );
};

DeletePortfolioModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  portfolioData: PropTypes.object.isRequired,
};

export default DeletePortfolioModal;
