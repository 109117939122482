import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@mui/material/Divider';
import InfoIcon from '../../../assets/icons/icons-alert-info.svg';
import errorRound from '../../../assets/icons/error-round.svg';
import successRound from '../../../assets/icons/green-round-check.svg';
import noUserdata from '../../../assets/img/no-user-illustration.svg';
import HyperlinkButton from '../../common/HyperlinkButton';
import CustomRadio from '../common/CustomRadio';

import {
  DARK_MEDIUM_GREY,
  RED_DARK,
  MEDIUM_GREY,
  MCKINSEY_LIGHT_BLUE,
  MODERATE_DARK_GREY,
  LIGHT_PURPLE,
  BANNER_FOREGROUND,
  ERROR_ORANGE1,
  BLACK,
  PALE_GREY,
  MODERATE_LIGHT_GREY,
} from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '2rem 0.5rem 0',
    fontSize: '1.125rem',
    overflow: 'auto',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  subtitle: {
    color: MEDIUM_GREY,
    paddingBottom: '0.5rem',
  },
  inactiveCountClass: {
    color: RED_DARK,
    background: ERROR_ORANGE1,
    padding: '0.5rem 0.75rem',
    display: 'flex',
    fontWeight: 'bold',
  },
  warning: {
    background: LIGHT_PURPLE,
    padding: '0.5rem 0.75rem',
    color: BANNER_FOREGROUND,
    marginBottom: '1rem',
    display: 'flex',
  },
  subText: {
    padding: '0.5rem 0',
    color: MEDIUM_GREY,
  },
  sectionNote: {
    fontWeight: '500',
    color: MCKINSEY_LIGHT_BLUE,
    marginTop: '0.5rem',
  },
  body: {
    margin: '2rem 0px 1rem 0px',
  },
  subSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  subSectionTitle: {
    fontWeight: 'bold',
    paddingBottom: '0.5rem',
    color: BLACK,
  },
  saveChangesWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bestPracticeSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorSection: {
    display: 'flex',
    marginBottom: '2rem',
  },
  iconContainer: {
    paddingRight: '1rem',
  },
  errorSectionInner: {},
  errorTitle: {
    fontWeight: '500',
  },
  errorsList: {
    color: DARK_MEDIUM_GREY,
  },
  icon: {
    color: 'red',
  },
  profileLabel: {
    lineHeight: '3rem',
  },
  excelValuesNote: {
    marginTop: '0.5rem',
    fontWeight: 'bold',
  },
  preflightHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItem: {
    color: MODERATE_DARK_GREY,
  },
  userWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
  },
  userCount: {
    color: MODERATE_DARK_GREY,
    width: '12rem',
    height: '6rem',
    background: PALE_GREY,
    padding: '1rem',
    margin: '0rem 2rem',
    fontSize: '1rem',
    boxShadow: `0 0 1px 0 ${MODERATE_LIGHT_GREY}, 0 4px 8px -1px ${MODERATE_LIGHT_GREY}`,
  },
  preflightSection: {
    display: 'flex',
  },
  rightPreflight: {
    borderLeft: `1px solid ${MODERATE_LIGHT_GREY}`,
    paddingLeft: '1rem',
  },
  leftPreflight: {
    marginRight: '1rem',
    width: '49.5%',
  },
  deepDive: {
    margin: '1.5rem 0',
  },
  deepDiveInner: {
    display: 'flex',
    boxShadow: `0 0 1px 0 ${MODERATE_LIGHT_GREY}, 0 4px 8px -1px ${MODERATE_LIGHT_GREY}`,
    borderRadius: '4px',
    padding: '1rem',
  },
  deepDiveSubtitle: {
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
    fontSize: '1.25rem',
  },
  noNewUserTitle: {
    color: MODERATE_DARK_GREY,
    fontSize: '1.125rem',
    marginBottom: '2rem',
  },
  errorsSection: {
    fontSize: '1rem',
    color: MODERATE_DARK_GREY,
  },
  noNewUser: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25rem',
  },
  bestPracticeNote: {
    fontWeight: 'bold',
  },
});

const PasswordsSection = ({
  loggedIn,
  neverLoggedIn,
  shouldUseExcelDataForPasswordChange,
  setShouldUseExcelDataForPasswordChange,
  onChangeExcelDataPassword,
}) => {
  const classes = useStyles();

  const VALUE_BEST_PRACTICE = 'append';
  const VALUE_XLSX = 'not_append';
  const value = shouldUseExcelDataForPasswordChange ? VALUE_XLSX : VALUE_BEST_PRACTICE;

  const onChangeInner = (e) => {
    setShouldUseExcelDataForPasswordChange(e.target.value === VALUE_XLSX);
    onChangeExcelDataPassword(e.target.value === VALUE_XLSX);
  };

  const userLoggedInPrompt = `Users already logged in (${loggedIn}) - Password change WILL NOT be forced`;
  const userNeverLoggedInPrompt = `Users never logged in (${neverLoggedIn}) - Password change will be FORCED`;

  const bestPracticeSection = (
    <div className={classes.bestPracticeSection}>
      <ul style={{
        margin: 0, paddingLeft: '0rem', listStyle: 'none',
      }}
      >
        <li>
          <Typography className={classes.listItem}>{userLoggedInPrompt}</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>{userNeverLoggedInPrompt}</Typography>
        </li>
      </ul>
    </div>
  );

  const xlsxSection = (
    <Typography className={classes.listItem}>
      Keep FORCE PASSWORD settings from the excel
    </Typography>
  );

  return (
    <div className={classes.subSection}>
      <Typography className={classes.subSectionTitle}>Manage Passwords</Typography>
      <Typography className={classes.subtitle}>
        The following will be updated for the existing users:
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="gender"
          name="enrollmentGroup"
          value={value}
          onChange={onChangeInner}
        >
          <Typography className={classes.bestPracticeNote}>
            (For non-SSO client and internal enrollments)
          </Typography>
          <FormControlLabel
            value={VALUE_BEST_PRACTICE}
            control={<CustomRadio style={{ paddingBottom: '2rem' }} />}
            label={bestPracticeSection}
          />
          <Typography className={classes.excelValuesNote}>
            (For SSO clients, internal users & test users. Field in Excel should be set to No)
          </Typography>
          <FormControlLabel value={VALUE_XLSX} control={<CustomRadio />} label={xlsxSection} />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

PasswordsSection.propTypes = {
  loggedIn: PropTypes.number.isRequired,
  neverLoggedIn: PropTypes.number.isRequired,
  shouldUseExcelDataForPasswordChange: PropTypes.bool.isRequired,
  setShouldUseExcelDataForPasswordChange: PropTypes.func.isRequired,
  onChangeExcelDataPassword: PropTypes.func.isRequired,
};

const EnrollmentPreflight = ({
  transaction, onDownloadExistingUserList, onChangeExcelDataPassword, onDownloadNewUserList,
}) => {
  const classes = useStyles();
  const {
    logged_in: loggedIn,
    never_logged_in: neverLoggedIn,
    inactive_users,
  } = transaction.existing_users;
  const totalExistingUsers = loggedIn + neverLoggedIn;

  const { success, failed, errors } = transaction.new_users;
  const totalNewUsers = success + failed;
  const hasFailures = failed !== 0;
  const isEmpty = totalNewUsers === 0;

  const [
    shouldUseExcelDataForPasswordChange,
    setShouldUseExcelDataForPasswordChange,
  ] = useState(false);

  const errorDom = errors.map((error) => (
    <li key={error}>
      -
      {' '}
      { error}
    </li>
  ));
  const noNewUserDom = (
    <Box className={classes.noNewUser}>
      <Typography className={classes.noNewUserTitle}>No new users!</Typography>
      <img src={noUserdata} alt="no-new-user" style={{ marginRight: '1rem' }} width={80} height={120} />
    </Box>
  );

  const successUsersDom = (
    <Box className={classes.deepDive}>
      <Box className={classes.deepDiveInner}>
        <img src={successRound} alt="success-icon" style={{ marginRight: '1rem' }} width={15} height={15} />
        <Box>
          <Typography className={classes.deepDiveSubtitle}>{`${success} new user created successfully!`}</Typography>
        </Box>
      </Box>
    </Box>
  );

  const failedUsersDom = (
    <Box className={classes.deepDive}>
      <Box className={classes.deepDiveInner}>
        <img src={errorRound} alt="error-icon" style={{ marginRight: '1rem' }} width={15} height={15} />
        <Box>
          <Typography className={classes.deepDiveSubtitle}>{`${failed}/${totalNewUsers} users could not be created successfully!`}</Typography>
          {hasFailures
          && (
            <Box className={classes.errorsSection}>
              Problems presented:
              <ul data-testid="error-list" style={{ margin: '0px', padding: '0rem', listStyle: 'none' }}>{errorDom}</ul>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Paper elevation={0} className={classes.wrapper}>
      <Box className={classes.userWrapper}>
        <Box className={classes.userCount}>
          New Users :
          <Typography variant="subtitle1" className={classes.transactionuser}>
            {totalNewUsers}
          </Typography>
        </Box>
        <Box className={classes.userCount}>
          Existing Users:
          <Typography variant="subtitle1" className={classes.transactionuser}>
            {totalExistingUsers}
          </Typography>
        </Box>
        <Box className={classes.userCount}>
          Total Users:
          <Typography variant="subtitle1" className={classes.transactionuser}>
            {totalNewUsers + totalExistingUsers}
          </Typography>
        </Box>
      </Box>
      <Divider light sx={{ my: 1.5, borderBottomWidth: 'inherit' }} />
      <Box className={classes.preflightSection}>
        <Box className={classes.leftPreflight}>
          <Box className={classes.preflightHeader}>
            <Typography className={classes.title}>New Users</Typography>
            {
              !isEmpty && (
              <HyperlinkButton
                className={classes.viewListButtonWrapper}
                onClick={() => onDownloadNewUserList(transaction)}
              >
                Download list of users
              </HyperlinkButton>
              )
            }

          </Box>
          <Divider light sx={{ my: 1.5, borderBottomWidth: 'inherit' }} />
          {!isEmpty && success !== 0 && successUsersDom}
          {!isEmpty && hasFailures && failedUsersDom}
          {isEmpty && noNewUserDom}
        </Box>
        <Box className={classes.rightPreflight}>
          <Box className={classes.preflightHeader}>
            <Typography className={classes.title}>Existing Users</Typography>
            <HyperlinkButton
              className={classes.viewListButtonWrapper}
              onClick={() => onDownloadExistingUserList(transaction)}
            >
              Download list of users
            </HyperlinkButton>
          </Box>
          <Divider light sx={{ my: 1.5, borderBottomWidth: 'inherit' }} />
          {!!inactive_users && (
            <>
              <Box className={classes.inactiveCountClass}>
                <img src={errorRound} alt="error-icon" style={{ marginRight: '0.5rem' }} width={15} height={15} />
                <Typography data-testid="inactiveUser">
                  {`${inactive_users} inactive user(s) cannot be enrolled into the LP.`}
                </Typography>

              </Box>
              <Divider light sx={{ my: 1.5, borderBottomWidth: 'inherit' }} />
            </>
          )}
          <Typography className={classes.subText}>
            Please configure the settings for existing active users below
            and click &apos;Save Changes&apos;
          </Typography>
          <Box className={classes.warning}>
            <img src={InfoIcon} alt="info-icon" style={{ marginRight: '0.5rem' }} />
            <Typography>
              Client Information & Custom Fields will not be updated for existing users
            </Typography>
          </Box>
          <PasswordsSection
            loggedIn={loggedIn}
            neverLoggedIn={neverLoggedIn}
            shouldUseExcelDataForPasswordChange={shouldUseExcelDataForPasswordChange}
            setShouldUseExcelDataForPasswordChange={setShouldUseExcelDataForPasswordChange}
            onChangeExcelDataPassword={onChangeExcelDataPassword}
          />
        </Box>
      </Box>
    </Paper>
  );
};

EnrollmentPreflight.propTypes = {
  transaction: PropTypes.shape({
    transaction_id: PropTypes.number.isRequired,
    new_users: PropTypes.shape({
      success: PropTypes.number.isRequired,
      failed: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    existing_users: PropTypes.shape({
      logged_in: PropTypes.number.isRequired,
      never_logged_in: PropTypes.number.isRequired,
      success: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      done: PropTypes.bool.isRequired,
      inactive_users: PropTypes.number.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }).isRequired,
  onDownloadExistingUserList: PropTypes.func.isRequired,
  onDownloadNewUserList: PropTypes.func.isRequired,
  onChangeExcelDataPassword: PropTypes.func.isRequired,
};

export default EnrollmentPreflight;
