import { useEffect, useCallback } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import useInterval from './useInterval';
import {
  getProgress,
} from '../containers/common/apis';
import { getErrorMessage } from '../helpers/apiHelper';

export const useProgressResult = (pollProgressValue, transactionId, updatePollProgress) => {
  // To avoid memory leak
  const [progressResult, setProgressResult] = useStateIfMounted(null);
  const [progressError, setProgressError] = useStateIfMounted(null);

  const clearProgressResult = () => {
    setProgressResult(null);
  };

  const clearProgressError = () => {
    setProgressError(null);
  };

  useEffect(() => {
    if (progressError) {
      updatePollProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressError]);

  const asyncProgressCallback = useCallback(async () => {
    try {
      if (pollProgressValue) {
        setProgressResult(await getProgress(transactionId));
      }
    } catch (e) {
      setProgressError(getErrorMessage(e));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollProgressValue, transactionId]);

  useInterval(asyncProgressCallback, pollProgressValue ? 1000 : null);

  return {
    progressResult, progressError, clearProgressResult, clearProgressError,
  };
};

export default useProgressResult;
