/**
 * This is the GlobalHeader component. Useful for Any of the firms header sections
 *
 * Usage:
 * <GlobalHeader>
 *  <GlobalHeader.Logo />
 *  <GlobalHeader.Left />
 *  <GlobalHeader.Right />
 * </GlobalHeader>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { GlobalHeaderStyles } from './GlobalHeaderStyles';

const getChildrenOnDisplayName = (children, displayName) => React.Children.map(children,
  (child) => (child.type.displayName === displayName ? child : null));

const GlobalHeader = ({ children }) => {
  const left = getChildrenOnDisplayName(children, 'Left');

  const right = getChildrenOnDisplayName(children, 'Right');

  const logo = getChildrenOnDisplayName(children, 'Logo');

  return (
    <GlobalHeaderStyles showDivider={left !== null}>
      <div className="header">
        {logo}
        <div className="right_container">
          <div className="left">{left}</div>
          <div className="right">{right}</div>
        </div>
      </div>
    </GlobalHeaderStyles>
  );
};

const Logo = ({ url, imageUrl, title }) => (
  <a href={url} className="logo">
    <img className="logo_image" src={imageUrl} alt={title} />
  </a>
);
Logo.displayName = 'Logo';
GlobalHeader.Logo = Logo;

const Left = ({ children }) => <span className="header_item">{children}</span>;
Left.displayName = 'Left';
GlobalHeader.Left = Left;

const Right = ({ children }) => <span className="header_item">{children}</span>;
Right.displayName = 'Right';
GlobalHeader.Right = Right;

GlobalHeader.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

Left.propTypes = {
  children: PropTypes.element.isRequired,
};

Right.propTypes = {
  children: PropTypes.element.isRequired,
};

Logo.propTypes = {
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default GlobalHeader;
