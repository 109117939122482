import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import moment from 'moment';
import ConfirmationModal from '../common/ConfirmationModal';
import FormattedTypography from '../common/FormattedTypography';
import LabledTextField from '../common/LabledTextField';
import LabeledView from '../common/LabeledView';
import ErrorText from '../common/ErrorText';
import { ELUIDAT_PROJECT_CHARACTER_LIMIT } from '../../constants';
import AutoMultiSelect from '../common/AutoMultiSelect';
import { getAllFolders } from '../../containers/ReplicationPage/api';
import AlertBarWithAction from '../common/AlertBarWithAction';

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1.5rem',
  },
  alertBottomPadding: {
    marginBottom: '1.5rem',
    padding: '0.5rem 0',
    '& div h6': {
      whiteSpace: 'pre-wrap',
    },
  },
  sourceTitle: {
    padding: '1.5rem 0',
  },
}));

const DuplicateParentProjectName = ({
  open, onClose, projectName, handleSubmit, fieldValue, SelectedFoldersFieldValue,
  destinationConfigId, feature,

}) => {
  const classes = useStyles();

  const myRegexp = /\(([^)]+)\)(?=[^)]*$)(.*)/;
  const match = myRegexp.exec(projectName);

  const updatedProjectName = match ? match[2].replace(/(^-)/gi, '') : projectName;

  const [value, setValue] = useState(`[CONFIG]${destinationConfigId}-(${moment().format('DDMMMYYYY').toUpperCase()})-${updatedProjectName}`);
  const [errors, setErrors] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState(SelectedFoldersFieldValue);

  useEffect(() => {
    if (open) {
      if (fieldValue.length > 0) {
        setValue(fieldValue);
      } else {
        setValue(`[CONFIG][${destinationConfigId}]-(${moment().format('DDMMMYYYY').toUpperCase()})-${updatedProjectName}`);
      }

      if (SelectedFoldersFieldValue.length > 0) {
        setSelectedFolders(setSelectedFolders);
      }
    }
  }, [open, fieldValue, updatedProjectName, SelectedFoldersFieldValue, destinationConfigId]);

  const handleAutoCompleteResult = (result) => {
    const folder = result?.data?.elucidat_folders.map((data) => ({
      value: data.id,
      label: data.name,
    }));
    return folder;
  };

  const handlePickedFolders = (items) => {
    setSelectedFolders(items);
  };

  const formSchema = Yup.object({
    value: Yup.string().required('This is a required field').max(150, 'Name should not exceed 150 characters'),
  });

  const validateAndSubmitForm = useCallback(
    async ({ isSubmit }) => {
      // Check the schema if form is valid:
      const isFormValid = await formSchema.isValid({ value }, {
        abortEarly: false, // Prevent aborting validation after first error
      });
      if (isFormValid && isSubmit) {
        handleSubmit(value, selectedFolders);
      } else {
        // If form is not valid, check which fields are incorrect:
        formSchema.validate({ value }, { abortEarly: false }).catch((err) => {
          setErrors(err?.errors);
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, selectedFolders],
  );

  useEffect(() => {
    setErrors([]);
    validateAndSubmitForm({ isSubmit: false });
  }, [validateAndSubmitForm]);

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      breakpoint="md"
      minHeightClass={classes.modalHeight}
      title={(
        <FormattedTypography
          body="Duplicate Parent Project"
          subHeading="Enter a new project name"
        />
      )}
      dialogContentClass={classes.width100}
      content={(
        <Container className={classes.wrapper}>
          <LabeledView className={classes.sourceTitle} label="Source Project Title" value={projectName} />
          <LabledTextField
            label="Project Name"
            name="newComponentTitle"
            required
            error={errors.length > 0}
            labelClass={classes.greyText}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            textFieldCharLimit={ELUIDAT_PROJECT_CHARACTER_LIMIT}
            inputProps={{
              inputProps: {
                maxLength: ELUIDAT_PROJECT_CHARACTER_LIMIT,
              },
            }}
          />
          <ErrorText touchedField errorField={errors[0]} />
          <AlertBarWithAction
            variant="configurationInfo"
            percentage={null}
            labelText={`The above title will be appended with the new Elucidat Project ID post ${feature}.`}
            actionButtonText=""
            customStyle={classes.alertBottomPadding}
          />
          <AutoMultiSelect
            label="Assign to Additional Folder(s)"
            tooltipMessage="The folders selected in this field will be assigned to the project in addition to the parent project’s source folders"
            placeholder="Select Folders"
            selectedItems={selectedFolders}
            fetchData={getAllFolders}
            maxLimit={200}
            limitExceedMsg="Limit reached"
            handleMultiSelect={handleAutoCompleteResult}
            onChange={(val) => handlePickedFolders(val)}
          />
        </Container>
      )}
      actions={(
        <>
          <Button color="primary" variant="contained" onClick={() => validateAndSubmitForm({ isSubmit: true })}>
            Save Details
          </Button>
        </>
      )}
    />
  );
};

DuplicateParentProjectName.propTypes = {
  open: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fieldValue: PropTypes.string.isRequired,
  SelectedFoldersFieldValue: PropTypes.array.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
  feature: PropTypes.string.isRequired,
};

export default DuplicateParentProjectName;
