/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import Linkify from 'react-linkify';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { LockOutlined, FileCopyOutlined, ArrowForward } from '@material-ui/icons';
import LabledTextField from '../common/LabledTextField';
import LabeledTextArea from '../common/LabeledTextArea';
import MuiChipInput from '../common/ChipInput';
import Select from '../common/SelectMui';
import CKEditor from '../common/CKEditor';
import CustomSwitch from '../common/CustomSwitch';
import InfoTooltip from '../common/InfoTooltip';
import StagesMap from '../StageBadge/constants';
import { LEARNING_PLAN_LOCKED_STATUS } from '../../constants';
import WarningInfoAlert from '../common/WarningInfoAlert';
import useCopyText from '../../hooks/useCopyText';

const useStyles = makeStyles({
  alertBox: {
    '& .MuiAlert-root': {
      marginTop: '0.5rem',
    },
    '& .MuiAlert-message': {
      fontWeight: '800',
    },
  },
  warningBox: {
    padding: '1rem 0',
  },
});

const Error = ({ errorText }) => (
  <span className="error">{errorText || 'This field is required'}</span>
);
Error.propTypes = {
  errorText: PropTypes.string.isRequired,
};

const NA = 'N/A';

const SettingValueCell = ({
  name,
  label,
  value,
  children,
  hasLock,
  hasCopy,
  tooltipMessage,
  isEdit,
  fieldType,
  hasHTML,
  hasError,
  errorText,
  handleChange,
  options,
  disabled,
  redirectTo,
  isMulti,
  valueType,
  isEditRedirect,
  openRedirectModal,
  cb,
  programId,
  helperText,
}) => {
  const { push } = useHistory();
  const classes = useStyles();

  const [readMore, setReadMore] = useState(true);
  const { isCopied, copyText } = useCopyText();

  const LpSwitchWarningBox = () => (
    <WarningInfoAlert severity="warning" customStyles={classes.warningBox}>
      {'Ensure this field is set to \'Not Allowed\' for Client builds'}
    </WarningInfoAlert>
  );

  const lockedSwitchField = (fieldSet) => (
    <div className="align-text">
      <img
        src={LEARNING_PLAN_LOCKED_STATUS[fieldSet?.toString()]?.path}
        alt={LEARNING_PLAN_LOCKED_STATUS[fieldSet?.toString()]?.alt}
        height="15"
        width="15"
      />
      <Typography component="span" className="fld-chip" style={{ margin: '0 1rem' }}>
        {LEARNING_PLAN_LOCKED_STATUS[fieldSet?.toString()]?.title}
      </Typography>
    </div>
  );

  const getValue = (type, fieldValue) => {
    if (type === 'chip') {
      return (
        <div className="fld-value fld-chip multiple">
          {fieldValue.map((el, index) => (
            <Typography component="span" key={el} className="fld-chip">
              {fieldValue.length - 1 !== index ? `${el},` : el}
            </Typography>
          ))}
          {!fieldValue.length && NA}
        </div>
      );
    }

    if (type === 'linkify') {
      return (
        <div className="linkify-value">
          <span className="text-clip" style={{ height: readMore ? '5rem' : 'auto' }}>
            <Linkify>{fieldValue || NA}</Linkify>
          </span>
          {fieldValue && fieldValue.length > 200 && (
            <span tabIndex={0} role="button" className="readmore" onClick={() => setReadMore(!readMore)} onKeyUp={() => setReadMore(!readMore)}>
              View
              {readMore ? 'More' : 'Less'}
            </span>
          )}
        </div>
      );
    }

    if (type === 'switch') {
      return (
        <div style={{ width: '100%' }}>
          {lockedSwitchField(fieldValue)}
          {!isEdit && (
            <LpSwitchWarningBox />
          )}
        </div>
      );
    }

    return <span className="regular">{fieldValue || NA}</span>;
  };

  return (
    <div className="form-cell">
      <div className="form-field">
        <span className="fld-name">
          <span>{label}</span>
          {helperText && <span className="fld-helper">{helperText}</span>}
          {tooltipMessage && <InfoTooltip title={tooltipMessage} />}
        </span>

        {!isEdit && valueType && (
          <div className={`fld-value fld-${fieldType} fld-${name}`}>
            {hasHTML ? (
              <div dangerouslySetInnerHTML={{ __html: value || NA }} />
            ) : (
              getValue(valueType, value)
            )}
            {redirectTo && (
              <ArrowForward
                onClick={() => (isEditRedirect
                  ? openRedirectModal(2)
                  : push(`/report/${redirectTo}/course_prod/overview/`))}
              />
            )}
          </div>
        )}

        {isEdit && (
          <>
            {fieldType === 'chip' && (
              <div className="fld-input">
                <div className={`fld-value ${label === 'Status' ? 'fld-chip2' : 'fld-chip'} multiple`}>
                  {options.map((el) => (
                    <span
                      tabIndex={0}
                      role="button"
                      key={el}
                      className={`pointer ${StagesMap[el] === value ? 'active' : 'regular'}`}
                      onClick={() => {
                        handleChange(name, el);
                        cb(el);
                      }}
                      onKeyDown={() => {
                        handleChange(name, el);
                        cb(el);
                      }}
                    >
                      {StagesMap[el]}
                    </span>
                  ))}
                </div>
                {hasError && <Error errorText={errorText} />}
              </div>
            )}

            {fieldType === 'text' && (
              <div className="fld-input">
                <LabledTextField
                  name={name}
                  value={value}
                  onChange={handleChange}
                  disabled={disabled}
                />
                {hasError && <Error errorText={errorText} />}
              </div>
            )}

            {fieldType === 'textarea' && (
              <div className="fld-input">
                <LabeledTextArea
                  name={name}
                  value={value}
                  onChange={handleChange}
                  disabled={disabled}
                />
                {hasError && <Error errorText={errorText} />}
              </div>
            )}

            {fieldType === 'editor' && (
              <div className="fld-input fld-width900">
                <CKEditor
                  defaultContent={value}
                  name={name}
                  onChange={handleChange}
                  programId={programId}
                />
                {hasError && <Error errorText={errorText} />}
              </div>
            )}

            {fieldType === 'chipinput' && (
              <div className="fld-input">
                <MuiChipInput value={value} name={name} onChange={handleChange} />
                {hasError && <Error errorText={errorText} />}
              </div>
            )}

            {fieldType === 'dropdown' && (
              <div className="fld-input">
                <Select
                  name={name}
                  defaultValue={options.find((el) => el.value === value)}
                  options={options}
                  onChange={(v) => {
                    if (isMulti) {
                      const multiValue = v ? v.map((el) => el.value).join(',') : '';
                      handleChange(name, multiValue, true);
                    } else {
                      handleChange(name, v?.value, true);
                    }
                  }}
                  disabled={disabled}
                  isMulti={isMulti}
                />
                {hasError && <Error errorText={errorText} />}
              </div>
            )}

            {fieldType === 'switchinput' && (
              <div className="fld-input">
                <div className="align-input">
                  {lockedSwitchField(value)}
                  <CustomSwitch
                    checked={value}
                    disabled={disabled}
                    onChange={(event) => handleChange(
                      [event.target.name], !!event.target.checked, true,
                    )}
                    name={name}
                  />
                </div>
                <WarningInfoAlert severity="info" customStyles={classes.alertBox}>
                  {value ? 'Components in this learning plan are not available for copying' : 'All components in this learning plan are available for copying'}
                </WarningInfoAlert>
                <LpSwitchWarningBox />
                {hasError && <Error errorText={errorText} />}
              </div>
            )}

          </>
        )}

        <div className="fld-icons">
          {hasLock && <LockOutlined fontSize="small" />}
          {value && hasCopy && (
            <InfoTooltip title={isCopied ? 'URL Copied' : 'Copy URL'} placement="top">
              <FileCopyOutlined
                fontSize="small"
                onClick={() => copyText(value)}
              />
            </InfoTooltip>
          )}
        </div>

        {children}
      </div>
    </div>
  );
};

SettingValueCell.defaultProps = {
  name: PropTypes.string,
  hasLock: false,
  hasCopy: false,
  tooltipMessage: '',
  isEdit: false,
  hasHTML: false,
  hasError: false,
  isMulti: false,
  disabled: false,
  isEditRedirect: false,
  errorText: '',
  redirectTo: '',
  valueType: 'text',
  options: [],
  cb: () => { },
  programId: null,
  helperText: '',
};

SettingValueCell.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  hasLock: PropTypes.bool,
  hasCopy: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  isEdit: PropTypes.bool,
  fieldType: PropTypes.string.isRequired,
  hasHTML: PropTypes.bool,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  redirectTo: PropTypes.string,
  isMulti: PropTypes.bool,
  isEditRedirect: PropTypes.bool,
  valueType: PropTypes.string,
  openRedirectModal: PropTypes.func.isRequired,
  cb: PropTypes.func,
  programId: PropTypes.number,
  helperText: PropTypes.string,
};

export default SettingValueCell;
