import config from '../../config';
import request from '../../helpers/apiHelper';

export const getJournals = async (searchText, currentPage, cancelToken) => {
  const url = `${config.ROUTES.JOURNALS}/learning-plans/?page=${currentPage}&search=${encodeURIComponent(
    searchText,
  )}`;
  const options = { url, method: 'GET', cancelToken };
  const result = await request(options, true);
  return result?.data?.data;
};

export const getJournalsStage = async (data) => {
  const url = `${config.API_SERVER}/programs/stage`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result?.data?.data;
};

export const getPrompts = async (currentPage, cancelToken) => {
  const url = `${config.ROUTES.JOURNALS}/journal-prompts?page=${currentPage}`;
  const options = { url, method: 'GET', cancelToken };
  const result = await request(options, true);
  return result?.data?.data;
};

export const getPromptsWithinPage = async (pageId, searchText) => {
  const url = `${config.ROUTES.JOURNALS}/journal-prompts/?page_id=${pageId}&search=${encodeURIComponent(
    searchText,
  )}`;
  const options = { url, method: 'GET' };
  const result = await request(options, true);
  return result?.data?.data;
};

export const getPageDetails = async (pageId, searchText) => {
  const url = `${config.ROUTES.JOURNALS}/journal-pages/${pageId}?search=${encodeURIComponent(
    searchText,
  )}`;
  const options = { url, method: 'GET' };
  const result = await request(options, true);
  return result?.data?.data;
};

export const getJournalDetails = async (journalId) => {
  const url = `${config.ROUTES.JOURNALS}/learning-plans/${journalId}`;
  const options = { url, method: 'GET' };
  const result = await request(options, true);
  return result?.data?.data;
};

export const getJournalINFO = async (searchText) => {
  const url = `${config.API_SERVER}/overview/content/lp/${searchText}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const createJournal = async (data) => {
  const url = `${config.ROUTES.JOURNALS}/learning-plans/`;
  const options = { url, method: 'POST', data };
  const result = await request(options, true);
  return result?.data?.data;
};

export const deleteJournal = async (journalId) => {
  const url = `${config.API_SERVER}/lti/journal/lp/${journalId}`;
  const options = { url, method: 'DELETE' };
  const result = await request(options);
  return result?.data?.data;
};

export const updateJournal = async (data, journalId) => {
  const url = `${config.ROUTES.JOURNALS}/learning-plans/${journalId}`;
  const options = { url, method: 'PATCH', data };
  const result = await request(options, true);
  return result?.data;
};

export const getComponents = async (id, isUpdate = false) => {
  const url = `${config.API_SERVER}/lti/journal/lp/${id}/components?update=${isUpdate}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const getPages = async (id, searchText) => {
  const url = `${config.ROUTES.JOURNALS}/journal-pages/?lp_id=${id}&search=${encodeURIComponent(
    searchText,
  )}`;
  const options = { url, method: 'GET' };
  const result = await request(options, true);
  return result?.data?.data;
};

export const deletePage = async (pageId) => {
  const url = `${config.API_SERVER}/lti/journal/${pageId}`;
  const options = { url, method: 'DELETE' };
  const result = await request(options);
  return result;
};

export const updatePage = async (data, pageId) => {
  const url = `${config.API_SERVER}/lti/journal/${pageId}`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const createPage = async (data) => {
  const url = `${config.API_SERVER}/lti/journal/create/page`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const createPrompt = async (data) => {
  const url = `${config.ROUTES.JOURNALS}/journal-prompts/`;
  const options = { url, method: 'POST', data };
  const result = await request(options, true);
  return result?.data?.data;
};

export const deletePrompt = async (promptId) => {
  const url = `${config.ROUTES.JOURNALS}/journal-prompts/${promptId}`;
  const options = { url, method: 'DELETE' };
  const result = await request(options, true);
  return result?.data?.data;
};

export const updatePrompt = async (data, promptId) => {
  const url = `${config.ROUTES.JOURNALS}/journal-prompts/${promptId}`;
  const options = { url, method: 'PUT', data };
  const result = await request(options, true);
  return result?.data?.data;
};
