import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Collapse, Divider } from '@material-ui/core';

import {
  PROGRAM_CREATION,
  ASSIGN_COURSE_CONTENT,
  TEST_USERS_CREATION,
  BULK_UNENROLLMENT,
  AUTO_ENROLLMENT,
  GROUPWORK_GROUPS,
  OVERVIEW,
  WORKSHOP_MANAGEMENT,
  WEBINAR_MANAGEMENT,
  TEMPORARY_ADMIN,
  REPLICATION,
  EDIT_PROGRAM,
  ASSIGNMENT_MANAGEMENT,
  REPORT_SUBHEADER_LOOKUP,
  ADVANCE_CONFIGURATION,
  USER_MANAGEMENT,
  TEXT_ADMIN,
  ADMIN,
  METADATA_MANAGEMENT,
  BULK_UPDATE_ACCOUNTS,
  ASSIGNMENT_DOWNLOAD,
  UPLOAD_GRADE,
  SETTINGS,
  CONTENT_MANAGEMENT,
  WAVE_MANAGEMENT,
  PRODUCTION_CHECKLIST,
  REORDER_TRAINING_MATERIALS,
  BULK_UPDATE_COMPONENT_ACCESS,
  MARK_ALL_USERS_COMPLETE, INDIVIDUAL_SCORM_REFRESH, MULTIPLE_SCORM_REFRESH,
} from '../../constants';

import {
  WHITE,
  HOME_SIDEBAR_BG,
  VERY_LIGHT_GREY,
  MODERATE_LIGHT_GREY,
  MCKINSEY_BLUE,
} from '../../stylesheets/colors';
import SideBarListItem from './SideBarListItem';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  cardWrapperOuter: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.9,
  },
  paper: {
    position: 'initial',
  },
  toolBarBg: {
    backgroundColor: HOME_SIDEBAR_BG,
    minHeight: '5rem',
  },
  subHeadClass: {
    color: WHITE,
    fontWeight: 500,
    lineHeight: 1.17,
  },
  list: {
    paddingTop: 0,
    '& .MuiListItemButton-root.Mui-selected': {
      fontWeight: 'bold',
      backgroundColor: MODERATE_LIGHT_GREY,
      borderLeft: `0.25rem solid ${MCKINSEY_BLUE}`,
    },
  },
  subList: {
    backgroundColor: VERY_LIGHT_GREY,
  },
}));

const OuterSidebar = ({
  programType, programSubType, onChange, programMetadata, isAdmin,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState({});

  const handleGroupTitleClick = (clickedItem) => {
    setOpen((prevState) => ({ ...prevState, [clickedItem]: !prevState[clickedItem] }));
  };

  const groupTitles = [ADVANCE_CONFIGURATION, USER_MANAGEMENT, ASSIGNMENT_MANAGEMENT];

  const onlyAdminItems = ['user_statuses'];

  const unusedSubItems = ['modify_grade', 'webinar_management', 'transfer_cohort_users', 'ec_users_management'];
  const unusedItems = ['bulk_edit_metadata', 'metadata_bulk_update', 'sync_user_metadata', 'sync_session_enrollments', 'single_session_sync', 'webinar_management', 'upload_groupwork_roles', 'group_enrollment', REORDER_TRAINING_MATERIALS, BULK_UPDATE_COMPONENT_ACCESS, MARK_ALL_USERS_COMPLETE, INDIVIDUAL_SCORM_REFRESH, MULTIPLE_SCORM_REFRESH];

  // todo: remove true and false values since now the names are same in both cases, requires BE work

  const titleLookup = {
    [PRODUCTION_CHECKLIST]: {
      true: 'Production Checklist',
      false: 'Production Checklist',
    },
    [PROGRAM_CREATION]: {
      true: 'Components',
      false: 'Components',
    },
    [ASSIGN_COURSE_CONTENT]: {
      true: 'Content',
      false: 'Content',
    },
    [TEST_USERS_CREATION]: {
      true: 'Test User Management',
      false: 'Test User Management',
    },
    [BULK_UNENROLLMENT]: {
      true: 'User Un-enrollment',
      false: 'User Un-enrollment',
    },
    [AUTO_ENROLLMENT]: {
      true: 'User Enrollment',
      false: 'User Enrollment',
    },
    [GROUPWORK_GROUPS]: {
      true: 'Group Management',
      false: 'Group Management',
    },
    [WEBINAR_MANAGEMENT]: {
      true: 'Workshop Management',
      false: 'Workshop Management',
    },
    [TEMPORARY_ADMIN]: {
      true: 'Admin Page',
      false: 'Admin Page',
    },
    [REPLICATION]: {
      true: 'Replication',
      false: 'Replication',
    },
    [EDIT_PROGRAM]: {
      true: 'Modify Config ID',
      false: 'Modify Config ID',
    },
    [ASSIGNMENT_DOWNLOAD]: {
      true: 'Assignment Download',
      false: 'Assignment Download',
    },
    [UPLOAD_GRADE]: {
      true: 'Evaluation',
      false: 'Evaluation',
    },
    [METADATA_MANAGEMENT]: {
      true: 'Metadata Management',
      false: 'Metadata Management',
    },
    [BULK_UPDATE_ACCOUNTS]: {
      true: 'Activate / Deactivate Users',
      false: 'Activate / Deactivate Users',
    },
    [ASSIGNMENT_MANAGEMENT]: 'Assignment Management',
    [ADVANCE_CONFIGURATION]: 'Advanced Configuration',
    [USER_MANAGEMENT]: 'User Management',
    [WORKSHOP_MANAGEMENT]: {
      true: 'Workshop Management',
      false: 'Workshop Management',
    },
    [SETTINGS]: {
      true: 'Settings',
      false: 'Settings',
    },
    [CONTENT_MANAGEMENT]: {
      true: 'Content Management',
      false: 'Content Management',
    },
    [WAVE_MANAGEMENT]: {
      true: 'Enrollment Cohort',
      false: 'Enrollment Cohort',
    },
  };

  const menuItems = isAdmin
    ? programMetadata.activities_status[ADMIN]
    : programMetadata.activities_status[programType];

  const listItems = _omit(menuItems, unusedItems);
  const removeSubType = (subItem) => (!unusedSubItems.includes(subItem)
    && !(onlyAdminItems.includes(subItem) && !isAdmin));

  useEffect(() => {
    if (programSubType === WAVE_MANAGEMENT) {
      setOpen((prevState) => ({ ...prevState, user_management: true }));
    }
    if (programSubType === METADATA_MANAGEMENT) {
      setOpen((prevState) => ({ ...prevState, advance_configuration: true }));
    }
  }, [programSubType]);

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.cardWrapperOuter}>
        <Drawer variant="permanent" classes={{ paper: classes.paper }}>
          <Box>
            <Toolbar className={classes.toolBarBg}>
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                className={classes.subHeadClass}
              >
                {isAdmin ? TEXT_ADMIN : REPORT_SUBHEADER_LOOKUP[programType]}
              </Typography>
            </Toolbar>
            <List component="nav" className={classes.list}>
              <ListItemButton selected={programSubType === OVERVIEW}>
                <ListItemText primary="Overview" onClick={() => onChange(OVERVIEW)} />
              </ListItemButton>
              <Divider />
              {Object.keys(listItems).map((item) => {
                const isGroupTitle = groupTitles.includes(item);
                const title = isGroupTitle
                  ? titleLookup[item]
                  : titleLookup[item]?.[listItems[item].status || false];
                return (
                  <>
                    {item === ADVANCE_CONFIGURATION && <Divider />}
                    <SideBarListItem
                      key={item}
                      programSubType={programSubType}
                      type={item}
                      title={title}
                      onChange={isGroupTitle ? handleGroupTitleClick : onChange}
                      open={open[item]}
                      groupTitles={groupTitles}
                    />
                    {isGroupTitle && (
                      <Collapse in={open[item]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.subList}>
                          {Object.keys(listItems[item])
                            .filter(removeSubType)
                            .map((subItem) => (
                              <SideBarListItem
                                key={subItem}
                                programSubType={programSubType}
                                type={subItem}
                                title={titleLookup[subItem]
                                  && titleLookup[subItem][listItems[item][subItem].status]}
                                leftPad={4}
                                onChange={onChange}
                                groupTitles={groupTitles}
                                open={false}
                              />
                            ))}
                        </List>
                      </Collapse>
                    )}
                  </>
                );
              })}
            </List>
          </Box>
        </Drawer>
      </div>
    </Paper>
  );
};

OuterSidebar.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  programType: PropTypes.string.isRequired,
  programSubType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  programMetadata: PropTypes.shape({
    activities_status: PropTypes.object.isRequired,
  }).isRequired,
};

export default OuterSidebar;
