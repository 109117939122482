import React, { memo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';

const radius = 18;
const useStyles = makeStyles(() => ({
  '@keyframes spinner': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  infiniteSpinner: {
    display: 'inline-block',
    position: 'relative',
    width: ({ scale }) => `${scale * radius * 2}px`,
    height: ({ scale }) => `${scale * radius * 2}px`,

    '& > div': {
      transformOrigin: ({ scale }) => `${scale * radius}px ${scale * radius}px`,
      animation: '$spinner 0.8s linear infinite',

      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: ({ scale }) => `${scale * 1}px`,
        left: ({ scale }) => `${scale * 17}px`,
        width: ({ scale }) => `${scale * 3}px`,
        height: ({ scale }) => `${scale * 9}px`,
        borderRadius: '20%',
        background: MCKINSEY_BLUE,
      },
    },

    '& > div:nth-child(1)': {
      transform: 'rotate(0deg)',
      animationDelay: '-0.7s',
    },

    '& > div:nth-child(2)': {
      transform: 'rotate(45deg)',
      animationDelay: '-0.6s',
    },

    '& > div:nth-child(3)': {
      transform: 'rotate(90deg)',
      animationDelay: '-0.5s',
    },

    '& > div:nth-child(4)': {
      transform: 'rotate(135deg)',
      animationDelay: '-0.4s',
    },

    '& > div:nth-child(5)': {
      transform: 'rotate(180deg)',
      animationDelay: '-0.3s',
    },

    '& > div:nth-child(6)': {
      transform: 'rotate(225deg)',
      animationDelay: '-0.2s',
    },

    '& > div:nth-child(7)': {
      transform: 'rotate(270deg)',
      animationDelay: '-0.1s',
    },

    '& > div:nth-child(8)': {
      transform: 'rotate(315deg)',
      animationDelay: '0s',
    },
  },
  loadr: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,.4)',
    zIndex: 1000,
  },
}));

export const InfiniteLoader = ({ scale, withBg, customClass }) => {
  const classes = useStyles({ scale });
  return (
    <div data-testid="infiniteSpinner" className={`${withBg ? classes.loadr : ''} ${customClass}`}>
      <div className={classes.infiniteSpinner}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

InfiniteLoader.defaultProps = {
  withBg: false,
  scale: 1,
  customClass: '',
};

InfiniteLoader.propTypes = {
  scale: PropTypes.number,
  withBg: PropTypes.bool,
  customClass: PropTypes.string,
};

export default memo(
  InfiniteLoader,
  (prevProp, nextProp) => prevProp.scaleFactor === nextProp.scaleFactor,
);
