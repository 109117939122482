import React, {
  useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select/async';
import _debounce from 'lodash.debounce';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

import { CancelToken } from 'axios';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@mui/material/Collapse';
import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';
import {
  BLACK, DROPDOWN_HOVER, DROPDOWN_SELECT, LIGHT_MEDIUM_GREY,
  MODERATE_DARK_GREY, MODERATE_LIGHT_GREY, PALE_GREY, WHITE,
} from '../../stylesheets/colors';
import theme from '../../stylesheets/theme';
import {
  getAllPrograms,
} from '../../containers/TestUserCreationReportPage/apis';
import { defaultStages } from '../../constants';

let cancelTokenSource = null;
const page = 0;
const limit = 5;

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& .MuiCollapse-entered': {
      marginBottom: '1rem',
    },
  },
  labelWrapper: {
    display: 'flex',
    flex: 0.25,
    fontWeight: 'bold',
    alignItems: 'flex-end',
  },
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  dropdownOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabledOptionTag: {
    fontWeight: 'bold',
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    backgroundColor: LIGHT_MEDIUM_GREY,
    borderRadius: '10px',
    color: BLACK,
  },
});

const getOptionsBgColor = ({ isDisabled, isFocused, isSelected }) => {
  let bgColor = WHITE;
  if (isDisabled) {
    bgColor = PALE_GREY;
  } else if (isFocused) {
    bgColor = DROPDOWN_HOVER;
  } else if (isSelected) {
    bgColor = DROPDOWN_SELECT;
  }
  return bgColor;
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: 'auto',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: MODERATE_DARK_GREY,
    padding: '0.25rem 0.5rem',
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: WHITE,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: WHITE,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  option: (provided, { isDisabled, isFocused, isSelected }) => (
    {
      ...provided,
      backgroundColor: getOptionsBgColor({ isDisabled, isFocused, isSelected }),
      cursor: isDisabled ? 'not-allowed' : 'default',
    }
  ),
};

const AsyncSelect = ({
  label, placeholder, onChange, isMulti, disabledLP, defaultOptions,
  maxMenuHeight, selectedItems, programType, disableSelect, filteredOptions, configId, menuPosition,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState('');
  const [openCollapse, setOpenCollapse] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (disableSelect || selectedItems.length >= limit) {
      setOpenCollapse(true);
    } else {
      setOpenCollapse(false);
    }
  }, [disableSelect, selectedItems]);

  const debouncedChangeHandler = useMemo(
    () => _debounce((value) => setQuery(value), 1000),
    [],
  );

  const loadOptions = async (inputText, callback) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    cancelTokenSource = CancelToken.source();
    const result = await getAllPrograms(
      programType,
      inputText,
      page,
      defaultStages,
      cancelTokenSource.token,
    );
    if (!result.data.docebo_success) callback([]);
    const newPrograms = _filter(
      result.data.items, (o) => !_includes(filteredOptions, o?.config_id?.trim()),
    ).map((program) => ({
      value: program.config_id,
      label: (
        <Box className={classes.dropdownOptions}>
          <Typography variant="h3" component="span">{program.config_id}</Typography>
          {program.config_id === configId && (
            <Typography className={classes.disabledOptionTag} variant="h2" component="span">
              Current Program
            </Typography>
          )}
        </Box>
      ),
      isDisabled: program?.config_id === configId || _includes(disabledLP, program?.config_id),
      program_id: program?.program_id,
    }));
    callback(newPrograms);
  };

  const debouncedFetch = _debounce(loadOptions, 1000);

  const check = disableSelect || selectedItems.length >= limit;

  const getOptions = (input, callback) => debouncedFetch(input, callback);

  const handleDisableOption = (option) => (check || option?.isDisabled ? option.value : '');
  return (
    <div className={classes.wrapper}>
      <Collapse in={openCollapse}>
        <Alert severity="warning">
          Looks like one or more selected test users have reached their maximum limit of
          additional enrollments.You can enroll the remaining users to more LPs individually
          too.
        </Alert>
      </Collapse>

      <Typography variant="body1" className={classes.labelWrapper}>
        {label}
      </Typography>
      <div className={classes.selectWrapper}>
        <Select
          defaultOptions={defaultOptions}
          styles={selectStyles}
          placeholder={placeholder}
          onInputChange={debouncedChangeHandler}
          loadOptions={getOptions}
          name={label}
          onChange={onChange}
          maxMenuHeight={maxMenuHeight}
          isMulti={isMulti}
          isClearable={false}
          isSearchable={!check}
          isOptionDisabled={handleDisableOption}
          menuPosition={menuPosition}
          menuPortalTarget={document.body}
        />
      </div>
    </div>
  );
};

AsyncSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  programType: PropTypes.string.isRequired,
  maxMenuHeight: PropTypes.number,
  selectedItems: PropTypes.array,
  disableSelect: PropTypes.bool,
  filteredOptions: PropTypes.array,
  configId: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  menuPosition: PropTypes.string,
  disabledLP: PropTypes.array,
  defaultOptions: PropTypes.string,
};

AsyncSelect.defaultProps = {
  maxMenuHeight: 300,
  selectedItems: [],
  disableSelect: false,
  filteredOptions: [],
  isMulti: true,
  menuPosition: 'fixed',
  disabledLP: [],
  defaultOptions: true,
};

export default AsyncSelect;
