import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { MCKINSEY_BLUE, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontFamily: 'McKinseySans',
  },
  titleRow: {
    fontSize: '1.25rem',
  },
  lastUpdateRow: {
    fontSize: '1rem',
    color: MODERATE_DARK_GREY,
  },
  refreshButton: {
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
  },
});

const RefreshSection = ({
  onRefresh,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleRow}>
        Live data from Docebo.
        <button onClick={onRefresh} className={classes.refreshButton} type="button">Refresh</button>
        for updated results
      </div>
    </div>
  );
};

RefreshSection.propTypes = {
  onRefresh: PropTypes.func.isRequired,
};

export default RefreshSection;
