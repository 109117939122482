import React from 'react';
import PropTypes from 'prop-types';
import { ContentDetailsSection } from '../ContentManagement/ContentDetailsSection';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import {
  ROUTE_REPORT, SETTINGS,
} from '../../constants';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';

const PointOfContacts = ({
  values, handleChange, programId, coursePath, isLoading,
}) => {
  const ddAnalyst = values?.dd_analyst ? values.dd_analyst.split(',').map((el) => el.trim()) : [];
  const course_production = values?.course_production ? values.course_production.split(',').map((el) => el.trim()) : [];
  const client_delivery = values?.client_delivery ? values.client_delivery.split(',').map((el) => el.trim()) : [];
  const offering_owner = values?.offering_owner ? values.offering_owner.split(',').map((el) => el.trim()) : [];

  return (
    <ContentDetailsBlock
      headerText="Points of Contact"
      fieldRedirectOnLabel={`/${ROUTE_REPORT}/${programId}/${coursePath}/${SETTINGS}?tab=1&mode=view`}
      noPaper
    >
      {isLoading ? <LoadingCircle labelText="Loading Point Of Contacts" /> : (
        <>
          <ContentDetailsSection
            label="Digital Delivery"
            name="dd_analyst"
            type="chipText"
            fieldType="chipinput"
            value={ddAnalyst}
            handleChange={handleChange}
          />
          <ContentDetailsSection
            label="Client Delivery"
            name="client_delivery"
            type="chipText"
            fieldType="chipinput"
            value={client_delivery}
            handleChange={handleChange}
          />
          <ContentDetailsSection
            label="Course Production"
            name="course_production"
            type="chipText"
            fieldType="chipinput"
            value={course_production}
            handleChange={handleChange}
          />
          <ContentDetailsSection
            label="Offering Owner"
            name="offering_owner"
            type="chipText"
            fieldType="chipinput"
            value={offering_owner}
            handleChange={handleChange}
          />
        </>
      )}

    </ContentDetailsBlock>
  );
};

PointOfContacts.propTypes = {
  programId: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  coursePath: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PointOfContacts;
