import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@material-ui/core/Box';
import {
  ACCORDION_GREY, BLACK, DROPPABLE_COLOR, MCKINSEY_BLUE, MODERATE_DARK_GREY,
} from '../../../../stylesheets/colors';
import MCKINSEY_SANS from '../../../../stylesheets/fonts';
import { NA } from '../../../../constants';
import { dateFormatter } from '../../../../helpers/formattingHelpers';
import CustomPopper from '../../../common/Popper';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
    padding: '1.25rem 1.25rem 1rem 1.25rem',

    '& > img': {
      cursor: 'not-allowed',
    },
  },
  promptTitle: {
    fontFamily: MCKINSEY_SANS,
    fontWeight: 600,
    fontSize: '1.125rem',
    color: BLACK,
  },
  lowerWrapper: {
    paddingTop: '0.7rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '50%',
    },

    '& > div:nth-child(1)': {
      '& > div': {
        display: 'flex',
        alignItems: 'center',

        '& > p:nth-child(1)': {
          maxWidth: '80%',
        },
      },
    },

    '& > div:nth-child(2)': {
      marginLeft: '1.875rem',
      '& > div': {
        display: 'flex',
        alignItems: 'center',

        '& > p:nth-child(1)': {
          maxWidth: '80%',
        },
      },
    },
  },
  title: {
    fontFamily: MCKINSEY_SANS,
    fontSize: '0.875rem',
    color: ACCORDION_GREY,
    whiteSpace: 'nowrap',
  },
  value: {
    fontFamily: MCKINSEY_SANS,
    fontSize: '0.875rem',
    color: MODERATE_DARK_GREY,
    fontWeight: 600,
    marginLeft: '2px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
  },
  lpId: {
    fontFamily: MCKINSEY_SANS,
    fontSize: '0.75rem',
    fontWeight: 600,
    marginLeft: '0.625rem',
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MCKINSEY_BLUE}`,
    borderRadius: '10px',
    color: MCKINSEY_BLUE,

  },
  midWrapper: {
    marginLeft: '1.375rem',
    width: '80%',
  },
  promptSeqWrapper: {
    backgroundColor: DROPPABLE_COLOR,

    '& > p': {
      fontSize: '1.25rem',
      fontWeight: 600,
      padding: '1rem 1.25rem',
    },
  },
}));

const PromptsCard = ({
  prompt, onDeletePrompt, onEditPrompt, is_locked,
}) => {
  const classes = useStyles({ });
  const {
    prompt: prompt_text, is_required, sequence_number,
    modified_by, modified, prompt_id,
  } = prompt;

  const actionMenu = [{
    title: 'Edit prompt',
    value: 'edit_prompt',
    onClick: () => { onEditPrompt(prompt); },
  },
  {
    title: 'Delete prompt',
    value: 'delete_prompt',
    onClick: () => { onDeletePrompt(prompt); },
  }];

  return (
    <Paper className={classes.wrapper} square>
      <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box className={classes.promptSeqWrapper}>
          <Typography>{sequence_number}</Typography>
        </Box>
        <Box className={classes.midWrapper}>
          <Typography className={classes.promptTitle}>
            {prompt_text}
          </Typography>
          <Box className={classes.lowerWrapper}>
            <Box>
              <Typography className={classes.title}>
                Updated By:
              </Typography>
              <Box>
                <Typography className={classes.value}>
                  {modified_by || NA}
                </Typography>
                <Typography className={classes.value}>
                  |
                </Typography>
                <Typography className={classes.value}>
                  {modified ? dateFormatter(new Date(modified)) : NA}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography className={classes.title}>
                Prompt ID:
              </Typography>
              <Typography className={classes.value}>
                {prompt_id || NA}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {!is_required && (
          <Typography className={classes.lpId} style={{ marginRight: '2rem' }}>
            Optional
          </Typography>
        )}
        {is_locked ? null : <CustomPopper list={actionMenu} />}
        {' '}
      </Box>
    </Paper>
  );
};

PromptsCard.propTypes = {
  prompt: PropTypes.object.isRequired,
  onDeletePrompt: PropTypes.func.isRequired,
  onEditPrompt: PropTypes.func.isRequired,
  is_locked: PropTypes.bool.isRequired,
};

export default PromptsCard;
