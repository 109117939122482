import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import _omit from 'lodash/omit';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _debounce from 'lodash/debounce';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import LabledTextField from '../../../../../common/LabledTextField';
import {
  ACCORDION_GREY, MODERATE_DARK_GREY, WHITE,
} from '../../../../../../stylesheets/colors';
import {
  updatePage, getPageDetails, deletePage, getPromptsWithinPage,
} from '../../../../../../containers/ContentLibrary/api';
import PageIcon from '../../../../../../assets/img/NoPages.png';
import {
  NA, ROUTE_HOME, snackBarInitialState, TOOLTIP_TIME, USER_INFO_KEY,
} from '../../../../../../constants';
import BreadCrumbHOC from '../../../../../BreadCrumbHOC';
import Loading from '../../../../../common/Loading';
import CustomSnackbar from '../../../../../common/CustomSnackbar';
import AddPromptModal from '../AddPromptModal';
import PromptsListContainer from './PromptListContainer';
import { dateFormatter } from '../../../../../../helpers/formattingHelpers';
import { getUserNameFromLocalStorage } from '../../../../../../helpers/userHelper';
import AddPageModal from '../../AddPageModal';
import { handlePromptCreteAndEdit, handlePromptDelete } from '../utils';
import { getErrorMessage } from '../../../../../../helpers/apiHelper';
import DeletePageModal from './DeletePageModal';
import DeletePromptModal from './DeletePromptModal';
import AlertBarWithAction from '../../../../../common/AlertBarWithAction';
import useAlertBar from '../../../../../../hooks/useAlertBar';

const useStyles = makeStyles((theme) => ({
  bodyWrapper2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  components: {
    fontWeight: 600,
  },
  componentsBreakdown: {
    fontSize: '0.875rem',
    color: MODERATE_DARK_GREY,
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: '2rem',
  },
  searchBox: {
    marginTop: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > button': {
      width: '9.06rem',
      marginTop: '1rem',
      fontSize: '1rem',
    },
  },
  input: {
    paddingRight: '0 !important',
  },
  inputBox: {
    width: '21rem',
    '& input': {
      fontSize: '1rem',
      color: MODERATE_DARK_GREY,
    },
  },
  promptDetailWrapper: {
    flexDirection: 'column',
    fontFamily: theme.typography.fontFamily,
    marginTop: '1.25rem',
    overflow: 'hidden',
    padding: '0.1rem',
  },
  dataWrapper: {
    height: '12.2rem',
    padding: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > div:nth-child(1)': {
      display: 'flex',
      flexDirection: 'row',
      width: '70%',
      alignItems: 'center',

      '& > img': {
        height: '5.313rem',
        width: '6.563rem',
        margin: '0.813rem',
      },
    },

    '& > div:nth-child(2)': {
      width: '30%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      alignItems: 'end',
    },
  },
  textInfo: {
    marginLeft: '1rem',
    width: '100%',

    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-inline-box',
      boxOrient: 'vertical',
    },
    '& > p:nth-child(1)': {
      lineClamp: 1,
    },
    '& > p:nth-child(3)': {
      lineClamp: 2,
    },
  },
  pageDescription: {
    color: ACCORDION_GREY,
    lineHeight: 1.5,
    marginTop: '0.688rem',

  },
  promptTitle: {
    fontWeight: 600,
  },
  lowerWrapper: {
    '& > div': {
      display: 'flex',

      '& > div': {
        display: 'flex',
        justifyContent: 'flex-end',

        '& > p:nth-child(1)': {
          maxWidth: '70%',
        },
      },
    },
  },
  title: {
    color: ACCORDION_GREY,
    whiteSpace: 'nowrap',
  },
  value: {
    fontWeight: 600,
    marginLeft: '2px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
  },
  editPageButton: {
    height: '2.5rem',

    '& > Typography': {
      color: WHITE,
    },
  },
  promptWrapper: {
    padding: '1.25rem 2rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  linkedComponent: {
    display: 'flex',
    marginTop: '0.75rem',
    alignItems: 'center',
    width: '100%',
  },
  pageActions: {
    '& > button': {
      height: '2.5rem',
    },
    '& > button:nth-child(2)': {
      marginLeft: '1.25rem',
    },
    '& > Typography': {
      fontSize: '1rem',
      color: WHITE,
    },
  },
}));

const PageDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: pageId, programType } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isPromptLoading, setIsPromptLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [pageInfo, setPageInfo] = useState({ lp: {} });
  const [prompts, setPrompts] = useState([]);
  const [showAddPromptModal, setShowAddPromptModal] = useState(false);
  const [showDeletePageModal, setShowDeletePageModal] = useState(false);
  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);
  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);
  const [showAddPageModal, setShowAddPageModal] = useState(false);
  const [savedValues, setSavedValues] = useState({});
  const {
    journal_title: pageTitle = '', description = '', page_id = '', course_id = '', journal_type = '',
    modified_by = '', modified = '', lp: {
      is_locked = false, id: lpId = '', journal_id = '', learning_plan_id = '', program_name = '', learning_plan_code = '', num_pages = 0,
    }, training_material_id, standard_pages_count_in_journal = 0, review_pages_count_in_journal = 0,
  } = pageInfo;
  const [savedPromptValues, setSavedPromptValues] = useState({});
  const [selectedPrompt, setSelectedPrompt] = useState({});

  const location = useLocation();
  const successMessage = location?.state?.successMessage;
  const { ENTER_DELAY, ENTER_NEXT_DELAY } = TOOLTIP_TIME;

  const {
    labelText, variant, open, setAlertBarConfig, initialAlertBarConfig,
  } = useAlertBar();

  const getDetails = useCallback(async (searchVal) => {
    try {
      const response = await getPageDetails(pageId, searchVal);
      setPageInfo(response);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.log(e);
    } finally {
      setIsLoading(false);
      setIsPromptLoading(false);
    }
  }, [pageId]);

  useEffect(() => {
    setIsLoading(true);
    setIsPromptLoading(true);
    getDetails('');
    if (successMessage) {
      setAlertBarConfig({
        labelText: successMessage,
        variant: 'success',
        open: true,
      });
      history.replace({ state: {} });
    }
  }, [getDetails, history, pageId, setAlertBarConfig, successMessage]);

  const fetchPrompts = useCallback(async (searchVal) => {
    setIsPromptLoading(true);
    try {
      const response = await getPromptsWithinPage(pageId, searchVal || '');
      setPrompts(response?.items);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.log(e);
    } finally {
      setIsPromptLoading(false);
    }
  }, [pageId]);

  useEffect(() => {
    fetchPrompts();
  }, [fetchPrompts]);

  const onPromptModalClose = () => {
    batchUpdates(() => {
      setShowAddPromptModal(false);
      setSavedPromptValues({});
      setSelectedPrompt({});
    });
  };

  const handlePromptCallback = useCallback((searchVal) => {
    setIsPromptLoading(true);
    fetchPrompts(searchVal);
  }, [fetchPrompts]);

  const handleOnOk = async (values) => {
    handlePromptCreteAndEdit({
      setSnackbarObj,
      setAlertBarConfig,
      values,
      savedPromptValues,
      selectedPrompt,
      pageId,
      prompts,
      onPromptModalClose,
      cbFunction: () => { handlePromptCallback(searchText); },
      history,
      programType,
    });
  };

  const onAddPrompt = () => {
    setShowAddPromptModal(true);
  };

  const breadCrumbList = [
    { label: 'All Journals', redirectionUrl: `/${ROUTE_HOME}/${programType}/contentLibrary/lti/journals`, isActive: false },
    { label: 'Journal Details', redirectionUrl: `/${ROUTE_HOME}/${programType}/contentLibrary/lti/journals/${lpId}`, isActive: false },
    { label: 'Page Details', redirectionUrl: '', isActive: true },
  ];

  const handleOnClose = () => {
    setShowAddPageModal(false);
    setSavedValues({});
  };

  const onPageEdit = async (values, selectedComponent) => {
    try {
      const { pageTitle: updatedPageTitle, pageDesc, journalType } = values;
      const selectedCmpClone = cloneDeep(selectedComponent);
      const filteredComponentObj = _omit(selectedCmpClone, ['value', 'id', 'isDisabled', 'label']);
      const userName = getUserNameFromLocalStorage(USER_INFO_KEY);

      const data = {
        course: filteredComponentObj,
        page_title: updatedPageTitle,
        page_description: pageDesc,
        journal_type: journalType,
        journal_id: lpId,
        modified_by: userName,
        training_material_id,
      };
      await updatePage(data, pageId);
      handleOnClose();
      if (journalType === 'review') {
        history.push({
          pathname: `/${ROUTE_HOME}/${programType}/contentLibrary/lti/journals/${lpId}`,
          state: { successMessage: 'Page Edited Successfully' },
        });
      }
      setAlertBarConfig({
        labelText: 'Page Edited Successfully',
        variant: 'success',
        open: true,
      });
      setIsLoading(true);
      getDetails(searchText);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.error(e);
    }
  };

  const handlePageEdit = async () => {
    const filledFormData = {
      selectedComponent: course_id,
      pageTitle,
      pageId: page_id,
      pageDesc: description,
      journalType: journal_type,
    };
    setSavedValues(filledFormData);
    setShowAddPageModal(true);
  };

  const onDeletePrompt = async ({ promptId }) => {
    setShowDeletePromptModal(false);
    handlePromptDelete({
      promptId,
      setSnackbarObj,
      setAlertBarConfig,
      cbFunction: () => { handlePromptCallback(searchText); },
    });
  };

  const onClickDeletePrompt = (selectedPromptObj) => {
    setSelectedPrompt(selectedPromptObj);
    setShowDeletePromptModal(true);
  };

  const onCloseDeletePromptModal = () => {
    setSelectedPrompt({});
    setShowDeletePromptModal(false);
  };

  const onEditPrompt = async (selectedPromptObj) => {
    const { prompt: promptText, is_required } = selectedPromptObj;
    const filledFormData = {
      promptText,
      promptType: is_required ? 'mandatory' : 'optional',
    };
    setSelectedPrompt(selectedPromptObj);
    setSavedPromptValues(filledFormData);
    setShowAddPromptModal(true);
  };

  const handlePageDelete = async () => {
    try {
      await deletePage(pageId);
      history.push({
        pathname: `/${ROUTE_HOME}/${programType}/contentLibrary/lti/journals/${lpId}`,
        state: { successMessage: 'Page Deleted Successfully' },
      });
    } catch (e) {
      console.log(e);
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    }
  };

  const onClickDeletePage = () => {
    setShowDeletePageModal(true);
  };

  const onCloseDeletePageModal = () => {
    setShowDeletePageModal(false);
  };

  const debouncedChange = useCallback(_debounce((searchVal) => { fetchPrompts(searchVal); }, 500),
    [fetchPrompts]);

  const handleSearch = (searchVal) => {
    setSearchText(searchVal);
    setIsPromptLoading(true);
    debouncedChange(searchVal);
  };

  return (
    <Box className={classes.bodyWrapper2}>
      { showDeletePromptModal && (
      <DeletePromptModal
        onClose={onCloseDeletePromptModal}
        onConfirm={onDeletePrompt}
        prompt={selectedPrompt}
        pageId={page_id}
      />
      )}
      {
          showAddPageModal && (
          <AddPageModal
            setSnackbarObj={setSnackbarObj}
            open={showAddPageModal}
            onClose={handleOnClose}
            onOk={onPageEdit}
            journalInfo={{
              program_name,
              learning_plan_code,
              learning_plan_id,
              journalId: lpId,
              journal_id,
              num_pages,
            }}
            savedValues={savedValues}
            pageTitle={pageTitle}
            standardPagesLength={standard_pages_count_in_journal}
          />
          )
        }
      {
          showAddPromptModal && (
          <AddPromptModal
            open={showAddPromptModal}
            onClose={() => { onPromptModalClose(); }}
            onOk={handleOnOk}
            selectedPage={pageInfo}
            journal_id={journal_id}
            savedPromptValues={savedPromptValues}
            prompt_id={selectedPrompt?.prompt_id}
          />
          )
        }
      { showDeletePageModal && (
      <DeletePageModal
        onClose={onCloseDeletePageModal}
        onConfirm={handlePageDelete}
        page={pageInfo}
        standardPagesLength={standard_pages_count_in_journal}
        reviewPagesLength={review_pages_count_in_journal}
      />
      )}
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
      {open && (
      <AlertBarWithAction
        variant={variant}
        labelText={labelText}
        actionButtonIcon={<CloseIcon onClick={() => setAlertBarConfig(initialAlertBarConfig)} />}
      />
      )}
      <Paper className={classes.wrapper}>
        <Box className={classes.promptWrapper}>
          <BreadCrumbHOC list={breadCrumbList} />
          <Box className={classes.promptDetailWrapper}>
            <Paper className={classes.dataWrapper} square elevation={1}>
              { isLoading ? <Loading /> : (
                <>
                  <Box>
                    <img src={PageIcon} alt="dots" />
                    <Box className={classes.textInfo}>
                      <Tooltip title={pageTitle} arrow placement="top" enterDelay={ENTER_DELAY} enterNextDelay={ENTER_NEXT_DELAY}>
                        <Typography variant="h4" component="p" className={classes.promptTitle}>{pageTitle || NA}</Typography>
                      </Tooltip>
                      <Box className={classes.linkedComponent}>
                        <Typography variant="h2" component="p" className={classes.title}>Page ID:</Typography>
                        <Typography variant="h2" component="p" className={classes.value}>{page_id || NA}</Typography>
                      </Box>
                      <Typography variant="h3" component="p" className={classes.pageDescription}>{description || NA}</Typography>
                      <Box className={classes.linkedComponent}>
                        <Typography variant="h2" component="p" className={classes.title}>Linked Component Docebo ID:</Typography>
                        <Typography variant="h2" component="p" className={classes.value}>{course_id || NA}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box className={classes.pageActions}>
                      <Button
                        className={classes.editPageButton}
                        type="button"
                        color="primary"
                        variant="outlined"
                        onClick={() => { handlePageEdit(); }}
                        disabled={is_locked}
                      >
                        <Typography variant="h3" component="p">Edit</Typography>
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        onClick={() => { onClickDeletePage(); }}
                        disabled={is_locked}
                      >
                        <Typography variant="h3" component="p">Delete</Typography>
                      </Button>
                    </Box>
                    <Box className={classes.lowerWrapper}>
                      <Box>
                        <Typography variant="h2" component="p" className={classes.title}>Updated By:</Typography>
                        <Box>
                          <Typography variant="h2" component="p" className={classes.value}>{modified_by || NA}</Typography>
                          <Typography variant="h2" component="p" className={classes.value}>|</Typography>
                          <Typography variant="h2" component="p" className={classes.value}>
                            {modified ? dateFormatter(new Date(modified)) : NA}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Paper>
          </Box>
          <Box className={classes.bodyContainer}>
            <Typography variant="h1" component="p" className={classes.components}>
              Prompts (
              {prompts.length}
              )
            </Typography>
            <Box className={classes.searchBox}>
              <LabledTextField
                label="Search"
                placeholder="Search by Prompt ID or Text"
                labelClass={classes.componentsBreakdown}
                customCss={`${classes.inputBox}`}
                inputProps={{
                  inputProps: {
                    name: 'configId',
                    className: classes.input,
                  },
                  InputProps: {
                    endAdornment: searchText ? (
                      <ClearIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => { handleSearch(''); }} />
                    ) : (<SearchIcon style={{ cursor: 'pointer' }} fontSize="small" />),
                  },
                }}
                value={searchText}
                onChange={(e) => { handleSearch(e.target.value); }}
              />
              {prompts.length > 0 && (
                <Button type="button" variant="contained" color="primary" onClick={() => { onAddPrompt(); }} disabled={is_locked}>
                  Add Prompt
                </Button>
              )}
            </Box>
            {isLoading || isPromptLoading ? <Loading /> : (
              <PromptsListContainer
                prompts={prompts}
                onAddPrompt={onAddPrompt}
                onDeletePrompt={onClickDeletePrompt}
                onEditPrompt={onEditPrompt}
                searchText={searchText}
                is_locked={is_locked}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>

  );
};

export default PageDetail;
