import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MEDIUM_GREY } from '../../stylesheets/colors';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import ConfirmationIcon from '../../assets/icons/confirmation.svg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:nth-child(3)': {
      marginBottom: '2rem',
    },

    '& > p:nth-child(4)': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    fontWeight: 600,
    marginBottom: '0.2rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
});

const MarkSetUserCompleteConfirmationModal = ({
  open, onClose, loading, onConfirm, courseName,
}) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyle}
      isLoading={loading}
      content={(
        <Container className={classes.wrapper}>
          <img src={ConfirmationIcon} alt="confirmation-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>Mark all users as complete</Typography>
          <Typography variant="subtitle2" component="p" className={classes.infoContentText}>{courseName}</Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            You are choosing to mark all users enrolled in this workshop as complete. Please
            note this action will take some time and will run in the background. You can check
            back in the activity log for progress on this action.
          </Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            The status will update for all enrolled users within this session.
          </Typography>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => { onConfirm(); }}>
            Proceed
          </Button>
        </>
      )}
    />
  );
};

MarkSetUserCompleteConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  courseName: PropTypes.string.isRequired,
};

export default MarkSetUserCompleteConfirmationModal;
