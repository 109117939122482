import config from '../../config';
import request from '../../helpers/apiHelper';

export const getWorkshops = (programId) => {
  const url = `${config.ROUTES.GET_WORKSHOPS}/${programId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const getSessionsAndEvents = (programId, courseId) => {
  const url = `${config.ROUTES.GET_WORKSHOPS}/${programId}/course_details/${courseId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const createSession = (courseId, data) => {
  const url = `${config.ROUTES.GET_WORKSHOPS}/course/${courseId}/session`;
  const options = { url, method: 'POST', data };
  return request(options);
};

export const updateSession = (sessionId, data) => {
  const url = `${config.ROUTES.GET_WORKSHOPS}/session/${sessionId}`;
  const options = { url, method: 'PUT', data };
  return request(options);
};

export const getSessionDetails = (sessionId) => {
  const url = `${config.ROUTES.GET_WORKSHOPS}/session/${sessionId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const deleteSessionByID = (sessionId) => {
  const url = `${config.ROUTES.GET_WORKSHOPS}/session/${sessionId}`;
  const options = { url, method: 'DELETE' };
  return request(options);
};

export const getInstructor = (inputText) => {
  const url = `${config.ROUTES.WORKSHOP_SESSION}/search/instructors?search_text=${inputText}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const createEvent = (sessionId, data) => {
  const url = `${config.ROUTES.GET_WORKSHOP_EVENTS}/${sessionId}/event`;
  const options = { url, method: 'POST', data };
  return request(options);
};

export const editEvent = (eventId, data) => {
  const url = `${config.ROUTES.EDIT_EVENT}/event/${eventId}`;
  const options = { url, method: 'PUT', data };
  return request(options);
};

export const deleteEventByID = (eventId) => {
  const url = `${config.ROUTES.EDIT_EVENT}/event/${eventId}`;
  const options = { url, method: 'DELETE' };
  return request(options);
};

export const getEvent = (eventId) => {
  const url = `${config.ROUTES.GET_WORKSHOPS}/event/${eventId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const listVenues = () => {
  const url = `${config.ROUTES.VENUES}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const syncEnrolledUsers = (program_id) => {
  const url = `${config.ROUTES.WORKSHOP_SESSION}/sync_users/program/${program_id}`;
  const options = { url, method: 'POST' };
  return request(options);
};

export const syncAllUsers = (data, course_id) => {
  const url = `${config.ROUTES.WEBINAR}/mark-all-users-complete/${course_id}`;
  const options = { url, method: 'PUT', data };
  return request(options);
};

export const syncSingleSessionUsers = (program_id, session_id) => {
  const url = `${config.ROUTES.WORKSHOP_SESSION}/sync_users/${program_id}`;
  const options = { url, method: 'POST', data: { session_ids: [session_id] } };
  return request(options);
};
