import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@material-ui/core';
import RefreshConfirmation from '../../../assets/icons/refreshConfirmation.png';
import { BLACK, SWITCH_GREY } from '../../../stylesheets/colors';
import MCKINSEY_SANS from '../../../stylesheets/fonts';

const useStyles = makeStyles({
  modalWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2.5rem 0 0.5rem 0',
    textAlign: 'center',
    '& > span': {
      fontSize: '1.5rem',
      color: BLACK,
      fontWeight: 600,
      marginBottom: '3.5rem',
      fontFamily: MCKINSEY_SANS,
    },
    '& > img': {
      width: '7.438rem',
      height: '6.25rem',
      marginBottom: '1.875rem',
    },
  },
  modalCTA: {
    paddingTop: '2rem',
    borderTop: `1px solid ${SWITCH_GREY}`,
    width: '100%',

    '& >button:nth-child(1)': {
      width: '10.5rem',
    },

    '& >button:nth-child(2)': {
      marginLeft: '1.25rem',
      width: '10.1rem',
    },
  },
});

export default function TrainingMaterialModal({ onClose, onOk }) {
  const classes = useStyles({ });
  return (
    <div className={classes.modalWrapper}>
      <img src={RefreshConfirmation} alt="confirmation" />
      <span>
        Your applied search and filter results will be cleared to
        <br />
        {' '}
        fetch the latest table from Docebo
      </span>
      <div className={classes.modalCTA}>
        <Button color="primary" variant="outlined" type="button" onClick={onClose}>
          Do not Refresh
        </Button>
        <Button color="primary" variant="contained" type="button" onClick={onOk}>
          Continue
        </Button>
      </div>
    </div>

  );
}

TrainingMaterialModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};
