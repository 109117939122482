/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { CancelToken, isCancel } from 'axios';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Box,
  Grid,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { find } from 'lodash';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';

import ReplicationHeader from '../../components/Replication/ReplicationHeader';
import ReplicationSidebar from '../../components/Replication/ReplicationSidebar';
import ReplicationFormPage from '../../components/Replication/ReplicationFormPage';
import ReplicationConfigureCourses from '../../components/Replication/ReplicationConfigureCourses';
import ReplicationOverviewModal from '../../components/Replication/ReplicationOverviewModal';
import Loader from '../../components/Loader';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import {
  ROUTE_HOME,
  ROUTE_REPORT,
  REPLICATION_PENDING,
  REPLICATION_PROCESS_STEPS,
  PROCESS_STATUS_END,
  PROCESS_STATUS_ERROR,
  OVERVIEW,
} from '../../constants';
import { internalServerErrorModalLogic } from '../common/utils';
import {
  scanForReplication,
  getReplicationProgress,
  checkValidityOfElucidatPid,
  proceedWithReplication,
  checkNewConfigIdExists,
  replicationTimeline,
  getChannels,
  uniqueChannelName,
} from './api';
import {
  DARK_GREEN,
  DARK_MEDIUM_GREY,
  KEY_BLACK,
  MODERATE_LIGHT_GREY,
  SWITCH_GREY,
  MEDIUM_GREY,
  WHITE,
} from '../../stylesheets/colors';

import CustomModal from '../../components/common/CustomModal';
import LinkButton from '../../components/common/LinkButton';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  repLoaderWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem 10rem',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  dividerWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '12rem 0',
  },
  progressInfoWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  spinnerWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  timeline: {
    flex: 0.2,
  },
  body: {
    flex: 0.8,
  },
  circularProgressPadding: {
    padding: '0.5rem',
  },
  listSubheaderRoot: {
    margin: '0 auto',
    padding: '1rem 0',
    color: KEY_BLACK,
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
  confirmationModalContent: {
    width: '100%',
    paddingLeft: '3rem',
  },
  closeButton: {
    padding: '0.5rem 3rem',
    marginTop: '0.75rem',
  },
  greyColor: {
    color: DARK_MEDIUM_GREY,
  },
  greenColor: {
    color: DARK_GREEN,
  },
  successText: {
    textAlign: 'center',
    color: DARK_GREEN,
  },
  modalList: {
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  loaderModalFooter: {
    width: '100%',
    borderTop: `1px solid ${SWITCH_GREY}`,
    paddingTop: '2rem',
  },
  modalHeight: {
    minHeight: '400px',
  },
  progressHeadWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  progressHeadLoader: {
    flex: '0.05',
    backgroundColor: 'blue',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressHeadContent: {
    padding: '0 2rem 0 1rem',
    flex: '0.95',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

});

const ReplicationPage = (props) => {
  const { programMetadata, match } = props;
  const { config_id: configId, docebo_lp_id: doceboLpId } = programMetadata;
  const programId = _get(match, 'params.programId');
  const programType = _get(match, 'params.programType');
  const programSubType = _get(match, 'params.programSubType');
  const routeTransactionId = _get(match, 'params.transactionId');

  const [destinationConfigId, setDestinationConfigId] = useState(configId);
  const [progress, setProgress] = useState({ done: false, percentage: 0 });
  // eslint-disable-next-line no-unused-vars
  const [isReplicationFailed, setIsReplicationFailed] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [report, setReport] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [pollProgress, setPollProgress] = useState(true);
  const [timelineData, setTimelineData] = useState([]);
  const [openReplicationConfirmationModal, setReplicationConfirmationModal] = useState(false);
  const [channelData, setChannelData] = useState({});
  const [channelName, setChannelName] = useState({});
  const [channelLoading, setChannelLoading] = useState(false);
  const [shouldProceed, setShouldProceed] = useState(false);
  const [channelConfig, setChannelConfig] = useState({});
  const [processSteps, setProcessSteps] = useState([
    {
      label: 'Config ID & Channel',
      completed: true,
    },
    {
      label: 'Modify Learning Objects',
      completed: false,
    },
  ]);
  const [progressModal, setProgressModal] = useState(false);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const getChannelData = async () => {
      try {
        setChannelLoading(true);
        const [resp, resp1] = await Promise.all([
          getChannels(programId),
          uniqueChannelName(programId),
        ]);
        const data = _get(resp, 'data.data[0]', {});
        const formattedChannelName = _get(resp1, 'data.channel_name', '');
        setChannelData(data);
        setChannelName(formattedChannelName);
      } catch (e) {
        console.error(e);
      } finally {
        setChannelLoading(false);
      }
    };
    getChannelData();
  }, [programId]);

  const openModalWithReportData = async (transactionId, replicatedProgramId) => {
    let cancelTokenSource = null;
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }

    cancelTokenSource = CancelToken.source();
    const res = await getReplicationProgress(transactionId, cancelTokenSource.token);
    const { done, report: replicationReport, replicated_program_id } = res.data;

    if (
      replicatedProgramId === replicated_program_id
      && done
      && Object.keys(replicationReport).length
    ) {
      // set completion modal data and open modal
      setProgress(res.data);
      setReplicationConfirmationModal(true);
      history.push(`/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}`);
    }
  };

  useEffect(() => {
    if (routeTransactionId) {
      setProgressModal(pollProgress);
    }
  }, [routeTransactionId, pollProgress]);

  useEffect(() => {
    let timer = null;
    const transactionId = routeTransactionId;
    let cancelTokenSource = null;

    const getTimelineData = async () => {
      try {
        const resp = await replicationTimeline(programId);
        const data = _get(resp, 'data.data', []);
        const idx = _findIndex(data, { status: REPLICATION_PENDING });

        if (idx > -1) {
          history.replace(
            `/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}/${data[idx].id}`,
          );
        } else {
          setTimelineData(data);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const checkIsReplicationFailed = ({ process }) => !!find(process, { process_status: 'ERROR' });

    const pollProgressApi = async () => {
      try {
        if (cancelTokenSource) {
          cancelTokenSource.cancel();
        }

        cancelTokenSource = CancelToken.source();
        const res = await getReplicationProgress(transactionId, cancelTokenSource.token);
        const { done, report: replicationReport } = res.data;

        if (done && Object.keys(replicationReport).length) {
          batchUpdates(() => {
            // set data
            setProgress(res.data);
            setIsReplicationFailed(checkIsReplicationFailed(res.data));

            // Replication complete, redirect to report page
            setPollProgress(false);
          });
          setReplicationConfirmationModal(true);
          setReport(null);
          history.push(`/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}`);
        } else {
          // set data
          setProgress(res.data);
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };

    if (transactionId) {
      pollProgressApi();
    } else {
      getTimelineData();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [pollProgress, routeTransactionId, programSubType, history, programId, programType]);

  const formatChannelData = useCallback(
    ({ picked, newChannelName }) => (channelData.channel_id && picked !== 'noChannel'
      ? {
        channel_id: channelData.channel_id,
        source_channel_name: channelData.name,
        add_visibility_only: picked === 'onlyVisibility',
        new_channel_name: picked !== 'onlyVisibility' ? newChannelName : '',
        link_assets: picked === 'newChannelWithSameAssets',
      }
      : {}),
    [channelData],
  );

  const onReplicate = useCallback(
    async (values) => {
      try {
        const channel = formatChannelData(values);
        batchUpdates(() => {
          setShowSpinner(true);
          setReport(null);
          setDestinationConfigId(values.newCid);
          setChannelConfig(channel);
        });

        const response = await scanForReplication(doceboLpId);
        if (response.data.success) {
          batchUpdates(() => {
            setReport(response.data.data);
            // eslint-disable-next-line max-len
            setProcessSteps((steps) => steps.map((v, idx) => (idx === 1 ? { ...v, completed: true } : v)));
            setShowSpinner(false);
            history.push(`/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}`);
          });
        }
      } catch (e) {
        console.error(e);
        setShowSpinner(false);
      }
    },
    [doceboLpId, formatChannelData, history, programId, programType, programSubType],
  );

  const onProceed = useCallback(async (modifiedReport) => {
    try {
      batchUpdates(() => {
        setProgress({ done: false, percentage: 0 });
        setShouldProceed(true);
      });
      const response = await proceedWithReplication({
        ...modifiedReport,
        learning_plan: { ...modifiedReport.learning_plan, code: destinationConfigId },
        program_id: programId,
        source_cid: configId,
        destination_cid: destinationConfigId,
        channel_config: channelConfig,
      });
      const { transaction_id: rtid } = response.data;
      history.push(`/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}/${rtid}`);
      setPollProgress(true);
    } catch (e) {
      console.error(e);
    }
  }, [
    configId,
    destinationConfigId,
    history,
    programId,
    programSubType,
    programType,
    channelConfig,
  ]);

  const onErrorModalClose = useCallback(() => {
    setIsErrorModalOpen(false);
    history.replace(`/${ROUTE_HOME}/${programType}`);
  }, [history, setIsErrorModalOpen, programType]);

  const redirectToreplicatedProgram = () => {
    setReport(null);
    setDestinationConfigId(configId);
    setReplicationConfirmationModal(false);
    history.push(`/${ROUTE_REPORT}/${progress?.replicated_program_id}/${programType}/${OVERVIEW}`);
  };

  const replicationProgressInfo = (
    <>
      <Box className={classes.listSubheaderRoot}>
        <Typography variant="subtitle1" align="center" style={{ marginBottom: '1rem' }}>
          Please hold on a bit. We are working on it…
        </Typography>
        <Typography component="h6" variant="h6" color="default">
          {`We are replicating program '${configId}'`}
        </Typography>
      </Box>
      <List dense style={{ margin: '0 auto' }}>
        {progress?.process?.map((item) => {
          let infoIcon = (
            <CircularProgress
              size="3.5rem"
              thickness={5}
              className={classes.circularProgressPadding}
            />
          );

          if (item?.process_status === PROCESS_STATUS_END) {
            infoIcon = (
              <img
                src={CheckRoundIcon}
                alt="check-round-green"
                className={classes.circularProgressPadding}
              />
            );
          } else if (item?.process_status === PROCESS_STATUS_ERROR) {
            infoIcon = (
              <img
                src={ErrorRoundIcon}
                alt="error-round"
                className={classes.circularProgressPadding}
              />
            );
          }
          return (
            <ListItem className={classes.listItem} key={item?.process_id}>
              <ListItemIcon>{infoIcon}</ListItemIcon>
              {item?.completed && item?.total && item?.total > 1 ? (
                <ListItemText
                  primary={`${REPLICATION_PROCESS_STEPS[item?.process_id]} (${item?.completed}/${item?.total})`}
                  secondary={<Typography type="body2" style={{ color: '#FF9494' }}>{item?.process_status === PROCESS_STATUS_ERROR ? item?.user_message : ''}</Typography>}
                />
              ) : (
                <ListItemText primary={REPLICATION_PROCESS_STEPS[item?.process_id]} secondary={<Typography type="body2" style={{ color: '#FF9494' }}>{item?.process_status === PROCESS_STATUS_ERROR ? item?.user_message : ''}</Typography>} />)}
            </ListItem>
          );
        })}
      </List>
    </>
  );

  const Body = showSpinner
    ? () => null
    : {
      true: ReplicationConfigureCourses,
      false: ReplicationFormPage,
    }[routeTransactionId ? false : (!!report)];

  const loaderDom = (
    <Container style={{ padding: '1rem 3rem', minHeight: '50vh' }}>
      <Grid container xs={12} spacing={0} direction="column">
        <Grid item xs={12} md={12} alignItems="center" className={classes.repLoaderWrapper}>
          <Loader
            progress={progress.percentage}
            showEstimatedTime={false}
            showLoaderSubText={false}
            estimatedTimeSeconds={progress.estimated_time_left}
            hideCancel
          />
        </Grid>
        <Grid item xs={12} md={12} className={classes.progressInfoWrapper}>
          {!!(progress?.process || []).length && replicationProgressInfo}
        </Grid>
      </Grid>
    </Container>
  );

  const spinnerDom = !showSpinner ? null : (
    <div className={classes.spinnerWrapper}>
      <CircularProgress />
    </div>
  );

  const inProgressReplication = ((routeTransactionId?.length > 0) && !progress?.done);

  return (
    <Paper className={classes.wrapper}>
      {spinnerDom}
      {!spinnerDom && (
        <>
          {(inProgressReplication && !progressModal) && (
            <Paper elevation="1">
              <Toolbar disableGutters className={classes.progressHeadWrapper} variant="dense">
                <Box className={classes.progressHeadLoader}>
                  <CircularProgress
                    size="3rem"
                    style={{ color: WHITE }}
                    thickness={5}
                    className={classes.circularProgressPadding}
                  />
                  <Typography variant="subtitle1" style={{ color: WHITE, padding: '0 1rem' }}>{`${Math.floor(progress?.percentage)}%`}</Typography>
                </Box>
                <Box className={classes.progressHeadContent}>
                  <Typography variant="subtitle2" style={{ padding: '0 1rem' }}>
                    Replication is in process, please wait till it completes to start another.
                  </Typography>
                  <LinkButton onClick={() => setProgressModal(true)} text="View Status" />
                </Box>
              </Toolbar>
            </Paper>
          )}
          <ReplicationHeader shouldShow={false} />
          <Container className={classes.innerWrapper} disableGutters maxWidth={false}>
            <ReplicationSidebar className={classes.timeline} items={processSteps} />
            <Body
              className={classes.body}
              sourceConfigId={configId}
              destinationConfigId={destinationConfigId}
              onReplicate={onReplicate}
              report={report}
              inProgressReplication={inProgressReplication}
              channelData={channelData}
              checkElucidatValidity={checkValidityOfElucidatPid}
              onProceed={onProceed}
              checkNewConfigIdExists={checkNewConfigIdExists}
              timelineData={timelineData}
              shouldProceed={shouldProceed}
              openModalWithReportData={openModalWithReportData}
              channelLoading={channelLoading}
              channelConfig={channelConfig}
              channelName={channelName}
            />
          </Container>
        </>
      )}
      <ErrorModal open={isErrorModalOpen} onClose={onErrorModalClose} />
      {progress?.report && Object.keys(progress?.report).length > 0 && (
        <ReplicationOverviewModal
          progress={progress}
          programSubType={programSubType}
          open={openReplicationConfirmationModal}
          onClose={() => setReplicationConfirmationModal(false)}
          redirectToreplicatedProgram={redirectToreplicatedProgram}
        />
      )}
      <CustomModal open={progressModal} onClose={() => setProgressModal(false)} breakpoint="sm">
        {loaderDom}
        <DialogActions disableSpacing className={classes.loaderModalFooter}>
          <Typography variant="subtitle2" align="center" style={{ margin: '0 auto', fontColor: MEDIUM_GREY }}>
            You can close this window, the process will continue in the background
          </Typography>
        </DialogActions>
      </CustomModal>
    </Paper>
  );
};

ReplicationPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string.isRequired,
    program_id: PropTypes.number.isRequired,
    docebo_lp_id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ReplicationPage;
