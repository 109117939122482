import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogTitle } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import FormattedTypography from '../../common/FormattedTypography';
import {
  COMPLETED, COMPLETED_WITH_ERRORS, FAILED,
} from '../../../constants';
import { ACCORDION_GREY, MEDIUM_GREY } from '../../../stylesheets/colors';
import ReportContent from '../../ActivityLogs/ReportContent';
import { getStatusLogo } from '../../../helpers/utils';
import StatusModalWrapper from '../../ActivityLogs/StatusModalWrapper';

const useStyles = makeStyles(() => ({
  circularProgressPadding: {
    padding: '1rem',
  },
  logoIcon: {
    '& > :nth-child(1)': {
      margin: '1rem 0 0 0',
    },
  },
  headingWrapper: {
    paddingTop: '0 !important',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
    padding: '0.25rem 0',
  },
  headingBoldContent: {
    color: MEDIUM_GREY,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  contentBox: {
    marginBottom: '0',
  },
}));

const GroupEnrollStatusModal = ({
  open, onClose, modalState, transactionId, getReport, progressData,
  progressErrorMsg, onDownloadLog, configId,
}) => {
  const classes = useStyles();

  const getModalTitle = (reportStatus, fileName) => {
    let header;
    let subTitle;
    if (reportStatus === COMPLETED) {
      header = 'Group Enrollment file was uploaded successfully!';
      subTitle = `Group Enrollment file “${fileName}” was successfully uploaded for ${configId}`;
    } else if (reportStatus === COMPLETED_WITH_ERRORS) {
      header = 'Group enrollment file upload was completed with errors';
      subTitle = `${fileName} was uploaded with errors`;
    } else {
      header = 'Group enrollment file upload failed!';
      subTitle = `Group enrollment file “${fileName}” could not be uploaded.`;
    }
    return {
      header,
      subTitle,
    };
  };

  const applyReportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const {
      status, file_name = '', filename, fail_count, success_count, total_rows, created_at,
    } = result;
    const { header, subTitle } = getModalTitle(status, file_name || filename);

    return {
      component: ReportContent,
      wrapperProps: {
        logo: (
          <img
            src={getStatusLogo(status)}
            alt="status-icon"
            width={50}
            height={50}
            className={classes.circularProgressPadding}
          />
        ),
        heading: (
          <DialogTitle className={classes.headingWrapper}>
            <FormattedTypography
              variant="subtitle1"
              body={header}
              subHeading={(
                <>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={`${classes.headingBoldContent} ${classes.nestedText}`}
                  >
                    {subTitle}
                  </Typography>
                  {status === FAILED ? (
                    <Typography
                      variant="subtitle2"
                      className={`${classes.headingBoldContent} ${classes.infoHelperText}`}
                      align="center"
                    >
                      Please try again later.
                    </Typography>
                  ) : null}
                </>
                  )}
            />
          </DialogTitle>
        ),
        primaryBtnText: 'Download Log',
        breakpoint: 'sm',
        primaryBtnProps: {
          onClick: () => {
            onDownloadLog({
              transaction_id: transactionId,
              created_at,
            });
          },
        },
        logoWrapper: classes.logoIcon,
        contentWrapper: classes.contentBox,
        headingDivider: true,
        contentDivider: true,
        disableGutters: true,
      },
      props: {
        success_count,
        fail_count,
        total_rows,
        status,
        updatedValue: 'groups',
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getModalTitle, getReport, onClose, onDownloadLog, transactionId]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={() => {}}
      reportModal={applyReportModal}
    />
  );
};

GroupEnrollStatusModal.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

GroupEnrollStatusModal.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
  configId: PropTypes.string.isRequired,
};

export default GroupEnrollStatusModal;
