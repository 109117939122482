import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NoChannel from '../../assets/img/noChannel.svg';
import { BLACK } from '../../stylesheets/colors';
import MCKINSEY_SANS from '../../stylesheets/fonts';

const useStyles = makeStyles({
  noContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4.438rem',

    '& img': {
      width: '23rem',
      height: '15rem',
      marginBottom: '2rem',
    },
    '& span': {
      color: BLACK,
      fontSize: '2.5rem',
      fontWeight: 600,
      fontFamily: MCKINSEY_SANS,
      lineHeight: 1.2,
    },
  },
});

const NoContent = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.noContent}>
      <img src={NoChannel} alt="no-channel" />
      {children}
    </div>
  );
};

NoContent.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NoContent;
