import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StuckQueue from '../../assets/icons/stuck-in-queue.svg';
import FormattedTypography from './FormattedTypography';
import InProgressInstructions from '../ActivityLogs/InProgressInstructions';
import { DARK_MEDIUM_GREY } from '../../stylesheets/colors';
import LoaderCancelSection from './LoaderCancelSection';

const useStyles = makeStyles({
  leftSpacing: {
    marginLeft: '0.5rem',
  },
  imageWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    '& > :nth-child(1)': {
      margin: '3rem 0 2rem 0',
    },
  },
  boldText: {
    color: 'black',
  },
  footerText: {
    color: DARK_MEDIUM_GREY,
    marginTop: '2rem',
    padding: 0,
    textAlign: 'center',
  },
});

const EnrollmentStuckQueue = ({
  queue_position, onCancelTransaction, cancellationRequested, setCancellationRequested,
}) => {
  const classes = useStyles();

  return (
    <Container className={classes.headingContainer} data-testid="file-upload-modal">
      <div className={classes.imageWrapper}>
        <img src={StuckQueue} alt="stuck-queue" width="35%" height="auto" />
      </div>
      <FormattedTypography
        variant="subtitle1"
        className={`${classes.leftSpacing} ${classes.boldText}`}
        prefix="Your process is"
        body={queue_position || ''}
        suffix="in the queue"
        suffixClassName={classes.leftSpacing}
        subHeading={cancellationRequested ? 'Cancelling pending tasks...' : ''}
      />
      <InProgressInstructions />
      {onCancelTransaction ? (
        <LoaderCancelSection
          onCancelTransaction={onCancelTransaction}
          cancellationRequested={cancellationRequested}
          setCancellationRequested={setCancellationRequested}
        />
      ) : null}

    </Container>
  );
};

EnrollmentStuckQueue.defaultProps = {
  queue_position: '',
  onCancelTransaction: null,
  cancellationRequested: true,
  setCancellationRequested: () => {},

};
EnrollmentStuckQueue.propTypes = {
  queue_position: PropTypes.string,
  onCancelTransaction: PropTypes.func,
  cancellationRequested: PropTypes.bool,
  setCancellationRequested: PropTypes.func,
};

export default memo(EnrollmentStuckQueue, (
  (prevProps, nextProps) => prevProps.queue_position === nextProps.queue_position));
