import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select/async';
import _debounce from 'lodash.debounce';
import { CancelToken } from 'axios';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import { fetchClientData } from '../../store/actions/async/platformUserAsync';
import { selectComponentStyles, selectStyles } from './AsyncSelectStyles';

let cancelTokenSource = null;
const page = 0;

const useStyles = makeStyles(
  { ...selectComponentStyles },
);

const AsyncSelectClient = ({
  label, placeholder, onChange, maxMenuHeight, selectedClients,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState('');

  const classes = useStyles();

  const debouncedChangeHandler = useMemo(
    () => _debounce((value) => setQuery(value), 1000),
    [],
  );

  const loadOptions = async (inputText, callback) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    cancelTokenSource = CancelToken.source();
    const result = await fetchClientData(
      {
        page_size: 10,
        page,
        search: inputText,
      },
      cancelTokenSource.token,
    );
    if (!result?.success) callback([]);
    const newPrograms = result?.data.map((program) => ({
      value: program.client_id,
      label: (
        <Typography variant="h3" component="span">
          {`${program.client_id} - ${program.client_name}`}
        </Typography>
      ),
    }));
    callback(newPrograms);
  };

  const debouncedFetch = _debounce(loadOptions, 1000);

  const getOptions = (input, callback) => debouncedFetch(input, callback);

  return (
    <Box className={classes.wrapper}>
      <Typography variant="body1" className={classes.labelWrapper}>
        {label}
      </Typography>
      <Box className={classes.selectWrapper}>
        <Select
          defaultOptions
          styles={selectStyles}
          placeholder={placeholder}
          onInputChange={debouncedChangeHandler}
          loadOptions={getOptions}
          name={label}
          onChange={onChange}
          maxMenuHeight={maxMenuHeight}
          isMulti
          isClearable={false}
          menuPosition="fixed"
          value={selectedClients}
        />
      </Box>
    </Box>
  );
};

AsyncSelectClient.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxMenuHeight: PropTypes.number,
  selectedClients: PropTypes.array.isRequired,
};

AsyncSelectClient.defaultProps = {
  maxMenuHeight: 300,
};

export default AsyncSelectClient;
