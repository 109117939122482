import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { DARK_GREY } from '../../stylesheets/colors';

import {
  ROUTE_HOME,
  ROUTE_UPLOAD,
  PROGRAM_CREATION,
  MODIFY_COURSES,
  TEXT_PROGRAM_CREATION,
  TEXT_MODIFY_COURSES,
  TEXT_HOME,
  COURSE_PROD,
} from '../../constants';

const useStyles = makeStyles(() => ({
  breadCrumbText: {
    color: DARK_GREY,
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
      color: 'initial',
    },
    '&:focus': {
      textDecoration: 'none',
      color: 'initial',
    },
  },
}));

const programTypeLookup = {
  [PROGRAM_CREATION]: TEXT_PROGRAM_CREATION,
};

const programSubTypeLookup = {
  [MODIFY_COURSES]: TEXT_MODIFY_COURSES,
};

const CustomBreadCrumb = ({ programType, programSubType }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Link
        className={classes.link}
        color="inherit"
        component={RouterLink}
        to={`/${ROUTE_HOME}/${COURSE_PROD}`}
        replace
      >
        {TEXT_HOME}
      </Link>
      <Link
        className={classes.link}
        color="inherit"
        component={RouterLink}
        to={`/${ROUTE_UPLOAD}/${COURSE_PROD}/${programType}`}
        replace
      >
        {programTypeLookup[programType]}
      </Link>
      <Typography color="textPrimary">{programSubTypeLookup[programSubType]}</Typography>
    </Breadcrumbs>
  );
};

CustomBreadCrumb.propTypes = {
  programType: PropTypes.string.isRequired,
  programSubType: PropTypes.string.isRequired,
};

export default CustomBreadCrumb;
