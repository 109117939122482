import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  noResultsWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '2rem 0px',
  },
  noContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',

    '& img': {
      width: '23rem',
      height: '15rem',
      marginBottom: '2rem',
    },
  },
  noResultsText: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: DARK_GREY,
    fontWeight: 'bold',
    lineHeight: '1.21',
    textTransform: 'initial',
    marginBottom: '1rem',
  },
});

const NoDataComponent = ({
  imgSrc, primaryText, secondaryText, secondaryTextCls,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.noContent}>
      <img src={imgSrc} alt="no-channel" />
      {primaryText && <Typography variant="h4" component="span" className={classes.noResultsText}>{primaryText}</Typography>}
      {secondaryText && <Typography className={secondaryTextCls} variant="subtitle1">{secondaryText}</Typography>}
    </Box>
  );
};
NoDataComponent.defaultProps = {
  secondaryTextCls: '',
  secondaryText: '',
};

NoDataComponent.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  secondaryTextCls: PropTypes.string,
};

export default memo(NoDataComponent, ((prevProps, nextProps) => (
  prevProps.imgSrc === nextProps.imgSrc && prevProps.primaryText === nextProps.primaryText
  && prevProps.secondaryText === nextProps.secondaryText
)));
