import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Header from '../common/Header';
import FlyoutMenu from '../common/FlyoutMenu';

import { DARK_MEDIUM_GREY, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const content = [
  'Activate - the selected test user(s) will be activated',
  'Deactivate - the selected test user(s) will be de-activated. Deactivate will NOT unenroll from any program nor will it be deleted',
  'Enroll to other programs - the selected test user(s) will be enrolled to additional programs (maximum of 5) ',
  'Reset password - the selected test user(s) will have their password reset to default password',
  'Edit language preference - this will allow you to set the preferred language for the selected test user(s)',
];

const useStyles = makeStyles({
  menuSubtitleMedGrey: {
    color: DARK_MEDIUM_GREY,
  },
  menuItems: {
    whiteSpace: 'pre-line',
    lineHeight: 1.5,
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  listItemIcon: {
    minWidth: '2.5rem',
    marginTop: '0.8rem',
    alignSelf: 'flex-start',
    color: MODERATE_DARK_GREY,
  },
  listRecordIcon: {
    fontSize: '0.5625rem',
  },
});

const TestUserHeader = ({ className, shouldShow }) => {
  const classes = useStyles();
  return (
    <Header
      className={className}
      heading="Test User Management"
      subHeading="Create and manage test users, add them to multiple programs and more."
    >
      <FlyoutMenu
        buttonTitle="Instructions"
        subTitle="Different actions that you can take at user or bulk level (selected users):"
        initialState={shouldShow}
        classes={{
          subtitle: classes.menuSubtitleMedGrey,
        }}
      >
        {content.map((item) => (
          <MenuItem className={classes.menuItems} key={item}>
            <ListItemIcon className={classes.listItemIcon}>
              <FiberManualRecordIcon className={classes.listRecordIcon} />
            </ListItemIcon>
            <Typography variant="inherit">{item}</Typography>
          </MenuItem>
        ))}
      </FlyoutMenu>
    </Header>
  );
};

TestUserHeader.defaultProps = {
  className: '',
};

TestUserHeader.propTypes = {
  className: PropTypes.string,
  shouldShow: PropTypes.bool.isRequired,
};

export default memo(TestUserHeader, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
    && prevProps.shouldShow === nextProps.shouldShow
));
