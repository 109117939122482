import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { SWITCH_GREY, ACCORDION_GREY } from '../../stylesheets/colors';
import CustomPopover from '../common/CustomPopover';

const useStyles = makeStyles(() => ({
  navigationRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.25rem',
  },
  navigate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    width: '60%',
    '& > svg': {
      marginRight: '1rem',
      fontSize: '2rem',
    },
    '& > h6': {
      fontWeight: 'bold',
    },
  },
  addSessionBtn: {
    marginLeft: '1.25rem',
    '& svg': {
      transform: 'rotate(-90deg)',
      marginLeft: '0.5rem',
    },
    '&.active svg': {
      transform: 'rotate(90deg)',
    },
  },
  link: {
    fontSize: '1.5rem',
    paddingRight: '2rem',
    display: 'inline-flex',
    alignItems: 'flex-start',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  loaderIconCls: {
    padding: '1rem',
    marginBottom: '0 !important',
    animation: '$spinner 1s linear infinite',
  },
  syncButtonCls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '2rem',
    borderLeft: 'thin solid #e6e6e6',
  },
  markUserWrapper: {
    fontSize: '1.5rem',
    display: 'inline-flex',
    alignItems: 'flex-start',
  },
  markUserButton: {
    padding: '0.5rem 1.005rem',
  },
  disabledAddBtn: {
    '&:disabled': {
      backgroundColor: SWITCH_GREY,
      color: ACCORDION_GREY,
    },
  },
}));

const CommonButton = ({
  onClick, anchorEl, disabled, text,
}) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={`${classes.addSessionBtn} ${disabled ? classes.disabledAddBtn : ''}  ${anchorEl ? ' active' : ''}`}
      variant="contained"
      color="primary"
      disabled={disabled}
    >
      <Typography>{text}</Typography>
      <KeyboardArrowLeftIcon />
    </Button>
  );
};

CommonButton.defaultProps = {
  anchorEl: null,
  disabled: false,
};

CommonButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({}),
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

const WorkshopManagementSearch = ({
  goBack,
  courseName,
  onAddSessionClick,
  onMarkUserClick,
  disabled,
  sessionsAndEventsExists,
  setShowMarkUsersCompleteModal,
}) => {
  const classes = useStyles();

  const actionMenu = [
    {
      title: 'Add new session',
      onClick: onAddSessionClick,
    },
  ];

  const markUsersActionMenu = [
    {
      title: 'All users',
      onClick: () => { setShowMarkUsersCompleteModal(true); },
    },
    {
      title: 'Set of users',
      onClick: onMarkUserClick,
    },
  ];

  return (
    <div className={classes.navigationRow}>
      <div
        className={classes.navigate}
        onClick={goBack}
        onKeyDown={() => { }}
        role="button"
        tabIndex="0"
      >
        <KeyboardArrowLeftIcon />
        <Typography variant="subtitle1">{courseName}</Typography>
      </div>
      <div>
        <div className={classes.markUserWrapper}>
          <CustomPopover
            additionalClass={classes.markUserButton}
            list={markUsersActionMenu}
            disabled={!sessionsAndEventsExists || disabled}
            button={(e) => CommonButton({ ...e, text: 'Mark Users as Complete' })}
          />
        </div>
        <CustomPopover
          list={actionMenu}
          disabled={disabled}
          button={(e) => CommonButton({ ...e, text: 'Add' })}
        />
      </div>
    </div>
  );
};
WorkshopManagementSearch.defaultProps = {
  disabled: false,
};

WorkshopManagementSearch.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  goBack: PropTypes.func.isRequired,
  onAddSessionClick: PropTypes.func.isRequired,
  courseName: PropTypes.string.isRequired,
  onMarkUserClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  sessionsAndEventsExists: PropTypes.bool.isRequired,
  setShowMarkUsersCompleteModal: PropTypes.func.isRequired,
};

export default WorkshopManagementSearch;
