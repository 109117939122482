import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import MetaDataLogs from './MetaDataLogs/index';
import { MODERATE_DARK_GREY, MODERATE_LIGHT_GREY, DARK_MEDIUM_GREY } from '../../stylesheets/colors';
import GroupImg from '../../assets/img/group.svg';
import CheckImg from '../../assets/img/check.svg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    padding: '3rem 0',
  },
  logsContainer: {
    borderLeft: `1px solid ${MODERATE_LIGHT_GREY}`,
    paddingLeft: '2rem',
  },
  textColor: {
    color: MODERATE_DARK_GREY,
  },
  heading: {
    color: DARK_MEDIUM_GREY,
    fontWeight: 'normal',
    marginTop: '1rem',
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '3rem 14rem 3rem 6rem',
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    boxShadow: '0px -1px 10px -5px rgb(0 0 0 / 56%)',
    fontSize: '1.25rem',
  },
  imgWrapper: {
    paddingRight: '4rem',
    borderRight: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  contentWrapper: {
    paddingLeft: '4rem',
  },
  listHolder: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '1rem 0',
    '& > div': {
      paddingLeft: '1rem',
    },
  },
});

const MetaDataGeneral = ({
  metadataReport,
  fetchMetadataActivity,
  onDownloadLog,
}) => {
  const classes = useStyles();
  useEffect(() => {
    fetchMetadataActivity();
  }, [fetchMetadataActivity]);

  return (
    <Box className={classes.wrapper}>
      <Grid container>
        <Grid item md={9}>
          <Typography variant="body1" className={classes.textColor}>
            Metadata Management allows the users to update the
            Additional Fields at different levels represented via tabs.
          </Typography>

          <Box sx={{ pr: 3, mt: 4 }}>
            <div className={classes.infoWrapper}>
              <div className={classes.imgWrapper}>
                <img src={GroupImg} alt="group" />
              </div>

              <div className={classes.contentWrapper}>
                <span>
                  Please note the following things -
                </span>

                <div className={classes.listHolder}>
                  <div className={classes.listItem}>
                    <span><img src={CheckImg} alt="check" /></span>
                    <div>
                      The metadata can be updated for a single
                      entity or for multiple entities in bulk.
                    </div>
                  </div>
                  <div className={classes.listItem}>
                    <span><img src={CheckImg} alt="check" /></span>
                    <div>
                      The updates would be done on both Ops
                      Platform DB &amp; DOCEBO (wherever applicable).
                    </div>
                  </div>
                  <div className={classes.listItem}>
                    <span><img src={CheckImg} alt="check" /></span>
                    <div>The scope of updates is limited to this Program only.</div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item md={3} className={classes.logsContainer}>
          <Typography className={classes.heading} component="span">Activity Log</Typography>
          <MetaDataLogs report={metadataReport} onDownloadLog={onDownloadLog} />
        </Grid>
      </Grid>
    </Box>
  );
};

MetaDataGeneral.defaultProps = {
  // activeLanguages: [],
  // metaData: {
  //   slug: undefined,
  //   value: undefined,
  // },
  metadataReport: {
    components: [],
    enrollments: [],
    general: {},
  },
};

MetaDataGeneral.propTypes = {
  // activeLanguages: PropTypes.array,
  // onUpdateMetaInfo: PropTypes.func.isRequired,
  // metaData: {
  //   slug: PropTypes.string,
  //   value: PropTypes.strin,
  // },
  metadataReport: PropTypes.shape({
    components: PropTypes.array,
    enrollments: PropTypes.array,
    users: PropTypes.array,

  }),
  fetchMetadataActivity: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
};

export default MetaDataGeneral;
