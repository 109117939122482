import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import AlertBarWithAction from '../common/AlertBarWithAction';

const useStyles = makeStyles(() => ({
  alertBottomPadding: {
    marginBottom: '1.5rem',
    padding: '0.5rem 0',
    '& div h6': {
      whiteSpace: 'pre-wrap',
    },
  },
}));

const AlertBar = ({ message }) => {
  const classes = useStyles();

  return (
    <AlertBarWithAction
      variant="alertError"
      percentage={null}
      labelText={message}
      actionButtonText=""
      customStyle={classes.alertBottomPadding}
    />
  );
};

const LimitExceedValidationMsg = ({
  componentCountExceed, eachComponentTmCountExceed, totalLoCountExceed, exceedLimitComponents,
}) => {
  const genericMessage = 'Replication supports a maximum of 30 components and 180 Learning Objects (Components + Training Materials) with no more than 10 Training materials per Component.';

  if (componentCountExceed && eachComponentTmCountExceed && totalLoCountExceed) {
    return (
      <AlertBar message={genericMessage} />
    );
  }
  if (componentCountExceed && eachComponentTmCountExceed) {
    return (
      <AlertBar message={`More than 30 components are selected and ${exceedLimitComponents} components have more than 10 Training Materials selected.\n${genericMessage}`} />
    );
  }
  if (eachComponentTmCountExceed && totalLoCountExceed) {
    return (
      <AlertBar message={`${exceedLimitComponents} components have more than 10 Training Materials selected and more than 180 Learning Objects are selected.\n${genericMessage}`} />
    );
  }
  if (componentCountExceed && totalLoCountExceed) {
    return (
      <AlertBar message={`More than 30 components and more than 180 Learning Objects are selected.\n${genericMessage}`} />
    );
  }
  if (componentCountExceed) {
    return (
      <AlertBar message={`More than 30 components are selected.\n ${genericMessage}`} />
    );
  }
  if (eachComponentTmCountExceed) {
    return (
      <AlertBar message={`${exceedLimitComponents} components have more than 10 Training Materials selected.\n${genericMessage}`} />
    );
  }
  if (totalLoCountExceed) {
    return (
      <AlertBar message={`More than 180 Learning Objects are selected.\n${genericMessage}`} />
    );
  }
  return <></>;
};

export default LimitExceedValidationMsg;

LimitExceedValidationMsg.propTypes = {
  componentCountExceed: PropTypes.bool.isRequired,
  eachComponentTmCountExceed: PropTypes.bool.isRequired,
  totalLoCountExceed: PropTypes.bool.isRequired,
  exceedLimitComponents: PropTypes.string.isRequired,
};

AlertBar.propTypes = {
  message: PropTypes.string.isRequired,
};
