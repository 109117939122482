import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _findLastIndex from 'lodash/findLastIndex';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Container from '@material-ui/core/Container';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography/Typography';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { BLACK, MODERATE_LIGHT_GREY } from '../../stylesheets/colors';

const leftBuffer = '6.5rem';
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem 0 0 0',
  },
  heading: {
    color: BLACK,
    fontWeight: 'bold',
    paddingLeft: '4rem',
  },
  timeline: {
    padding: 0,
    margin: 0,
  },
  item: {
    alignItems: 'center',
    paddingLeft: '4rem',
    '&::before': {
      flex: 0,
      padding: 0,
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
      bottom: '3px',
      width: `calc(100% - ${leftBuffer})`,
      left: leftBuffer,
    },
    '&:last-child::after': {
      left: 0,
      width: '100%',
    },
  },
  timelineSeperator: {
    minHeight: '100%',
  },
  timelineDot: {
    position: 'relative',
    marginTop: '29px',
    marginBottom: '0px',
  },
  timelineConnector: {
    marginTop: '35px',
    height: '70px',
    position: 'absolute',
  },
  timelineContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '0px',
    paddingBottom: '0px',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  primaryColorBg: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const SubMenu = ({ className, heading, items }) => {
  const classes = useStyles();
  const activeIdx = _findLastIndex(items, { completed: true });
  return (
    <Container className={`${classes.wrapper} ${className}`}>
      <Typography className={classes.heading}>{heading}</Typography>
      <Timeline className={classes.timeline}>
        {items.map((item, idx) => (
          <TimelineItem key={item.label} className={classes.item}>
            <TimelineSeparator className={classes.timelineSeperator}>
              {idx < items.length - 1 && <TimelineConnector className={`${classes.timelineConnector} ${items[idx + 1].completed ? classes.primaryColorBg : ''}`} />}
              <TimelineDot color={item.completed ? 'primary' : 'grey'} className={classes.timelineDot} />
            </TimelineSeparator>
            <TimelineContent
              className={`${classes.timelineContent} 
              ${idx === activeIdx ? classes.primaryColor : ''}`}
            >
              <Typography variant="body1">{item.label}</Typography>
              {idx === activeIdx && <ArrowForwardIcon />}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Container>
  );
};

SubMenu.defaultProps = {
  heading: '',
  className: '',
};

SubMenu.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      completed: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default memo(SubMenu, (
  (prevProps, nextProps) => prevProps.heading === nextProps.heading
    && prevProps.className === nextProps.className && _isEqual(prevProps.items, nextProps.items)
));
