import { makeStyles } from '@material-ui/core';
import { LIGHT_PURPLE, DARK_GREY, WHITE } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  bannerBox: {
    display: 'flex',
    backgroundColor: LIGHT_PURPLE,
    fontWeight: 'bold',
    alignItems: 'flex-start',
    padding: '20px',
    color: DARK_GREY,
    marginTop: '20px',
  },
  infoIcon: {
    width: 20,
    backgroundColor: DARK_GREY,
    padding: '4px',
    color: WHITE,
    textAlign: 'center',
  },
  content: {
    paddingLeft: '10px',
  },
  bannerUrl: {
    color: DARK_GREY,
    textDecoration: 'underline',
    '&:hover': {
      color: DARK_GREY,
    },
  },
}));

export default useStyles;
