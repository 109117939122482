import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { useFormik } from 'formik';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import ConfirmationModal from '../common/ConfirmationModal';
import FormattedTypography from '../common/FormattedTypography';
import LabledTextField from '../common/LabledTextField';
import { ERROR_RED, MODERATE_DARK_GREY, SUCCESS_GREEN } from '../../stylesheets/colors';
import { savePasswordInfo } from '../../store/actions/async/platformUserAsync';
import { getErrorMessage } from '../../helpers/apiHelper';
import ErrorRoundIcon from '../../assets/icons/cross-outline2.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
  },
  width60: {
    width: '60%',
  },
  greyText: {
    color: MODERATE_DARK_GREY,
    fontWeight: 600,
    fontSize: '1.25rem',
  },
  modalHeight: {
    minHeight: '30rem',
  },
  errorBlock: {
    marginBottom: '1.2rem',
  },
  modalStyle: {
    maxWidth: '80rem',
  },
  errorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.7rem',

    '& img': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '1rem',
    },
  },
});

const EditUserPasswordModal = ({
  open, onClose, data, loadingText, setSnackbar, setAlertBarConfig,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const { userId } = data;
  // eslint-disable-next-line
  const specialCharsValidation = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const hasNumberValidation = /\d/;

  const validate = (values) => {
    const errors = {
      confirmPasswordError: true, // true means error exists
      min_characters: true,
      no_special_char: true,
      no_number: true,
    };

    if (values.password && values.confirmPassword === values.password) {
      delete errors.confirmPasswordError;
    }

    if (values.password.length >= 12) {
      delete errors.min_characters;
    }
    if (specialCharsValidation.test(values.password)) {
      delete errors.no_special_char;
    }
    if (hasNumberValidation.test(values.password)) {
      delete errors.no_number;
    }

    return errors;
  };

  const {
    values, handleSubmit, handleChange, errors, resetForm,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validate,
    initialValues: { password: '', confirmPassword: '' },
    initialErrors: {
      confirmPasswordError: true, // true means error exists
      min_characters: true,
      no_special_char: true,
      no_number: true,
    },
    onSubmit: (payload) => {
      // eslint-disable-next-line no-use-before-define
      onSave(payload);
    },
  });

  useEffect(() => {
    if (open) {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const {
    confirmPasswordError, min_characters, no_special_char, no_number,
  } = errors;

  const onSave = async (formData) => {
    try {
      setIsLoading(true);
      const { password } = formData;

      const saveResp = await savePasswordInfo({ password }, userId);
      if (saveResp?.success) {
        onClose();
        setAlertBarConfig({
          labelText: 'Password saved successfully',
          variant: 'success',
          open: true,
        });
      } else {
        setSnackbar({ open: true, message: getErrorMessage({}, saveResp?.message), severity: 'error' });
      }
    } catch (err) {
      setSnackbar({ open: true, message: getErrorMessage(err), severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const resetFormBeforeClose = () => {
    onClose();
  };

  const getErrorBlock = useCallback((showErrorIcon, errorMsg) => (
    <Box className={classes.errorRow}>
      {showErrorIcon ? <img src={ErrorRoundIcon} alt="error-cross" /> : <img src={CheckRoundIcon} alt="success-tick" /> }
      <Typography style={{ color: showErrorIcon ? ERROR_RED : SUCCESS_GREEN }} variant="body1">{errorMsg}</Typography>
    </Box>
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const handlePasswordChange = useCallback((e) => {
    if (e.target.value.indexOf(' ') >= 0) { // restrict space
      return;
    }
    handleChange(e);
  }, [handleChange]);

  return (
    <ConfirmationModal
      open={open}
      isLoading={isLoading}
      onClose={resetFormBeforeClose}
      breakpoint="md"
      minHeightClass={classes.modalHeight}
      loadingText={loadingText}
      title={(<FormattedTypography body="Reset Password" />)}
      modalStyle={classes.modalStyle}
      dialogContentClass={classes.width60}
      content={(
        <Container className={classes.wrapper}>
          <LabledTextField
            label="New Password"
            placeholder={'Type a new password'}
            name="password"
            labelClass={classes.greyText}
            value={values.password}
            onChange={(e) => { handlePasswordChange(e); }}
          />
          <Box className={classes.errorBlock}>
            {getErrorBlock(min_characters, 'Password must be minimum 12 characters')}
            {getErrorBlock(no_special_char, 'Password must have at least one special character')}
            {getErrorBlock(no_number, 'Password must have at least one numeric value')}
          </Box>

          <LabledTextField
            label="Re-Type Password"
            placeholder={'Re-Type your password'}
            name="confirmPassword"
            labelClass={classes.greyText}
            value={values.confirmPassword}
            onChange={(e) => { handlePasswordChange(e); }}
          />
          <Box className={classes.errorBlock}>
            <Box className={classes.errorRow}>
              {getErrorBlock(confirmPasswordError, 'Password entered does not match the new password')}
            </Box>
          </Box>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={resetFormBeforeClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={min_characters || no_number || no_special_char || confirmPasswordError}
          >
            Reset Password
          </Button>
        </>
      )}
    />
  );
};

EditUserPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  loadingText: PropTypes.string.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  setAlertBarConfig: PropTypes.func.isRequired,
};

export default EditUserPasswordModal;
