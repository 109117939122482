import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import CopyComponentReportOverview from './CopyComponentReportOverview';
import CustomUploadModal from '../common/CustomUploadModal';
import FormattedTypography from '../common/FormattedTypography';
import { FOR_REPORT } from '../../constants';
import AlertIcon from '../../assets/icons/alert-status-icon.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';

const CopyComponentReportModal = ({
  transactionId, status, open, onClose, onDownloadLog, getReport,
}) => {
  const STATUS = {
    COMPLETED: {
      src: CheckRoundIcon,
      heading: 'Copy of component shell created successfully!',
      getSubHeading: (componentCode, configId) => (`A copy of ${componentCode} was successfully created and linked to the learning plan ${configId}`),
      primaryBtnText: 'Close',
    },
    COMPLETED_WITH_ERRORS: {
      src: AlertIcon,
      heading: 'Copy of component shell completed with errors',
      getSubHeading: (componentCode, configId) => (`A copy of ${componentCode} was created but failed to link to the learning plan ${configId}. Please contact support or link the component from Docebo's Content Management section.`),
      primaryBtnText: 'Download Log',
    },
    FAILED: {
      src: ErrorRoundIcon,
      heading: 'Copy of component shell failed!',
      getSubHeading: (componentCode) => (`Copy of the component shell ${componentCode} could not be created. Please try again later.`),
      primaryBtnText: 'Download Log',
    },
  };

  const [modalState, setModalState] = useState(null);
  const [modalSchema, setModalScheme] = useState({
    REPORT: {
      component: CopyComponentReportOverview,
      wrapperProps: {
        logo: null,
        heading: null,
        breakpoint: 'md',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: {
        steps: [],
      },
    },
  });

  const preClose = () => {
    setModalState(null);
    onClose();
  };

  const checkEvent = useCallback((result) => {
    if (status === 'COMPLETED') {
      return preClose();
    }
    return onDownloadLog(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, onDownloadLog]);

  const applyReportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const configId = result.lp_config_id;
    const courseName = result.course_name;
    const courseCode = result.course_code;
    batchUpdates(() => {
      setModalState('REPORT');
      setModalScheme((schema) => ({
        ...schema,
        REPORT: {
          ...schema.REPORT,
          wrapperProps: {
            ...schema.REPORT.wrapperProps,
            disableGutters: true,
            logo: (
              <Box>
                <img src={STATUS?.[status]?.src} alt="copied-status" width={60} height={60} />
              </Box>
            ),
            heading: (
              <DialogTitle style={{ paddingTop: 0 }}>
                <FormattedTypography
                  variant="subtitle1"
                  body={STATUS?.[status]?.heading}
                  subHeading={STATUS?.[status]
                    ?.getSubHeading(courseCode, configId)}
                />
              </DialogTitle>
            ),
            headingDivider: status !== 'FAILED',
            contentDivider: status !== 'FAILED',
            primaryBtnText: STATUS?.[status]?.primaryBtnText,
            primaryBtnProps: {
              ...schema.REPORT.wrapperProps.primaryBtnProps,
              onClick: () => checkEvent({ transaction_id: transactionId }),
            },
            breakpoint: 'sm',
          },
          props: {
            ...schema.REPORT.props,
            status,
            componentTitle: courseName,
            componentCode: courseCode,
          },
        },

      }));
    });
  }, [getReport, transactionId, STATUS, status, checkEvent]);

  useEffect(() => {
    if (FOR_REPORT.includes(status)) {
      applyReportModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, status]);

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  return (
    <>
      {ModalComponent && (
        <CustomUploadModal
          open={open}
          onClose={preClose}
          {...modalSchema[modalState].wrapperProps}
        >
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
    </>
  );
};

CopyComponentReportModal.propTypes = {
  transactionId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  pollProgressValue: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  updatePollProgress: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
};

export default CopyComponentReportModal;
