import FileDownload from 'js-file-download';

import config from '../../config';
import request from '../../helpers/apiHelper';
import {
  COURSE_OPS,
  COURSE_PROD,
  PROGRAM_CREATION,
  BULK_UNENROLLMENT,
  AUTO_ENROLLMENT,
  WEBINAR_MANAGEMENT,
  CLIENT_MASTER,
} from '../../constants';

export const uploadXlsx = async (automationType, programSubType, data) => {
  const isVersionOld = sessionStorage.getItem('vn');
  let automationRoute = config.ROUTES.AUTOMATION;

  if (programSubType === AUTO_ENROLLMENT) {
    automationRoute = !isVersionOld ? config.ROUTES.AUTOMATION.replace('automation', 'v2/automation') : config.ROUTES.AUTOMATION;
  }
  let url = `${automationRoute}/${automationType}/${programSubType}`;
  if (programSubType === WEBINAR_MANAGEMENT) {
    url += `/${data.get('program_id')}`;
  }
  const method = {
    [COURSE_PROD]: { [PROGRAM_CREATION]: 'POST' },
    [COURSE_OPS]: { [BULK_UNENROLLMENT]: 'POST', [AUTO_ENROLLMENT]: 'POST', [WEBINAR_MANAGEMENT]: 'PUT' },
  }[automationType][programSubType];
  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const downloadFile = async (transactionId, configId, programSubType, createdAt, query,
  isReplication = false) => {
  const url = `${isReplication ? config.ROUTES.REPLICATION_DOWNLOAD_XLSX : config.ROUTES.DOWNLOAD_XLSX}/${transactionId}${query || ''}`;

  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;

  const createdAtFormated = new Date(createdAt).toDateString();

  // https://stackoverflow.com/a/41940307/1217998
  if (configId && programSubType) {
    FileDownload(data, `${configId}: ${programSubType} - ${createdAtFormated}.xlsx`);
  } else {
    FileDownload(data, `${createdAtFormated}.xlsx`);
  }

  return result;
};

export const validateXLSXApiCall = async (automationType, programSubType, data) => {
  const url = `${config.ROUTES.VALIDATE_XLSX}/${automationType}/${programSubType}`;
  const options = { url, method: 'POST', data };
  try {
    const result = await request(options);
    return result.data;
  } catch (e) {
    if (e.response.status === 400) {
      return e.response.data;
    }
    return { errors: [{ message: e.toString() }] };
  }
};

export const getProgramInfo = async (programId) => {
  const url = `${config.ROUTES.GET_REPORT}/program/${programId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result.data;
};

export const ignoreErrorsForTransaction = async (transactionId) => {
  const url = `${config.ROUTES.MARK_FOR_COMPLETION}/${transactionId}`;
  const options = { url, method: 'POST' };
  const result = await request(options);

  return result;
};

export const getProgress = async (transactionId, signal = undefined) => {
  const url = `${config.ROUTES.GET_PROGRESS}/${transactionId}`;
  const options = { url, method: 'GET', signal };
  const result = await request(options);

  return result;
};

export const getReport = async (transactionId) => {
  const url = `${config.ROUTES.GET_REPORT}/${transactionId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);

  return result.data;
};

export const createProgramLinks = async (programId, linkObj) => {
  const url = `${config.ROUTES.PROGRAM_LINKS}/${programId}`;
  const links = Object.keys(linkObj).map((k) => ({ link_type: k, link_url: linkObj[k] }));
  const data = { links };
  const options = { url, data, method: 'POST' };
  const result = await request(options);
  return result;
};

export const cancelTransaction = async (transactionId) => {
  const url = `${config.ROUTES.CANCEL_TRANSACTION}/${transactionId}`;
  const options = { url, method: 'POST' };
  const result = await request(options);
  return result;
};

export const getExperienceTypeOptions = async () => {
  const url = `${config.ROUTES.GET_USER_FIELD}?field_name=Primary Experience`;
  const options = { url, method: 'GET' };
  const result = await request(options);

  return result;
};

export const fetchActiveLanguages = async () => {
  const url = `${config.ROUTES.SERVICE}/active_languages`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const uploadComponentXlsx = async (data) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/component/trigger_bulk_edit`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const checkValidityOfElucidatPid = (elucidatPid, cancelToken) => {
  const url = `${config.ROUTES.REPLICATION_ELUCIDAT_CHECK}/${elucidatPid}`;
  const options = { url, method: 'GET', cancelToken };
  return request(options);
};

export const getMetaDataForLP = async (lpdata) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/lp/bulk_edit`;
  const options = {
    url, method: 'PUT', data: lpdata, responseType: 'blob',
  };
  const response = await request(options);
  const { data } = response;
  FileDownload(data, 'component_metadata.xlsx');
};

export const getPortfolioOfferingProgram = async (requestParam) => {
  let url = `${config.ROUTES.MASTER_DATA_PORTFOLIO}/offerings`;
  if (requestParam) {
    const key = Object.keys(requestParam);
    url += `?${key[0]}=${requestParam[key[0]]}`;
  }
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);
  return result;
};

export const downloadClientMaster = async (createdAt) => {
  const url = `${config.API_SERVER}/master/download_client_data`;

  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;

  const createdAtFormated = new Date(createdAt).toDateString();

  FileDownload(data, `${CLIENT_MASTER} - ${createdAtFormated}.xlsx`);

  return result;
};
