import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import CustomSelect from '../../common/CustomSelect';
import FormattedTypography from '../../common/FormattedTypography';
import {
  BACKDROP_GREY,
  DARK_MEDIUM_GREY,
  WHITE,
  MODERATE_DARK_GREY,
  ERROR_RED,
} from '../../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  sideBarWrapper: {
    display: 'flex',
    backgroundColor: BACKDROP_GREY,
    flexDirection: 'column',
    height: '100vh',
    padding: '2.5rem 2.5rem 0 2.5rem',
  },
  innerWrapper: {
    paddingBottom: '2rem',
  },
  bold: {
    fontWeight: '900',
  },
  radioLableContiner: {
    paddingTop: '1rem',
  },
  radioLableSubtext: {
    fontSize: '0.875rem',
    color: DARK_MEDIUM_GREY,
  },
  divider: {
    background: WHITE,
    margin: 0,
  },
  enrollmentDivider: {
    margin: '1rem 0',
  },
  textField: {
    width: '100%',
  },
  searchBox: {
    margin: '0 0 1rem 2.5rem',
  },
  helperTextFirst: {
    marginLeft: '0',
    paddingTop: '1.37rem',
    paddingBottom: '0.5rem',
    color: MODERATE_DARK_GREY,
  },
  helperText: {
    marginLeft: '0',
    paddingTop: '1rem',
  },
  configId: {
    paddingRight: '6px',
  },
  closeIcon: {
    position: 'unset',
  },
  eeSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.375rem 0',
  },
  enrollmentWaveRow: {
    marginBottom: '1rem',
    width: '20rem',
  },
  red: {
    color: ERROR_RED,
  },
  labelWrapper: {
    margin: '0.5rem 0',
    display: 'flex',
    alignItems: 'center',
  },
  leftSidebar: {
    transition: ({ transitionDuration }) => theme.transitions.create(['flex', 'transform'], {
      duration: transitionDuration || theme.transitions.duration.standard,
    }),
  },
  cohortDetail: {
    '& > div': {
      marginBottom: '1rem',
    },
  },
  prefixClient: {
    width: '9rem',
  },
  bodyClient: {
    width: 'calc(100% - 9rem)',
    maxHeight: '40rem',
    overflow: 'auto',
  },
  prefixEcType: {
    width: '9rem',
  },
  bodycohortType: {
    marginLeft: '5px',
    width: 'calc(100% - 9rem)',
  },
  errorCohortText: {
    color: '#d51f31',
  },
}));

const EnrollmentLeftBar = ({
  className, cohortList,
  setEnrollmentWave, navigateToEnrollmentCohortPage, extendedEnterprise, enrollmentWave,
}) => {
  const transitionDuration = 500;
  const classes = useStyles({ extendedEnterprise, transitionDuration });

  return (
    <Grid className={`${className} ${classes.sideBarWrapper}`}>
      <Grid item className={classes.innerWrapper}>
        <Typography className={classes.bold} variant="subtitle1">
          Step 1
        </Typography>
        <div className={classes.enrollmentWaveRow}>
          <CustomSelect
            options={cohortList}
            placeholder={(cohortList[0] && cohortList[0].label === 'No cohort available') ? 'No Cohort Available' : 'Select a cohort'}
            customOption={(cohortList[0] && cohortList[0].label === 'No cohort available') ? 'Create a cohort' : ''}
            customOptionHandler={navigateToEnrollmentCohortPage}
            label={(
              <Box className={classes.labelWrapper}>
                <Typography variant="body1" component="span">
                  Select the Enrollment Cohort
                </Typography>
                <span className={classes.red}>*</span>
              </Box>
)}
            onChange={(e) => setEnrollmentWave(e)}
          />
        </div>
        {
          enrollmentWave && (
            <div className={classes.cohortDetail}>
              <Box>
                <FormattedTypography
                  prefix="Enrollment Cohort Type :"
                  prefixClassName={classes.prefixEcType}
                  body={enrollmentWave.experience_type || 'Type not mapped'}
                  variant="body1"
                  justifyContent="left"
                  className={`${classes.bodycohortType} ${!enrollmentWave.experience_type ? classes.errorCohortText : ''}`}
                />
              </Box>
              <Box>
                <FormattedTypography
                  prefix="Client Mapped :"
                  prefixClassName={classes.prefixClient}
                  body={enrollmentWave.clientId.length
                    ? enrollmentWave.clientId.map(
                      (item, index, arr) => <p key={item}>{`${item.client_id} - ${item.client_name}${index !== arr.length - 1 ? ';' : ''}`}</p>,
                    )
                    : 'No Client mapped to the Enrollment Cohort'}
                  variant="body1"
                  justifyContent="left"
                  className={`${classes.bodyClient} ${!enrollmentWave.clientId.length ? classes.errorCohortText : ''}`}
                />
              </Box>
            </div>
          )
        }
      </Grid>
    </Grid>
  );
};

EnrollmentLeftBar.defaultProps = {
  className: '',
};

EnrollmentLeftBar.propTypes = {
  className: PropTypes.string,
  userSelection: PropTypes.shape({
    type: PropTypes.string.isRequired,
    configId: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]).isRequired,
  }).isRequired,
  cohortList: PropTypes.array.isRequired,
  setEnrollmentWave: PropTypes.func.isRequired,
  navigateToEnrollmentCohortPage: PropTypes.func.isRequired,
  extendedEnterprise: PropTypes.string.isRequired,
  enrollmentWave: PropTypes.object.isRequired,
};

export default memo(EnrollmentLeftBar, (prevProps, nextProps) => (
  prevProps.userSelection === nextProps.userSelection
  && prevProps.cohortList === nextProps.cohortList
  && prevProps.extendedEnterprise === nextProps.extendedEnterprise
  && prevProps.enrollmentWave === nextProps.enrollmentWave
));
