import React from 'react';
import PropTypes from 'prop-types';
import {
  useField,
} from 'formik';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MODERATE_LIGHT_GREY } from '../../../stylesheets/colors';
import HyperlinkButton from '../../common/HyperlinkButton';

const useStyles = makeStyles({
  tableHeadRow: {
    alignItems: 'center',
    padding: '0.5 0',
    marginBottom: '1rem',
    backgroundColor: MODERATE_LIGHT_GREY,
  },
  checkboxFlexBasis: {
    flexBasis: '4%',
  },
});

const BulkScormReleaseTableHead = ({
  expandAll, setExpandAll, values,
}) => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [selectAllField, selectAllMeta, selectAllHelpers] = useField({
    name: 'selectAll',
    type: 'checkbox',
  });

  const { isAllCourseSelected, isNoneCourseSelected } = values;

  return (
    <Grid container direction="column">
      <Grid container item xs={12} direction="row" component={Paper} className={classes.tableHeadRow}>
        <Grid item xs={1} className={classes.checkboxFlexBasis}>
          <Checkbox
            color="primary"
            id={selectAllField.name}
            name={selectAllField.name}
            checked={selectAllField.checked}
            indeterminate={!isNoneCourseSelected && !isAllCourseSelected && selectAllField.checked}
            onChange={(event) => {
              selectAllHelpers.setValue(event.target.checked);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography> Component Name & ID</Typography>
        </Grid>
        <Grid item xs={1} className={classes.checkboxFlexBasis} />
        <Grid item xs={3}>
          <Typography>Training Material Name</Typography>
        </Grid>
        <Grid item xs>
          <Typography> Project Details</Typography>
        </Grid>
        <Grid item xs>
          <Typography>Release Details</Typography>
        </Grid>
        <Grid item xs={1} style={{ flexBasis: '5%' }}>
          <HyperlinkButton onClick={() => { setExpandAll(!expandAll); }}>{expandAll ? 'Collapse All' : 'Expand All'}</HyperlinkButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BulkScormReleaseTableHead;

BulkScormReleaseTableHead.propTypes = {
  expandAll: PropTypes.bool.isRequired,
  setExpandAll: PropTypes.func.isRequired,
  values: PropTypes.shape({
    isAllCourseSelected: PropTypes.bool.isRequired,
    isNoneCourseSelected: PropTypes.bool.isRequired,
  }).isRequired,
};
