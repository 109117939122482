import React, {
  useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import LabledTextField from '../common/LabledTextField';
import Loading from '../common/Loading';
import CustomRadio from '../UserEnrollment/common/CustomRadio';
import {
  MODERATE_LIGHT_GREY, BLACK, MEDIUM_GREY, ERROR_ORANGE1, ERROR_LABEL_RED,
  SWITCH_GREY, ACCORDION_GREY, FADED_BLUE, ENV_BANNER_COLOR, LIGHT_MEDIUM_GREY,
} from '../../stylesheets/colors';
import InfoIcon from '../../assets/icons/configuration-info-icon.svg';
import { COMPONENT_TITLE_MAX_LIMIT, METADATA_ROLE_OPTIONS } from '../../constants';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '0 48px 20px',
    width: '87%',
    margin: 'auto',
  },
  width100: {
    width: '100%',
  },
  modalHeight: {
    minHeight: '400px',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  errorBlock: {
    display: 'flex',
  },
  rootLabelText: {
    '& > div': {
      borderRadius: 'unset',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
  input: {
    width: '100%',
    margin: '8px 0',
    '& > div': {
      borderRadius: 'unset',
    },
    '& input': {
      paddingRight: '4.5rem',
      '&:disabled': {
        backgroundColor: MODERATE_LIGHT_GREY,
        opacity: 0.5,
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  saveChanges: {
    '&:disabled': {
      color: ACCORDION_GREY,
      boxShadow: 'none',
      backgroundColor: SWITCH_GREY,
    },
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  infoSection: {
    display: 'flex',
    backgroundColor: FADED_BLUE,
    padding: '1rem 1.375rem 1rem 1.5rem',
    alignItems: 'flex-start',
    marginTop: '2rem',
  },
  errorSection: {
    display: 'flex',
    backgroundColor: ERROR_ORANGE1,
    padding: '1rem 1.375rem 1rem 1.5rem',
    alignItems: 'flex-start',
    marginTop: '1rem',
  },
  info: {
    marginLeft: '1rem',
    color: ENV_BANNER_COLOR,
    fontWeight: 'bold',
  },
  warning: {
    marginLeft: '1rem',
    color: ERROR_LABEL_RED,
    fontWeight: 'bold',
  },
  userCount: {
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: BLACK,
  },
  subHeading: {
    '& div': {
      fontSize: '1.25rem',
      color: MEDIUM_GREY,
    },
  },
  formControlLabel: {
    width: '45%',
  },
  checkbox: {
    color: LIGHT_MEDIUM_GREY,
    opacity: 0.6,
    margin: '0 2.5rem 0 0.75rem',
  },
  checkboxChecked: {
    opacity: 1,
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkBoxContainer: {
    position: 'relative',
  },
  controlLabel: {
    marginRight: '0',
    position: 'absolute',
    right: '0',
  },
}));

const WarningIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g id="Prototype-Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="11_CM_Release-Management" transform="translate(-428.000000, -788.000000)">
        <g id="Group-10" transform="translate(428.000000, 788.000000)">
          <rect id="Rectangle" x="0" y="0" width="16" height="16" />
          <path d="M15.9106758,13.9880494 L8.57671593,1.3203006 C8.44808282,1.12759144 8.23169657,1.01186516 8,1.01186516 C7.76830343,1.01186516 7.55191718,1.12759144 7.42328407,1.3203006 L0.0893242303,13.9880494 C-0.029713593,14.1942287 -0.0297788045,14.4482369 0.0891531381,14.6544772 C0.208085081,14.8607176 0.427964892,14.9878908 0.666040163,14.9881348 L15.3339598,14.9881348 C15.5720351,14.9878908 15.7919149,14.8607176 15.9108469,14.6544772 C16.0297788,14.4482369 16.0297136,14.1942287 15.9106758,13.9880494 L15.9106758,13.9880494 Z M8.80406869,5.65400414 L8.48870841,10.05438 L7.51129159,10.05438 L7.21393285,5.65400414 L8.80406869,5.65400414 Z M8,12.987964 C7.45981937,12.987964 7.02191645,12.5500611 7.02191645,12.0098804 C7.02191645,11.4696998 7.45981937,11.0317969 8,11.0317969 C8.54018063,11.0317969 8.97808355,11.4696998 8.97808355,12.0098804 C8.97808355,12.5500611 8.54018063,12.987964 8,12.987964 Z" id="Icon-Color" fill="#e30000" />
        </g>
      </g>
    </g>
    {' '}
  </svg>
);

const EditMetaData = ({
  open,
  onClose,
  isLoading,
  onUpdateMetaData,
  editMetadataUsers,
}) => {
  const classes = useStyles();
  const isSingleUser = editMetadataUsers.length === 1;
  const [formValues, setFormValues] = useState({});
  const initialCheckboxState = {
    custom_field1: false,
    custom_field2: false,
    custom_field3: false,
    custom_field4: false,
  };
  const [checkedItems, setCheckedItems] = useState(initialCheckboxState);
  const disableCustomField = true;
  const custumUserFieldArray = ['Cohort Custom User Field 1', 'Cohort Custom User Field 2',
    'Cohort Custom User Field 3', 'Cohort Custom User Field 4'];

  const prepareFormValues = useCallback(() => ({
    role: isSingleUser ? editMetadataUsers[0]?.metadata_json?.role : '',
    ec_custom_user_field_1: isSingleUser ? editMetadataUsers[0]?.metadata_json?.ec_custom_user_field_1 : '',
    ec_custom_user_field_2: isSingleUser ? editMetadataUsers[0]?.metadata_json?.ec_custom_user_field_2 : '',
    ec_custom_user_field_3: isSingleUser ? editMetadataUsers[0]?.metadata_json?.ec_custom_user_field_3 : '',
    ec_custom_user_field_4: isSingleUser ? editMetadataUsers[0]?.metadata_json?.ec_custom_user_field_4 : '',
  }), [editMetadataUsers, isSingleUser]);

  const clearFormValues = useCallback(() => ({
    role: '',
    ec_custom_user_field_1: '',
    ec_custom_user_field_2: '',
    ec_custom_user_field_3: '',
    ec_custom_user_field_4: '',
  }), []);

  useEffect(() => {
    setFormValues(prepareFormValues());
  }, [prepareFormValues]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formValues,
    onSubmit: (values) => {
      const finalValues = {
        ...(values.role) && { role: values.role },
        ...(values.ec_custom_user_field_1)
        && { ec_custom_user_field_1: values.ec_custom_user_field_1 },
        ...(values.ec_custom_user_field_2)
        && { ec_custom_user_field_2: values.ec_custom_user_field_2 },
        ...(values.ec_custom_user_field_3)
        && { ec_custom_user_field_3: values.ec_custom_user_field_3 },
        ...(values.ec_custom_user_field_4)
        && { ec_custom_user_field_4: values.ec_custom_user_field_4 },
        ...(checkedItems.custom_field1)
        && { ec_custom_user_field_1: '' },
        ...(checkedItems.custom_field2)
        && { ec_custom_user_field_2: '' },
        ...(checkedItems.custom_field3)
        && { ec_custom_user_field_3: '' },
        ...(checkedItems.custom_field4)
        && { ec_custom_user_field_4: '' },
      };
      onUpdateMetaData({ ...finalValues });
    },
  });

  const {
    values,
    handleSubmit,
    handleChange,
    setValues,
    resetForm,
  } = formik;
  useEffect(() => {
    setValues(formValues);
  }, [formValues, setValues]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  const checkCharacterLimit = (e, charLen) => {
    if (e.target.value.length <= charLen) {
      handleChange(e);
    }
  };

  const clearForm = () => {
    batchUpdates(() => {
      setCheckedItems(initialCheckboxState);
      setFormValues(clearFormValues());
    });
  };

  const getWarning = () => (
    <Grid item xs={12}>
      <Box variant="subtitle2" className={classes.errorSection}>
        <WarningIcon />
        <Typography variant="subtitle2" className={classes.warning}>
          Value once deleted, cannot be retrieved back.
        </Typography>
      </Box>
    </Grid>
  );

  return (
    <ConfirmationModal
      open={open}
      isContainForm
      onClose={onClose}
      onSubmit={handleSubmit}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      modalWidth={85}
      title={(
        <FormattedTypography
          variant="subtitle1"
          body={isSingleUser ? 'Edit metadata' : 'Edit metadata for selected users'}
          subHeading={isSingleUser
            ? `Edit the user Role, Cohort Custom User Field values for ${editMetadataUsers[0].firstname} ${editMetadataUsers[0].lastname}`
            : 'Edit the user Role, Cohort Custom User Field values for the selected users. All values added will be applied to all selected users.'}
          subHeadingSection={classes.subHeading}
        />
        )}
      content={
          isLoading ? (
            <Loading />
          ) : (
            <Grid container justifyContent="space-between" className={classes.modalWrapper}>
              {
                !isSingleUser && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.userCount}>{`Selected Users: ${editMetadataUsers.length}`}</Typography>
                </Grid>
                )
              }
              <Grid item xs={12}>
                <Typography variant="body1">Role</Typography>
                <RadioGroup
                  row
                  className={classes.radioGroup}
                  aria-label="role"
                  name="role"
                  value={values?.role ? values?.role : ''}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  {METADATA_ROLE_OPTIONS.map(
                    ({
                      label, value,
                    }) => (
                      <FormControlLabel
                        className={classes.formControlLabel}
                        key={value}
                        value={value}
                        control={<CustomRadio />}
                        label={label}
                      />
                    ),
                  )}
                </RadioGroup>
              </Grid>
              {
                custumUserFieldArray.map((elem, index) => (
                  <Grid key={elem} item xs={12} className={classes.checkBoxContainer}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          className={classes.checkbox}
                          checked={checkedItems[`custom_field${index + 1}`]}
                          value={checkedItems[`custom_field${index + 1}`]}
                          onChange={
                            (event) => setCheckedItems(
                              { ...checkedItems, [event.target.name]: event.target.checked },
                            )
                            }
                          name={`custom_field${index + 1}`}
                          color="primary"
                          classes={{
                            checked: classes.checkboxChecked,
                          }}
                          disabled={disableCustomField}
                        />
                          )}
                      name={`custom_field${index + 1}`}
                      label="Delete Value"
                      className={classes.controlLabel}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                    />
                    <LabledTextField
                      label={elem}
                      disabled={checkedItems[`custom_field${index + 1}`] || disableCustomField}
                      placeholder="Enter value here"
                      className={classes.fieldPadding}
                      inputProps={{
                        name: `ec_custom_user_field_${index + 1}`,
                        classes: {
                          root: `${classes.rootLabelText}`,
                        },
                      }}
                      value={values[`ec_custom_user_field_${index + 1}`]}
                      onChange={(e) => checkCharacterLimit(e, COMPONENT_TITLE_MAX_LIMIT)}
                    />
                    <Box className={classes.errorBlock}>
                      <Typography className={classes.rightText}>
                        {(values[`ec_custom_user_field_${index + 1}`] || '').length}
                        /
                        {COMPONENT_TITLE_MAX_LIMIT}
                      </Typography>
                    </Box>
                    {
                      checkedItems[`custom_field${index + 1}`] && getWarning()
                    }
                  </Grid>
                ))
              }
              <Grid item xs={12}>
                <Box variant="subtitle2" className={classes.infoSection}>
                  <img src={InfoIcon} alt="infoIcon" />
                  <Typography variant="subtitle2" className={classes.info}>
                    If any field is left blank,
                    the data point will be ignored and the old value will be retained.
                    If a new value is added, the data point will be updated with the same.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )
        }
      actions={(
        <>
          <Button className={classes.clearCohort} color="primary" variant="outlined" type="button" onClick={clearForm}>
            Clear
          </Button>
          <Button className={classes.saveChanges} color="primary" disabled={!(values.role || values.ec_custom_user_field_1 || values.ec_custom_user_field_2)} variant="contained" type="submit">
            Save Changes
          </Button>
        </>
        )}
    />
  );
};

export default EditMetaData;

EditMetaData.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateMetaData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  editMetadataUsers: PropTypes.array.isRequired,
};
