import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Checkbox, FormGroup, FormControlLabel,
} from '@mui/material';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  fieldSelectionWrapper: {
    padding: '0 15px',
    fontSize: '1.25rem',
    '& .heading': {
      // padding: "9px"
    },
  },
  text: {
    fontSize: '1.25rem',
  },
}));

const FieldSelectionForm = ({
  heading, fields, selectedFields, onCheck,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.fieldSelectionWrapper}>
      <span className="heading">{heading}</span>
      {fields.map((fld) => (
        <Grid key={fld.key} style={fld.key === 'offering' || fld.key === 'program' ? { display: 'none' } : {}}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={selectedFields.includes(fld.key)}
                  onChange={onCheck}
                  value={fld.key}
                  sx={{
                    '&.Mui-checked': {
                      color: MCKINSEY_BLUE,
                    },
                  }}
                />
                          )}
              label={fld.key === 'portfolio' ? `${fld.label}, Offering, Program` : fld.label}
              className={classes.text}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            />
          </FormGroup>
        </Grid>
      ))}
    </div>
  );
};

FieldSelectionForm.defaultProps = {
  heading: '',
  fields: [],
  selectedFields: [],
};

FieldSelectionForm.propTypes = {
  heading: PropTypes.string,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.array,
  onCheck: PropTypes.func.isRequired,
};

export default FieldSelectionForm;
