import { isEmpty } from 'lodash';
import { getUserNameFromLocalStorage } from '../../../../helpers/userHelper';
import { STAGE_UNKNOWN, USER_INFO_KEY } from '../../../../constants';
import { createJournal, getJournalsStage, updateJournal } from '../../../../containers/ContentLibrary/api';
import { getErrorMessage } from '../../../../helpers/apiHelper';

export default async ({
  values, selectedJournal, savedJournalValues, onJournalModalClose,
  setSnackbarObj, cbFunction, setAlertBarConfig,
}) => {
  try {
    const {
      doceboLpId, configId, linkedName, journalTitle, journalDesc,
    } = values;
    const { id } = selectedJournal;
    const isNewJournal = isEmpty(savedJournalValues);
    const userName = getUserNameFromLocalStorage(USER_INFO_KEY);
    const data = {
      learning_plan_id: doceboLpId,
      learning_plan_code: configId,
      program_name: linkedName,
      journal_title: journalTitle,
      description: journalDesc,
      modified_by: userName,
    };

    let successMessage = '';
    if (isNewJournal) {
      data.created_by = userName;
      successMessage = 'Journal Created Successfully';
      await createJournal(data);
    } else {
      successMessage = 'Journal Edited Successfully';
      await updateJournal(data, id);
    }
    onJournalModalClose();
    cbFunction();
    setAlertBarConfig({
      labelText: successMessage,
      variant: 'success',
      open: true,
    });
  } catch (e) {
    setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
  }
};

export const lockUnlockJournal = async ({
  selectedJournal, onCloseLockUnlockJournalModal,
  setSnackbarObj, cbFunction, setIsLoading, lockJournal,
}) => {
  try {
    onCloseLockUnlockJournalModal();
    setIsLoading(true);
    const { id } = selectedJournal;
    const successMessage = `Journal ${lockJournal ? 'Locked' : 'Unlocked'} Successfully`;
    const { success } = await updateJournal({ is_locked: lockJournal }, id);
    cbFunction(success, successMessage);
  } catch (e) {
    setIsLoading(false);
    setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
  }
};

// eslint-disable-next-line consistent-return
export const updateJournalsWithStage = async (journals, setSnackbarObj) => {
  try {
    const lp_ids = journals.map((journal) => journal?.learning_plan_id);
    const { program_id_found: badgeResult } = await getJournalsStage({ lp_ids });
    if (badgeResult) {
      journals.forEach((journal) => {
        const { learning_plan_id } = journal;
        journal.badge = badgeResult[learning_plan_id] || STAGE_UNKNOWN;
      });
    }
    return journals;
  } catch (e) {
    setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    return journals;
  }
};
