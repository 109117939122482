import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Typography from '@material-ui/core/Typography/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Header from '../common/Header';
import FlyoutMenu from '../common/FlyoutMenu';

import { DARK_MEDIUM_GREY, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const content = [
  'This learning plan will be duplicated',
  'All the course components in it will be duplicated along with its training material',
  'Old Elucidate Scorm training materials will be removed from the courses',
  'New Elucidat Scorm releases will be requested as per your requirements and assigned to the courses',
];

const useStyles = makeStyles({
  menuItems: {
    whiteSpace: 'normal',
    lineHeight: 2,
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  listItemIcon: {
    minWidth: '2.5rem',
    marginTop: '0.8rem',
    alignSelf: 'flex-start',
    color: MODERATE_DARK_GREY,
  },
  menuSubtitleMedGrey: {
    color: DARK_MEDIUM_GREY,
  },
  listRecordIcon: {
    fontSize: '0.5625rem',
  },
});

const ReplicationHeader = ({ className, shouldShow }) => {
  const classes = useStyles();
  return (
    <Header
      className={className}
      heading="Replicate Program"
      subHeading="You can now replicate a program with a few guided steps in a matter of minutes"
    >
      <FlyoutMenu
        buttonTitle="Instructions"
        subTitle="Somethings you should know before replicating a program"
        initialState={shouldShow}
        classes={{
          subtitle: classes.menuSubtitleMedGrey,
        }}
      >
        {content.map((item) => (
          <MenuItem className={classes.menuItems} key={item}>
            <ListItemIcon className={classes.listItemIcon}>
              <FiberManualRecordIcon className={classes.listRecordIcon} />
            </ListItemIcon>
            <Typography variant="inherit">{item}</Typography>
          </MenuItem>
        ))}
      </FlyoutMenu>
    </Header>
  );
};

ReplicationHeader.defaultProps = {
  className: '',
};

ReplicationHeader.propTypes = {
  className: PropTypes.string,
  shouldShow: PropTypes.bool.isRequired,
};

export default memo(ReplicationHeader, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
  && prevProps.shouldShow === nextProps.shouldShow
));
