import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { useWindowWidth } from '@react-hook/window-size';

import ContentSearchCard from './ContentSearchCard';
import DoceboContentSearchCard from './DoceboContentSearchCard';
import TitleBlock from './TitleBlock';
import CustomBreadCrumb from './CustomBreadCrumb';
import SearchBlock from './SearchBlock';
import CarouselCardBlock from './CarouselCardBlock';
import GridCardBlock from './GridCardBlock';
import ConfirmationModal from './ConfirmationModal';
import FailureModal from './FailureModal';
import FilterDropdown from './FilterDropdown';
import {
  elucidatTypeDropdownOptions, doceboTypeDropdownOptions,
  eLearningResourceDropdownOptions, otherResourceDropDownOptions,
} from './utils';

import { VERY_LIGHT_GREY, MCKINSEY_BLUE } from '../../stylesheets/colors';
import { ELEARNING, DOCEBO, ELUCIDAT } from '../../constants';
import AlertBar from './AlertBar';

const useStyles = makeStyles((theme) => ({
  wrappingPaper: {
    width: '100%',
    backgroundColor: VERY_LIGHT_GREY,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
  },
  outerWrapper: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  backWrapper: {
    width: '100px',
    display: 'flex',
    flex: 0.05,
    justifyContent: 'center',
    height: '60px',
    alignItems: 'center',
  },
  backButton: {
    backgroundColor: 'transparent',
  },
  arrow: {
    color: MCKINSEY_BLUE,
    height: '30px',
    width: '30px',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.9,
    maxWidth: ({ windowWidth }) => windowWidth * 0.8, // Carousel explodes without this line
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: MCKINSEY_BLUE,
  },
  errorBox: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  searchFilterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const MessageBlock = ({ message }) => {
  const classes = useStyles();

  if (message.length === 0) return <div />;

  return (
    <div className={classes.errorBox}>
      {message}
    </div>
  );
};

MessageBlock.propTypes = {
  message: PropTypes.string,
};

MessageBlock.defaultProps = {
  message: '',
};

const ContentSearch = ({
  title,
  courseType,
  transactionId,
  programId,
  onBack,
  onAssign,
  onSearch,
  cards,
  backdropOpen,
  message,
  assignFailed,
  setAssignFailed,
  assignFailedErrors,
  selectedType,
  setSelectedType,
  selectedResource,
  setSelectedResource,
  doceboCards,
  alertbarOpen,
  setAlertbarOpen,
  alertbarText,
}) => {
  const windowWidth = useWindowWidth();
  const classes = useStyles({ windowWidth });

  const metadataAdder = (card) => ({
    ...card,
    id: card.project_code,
    title: card.name,
    type: `${card.type}`.toUpperCase(),
    release: get(card, 'releases.0'),
    checked: false,
  });

  const [isTypeDropdownOpen, setTypeDropdownState] = useState(false);
  const [isResourceDropdownOpen, setResourceDropdownState] = useState(false);

  const toggleTypeDropdown = () => setTypeDropdownState(
    (oldIsDropdownOpen) => !oldIsDropdownOpen,
  );

  const toggleResourceDropdown = () => setResourceDropdownState(
    (oldIsDropdownOpen) => !oldIsDropdownOpen,
  );

  const isCardValid = (card) => (card.releases && card.releases.length > 0);

  const [cardsWithSelectionData, setCardsWithSelectionData] = useState(
    cards.filter(isCardValid).map(metadataAdder),
  );

  const [doceboCardsWithSelectionData, setDoceboCardsWithSelectionData] = useState(doceboCards);

  const [isConfirmationModalOpen, setModalState] = useState(false);

  const resourceOptions = courseType === ELEARNING
    ? eLearningResourceDropdownOptions : otherResourceDropDownOptions;

  let typeOptions = [];
  if (selectedResource === DOCEBO) {
    typeOptions = doceboTypeDropdownOptions;
  } else if (selectedResource === ELUCIDAT) {
    typeOptions = elucidatTypeDropdownOptions;
  } else {
    typeOptions = [...doceboTypeDropdownOptions, ...elucidatTypeDropdownOptions];
  }

  useEffect(() => {
    setCardsWithSelectionData(cards.filter(isCardValid).map(metadataAdder));
  }, [cards]);

  useEffect(() => {
    setDoceboCardsWithSelectionData(doceboCards);
  }, [doceboCards]);

  useEffect(() => {
    setSelectedResource(resourceOptions[0].value);
  }, [resourceOptions, setSelectedResource]);

  useEffect(() => {
    setSelectedType(typeOptions[0].value);
    // eslint-disable-next-line
  }, [selectedResource, setSelectedType]);

  const onUpdate = (contentId, release, checked) => {
    const cardIndex = findIndex(cardsWithSelectionData, (card) => card.id === contentId);
    const cardToModify = cardsWithSelectionData[cardIndex];
    cardToModify.release = release;
    cardToModify.checked = checked;
    cardsWithSelectionData[cardIndex] = cardToModify;
    setCardsWithSelectionData([...cardsWithSelectionData]);
  };

  const onDoceboCardUpdate = (contentId, version, checked) => {
    const cardIndex = findIndex(doceboCardsWithSelectionData, (card) => card.id === contentId);
    const cardToModify = doceboCardsWithSelectionData[cardIndex];
    const dateCreatedForCardIndex = findIndex(cardToModify.versions, (item) => item === version);
    cardToModify.version = version;
    cardToModify.checked = checked;
    cardToModify.createdAt = cardToModify.createdDates[dateCreatedForCardIndex];
    doceboCardsWithSelectionData[cardIndex] = cardToModify;
    setDoceboCardsWithSelectionData([...doceboCardsWithSelectionData]);
  };

  const selectedCards = cardsWithSelectionData.filter((card) => card.checked);
  const notSelectedCards = cardsWithSelectionData.filter((card) => !card.checked);

  const selectedDoceboCards = doceboCardsWithSelectionData.filter((card) => card.checked);
  const notSelectedDoceboCards = doceboCardsWithSelectionData.filter((card) => !card.checked);

  const getLabelForSelectedOption = (options, valueToFind) => {
    const selectedObj = options.find((option) => option.value === valueToFind);
    return selectedObj ? selectedObj.label : '';
  };

  return (
    <>
      <Paper className={classes.wrappingPaper}>
        <div className={classes.outerWrapper}>
          <CustomBreadCrumb transactionId={transactionId} programId={programId} />
          <div className={classes.innerWrapper}>
            <div className={classes.backWrapper}>
              <button type="button" className={classes.backButton} onClick={onBack}>
                <ArrowBackIcon className={classes.arrow} />
              </button>
            </div>
            <div className={classes.bodyWrapper}>
              <TitleBlock
                title={title}
                courseType={courseType}
                onAssign={() => setModalState(true)}
                buttonDisabled={!selectedCards.length && !selectedDoceboCards.length}
              />
              <div className={classes.searchFilterContainer}>
                <SearchBlock
                  onSearch={onSearch}
                  defaultSearchText={title}
                  onTypeFilterChange={setSelectedType}
                />
                <FilterDropdown
                  onDropdownChange={(option) => setSelectedResource(option.value)}
                  open={isResourceDropdownOpen}
                  toggleDropdown={toggleResourceDropdown}
                  currentType={getLabelForSelectedOption(resourceOptions, selectedResource)}
                  options={resourceOptions}
                  optionLabel="Source"
                />
                <FilterDropdown
                  onDropdownChange={(option) => setSelectedType(option.value)}
                  open={isTypeDropdownOpen}
                  toggleDropdown={toggleTypeDropdown}
                  currentType={getLabelForSelectedOption(typeOptions, selectedType)}
                  options={typeOptions}
                  optionLabel="Type"
                />
              </div>
              <AlertBar text={alertbarText} open={alertbarOpen} setOpen={setAlertbarOpen} />
              <MessageBlock message={message} />
              <CarouselCardBlock
                title={`${selectedCards.length + selectedDoceboCards.length} Selected`}
                cards={selectedCards}
                onUpdate={onUpdate}
                doceboCards={selectedDoceboCards}
                onDoceboCardUpdate={onDoceboCardUpdate}
                CardComponent={ContentSearchCard}
                DoceboCardComponent={DoceboContentSearchCard}
              />
              <GridCardBlock
                cards={notSelectedCards}
                onUpdate={onUpdate}
                doceboCards={notSelectedDoceboCards}
                onDoceboCardUpdate={onDoceboCardUpdate}
                selectedResource={selectedResource}
              />
            </div>
            <div className={classes.backWrapper} />
          </div>
        </div>
        <ConfirmationModal
          open={isConfirmationModalOpen}
          onAssign={() => { setModalState(false); onAssign(selectedCards, selectedDoceboCards); }}
          onClose={() => setModalState(false)}
        />
        <FailureModal
          open={assignFailed}
          onClose={() => setAssignFailed(false)}
          errors={assignFailedErrors}
          selectedCourseCount={selectedCards.length + selectedDoceboCards.length}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

ContentSearch.propTypes = {
  title: PropTypes.string.isRequired,
  courseType: PropTypes.string.isRequired,
  transactionId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({
    project_code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    releases: PropTypes.arrayOf(PropTypes.any),
  })).isRequired,
  backdropOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  assignFailed: PropTypes.bool.isRequired,
  setAssignFailed: PropTypes.func.isRequired,
  assignFailedErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedType: PropTypes.string.isRequired,
  setSelectedType: PropTypes.func.isRequired,
  selectedResource: PropTypes.string.isRequired,
  setSelectedResource: PropTypes.func.isRequired,
  doceboCards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.number),
    createdDates: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  alertbarOpen: PropTypes.bool.isRequired,
  setAlertbarOpen: PropTypes.func.isRequired,
  alertbarText: PropTypes.string.isRequired,
};

export default ContentSearch;
