import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KEY_BLACK, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  confirmationWrapper: {
    margin: 'auto',
  },
  boldText: {
    color: KEY_BLACK,
  },
  normalText: {
    color: MODERATE_DARK_GREY,
  },
});

const BulkUpdateConfirmation = ({ count }) => {
  const classes = useStyles();

  return (
    <Box className={classes.confirmationWrapper}>
      <Typography variant="subtitle1" align="center" className={classes.boldText}>
        {`You are about to change the status of ${count} users`}
      </Typography>
      <Typography variant="subtitle1" align="center" className={classes.normalText}>
        The deactivated users will not be able to access any leaning plan on DOCEBO
      </Typography>
    </Box>
  );
};

BulkUpdateConfirmation.propTypes = {
  count: PropTypes.number.isRequired,
};

export default BulkUpdateConfirmation;
