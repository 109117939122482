import config from '../../config';
import request from '../../helpers/apiHelper';

export const getContent = async (programId, type, update, cancelToken) => {
  const url = `${config.ROUTES.OVERVIEW_CONTENT}/${type}/${programId}?update=${update}`;

  const options = { url, method: 'GET', cancelToken };
  const result = await request(options);
  const { data } = result;

  return data;
};

export const getEnrollment = async (type, doceboContentId, update) => {
  const url = `${config.ROUTES.OVERVIEW_CONTENT}/${type}/enrollment_count/${doceboContentId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result;

  return data;
};
