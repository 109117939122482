import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import MoreVertIcon from '@material-ui/icons/MoreVert';

const menuItems = [
  {
    key: 'deactivate',
    action: false,
    title: 'Deactivate',
  },
  {
    key: 'activate',
    action: true,
    title: 'Activate',
  },
  {
    key: 'deleteUser',
    action: 'all',
    title: 'Delete User',
  },
  {
    key: 'resetPassword',
    action: false,
    title: 'Reset Password',
  },
  {
    key: 'enrollProgram',
    action: false,
    title: 'Enroll to additional LPs',
  },
  {
    key: 'editLanguagePreferences',
    action: false,
    title: 'Edit Language Preferences',
  },
];

const SingleTestUserMenu = (({ action, menuClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (key) => {
    handleClickAway();
    menuClick(key);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper open={!!anchorEl} anchorEl={anchorEl} placement="top-end">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper elevation={3}>
            {menuItems.filter((item) => (item.action === 'all' || item.action === action)).map((value) => (
              <MenuItem
                key={value.key}
                onClick={() => handleItemClick(value.key)}
                value={value.title}
                disable={value.disable}
              >
                {value.title}
              </MenuItem>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
});

SingleTestUserMenu.propTypes = {
  menuClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};

SingleTestUserMenu.displayName = 'DropdownMenu';

export default memo(SingleTestUserMenu);
