import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { REPORT_ERROR_RED } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  error: {
    color: REPORT_ERROR_RED,
  },
}));

const Error = ({ errorText }) => {
  const classes = useStyles();

  return (
    <span className={classes.error}>{errorText || 'This field is required'}</span>
  );
};
Error.propTypes = {
  errorText: PropTypes.string.isRequired,
};

export default Error;
