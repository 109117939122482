import {
  put, call, takeLatest,
} from 'redux-saga/effects';
import {
  getClientDataSuccess,
  getClientDataFailure,
  updateClientCodeSuccess,
  updateClientCodeFailure,
  addNewClientSuccess,
  addNewClientFailure,
} from '../clientMasterData';
import {
  fetchClientData,
  updateClientCode,
  addClient,
} from '../async/masterdataAsynch';

import {
  ADD_NEW_CLIENT,
  CLIENT_DATA_FETCH,
  UPDATE_CLIENT_INFO,
} from '../../constants';

export function* getClientDataList({ data }) {
  try {
    const response = yield call(fetchClientData, data);
    yield put(getClientDataSuccess(response.data));
  } catch (err) {
    yield put(getClientDataFailure(err.response.data));
  }
}

export function* updateCLient({ data }) {
  try {
    const response = yield call(updateClientCode, data);
    yield put(updateClientCodeSuccess(response.data));
  } catch (err) {
    yield put(updateClientCodeFailure(err.response.data));
  }
}

export function* createNewClient({ data }) {
  try {
    const response = yield call(addClient, data);
    yield put(addNewClientSuccess(response.data));
  } catch (err) {
    yield put(addNewClientFailure(err.response.data));
  }
}

export function* clientDataSaga() {
  yield takeLatest(CLIENT_DATA_FETCH, getClientDataList);
  yield takeLatest(UPDATE_CLIENT_INFO, updateCLient);
  yield takeLatest(ADD_NEW_CLIENT, createNewClient);
}
