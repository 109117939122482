import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  SUCCESS_GREEN, ERROR_RED, MODERATE_LIGHT_GREY, LIGHT_MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 0',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.5,
    flex: 1,
  },
  buttonOutline: {
    height: '1.5rem',
    width: '1.5rem',
    minHeight: 'unset',
    marginRight: '1.25rem',
    cursor: 'unset',
    '& svg': {
      fontSize: '1.25rem',
    },
  },
  buttonOutlineSuccess: {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${SUCCESS_GREEN}`,
    '& svg': {
      color: SUCCESS_GREEN,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${SUCCESS_GREEN}`,
      '& svg': {
        color: SUCCESS_GREEN,
      },
    },
  },
  buttonOutlineError: {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${ERROR_RED}`,
    '& svg': {
      color: ERROR_RED,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${ERROR_RED}`,
      '& svg': {
        color: ERROR_RED,
      },
    },
  },
  heavyFont: {
    fontWeight: 'bold',
  },
  line2: {
    color: LIGHT_MEDIUM_GREY,
  },
  line3: {
    color: SUCCESS_GREEN,
  },
  expand: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'unset',
  },
  expandSummary: {
    padding: 0,
    minHeight: 'unset',
    '&$expanded': {
      minHeight: 'unset',
    },
  },
  expandSummaryContent: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  expandSummaryIcon: {
    padding: 0,
    marginRight: '1rem',
  },
  expandBtn: {
    fontSize: '0.875rem',
  },
  expandDetail: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  listItem: {
    flexDirection: 'row',
    display: 'flex',
    flex: 0.5,
    justifyContent: 'space-between',
    margin: 0,
    wordBreak: 'break-all',
    '& > *': {
      flex: 0.45,
    },
  },
}));

const EditProgramRow = ({
  className, heading, willChange, accordion, data,
  oldValueLabel, newValueLabel,
}) => {
  const classes = useStyles();

  return (
    <Container className={`${classes.wrapper} ${className}`}>
      <Fab
        color="primary"
        component="span"
        size="small"
        className={`${classes.buttonOutline} ${willChange ? classes.buttonOutlineSuccess : classes.buttonOutlineError}`}
      >
        {willChange ? <CheckIcon fontSize="small" color="secondary" /> : <CloseIcon fontSize="small" color="secondary" />}
      </Fab>
      <div className={classes.infoWrapper}>
        {accordion ? (
          <ExpansionPanel className={classes.expand}>
            <ExpansionPanelSummary
              classes={{
                root: classes.expandSummary,
                content: classes.expandSummaryContent,
                expandIcon: classes.expandSummaryIcon,
                expanded: classes.expanded,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="body1" className={classes.heavyFont}>{`${heading} will${!willChange ? ' not' : ''} be modified`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expandDetail}>
              <List dense className={classes.list}>
                {data.map((course) => (
                  <ListItem key={course.old_name} disableGutters>
                    <ListItemText
                      className={classes.listItem}
                      primary={course.old_name}
                      secondary={course.new_name}
                      primaryTypographyProps={{ variant: 'body1' }}
                      secondaryTypographyProps={{ variant: 'body1', className: classes.line3 }}
                    />
                  </ListItem>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <>
            <Typography variant="body1" className={classes.heavyFont}>{`${heading} will${!willChange ? ' not' : ''} be modified`}</Typography>
            {data[0].old_name && <Typography variant="body1" className={`${classes.heavyFont} ${classes.line2}`}>{`${oldValueLabel}: ${data[0].old_name}`}</Typography>}
            {data[0].new_name && <Typography variant="body1" className={`${classes.heavyFont} ${classes.line3}`}>{`${newValueLabel}: ${data[0].new_name}`}</Typography>}
          </>
        )}
      </div>
    </Container>
  );
};

EditProgramRow.defaultProps = {
  className: '',
  accordion: false,
  oldValueLabel: 'Old Name',
  newValueLabel: 'New Name',
};

EditProgramRow.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  willChange: PropTypes.bool.isRequired,
  accordion: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.string.isRequired,
    old_name: PropTypes.string,
    new_name: PropTypes.string,
    success: PropTypes.bool.isRequired,
  })).isRequired,
  oldValueLabel: PropTypes.string,
  newValueLabel: PropTypes.string,
};

export default memo(EditProgramRow, (prevProps, nextProps) => (
  prevProps.className === nextProps.className && prevProps.heading === nextProps.heading
  && _isEqual(prevProps.data, nextProps.data) && prevProps.willChange === nextProps.willChange
  && prevProps.accordion === nextProps.accordion
));
