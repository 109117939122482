import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  link: {
    textDecoration: 'underline',
  },
});

const SuccessPrompt = ({ fileName, replicated }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {replicated
        ? 'Program replicated successfully'
        : `${fileName} executed successfully with no errors.`}
    </div>
  );
};

SuccessPrompt.defaultProps = {
  replicated: false,
};

SuccessPrompt.propTypes = {
  fileName: PropTypes.string.isRequired,
  replicated: PropTypes.bool,
};

export default SuccessPrompt;
