import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import Button from '../ds-react-components/Button';
import Dropdown from '../ds-react-components/Dropdown';

import ChevronUp from '../../assets/icons/chevron-up-black.svg';
import ChevronDown from '../../assets/icons/chevron-down-black.svg';
import { MODERATE_LIGHT_GREY, LIGHT_MEDIUM_GREY, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  dropdown: {
    height: '42px',
    marginRight: '10px',
  },
  button: {
    display: 'flex',
    flex: 1,
    padding: '0',
    width: '180px',
    height: '42px',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    color: MODERATE_DARK_GREY,
    border: `1px solid ${LIGHT_MEDIUM_GREY}`,
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  buttonInnerWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '0',
    width: '100%',
    justifyContent: 'space-between',
  },
  programType: {
    padding: '5px 10px',
    textAlign: 'center',
    flex: 1,
  },
  chevronWrapper: {
    borderLeft: `1px solid ${MODERATE_LIGHT_GREY}`,
    padding: '5px',
    alignItems: 'center',
  },
});

const FilterDropdown = ({
  onDropdownChange, toggleDropdown, open, currentType, options, optionLabel,
}) => {
  const styles = useStyles();

  return (
    <Dropdown
      options={options}
      onChange={onDropdownChange}
      onClick={toggleDropdown}
      className={styles.dropdown}
      value={currentType}
    >
      <Button className={styles.button}>
        <div className={styles.buttonInnerWrapper}>
          <span className={styles.programType}>
            {optionLabel}
            :
            {' '}
            {currentType}
          </span>
          <span className={styles.chevronWrapper}>
            <img src={open ? ChevronUp : ChevronDown} alt="Chevron" />
          </span>
        </div>
      </Button>
    </Dropdown>
  );
};

FilterDropdown.propTypes = {
  currentType: PropTypes.string.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  optionLabel: PropTypes.string.isRequired,
};

export default FilterDropdown;
