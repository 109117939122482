import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from './colors';

export const useStyles = makeStyles((theme) => ({
  // https://material-ui.com/api/dialog/#css
  paperFullWidth: {
    width: '75%',
  },
  paper: {
    // height: '705px',
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 0,
    padding: 0,
  },
  closeButton: {
    // position: 'absolute',
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    color: theme.palette.grey[500],
    alignSelf: 'flex-end',
  },
  titleText: {
    lineHeight: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    fontFamily: 'McKinseySans, sans-serif',
    width: '90%',
  },
  dialogTitle: { justifyContent: 'center', margin: 'auto' },
  imageBlock: {
    display: 'block',
    margin: 'auto',
    marginTop: '35px',
    marginBottom: '15px',
  },
  buttonActions: { marginTop: '50px', justifyContent: 'center' },
  dialogContent: { marginBottom: '50px', margin: 'auto' },
  banner: {
    backgroundColor: COLORS.LIGHT_PURPLE,
    fontSize: '16px',
    color: COLORS.BANNER_FOREGROUND,
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bannerText: {
    marginLeft: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'McKinseySans, sans-serif',
  },
  stepText: {
    color: COLORS.MEDIUM_GREY,
    fontFamily: 'McKinseySans, sans-serif',
    fontSize: '14px',
  },
  bottomToolTip: {
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '40px',
    fontFamily: 'McKinseySans, sans-serif',
  },
  headingText: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  headingTextSecondary: {
    fontSize: '18px',
    fontWeight: 'normal',
  },
}));

export const useDialogStyles = makeStyles(() => ({
  // https://material-ui.com/api/dialog/#css
  paperFullWidth: {
    width: '75%',
  },
  paper: {
    // height: '705px',
  },
  dialogContent: {
    marginBottom: '0px',
  },
}));
