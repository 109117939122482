import React, { memo } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormattedTypography from '../common/FormattedTypography';

import { WORK_SHOP_USER_RECOMMENDED_LIMIT } from '../../constants';
import { MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  listPadding: {
    padding: '0rem',
  },
  listMargin: {
    margin: '0rem',
  },
  listColor: {
    color: MEDIUM_GREY,
  },
});

const WorkshopUserLimitWarning = () => {
  const classes = useStyles();
  return (
    <>
      <List component="nav" aria-label="main" className={classes.listPadding}>
        <ListItem className={classes.listPadding}>
          <ListItemText
            disableTypography
            className={classes.listMargin}
            primary={<FormattedTypography prefixClassName={classes.listColor} prefix={`Workshop files with less than ${WORK_SHOP_USER_RECOMMENDED_LIMIT} users in unique excel file have a higher success rate`} dense variant="subtitle2" justifyContent="center" />}
          />
        </ListItem>
      </List>
    </>
  );
};

export default memo(WorkshopUserLimitWarning, (() => true));
