import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Chip from '@mui/material/Chip';
import { Typography } from '@material-ui/core';
import {
  TableCell, TableRow,
} from '@mui/material';
import TableRc from '../common/TableRC';
import {
  WHITE, MODERATE_DARK_GREY, BLACK, DARK_MEDIUM_GREY,
} from '../../stylesheets/colors';
import NoContent from '../common/NoContent';
import FormattedTypography from '../common/FormattedTypography';
import { ASSET_ICON_ALT_LOOKUP } from '../../constants';

const useStyles = makeStyles({
  subHeadingNoContent: {
    color: BLACK,
  },
  assetIcon: {
    fontWeight: 'normal',
    color: DARK_MEDIUM_GREY,
  },
});

const ChannelsView = ({ settings }) => {
  const classes = useStyles();

  const channelData = settings?.channels;

  const rowSpanCells = (rowSpan, cellValue) => (
    <TableCell rowSpan={rowSpan}>
      <Typography variant="body1">{cellValue}</Typography>
    </TableCell>
  );

  return (
    <>
      <div className="form-header">
        <div>
          <span className="form-header-title">Channels</span>
        </div>
      </div>

      <div className="form-content">
        <div className="form-group">
          {channelData?.length ? (
            <TableRc
              columns={[
                { title: 'Channel Name', property: 'name' },
                { title: 'Channel Link', property: 'channel_link' },
                { title: 'Training Material', property: 'tm_name', style: { padding: 0 } },
                { title: 'Material Type', property: 'tm_type', style: { padding: 0 } },
              ]}
              customBody={(
                <>
                  {channelData?.map((item) => (
                    <>
                      <TableRow>
                        {rowSpanCells(item?.training_materials?.length + 1, item.name)}
                        {rowSpanCells(item?.training_materials?.length + 1, item.channel_link)}
                      </TableRow>
                      {item.training_materials.map((detail) => (
                        <TableRow key={detail?.mame}>
                          <TableCell>
                            <Typography variant="body1">{detail?.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              style={{
                                backgroundColor: MODERATE_DARK_GREY,
                                color: WHITE,
                                fontSize: '1rem',
                                fontWeight: 500,
                                borderRadius: '4px',
                              }}
                              label={
                                ASSET_ICON_ALT_LOOKUP[detail.type.replace(/\W/g, '').toUpperCase()]
                                || ASSET_ICON_ALT_LOOKUP.DEFAULT_CONTENT
                              }
                              size="small"
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
                </>
              )}
            />
          ) : (
            <NoContent>
              <FormattedTypography
                body="This learning plan has no channels."
                subHeading="Please contact a Course Production member for further assistance."
                subHeadingClass={classes.subHeadingNoContent}
              />
            </NoContent>
          )}
        </div>
      </div>
    </>
  );
};

ChannelsView.propTypes = {
  settings: PropTypes.shape({
    channels: PropTypes.shape({
      name: PropTypes.string.isRequired,
      channel_link: PropTypes.string.isRequired,
      training_materials: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ChannelsView;
