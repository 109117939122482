import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash/lang';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography/Typography';
import _find from 'lodash/find';
import {
  BLACK, ERROR_RED, LIGHT_MEDIUM_GREY, MEDIUM_GREY,
} from '../../../stylesheets/colors';
import Loading from '../../common/Loading';
import { getErrorMessage } from '../../../helpers/apiHelper';
import FormattedTypography from '../../common/FormattedTypography';
import CustomModal from '../../common/CustomModal';
import CustomSelect from '../common/CustomSelect';
import commonStyles from '../../../stylesheets/common.styles';
import { getComponents } from '../../../containers/OverviewComponentPage/apis';

const { optionTitle, optionSubtitle, dropdownOptions } = commonStyles;

const useStyles = makeStyles(() => ({
  modalStyles: {
    maxWidth: '65rem',
  },
  dialogContentClass: {
    padding: '0 12.5rem 0.5rem 12.5rem',
    '& > div': {
      marginBottom: '2.5rem',
    },
  },
  modalHeader: {
    margin: '0 auto',
    width: '100%',
    maxHeight: '50vh',
    overflow: 'auto',
    alignItems: 'center',

    '& span': {
      textAlign: 'center',
    },
  },
  divider: {
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  subHeading: {
    color: MEDIUM_GREY,
    fontSize: '1.125rem',
    alignItems: 'center',
  },
  mb: {
    marginBottom: '0.875rem',
  },
  requiredMark: {
    color: ERROR_RED,
  },
  optionTitle,
  optionSubtitle,
  dropdownOptions,
}
));

const AddComponentModal = ({
  open, onClose, onOk, setSnackbarObj, programId,
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [components, setComponents] = useState([]);

  const formik = useFormik({
    initialValues: { selectedComponent: {} },
    onSubmit: (values) => {
      onOk(values);
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit, values, resetForm, setFieldValue,
  } = formik;

  const modifyComponents = useCallback((data) => {
    const updatedComponents = cloneDeep(data);
    updatedComponents.forEach((obj, idx) => {
      const { title, code, course_type } = obj;
      const isOptionDisabled = course_type === 'classroom';
      const textColor = isOptionDisabled ? LIGHT_MEDIUM_GREY : BLACK;
      obj.value = code;
      obj.isdisabled = isOptionDisabled;
      obj.label = (
        <Box className={classes.dropdownOptions}>
          <Typography className={classes.optionTitle} style={{ color: textColor }}>{`${(idx + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}: ${title}`}</Typography>
          <Typography className={classes.optionSubtitle} style={{ color: textColor }}>
            {code}
          </Typography>
        </Box>
      );
    });

    setComponents(updatedComponents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const getComp = async () => {
      try {
        const response = await getComponents(programId, false);
        const resp = _find(response?.data, { type: 'course' });

        if (resp && resp?.rows) {
          modifyComponents(resp?.rows);
        }
      } catch (e) {
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    getComp();
  }, [modifyComponents, programId, setSnackbarObj]);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const { selectedComponent: { name, value } } = values;

  return (
    <CustomModal open={open} onClose={handleClose} modalStyle={classes.modalStyles}>
      {isLoading ? <Loading />
        : (
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography className={classes.modalHeader}>
              <FormattedTypography
                body="Create Group View for participants in Docebo"
                dense
                className={classes.heading}
              />
              <FormattedTypography
                body="To create the participant view in Docebo for this learning plan, please select a component and click on Confirm & Proceed."
                dense
                className={classes.subHeading}
              />
            </DialogTitle>
            <Divider variant="fullWidth" className={classes.divider} light />
            <DialogContent className={`${classes.dialogContentClass}`}>
              <CustomSelect
                options={components}
                placeholder="Select a component"
                label={(
                  <Box className={classes.mb}>
                    <Typography variant="h3" component="span">Select Component</Typography>
                    <Typography variant="h3" component="span" className={classes.requiredMark}>*</Typography>
                  </Box>
                )}
                onChange={(e) => { setFieldValue('selectedComponent', e); }}
                defaultValue={name}
                name="selectedComponent"
                isOptionsNotLoading
              />
            </DialogContent>
            <Divider variant="fullWidth" className={classes.divider} light />
            <DialogActions className={classes.centerAction}>
              <Button
                color="primary"
                variant="contained"
                disabled={!value}
                onClick={() => {
                  onOk(values);
                }}
              >
                Confirm & Proceed
              </Button>
            </DialogActions>
          </form>
        )}
    </CustomModal>
  );
};

AddComponentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  programId: PropTypes.number.isRequired,
};

export default AddComponentModal;
