import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import uniqueId from 'lodash/uniqueId';

import Tooltip from '@material-ui/core/Tooltip';
import {
  MCKINSEY_BLUE, DARK_MEDIUM_GREY, ERROR_RED, DARK_GREY,
} from '../../../stylesheets/colors';

const decideColor = ({ isHovering, success, marked }) => ({
  true: {
    true: {
      true: MCKINSEY_BLUE,
      false: MCKINSEY_BLUE,
    },
    false: {
      true: MCKINSEY_BLUE,
      false: MCKINSEY_BLUE,
    },
  },
  false: {
    true: {
      true: DARK_GREY,
      false: DARK_GREY,
    },
    false: {
      true: DARK_MEDIUM_GREY,
      false: ERROR_RED,
    },
  },
}[isHovering][success][marked]);

const useStyles = makeStyles(() => ({
  metadata: {
    marginTop: '10px',
    fontWeight: '400',
    color: decideColor,
  },
}));

const MetadataBlock = ({ data, success, marked }) => {
  const [isHovering, setHover] = useState(false);

  const classes = useStyles({ isHovering, success, marked });
  if (!data) return null;
  if (!data.length) return null;

  const successCount = data.filter((d) => d.success).length;
  const totalCount = data.length;

  const format = (s) => `${s}`.padStart(2, '0');
  const tagString = `${format(successCount)}/${format(totalCount)} Meta Tags added`;

  const titleFragment = data.map((row) => (
    <div key={uniqueId('metadata-')}>{`${row.title || ''} ${row.metadata}: ${row.success ? 'Created' : 'Not Created'}`}</div>
  ));

  return (
    <div className={classes.metadata}>
      <Tooltip title={<>{titleFragment}</>} arrow placement="right-start">
        <span
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {tagString}
        </span>
      </Tooltip>
    </div>
  );
};

MetadataBlock.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    metadata: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
  })),
  success: PropTypes.bool.isRequired,
  marked: PropTypes.bool.isRequired,
};

MetadataBlock.defaultProps = {
  data: null,
};

export default MetadataBlock;
