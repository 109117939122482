import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { Chip, Typography } from '@material-ui/core';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import Loading from '../common/Loading';

const useStyles = makeStyles(() => ({
  instructor: {
    margin: '0.5rem',
  },
}));

const WorkshopManagementSessionModal = ({
  open, onClose, data, onEditDetailsClick, isLoading, isEditDisabled,
}) => {
  const classes = useStyles();
  const instructors = data?.instructors || [];
  const chips = instructors.map((instr) => (
    <Chip
      key={instr?.user_id}
      label={instr?.username}
      className={classes.instructor}
    />
  ));
  const attendanceBasedText = data?.min_attended_dates_for_completion > 0 ? `${data?.min_attended_dates_for_completion} custom events` : 'All events';
  const evaluationType = data?.evaluation_type === '3' ? 'Manual' : `Attendance Based  |  ${attendanceBasedText}`;

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      breakpoint="sm"
      title={(
        <FormattedTypography
          body="Session Details"
          subHeading="View and edit the details for this workshop"
          dense
        />
      )}
      content={isLoading ? <Loading /> : (
        <Stack spacing={4}>
          <ListItemText primary={<Typography variant="subtitle1" component="span">Session Name</Typography>} secondary={<Typography variant="subtitle1" component="span">{data?.name}</Typography>} />
          {data?.description && (<ListItemText primary={<Typography variant="subtitle1" component="span">Session Description</Typography>} secondary={<Typography variant="subtitle1" component="span">{data?.description || ''}</Typography>} />)}
          {data?.enrolled_users_count !== 0 && (<ListItemText primary={<Typography variant="subtitle1" component="span">Session Enrollments</Typography>} secondary={<Typography variant="subtitle1" component="span">{data?.enrolled_users_count || ''}</Typography>} />)}
          {data?.max_enroll !== 0 && (<ListItemText primary={<Typography variant="subtitle1" component="span">Max Enrollments</Typography>} secondary={<Typography variant="subtitle1" component="span">{data?.max_enroll || ''}</Typography>} />)}
          {chips.length > 0 && (<ListItemText primary={<Typography variant="subtitle1" component="span">Instructor</Typography>} secondary={chips} />)}
          {evaluationType && (<ListItemText primary={<Typography variant="subtitle1" component="span">Session Completion</Typography>} secondary={<Typography variant="subtitle1" component="span">{evaluationType}</Typography>} />)}
        </Stack>
      )}
      actions={(
        <Button disabled={isEditDisabled} color="primary" variant="contained" onClick={onEditDetailsClick}>
          Edit Details
        </Button>
      )}
    />
  );
};

export default WorkshopManagementSessionModal;

WorkshopManagementSessionModal.propTypes = {
  data: PropTypes.shape({
    idCourse: PropTypes.number.isRequired,
    instructors: PropTypes.array,
    name: PropTypes.string.isRequired,
    max_enroll: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    enrolled_users_count: PropTypes.number,
    evaluation_type: PropTypes.string.isRequired,
    min_attended_dates_for_completion: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditDetailsClick: PropTypes.func.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
};
