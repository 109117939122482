/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { format } from 'date-fns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogTitle } from '@mui/material';
import CustomUploadModal from '../../common/CustomUploadModal';
import { LoaderInner } from '../../Loader';
import EnrollmentStuckQueue from '../../common/EnrollmentStuckQueue';
import FormattedTypography from '../../common/FormattedTypography';
import WorkshopUploadProgress from '../WorkshopUploadProgress';
import WorkshopUploadReport from '../WorkshopUploadReport';

import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import BlueAlertIcon from '../../../assets/icons/icon-blue-alert.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';

import {
  COMPLETED, COMPLETED_WITH_ERRORS, FAILED, QUEUED,
} from '../../../constants';
import { downloadFile } from '../../../containers/common/apis';
import { ACCORDION_GREY } from '../../../stylesheets/colors';
import { ordinalSuffix } from '../../../helpers/formattingHelpers';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    margin: '0 auto',
  },
  avtarSection: {
    padding: '1rem 1rem 0 1rem',
    marginBottom: '0 !important',
  },
  bodyTextClass: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  headingWrapper: {
    padding: '0 20rem !important',
    flexWrap: 'wrap',
  },
  headingWrapper1: {
    padding: '0px 20rem 2rem !important',
    flexWrap: 'wrap',
  },
  logoIcon: {
    '& > :nth-child(1)': {
      margin: '3rem 0 2rem 0',
    },
  },
  contentBox: {
    margin: '2rem 0',
  },
  dialogcontentBox: {
    marginBottom: '2rem',
  },
  normalText: {
    fontSize: '1.125rem',
    marginTop: '1.125rem',
    color: ACCORDION_GREY,
    textAlign: 'center',
  },
  headingSection: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  headingBody: {
    fontSize: '1.125rem',
  },
}));

const UploadStatusModal = ({
  open, onClose, progress, programId,
}) => {
  const classes = useStyles();
  const [modalState, setModalState] = useState(null);
  const onDownloadLog = async () => {
    try {
      await downloadFile(progress?.transaction_id, programId, progress?.program_sub_type, progress?.created_at, '', false);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const reportModalButtonProps = () => {
    if ([COMPLETED_WITH_ERRORS, FAILED].includes(progress?.status)) {
      return {
        primaryBtnText: 'Download Log',
        primaryBtnProps: {
          onClick: () => onDownloadLog(),
        },
      };
    }
    return {
      primaryBtnText: 'Close',
      primaryBtnProps: { onClick: () => onClose() },
    };
  };

  const [modalSchema, setModalScheme] = useState({
    PROGRESS: {
      component: WorkshopUploadProgress,
      wrapperProps: {
        logo: (
          <div className={classes.progressRow}>
            <LoaderInner progress={0} scaleFactor={1.5} />
          </div>
        ),
        heading: null,
        headingDivider: false,
        contentDivider: false,
        contentWrapper: classes.dialogcontentBox,
        breakpoint: 'md',
        secondaryBtnText: 'Close',
        secondaryBtnProps: {
          onClick: () => onClose(),
        },
      },
      props: {},
    },
    QUEUED: {
      component: EnrollmentStuckQueue,
      wrapperProps: {
        logo: <></>,
        heading: null,
        breakpoint: 'md',
        primaryBtnText: '',
        primaryBtnProps: null,
      },
      props: {
        queue_position: '',
      },
    },
    REPORT: {
      component: WorkshopUploadReport,
      wrapperProps: {
        logo: null,
        heading: null,
        breakpoint: 'md',
      },
      props: {},
    },
  });

  const applyReportModal = useCallback(() => {
    batchUpdates(() => {
      setModalState('REPORT');
      setModalScheme((schema) => ({
        ...schema,
        REPORT: {
          ...schema.REPORT,
          wrapperProps: {
            ...schema.REPORT.wrapperProps,
            logoWrapper: classes.logoIcon,
            contentWrapper: classes.contentBox,
            headingDivider: !(progress?.status === COMPLETED || progress?.status === FAILED),
            contentDivider: true,
            disableGutters: true,
            logo: (
              <img
                src={
                  progress?.status === COMPLETED
                    ? CheckRoundIcon
                    : progress?.status === FAILED
                      ? ErrorRoundIcon
                      : BlueAlertIcon
                }
                alt="status-icons"
                width={30}
                height={30}
              />
            ),
            heading: (
              // eslint-disable-next-line max-len
              <DialogTitle className={progress?.status === COMPLETED_WITH_ERRORS ? classes.headingWrapper1 : classes.headingWrapper}>
                <FormattedTypography
                  className={classes.bodyTextClass}
                  body={
                    // eslint-disable-next-line no-nested-ternary
                    progress?.status === COMPLETED
                      ? 'Workshop Completion file was uploaded successfully!'
                      : progress?.status === FAILED
                        ? 'Workshop Completion file upload failed!'
                        : 'Workshop Completion file upload was completed with errors'
                  }
                  subHeading={
                    // eslint-disable-next-line no-nested-ternary
                    progress?.status === COMPLETED
                      ? `Workshop Completion file "${progress.excel_file}" was successfully uploaded for`
                      : progress?.status === COMPLETED_WITH_ERRORS
                        ? `"${progress.excel_file}" was completed with errors`
                        : `Workshop Completion file "${progress.excel_file}" could not be uploaded for ${progress.course_name}.`
                  }
                  helperNode={progress?.status === COMPLETED ? progress.course_name : progress?.status === FAILED ? 'Please try again later.' : ''}
                  subHeadingSection={classes.normalText}
                />
              </DialogTitle>
            ),
            ...reportModalButtonProps(),
          },
          props: {
            ...schema.REPORT.props,
            progressData: (progress?.status === COMPLETED_WITH_ERRORS) ? progress.report : {},
          },
        },
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const applyProgressModal = useCallback(() => {
    if (progress) {
      batchUpdates(() => {
        setModalState('PROGRESS');
        setModalScheme((schema) => ({
          ...schema,
          PROGRESS: {
            ...schema.PROGRESS,
            wrapperProps: {
              ...schema.PROGRESS.wrapperProps,
              contentDivider: false,
              headingDivider: false,
              heading: (
                <>
                  <FormattedTypography
                    prefix="Workshop management"
                    prefixClassName={classes.headingSection}
                    containerClassName={classes.headingWrapper}
                    className={classes.headingBody}
                    body={`Uploading workshop completion file "${progress.excel_file}"`}
                  />
                </>
              ),
              logo: (
                <div className={classes.progressRow}>
                  <LoaderInner
                    progress={progress?.percentage ? progress?.percentage : 0}
                    scaleFactor={0.75}
                  />
                </div>
              ),
              primaryBtnProps: {
                ...schema.PROGRESS.wrapperProps.primaryBtnProps,
              },
            },
            props: {
              ...schema.PROGRESS.props,
              progressData: { ...progress },
              courseName: progress.course_name,
              username: progress.created_by,
              createDateTime: format(new Date(`${progress.created_at}+00:00`), "do MMM 'at' hh:mm a"),
            },
          },
        }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const applyQueuedModal = useCallback(() => {
    batchUpdates(() => {
      setModalState('QUEUED');
      setModalScheme((schema) => ({
        ...schema,
        QUEUED: {
          ...schema.QUEUED,
          props: {
            ...schema.QUEUED.props,
            queue_position: progress?.queue_position ? ordinalSuffix(progress?.queue_position) : '',
          },
        },
      }));
    });
  }, [progress]);

  useEffect(() => {
    if (open) {
      if (progress.done === true) {
        applyReportModal();
      } else if (progress?.status === QUEUED) {
        applyQueuedModal();
      } else {
        applyProgressModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  return (
    <>
      {ModalComponent && (
        <CustomUploadModal open={open} onClose={onClose} {...modalSchema[modalState].wrapperProps}>
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
    </>
  );
};

UploadStatusModal.defaultProps = {
  hideSyncAgain: false,
};

UploadStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
    percentage: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    transaction_id: PropTypes.number,
    created_at: PropTypes.string,
    course_name: PropTypes.string,
    created_by: PropTypes.string,
    excel_file: PropTypes.string,
    report: PropTypes.object,
    program_sub_type: PropTypes.string,
    queue_position: PropTypes.string,
  }).isRequired,
  onSyncAgainClick: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  hideSyncAgain: PropTypes.bool,
};

export default UploadStatusModal;
