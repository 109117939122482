import React, {
  useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select/async';
import _debounce from 'lodash.debounce';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import { CancelToken } from 'axios';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@mui/material/Collapse';
import Alert from '@material-ui/lab/Alert';
import Box from '@mui/material/Box';
import { getAllPrograms } from '../../containers/TestUserCreationReportPage/apis';
import { defaultStages } from '../../constants';
import { selectComponentStyles, selectStyles } from './AsyncSelectStyles';

let cancelTokenSource = null;
const page = 0;
const limit = 5;

const useStyles = makeStyles(
  { ...selectComponentStyles },
);

const AsyncProgramSelect = ({
  label, placeholder, onChange, disableOnLimitReach,
  maxMenuHeight, selectedItems, programType, disableSelect, filteredOptions, configId,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState('');
  const [openCollapse, setOpenCollapse] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (disableSelect || (disableOnLimitReach && selectedItems.length >= limit)) {
      setOpenCollapse(true);
    } else {
      setOpenCollapse(false);
    }
  }, [disableOnLimitReach, disableSelect, selectedItems]);

  const debouncedChangeHandler = useMemo(
    () => _debounce((value) => setQuery(value), 1000),
    [],
  );

  const loadOptions = async (inputText, callback) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    cancelTokenSource = CancelToken.source();
    const result = await getAllPrograms(
      programType,
      inputText,
      page,
      defaultStages,
      cancelTokenSource.token,
    );
    if (!result.data.docebo_success) callback([]);
    const newPrograms = _filter(
      result.data.items, (o) => !_includes(filteredOptions, o?.config_id?.trim()),
    ).map((program) => ({
      value: program.config_id,
      program_id: program.program_id,
      label: (
        <Box className={classes.dropdownOptions}>
          <Typography variant="h3" component="span">{program.config_id}</Typography>
          {program.config_id === configId && (
            <Typography className={classes.disabledOptionTag} variant="h2" component="span">
              Current Program
            </Typography>
          )}
        </Box>
      ),
      isDisabled: program?.config_id === configId,
    }));
    callback(newPrograms);
  };

  const debouncedFetch = _debounce(loadOptions, 1000);

  const check = disableSelect || (disableOnLimitReach && selectedItems.length >= limit);

  const getOptions = (input, callback) => debouncedFetch(input, callback);

  const handleDisableOption = (option) => (check || option?.isDisabled ? option.value : '');
  return (
    <Box className={classes.wrapper}>
      <Collapse in={openCollapse}>
        <Alert severity="warning">
          Looks like one or more selected test users have reached their maximum limit of
          additional enrollments.You can enroll the remaining users to more LPs individually
          too.
        </Alert>
      </Collapse>

      <Typography variant="body1" className={classes.labelWrapper}>
        {label}
      </Typography>
      <Box className={classes.selectWrapper}>
        <Select
          defaultOptions
          styles={selectStyles}
          placeholder={placeholder}
          onInputChange={debouncedChangeHandler}
          loadOptions={getOptions}
          name={label}
          onChange={onChange}
          maxMenuHeight={maxMenuHeight}
          isMulti
          isClearable={false}
          isSearchable={!check}
          isOptionDisabled={handleDisableOption}
          menuPosition="fixed"
          value={selectedItems}
        />
      </Box>
    </Box>
  );
};

AsyncProgramSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  programType: PropTypes.string.isRequired,
  maxMenuHeight: PropTypes.number,
  selectedItems: PropTypes.array,
  disableSelect: PropTypes.bool,
  filteredOptions: PropTypes.array,
  configId: PropTypes.string.isRequired,
  disableOnLimitReach: PropTypes.bool,
};

AsyncProgramSelect.defaultProps = {
  maxMenuHeight: 300,
  selectedItems: [],
  disableSelect: false,
  filteredOptions: [],
  disableOnLimitReach: true,
};

export default AsyncProgramSelect;
