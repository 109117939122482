import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import RetryModalImg from '../../assets/img/too-many-retry.svg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  bodyWrapper: {
    flex: 1,
    padding: '1.75rem 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: '23rem',
    height: '23rem',
    marginTop: '-5rem',
  },
  dialogInfo: {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0px',
  },
  buttonOutline: {
    height: '1.5rem',
    width: '1.5rem',
    minHeight: 'unset',
    marginRight: '1.25rem',
    cursor: 'unset',
    '& svg': {
      fontSize: '1.25rem',
    },
  },
  heading: {
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
});

const EditProgramRetryWarningModal = ({
  open, onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.wrapper }}
    >
      <div className={classes.bodyWrapper}>
        <DialogTitle disableTypography className={classes.dialogInfo}>
          <img src={RetryModalImg} className={classes.image} alt="Too many retry" />
          <Typography variant="subtitle1" className={classes.heading}>We are unable to process this program further</Typography>
          <Typography variant="body1">Please proceed with errors.</Typography>
          <Typography variant="body1">Certain components could not be modified. You can modify them in Docebo directly</Typography>
        </DialogTitle>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

EditProgramRetryWarningModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(EditProgramRetryWarningModal, (prevProps, nextProps) => (
  prevProps.open === nextProps.open && prevProps.onClose === nextProps.onClose
));
