import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  highlight: {
    color: MCKINSEY_BLUE,
    cursor: 'pointer',
    width: 'fit-content',
  },
}));

const UsersTableBody = ({
  tableData, onUserClick,
}) => {
  const classes = useStyles();

  const getUserTableCell = (value) => (
    <TableCell align="left">
      {typeof value === 'string' || value instanceof String ? <Typography>{value || '-'}</Typography> : value}
    </TableCell>
  );

  return (
    <TableBody>
      {tableData && tableData.map((user) => {
        const {
          user_id, firstname, lastname, client_name, user_name,
        } = user;
        return (
          <TableRow
            key={user_id}
            sx={{ '&:last-child td, &:last-child th': { border: '0' } }}
          >
            {getUserTableCell(
              <Typography className={classes.highlight} onClick={() => { onUserClick(user_id); }}>{firstname || '-'}</Typography>,
            )}
            {getUserTableCell(lastname)}
            {getUserTableCell(client_name)}
            {getUserTableCell(user_name)}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

UsersTableBody.propTypes = {
  tableData: PropTypes.array.isRequired,
  onUserClick: PropTypes.func.isRequired,
};

export default UsersTableBody;
