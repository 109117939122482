import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './EnvironmentBanner.styles';

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const EnvironmentBanner = ({ environment }) => {
  const classes = useStyles();
  return (
    <div className={classes.bannerBox}>
      <span className={classes.infoIcon}>i</span>
      Your current environment: Mckinsey
      {' '}
      {capitalizeFirstLetter(environment)}
    </div>
  );
};

EnvironmentBanner.propTypes = {
  environment: PropTypes.string,
};

EnvironmentBanner.defaultProps = {
  environment: '',
};

export default EnvironmentBanner;
