import React, { memo } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import FormattedTypography from '../../common/FormattedTypography';

import { USER_MANAGEMENT_RECOMMENDED_LIMIT } from '../../../constants';

const EnrollmentUserLimitWarning = () => (
  <>
    <List component="nav" aria-label="main" dense>
      <ListItem>
        <ListItemText
          disableTypography
          primary={<FormattedTypography prefix={`User Enrollments with less than ${USER_MANAGEMENT_RECOMMENDED_LIMIT} users in unique excel file have a higher success rate`} dense variant="subtitle2" justifyContent="center" />}
        />
      </ListItem>
    </List>
  </>
);

export default memo(EnrollmentUserLimitWarning, (() => true));
