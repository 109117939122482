import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReportOverviewModal from '../ReportOverviewModal';
import InProgressInstructions from '../InProgressInstructions';
import ModalHeader from '../ModalHeader';
import CustomUploadModal from '../../common/CustomUploadModal';
import FormattedTypography from '../../common/FormattedTypography';
import Loading from '../../common/Loading';
import ValidationError from '../../../assets/img/validation-error.webp';
import ErrorModal from '../ErrorModal';
import { LoaderInner } from '../../Loader';
import { ordinalSuffix, zeroAppender } from '../../../helpers/formattingHelpers';
import {
  IN_PROGRESS, FOR_REPORT, QUEUED, COMPLETED, FAILED, COMPLETED_WITH_ERRORS,
} from '../../../constants';
import useProgressResult from '../../../hooks/useProgressResult';
import EnrollmentStuckQueue from '../../common/EnrollmentStuckQueue';

const useStyles = makeStyles(() => ({
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
}));

const ActivateDeactivateUsers = ({
  transactionId, status, open, onClose, onDownloadLog, pollProgressValue,
  updatePollProgress, getReport,
}) => {
  const classes = useStyles();
  const {
    progressResult, progressError, clearProgressResult, clearProgressError,
  } = useProgressResult(pollProgressValue, transactionId, updatePollProgress);

  const [modalState, setModalState] = useState(null);
  const [modalSchema, setModalScheme] = useState({
    LOADING: {
      component: Loading,
      wrapperProps: {
        logo: null,
        breakpoint: 'md',
        heading: null,
      },
      props: {
        minHeight: '20rem',
      },
    },
    PROGRESS: {
      component: InProgressInstructions,
      wrapperProps: {
        logo: (
          <div className={classes.progressRow}>
            <LoaderInner progress={0} scaleFactor={1.5} />
          </div>
        ),
        heading: null,
        breakpoint: 'md',
      },
      props: {
        totalUsers: 0,
        programType: 'SINGLE',
        isExtendedEnterpriseExist: false,
      },
    },
    QUEUED: {
      component: EnrollmentStuckQueue,
      wrapperProps: {
        logo: (<></>),
        heading: null,
        breakpoint: 'md',
      },
      props: {
        queue_position: '',
      },
    },
    ERROR: {
      component: ErrorModal,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        breakpoint: 'md',
        heading: null,
      },
      props: {
        message: 'Unable to fetch data',
      },
    },
    REPORT: {
      component: ReportOverviewModal,
      wrapperProps: {
        logo: null,
        heading: null,
        breakpoint: 'md',
        primaryBtnText: 'Download Log',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: {
        steps: [],
      },
    },
  });
  const applyLoading = useCallback(() => {
    setModalState('LOADING');
  }, []);

  const applyReportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const totalCount = result?.total_rows;
    const successCount = result?.processed_rows;
    const failedCount = totalCount - successCount;
    const reportStatus = result?.status;

    const steps = [];
    if (reportStatus === COMPLETED) {
      steps.push({ type: 'success', primary: `${zeroAppender(successCount)} / ${zeroAppender(totalCount)} rows were updated in Docebo` });
    }
    if (reportStatus === FAILED) {
      steps.push({ type: 'error', primary: `${zeroAppender(failedCount)} / ${zeroAppender(totalCount)} rows were not updated in Docebo` });
    }
    if (reportStatus === COMPLETED_WITH_ERRORS) {
      steps.push(
        { type: 'success', primary: `${zeroAppender(successCount)} / ${zeroAppender(totalCount)} rows were updated in Docebo` },
        { type: 'error', primary: `${zeroAppender(failedCount)} / ${zeroAppender(totalCount)} rows were not updated in Docebo` },
      );
    }

    batchUpdates(() => {
      setModalState('REPORT');
      setModalScheme((schema) => ({
        ...schema,
        REPORT: {
          ...schema.REPORT,
          wrapperProps: {
            ...schema.REPORT.wrapperProps,
            heading: (
              <ModalHeader
                title="Activate/Deactivate Users Overview"
                fileName={result?.filename}
                helpingText="Please download the log file for details"
              />
            ),
            primaryBtnProps: {
              ...schema.REPORT.wrapperProps.primaryBtnProps,
              onClick: () => onDownloadLog(result),
            },
          },
          props: {
            ...schema.REPORT.props,
            steps,
          },
        },
      }));
    });
  }, [transactionId, getReport, onDownloadLog]);

  const applyProgressModal = useCallback((data) => {
    if (data) {
      batchUpdates(() => {
        setModalState('PROGRESS');
        setModalScheme((schema) => ({
          ...schema,
          PROGRESS: {
            ...schema.PROGRESS,
            wrapperProps: {
              ...schema.PROGRESS.wrapperProps,
              heading: (
                <DialogTitle>
                  <FormattedTypography
                    body="Activate/Deactivate Users"
                    subHeading="Please hold on a bit,we are working on it."
                  />
                </DialogTitle>
              ),
              logo: (
                <div className={classes.progressRow}>
                  <LoaderInner
                    progress={data?.percentage ? data?.percentage : 0}
                    scaleFactor={1.5}
                  />
                </div>
              ),
            },
            props: {
              ...schema.PROGRESS.props,
            },
          },
        }));
      });
    } else {
      applyLoading();
    }
  }, [classes.progressRow, applyLoading]);

  const applyErrorModal = useCallback((message) => {
    batchUpdates(() => {
      setModalState('ERROR');
      setModalScheme((schema) => ({
        ...schema,
        ERROR: {
          ...schema.ERROR,
          props: {
            ...schema.ERROR.props,
            message,
          },
        },
      }));
    });
  }, []);

  const applyQueuedModal = useCallback((data) => {
    if (data) {
      batchUpdates(() => {
        setModalState('QUEUED');
        setModalScheme((schema) => ({
          ...schema,
          QUEUED: {
            ...schema.QUEUED,
            props: {
              ...schema.QUEUED.props,
              queue_position: data?.queue_position ? ordinalSuffix(data?.queue_position) : '',
            },
          },
        }));
      });
    } else {
      applyLoading();
    }
  }, [applyLoading]);

  const preClose = () => {
    setModalState(null);
    clearProgressResult(null);
    clearProgressError(null);
    onClose();
  };

  const checkProgress = useCallback(() => {
    if (progressError !== null) {
      applyErrorModal(progressError?.message);
    } else if (progressResult?.data?.done) {
      updatePollProgress(false);
      applyReportModal();
    } else if (progressResult?.data?.transaction_status === QUEUED) {
      applyQueuedModal(progressResult?.data);
    } else {
      applyProgressModal(progressResult?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressError, progressResult]);

  useEffect(() => {
    if (status.length > 0 && open) {
      if (IN_PROGRESS.includes(status)) {
        checkProgress();
      } else if (FOR_REPORT.includes(status)) {
        applyReportModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, status, checkProgress]);

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  return (
    <>
      {ModalComponent && (
        <CustomUploadModal
          open={open}
          onClose={preClose}
          {...modalSchema[modalState].wrapperProps}
        >
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
    </>
  );
};

ActivateDeactivateUsers.propTypes = {
  transactionId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  pollProgressValue: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  updatePollProgress: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
};

export default ActivateDeactivateUsers;
