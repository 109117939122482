import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import FormattedTypography from '../common/FormattedTypography';
import SelectedUserTable from './SelectedUserTable';
import ConfirmationModal from '../common/ConfirmationModal';

const useStyles = makeStyles(() => ({
  marginBottom: {
    marginBottom: '2rem',
  },
}));

const DeleteUserConfirmModal = ({
  users, open, onClose, onDelete,
}) => {
  const classes = useStyles();
  const actionTitle = 'Delete';
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      title={(
        <FormattedTypography
          variant="subtitle2"
          body={`${actionTitle} selected test users`}
          subHeading={`You are about to ${actionTitle.toLowerCase()} ${users.length} selected  user IDs.`}
          dense
        />
      )}
      content={<SelectedUserTable title="Test Users" list={users} className={classes.marginBottom} />}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onDelete}>
            {actionTitle}
            {' '}
            Users
          </Button>
        </>
      )}
    />
  );
};

export default DeleteUserConfirmModal;

DeleteUserConfirmModal.propTypes = {
  users: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
