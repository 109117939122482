/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Grid, Box } from '@mui/material';
import _map from 'lodash/map';
import { Typography } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import TableRC from '../common/TableRC';
import { convertInDigits } from '../../helpers/formattingHelpers';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import EmptyComponentTitle from '../../assets/img/empty-component-title.svg';
import {
  CONTENT_TYPE_MAPPING, ROUTE_REPORT, CONTENT_MANAGEMENT,
} from '../../constants';
import {
  tableSort, getComparator, secondsToHms, getMaterialType,
} from '../../helpers/utils';

const useStyles = makeStyles({
  centerAllign: {
    display: 'flex',
    alignItems: 'center',

  },
  graphicImage: {
    '& img': {
      height: '1.75rem',
      width: '3.125rem',
    },
  },
  leftPadding: {
    paddingLeft: '1rem',
  },
  boldText: {
    fontWeight: 900,
    fontSize: '1.25rem',
  },
  removeMarginTop: {
    '&.MuiListItemText-root': {
      marginTop: '0px',
    },
  },
});

const getList = (list, removeMargin) => list.map((item, index) => (
  <ListItem
    key={item?.name}
    disableGutters
    disablePadding
  >
    <ListItemText
      className={removeMargin}
      primaryTypographyProps={{
        fontSize: '1.25rem',
      }}
      primary={`${index + 1}. ${item?.name} | ${getMaterialType(item).label}`}
    />
  </ListItem>
));

const DoceboDetails = ({
  values, isLoading, programId, coursePath, doceboSectionRef,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('order');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const data = _map(values?.doceboDetails?.length > 0 ? tableSort(values?.doceboDetails, getComparator(order, orderBy)) : values?.doceboDetails, (obj, index) => ({
    ...obj,
    order: <Typography className={classes.boldText} variant="subtitle2">{convertInDigits(index + 1)}</Typography>,
    name:
      <Box className={`${classes.centerAllign} ${classes.graphicImage}`}>
        {obj?.course_graphic ? <img src={obj?.course_graphic} alt="img" /> : <img src={EmptyComponentTitle} alt="img" />}
        <Typography component="span" variant="subtitle2" className={classes.leftPadding}>{obj.name}</Typography>
      </Box>,
    course_code: <Typography variant="subtitle2">{obj.course_code}</Typography>,
    course_type:
      <Box className={classes.centerAllign}>
        {obj?.course_type && <img src={CONTENT_TYPE_MAPPING[obj?.course_type].icon} alt="img" />}
        <Typography component="span" variant="subtitle2" className={classes.leftPadding}>{CONTENT_TYPE_MAPPING[obj.course_type].label}</Typography>
      </Box>,
    duration: <Typography variant="subtitle2">{obj?.duration ? secondsToHms(obj?.duration) : '-'}</Typography>,
    training_materials: obj?.training_materials.length > 0 ? <List disablePadding>{getList(obj?.training_materials.filter((tm) => tm?.type !== ''), classes.removeMarginTop)}</List> : 'N/A',
    style: { verticalAlign: 'top' },
  }));

  return (
    <ContentDetailsBlock
      headerText="Docebo Details"
      fieldRedirectOnLabel={`/${ROUTE_REPORT}/${programId}/${coursePath}/${CONTENT_MANAGEMENT}`}
      noPaper
    >
      {isLoading ? (<LoadingCircle labelText="Loading Docebo Details" />) : (
        <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: '1rem' }} ref={doceboSectionRef}>
          <TableRC
            columns={[
              { title: 'Order', property: 'order' },
              {
                title: 'Component Title', property: 'name', width: '18%', sortable: true,
              },
              { title: 'Code', property: 'course_code' },
              { title: 'Type', property: 'course_type', width: '15%' },
              { title: 'Est. times', property: 'duration', sortable: true },
              { title: 'Training materials in order', property: 'training_materials' },
            ]}
            data={data}
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
            noDataMessage="Docebo details unavailable for this learning plan."
          />
        </Grid>
      )}

    </ContentDetailsBlock>
  );
};

DoceboDetails.propTypes = {
  values: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
  coursePath: PropTypes.string.isRequired,
  doceboSectionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default DoceboDetails;
