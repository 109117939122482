import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { SWITCH_GREY, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapperTableHead: {
    '& .MuiTableCell-head': {
      backgroundColor: SWITCH_GREY,
      color: MODERATE_DARK_GREY,
      fontSize: '1.25rem',
      fontWeight: '800',
    },
  },
}));

const CustomTable = ({
  headerData, bodyData,
}) => {
  const classes = useStyles();

  return (
    <TableContainer style={{ maxHeight: 400, marginTop: '2rem' }}>
      <Table aria-label="simple table" padding="normal" stickyHeader>
        <TableHead className={classes.wrapperTableHead}>
          <TableRow>
            {headerData}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyData}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomTable.propTypes = {
  headerData: PropTypes.array.isRequired,
  bodyData: PropTypes.array.isRequired,
};

export default CustomTable;
