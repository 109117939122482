import { useState } from 'react';

const useCopyText = () => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      // eslint-disable-next-line no-return-await
      return await navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const copyText = (text) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return { isCopied, copyText };
};

export default useCopyText;
