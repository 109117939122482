import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';

import EmptySectionIllustration from '../../assets/img/empty-section.svg';
import { DARK_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: DARK_GREY,
    fontWeight: 'bold',
    fontFamily: 'Bower',
  },
  illustration: {
    maxWidth: '11rem',
    maxHeight: '13rem',
    paddingBottom: '2rem',
  },
}));

const NoDataDom = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img className={classes.illustration} src={EmptySectionIllustration} alt="No past records" />
      <Typography variant="subtitle2" className={classes.title}>{message}</Typography>
    </div>
  );
};

NoDataDom.propTypes = {
  message: PropTypes.string.isRequired,
};

export default memo(NoDataDom);
