import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '@material-ui/core';
import { LockOutlined, FileCopyOutlined } from '@material-ui/icons';
import SettingValueCell from './SettingValueCell';
import tooltipMessages from './tooltipMessages';
import { REQUIRED, INVALID_URL } from '../../constants';
import InfoTooltip from '../common/InfoTooltip';
import useCopyText from '../../hooks/useCopyText';

const defaultSettingsValue = {
  experience: '',
  platform_url: '',
  docebo_learning_plan_url: '',
  created_by: '',
  language: '',
};

const validationSchema = yup.object({
  experience: yup.string().required(REQUIRED).nullable(),
  platform_url: yup.string().required(REQUIRED).nullable(),
  docebo_learning_plan_url: yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      INVALID_URL,
    ).nullable(),
  language: yup.string().nullable(),
});

const ExperienceView = ({
  settings,
  isEdit,
  experienceTypeOptions,
  extendedEnterpriseTypeOptions: platformUrls,
  onSubmit,
  onEdit,
  setOpenModal,
  onCancel,
  languages,
  disableUserStatus,
}) => {
  const defaultData = settings?.program_id ? settings : defaultSettingsValue;
  const { isCopied, copyText } = useCopyText();

  const {
    values, handleSubmit, errors, touched, handleChange, setFieldValue, resetForm,
  } = useFormik({
    initialValues: { ...defaultData },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (payload) => {
      let lang = {};
      if (payload.language) {
        lang = languages.find((el) => (el.value === payload.language));
      }
      const newPayload = {
        ...payload,
        ...(Object.keys(lang).length > 0 && {
          selectedLanguage: {
            code: lang.code,
            shortCode: lang.shortCode,
          },
        }),
      };
      onSubmit(newPayload);
    },
  });

  const platform_url = values?.platform_url
    ? platformUrls.find((el) => el.code === values.platform_url)?.url
    : '';

  const cancelEdit = () => {
    resetForm();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-header">
        <div>
          <span className="form-header-title">Participant Experience</span>
        </div>
        <div>
          {!isEdit && (
            <Button color="primary" variant="contained" onClick={() => onEdit()}>
              Edit
            </Button>
          )}
          {isEdit && (
            <>
              <Button color="primary" variant="outlined" onClick={() => cancelEdit()}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Save Changes
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="form-content">
        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              name="experience"
              label="Primary Experience"
              value={values.experience}
              tooltipMessage={tooltipMessages.EXPERIENCE}
              hasLock={disableUserStatus}
              disabled={disableUserStatus}
              fieldType="dropdown"
              isEdit={isEdit}
              options={experienceTypeOptions}
              handleChange={setFieldValue}
              hasError={touched.experience && !!errors.experience}
              errorText={touched.experience && errors.experience}
            >
              {disableUserStatus && (
                <div className="fld-value mt10">
                  <span>
                    {/* <InfoTooltip /> */}
                    <span className="extrainfo">
                      Email
                      {' '}
                      <u>mcka_courseproduction_&amp;_EWs@mckinsey.com</u>
                      {' '}
                      or contact your Course
                      Production representative to request a different Primary Experience.
                    </span>
                  </span>
                </div>
              )}
            </SettingValueCell>

            <SettingValueCell
              label="Language"
              value={values.language}
              tooltipMessage={tooltipMessages.LANGUAGE}
              isEdit={isEdit}
              name="language"
              options={languages}
              fieldType="dropdown"
              handleChange={setFieldValue}
              hasError={touched.language && !!errors.language}
              errorText={touched.language && errors.language}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-row">
            <SettingValueCell label="Client" tooltipMessage={tooltipMessages.CLIENT} valueType="">
              <div className="form-field">
                <div className="fld-value">
                  <span>
                    {values.client_type}
                    {values.client_type && values.client_type.toLowerCase() !== 'n/a' && (
                      <span
                        className="popup-label"
                        tabIndex={0}
                        role="button"
                        onClick={() => setOpenModal(true)}
                        onKeyDown={() => setOpenModal(true)}
                      >
                        View
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </SettingValueCell>
            <SettingValueCell
              name="docebo_learning_plan_url"
              label="Docebo Learning Plan URL"
              value={values.docebo_learning_plan_url}
              tooltipMessage={tooltipMessages.DOCEBO_LEARNING_PLAN_URL}
              hasCopy
              isEdit={isEdit}
              fieldType="text"
              hasError={touched.docebo_learning_plan_url && !!errors.docebo_learning_plan_url}
              errorText={touched.docebo_learning_plan_url && errors.docebo_learning_plan_url}
              handleChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              name="platform_url"
              label="Platform URL"
              value={values.platform_url}
              tooltipMessage={tooltipMessages.PLATFORM_URL}
              fieldType="dropdown"
              isEdit={isEdit}
              disabled={disableUserStatus}
              options={platformUrls}
              handleChange={setFieldValue}
              hasError={touched.platform_url && !!errors.platform_url}
              errorText={touched.platform_url && errors.platform_url}
            >
              {platform_url && (
                <div className="fld-value">
                  <span>{platform_url}</span>
                  <span>
                    {disableUserStatus && (
                      <>
                        <LockOutlined fontSize="small" />
                        <span className="separator">|</span>
                      </>
                    )}
                    <InfoTooltip title={isCopied ? 'URL Copied' : 'Copy URL'} placement="top">
                      <FileCopyOutlined
                        fontSize="small"
                        onClick={() => copyText(platform_url)}
                      />
                    </InfoTooltip>
                  </span>
                </div>
              )}
            </SettingValueCell>
            <SettingValueCell valueType="" />
          </div>
        </div>
      </div>
    </form>
  );
};

ExperienceView.defaultProps = {
  isEdit: false,
  languages: [],
  experienceTypeOptions: [],
  extendedEnterpriseTypeOptions: [],
  disableUserStatus: false,
};

ExperienceView.propTypes = {
  settings: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  experienceTypeOptions: PropTypes.array,
  extendedEnterpriseTypeOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  disableUserStatus: PropTypes.bool,
  languages: PropTypes.array,
};

export default ExperienceView;
