import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import {
  DARK_MEDIUM_GREY,
  MODERATE_LIGHT_GREY,
  MCKINSEY_BLUE,
  WHITE,
  DARK_GREY,
} from '../../stylesheets/colors';

const COMMON_PADDING = '1rem';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  overviewWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.1,
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    alignItems: 'center',
    fontSize: '1.125rem',
    fontWeight: 'bold',
    padding: COMMON_PADDING,
    maxHeight: '3.7rem',
    backgroundColor: ({ overviewActive }) => (overviewActive ? MCKINSEY_BLUE : 'transparent'),
    color: ({ overviewActive }) => (overviewActive ? WHITE : DARK_GREY),
  },
  cardWrapperOuter: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.9,
    padding: '2rem 1rem',
  },
  cardTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.14,
    color: DARK_MEDIUM_GREY,
    alignItems: 'center',
    padding: COMMON_PADDING,
    maxHeight: '3.7rem',
  },
  arrowIcon: {
    color: theme.palette.primary.light,
    width: '1.125rem',
    marginRight: '1.186rem',
  },
  listItem: {
    padding: '0 0 0 1rem',
  },
}));

const Sidebar = ({ heading, steps }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.wrapper}>
      <div className={classes.cardWrapperOuter}>
        <Typography variant="subtitle1" className={classes.cardTitleWrapper}>
          {heading}
        </Typography>

        <List dense>
          {steps.map((item) => (
            <ListItem key={item} className={classes.listItem}>
              <ArrowForwardIcon className={classes.arrowIcon} />
              <ListItemText key={item} primary={item} />
            </ListItem>
          ))}
        </List>
      </div>
    </Paper>
  );
};

Sidebar.propTypes = {
  heading: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Sidebar;
