import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import {
  MCKINSEY_BLUE,
  DARK_MEDIUM_GREY,
  WHITE,
} from '../../stylesheets/colors';

import { getColorForType } from './utils';

const useStyles = makeStyles(() => ({
  wrappingPaper: {
    width: '280px',
    height: '185px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px',
    marginBottom: '15px',
  },
  typeWrapper: {
    width: '100%',
    flex: 0.1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  typeBox: {
    backgroundColor: ({ typeColor }) => typeColor,
    color: WHITE,
    height: '18px',
    minWidth: '60px',
    maxWidth: '100px',
    fontSize: '10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
  },
  bodyWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    justifyContent: 'space-between',
  },
  checkboxWrapper: {
    flex: 1.25,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  checkbox: {
    backgroundColor: MCKINSEY_BLUE,
    height: '20px',
    width: '20px',
    border: `1px solid ${MCKINSEY_BLUE}`,
  },
  titleWrapper: {
    flex: 2,
    display: 'flex',
    fontSize: '14px',
    wordBreak: 'break-all',
  },
  releasesWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    border: '1px solid black',
    alignItems: 'center',
    padding: '10px',
    height: '40px',
  },
  selectBox: {
    display: 'flex',
    justifyContent: 'center',
    border: '0px',
    width: '100%',
    height: '24px',
    color: DARK_MEDIUM_GREY,
    outline: 'transparent',
  },
  chevron: {
    width: '24px',
    height: '24px',
    color: DARK_MEDIUM_GREY,
  },
}));

const ContentSearchCard = ({
  id, title, type, releases, release, checked, onUpdate,
}) => {
  const typeColor = getColorForType(type);
  const classes = useStyles({ typeColor });

  const [currentRelease, setRelease] = useState(release);

  const onCheckedChange = (e) => {
    const isChecked = e.target.checked;
    onUpdate(id, currentRelease, isChecked);
  };

  const onReleaseChange = (e) => {
    const newReleaseCode = e.target.value;
    const newRelease = releases.filter((r) => r.release_code === newReleaseCode)[0];
    setRelease(newRelease);
    onUpdate(id, newRelease, checked);
  };

  const ReleasesDropdown = (
    <select
      className={classes.selectBox}
      id={id}
      value={currentRelease.release_code}
      onChange={onReleaseChange}
    >
      {releases.map((r) => (
        <option key={r.release_code} value={r.release_code}>
          {`Release Date: ${r.modified}`}
        </option>
      ))}
    </select>
  );

  return (
    <Paper className={classes.wrappingPaper}>
      <div className={classes.typeWrapper}>
        <div className={classes.typeBox}>
          {type}
        </div>
      </div>
      <div className={classes.bodyWrapper}>
        <div className={classes.checkboxWrapper}>
          <input
            className={classes.checkbox}
            type="checkbox"
            onChange={onCheckedChange}
            checked={checked}
          />
        </div>
        <div className={classes.titleWrapper}>
          {title}
        </div>
        <div className={classes.releasesWrapper}>
          {ReleasesDropdown}
          <ChevronDown className={classes.chevron} />
        </div>
      </div>
    </Paper>
  );
};

ContentSearchCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  releases: PropTypes.arrayOf(PropTypes.shape({
    release_code: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  })).isRequired,
  checked: PropTypes.bool.isRequired,
  release: PropTypes.shape({
    release_code: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ContentSearchCard;
