import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: ({ minHeight }) => (minHeight || '10rem'),
  },
}));

const Loading = ({ minHeight }) => {
  const classes = useStyles({ minHeight });
  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
};

Loading.defaultProps = {
  minHeight: '',
};

Loading.propTypes = {
  minHeight: PropTypes.string,
};

export default Loading;
