import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FileInput from '../../FileInput';
import { useStyles, useDialogStyles } from '../../../stylesheets/commonModal.styles';
import MainButton from '../../MainButton';

import { WEBINAR_MANAGEMENT_TEMPLATE } from '../../../constants';

const additionalStyles = makeStyles({
  helpSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3.625rem',
  },
});

const UserSheetUploadModal = ({
  open, onClose, onFileInput,
}) => {
  const classes = useStyles();
  const additionalClasses = additionalStyles();
  const dialogClasses = useDialogStyles();

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      disableBackdropClick
      onClose={onClose}
      open={open}
      classes={dialogClasses}
    >
      <MuiDialogTitle disableTypography className={classes.titleBlock}>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <MuiDialogContent>
        <FileInput idx={0} onFileInput={onFileInput}>
          <MainButton subtitle="Change the status of the users from the excel" />
        </FileInput>
        <div className={additionalClasses.helpSection}>
          <Typography variant="body1">Need some help to configure your file?</Typography>
          <div>
            <a href={WEBINAR_MANAGEMENT_TEMPLATE} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" color="primary" className={classes.downloadBtn}>Download sample template</Button>
            </a>
          </div>
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};

UserSheetUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFileInput: PropTypes.func.isRequired,
};

export default UserSheetUploadModal;
