import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { LoaderInner, LoaderCancelSection } from '../../Loader';
import WrappedIconRow from './WrappedIconRow';

import { BACKDROP_BLUE, SUCCESS_GREEN } from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: '80rem',
    minHeight: '40rem',
    width: '50vw',
    height: '50vh',
  },
  closeRow: {
    display: 'flex',
    flex: 0.14,
    justifyContent: 'flex-end',
  },
  closeButton: {
    backgroundColor: 'transparent',
    paddingRight: '2rem',
  },
  progressRow: {
    display: 'flex',
    flex: ({ skipped }) => (skipped ? 0.51 : 0.38),
    justifyContent: 'center',
    alignItems: 'flex-end',
    margin: '1rem',
    // transform: 'scale(0.4) translate(0, -150%)',
  },
  titleRow: {
    display: 'flex',
    flex: ({ skipped }) => (skipped ? 0.38 : 0.08),
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  checkIcon: {
    color: SUCCESS_GREEN,
    width: '1.5rem',
    height: '1.5rem',
    margin: '0.5rem',
  },
  promptRow: {
    display: 'flex',
    flex: 0.08,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.125rem',
  },
  linksRow: {
    display: 'flex',
    flex: 0.32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelSectionRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem',
  },
  modalBackground: {
    backgroundColor: BACKDROP_BLUE,
    boxShadow: 'none',
  },
});

const LinkModalCreated = ({
  open,
  skipped,
  onClose,
  progress,
  programMetadata,
  onCancelTransaction,
  cancellationRequested,
  setCancellationRequested,
}) => {
  const classes = useStyles({ skipped });

  const optionalDom = (
    <>
      <div className={classes.promptRow}>
        <CheckCircleOutlineIcon className={classes.checkIcon} />
        <div>Program links update successfully. You can edit it later</div>
      </div>
      <div className={classes.linksRow}>
        <WrappedIconRow programMetadata={programMetadata} iconWidth="4.5rem" />
      </div>
    </>
  );

  return (
    <Dialog
      // maxWidth="50vw"
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableBackdropClick
      BackdropProps={{ className: classes.modalBackground }}
    >
      <div className={classes.wrapper}>
        <div className={classes.closeRow}>
          <button type="button" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>

        <div className={classes.progressRow}>
          <LoaderInner progress={progress} scaleFactor={1} />
        </div>

        <div className={classes.titleRow}>
          {cancellationRequested
            ? 'Cancelling pending tasks.'
            : 'We are creating program components.'}
        </div>
        {!skipped && optionalDom}
        <div className={classes.cancelSectionRow}>
          <LoaderCancelSection
            onCancelTransaction={onCancelTransaction}
            cancellationRequested={cancellationRequested}
            setCancellationRequested={setCancellationRequested}
          />
        </div>
      </div>
    </Dialog>
  );
};

LinkModalCreated.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  programMetadata: PropTypes.shape({}).isRequired,
  skipped: PropTypes.bool.isRequired,
  onCancelTransaction: PropTypes.func.isRequired,
  cancellationRequested: PropTypes.bool.isRequired,
  setCancellationRequested: PropTypes.func.isRequired,
};

export default LinkModalCreated;
