import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropdownItem } from './DropdownItem';
import { OptionShape } from './option-shape';

const zIndexValueMap = {
  DropdownList: '1',
  Tooltip: '1',
};

const StyledDropdownList = styled.ul`
  display: none;
  margin-top: 0px;
  overflow-x: hidden;
  max-height: 195px;
  position: absolute;
  list-style-type: none;
  z-index: ${zIndexValueMap.DropdownList};
  padding-left: 0;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  &.open {
    display: block;
    border-top: none;
  }
`;

StyledDropdownList.defaultProps = {};

StyledDropdownList.propTypes = {};

export const DropdownList = (props) => {
  const { onClick, options, open } = props;

  const optionItems = options.map((option, index) => (
    <DropdownItem option={option} onClick={onClick} key={Number(index)} />
  ));

  return (
    <StyledDropdownList
      className={`dsrc_dropdown_list_container${open && options.length ? ' open' : ''}`}
    >
      {optionItems}
    </StyledDropdownList>
  );
};

DropdownList.defaultProps = {
  onClick: () => { },
  options: [],
  open: false,
};

DropdownList.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(OptionShape),
  open: PropTypes.bool,
};

export default DropdownList;
