import React, { useEffect, useState, useCallback } from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CancelToken, isCancel } from 'axios';
import { useFormik } from 'formik';
import { useCustomCompareEffect } from 'use-custom-compare';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertStatusIcon from '../../assets/icons/alert-status-icon2.svg';
import NoChannel from '../../assets/img/noChannel.svg';
import CrossOutLine from '../../assets/icons/cross-outline2.svg';
import EmptyTimeline from '../common/EmptyTimeline';
import LabledTextField from '../common/LabledTextField';
import TimelineStep from '../common/TimelineStep';
import CustomRadio from '../UserEnrollment/common/CustomRadio';
import useModal from '../../hooks/useModal';
import ChannelAssets from './ChannelAssets';
import {
  COURSE_PROD,
  OVERVIEW,
  REPLICATION_CHANNEL_CATEGORIES,
  ROUTE_REPORT,
} from '../../constants';
import {
  LIGHT_MEDIUM_GREY,
  ERROR_RED,
  SUCCESS_GREEN,
  DARK_MEDIUM_GREY,
  MODERATE_LIGHT_GREY,
  KEY_BLACK,
  MODERATE_DARK_GREY,
  SUCCESS_GREEN2,
  WHITE,
  MCKINSEY_BLUE,
  SWITCH_GREY,
  ACCORDION_GREY,
} from '../../stylesheets/colors';
import { trimValue } from '../../helpers/formattingHelpers';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.7rem 0 2rem',
    flex: 0.7,
    borderRight: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  button: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'space-evenly',
    marginTop: '1.5rem',
    alignItems: 'center',
  },
  parentIdPanel: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
  },
  inputItem: {
    flex: '0 1 45%',
    width: '30rem',
  },
  datePickerLabel: {
    marginTop: '-10px',
  },
  inputLabel: {
    fontWeight: 'normal',
    color: LIGHT_MEDIUM_GREY,
  },
  previewPanel: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.55,
    lineHeight: '1.43',
    alignItems: 'center',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0.5rem',
    },
  },
  verticalDivider: {
    margin: '1% auto',
  },
  horizontalDivider: {
    margin: '1.25rem 0',
  },
  label: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: MODERATE_DARK_GREY,
  },
  noChannel: {
    marginTop: '1.125rem',
    fontSize: '1rem',
    fontFamily: 'McKinseySans',
    color: MODERATE_DARK_GREY,
  },
  viewChannelBtn: {
    marginLeft: 'auto',
    textDecoration: 'underline',
  },
  success: {
    color: SUCCESS_GREEN2,
    fontSize: '0.875rem',
  },
  error: {
    color: ERROR_RED,
    fontSize: '0.875rem',
  },
  inputParent: {
    paddingRight: 0,
  },
  input: {
    flex: 0.9,
  },
  editIcon: {
    flex: 0.1,
    cursor: 'pointer',
  },
  buttonSuccess: {
    '&:disabled': {
      backgroundColor: SUCCESS_GREEN,
    },
  },
  buttonError: {
    '&:disabled': {
      backgroundColor: ERROR_RED,
    },
  },
  datepicker: {
    display: 'flex',
    marginTop: '8px',
    '& > div': {
      height: '3.325rem',
      '& > fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: LIGHT_MEDIUM_GREY,
      },
    },
  },
  dateInput: {
    padding: '8.5px 8px',
  },
  loader: {
    alignSelf: 'center',
  },
  timelineWrapper: {
    display: 'flex',
    flex: 0.3,
    flexDirection: 'column',
    marginTop: '3rem',
    height: '100vh',
    overflowX: 'auto',
  },
  heading: {
    color: DARK_MEDIUM_GREY,
    fontWeight: 'normal',
    marginTop: '1rem',
  },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    paddingBottom: '1.7rem',
    alignItems: 'center',
  },
  description: {
    color: MODERATE_DARK_GREY,
    fontSize: '1rem',
  },
  nextButton: {
    width: '4rem',
    fontSize: '1rem',
    backgroundColor: MCKINSEY_BLUE,
    color: WHITE,
    '&:disabled': {
      backgroundColor: SWITCH_GREY,
      color: ACCORDION_GREY,
    },
    '&:hover': {
      backgroundColor: MCKINSEY_BLUE,
      color: WHITE,
      opacity: 0.6,
    },
  },
  charLimit: {
    color: MODERATE_DARK_GREY,
    fontSize: '0.875rem',
  },
  buttonIcon: {
    width: '1rem',
    height: '1rem',
  },
  config: {
    color: KEY_BLACK,
    fontWeight: 'bold',
  },
  customTooltip: {
    maxWidth: 200,
    backgroundColor: MODERATE_DARK_GREY,
  },
  customArrow: {
    color: MODERATE_DARK_GREY,
  },
  lpIds: {
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    borderRadius: '10px',
    padding: '0.125rem 0.5rem',
    fontSize: ' 0.75rem',
    fontWeight: 600,
    width: 'fit-content',
    marginLeft: '0.475rem',
    fontFamily: 'McKinseySans',
    lineHeight: 'normal',
    marginBottom: '0.375rem',
    color: MODERATE_DARK_GREY,
  },
  wrapperLp: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  configOptions: {
    color: MODERATE_DARK_GREY,
    fontSize: ' 1rem',
  },
  successFailMessage: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  inputBox: {
    '& input': {
      fontSize: '1rem',
      color: MODERATE_DARK_GREY,
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'left',
      fontFamily: 'inherit',
      color: 'red',
      fontSize: '1.125rem',
      marginLeft: '0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  inputStyles: ({ values, errors, success }) => {
    let borderColor;
    if (values && values.configId && errors && errors.configId) {
      borderColor = ERROR_RED;
    }
    if (success) {
      borderColor = SUCCESS_GREEN2;
    }
    return (
      {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor,
          },
          '&:hover fieldset': {
            borderColor,
          },
          '&.Mui-focused fieldset': {
            borderColor,
          },
        },
      }
    );
  },
  configurationDesc: {
    color: MODERATE_DARK_GREY,
    fontSize: '1rem',
    fontWeight: 600,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20vh',
  },
}));

let cancelTokenSource;

// Keeping this function now to return sanitized config id only,
// earlier it used to return underscore added config pased on prefix

const newConfigIdGenerator = (values) => trimValue(values.configId);

const ReplicationFormPage = ({
  className,
  sourceConfigId,
  onReplicate,
  checkNewConfigIdExists,
  timelineData,
  channelData,
  openModalWithReportData,
  channelLoading,
  inProgressReplication,
  channelName,
}) => {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { open, openModal, closeModal } = useModal(false);
  const [newChannelName, setNewChannelName] = useState('');
  const [initialValues, setInitialValues] = useState({
    configId: '',
    picked: '',
  });
  const [newCid, setNewCid] = useState(newConfigIdGenerator(initialValues));

  const assets = Object.keys(channelData).length > 0 && channelData.training_materials;

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => onReplicate({ ...values, newCid, newChannelName }),
    enableReinitialize: true,
  });

  const {
    handleSubmit, handleChange, setErrors, values, errors,
  } = formik;
  const classes = useStyles({ values, errors, success });

  const updateConfigId = useCallback(
    (vals) => {
      const cid = newConfigIdGenerator(vals);
      setNewCid(cid);
    },
    [setNewCid],
  );

  const debouncedChange = useCallback(_debounce(updateConfigId, 500), [updateConfigId]);

  const channelNameLengthCheck = newChannelName.length > 255;

  const checkUnique = useCallback(
    async (configId) => {
      // Check unique
      batchUpdates(() => {
        setLoading(true);
        setSuccess(false);
      });

      try {
        cancelTokenSource = CancelToken.source();
        await checkNewConfigIdExists(trimValue(configId), cancelTokenSource.token);
        batchUpdates(() => {
          setSuccess(true);
          setLoading(false);
          setErrors({ configId: '', similarIds: [] });
        });
      } catch (e) {
        if (isCancel(e)) {
          return;
        }

        const msg = _get(e, 'response.data.message');
        const similarIds = _get(e, 'response.data.similarIds');
        if (msg) {
          batchUpdates(() => {
            setErrors({ configId: msg, similarIds });
            setLoading(false);
          });
          return;
        }
        setLoading(false);
      }
    },
    [checkNewConfigIdExists, setErrors],
  );

  useEffect(() => {
    setInitialValues((v) => ({
      ...v,
      configId: '',
      picked: 'newChannelWithSameAssets',
    }));
  }, [sourceConfigId, assets]);

  useEffect(() => {
    setNewChannelName(channelName);
  }, [channelName]);

  useCustomCompareEffect(
    () => {
      updateConfigId(initialValues);
    },
    [initialValues, updateConfigId],
    (prevDeps, nextDeps) => _isEqual(prevDeps[0], nextDeps[0]),
  );

  useEffect(() => {
    // Cancel old request
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    if (newCid) {
      checkUnique(newCid);
    } else {
      setErrors({ configId: '', similarIds: [] });
      setSuccess(false);
      setLoading(false);
    }
  }, [newCid, setErrors, checkUnique]);

  const channelHelperNode = (error) => (
    <>
      <img src={CrossOutLine} style={{ marginRight: '0.25rem' }} alt="error" height={13} width={13} />
      <Typography component="span">{error}</Typography>
    </>
  );

  const channelTextField = () => {
    let helperText = <></>;
    if (!newChannelName.length > 0) {
      helperText = channelHelperNode('Channel name cannot be left blank');
    } else if (channelNameLengthCheck) {
      helperText = channelHelperNode('value must be less than  255 characters');
    }

    return (
      <LabledTextField
        label="Your channel will be created with the name"
        labelClass={classes.configOptions}
        customCss={classes.inputBox}
        required
        inputProps={{
          name: 'newChannelName',
          placeholder: 'Channel Name',
          error: helperText?.length > 0,
          helperText,
          disabled: inProgressReplication,
        }}
        className={classes.inputItem}
        value={newChannelName}
        onChange={(e) => {
          setNewChannelName(e.target.value);
        }}
      />
    );
  };

  const tooltipText = (label, tooltip) => {
    if (tooltip) {
      return (
        <Tooltip
          title={tooltip}
          TransitionComponent={Zoom}
          placement="right-end"
          classes={{ arrow: classes.customArrow, tooltip: classes.customTooltip }}
          arrow
        >
          <span className={classes.configOptions}>{label}</span>
        </Tooltip>
      );
    }
    return <span>{label}</span>;
  };

  const getLpErrorUI = () => {
    if (errors && errors.configId && errors?.similarIds?.length) {
      const similarLpJSX = [];
      errors.similarIds.forEach((id, index) => {
        similarLpJSX.push(
          <span id={index} className={classes.lpIds}>
            {id}
          </span>,
        );
      });
      return (
        <div className={classes.wrapperLp}>
          Some similar ID’s that exist:
          {' '}
          {similarLpJSX}
        </div>
      );
    }
    return null;
  };

  const getSuccessFailMessage = () => {
    let jsx = null;
    if (values && values.configId && errors && errors.configId) {
      jsx = <span className={classes.error}>New Configuration ID should be unique</span>;
    } else if (success) {
      jsx = <span className={classes.success}>New Configuration ID is unique</span>;
    } else {
      jsx = <span style={{ visibility: 'hidden' }}>Message</span>;
    }
    return jsx;
  };

  const Loading = () => {
    const styles = useStyles();

    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  };

  // eslint-disable-next-line no-nested-ternary
  const isChannelNameValid = Object.keys(channelData).length > 0
    ? (_find(REPLICATION_CHANNEL_CATEGORIES, { value: values.picked })?.isInputField === true
      ? newChannelName.length > 0 : true) : true;

  return (
    <div className={`${classes.paper} ${className}`}>
      <Container className={classes.wrapper}>
        <div className={classes.descriptionContainer}>
          <Typography variant="body1" className={classes.description}>
            Create a new Config ID and select a Channel Configuration, if applicable.
          </Typography>
          <Button
            className={classes.nextButton}
            variant="contained"
            type="button"
            disabled={!(success && !channelNameLengthCheck && isChannelNameValid)}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </div>
        <Typography variant="body1" className={classes.label} style={{ marginTop: '1.2rem' }}>
          Create Configuration ID
        </Typography>
        <form className={classes.form}>
          <div className={classes.formContainer}>
            <Grid container spacing={2}>
              <Grid item sm={12} lg={5}>
                <div className={classes.parentIdPanel}>
                  <LabledTextField
                    label="New Configuration ID"
                    value={values.configId}
                    placeholder="Add a new config ID"
                    labelClass={classes.configOptions}
                    customCss={`${classes.inputStyles} ${classes.inputBox}`}
                    inputProps={{
                      inputProps: {
                        disabled: inProgressReplication,
                        name: 'configId',
                        className: classes.input,
                        maxlength: 25,
                      },
                      InputProps: {
                        endAdornment: loading && (
                          <CircularProgress size="2rem" />
                        ),
                      },
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      debouncedChange({
                        ...values,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className={classes.successFailMessage}>
                  <Typography variant="body1">
                    {getSuccessFailMessage()}
                  </Typography>
                  <Typography variant="body1">
                    <span className={classes.charLimit}>
                      {newCid.length}
                      /25
                    </span>
                  </Typography>
                </div>
              </Grid>
              <Grid item display={{ xs: 'none', lg: 'block' }} lg={1} component={Box}>
                <Divider
                  variant="middle"
                  orientation="vertical"
                  classes={{ vertical: classes.verticalDivider }}
                />
              </Grid>
              {values.configId && (
                <Grid item sm={12} lg={6}>
                  <div className={classes.previewPanel}>
                    <div className={classes.infoWrapper}>
                      {errors && errors.configId && (
                        <img src={CrossOutLine} alt="cross" style={{ alignSelf: 'flex-start' }} height="12px" width="12px" />
                      )}
                      <div>
                        <Typography className={(errors && errors.configId && classes.error) || ''}>
                          {(errors && errors.configId)}
                        </Typography>
                        {getLpErrorUI()}
                      </div>
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
            <Divider classes={{ root: classes.horizontalDivider }} />
            <Grid container>
              <Grid item sm={12} lg={12}>
                <Typography className={classes.label} style={{ marginBottom: '1rem' }}>
                  Channel Configuration
                </Typography>
                {/* eslint-disable-next-line no-nested-ternary */}
                {channelLoading ? <Loading /> : Object.keys(channelData).length > 0 ? (
                  <>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography className={classes.configurationDesc}>
                        {channelData.name}
                      </Typography>
                      {assets.length > 0 && (
                        <Button
                          color="primary"
                          className={classes.viewChannelBtn}
                          onClick={openModal}
                        >
                          View channel assets
                        </Button>
                      )}
                    </Box>
                    <RadioGroup
                      aria-label="channel"
                      name="picked"
                      value={values.picked}
                      disabled={inProgressReplication}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      {REPLICATION_CHANNEL_CATEGORIES.map(
                        ({
                          isInputField, title, value, tooltip,
                        }) => !(assets.length <= 0 && value === 'newChannelWithSameAssets') && (
                          <>
                            <FormControlLabel
                              key={value}
                              value={value}
                              control={<CustomRadio />}
                              label={tooltipText(title, tooltip)}
                            />
                            {isInputField && value === values.picked && channelTextField()}
                          </>
                        ),
                      )}
                    </RadioGroup>
                    <ChannelAssets open={open} channelDetails={channelData} onClose={closeModal} />
                  </>
                ) : (
                  <>
                    <img src={NoChannel} alt="no-channel" />
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      className={classes.noChannel}
                    >
                      <Grid item>
                        <img src={AlertStatusIcon} alt="alert" />
                      </Grid>
                      <Grid item>This program has no associated channel</Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </form>
      </Container>
      <Container className={classes.timelineWrapper}>
        <Typography className={classes.heading}>Activity Log</Typography>
        {timelineData.length ? (
          timelineData.map((data) => {
            let channelMessage = '';
            if (data.link_assets !== null) {
              if (data.add_visibility_only) {
                channelMessage = '(Existing Channel with same assets)';
              } else if (data.link_assets) {
                channelMessage = '(Duplicated channel with duplicated assets)';
              } else {
                channelMessage = '(New Channel With No Assets)';
              }
            }

            return (
              <TimelineStep
                key={data.id}
                data={{
                  ...data,
                  completed: data.replication_success,
                }}
                link={`${pathname.replace(new RegExp('/$', 'g'), '')}/${data.id}`}
                openModalWithReportData={openModalWithReportData}
                successMsg="Program replicated successfully"
                errorMsg="Program replicated with errors"
              >
                <Typography variant="body1" className={classes.config}>
                  New Configuration ID:
                  <br />
                  <Link
                    to={`/${ROUTE_REPORT}/${data.destination_program_id}/${COURSE_PROD}/${OVERVIEW}`}
                  >
                    {data.destination_config_id}
                  </Link>
                </Typography>

                <Typography className={classes.config}>
                  Channel Config:
                  {data.channel_created}
                </Typography>
                {
                  data.link_assets !== null && (
                    <Typography variant="body1" className={classes.heading}>
                      {channelMessage}
                    </Typography>
                  )
                }

              </TimelineStep>
            );
          })
        ) : (
          <EmptyTimeline message="No replications created yet..." />
        )}
      </Container>
    </div>
  );
};

ReplicationFormPage.defaultProps = {
  className: '',
};

ReplicationFormPage.propTypes = {
  className: PropTypes.string,
  sourceConfigId: PropTypes.string.isRequired,
  onReplicate: PropTypes.func.isRequired,
  checkNewConfigIdExists: PropTypes.func.isRequired,
  timelineData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      destination_config_id: PropTypes.string.isRequired,
      source_config_id: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      replication_success: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.bool.isRequired,
      ]),
    }).isRequired,
  ).isRequired,
  channelData: PropTypes.shape({
    name: PropTypes.string,
    channel_id: PropTypes.number,
    training_materials: PropTypes.array,
  }).isRequired,
  openModalWithReportData: PropTypes.func.isRequired,
  channelLoading: PropTypes.bool.isRequired,
  inProgressReplication: PropTypes.bool.isRequired,
  channelName: PropTypes.string.isRequired,
};

export default ReplicationFormPage;
