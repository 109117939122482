import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import { CancelToken, isCancel } from 'axios';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import _debounce from 'lodash/debounce';
import { useHistory, useLocation } from 'react-router-dom';
import JournalBook from '../../../../assets/icons/LTI_journal_thumbnail.svg';

import { MODERATE_DARK_GREY } from '../../../../stylesheets/colors';
import MCKINSEY_SANS from '../../../../stylesheets/fonts';
import JournalsCard from './JournalsCard';
import { deleteJournal, getJournals } from '../../../../containers/ContentLibrary/api';
import LabledTextField from '../../../common/LabledTextField';
import AddJournalModal from './AddJournalModal';
import NoDataComponent from '../../NoDataComponent';
import EmptySearchComponent from '../../../common/NoDataComponent';
import { getErrorMessage } from '../../../../helpers/apiHelper';
import handleJournalCreteAndEdit, { lockUnlockJournal, updateJournalsWithStage } from './utils';
import NoChannel from '../../../../assets/img/noChannel.svg';
import DeleteJournalModal from './Pages/DeleteJournalModal';
import LoadMoreComponent from '../../../common/LoadMore';
import LockJournalModal from './Pages/LockJournalModal';
import UnlockJournalModal from './Pages/UnlockJournalModal';

const useStyles = makeStyles({
  cardContainer: {
    width: '33.3%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    '&:nth-child(3n + 1)': {
      paddingLeft: '0.1rem',
    },
    '&:nth-child(3n)': {
      paddingRight: '0.1rem',
    },

  },
  bold: {
    fontWeight: 'bold',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  componentsBreakdown: {
    fontSize: '0.875rem',
    fontFamily: MCKINSEY_SANS,
    color: MODERATE_DARK_GREY,
  },
  searchBox: {
    marginTop: '1.25rem',
  },
  input: {
    paddingRight: '0 !important',
  },
  inputBox: {
    width: '21rem',
    '& input': {
      fontSize: '1rem',
      color: MODERATE_DARK_GREY,
    },
  },
});

let cancelTokenSource = null;

const getProgramsHelper = async (
  searchText,
  journals,
  setJournals,
  hasMore,
  setHasMore,
  currentPage,
  setCurrentPage,
  setSnackbarObj,
  setIsLoading,
  setProgramCount,
) => {
  if (!hasMore) return;

  // Cancel old request
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }

  try {
    cancelTokenSource = CancelToken.source();
    setIsLoading(true);
    const nextPage = currentPage + 1;
    const result = await getJournals(
      searchText,
      nextPage,
      cancelTokenSource.token,
    );
    let updatedJournals = [];
    if (result?.items?.length) {
      updatedJournals = await updateJournalsWithStage(result?.items, setSnackbarObj);
    }
    batchUpdates(() => {
      setJournals(journals.concat(updatedJournals));
      setHasMore(result?.hasMore);
      setCurrentPage(nextPage);
      setProgramCount(result?.totalRows);
      setIsLoading(false);
    });
  } catch (e) {
    if (!isCancel(e)) {
      batchUpdates(() => {
        setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
        setHasMore(true);
        setIsLoading(false);
      });
    }
  }
};

const JournalsTab = ({ setSnackbarObj, match, setAlertBarConfig }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [programCount, setProgramCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [journals, setJournals] = useState([]);
  const [showAddJournalModal, setShowAddJournalModal] = useState(false);
  const [showDeleteJournalModal, setShowDeleteJournalModal] = useState(false);
  const [showLockJournalModal, setShowLockJournalModal] = useState(false);
  const [showUnlockJournalModal, setShowUnlockJournalModal] = useState(false);
  const [savedJournalValues, setSavedJournalValues] = useState({});
  const [selectedJournal, setSelectedJournal] = useState({});
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const location = useLocation();
  const successMessage = location?.state?.successMessage;

  const getAllJournals = useCallback((searchVal) => {
    setCurrentPage(0);
    setJournals([]);
    setHasMore(false);
    setIsLoading(true);
    const populateSidebar = async () => {
      await getProgramsHelper(
        searchVal || '',
        [],
        setJournals,
        true,
        setHasMore,
        0,
        setCurrentPage,
        setSnackbarObj,
        setIsLoading,
        setProgramCount,
      );
    };
    populateSidebar();
  }, [setSnackbarObj]);

  useEffect(() => {
    getAllJournals();
    if (successMessage) {
      setAlertBarConfig({
        labelText: successMessage,
        variant: 'success',
        open: true,
      });
      history.replace({ state: {} });
    }
  }, [getAllJournals, history, setAlertBarConfig, successMessage]);

  const onLoadMore = async () => {
    await getProgramsHelper(
      searchText,
      journals,
      setJournals,
      hasMore,
      setHasMore,
      currentPage,
      setCurrentPage,
      setSnackbarObj,
      setIsLoading,
      setProgramCount,
    );
  };

  const areJournalsEmpty = get(journals, 'length') === 0 && !isLoading && !hasMore;

  const onJournalModalClose = () => {
    batchUpdates(() => {
      setShowAddJournalModal(false);
      setSavedJournalValues({});
      setSelectedJournal({});
    });
  };

  const handleOnOk = async (values) => {
    handleJournalCreteAndEdit({
      values,
      selectedJournal,
      savedJournalValues,
      onJournalModalClose,
      setAlertBarConfig,
      setSnackbarObj,
      cbFunction: () => {
        getProgramsHelper(
          searchText,
          [],
          setJournals,
          true,
          setHasMore,
          0,
          setCurrentPage,
          setSnackbarObj,
          setIsLoading,
          setProgramCount,
        );
      },
    });
  };

  const onDeleteJournal = async ({ journalId }) => {
    try {
      await deleteJournal(journalId);
      setShowDeleteJournalModal(false);
      getAllJournals(searchText);
      setAlertBarConfig({
        labelText: 'Journal Deleted Successfully',
        variant: 'success',
        open: true,
      });
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    }
  };

  const onEditJournal = async (selectedJournalObj) => {
    const { description: journalDesc, journal_title, learning_plan_code } = selectedJournalObj;
    const filledFormData = {
      searchId: learning_plan_code,
      journalTitle: journal_title,
      journalDesc,
    };
    setSelectedJournal(selectedJournalObj);
    setSavedJournalValues(filledFormData);
    setShowAddJournalModal(true);
  };

  const onClickDeleteJournal = (selectedJournalObj) => {
    setSelectedJournal(selectedJournalObj);
    setShowDeleteJournalModal(true);
  };

  const onCloseDeleteJournalModal = () => {
    setSelectedJournal({});
    setShowDeleteJournalModal(false);
  };

  const onClickLockJournal = (selectedJournalObj) => {
    batchUpdates(() => {
      setSelectedJournal(selectedJournalObj);
      setShowLockJournalModal(true);
    });
  };

  const onClickUnlockJournal = (selectedJournalObj) => {
    batchUpdates(() => {
      setSelectedJournal(selectedJournalObj);
      setShowUnlockJournalModal(true);
    });
  };

  const onCloseLockUnlockJournalModal = () => {
    batchUpdates(() => {
      setSelectedJournal({});
      setShowLockJournalModal(false);
      setShowUnlockJournalModal(false);
    });
  };

  const onLockUnlockJournal = async (lockJournal) => {
    lockUnlockJournal({
      selectedJournal,
      onCloseLockUnlockJournalModal,
      setSnackbarObj,
      setIsLoading,
      lockJournal,
      cbFunction: async (success, alertMessage) => {
        await getProgramsHelper(
          searchText,
          [],
          setJournals,
          true,
          setHasMore,
          0,
          setCurrentPage,
          setSnackbarObj,
          setIsLoading,
          setProgramCount,
        );
        setAlertBarConfig({
          labelText: alertMessage,
          variant: 'success',
          open: true,
        });
      },
    });
  };

  const cardsDom = journals.map((journal) => (
    <Box className={classes.cardContainer} key={journal?.id}>
      <JournalsCard
        journal={journal}
        match={match}
        onDeleteJournal={() => { onClickDeleteJournal(journal); }}
        onEditJournal={onEditJournal}
        onLockJournal={onClickLockJournal}
        onUnlockJournal={onClickUnlockJournal}
      />
    </Box>
  ));

  const debouncedChange = useCallback(_debounce((searchVal) => { getAllJournals(searchVal); }, 500),
    [getAllJournals]);

  const handleSearch = (searchVal) => {
    setSearchText(searchVal);
    setIsLoading(true);
    debouncedChange(searchVal);
  };

  const getJournalsJsx = () => {
    if (searchText && areJournalsEmpty) {
      return (
        <EmptySearchComponent
          imgSrc={NoChannel}
          primaryText="Oops! Could not find the journal you were looking for."
          secondaryText="Adjust your search to see more results."
        />
      );
    } if (areJournalsEmpty) {
      return <NoDataComponent emptyIcon={JournalBook} level="Journals" onAdd={() => { setShowAddJournalModal(true); }} />;
    }
    return cardsDom;
  };

  return (
    <>
      {showAddJournalModal && (
      <AddJournalModal
        open={showAddJournalModal}
        onClose={onJournalModalClose}
        onOk={handleOnOk}
        savedJournalValues={savedJournalValues}
        selectedJournal={selectedJournal}
      />
      )}

      { showDeleteJournalModal && (
      <DeleteJournalModal
        onClose={onCloseDeleteJournalModal}
        onConfirm={onDeleteJournal}
        selectedJournal={selectedJournal}
      />
      )}

      { showLockJournalModal && (
      <LockJournalModal
        onClose={onCloseLockUnlockJournalModal}
        onConfirm={() => { onLockUnlockJournal(true); }}
        selectedJournal={selectedJournal}
      />
      )}

      { showUnlockJournalModal && (
      <UnlockJournalModal
        onClose={onCloseLockUnlockJournalModal}
        onConfirm={() => { onLockUnlockJournal(false); }}
        selectedJournal={selectedJournal}
      />
      )}
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box className={classes.searchBox}>
          <LabledTextField
            label="Search"
            placeholder="Search by Journal/LP/Editor"
            labelClass={classes.componentsBreakdown}
            customCss={`${classes.inputBox}`}
            inputProps={{
              inputProps: {
                name: 'configId',
                className: classes.input,
              },
              InputProps: {
                endAdornment: searchText ? (
                  <ClearIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => { handleSearch(''); }} />
                ) : (<SearchIcon style={{ cursor: 'pointer' }} fontSize="small" />),
              },
            }}
            value={searchText}
            onChange={(e) => { handleSearch(e.target.value); }}
          />
        </Box>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => { setShowAddJournalModal(true); }}
        >
          Add Journal
        </Button>
      </Box>
      <Box style={{
        display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'scroll',
      }}
      >
        <Box
          style={{ alignItems: `${!areJournalsEmpty ? 'baseline' : ''}` }}
          className={classes.bodyWrapper}
        >
          {!isLoading && getJournalsJsx()}
        </Box>
        {!areJournalsEmpty && (
        <LoadMoreComponent
          totalItems={programCount}
          onLoadMore={onLoadMore}
          loadMoreText="journals"
          itemsShown={journals.length}
          hasMore={hasMore}
          isLoading={isLoading}
        />
        )}
      </Box>
    </>
  );
};

JournalsTab.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      platformType: PropTypes.string,
      programSubType: PropTypes.string,
      feature: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  setAlertBarConfig: PropTypes.func.isRequired,
};

export default JournalsTab;
