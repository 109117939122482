import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import FormattedTypography from '../common/FormattedTypography';
import SelectedUserTable from './SelectedUserTable';
import ConfirmationModal from '../common/ConfirmationModal';

const useStyles = makeStyles(() => ({
  marginBottom: {
    marginBottom: '2rem',
  },
}));

const TestUserConfirmModal = ({
  action, users, open, onClose, onConfirm,
}) => {
  const classes = useStyles();
  const actionTitle = action ? 'Activate' : 'Deactivate';
  const status = action ? 'Inactive' : 'Active';
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      title={(
        <FormattedTypography
          variant="subtitle2"
          body={`${actionTitle} selected test users`}
          subHeading={`You are about to ${actionTitle.toLowerCase()} ${users.length} selected ${status.toLowerCase()} user IDs.
      ${actionTitle === 'Deactivate' ? 'These user IDs are not deleted and you can re-active them anytime.' : ''}`}
          dense
        />
      )}
      content={<SelectedUserTable title="Test Users" list={users} className={classes.marginBottom} />}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            {actionTitle}
            {' '}
            Users
          </Button>
        </>
      )}
    />
  );
};

export default TestUserConfirmModal;

TestUserConfirmModal.propTypes = {
  action: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
