import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { getEnvironmentFromLocalStorage } from '../../helpers/userHelper';

import { useStyles, useDialogStyles } from '../../stylesheets/commonModal.styles';

import SuccessIcon from '../../assets/icons/ready-to-go.svg';

const DialogTitle = (props) => {
  const classes = useStyles();
  const {
    children, onClose,
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleBlock}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography className={classes.titleText}>{children}</Typography>
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ConfirmationModal = ({ open, onClose, onAssign }) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const environment = getEnvironmentFromLocalStorage();
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      disableBackdropClick
      onClose={onClose}
      open={open}
      classes={dialogClasses}
    >
      <DialogTitle onClose={onClose} className={classes.dialogTitle}>
        <img className={classes.imageBlock} src={SuccessIcon} alt="Spreadsheet" width={119} height={100} />
        <span className={classes.headingText}>
          Proceed with the selected content?
        </span>
      </DialogTitle>
      <MuiDialogContent className={classes.dialogContent} style={{ marginBottom: '0' }}>
        Once the content is assigned from the admin portal,
        it can be modified only on
        {' '}
        {environment.url}
      </MuiDialogContent>
      <MuiDialogActions className={classes.buttonActions} style={{ marginTop: '20px' }}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Go Back
        </Button>
        <Button variant="contained" color="primary" onClick={onAssign} style={{ marginLeft: '1rem' }}>
          Assign
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
};

export default ConfirmationModal;
