import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { DialogActions, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@mui/material/Paper';
import {
  ACCORDION_GREY, MEDIUM_GREY,
} from '../../../stylesheets/colors';
import CustomModal from '../../common/CustomModal';
import FormattedTypography from '../../common/FormattedTypography';
import WarningInfoAlert from '../../common/WarningInfoAlert';

const useStyles = makeStyles({
  modalStyles: {
    maxWidth: '70rem',
  },
  dialogContentClass: {
    padding: '0 13rem 0.5rem 13rem',
    '& > div': {
      marginBottom: '2rem',
    },
  },
  modalHeader: {
    textAlign: 'center',
  },
  divider: {
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',

    '& > button': {
      width: '8rem',
    },
  },
  subHeading: {
    color: MEDIUM_GREY,
  },
  groupMemberTitle: {
    fontWeight: 'bold',
  },
  title: {
    color: ACCORDION_GREY,
  },
  value: {
    marginTop: '0.2rem',
  },
  memberInfoContainer: {
    marginTop: '1rem',
    padding: '1.25rem',
  },
  memberInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  alertBox: {
    '& .MuiAlert-root': {
      marginTop: '0.5rem',
      padding: '0.75rem 1.25rem',
    },
    '& .MuiAlert-message': {
      fontWeight: '800',
    },
  },
});

const UnAssignGCModal = ({
  open, onClose, onSave, selectedMember, selectedMemberGroup,
}) => {
  const classes = useStyles();
  const { group_name } = selectedMemberGroup;
  const { name, email } = selectedMember;

  const onMoveMember = () => {
    onClose();
    onSave();
  };

  const getMemberInfoJsx = (title, value) => (
    <Box className={classes.memberInfoWrapper}>
      <Typography variant="h3" component="span" className={classes.title}>{title}</Typography>
      <Typography variant="body1" component="span" className={classes.value}>{value}</Typography>
    </Box>
  );

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyles}
    >
      <Box>
        <DialogTitle disableTypography className={classes.modalHeader}>
          <FormattedTypography
            body="Remove selected member as the Group Co-ordinator (GC)"
            dense
          />
          <Typography className={classes.subHeading} variant="body1">
            Removing Group Co-ordinator for
            {' '}
            {group_name}
          </Typography>
        </DialogTitle>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogContent className={`${classes.dialogContentClass}`}>
          <Typography variant="subtitle2" component="span" className={classes.groupMemberTitle}>Selected member details</Typography>
          <Paper className={classes.memberInfoContainer} square elevation="1">
            {getMemberInfoJsx('Name:', name)}
            {getMemberInfoJsx('Email:', email)}
          </Paper>
          <WarningInfoAlert severity="warning" customStyles={classes.alertBox}>
            This action will remove this member as the group co-ordinator across assignments
          </WarningInfoAlert>
        </DialogContent>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogActions className={classes.centerAction}>
          <Button color="primary" variant="outlined" onClick={onClose}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={onMoveMember}>Confirm</Button>
        </DialogActions>
      </Box>
    </CustomModal>
  );
};

UnAssignGCModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedMember: PropTypes.object.isRequired,
  selectedMemberGroup: PropTypes.object.isRequired,
};

export default UnAssignGCModal;
