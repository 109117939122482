import React from 'react';
// import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  bannerText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
  },
}));

const AdminFragment = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.wrapper}>
      <Typography className={classes.bannerText}>Secret Admin Page</Typography>
    </Paper>
  );
};

AdminFragment.propTypes = {
};

export default AdminFragment;
