import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@mui/material';
import Typography from '@material-ui/core/Typography/Typography';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import FormattedTypography from '../../common/FormattedTypography';
import { MEDIUM_GREY, DARK_GREY, MODERATE_LIGHT_GREY } from '../../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  iconWrapper: {
    height: '2rem',
    width: '4rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgressPadding: {
    padding: '1rem',
  },
  listPadding: {
    padding: '0rem 8rem 1rem 8rem',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    color: MEDIUM_GREY,
  },
  countSectionClass: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 1.5rem',
    color: DARK_GREY,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    },
  },
  containerClassName: {
    display: 'inline-block',
    marginLeft: '1rem',
  },
  warninglistSection: {
    padding: '2rem 8rem 1rem 8rem',
    borderTop: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  secondaryText: {
    textAlign: 'center',
    color: MEDIUM_GREY,
  },
  primaryText: {
    textAlign: 'center',
    color: MEDIUM_GREY,
  },
}));

const MetadataComponentsOverview = ({
  successCount, failCount, totalCount, fileName,
}) => {
  const classes = useStyles();
  return (
    <>
      <List aria-label="main" dense textAlign="center" classes={{ padding: classes.listPadding }}>
        {failCount === 0 && (
          <ListItem>
            <ListItemText id="switch-list-label-bluetooth" primary={`Component metadata bulk edit file "${fileName}" was successfully uploaded`} classes={{ primary: classes.primaryText }} />
          </ListItem>
        )}
        {successCount === 0 && (
        <ListItem>
          <ListItemText
            id="switch-list-label-bluetooth"
            primary={`Component metadata bulk edit file "${fileName}" could not be uploaded.`}
            secondary="Please try again later."
            classes={{ secondary: classes.secondaryText, primary: classes.primaryText }}
          />
        </ListItem>
        )}
        {failCount > 0 && successCount > 0 && (
          <ListItem>
            <ListItemText
              id="switch-list-label-bluetooth"
              primary={`"${fileName}" was completed with errors. Download the log to see reasons for failure `}
              classes={{ primary: classes.primaryText }}
            />
          </ListItem>
        )}
      </List>
      {failCount > 0 && successCount > 0 && (
        <Box component="div" className={classes.warninglistSection}>
          <Typography variant="subtitle1" style={{ fontWeight: '800' }}>Components:</Typography>
          <Box component="div" className={classes.countSectionClass}>
            <img
              src={CheckRoundIcon}
              alt="status-icon"
              width={15}
              height={15}
              className={classes.CheckRoundIcon}
            />
            <FormattedTypography
              body={`${successCount}/${totalCount} components’ `}
              suffixClassName={classes.sufix}
              suffix="metadata was successfully updated"
              containerClassName={classes.containerClassName}
            />
          </Box>
          <Box component="div" className={classes.countSectionClass}>
            <img
              src={ErrorRoundIcon}
              alt="status-icon"
              width={15}
              height={15}
              className={classes.CheckRoundIcon}
            />
            <FormattedTypography
              body={`${failCount}/${totalCount} components’ `}
              suffixClassName={classes.sufix}
              suffix="metadata could not be updated"
              containerClassName={classes.containerClassName}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default MetadataComponentsOverview;

MetadataComponentsOverview.defaultProps = {
  successCount: 0,
  failCount: 0,
  totalCount: 0,
  fileName: 'component_metadata.xlsx',
};

MetadataComponentsOverview.propTypes = {
  successCount: PropTypes.number,
  failCount: PropTypes.number,
  totalCount: PropTypes.number,
  fileName: PropTypes.string,

};
