import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OptionShape } from './option-shape';

const StyledDropdownItem = styled.li`
  display: block;
  padding: 10px 20px;
  background-color: #fff;
  color: #323232;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;

  span {
    white-space: nowrap;
  }

  &:hover {
    color: #1f40e6;
  }

  &.disabled {
    cursor: not-allowed;
    color: #b3b3b3;
  }
`;

StyledDropdownItem.defaultProps = {};

StyledDropdownItem.propTypes = {};

export const DropdownItem = (props) => {
  const { option, onClick } = props;

  return (
    <StyledDropdownItem
      value={option.value}
      className={option.value ? null : 'disabled'}
      onClick={() => (option.value ? onClick(option) : () => { })}
    >
      <span>{option.label}</span>
    </StyledDropdownItem>
  );
};

DropdownItem.defaultProps = {
  option: { value: null, label: 'Select a value...' },
  onClick: () => { },
};

DropdownItem.propTypes = {
  option: OptionShape,
  onClick: PropTypes.func,
};

export default DropdownItem;
