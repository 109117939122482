import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { BLACK, MEDIUM_GREY } from '../../../../../../stylesheets/colors';
import ConfirmationModal from '../../../../../common/ConfirmationModal';
import JournalingDeleteIcon from '../../../../../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:last-child': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
}));

const DeletePageModal = ({
  onClose,
  onConfirm,
  page,
  standardPagesLength,
  reviewPagesLength,
}) => {
  const classes = useStyles();
  const { id, journal_title: pageTitle, journal_type } = page;
  const restrictDeletion = standardPagesLength === 1 && reviewPagesLength > 0 && journal_type === 'standard';
  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>{restrictDeletion ? 'This standard page cannot be deleted' : 'Delete Page'}</Typography>
          {restrictDeletion ? (
            <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
              At all times there must be at least one standard page
              because there is a review page in this journal.
            </Typography>
          ) : (
            <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
              You are about to delete Page
              {' '}
              <span style={{ fontWeight: 600 }}>{pageTitle}</span>
            </Typography>
          )}
          {!restrictDeletion ? (
            <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>All prompts within this page will also be deleted.</Typography>
          ) : null}
        </Container>
            )}
      actions={restrictDeletion ? (
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      ) : (
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" data-testid="delete_page_action" variant="contained" onClick={() => { onConfirm({ pageId: id }); }}>
            Delete
          </Button>
        </>
      )}
    />
  );
};

DeletePageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  standardPagesLength: PropTypes.number.isRequired,
  reviewPagesLength: PropTypes.number.isRequired,
};

export default DeletePageModal;
