/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Stepper, Step, StepLabel,
} from '@material-ui/core';
import { CancelToken, isCancel } from 'axios';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import { useFormik } from 'formik';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { getCoursesAndValidateLp, getTrainingMaterials, validateCourseCode } from '../../../containers/ContentManagement/apis';
import { checkValidityOfElucidatPid } from '../../../containers/common/apis';
import CustomUploadModal from '../../common/CustomUploadModal';
import FormattedTypography from '../../common/FormattedTypography';
import CompleteTask from '../../../assets/icons/stepper-complete.svg';
import { USER_CIRCLE_DARK_BG, SWITCH_GREY } from '../../../stylesheets/colors';
import SelectLearningPlan from './SelectLearningPlan';
import ConfigureComponent from './ConfigureComponent';
import ReviewComponent from './ReviewComponent';
import Loading from '../../common/Loading';
import { COMPONENT_TITLE_MAX_LIMIT, COMPONENT_CODE_MAX_LIMIT } from '../../../constants';
import { getErrorMessage } from '../../../helpers/apiHelper';

const checkScorm = (loType) => (!!loType?.includes('scorm'));

function getSteps() {
  return ['01', '02', '03'];
}

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-circle': {
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  '& .QontoStepIcon-active': {
    backgroundColor: USER_CIRCLE_DARK_BG,
  },
  '& .QontoStepIcon-inactive': {
    backgroundColor: SWITCH_GREY,
  },
}));

const QontoStepIcon = (props) => {
  const { completed, active, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {(() => {
        if (completed) {
          return (
            <img src={CompleteTask} alt="complete-task" />
          );
        } if (active) {
          return (<div className="QontoStepIcon-circle QontoStepIcon-active" />
          );
        }
        return (<div className="QontoStepIcon-circle QontoStepIcon-inactive" />
        );
      })()}
    </QontoStepIconRoot>
  );
};

QontoStepIcon.defaultProps = {
  active: false,
  className: '',
  completed: false,
};

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ImportComponentCopy = ({
  open, onClose, programId, targetLpCode, triggerImport, eopComponent, importLoading,
  fetchContentApi,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [lpDetails, setLpDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tmList, setTmList] = useState([]);
  const [validateCourseError, setValidateCourseError] = useState('');

  const steps = getSteps();

  useEffect(() => {
    setLoading(importLoading);
  }, [importLoading]);

  const fetchTrainingMaterilas = useCallback(
    async (configId, newCourseId, sourceProgramId) => {
      try {
        setLoading(true);
        setValidateCourseError('');
        const response = await getTrainingMaterials(newCourseId, programId, sourceProgramId);
        if (response?.data?.success) {
          setLoading(false);
          setTmList(response?.data?.data);
          setActiveStep(1);
        } else {
          setValidateCourseError(response?.data?.message);
          setActiveStep(0);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const next = (values) => {
    // const data = validateConfigId(values?.configId)
    if (activeStep === 0) {
      fetchTrainingMaterilas(
        lpDetails.docebo_lp_id, values?.componentId, Number(lpDetails?.program_id),
      );
    } else if (activeStep === 1) {
      setActiveStep(2);
    }
  };

  const onImport = (values) => {
    triggerImport({
      new_course_code: values?.newComponentCode,
      new_course_title: values?.newComponentTitle,
      new_course_status: values?.userAccess,
      target_lp_id: Number(programId),
      target_lp_code: targetLpCode,
      source_course_id: values?.componentId,
      course_tms: values?.lo?.map((lo) => ({
        ...lo,
        new_project_id: lo?.elucidatInput,
        ...(lo?.type?.includes('scorm') && lo?.additionalFolders) && {
          selected_folders: lo?.additionalFolders && _map(lo?.additionalFolders, 'value'),
        },
      })),
      program_id: Number(programId),
      source_program_id: Number(lpDetails?.program_id),
      eop_transfer: values?.dropdownDefaultValue?.isEop && values?.eopSetting ? values?.eopSetting === 'true'
        : (!!values?.dropdownDefaultValue?.isEop),
    });
  };

  const cancelTokenSource = {};

  const Step1Schema = Yup.object({
    configId: Yup.string().required('Config Id Is Required'),
    componentId: Yup.string().required('Select one component'),
    isHavingEOP: Yup.boolean(),
    eopSetting: Yup.string().when('isHavingEOP', {
      is: true,
      then: Yup.string().required('Choose one option'),
    }),
  });

  const Step2Schema = Yup.object({
    lo: Yup.array(
      Yup.object({
        isScorm: Yup.boolean(),
        elucidatSelect: Yup.string().when(['selected', 'isScorm'], {
          is: (selected, isScorm) => selected && isScorm,
          then: Yup.string().required('Select option from list'),
        }),
        elucidatInput: Yup.string().when(['selected', 'isScorm'], {
          is: (selected, isScorm) => selected && isScorm,
          then: Yup.string().required('Required'),
        }),
      }),
    ),
  });

  const Step3Schema = Yup.object({
    newComponentTitle: Yup.string().required('This is a required field').max(COMPONENT_TITLE_MAX_LIMIT, `Title should not exceed ${COMPONENT_TITLE_MAX_LIMIT} characters`),
    newComponentCode: Yup.string().required('This is a required field').max(COMPONENT_CODE_MAX_LIMIT, `Code should not exceed ${COMPONENT_CODE_MAX_LIMIT} characters`),
    userAccess: Yup.string().required('This is a required field'),
  });

  const schemaArray = [Step1Schema, Step2Schema, Step3Schema];

  const formik = useFormik({
    initialValues: {
      configId: '',
      componentId: '',
      isHavingEOP: false,
      lo: [],
      newComponentTitle: '',
      newComponentCode: '',
      userAccess: '',
      eopSetting: '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: schemaArray[activeStep],
    onSubmit: (values, bag) => {
      if (activeStep === 2) {
        // eslint-disable-next-line consistent-return
        const validateAndSubmit = async () => {
          try {
            const resp = await validateCourseCode({
              new_course_code: values?.newComponentCode,
              target_lp_code: targetLpCode,
            });
            if (resp?.data?.success === true) {
              return onImport(values);
            }
            bag.setErrors({ newComponentCode: resp?.data?.message });
          } catch (err) {
            bag.setErrors({ newComponentCode: getErrorMessage(err) });
          }
        };
        validateAndSubmit();
      }
      next(values);
      bag.setSubmitting(false);
    },
  });

  const {
    handleSubmit, handleChange, values, errors, touched,
    setErrors, setFieldValue, setValues, resetForm,
  } = formik;

  useEffect(() => {
    if (tmList?.length > 0) {
      setValues({
        ...values,
        lo: tmList.map((tm) => ({
          ...tm,
          selected: true,
          isValidating: false,
          isAllTmsValidated: false,
          isScorm: !!checkScorm(tm?.type),
          ...(!!checkScorm(tm?.type) && { elucidatSelect: '' }),
          ...(!!checkScorm(tm?.type) && { elucidatInput: '' }),
          ...(!!checkScorm(tm?.type) && { isValidating: false }),
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmList]);

  const beforeClose = () => {
    resetForm();
    onClose();
  };

  const onCheckInner = useCallback(
    async (configText) => {
      // Cancel old request
      if (cancelTokenSource[programId]) {
        cancelTokenSource[programId].cancel();
      }
      try {
        setIsConfigLoading(true);
        setLpDetails({});
        cancelTokenSource[programId] = CancelToken.source();
        if (configText === targetLpCode) {
          setErrors({ ...errors, configId: 'Cannot select current Learning plan for importing a component.' });
        } else {
          const response = await getCoursesAndValidateLp(
            configText, cancelTokenSource[programId].token,
          );

          if (response.data.success) {
            setErrors({});
            setLpDetails(response.data.data);
          } else {
            setLpDetails({});
            setErrors({ ...errors, configId: 'No Learning Plan found with this ID. Please try another one.' });
          }
        }
      } catch (e) {
        if (!isCancel(e)) {
          setErrors({ ...errors, configId: 'Something went wrong' });
        }
      } finally {
        setIsConfigLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const debouncedChange = useCallback(_debounce(onCheckInner, 500), [onCheckInner]);

  const handleBlur = useCallback((textFieldValue) => {
    if (textFieldValue) {
      onCheckInner(textFieldValue);
    }
  }, [onCheckInner]);

  const onRefresh = async (lpProgramId) => {
    try {
      setLoading(true);
      const resp = await fetchContentApi({ isRefresh: true, programId: lpProgramId });
      if (resp?.data?.success === true) {
        onCheckInner(lpDetails?.config_id);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (validateCourseError.length) {
      setErrors({ ...errors, componentId: validateCourseError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateCourseError]);

  return (
    <>
      <CustomUploadModal
        open={open}
        onClose={beforeClose}
        heading={(
          <DialogTitle>
            <FormattedTypography
              variant="subtitle1"
              body="Import a Component Copy"
              subHeading="Choose a component from any learning plan on the platform.
             A copy of the component and it's training materials will be created into this learning plan."
            />
          </DialogTitle>
        )}
        headingDivider
        disableGutters
        contentDivider
        primaryBtnText={activeStep < 2 ? 'Next' : 'Import'}
        primaryBtnProps={
          {
            onClick: () => handleSubmit(),
            disabled: !!((activeStep === 0 && lpDetails?.is_locked)
              || (activeStep === 1 && !values?.isAllTmsValidated)),
          }
        }
        secondaryBtnText={activeStep !== 0 && 'Back'}
        secondaryBtnProps={
          { onClick: () => setActiveStep(activeStep - 1) }
        }
      >
        <Container sx={{ width: '100%' }}>
          <Box sx={{ width: '40%', margin: 'auto' }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '50%' }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {(() => {
            if (loading) {
              return (<Loading />);
            }
            if (activeStep === 1) {
              return (
                <ConfigureComponent
                  formik={formik}
                  checkElucidatValidity={checkValidityOfElucidatPid}
                  targetLpCode={targetLpCode}
                />
              );
            } if (activeStep === 2) {
              return (
                <ReviewComponent
                  formik={formik}
                  targetLpCode={targetLpCode}
                  handleChange={handleChange}
                />
              );
            }
            return (
              <SelectLearningPlan
                values={values}
                errors={errors}
                touched={touched}
                lpDetails={lpDetails}
                setValues={setValues}
                handleChange={handleChange}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                isConfigLoading={isConfigLoading}
                debouncedChange={debouncedChange}
                eopComponent={eopComponent}
                targetLpCode={targetLpCode}
                onRefresh={onRefresh}
              />
            );
          })()}
        </Container>
      </CustomUploadModal>
    </>
  );
};

ImportComponentCopy.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  targetLpCode: PropTypes.string.isRequired,
  triggerImport: PropTypes.func.isRequired,
  eopComponent: PropTypes.object.isRequired,
  importLoading: PropTypes.bool.isRequired,
  fetchContentApi: PropTypes.func.isRequired,
};

export default ImportComponentCopy;
