import get from 'lodash/get';
import { FEATURE_CLICKED, GROUP_ENROLLMENT } from '../../../constants';
import { downloadGroupEnrollmentFile, downloadGwFile } from '../../../containers/GroupWorkPage/apis';
import { getErrorMessage } from '../../../helpers/apiHelper';

export const DEFAULT_GROUP_COUNT = 30;

export const validateForm = (configId, startingAt, count) => {
  if (get(configId, 'length') < 3) return false;
  if (!(startingAt > 0)) return false;
  if (!(count > 0 && count <= 2000)) return false;

  return true;
};

export const onDownloadGWData = async ({ programSubType, programId, setSnackbarObj }) => {
  try {
    // adding heap tracking script to track actual feature click
    window.heap.track(FEATURE_CLICKED, programSubType);
    if (programSubType === GROUP_ENROLLMENT) {
      await downloadGroupEnrollmentFile(programId);
    } else {
      await downloadGwFile(programId);
    }
  } catch (e) {
    setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
  }
};
