import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { MODERATE_LIGHT_GREY, MCKINSEY_BLUE, VERY_LIGHT_GREY_V2 } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  stepperWrapper: {
    padding: '15px',
    backgroundColor: VERY_LIGHT_GREY_V2,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
  },
  stepper: {
    width: '40%',
  },
  label: {
    '& span': {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    '& .MuiStepLabel-label': {
      marginTop: '5px !important',
    },
  },
}));

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 7px)',
    right: 'calc(50% + 7px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: MCKINSEY_BLUE,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: MODERATE_LIGHT_GREY,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')((props) => ({
  color: MODERATE_LIGHT_GREY,
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(props.ownerState.active && {
    color: MCKINSEY_BLUE,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: MCKINSEY_BLUE,
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      <div className={`QontoStepIcon-circle ${completed ? 'QontoStepIcon-completedIcon' : ''}`} />
    </QontoStepIconRoot>
  );
}

QontoStepIcon.defaultProps = {
  active: false,
  completed: false,
  className: '',
};

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const steps = ['Select Metadata fields', 'Edit & Save'];

export default function FormStepperProgress({ completedSteps }) {
  const classes = useStyles();
  return (
    <div className={classes.stepperWrapper}>
      <Stepper
        activeStep={completedSteps}
        alternativeLabel
        className={classes.stepper}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              className={classes.label}
              StepIconComponent={QontoStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

FormStepperProgress.defaultProps = {
  completedSteps: 0,
};

FormStepperProgress.propTypes = {
  completedSteps: PropTypes.number,
};
