import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { MCKINSEY_BLUE, MEDIUM_GREY, LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  searchWrapper: {
    width: '100%',
    display: 'flex',
    height: '42px',
    marginTop: '36px',
    marginBottom: '35px',
    marginRight: '10px',
    border: `1px solid ${LIGHT_MEDIUM_GREY}`,
  },
  textBox: {
    flex: 1,
    border: '0px',
    padding: '20px',
    color: MEDIUM_GREY,
    outline: '0px',
  },
  searchButton: {
    backgroundColor: 'white',
    padding: '0px 20px 0px 20px',
  },
  searchIcon: {
    color: MCKINSEY_BLUE,
  },
}));

// https://stackoverflow.com/a/59226455/1217998
const debouncer = debounce((f) => f(), 300, { leading: true });

const SearchBlock = ({ onSearch, defaultSearchText }) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState(defaultSearchText);

  useEffect(() => {
    setSearchText(defaultSearchText);
  }, [defaultSearchText]);

  const onSearchInner = () => debouncer(() => {
    onSearch(searchText);
  });

  const handleClearSearchText = () => {
    onSearch('');
    setSearchText('');
  };

  return (
    <div className={classes.searchWrapper}>
      <input
        value={searchText}
        className={classes.textBox}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={(e) => e.key === 'Enter' && onSearchInner()}
        type="text"
      />
      {searchText ? (
        <button type="button" className={classes.searchButton} onClick={() => handleClearSearchText()}>
          <ClearIcon />
        </button>
      )
        : (
          <button type="button" className={classes.searchButton} onClick={() => onSearchInner()}>
            <SearchIcon className={classes.searchIcon} />
          </button>
        )}
    </div>
  );
};

SearchBlock.propTypes = {
  onSearch: PropTypes.func.isRequired,
  defaultSearchText: PropTypes.string.isRequired,
};

export default SearchBlock;
