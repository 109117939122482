import config from '../config';
import request from './apiHelper';

export const getNvtUserInfo = async () => {
  const url = `${config.ROUTES.NVT_USER_INFO}`;
  const options = { url, method: 'GET' };
  try {
    const result = await request(options);
    const { data } = result;
    return data;
  } catch (error) {
    return {
      teams: [],
    };
  }
};

export const noop = () => null;
