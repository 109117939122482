import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {
  Table, TableContainer, TableBody, TableRow, TableCell,
} from '@mui/material';
import EnhancedTableHeader from '../Table/EnhancedTableHeader';
import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import Header from '../common/Header';
import { BULK_WAVE_TABLE_HEAD_ATTRIBUTES, DATEFORMAT } from '../../constants';
import NoDataComponent from '../common/NoDataComponent';
import NoData from '../../assets/img/noChannel.svg';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import InfoTooltip from '../common/InfoTooltip';
import {
  MCKINSEY_BLUE,
  MODERATE_DARK_GREY, BLACK,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    boxShadow: 'none',
  },
  headerWrapper: {
    padding: '2rem',
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
    },
  },
  mainWrapper: {
    flexGrow: 1,
  },
  middleWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    padding: '1rem 2rem',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableTopSection: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.25rem 0',
  },
  wavetitle: {
    textDecoration: 'underline',
    color: MCKINSEY_BLUE,
    maxWidth: '20rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  dateDuration: {
    width: '15rem',
  },
  customPopoverList: {
    '& li': {
      padding: 0,
    },
  },
  formatCell: {
    minWidth: '12rem',
    wordBreak: 'break-word',
  },
  actionCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pinIcon: {
    marginRight: '1rem',
    fill: BLACK,
    '&:hover': {
      fill: MCKINSEY_BLUE,
    },
  },
}));
const ContentTabRow = ({
  row, editWave, deleteWave, selectedWave, isDisabled, setDefaultCohort,
}) => {
  const classes = useStyles();
  return (
    <>
      <TableRow
        key={row.wave_id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <Typography data-testid="ec_code" variant="body1" className={classes.wavetitle} onClick={() => selectedWave(row)}>{row.ec_code}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>{row.wave_name}</Typography>
        </TableCell>
        <TableCell align="left" className={classes.dateDuration}>
          <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
            {(row.start_date || row.end_date)
              ? `${row.start_date ? format(new Date(row.start_date), DATEFORMAT) : 'NA'} - ${row.end_date ? format(new Date(row.end_date), DATEFORMAT) : 'NA'}`
              : ''}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
            {row.expiration_date ? format(new Date(row.expiration_date), DATEFORMAT) : ''}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
            {row.user_count}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
            {row.cst_username}
          </Typography>
        </TableCell>
        <TableCell align="left" className={classes.formatCell}>
          <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
            {row.order_id}
          </Typography>
        </TableCell>
        <TableCell align="left" className={classes.formatCell}>
          <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
            {row.pdt_id}
          </Typography>
        </TableCell>
        <TableCell align="left" data-testid="ThreeDotButtonCell">
          <Box className={classes.actionCell}>
            {
              row.is_default && (
              <InfoTooltip title="This cohort is marked as the Default cohort for this LP" placement="top">
                <Box>
                  <DefaultCohortIcon className={classes.pinIcon} />
                </Box>
              </InfoTooltip>
              )
            }
            <CustomPopover
              list={[
                {
                  title: 'Edit Cohort',
                  onClick: () => editWave(row),
                  disabled: isDisabled,
                },
                {
                  title: 'Set as default cohort',
                  onClick: () => setDefaultCohort(row),
                  disabled: isDisabled || row.is_default,
                },
                {
                  title: 'Delete Cohort',
                  onClick: () => deleteWave(row),
                  disabled: isDisabled,
                },
              ]}
              button={ThreeDotButton}
              additionalClass={classes.customPopoverList}
            />
          </Box>

        </TableCell>
      </TableRow>
    </>
  );
};

const DefaultCohortIcon = ({ className }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" className={className} xmlns="http://www.w3.org/2000/svg">
    <path d="M9.146.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708l-1.175-1.176-4.498 8.088 1.38 1.38a.5.5 0 0 1 .058.638l-.057.07a.5.5 0 0 1-.708 0l-10-10a.5.5 0 1 1 .708-.708l1.379 1.38 8.088-4.498L9.146.854A.5.5 0 0 1 9.09.216zm-5.5 11.5a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708-.708zm7.409-8.884L2.967 7.26l5.772 5.772 4.498-8.088-2.182-2.182z" fillRule="evenodd" />
  </svg>
);

const WaveManagement = ({
  addNewWave,
  waveTableData,
  onDeleteWave,
  onEditWave,
  onSelectedWave,
  isLoading,
  transferUsers,
  isButtonDisabled,
  onSetDefaultCohort,
}) => {
  const classes = useStyles();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('is_default');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isLoading) return <LoadingCircle />;

  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      <Header
        heading="Enrollment Cohort Management"
        subHeading="Manage all enrollments within this Learning Plan."
        className={classes.headerWrapper}
      />
      <Paper className={classes.wrapper}>
        <Container maxWidth={false} className={classes.middleWrapper}>
          {waveTableData.length
            ? (
              <>
                <Box className={classes.tableTopSection}>
                  <Typography variant="h1">{`${waveTableData.length} enrollment cohorts`}</Typography>
                  <Box>
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{ marginRight: '1rem' }}
                      onClick={transferUsers}
                      disabled={isButtonDisabled}
                    >
                      Transfer Users Between Cohorts
                    </Button>
                    <Button disabled={isButtonDisabled} color="primary" variant="contained" onClick={addNewWave}>
                      Add a New Cohort
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <TableContainer style={{ maxHeight: 400 }}>
                    <Table aria-label="Bulk cohort table" className={classes.table} padding="normal" stickyHeader>
                      <EnhancedTableHeader
                        headCells={BULK_WAVE_TABLE_HEAD_ATTRIBUTES}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody className={classes.wrapperTableBody}>
                        {waveTableData.map((row) => (
                          !row.is_deleted && (
                          <ContentTabRow
                            row={row}
                            key={`content-${row.id}`}
                            editWave={onEditWave}
                            deleteWave={onDeleteWave}
                            selectedWave={onSelectedWave}
                            isDisabled={isButtonDisabled}
                            setDefaultCohort={onSetDefaultCohort}
                          />
                          )
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            )
            : (
              <Box>
                <NoDataComponent
                  imgSrc={NoData}
                  primaryText="This learning plan has no enrollment cohorts yet."
                  secondaryText="Please add a new cohort."
                />
                <div className={classes.buttonWrapper}>
                  <Button color="primary" variant="contained" onClick={addNewWave}>
                    Add a New Cohort
                  </Button>
                </div>
              </Box>
            ) }
        </Container>

      </Paper>
    </Paper>
  );
};
DefaultCohortIcon.propTypes = {
  className: PropTypes.string.isRequired,
};
WaveManagement.propTypes = {
  addNewWave: PropTypes.func.isRequired,
  waveTableData: PropTypes.array.isRequired,
  onDeleteWave: PropTypes.func.isRequired,
  onEditWave: PropTypes.func.isRequired,
  onSelectedWave: PropTypes.func.isRequired,
  onSetDefaultCohort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  transferUsers: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
};
ContentTabRow.propTypes = {
  editWave: PropTypes.func.isRequired,
  deleteWave: PropTypes.func.isRequired,
  selectedWave: PropTypes.func.isRequired,
  setDefaultCohort: PropTypes.func.isRequired,
  row: PropTypes.shape({
    wave_id: PropTypes.number.isRequired,
    ec_code: PropTypes.string.isRequired,
    wave_name: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string,
    user_count: PropTypes.number,
    client_id: PropTypes.string,
    cst_username: PropTypes.string,
    order_id: PropTypes.string,
    pdt_id: PropTypes.string,
    expiration_date: PropTypes.string,
    is_default: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
export default memo(
  WaveManagement,
  (prevProps, nextProps) => prevProps.waveTableData === nextProps.waveTableData,
);
