import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import PromptsCard from '../../../PromptsTab/PromptsCard';
import EmptyPrompts from '../../../../../../assets/img/EmptyPrompts.svg';
import NoDataComponent from '../../../../NoDataComponent';
import EmptySearchComponent from '../../../../../common/NoDataComponent';
import NoChannel from '../../../../../../assets/img/noChannel.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& > hr': {
      marginBottom: '1.2rem',
    },
  },
  cardContainer: {
    padding: '0.1rem 0.1rem',
  },
}));

const PromptsListContainer = ({
  prompts, onAddPrompt, onDeletePrompt, onEditPrompt, searchText, is_locked,
}) => {
  const classes = useStyles({});
  const isEmpty = !prompts.length;

  const cardsDom = prompts.map((prompt) => (
    <Box className={classes.cardContainer} key={prompt.id}>
      <PromptsCard
        prompt={prompt}
        onDeletePrompt={onDeletePrompt}
        onEditPrompt={onEditPrompt}
        is_locked={is_locked}
      />
    </Box>
  ));

  const getPromptJsx = () => {
    if (searchText && isEmpty) {
      return (
        <EmptySearchComponent
          imgSrc={NoChannel}
          primaryText="Oops! Could not find the prompt you were looking for."
          secondaryText="Adjust your search to see more results."
        />
      );
    } if (isEmpty) {
      return <NoDataComponent emptyIcon={EmptyPrompts} level="Prompts" onAdd={onAddPrompt} disableButton={is_locked} />;
    }
    return (
      <Box style={{ marginTop: '1.5rem' }}>
        {cardsDom}
      </Box>
    );
  };

  return (
    <Box className={classes.wrapper} square>
      {getPromptJsx()}
    </Box>
  );
};

PromptsListContainer.propTypes = {
  prompts: PropTypes.array.isRequired,
  onAddPrompt: PropTypes.func.isRequired,
  onDeletePrompt: PropTypes.func.isRequired,
  onEditPrompt: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  is_locked: PropTypes.bool.isRequired,
};

export default PromptsListContainer;
