import XLSX from 'xlsx';

export const readWB = (file, options = {}) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = (e) => {
    /* read workbook */
    try {
      const workbook = XLSX.read(e.target.result, {
        type: 'array',
        WTF: true,
        ...options,
      });
      resolve(workbook);
    } catch (err) {
      reject(err);
    }
  };
});

export const writeWS = (workbook, sheetData, options = {}) => XLSX.write(
  {
    ...workbook,
    Sheets: {
      ...workbook.Sheets,
      ...sheetData,
    },
  },
  { bookType: 'xlsx', type: 'array', ...options },
);
