import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  BLACK, MCKINSEY_BLUE, MODERATE_LIGHT_GREY,
  VERY_LIGHT_GREY, WHITE,
} from '../../stylesheets/colors';
import MCKINSEY_SANS from '../../stylesheets/fonts';
import LTIJournals from '../../components/ContentLibrary/LTIJournals';
import JournalDetail from '../../components/ContentLibrary/LTIJournals/JournalsTab/JournalDetail';
import PageDetail from '../../components/ContentLibrary/LTIJournals/JournalsTab/Pages/PageDetail';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundColor: VERY_LIGHT_GREY,
  },
  sideNav: {
    backgroundColor: WHITE,
    width: '15%',
    display: 'flex',
    flexDirection: 'column',
  },

  navBox: {
    height: '5.2rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',

    '& > p': {
      paddingLeft: '3rem',
      fontFamily: MCKINSEY_SANS,
      fontWeight: 600,
      fontSize: '1rem',
      color: BLACK,
    },
  },
  selectedCLTab: {
    borderLeft: `2px solid ${MCKINSEY_BLUE}`,
    background: MODERATE_LIGHT_GREY,
  },
});

const CONTENT_LIBRARY_TABS = [
  { value: 'lti_journals', label: 'Journals', component: LTIJournals },
];

const ContentLibrary = ({ match }) => {
  const classes = useStyles();
  const programSubType = get(match, 'params.programSubType');
  const feature = get(match, 'params.feature');
  const id = get(match, 'params.id');
  const [selectedCLTab, setSelectedCLTab] = useState({ value: 'lti_journals', label: 'Journals', component: LTIJournals });

  const getComponent = () => {
    let renderedComponent;
    if (id) {
      renderedComponent = feature === 'journals' ? <JournalDetail match={match} /> : <PageDetail />;
    } else if (programSubType === 'lti') {
      renderedComponent = (
        <>
          <Box className={classes.wrapper}>
            <Box className={classes.sideNav}>
              {CONTENT_LIBRARY_TABS.map(({ value, label, component }) => (
                // eslint-disable-next-line
                <Box key={value}
                  className={`${classes.navBox} ${value === selectedCLTab.value ? classes.selectedCLTab : ''}`}
                  onClick={() => { setSelectedCLTab({ value, label, component }); }}
                >
                  <Typography key={value}>{label}</Typography>
                </Box>
              ))}
            </Box>
            <LTIJournals match={match} />
          </Box>
        </>
      );
    }
    return renderedComponent;
  };

  return getComponent();
};

ContentLibrary.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      platformType: PropTypes.string,
      programSubType: PropTypes.string,
      feature: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default ContentLibrary;
