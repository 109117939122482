import React from 'react';
import PropTypes from 'prop-types';
import useCheckActivityStatus from '../../../hooks/useCheckActivityStatus';
import UnEnrollStatusModal from '../../UserUnenrollment/UnEnrollStatusModal';

const UserUnenrollment = ({
  transactionId, status, open, onClose, onDownloadLog,
  pollProgressValue, updatePollProgress, getReport, onCancelTransaction,
  disableCancelOperation, setCancellingTransaction,
}) => {
  const {
    modalState,
    preClose,
    progressData,
    progressErrorMsg,
  } = useCheckActivityStatus(status,
    open, onClose, pollProgressValue, transactionId, updatePollProgress);

  return (
    open ? (
      <UnEnrollStatusModal
        open={open}
        onClose={preClose}
        modalState={modalState}
        transactionId={transactionId}
        getReport={getReport}
        progressData={progressData}
        progressErrorMsg={progressErrorMsg}
        onDownloadLog={onDownloadLog}
        onCancelTransaction={onCancelTransaction}
        cancellationRequested={disableCancelOperation}
        setCancellationRequested={setCancellingTransaction}
      />
    ) : null
  );
};

UserUnenrollment.propTypes = {
  transactionId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  pollProgressValue: PropTypes.bool.isRequired,
  updatePollProgress: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  onCancelTransaction: PropTypes.func.isRequired,
  disableCancelOperation: PropTypes.bool.isRequired,
  setCancellingTransaction: PropTypes.func.isRequired,
};

export default UserUnenrollment;
