import React, {
  useState, useCallback, memo, useMemo,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { CancelToken, isCancel } from 'axios';
import { useFormik } from 'formik';
import { useCustomCompareCallback } from 'use-custom-compare';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReplayIcon from '@material-ui/icons/Replay';
import DoneIcon from '@material-ui/icons/Done';

import LabledTextField from '../common/LabledTextField';
// import Instruction from '../common/Instruction';
import TimelineStep from '../common/TimelineStep';
import EmptyTimeline from '../common/EmptyTimeline';

import { STATUS_SUCCESS } from '../../constants';
import {
  ERROR_RED,
  SUCCESS_GREEN,
  DARK_MEDIUM_GREY,
  MODERATE_LIGHT_GREY,
} from '../../stylesheets/colors';
import { trimValue } from '../../helpers/formattingHelpers';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3rem 0',
    flex: 0.66,
    borderRight: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  heading: {
    fontWeight: 'bold',
    color: DARK_MEDIUM_GREY,
  },
  form: {
    display: 'flex',
    flex: 0.5,
    flexDirection: 'column',
    marginTop: '4rem',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '13.5rem',
    flex: 0.2,
  },
  configIdPanel: {
    width: '60%',
    flex: 0.4,
    display: 'flex',
    flexDirection: 'column',
  },
  success: {
    color: SUCCESS_GREEN,
  },
  error: {
    color: ERROR_RED,
  },
  instructionWrapper: {
    display: 'flex',
    flex: 0.34,
    flexDirection: 'column',
  },
  header: {
    color: DARK_MEDIUM_GREY,
  },
  timelineWrapper: {
    display: 'flex',
    flex: 0.34,
    flexDirection: 'column',
    marginTop: '3rem',
    height: '100vh',
    overflowX: 'auto',
    '@media (min-width: 600px)': {
      paddingLeft: '18px',
      paddingRight: '18px',
    },
  },
  noFlex: {
    flex: 'unset',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

let cancelTokenSource;

const EditProgramFormPage = ({
  className, sourceConfigId, onNext, checkNewConfigIdExists, timelineData, downloadLog,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      configId: sourceConfigId,
    },
    onSubmit: (values) => onNext(values),
  });

  const {
    handleSubmit, handleChange, setErrors, values, errors, resetForm,
  } = formik;

  const checkUnique = useCallback(
    async (configId) => {
      // Check unique
      setLoading(true);
      try {
        cancelTokenSource = CancelToken.source();
        await checkNewConfigIdExists(trimValue(configId), sourceConfigId, cancelTokenSource.token);
        batchUpdates(() => {
          setDisable(false);
          setLoading(false);
        });
      } catch (e) {
        if (isCancel(e)) {
          return;
        }

        const msg = _get(e, 'response.data.message');
        if (msg) {
          console.log(msg);
          batchUpdates(() => {
            setErrors({
              configId: {
                api: true,
                message: msg,
              },
            });
            setLoading(false);
          });
          return;
        }
        setLoading(false);
      }
    },
    [checkNewConfigIdExists, sourceConfigId, setErrors],
  );

  const onBlur = useCustomCompareCallback(
    () => {
      if (errors.configId && values.configId.length <= 25) {
        checkUnique(values.configId);
      }
    },
    [errors, values, checkUnique],
    (prevDeps, nextDeps) => prevDeps[0].configId === nextDeps[0].configId
      || prevDeps[1].configId === nextDeps[1].configId,
  );

  const onReset = useCallback(() => {
    // Cancel old request
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
      setLoading(false);
      setDisable(true);
    }

    resetForm();
  }, [resetForm]);

  const handleValidateOnChange = useCallback(
    (val) => {
      // Cancel old request
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
        setLoading(false);
      }

      setDisable(true);
      if (!val.length) {
        setErrors({
          configId: {
            api: false,
            message: 'Program ID should not be empty',
          },
        });
      } else if (val.length > 25) {
        setErrors({
          configId: {
            api: false,
            message: 'Program ID should not exceed 25 characters',
          },
        });
      } else {
        checkUnique(val);
      }
    },
    [setErrors, checkUnique],
  );

  const debouncedChange = useCallback(_debounce(handleValidateOnChange, 500), [
    handleValidateOnChange,
  ]);

  const finalTimelineData = useMemo(() => timelineData.reduce((ini, v) => {
    if (v.learning_plan !== null || v.courses !== null) {
      ini.push({
        ...v,
        completed: v.learning_plan === STATUS_SUCCESS
          && v.courses === STATUS_SUCCESS
          && v.cohort_group === STATUS_SUCCESS
          && v.channels === STATUS_SUCCESS
          && v.enrollment_rules === STATUS_SUCCESS,
      });
    }
    return ini;
  }, []), [timelineData]);

  const getIcon = useMemo(() => {
    let icon = '';

    if (loading) {
      icon = <CircularProgress size="2rem" />;
    } else if (disable && !_isEmpty(errors.configId) && errors.configId.api) {
      icon = <ReplayIcon size="2rem" color="primary" className={classes.pointer} onClick={() => checkUnique(values.configId)} />;
    } else if (!disable) {
      icon = <DoneIcon size="2rem" className={classes.success} />;
    }
    return icon;
  }, [
    loading,
    disable,
    checkUnique,
    classes.success,
    errors.configId,
    values.configId,
    classes.pointer,
  ]);

  return (
    <Container className={`${classes.paper} ${className}`}>
      <div className={classes.wrapper}>
        <Typography className={classes.heading}>Modify Config ID</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.configIdPanel}>
            <LabledTextField
              className={classes.noFlex}
              label="Modify existing config ID"
              value={values.configId}
              inputProps={{
                autoFocus: true,
                inputProps: { name: 'configId', onBlur },
                InputProps: {
                  endAdornment: getIcon,
                },
              }}
              onChange={(e) => {
                handleChange(e);
                debouncedChange(e.target.value);
              }}
            />
            {!_isEmpty(errors.configId) && (
              <Typography className={classes.error}>{errors.configId.message}</Typography>
            )}
          </div>
          <div className={classes.button}>
            <Button variant="outlined" color="primary" type="button" onClick={onReset}>
              Reset
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={disable}>
              Next
            </Button>
          </div>
        </form>
      </div>
      <Container className={classes.timelineWrapper}>
        <Typography className={classes.header}>Activity Log</Typography>
        {finalTimelineData.length ? (
          finalTimelineData.map((data) => (
            <TimelineStep
              key={data.transaction_id}
              data={data}
              link={`${pathname.replace(new RegExp('/$', 'g'), '')}/${data.transaction_id}#view`}
              downloadLog={() => downloadLog(data.transaction_id, data.new_id)}
              successMsg="Program renamed successfully"
              errorMsg="Program renamed with errors"
            >
              <Typography variant="body1">{`Old Code: ${data.old_id}`}</Typography>
              <Typography variant="body1">{`New Code: ${data.new_id}`}</Typography>
            </TimelineStep>
          ))
        ) : (
          <EmptyTimeline message="No modification done yet..." />
        )}
      </Container>
    </Container>
  );
};

EditProgramFormPage.defaultProps = {
  className: '',
};

EditProgramFormPage.propTypes = {
  className: PropTypes.string,
  sourceConfigId: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  downloadLog: PropTypes.func.isRequired,
  checkNewConfigIdExists: PropTypes.func.isRequired,
  timelineData: PropTypes.arrayOf(PropTypes.shape({
    transaction_id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    learning_plan: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]).isRequired,
    courses: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]).isRequired,
    cohort_group: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]).isRequired,
    channels: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]).isRequired,
    enrollment_rules: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]).isRequired,
    new_id: PropTypes.string.isRequired,
    old_id: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
  })).isRequired,
};

export default memo(EditProgramFormPage, (prevProps, nextProps) => (
  prevProps.className === nextProps.className
  && prevProps.sourceConfigId === nextProps.sourceConfigId && prevProps.onNext === nextProps.onNext
  && prevProps.checkNewConfigIdExists === nextProps.checkNewConfigIdExists
  && _isEqual(prevProps.timelineData, nextProps.timelineData)
  && prevProps.downloadLog === nextProps.downloadLog
));
