import React, { useState } from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import Popover from '@material-ui/core/Popover';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import EmptyTimeline from '../common/EmptyTimeline';
import TimelineStep from '../common/TimelineStep';
import { MODERATE_LIGHT_GREY } from '../../stylesheets/colors';
import AssignmentListingTable from './AssignmentListingTable';
import RefreshSection from '../RefreshSection';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem 0 2rem',
    flex: 1,
    borderRight: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  headWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  timelineWrapper: {
    // display: 'none',
    flex: 0.25,
    flexDirection: 'column',
    marginTop: '3rem',
    height: '100vh',
    overflowX: 'auto',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.93,
    marginTop: '1rem',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: '1rem',
  },
  popoverWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    width: '270px',
  },
  popoverSection: {
    flex: 1,

    '& svg': {
      borderBottom: '1px solid #000000',
      fontSize: '17px',
      marginRight: '8px',
    },

    '& button': {
      color: '#000000',
      borderColor: '#000000',
      paddingTop: 0,
      paddingBottom: 0,

      '&:hover': {
        borderColor: '#000000',
      },
    },

    '& h6': {
      margin: '8px 0',
    },
  },
}));

const PopoverSection = ({ title, handleClick, info }) => {
  const classes = useStyles();

  return (
    <div className={classes.popoverSection}>
      <Button className={classes.button} color="primary" variant="outlined" onClick={handleClick}>
        <ArrowDownwardIcon />
        <Typography variant="subtitle2">{title}</Typography>
      </Button>
      <Typography variant="subtitle2" className={classes.info}>{info}</Typography>
    </div>
  );
};

PopoverSection.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

const AssignmentManagementContent = ({
  disable,
  onDownload,
  onDownloadMetadata,
  onRefresh,
  setDisable,
  data,
  loading,
  setAssignmentList,
  setSuccessBanner,
  activityReport,
  onActivityLogsDownload,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (fn) => {
    fn();
    handleClose();
  };

  const clearSelection = () => {
    setDisable(true);
    setSuccessBanner(false);
  };

  const tableBody = (
    <>
      <div>
        <div className={classes.headWrapper}>
          <RefreshSection onRefresh={onRefresh} />
          {!!data.length && (
            <div className={classes.buttonWrapper}>
              <Button variant="outlined" color="primary" className={classes.button} disabled={disable} onClick={clearSelection}>
                Clear selection
              </Button>
              <Button color="primary" variant="contained" disabled={disable} onClick={handleClick}>
                Download
              </Button>
            </div>
          )}
        </div>
        <Box className={classes.tableRow}>
          {data.length
            ? (
              <AssignmentListingTable
                data={data}
                disable={disable}
                setDisable={setDisable}
                setAssignmentList={setAssignmentList}
              />
            )
            : <Typography variant="subtitle1">No Assignment available for download</Typography>}
        </Box>
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popoverWrapper}>
          <PopoverSection
            title="Download New Assignments"
            info="Download only newly updated submissions since the last download"
            handleClick={() => handlePopoverClick(onDownload.bind(this, true))}
          />
          <PopoverSection
            title="Download Metadata File"
            info="Download only metadata file for selected assignments"
            handleClick={() => handlePopoverClick(onDownloadMetadata)}
          />
          <PopoverSection
            title="Download All Assignments"
            info="Download all submissions for the selected fields"
            handleClick={() => handlePopoverClick(onDownload)}
          />
        </div>
      </Popover>
    </>
  );
  const tableDom = loading
    ? (<Typography variant="subtitle1">Loading...</Typography>)
    : tableBody;

  return (
    <div className={`${classes.paper}`}>
      <Container className={classes.wrapper}>
        {tableDom}
      </Container>
      <Container className={classes.timelineWrapper}>
        <Typography className={classes.heading}>Activity Log</Typography>
        {activityReport.length ? (
          activityReport.map((log) => (
            <TimelineStep
              key={log?.transaction_id}
              data={{
                ...log,
                created_by: log.triggered_by,
                completed: log.success,
              }}
              downloadLog={!log.success ? () => onActivityLogsDownload(log) : null}
              hideView
              successMsg="Assignment downloaded successfully"
              errorMsg="Assignment could not be downloaded"
            />
          ))
        ) : (
          <EmptyTimeline message="No assignment download log..." />
        )}
      </Container>
    </div>
  );
};

AssignmentManagementContent.propTypes = {
  disable: PropTypes.bool.isRequired,
  onDownload: PropTypes.func.isRequired,
  onDownloadMetadata: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  setDisable: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  setAssignmentList: PropTypes.func.isRequired,
  setSuccessBanner: PropTypes.func.isRequired,
  activityReport: PropTypes.arrayOf(PropTypes.object).isRequired,
  onActivityLogsDownload: PropTypes.func.isRequired,
};

export default AssignmentManagementContent;
