import { makeStyles } from '@material-ui/core/styles';
import * as COLORS from '../../stylesheets/colors';

export const loaderUpperSemiCircleStyle = (progress) => {
  const rotationBase = (progress > 50) ? -135 : 45;
  const rotationValue = (progress * 360) / 100;
  const totalRotation = rotationBase + rotationValue;
  const color = progress > 50 ? COLORS.MCKINSEY_BLUE : COLORS.WHITE;
  return {
    borderTopColor: color,
    borderRightColor: color,
    transform: `rotate(${totalRotation}deg)`,
  };
};

const useStyles = makeStyles(() => ({
  loaderContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
  loaderBaseCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: ({ scaleFactor }) => `${200 * scaleFactor}px`,
    height: ({ scaleFactor }) => `${200 * scaleFactor}px`,
    backgroundColor: COLORS.WHITE,
    borderRadius: '50%',
    boxShadow: `0px 3px 10px -2px ${COLORS.MEDIUM_GREY}`,
  },
  loaderSemiCircle: {
    position: 'absolute',
    width: ({ scaleFactor }) => `${200 * scaleFactor}px`,
    height: ({ scaleFactor }) => `${200 * scaleFactor}px`,
    border: `25px solid ${COLORS.MCKINSEY_BLUE}`,
    borderRadius: '50%',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    transform: 'rotate(45deg)',
  },
  loaderLabelCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: ({ scaleFactor }) => `${200 * scaleFactor - 50}px`,
    height: ({ scaleFactor }) => `${200 * scaleFactor - 50}px`,
    backgroundColor: COLORS.WHITE,
    borderRadius: '50%',
    boxShadow: `0px 3px 10px -2px ${COLORS.MEDIUM_GREY}`,
    fontSize: ({ scaleFactor }) => `${200 * scaleFactor - 50}%`,
  },
  loaderLabelText: {
    fontSize: '300%',
  },
  loaderSubText: {
    padding: '30px',
    fontSize: '150%',
    textAlign: 'center',
  },
  estimatedTimeDiv: {
    padding: '30px',
    fontSize: '150%',
    textAlign: 'center',
  },
  loaderButtonsRow: {
    display: 'flex',
  },
  loaderCancelButton: {
    backgroundColor: COLORS.ERROR_RED,
    borderColor: COLORS.ERROR_RED,
    marginLeft: '1rem',
  },
}));

export default useStyles;
