import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import AlertStatusIcon from '../../assets/icons/alert-status-icon.svg';

const useStyles = makeStyles(() => ({
  iconWrapper: {
    height: '2rem',
    width: '4rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgressPadding: {
    padding: '1rem',
  },
  listPadding: {
    padding: '1rem 10rem 1rem 10rem',
    width: '60%',
    margin: '0 auto',
  },
}));

const getSrc = (result) => {
  if (result === 'success') {
    return CheckRoundIcon;
  } if (result === 'error') {
    return ErrorRoundIcon;
  }
  return AlertStatusIcon;
};

const ReportOverviewModal = ({
  steps,
}) => {
  const classes = useStyles();
  return (
    <List aria-label="main" dense classes={{ padding: classes.listPadding }}>
      {steps.map((step) => (step ? (
        <ListItem key={step}>
          <ListItemIcon className={classes.iconWrapper}>
            <img
              src={getSrc(step.type)}
              alt="check-round-green"
              className={classes.circularProgressPadding}
            />
          </ListItemIcon>
          <ListItemText id="switch-list-label-bluetooth" primary={step?.primary} secondary={step?.secondary} />
        </ListItem>
      ) : null))}
    </List>
  );
};

export default ReportOverviewModal;

ReportOverviewModal.propTypes = {
  steps: PropTypes.array.isRequired,
};
