import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { format, parse } from 'date-fns';
import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';

import { MODERATE_LIGHT_GREY } from '../../stylesheets/colors';
import { getUTCDate } from '../../helpers/formattingHelpers.js';

const useStyles = makeStyles((theme) => ({
  button: {
    '&.active': {
      color: theme.palette.primary.main,
    },
    '& svg': {
      width: '5px',
      margin: '0 1px',
    },
  },
  tableHead: {
    background: MODERATE_LIGHT_GREY,
  },
}));

const WorkshopManagementEventTable = ({
  editEvent, deleteEvent, events, disabledList,
}) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table size="small">
        {events.length > 0 && (
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell align="left">Start Date</TableCell>
              <TableCell align="left">Start Time</TableCell>
              <TableCell align="left">Duration</TableCell>
              <TableCell align="left">Timezone</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="left">Attendance</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {events.map((event) => (
            <TableRow key={event.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {event.name}
              </TableCell>
              <TableCell align="left">{format(getUTCDate(event?.date), 'dd MMM yyyy')}</TableCell>
              <TableCell align="left">{format(parse(event?.schedule?.time_begin, 'HH:mm:ss', new Date()), 'hh:mm a')}</TableCell>
              <TableCell align="left">{event?.duration}</TableCell>
              <TableCell align="left">{event?.schedule?.timezone}</TableCell>
              <TableCell align="left">{event?.venue}</TableCell>
              <TableCell align="left">{event?.attendance}</TableCell>
              <TableCell align="left">
                <CustomPopover
                  list={[
                    {
                      title: 'Edit event details',
                      onClick: () => editEvent(event.id),
                      disabled: disabledList,
                    },
                    {
                      title: 'Delete Event',
                      onClick: () => deleteEvent(event.id, event.name),
                      disabled: disabledList,

                    },
                  ]}
                  button={ThreeDotButton}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
WorkshopManagementEventTable.defaultProps = {
  disabledList: false,
};

WorkshopManagementEventTable.propTypes = {
  editEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  disabledList: PropTypes.bool,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      venue: PropTypes.string.isRequired,
      attendance: PropTypes.number.isRequired,
      schedule: PropTypes.object.isRequired,
    }),
  ).isRequired,
};

// eslint-disable-next-line max-len
export default memo(WorkshopManagementEventTable, (prevProps, nextProps) => _isEqual(prevProps.events, nextProps.events));
