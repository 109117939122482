import PropTypes from 'prop-types';
import React from 'react';
import {
  FieldArray,
} from 'formik';
import ScormRow from './ScormRow';

const ExpandedTmsComponent = ({
  isDisabled,
  courseIndex,
  coureseRow,
  courseId,
  formik,
  courseChecked,
  isExpanded,
  tableBodyRow,
  programId,
  programType,
  updateScorm,
  courseName,
  progressReport,
  isDDAdmin,
  disabledActions,
}) => (
  <FieldArray name={`${coureseRow}.lo`}>
    {() => (
      <>
        {formik?.values?.courses[courseIndex]?.training_materials?.map((scormObj, index) => (
          <ScormRow
            key={scormObj.name}
            scormIndex={index}
            scorm={scormObj}
            formik={formik}
            courseChecked={courseChecked}
            coureseRow={coureseRow}
            courseId={courseId}
            isExpanded={isExpanded}
            isDisabled={isDisabled}
            courseIndex={courseIndex}
            tableBodyRow={tableBodyRow}
            programId={programId}
            programType={programType}
            updateScorm={updateScorm}
            courseName={courseName}
            progressReport={progressReport}
            isDDAdmin={isDDAdmin}
            disabledActions={disabledActions}
          />
        ))}
      </>

    )}
  </FieldArray>
);

ExpandedTmsComponent.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
  }).isRequired,
  courseIndex: PropTypes.string.isRequired,
  coureseRow: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  tableBodyRow: PropTypes.string.isRequired,
  courseChecked: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
  programType: PropTypes.string.isRequired,
  updateScorm: PropTypes.func.isRequired,
  courseName: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
    percentage: PropTypes.string.isRequired,
  }).isRequired,
  progressReport: PropTypes.array.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  disabledActions: PropTypes.bool.isRequired,
};

export default ExpandedTmsComponent;
