import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CancelToken } from 'axios';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { CHANNELS, COURSES } from '../../constants';

import ContentScreen from '../../components/Overview/ContentScreen';
import RefreshSection from '../../components/Overview/RefreshSection';

import { getContent, getEnrollment } from './apis';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  refreshWrapper: {
    marginTop: '2rem',
  },
});

let cancelTokenSource;
let contentTypeValue;
const getEnrollmentsHelper = async (type, data, update) => {
  const enrollmentPromises = data.map((row) => getEnrollment(type, row.docebo_course_id, update));
  const enrollmentResponses = await Promise.all(enrollmentPromises);
  const dataWithEnrollments = data.map((row, idx) => ({
    ...row,
    enrollment_count: get(enrollmentResponses[idx], 'data.enrolled', null),
  }));

  return dataWithEnrollments;
};

const fetchData = async (programId, contentType, setData, setLastRefreshed, update) => {
  // TODO: Make the next requests cancelable

  setData([]);
  cancelTokenSource = CancelToken.source();
  const newData = await getContent(programId, contentType, update, cancelTokenSource.token);
  const loadingData = newData.data.map((row) => ({ ...row, enrollment_count: 'Loading...' }));
  setData(loadingData);
  setLastRefreshed(newData.last_refreshed);

  if (contentType === CHANNELS) return; // Early exit

  try {
    const dataWithEnrollments = await getEnrollmentsHelper(contentType, newData.data, update);
    if (contentTypeValue !== CHANNELS) setData(dataWithEnrollments);
  } catch (e) {
    console.error(e);
    const errorData = newData.data.map((row) => ({ ...row, enrollment_count: 'Unable to fetch' }));
    setData(errorData);
  }
};

const OverviewContentPage = (props) => {
  const { match } = props;

  const classes = useStyles();
  const programId = get(match, 'params.programId');

  const [type, setType] = useState(COURSES);
  const [data, setData] = useState([]);
  const [lastRefreshed, setLastRefreshed] = useState(null);
  contentTypeValue = type;
  useEffect(() => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    fetchData(programId, type, setData, setLastRefreshed, false);
  }, [programId, type]);

  const onRefresh = async () => {
    await fetchData(programId, type, setData, setLastRefreshed, true);
  };

  return (
    <div className={classes.wrapper}>
      {type === COURSES && (
      <div className={classes.refreshWrapper}>
        <RefreshSection onRefresh={onRefresh} lastRefreshed={lastRefreshed} />
      </div>
      )}
      <ContentScreen type={type} setType={setType} data={data} />
    </div>
  );
};

OverviewContentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({}).isRequired,
};

export default OverviewContentPage;
