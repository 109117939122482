import config from '../../../config';
import request from '../../../helpers/apiHelper';

const fetchUsers = async (data) => {
  const { params, body } = data;
  const url = `${config.ROUTES.USER_MANAGEMENT_USERS}`;
  const options = {
    url, method: 'POST', params, data: body,
  };
  const resp = await request(options);
  return resp?.data;
};

export const fetchUserDetails = async (user_id) => {
  const url = `${config.ROUTES.USER_MANAGEMENT_USER_DETAILS}/secondary_view/${user_id}`;
  const options = { url, method: 'GET' };
  const resp = await request(options);
  return resp?.data;
};

export const savePasswordInfo = async (data, user_id) => {
  const url = `${config.ROUTES.USER_MANAGEMENT_USER_DETAILS}/edit_user_password/${user_id}`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result?.data;
};

export const saveUserInfo = async (data, user_id) => {
  const url = `${config.ROUTES.USER_MANAGEMENT_USER_DETAILS}/edit_user/${user_id}`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result?.data;
};

export const validateUserInfo = async (data, user_id) => {
  const url = `${config.ROUTES.USER_MANAGEMENT_USER_DETAILS}/edit_user/${user_id}/validate`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result?.data;
};

export const saveDoceboPlatformDetails = async (data, user_id) => {
  const url = `${config.ROUTES.USER_MANAGEMENT_USER_DETAILS}/edit_user_platform_details/${user_id}`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result?.data;
};

export const fetchClientData = async (requestParam, cancelToken) => {
  const url = `${config.ROUTES.MASTER_DATA_CLIENT}`;
  const options = {
    url, method: 'GET', cancelToken, params: requestParam,
  };
  const result = await request(options);
  return result.data;
};

export default fetchUsers;
