import React, { useState, useEffect, useRef } from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import _find from 'lodash/find';
import _get from 'lodash/get';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { ContentDetailsSection, ContentDetailsLabel } from './ContentDetailsSection';
import ContentDetailsBlock from './ContentDetailsBlock';
import {
  COMPONENT_CODE_MAX_LIMIT, COMPONENT_TITLE_MAX_LIMIT,
} from '../../constants';
import { getPortfolioOfferingProgram } from '../../containers/common/apis';
import { toArrayOfObject, getSquareBracketsString } from '../../helpers/utils';

const useStyles = makeStyles({
  divider: {
    width: '98%',
    marginBottom: ({ hasContentHeader }) => (hasContentHeader ? '0rem' : '2rem'),
  },
});

const ContentSettingDetailsPage = ({
  isEdit, values, errors, touched, handleChange, setFieldValue, languages,
}) => {
  const classes = useStyles();

  const getadditionalFieldName = (field) => (`additional_fields[${field}].value`);

  const getadditionalFieldValue = (field) => (values?.additional_fields?.[field]?.value);

  const getadditionalFieldError = (field) => (errors?.additional_fields?.[field]?.value);

  const getadditionalFieldTouched = (field) => (touched?.additional_fields?.[field]?.value);

  const dropdownRef = useRef({});
  dropdownRef.current = {};

  const [portfolioOption, setPortfolioOptions] = useState([]);
  const [offeringOption, setOfferingOptions] = useState([]);
  const [programOption, setProgramOptions] = useState([]);

  const onDropdownChange = (key, val) => {
    if (val !== getadditionalFieldValue('Portfolio') && key === 'Portfolio') {
      if (dropdownRef.current.Offering) {
        dropdownRef.current.Offering.select.clearValue();
      }
      if (dropdownRef.current.Program) {
        dropdownRef.current.Program.select.clearValue();
      }
      batchUpdates(() => {
        setFieldValue(getadditionalFieldName(key), val);
        setOfferingOptions([]);
        setProgramOptions([]);
        setFieldValue(getadditionalFieldName('Offering'), '');
        setFieldValue(getadditionalFieldName('Program'), '');
      });
    } else if (val !== getadditionalFieldValue('Offering') && key === 'Offering') {
      if (dropdownRef.current.Program) {
        dropdownRef.current.Program.select.clearValue();
      }
      batchUpdates(() => {
        setProgramOptions([]);
        setFieldValue(getadditionalFieldName(key), val);
        setFieldValue(getadditionalFieldName('Program'), '');
      });
    } else {
      setFieldValue(getadditionalFieldName(key), val);
    }
  };

  const addToRefs = (el, key) => {
    if (el && !dropdownRef.current[key]) {
      dropdownRef.current[key] = el;
    }
  };

  const fetchPortfolioOfferingProgram = async (data) => {
    const response = await getPortfolioOfferingProgram(data);
    let optionArray = _get(response, 'data.data', []);
    if (!data) {
      setPortfolioOptions(toArrayOfObject(optionArray));
    } else if (data.portfolio) {
      setOfferingOptions(toArrayOfObject(optionArray));
    } else if (data.offering) {
      optionArray = optionArray.filter((item) => item);
      setProgramOptions(toArrayOfObject(optionArray));
    }
  };

  const portfolioValue = getadditionalFieldValue('Portfolio');
  const offeringValue = getadditionalFieldValue('Offering');

  useEffect(() => {
    if (isEdit) {
      fetchPortfolioOfferingProgram();
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && portfolioValue) {
      fetchPortfolioOfferingProgram({ portfolio: portfolioValue });
    }
  }, [isEdit, portfolioValue]);

  useEffect(() => {
    if (isEdit && offeringValue) {
      fetchPortfolioOfferingProgram({ offering: offeringValue });
    }
  }, [isEdit, offeringValue]);

  return (
    <Grid container item spacing={2}>
      <ContentDetailsBlock headerText="Component Details">
        <ContentDetailsSection
          label="Title"
          name="name"
          type="text"
          required
          handleChange={(e) => {
            if (e.target.value.length <= COMPONENT_TITLE_MAX_LIMIT) {
              handleChange(e);
            }
          }}
          isEdit={isEdit}
          fieldType="text"
          value={values.name}
          hasError={touched.name && !!errors.name}
          errorText={touched.name && errors.name}
          showTextLimit
          maxTextLimit={COMPONENT_TITLE_MAX_LIMIT}
        />
        <ContentDetailsSection
          label="Code"
          name="code"
          type="text"
          required
          isEdit={isEdit}
          fieldType="text"
          handleChange={(e) => {
            if (e.target.value.length <= COMPONENT_CODE_MAX_LIMIT) {
              handleChange(e);
            }
          }}
          value={values.code}
          hasError={touched.code && !!errors.code}
          errorText={touched.code && errors.code}
          showTextLimit
          maxTextLimit={COMPONENT_CODE_MAX_LIMIT}
        />
        <ContentDetailsSection
          label="Description"
          name="description"
          type="text"
          required
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={values.description}
          hasError={touched.description && !!errors.description}
          errorText={touched.description && errors.description}
        />
        <ContentDetailsSection label="Thumbnail" type="image" value={values.thumbnail} />
        <ContentDetailsSection label="ID" type="text" value={values.uidCourse} />
        <ContentDetailsSection label="Type" type="chip" value={values.type} />
      </ContentDetailsBlock>
      <ContentDetailsBlock headerText="Settings">
        <ContentDetailsSection
          label="Language"
          name="lang_label"
          type="text"
          fieldType="select"
          isEdit={isEdit}
          handleChange={(n, v, b) => {
            setFieldValue(n, v, b);
            setFieldValue('lang_code', _find(languages, { label: v }).code, b);
          }}
          value={values.lang_label}
          options={languages}
        />

        {!(values?.type?.includes('classroom')) && (
          <>
            <ContentDetailsSection
              label="Average Time"
              infoText="Define average time for the component"
              name="duration"
              type="text"
              fieldType="text"
              disabled={moment() < moment(values.date_end)}
              isEdit={isEdit}
              handleChange={handleChange}
              value={values.duration}
              hasError={touched.duration && !!errors.duration}
              errorText={touched.duration && errors.duration}
            />
            <Divider className={classes.divider} variant="middle" light />
            <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
              <ContentDetailsLabel label="Component Validity" />
            </Grid>
            <ContentDetailsSection
              label="Start Date"
              infoText="Learners will be able to access the course from the selected date onward, provided the component is in Published status"
              type="text"
              value={values.date_begin}
              name="date_begin"
              fieldType="calendar"
              isEdit={isEdit}
              hasError={touched.date_begin && !!errors.date_begin}
              errorText={touched.date_begin && errors.date_begin}
              handleChange={setFieldValue}
            />
            <ContentDetailsSection
              label="End Date"
              infoText="Learners will be able to access the course up to the selected date, provided the component is in Published status"
              name="date_end"
              type="text"
              value={values.date_end}
              fieldType="calendar"
              isEdit={isEdit}
              hasError={touched.date_end && !!errors.date_end}
              errorText={touched.date_end && errors.date_end}
              handleChange={setFieldValue}
            />
            {values.soft_deadline !== null && values.date_end && (
              <ContentDetailsSection
                label="Soft Deadline"
                name="soft_deadline"
                type="text"
                fieldType="switch"
                value={values.soft_deadline}
                isEdit={isEdit}
                handleChange={setFieldValue}
                alertText={values?.soft_deadline ? 'Learners can access the component training material after the component end date' : 'Learners cannot access the component training material after the component end date'}
              />
            )}
            <Divider className={classes.divider} variant="middle" light />
          </>
        )}
        <ContentDetailsSection
          label="Credits (CEUs)"
          infoText="Set the number of credits assigned to this course (greater than 0)"
          name="credits"
          type="text"
          value={values.credits}
          fieldType="text"
          isEdit={isEdit}
          handleChange={handleChange}
          hasError={touched.credits && !!errors.credits}
          errorText={touched.credits && errors.credits}
        />
        <ContentDetailsSection
          label="EOP Feedback Component (For Impact Tracking)"
          name="eop"
          type="text"
          infoText="Indicates this is/is not an end-of-program (EOP) feedback survey. This allows Impact Tracking team to gather impact metrics Academy-wide"
          value={values.eop ? values.eop : 0}
          fieldType="switch"
          isEdit={false}
        />
      </ContentDetailsBlock>
      <ContentDetailsBlock headerText="Additional Details">
        <ContentDetailsSection
          label="Portfolio"
          name={getadditionalFieldName('Portfolio')}
          required
          type="text"
          fieldType="select"
          options={portfolioOption}
          handleChange={(name, val) => onDropdownChange(getSquareBracketsString(name)[1], val)}
          addToRefs={(el) => addToRefs(el, 'Portfolio')}
          value={getadditionalFieldValue('Portfolio')}
          isEdit={isEdit}
          hasError={getadditionalFieldTouched('Portfolio')
            && !!getadditionalFieldError('Portfolio')}
          errorText={getadditionalFieldTouched('Portfolio')
            && getadditionalFieldError('Portfolio')}
        />
        <ContentDetailsSection
          label="Offering"
          name={getadditionalFieldName('Offering')}
          required
          type="text"
          fieldType="select"
          options={offeringOption}
          handleChange={(name, val) => onDropdownChange(getSquareBracketsString(name)[1], val)}
          addToRefs={(el) => addToRefs(el, 'Offering')}
          value={getadditionalFieldValue('Offering')}
          isEdit={isEdit}
          hasError={getadditionalFieldTouched('Offering')
            && !!getadditionalFieldError('Offering')}
          errorText={getadditionalFieldTouched('Offering')
            && getadditionalFieldError('Offering')}
        />
        <ContentDetailsSection
          label="Program"
          name={getadditionalFieldName('Program')}
          options={programOption}
          handleChange={(name, val) => onDropdownChange(getSquareBracketsString(name)[1], val)}
          addToRefs={(el) => addToRefs(el, 'Program')}
          value={getadditionalFieldValue('Program')}
          type="text"
          fieldType="select"
          isEdit={isEdit}
        />
        <ContentDetailsSection
          label="Elucidat Project ID"
          name={getadditionalFieldName('Elucidat Project ID')}
          type="text"
          value={getadditionalFieldValue('Elucidat Project ID')}
          fieldType="text"
          isEdit={isEdit}
          handleChange={handleChange}
        />
        <ContentDetailsSection
          label="Component Name"
          name={getadditionalFieldName('Course Name')}
          type="text"
          value={getadditionalFieldValue('Course Name')}
          fieldType="text"
          isEdit={isEdit}
          handleChange={handleChange}
        />
        <Divider className={classes.divider} variant="middle" light />
        <ContentDetailsSection
          label="Component Runner"
          name={getadditionalFieldName('Course runner')}
          type="text"
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={getadditionalFieldValue('Course runner')}
          hasError={getadditionalFieldTouched('Course runner')
            && !!getadditionalFieldError('Course runner')}
          errorText={getadditionalFieldTouched('Course runner')
            && getadditionalFieldError('Course runner')}
        />
        <ContentDetailsSection
          label="Additional Metadata"
          name={getadditionalFieldName('Additional Metadata')}
          type="text"
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={getadditionalFieldValue('Additional Metadata')}
        />
        <ContentDetailsSection
          label="Keep in mind"
          name={getadditionalFieldName('Keep in mind')}
          type="text"
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={getadditionalFieldValue('Keep in mind')}
        />
        <ContentDetailsSection
          label="After you complete the component"
          name={getadditionalFieldName('After you complete this course')}
          type="text"
          isEdit={isEdit}
          fieldType="textarea"
          handleChange={handleChange}
          value={getadditionalFieldValue('After you complete this course')}
        />
      </ContentDetailsBlock>
    </Grid>
  );
};

ContentSettingDetailsPage.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
};

export default ContentSettingDetailsPage;
