import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import {
  ACCORDION_GREY, LIGHT_MEDIUM_GREY, MCKINSEY_BLUE, MODERATE_DARK_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  breadCrumbText: {
    color: `${ACCORDION_GREY}`,
    letterSpacing: 'normal',
  },
  activeBreadCrumbText: {
    color: `${MODERATE_DARK_GREY}`,
  },
  nonActiveBreadCrumbText: {
    '&:hover': {
      color: `${MCKINSEY_BLUE}`,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const BreadCrumbHOC = ({ list, separatorIcon, customJsx }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.wrapper}>
      <Breadcrumbs aria-label="breadcrumb" separator={separatorIcon}>
        {list.map((obj) => (
          <Typography
            variant="h3"
            component="span"
            key={obj.label}
            className={`${classes.breadCrumbText} ${obj.isActive ? classes.activeBreadCrumbText : classes.nonActiveBreadCrumbText}`}
            onClick={obj.isActive ? () => {}
              : () => history.push(obj.redirectionUrl, obj.state)}
          >
            {obj.label}
          </Typography>
        ))}
      </Breadcrumbs>
      {customJsx}
    </Box>
  );
};

BreadCrumbHOC.defaultProps = {
  separatorIcon: <NavigateNextIcon fontSize="small" style={{ color: LIGHT_MEDIUM_GREY }} />,
  customJsx: <> </>,
};
BreadCrumbHOC.propTypes = {
  list: PropTypes.array.isRequired,
  separatorIcon: PropTypes.element,
  customJsx: PropTypes.node,
};

export default BreadCrumbHOC;
