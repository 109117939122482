import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LoaderInner } from '../../components/Loader';
import FormattedTypography from '../../components/common/FormattedTypography';
import StatusModalWrapper from '../../components/ActivityLogs/StatusModalWrapper';
import { ordinalSuffix, zeroAppender } from '../../helpers/formattingHelpers';
import EnrollmentStuckQueue from '../../components/common/EnrollmentStuckQueue';
import EnrollmentPreflight from '../../components/UserEnrollment/OperationsPage/EnrollmentPreflight';
import EnrollmentProgress from '../../components/UserEnrollment/OperationsPage/EnrollmentProgress';
import userEnrollmentStyles from './styles';
import ReportOverviewModal from '../../components/ActivityLogs/ReportOverviewModal';
import ModalHeader from '../../components/ActivityLogs/ModalHeader';

const useStyles = makeStyles(() => ({ ...userEnrollmentStyles }));

const UserEnrollmentStatusModal = ({
  open, onClose, modalState, transactionId, getReport,
  progressData, progressErrorMsg, onDownloadLog, onCancelTransaction,
  cancellationRequested, setCancellationRequested, onExistingUsersUpdate,

}) => {
  const [useExcelDataForPassword, setUseExcelDataForPassword] = useState(false);
  const useExcelDataRef = useRef();
  useExcelDataRef.current = useExcelDataForPassword;
  const classes = useStyles();

  const onDownloadNewUserList = useCallback(async (transaction) => onDownloadLog(transaction, '?filter=new'), [onDownloadLog]);
  const onDownloadExistingUserList = useCallback(async (transaction) => onDownloadLog(transaction, '?filter=existing'), [onDownloadLog]);

  const applyReportModal = useCallback(async () => {
    const data = await getReport(transactionId);

    const existingUsers = data?.existing_users?.success;
    const newUsers = data?.new_users?.success;
    const failedUsers = data?.failed;
    const inactiveUsers = data?.existing_users?.inactive_users;

    return {
      component: ReportOverviewModal,
      wrapperProps: {
        logo: null,
        breakpoint: 'md',
        primaryBtnText: 'Download Log',
        heading: (
          <ModalHeader
            title="User Enrollment Overview"
            fileName={data?.filename}
            helpingText="Please download the log file for details"
          />
        ),
        primaryBtnProps: {
          onClick: () => onDownloadLog(data),
        },
      },
      props: {
        steps: [
          { type: 'success', primary: `New Users Created: ${zeroAppender(newUsers)}` },
          { type: 'alert', primary: `Existing Users Found: ${zeroAppender(existingUsers)}` },
          (failedUsers > 0 ? { type: 'error', primary: `Users could not be enrolled: ${zeroAppender(failedUsers)}` } : undefined),
          (inactiveUsers > 0 ? { type: 'error', primary: `Inactive users could not be enrolled: ${zeroAppender(inactiveUsers)}` } : undefined),
        ],
      },
    };
  }, [getReport, onDownloadLog, transactionId]);

  const applyActionModal = useCallback(async () => {
    const reportData = await getReport(transactionId);
    return {
      component: EnrollmentPreflight,
      wrapperProps: {
        logo: null,
        headingDivider: true,
        contentDivider: true,
        contentWrapper: classes.preflightDialogContainer,
        primaryBtnText: 'Complete Enrollment',
        heading: <FormattedTypography
          variant="subtitle1"
          containerClassName={classes.preflightHeading}
          suffixClassName={`${classes.preflightSuffix}`}
          prefix="User Enrollment Overview"
          prefixClassName={classes.preflightprefixHeading}
          body="Uploaded file "
          suffix={` '${reportData?.filename}'.`}
          subHeading="Please complete the pending actions to complete the enrollment."
          subHeadingSection={classes.subHeadingSection}
          className={classes.preflightbody}
        />,
        primaryBtnProps: {
          onClick: () => {
            onExistingUsersUpdate({
              transactionId: reportData.transaction_id,
              shouldAppendConfigIds: true,
              shouldUseExcelDataForPasswordChange: useExcelDataRef.current,
              updateName: false,
              updateLang: false,
              wave_id: reportData.wave_id,
            });
          },
        },
      },
      props: {
        transaction: reportData,
        onDownloadExistingUserList,
        onDownloadNewUserList,
        onChangeExcelDataPassword: (value) => { setUseExcelDataForPassword(value); },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps,max-len
  }, [getReport, onDownloadExistingUserList, onDownloadNewUserList,
    onExistingUsersUpdate, transactionId, useExcelDataForPassword]);

  const applyProgressModal = useCallback(() => {
    const { percentage, filename, count } = progressData;

    return {
      component: EnrollmentProgress,
      wrapperProps: {
        logo: (
          <div className={classes.progressRow}>
            <LoaderInner progress={percentage} scaleFactor={1.5} />
          </div>
        ),
        heading: (
          <FormattedTypography prefix={`Uploading file ${filename}`} />
        ),
        primaryBtnText: 'Cancel Operation',
        primaryBtnProps: {
          disabled: cancellationRequested,
          onClick: () => onCancelTransaction(),
        },
      },
      props: {
        totalUsers: count,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps,max-len
  }, [cancellationRequested, onCancelTransaction, progressData]);

  const applyQueuedModal = useCallback(() => ({
    component: EnrollmentStuckQueue,
    wrapperProps: {
      logo: <></>,
      breakpoint: 'md',
      heading: null,
    },
    props: {
      queue_position: ordinalSuffix(progressData?.queue_position),
      onCancelTransaction,
      cancellationRequested,
      setCancellationRequested,
    },
  }),
  // eslint-disable-next-line react-hooks/exhaustive-deps,max-len
  [cancellationRequested, onCancelTransaction, progressData?.queue_position, setCancellationRequested]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={applyProgressModal}
      reportModal={applyReportModal}
      applyQueuedModal={applyQueuedModal}
      applyActionModal={applyActionModal}
      feature="user enrollment"
    />
  );
};

UserEnrollmentStatusModal.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

UserEnrollmentStatusModal.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
  onCancelTransaction: PropTypes.func.isRequired,
  cancellationRequested: PropTypes.bool.isRequired,
  setCancellationRequested: PropTypes.func.isRequired,
  onExistingUsersUpdate: PropTypes.func.isRequired,
};

export default UserEnrollmentStatusModal;
