import config from '../../config';
import request from '../../helpers/apiHelper';

export const createTransaction = (data) => {
  const url = `${config.ROUTES.EDIT_PROGRAM_TRANSACTION}`;
  const options = { url, method: 'POST', data };
  return request(options);
};

export const proceedWithRename = (data) => {
  const url = `${config.ROUTES.EDIT_PROGRAM_ID}`;
  // Use POST to tell cloudflare not to cache the response into CDN
  const options = { url, method: 'POST', data };
  return request(options);
};

export const getProgress = (transactionId, cancelToken) => {
  const url = `${config.ROUTES.GET_PROGRESS}/${transactionId}`;
  const options = { url, method: 'GET', cancelToken };
  return request(options);
};

export const getReportData = (transactionId) => {
  const url = `${config.ROUTES.GET_REPORT}/${transactionId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const checkNewConfigIdExists = (configId, sourceConfigId, cancelToken) => {
  const url = `${config.ROUTES.EDIT_PROGRAM_CID_CHECK}/${configId}/${sourceConfigId}`;
  const options = { url, method: 'GET', cancelToken };
  return request(options);
};

export const editProgramTimeline = (programId) => {
  const url = `${config.ROUTES.EDIT_PROGRAM_TIMELINE}/${programId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const markComplete = (transactionId) => {
  const url = `${config.ROUTES.MARK_FOR_COMPLETION}/${transactionId}`;
  const options = { url, method: 'POST' };
  return request(options);
};

export const deleteTransaction = (transactionId) => {
  const url = `${config.ROUTES.EDIT_PROGRAM_DELETE_TRANSACTION}/${transactionId}`;
  const options = { url, method: 'DELETE' };
  return request(options);
};
