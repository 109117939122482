import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
// import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import {
  BLACK, MEDIUM_GREY,
} from '../../stylesheets/colors';
import DeleteConfirmation from '../../assets/icons/DeleteConfirmation.svg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:nth-child(4)': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
  modalHeight: {
    minHeight: '35rem',
    height: 'auto',
  },
  topPadding: {
    paddingTop: '0.5rem',
  },
});

const DeleteConfirmationSocrm = ({
  open, onClose, onDelete, headingFirst, headingSecond,
}) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyle}
      minHeightClass={classes.modalHeight}
      content={(
        <Container className={classes.wrapper}>
          <img src={DeleteConfirmation} alt="delete-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>Delete row?</Typography>
          <Typography variant="h1" component="p" className={`${classes.infoContentText}`}>
            {headingFirst}
          </Typography>
          <Typography variant="h1" component="p" className={`${classes.infoContentText} ${classes.topPadding}`}>
            {headingSecond}
          </Typography>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => onDelete()}>
            Delete Row
          </Button>
        </>
      )}
    />
  );
};

export default DeleteConfirmationSocrm;

DeleteConfirmationSocrm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  data: PropTypes.shape({
    lp_config_id: PropTypes.string.isRequired,
  }).isRequired,
  headingFirst: PropTypes.string.isRequired,
  headingSecond: PropTypes.string.isRequired,
};
