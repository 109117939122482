import { useState, useEffect, useCallback } from 'react';
import {
  QUEUED, IN_PROGRESS, FOR_REPORT, PROGRESS, REPORT, ERROR, CANCELLING,
} from '../constants';

import useProgressResult from './useProgressResult';

const useCheckActivityStatus = (
  status, open, onClose, pollProgressValue, transactionId, updatePollProgress,
) => {
  const {
    progressResult, progressError, clearProgressResult, clearProgressError,
  } = useProgressResult(
    pollProgressValue, transactionId, updatePollProgress,
  );

  const [modalState, setModalState] = useState('');

  const applyModalState = useCallback((state) => {
    setModalState(state);
  }, []);

  const preClose = useCallback(() => {
    applyModalState(null);
    clearProgressResult(null);
    clearProgressError(null);
    onClose();
  }, [clearProgressResult, clearProgressError, onClose, applyModalState]);

  const checkProgress = useCallback(() => {
    if (progressError !== null) {
      applyModalState(ERROR);
    } else if (progressResult?.data?.done) {
      updatePollProgress(false);
      applyModalState(REPORT);
    } else if (progressResult?.data?.transaction_status === QUEUED
        || progressResult?.data?.status === QUEUED) {
      applyModalState(QUEUED);
    } else if (progressResult?.data?.transaction_status === CANCELLING
        || progressResult?.data?.status === CANCELLING) {
      applyModalState(CANCELLING);
    } else {
      applyModalState(PROGRESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressError, progressResult]);

  useEffect(() => {
    if (status?.length > 0 && open) {
      if (IN_PROGRESS.includes(status)) {
        checkProgress();
      } else if (FOR_REPORT.includes(status)) {
        applyModalState(REPORT);
      } else {
        applyModalState(ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, status, checkProgress]);

  return {
    modalState,
    preClose,
    progressData: progressResult?.data,
    progressErrorMsg: progressError?.message,
  };
};

export default useCheckActivityStatus;
