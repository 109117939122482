import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { KEY_BLACK, LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  searchRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: '1.25rem',
  },
  searchBox: {
    width: '22%',
    '& > div': {
      height: '24px',
      marginTop: 0,
      marginBottom: 0,
      '& > input': {
        padding: '5px 10px',
      },
      '& > button': {
        padding: '0 5px',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  linkAndButton: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  link: {
    fontSize: '1.5rem',
    paddingRight: '5rem',
    display: 'inline-flex',
    alignItems: 'flex-start',
  },
  syncButtonCls: {
    '&:disabled': {
      background: LIGHT_MEDIUM_GREY,
      color: KEY_BLACK,
    },
  },
}));

const WorkshopManagementSearch = ({
  isFinalDataEmpty,
  setOpenConfirmationModal,
  syncButtonDisabled,
  enableSyncAgain,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.searchRow}>
        {isFinalDataEmpty && (
          <div className={classes.linkAndButton}>
            <Button
              variant="contained"
              className={classes.syncButtonCls}
              color="primary"
              disabled={syncButtonDisabled}
              onClick={() => setOpenConfirmationModal(true)}
            >
              {enableSyncAgain ? 'Sync Again' : 'Session Enrollment Sync'}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

WorkshopManagementSearch.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  isFinalDataEmpty: PropTypes.bool.isRequired,
  setOpenConfirmationModal: PropTypes.func.isRequired,
  syncButtonDisabled: PropTypes.bool.isRequired,
  enableSyncAgain: PropTypes.bool.isRequired,
};

export default memo(
  WorkshopManagementSearch,
  (prevProps, nextProps) => prevProps.isFinalDataEmpty === nextProps.isFinalDataEmpty
    && prevProps.syncButtonDisabled === nextProps.syncButtonDisabled
    && prevProps.searchText === nextProps.searchText,
);
