import React, {
  useState, useRef, memo, forwardRef, useImperativeHandle, useEffect,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import _range from 'lodash/range';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import timezones from 'compact-timezone-list';
import {
  format, intervalToDuration, isBefore, roundToNearestMinutes, isEqual,
} from 'date-fns';

import { DatePicker, TimePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {
  formatHoursToMinute,
  convertInDigits,
  convertMinutesToHourMinutes,
  getUTCDate,
} from '../../helpers/formattingHelpers';
import LabledTextField from '../common/LabledTextField';
import CustomSelect from '../common/CustomSelect';
import { ERROR_RED, MODERATE_LIGHT_GREY } from '../../stylesheets/colors';
import { URLValidator } from '../../helpers/utils';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '0 48px 20px',
    width: '87%',
    margin: 'auto',
  },
  width100: {
    width: '100%',
  },
  datePickerInput: {
    borderRadius: 'unset',
    height: '35.1px',
    margin: '8px 0',
  },
  rootLabelText: {
    '& > div': {
      borderRadius: 'unset',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  highliteErrorInput: {
    '& > div': {
      border: `1px solid ${ERROR_RED}`,
    },
  },
  red: {
    color: ERROR_RED,
  },
  errorBlock: {
    display: 'flex',
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  divider: {
    width: '100%',
    margin: '2rem 0',
  },
  input: {
    width: '100%',
    margin: '8px 0',
    '& > div': {
      borderRadius: 'unset',
    },
    '& input': {
      paddingRight: '4.5rem',
      '&:disabled': {
        backgroundColor: MODERATE_LIGHT_GREY,
        opacity: 0.5,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
}));

const tzOptions = timezones.map((v) => ({
  label: `(GMT${v.offset}) ${v.tzCode}`,
  value: v.tzCode,
}));
const dateFormat = 'dd MMM yyyy';
const timeFormat = 'HH:mm';
const minStep = 5;
const minutesOptions = _range(15, 61, 15).map((v) => ({
  value: v,
  label: `${v} mins`,
}));
const earlyAccessOptions = [
  {
    label: 'Before start time of event',
    value: true,
  },
  {
    label: 'Start time of event',
    value: false,
  },
];

const getTimezone = (timezone) => {
  let tzIdx = timezones.findIndex((v) => v.tzCode === timezone);

  if (tzIdx === -1) {
    tzIdx = timezones.findIndex((v) => v.offset === format(new Date(), 'xxx'));
  }
  return tzOptions[tzIdx];
};

const validate = (values) => {
  const errors = {};
  const eventDate = format(values.event_start_date, 'yyyy-MM-dd');
  const startTime = new Date(`${eventDate} ${format(values.event_start_time, timeFormat)}:00`);
  const endTime = new Date(`${eventDate} ${format(values.event_end_time, timeFormat)}:00`);

  let err_participants_join_time = false;
  if (values.participant_access.value && values.instructor_access.value === false) {
    err_participants_join_time = true;
  } else if (values.participant_access.value
    && values.instructor_access.value
    && values.participants_advance_join_time.value === values.instructors_advance_join_time.value) {
    err_participants_join_time = true;
  } else if (values.participant_access.value
    && values.instructor_access.value
    && values.participants_advance_join_time.value > values.instructors_advance_join_time.value) {
    err_participants_join_time = true;
  }

  if (!values.event_name) {
    errors.event_name = 'This field is required';
  }

  if (isBefore(endTime, startTime) || isEqual(endTime, startTime)) {
    errors.event_end_time = 'The end time must be later than the start time';
    errors.event_start_time = 'The start time must be earlier than the end time';
  }

  if (err_participants_join_time) {
    errors.instructors_advance_join_time = 'The instructors access to link must be earlier than the participants';
    errors.participants_advance_join_time = 'The participants access to link must be earlier than the instructors';
  }

  if (values.video_conference && !values.custom_url) {
    errors.custom_url = 'This field is required when enabled';
  }

  if (values.video_conference && values.custom_url && !URLValidator(values.custom_url)) {
    errors.custom_url = 'Please enter a valid URL';
  }

  if (!values?.video_conference && !values?.venue) {
    errors.custom_url = 'The event must have a venue or a video conference url';
    errors.venue = 'The event must have a venue or a video conference url';
  }

  return errors;
};

const getFormData = (data = {}) => {
  const closestMin = roundToNearestMinutes(new Date(), { nearestTo: minStep });
  let instructorAdvTime = 0;
  let participantAdvTime = 0;
  if (!_isEmpty(data)) {
    instructorAdvTime = data?.join_in_advance_time_teacher
      ? formatHoursToMinute(data?.join_in_advance_time_teacher)
      : 0;
    participantAdvTime = data?.join_in_advance_time_user
      ? formatHoursToMinute(data?.join_in_advance_time_user)
      : 0;
  }

  return {
    event_name: data.name || '',
    event_start_date: data.day ? getUTCDate(data.day) : new Date(),
    event_start_time: data.day ? new Date(`${data.day} ${data.time_begin}`) : closestMin,
    event_end_time: data.day ? new Date(`${data.day} ${data.time_end}`) : closestMin,
    duration: data.day
      ? intervalToDuration({
        start: new Date(`${data.day} ${data.time_begin}`),
        end: new Date(`${data.day} ${data.time_end}`),
      })
      : {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    timezone: getTimezone((data || Intl.DateTimeFormat().resolvedOptions()).timezone),
    video_conference: !!data.custom_url,
    custom_url: data.custom_url,
    venue: !!data.id_location,
    location: {},
    instructor_access: _find(earlyAccessOptions, { value: !!instructorAdvTime }),
    instructors_advance_join_time: instructorAdvTime
      ? _find(minutesOptions, { value: instructorAdvTime })
      : minutesOptions[0],
    participant_access: _find(earlyAccessOptions, { value: !!participantAdvTime }),
    participants_advance_join_time: participantAdvTime
      ? _find(minutesOptions, { value: participantAdvTime })
      : minutesOptions[0],
    allow_join_completion: data.allow_join_completion || false,
    allow_recording_completion: data.allow_recording_completion || false,
  };
};

const Event = forwardRef(({
  sessionId, onSubmit, listVenues, getEvent, eventId, onDone, setAlertMessage, setInfoMessage,
  saveAndAddButtonClicked, onDoneAndAdd,
}, ref) => {
  const classes = useStyles();
  const locationRef = useRef();
  const [venues, setVenues] = useState([]);
  const timezoneRef = useRef(null);
  const {
    values, errors, touched, handleChange, setFieldValue, handleSubmit, setValues,
  } = useFormik({
    initialValues: getFormData(),
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (formValues) => {
      const finalValues = {
        event_name: formValues.event_name,
        event_start_date: format(formValues.event_start_date, 'yyyy-MM-dd'),
        event_start_time: `${format(formValues.event_start_time, timeFormat)}:00`,
        event_end_time: `${format(formValues.event_end_time, timeFormat)}:00`,
        timezone: formValues.timezone.value,
        video_conference: formValues.video_conference,
      };

      if (formValues.video_conference) {
        finalValues.custom_url = formValues.custom_url;
        finalValues.allow_join_completion = formValues.allow_join_completion;
        finalValues.allow_recording_completion = formValues.allow_recording_completion;
        if (formValues.participant_access.value) {
          finalValues.participants_advance_join_time = convertMinutesToHourMinutes(
            formValues.participants_advance_join_time.value,
          );
        } else {
          finalValues.participants_advance_join_time = formValues.instructor_access.value
            ? '00:00'
            : null;
        }
        finalValues.instructors_advance_join_time = formValues.instructor_access.value
          ? convertMinutesToHourMinutes(formValues.instructors_advance_join_time.value)
          : null;
      }

      if (formValues.venue) {
        finalValues.location_id = Number(formValues.location.value);
      }

      try {
        if (!eventId) {
          const response = await onSubmit(sessionId, finalValues);
          if (response && response.data && response.data.success) {
            setInfoMessage({ type: 'success', message: `Event '${finalValues.event_name}' created successfully.` });
          }
        } else {
          const updateResponse = await onSubmit(eventId, finalValues);
          if (updateResponse && updateResponse.data && updateResponse.data.success) {
            setInfoMessage({ type: 'success', message: `Event '${finalValues.event_name}' updated successfully.` });
          }
        }
        onDone();
        if (saveAndAddButtonClicked) onDoneAndAdd(sessionId);
      } catch (e) {
        setAlertMessage({ type: 'error', message: e?.response?.data?.message || 'Event creation failed' });
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const promises = [listVenues()];
        if (eventId) {
          promises.push(getEvent(eventId));
        }

        const [resp, resp2] = await Promise.all(promises);
        const setLocation = locationRef.current?.select.setValue;
        const list = (resp.data?.users || []).map((v) => ({
          label: v.name,
          value: `${v.id}`,
        }));

        let curVal = list[0];
        batchUpdates(() => {
          setVenues(list);

          if (resp2.data?.event) {
            const formVals = getFormData(resp2.data.event);
            setValues(formVals);
            curVal = _find(list, { value: `${resp2.data.event.id_location}` });
          }
        });

        setFieldValue('location', curVal);
        if (setLocation) {
          setLocation(curVal);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();
  }, [listVenues, setFieldValue, eventId, getEvent, setValues]);

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  const calculateTimeDiff = (key, time) => {
    const startTime = key === 'event_start_time' ? time : values.event_start_time;
    const endTime = key === 'event_end_time' ? time : values.event_end_time;
    const diff = intervalToDuration({ start: startTime.getTime(), end: endTime.getTime() });
    setFieldValue('duration', diff);
    setFieldValue(key, time);
  };

  useEffect(() => {
    if (values.timezone) {
      timezoneRef.current.select.setValue(values.timezone);
    }
  }, [values]);

  return (
    <Grid container spacing={2} justifyContent="space-between" className={classes.modalWrapper}>
      <Grid item xs={12}>
        <LabledTextField
          label={(
            <div className={classes.label}>
              <Typography>Event Name</Typography>
              <span className={classes.red}>*</span>
            </div>
          )}
          className={classes.fieldPadding}
          name="event_name"
          inputProps={{
            classes: {
              root: `${classes.rootLabelText}${errors.event_name && touched.event_name ? ` ${classes.highliteErrorInput}` : ''}`,
            },
          }}
          value={values.event_name}
          onChange={(e) => {
            if (e.target.value.length <= 255) {
              handleChange(e);
            }
          }}
        />
        <div className={classes.errorBlock}>
          {errors.event_name && touched.event_name && (
            <Typography className={classes.red}>{errors.event_name}</Typography>
          )}
          <Typography className={classes.rightText}>
            {(values.event_name || '').length}
            /255
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.label}>
          <Typography>Start Date</Typography>
          <span className={classes.red}>*</span>
        </div>
        <DatePicker
          className={classes.width100}
          value={values.event_start_date}
          format={dateFormat}
          inputVariant="outlined"
          autoOk
          disablePast
          variant="inline"
          onChange={(date) => setFieldValue('event_start_date', date)}
          InputProps={{
            className: classes.datePickerInput,
            endAdornment: <CalendarTodayIcon size="2rem" />,
          }}
        />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <div className={classes.label}>
            <Typography>Start Time</Typography>
            <span className={classes.red}>*</span>
          </div>
          <TimePicker
            className={classes.width100}
            value={values.event_start_time}
            inputFormat="mm:ss"
            inputVariant="outlined"
            ampm={false}
            minutesStep={5}
            autoOk
            variant="inline"
            onChange={(time) => calculateTimeDiff('event_start_time', time)}
            InputProps={{
              className: classes.datePickerInput,
              endAdornment: <AccessTimeIcon size="2rem" />,
            }}
          />
          <div className={classes.errorBlock}>
            {errors.event_start_time && (
              <Typography className={classes.red}>{errors.event_start_time}</Typography>
            )}
          </div>
          <Typography>
            Duration:
            {' '}
            {convertInDigits(values.duration.hours)}
            :
            {convertInDigits(values.duration.minutes)}
            {' '}
            h
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.label}>
            <Typography>End Time</Typography>
            <span className={classes.red}>*</span>
          </div>
          <TimePicker
            className={classes.width100}
            value={values.event_end_time}
            inputFormat="mm:ss"
            inputVariant="outlined"
            ampm={false}
            minutesStep={5}
            autoOk
            variant="inline"
            onChange={(time) => calculateTimeDiff('event_end_time', time)}
            InputProps={{
              className: classes.datePickerInput,
              endAdornment: <AccessTimeIcon size="2rem" />,
            }}
          />
          <div className={classes.errorBlock}>
            {errors.event_end_time && (
              <Typography className={classes.red}>{errors.event_end_time}</Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomSelect
          selectRef={timezoneRef}
          name="timezone"
          options={tzOptions}
          className={classes.fieldPadding}
          defaultValue={values.timezone}
          placeholder="Select an option"
          type="select"
          label={(
            <div className={classes.label}>
              <Typography>Timezone</Typography>
              <span className={classes.red}>*</span>
            </div>
          )}
          onChange={(e) => setFieldValue('timezone', e)}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.label}>
          <Typography>Location</Typography>
          <span className={classes.red}>*</span>
        </div>
        <LabledTextField
          name="custom_url"
          label={(
            <FormControlLabel
              control={(
                <Checkbox
                  checked={values.video_conference}
                  onChange={(e) => setFieldValue('video_conference', e.target.checked)}
                />
              )}
              label="Video Conference"
            />
          )}
          inputProps={{
            classes: { root: classes.rootLabelText },
            disabled: !values.video_conference,
          }}
          className={classes.fieldPadding}
          value={values.custom_url || ''}
          onChange={handleChange}
        />
        <div className={classes.errorBlock}>
          {errors.custom_url && touched.custom_url && (
            <Typography className={classes.red}>{errors.custom_url}</Typography>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <CustomSelect
          name="location"
          selectRef={locationRef}
          label={(
            <FormControlLabel
              control={(
                <Checkbox
                  checked={values.venue}
                  onChange={(e) => setFieldValue('venue', e.target.checked)}
                />
              )}
              label="Venue"
            />
          )}
          options={venues}
          disabled={!values.venue}
          className={classes.fieldPadding}
          defaultValue={values.location}
          placeholder="Select an option"
          type="select"
          onChange={(e) => setFieldValue('location', e)}
        />
        <div className={classes.errorBlock}>
          {errors.venue && (
            <Typography className={classes.red}>{errors.venue}</Typography>
          )}
        </div>
      </Grid>
      {values.video_conference ? (
        <>
          <Grid item xs={12}>
            <CustomSelect
              name="instructor_access"
              options={earlyAccessOptions}
              className={classes.fieldPadding}
              defaultValue={values.instructor_access}
              placeholder="Select an option"
              type="select"
              label="Instructor access to link"
              onChange={(e) => setFieldValue('instructor_access', e)}
            />
            {values.instructor_access.value ? (
              <CustomSelect
                name="instructors_advance_join_time"
                options={minutesOptions}
                className={classes.fieldPadding}
                defaultValue={values.instructors_advance_join_time}
                placeholder="Select an option"
                type="select"
                onChange={(e) => setFieldValue('instructors_advance_join_time', e)}
              />
            ) : null}
            <div className={classes.errorBlock}>
              {errors.instructors_advance_join_time && touched.instructors_advance_join_time && (
                <Typography className={classes.red}>
                  {errors.instructors_advance_join_time}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              name="participant_access"
              options={earlyAccessOptions}
              className={classes.fieldPadding}
              defaultValue={values.participant_access}
              placeholder="Select an option"
              type="select"
              label="Participant access to link"
              onChange={(e) => setFieldValue('participant_access', e)}
            />
            {values.participant_access.value ? (
              <CustomSelect
                name="participants_advance_join_time"
                options={minutesOptions}
                className={classes.fieldPadding}
                defaultValue={values.participants_advance_join_time}
                placeholder="Select an option"
                type="select"
                onChange={(e) => setFieldValue('participants_advance_join_time', e)}
              />
            ) : null}
            <div className={classes.errorBlock}>
              {errors.participants_advance_join_time && (
                <Typography className={classes.red}>
                  {errors.participants_advance_join_time}
                </Typography>
              )}
            </div>
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Grid item xs={12}>
            <div className={classes.label}>
              <Typography>Attendance options</Typography>
            </div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={values.allow_join_completion}
                  onChange={(e) => setFieldValue('allow_join_completion', e.target.checked)}
                />
              )}
              label="Mark the event as attended when the user joins the webinar"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={values.allow_recording_completion}
                  onChange={(e) => setFieldValue('allow_recording_completion', e.target.checked)}
                />
              )}
              label="Mark the event as attended if the user access the recordings"
            />
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} light />
        </>
      ) : null}
    </Grid>
  );
});

Event.displayName = 'Event';

Event.defaultProps = {
  eventId: null,
  getEvent: () => { },
  saveAndAddButtonClicked: false,
  onDoneAndAdd: () => { },
};

Event.propTypes = {
  sessionId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  listVenues: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  getEvent: PropTypes.func,
  eventId: PropTypes.string,
  setAlertMessage: PropTypes.func.isRequired,
  setInfoMessage: PropTypes.func.isRequired,
  saveAndAddButtonClicked: PropTypes.bool,
  onDoneAndAdd: PropTypes.func,
};

const EventForm = memo(
  Event,
  (prevProps, nextProps) => prevProps.sessionId === nextProps.sessionId
    && prevProps.eventId === nextProps.eventId
    && prevProps.saveAndAddButtonClicked === nextProps.saveAndAddButtonClicked,
);

export default EventForm;
