import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import Tooltip from '@material-ui/core/Tooltip';

import {
  LIGHT_MEDIUM_GREY, MCKINSEY_BLUE, VERY_LIGHT_GREY, DARK_MEDIUM_GREY,
} from '../../../stylesheets/colors';

import { DOCEBO, ELUCIDAT } from '../../../constants';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'McKinseySans',
    border: `dashed 2px ${LIGHT_MEDIUM_GREY}`,
    padding: '15px',
    backgroundColor: VERY_LIGHT_GREY,
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '9px',
  },
  logo: {
    color: MCKINSEY_BLUE,
  },
  textWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  release: {
    color: DARK_MEDIUM_GREY,
  },
}));

const AssignedContentListRow = ({
  title, release, version, source,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.rowWrapper}>
      <div className={classes.logoWrapper}>
        <Tooltip title="Assigned Content" arrow placement="top">
          <InsertCommentIcon className={classes.logo} />
        </Tooltip>
      </div>
      <div className={classes.textWrapper}>
        <div className={classes.title}>{title}</div>
        {source === ELUCIDAT && <div className={classes.release}>{release}</div>}
        {source === DOCEBO && <div className={classes.release}>{version}</div>}
      </div>
    </div>
  );
};

AssignedContentListRow.propTypes = {
  title: PropTypes.string.isRequired,
  release: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

const AssignedContentListBox = ({ contentList }) => {
  const classes = useStyles();

  if (contentList.length === 0) return <div />;

  const rows = contentList.map(({
    title, release, version, source,
  }) => (
    <AssignedContentListRow
      key={`${title}-${release}`}
      title={title}
      release={release}
      version={version}
      source={source}
    />
  ));

  return (
    <div className={classes.wrapper}>
      {rows}
    </div>
  );
};

AssignedContentListBox.propTypes = {
  contentList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    release: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  })).isRequired,
};

export default AssignedContentListBox;
