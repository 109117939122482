import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SyncStatusModal from '../../WorkshopManagement/SessionEnrollmentSyncModal/SyncStatusModal';
import { internalServerErrorModalLogic } from '../../../containers/common/utils';
import { getProgress } from '../../../containers/common/apis';

const WorkshopManagement = ({
  transactionId, onClose, programId,
}) => {
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [openStatusModal, setOpenStatusModal] = useState(true);
  const [pollProgress, setPollProgress] = useState(true);
  const history = useHistory();

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!pollProgress) return;
      try {
        if (!transactionId) return;
        const res = await getProgress(transactionId);
        if (res.data.done) {
          setProgress(res.data);
          setPollProgress(false);
        } else {
          setProgress(res.data);
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        timer = internalServerErrorModalLogic(history, err, false, pollProgressApi);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, history]);

  const preClose = () => {
    setOpenStatusModal(false);
    onClose();
  };
  return (
    <>
      {openStatusModal && progress?.done !== null ? (
        <SyncStatusModal
          open={openStatusModal}
          onClose={preClose}
          progress={progress}
          onSyncAgainClick={null}
          programId={programId}
          hideSyncAgain
        />
      ) : null}
    </>
  );
};

WorkshopManagement.propTypes = {
  transactionId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
};

export default WorkshopManagement;
