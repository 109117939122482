import MCKINSEY_SANS from './fonts';
import { MODERATE_DARK_GREY, MODERATE_LIGHT_GREY } from './colors';

const tableStyles = {
  rowStyle: {
    fontFamily: MCKINSEY_SANS,
    fontSize: '1rem',
    color: MODERATE_DARK_GREY,
    lineHeight: 1.43,
    overflowWrap: 'break-word',
  },
  headerStyle: {
    backgroundColor: MODERATE_LIGHT_GREY,
    fontSize: '1.125rem',
    fontWeight: 600,
    fontFamily: MCKINSEY_SANS,
  },
};

export default tableStyles;
