import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@mui/material/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormattedTypography from '../../common/FormattedTypography';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import BlueAlertIcon from '../../../assets/icons/icon-blue-alert.svg';
import CustomUploadModal from '../../common/CustomUploadModal';
import {
  ACCORDION_GREY, BLACK, MEDIUM_GREY,
} from '../../../stylesheets/colors';
import {
  COMPLETED, COMPLETED_WITH_ERRORS, FAILED, GROUP_ENROLLMENT,
} from '../../../constants';
import { downloadLogForGW } from '../../../containers/GroupWorkPage/apis';
import { downloadFile } from '../../../containers/common/apis';

const useStyles = makeStyles(() => ({
  circularProgressPadding: {
    padding: '1rem',
  },
  logoIcon: {
    '& > :nth-child(1)': {
      margin: '1rem 0 0 0',
    },
  },
  headingWrapper: {
    paddingTop: '0',
  },
  contentListWrapper: {
    width: '100%',
    padding: '0.5rem 0',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
    padding: '0.25rem 0',
  },
  infoContentText: {
    color: BLACK,
  },
  headingBoldContent: {
    color: MEDIUM_GREY,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  subHeading: {
    fontWeight: 800,
    paddingBottom: '0.5rem',
  },
  contentBox: {
    marginBottom: '0',
  },
  loCountWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 0',
  },
  errorBox: {
    marginBottom: '4rem',
  },
}));

const GroupWorkReportModal = ({
  open, onClose, progress, fileName, programSubType, configId, label,
}) => {
  const classes = useStyles();
  const {
    total_rows, successful_rows, failed_rows, status, transaction_id, created_at = '',
  } = progress;

  const getStatusLogo = () => {
    if (status === COMPLETED) {
      return CheckRoundIcon;
    } if (status === FAILED) {
      return ErrorRoundIcon;
    }
    return BlueAlertIcon;
  };

  const onDownloadLog = async () => {
    try {
      if (programSubType === GROUP_ENROLLMENT) {
        await downloadFile(transaction_id, configId, programSubType, created_at);
      } else {
        const createdAt = new Date();
        await downloadLogForGW({
          transactionId: transaction_id, configId, programSubType, createdAt,
        });
      }
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const body = (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
        <Box className={classes.contentListWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.subHeading}
          >
            {label}
          </Typography>
          <Container>
            <Box className={classes.loCountWrapper}>
              <img
                src={CheckRoundIcon}
                alt="check-round-green"
              />
              <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                {`${successful_rows}/${total_rows} groups successfully updated`}
              </Typography>
            </Box>
            {failed_rows > 0 && (
              <>
                <Divider component="li" />
                <Box className={classes.loCountWrapper}>
                  <img
                    src={ErrorRoundIcon}
                    alt="Error-round-red"
                  />
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                    {`${failed_rows}/${total_rows} groups not updated successfully`}
                  </Typography>
                </Box>
              </>
            )}
          </Container>
        </Box>
      </ListItem>
    </List>
  );

  return (
    <>
      {status === FAILED
        ? (
          <CustomUploadModal
            open={open}
            onClose={onClose}
            breakpoint="sm"
            logoWrapper={classes.logoIcon}
            contentWrapper={classes.contentBox}
            logo={(
              <img
                src={getStatusLogo()}
                alt="status-icon"
                width={80}
                height={80}
                className={classes.circularProgressPadding}
              />
            )}
            heading={(
              <DialogTitle className={classes.headingWrapper}>
                <FormattedTypography
                  variant="subtitle1"
                  body={`${label} file upload failed!`}
                />
              </DialogTitle>
            )}
            contentDivider
            disableGutters
            primaryBtnText="Download Log"
            primaryBtnProps={{
              onClick: () => onDownloadLog(),
            }}
          >
            <Box className={classes.errorBox}>
              <Typography variant="subtitle2" className={classes.infoHelperText} align="center">
                {`${label} file '${fileName}' could not be uploaded.`}
              </Typography>
              <Typography variant="subtitle2" className={classes.infoHelperText} align="center">
                Please try again later.
              </Typography>
            </Box>
          </CustomUploadModal>
        ) : (
          <CustomUploadModal
            open={open}
            onClose={onClose}
            breakpoint="sm"
            logoWrapper={classes.logoIcon}
            contentWrapper={classes.contentBox}
            logo={(
              <img
                src={getStatusLogo()}
                alt="status-logo"
                width={80}
                height={80}
                className={classes.circularProgressPadding}
              />
            )}
            heading={(
              <DialogTitle className={classes.headingWrapper}>
                <FormattedTypography
                  body={`${label} file upload was completed ${status === COMPLETED ? 'successfully' : 'with errors'}`}
                  subHeading={(
                    <Typography variant="subtitle1" component="span" className={`${classes.headingBoldContent} ${classes.nestedText}`}>
                      {fileName}
                      {' '}
                      was uploaded
                      {' '}
                      {status === COMPLETED ? 'successfully' : 'with errors'}
                    </Typography>
                  )}
                />
              </DialogTitle>
            )}
            headingDivider
            contentDivider
            disableGutters
            primaryBtnText={status === COMPLETED_WITH_ERRORS ? 'Download Log' : ''}
            primaryBtnProps={{
              onClick: () => onDownloadLog(),
            }}
          >
            {body}
          </CustomUploadModal>
        )}
    </>
  );
};

GroupWorkReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  progress: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
  programSubType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default GroupWorkReportModal;
