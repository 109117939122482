import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChevronUp from '../../assets/icons/chevron-up-black.svg';
import ChevronDown from '../../assets/icons/chevron-down-black.svg';
import theme from '../../stylesheets/theme';
import { DARK_MEDIUM_GREY, LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';
import MCKINSEY_SANS from '../../stylesheets/fonts';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  labelWrapper: {
    display: 'flex',
    flex: 0.25,
    fontWeight: 'bold',
    alignItems: 'flex-end',
  },
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    maxHeight: '2.5rem',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  chevronBg: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  chevronIcon: {
    height: '15px',
    width: '15px',
  },
  groupHeading: {
    paddingRight: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: '32px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: 'unset',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: DARK_MEDIUM_GREY,
    cursor: 'pointer',
    fontSize: 'inherit',
    textTransform: 'unset',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: MCKINSEY_SANS,
    width: '22rem',
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (provided) => ({
    ...provided,
    padding: '1rem 2rem',
  }),
};

const GroupHeading = (props) => {
  const { id } = props;
  const classes = useStyles();
  const [imgSrc, setImgSrc] = useState(ChevronDown);

  useEffect(() => {
    const node = document.querySelector(`#${id}`).parentElement.nextElementSibling;
    node.style.display = 'none';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHeaderClick = (unique_id) => {
    const node = document.querySelector(`#${unique_id}`).parentElement.nextElementSibling;
    if (node.style.display !== 'none') {
      node.style.display = 'none';
      setImgSrc(ChevronDown);
    } else {
      node.style.display = 'block';
      setImgSrc(ChevronUp);
    }
  };

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={() => {}}
      onClick={() => handleHeaderClick(id)}
      className={classes.groupHeading}
    >
      <components.GroupHeading {...props} />
      <div className={classes.chevronBg}>
        <img src={imgSrc} alt="icon" className={classes.chevronIcon} />
      </div>
    </div>
  );
};

const CustomSelect = ({
  label,
  placeholder,
  options,
  onChange,
  defaultValue,
  maxMenuHeight,
  menuPlacement,
  name,
  className,
  selectRef,
  disabled,
}) => {
  const classes = useStyles();

  const isLoading = !options.length;

  return (
    <div className={`${classes.wrapper} ${className}`}>
      {typeof label === 'string' || typeof label === 'number' ? (
        <Typography variant="body1" className={classes.labelWrapper}>
          {label}
        </Typography>
      ) : (
        label
      )}
      <Select
        ref={selectRef}
        styles={selectStyles}
        placeholder={placeholder}
        isSearchable
        isLoading={isLoading}
        name={name.length > 0 ? name : label}
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        components={{ GroupHeading }}
        isOptionDisabled={(option) => option.isdisabled}
      />
    </div>
  );
};

GroupHeading.propTypes = {
  id: PropTypes.number.isRequired,
};

CustomSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selectRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

CustomSelect.defaultProps = {
  label: null,
  defaultValue: undefined,
  maxMenuHeight: 300,
  menuPlacement: 'bottom',
  name: '',
  className: '',
  disabled: false,
  selectRef: null,
};

export default CustomSelect;
