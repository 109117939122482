import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@mui/material/Divider';
import Container from '@material-ui/core/Container';
import {
  BLACK,
} from '../../stylesheets/colors';
import { COMPLETED_WITH_ERRORS } from '../../constants';

import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';

const useStyles = makeStyles(() => ({
  contentListWrapper: {
    width: '100%',
    padding: '0.5rem 0',
  },
  contentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoContentText: {
    color: BLACK,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  subHeading: {
    fontWeight: 800,
    paddingBottom: '0.5rem',
  },
  countWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 0',
  },
}));

const GenericReportOverview = ({
  status, total, failed, succeed,
}) => {
  const classes = useStyles();

  return (
    <>
      {status === COMPLETED_WITH_ERRORS && (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
            <Box className={classes.contentListWrapper}>
              <Typography
                variant="subtitle2"
                className={classes.subHeading}
              >
                Components
              </Typography>
              <Container>
                <Box className={classes.countWrapper}>
                  <img
                    src={CheckRoundIcon}
                    alt="check-round-green"
                  />
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={`${classes.infoContentText} ${classes.nestedText}`}
                  >
                    {`${succeed}/${total} components successfully updated`}
                  </Typography>
                </Box>
                <Divider component="li" />
                <Box className={classes.countWrapper}>
                  <img
                    src={ErrorRoundIcon}
                    alt="Error-round-red"
                  />
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={`${classes.infoContentText} ${classes.nestedText}`}
                  >
                    {`${failed}/${total} components not updated successfully`}
                  </Typography>
                </Box>
              </Container>
            </Box>
          </ListItem>
        </List>
      )}
    </>
  );
};

GenericReportOverview.propTypes = {
  status: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  failed: PropTypes.string.isRequired,
  succeed: PropTypes.string.isRequired,
};

export default GenericReportOverview;
