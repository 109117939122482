import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  MODERATE_LIGHT_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  listWrapper: {
    background: MODERATE_LIGHT_GREY,
  },
  bold: {
    fontWeight: 900,
  },
  bodyText: {
    textAlign: 'left',
    fontSize: '1.125rem',
    padding: '0px 8rem',
  },
  Text: {
    fontSize: '1.125rem',
  },
  stackItem: {
    margin: '1rem',
  },
});

const WorkshopUploadReport = ({
  progressData,
}) => {
  const classes = useStyles();
  return (
    <Container className={classes.bodyText}>
      {Object.keys(progressData).length ? (
        <>
          <Typography className={`${classes.Text} ${classes.bold}`} variant="body2">
            Marking users as complete
          </Typography>
          <Stack direction="row" className={classes.stackItem} alignItems="center" gap={1}>
            <CheckCircleIcon sx={{ color: 'green' }} />
            <Typography variant="body2" className={`${classes.Text}`}>
              <Typography className={`${classes.bold}`} variant="strong">
                {' '}
                {progressData.passed_count}
                /
                {progressData.total}
                {' '}
              </Typography>
              {' '}
              users successfully marked as complete
            </Typography>
          </Stack>
          <Stack direction="row" className={classes.stackItem} alignItems="center" gap={1}>
            <CancelIcon sx={{ color: 'red' }} />
            <Typography variant="body2" className={`${classes.Text} ${classes.bold}`}>
              <Typography className={`${classes.bold}`} variant="strong">
                {progressData.failed_count}
                /
                {progressData.total}
                {' '}
              </Typography>
              {' '}
              not marked as complete successfully
            </Typography>
          </Stack>
        </>
      ) : <></>}

    </Container>
  );
};

WorkshopUploadReport.propTypes = {
  progressData: PropTypes.object.isRequired,
};

export default memo(WorkshopUploadReport, (() => true));
