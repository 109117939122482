import get from 'lodash/get';

import { ROUTE_ERROR } from '../../constants';

export const noop = () => null;

export const internalServerErrorModalLogic = (
  history, err, setIsErrorModalOpen, pollProgressApi,
) => {
  const status = get(err, 'response.status');
  if (status === 500) {
    // Internal server error from our server
    setIsErrorModalOpen(true);
    return null;
  } if (status > 500) {
    // Cloudflare sometimes replies with a gateway timeout
    // Can be ignored safely
    return setTimeout(pollProgressApi, 1000);
  } if (status === 404) {
    // 404
    history.replace(`/${ROUTE_ERROR}`);
    return null;
  }
  // Unknown error. Just try again
  return setTimeout(pollProgressApi, 1000);
};
