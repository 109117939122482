import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import WebinarsTable from '../../components/Webinars/WebinarsTable';
import UserSheetUploadModal from '../../components/Webinars/UserSheetUploadModal';
import TransactionListModal from '../../components/Webinars/TransactionListModal';
import StatusSelectModal from '../../components/Webinars/StatusSelectModal';
import XlsxValidationModal from '../../components/XlsxValidationModal';
import Loading from '../../components/FullPageLoader/FullPageLoader';
import RefreshSection from '../../components/Webinars/RefreshSection';
import {
  getWebinars, getCourseEnrollments, uploadXlsx,
} from './apis';
import { validateXLSXApiCall, downloadFile } from '../common/apis';
import { actionTypeOptions, statusValues, ACTION_CHANGE_STATUS } from './config';

import {
  COURSE_OPS,
  WEBINAR_MANAGEMENT,
} from '../../constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  uploadWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: '2rem',
  },
  alertSnackBar: {
    display: 'flex',
    alignItems: 'center',
  },
  refreshWrapper: {
    marginBottom: '2rem',
  },
});

const getEnrollmentsHelper = async (data, update) => {
  const courseEnrollPromises = data.map((row) => getCourseEnrollments(row.course_id, update));
  const courseEnrollResponses = await Promise.all(courseEnrollPromises);

  let dataWithEnrollments = cloneDeep(data);

  dataWithEnrollments = dataWithEnrollments.map((row, idx) => ({
    ...row,
    ...courseEnrollResponses[idx],
  }));

  return dataWithEnrollments;
};

const fetchData = async (programId, setData, update) => {
  setData([]);
  const newData = await getWebinars(programId, update);
  const loadingData = newData.data.map((row) => ({ ...row, enrollment_count: 'Loading...', waitlisted_count: 'Loading...' }));
  setData(loadingData);

  try {
    const dataWithEnrollments = await getEnrollmentsHelper(newData.data, update);
    setData(dataWithEnrollments);
  } catch (e) {
    console.error(e);

    const errorData = newData.data.map((row) => ({ ...row, enrollment_count: 'Unable to fetch' }));
    setData(errorData);
  }
};

const WebinarManagementPage = (props) => {
  const { match, programMetadata } = props;
  const programType = COURSE_OPS;
  const programSubType = WEBINAR_MANAGEMENT;

  const programId = get(match, 'params.programId');
  const configId = get(programMetadata, 'config_id', '');

  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [transactionsModalOpen, setTransactionsModalOpen] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [uploadSheetModalOpen, setUploadSheetModalOpen] = useState(false);
  const [statusSelectModalOpen, setStatusSelectModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [excelRowCount, setExcelRowCount] = useState(0);
  const [excelErrorModalOpen, setExcelValidationModalOpen] = useState(false);
  const [excelErrors, setExcelErrors] = useState([]);
  const [snackbarText, setSnackbarText] = useState('');

  const classes = useStyles();

  useEffect(() => {
    fetchData(programId, setData, false);
  }, [programId, setData]);

  const getTransactionSummary = (transaction) => `${transaction.success}/${transaction.total} users updated`;

  const getLatestTransaction = (transactions) => {
    if (Array.isArray(transactions) && transactions.length) {
      const transaction = transactions.reduce((a, b) => (a.created_at > b.created_at ? a : b));
      return getTransactionSummary(transaction);
    }
    return '';
  };

  const onFileInput = async (e) => {
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);
    const response = await validateXLSXApiCall(programType, programSubType, formData);
    if ('errors' in response) {
      setExcelErrors(response.errors);
      setUploadSheetModalOpen(false);
      setExcelValidationModalOpen(true);
    } else {
      setFile(selectedFile);
      setExcelRowCount(get(response, 'rows', 0));
      setUploadSheetModalOpen(false);
      setStatusSelectModalOpen(true);
    }
  };

  const onReUploadExcel = () => {
    setExcelValidationModalOpen(false);
    setUploadSheetModalOpen(true);
  };

  const onDownload = async ({ transaction_id: tId, created_at: createdAt }) => {
    await downloadFile(tId, configId, WEBINAR_MANAGEMENT, createdAt);
  };

  const transformDataForTable = (webinarData) => webinarData.map((webinar) => ({
    id: webinar.course_id,
    name: webinar.course_name,
    waitlisted: webinar.waitlisted == null ? 'Loading...' : webinar.waitlisted,
    lastAction: getLatestTransaction(webinar.transactions),
  }));

  const onLastActionClick = (tableCourseData) => {
    const currentCourse = data.find((course) => course.course_id === tableCourseData.id);
    const tansactionSortedByDate = orderBy(currentCourse.transactions, ['created_at'], ['desc']);
    setTransactionData(tansactionSortedByDate);
    setTransactionsModalOpen(true);
  };

  const onActionSelect = (selectedAction, course) => {
    if (selectedAction === ACTION_CHANGE_STATUS) {
      setUploadSheetModalOpen(true);
      setCurrentAction(ACTION_CHANGE_STATUS);
      setSelectedCourse(course);
    }
  };

  const onRefresh = async () => {
    setShowLoader(true);
    try {
      await fetchData(programId, setData, true);
    } catch (err) {
      setSnackbarText('Unable to connect to Docebo. Please try later.');
      setSnackBarOpen(true);
      console.error(err);
    }
    setShowLoader(false);
  };

  const handleChangeStatusAction = async (status) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('course_id', selectedCourse.id);
    formData.append('program_id', programId);
    formData.append('status', status);
    formData.append('action', currentAction);
    try {
      setShowLoader(true);
      await uploadXlsx(programType, programSubType, formData, programId);
      await fetchData(programId, setData, false);
    } catch (err) {
      if (err.status === 400) {
        setSnackbarText('Docebo webinar API is returning an error. '
        + 'Please check if users exist in the  webinar or contact your system admin');
      } else {
        setSnackbarText('Unable to connect to Docebo. Please try later.');
      }
      setSnackBarOpen(true);
      console.error(err);
    }
    setStatusSelectModalOpen(false);
    setShowLoader(false);
    setCurrentAction(null);
    setSelectedCourse(null);
  };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.uploadWrapper}>
        {showLoader ? <Loading />
          : (
            <div className={classes.bodyContainer}>
              <div className={classes.refreshWrapper}>
                <RefreshSection onRefresh={onRefresh} />
              </div>
              <WebinarsTable
                data={transformDataForTable(data)}
                actionTypeOptions={actionTypeOptions}
                onLastActionClick={onLastActionClick}
                onActionSelect={onActionSelect}
              />
              <UserSheetUploadModal
                open={uploadSheetModalOpen}
                onFileInput={onFileInput}
                onClose={() => setUploadSheetModalOpen(false)}
              />
              <TransactionListModal
                onDownloadclick={onDownload}
                open={transactionsModalOpen}
                transactions={transactionData}
                configId={configId}
                onClose={() => setTransactionsModalOpen(false)}
              />
              <StatusSelectModal
                open={statusSelectModalOpen}
                onClose={() => setStatusSelectModalOpen(false)}
                statusValues={statusValues}
                handleChangeStatusAction={handleChangeStatusAction}
                selectedFileName={get(file, 'name', '')}
                excelRowCount={excelRowCount}
              />
              <XlsxValidationModal
                open={excelErrorModalOpen}
                onClose={() => setExcelValidationModalOpen(false)}
                onOk={onReUploadExcel}
                errors={excelErrors}
              />
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isSnackBarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBarOpen(false)}
              >
                <Alert
                  className={classes.alertSnackBar}
                  onClose={() => setSnackBarOpen(false)}
                  severity="error"
                >
                  {snackbarText}
                </Alert>
              </Snackbar>
            </div>
          )}
      </Paper>
    </div>
  );
};

WebinarManagementPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({
    configId: PropTypes.string,
  }).isRequired,
};

export default WebinarManagementPage;
