import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  labelWrapper: {
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputWrapper: {
    display: 'flex',
    fontSize: '1.125rem',
  },
  input: {
    fontSize: '1.125rem',
    margin: '8px 0',
    background: theme.palette.secondary.main,
    border: `1px solid ${LIGHT_MEDIUM_GREY}`,
    flex: 1,
    padding: '8px',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const LabledTextArea = ({
  label, value, onChange, placeholder, name, inputProps, className,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <Typography variant="body1" className={classes.labelWrapper}>
        {label}
      </Typography>
      <div className={classes.inputWrapper}>
        <TextareaAutosize
          name={name}
          aria-label="minimum height"
          minRows={3}
          value={value}
          className={classes.input}
          onChange={onChange}
          placeholder={placeholder}
          style={{ width: 200 }}
          {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
        />
      </div>
    </div>
  );
};

LabledTextArea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  inputProps: PropTypes.shape({}),
  className: PropTypes.string,
};

LabledTextArea.defaultProps = {
  placeholder: '',
  name: '',
  inputProps: {},
  className: '',

};

export default LabledTextArea;
