import React, {
  useEffect, useState, useCallback, createContext,
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';

import {
  ROUTE_ERROR,
  TEST_USERS_CREATION,
  METADATA_MANAGEMENT,
  ASSIGNMENT_DOWNLOAD,
  UPLOAD_GRADE,
  BULK_UPDATE_ACCOUNTS,
  PROGRAM_CREATION,
  ROUTE_REPORT,
  ROUTE_HOME,
  ASSIGN_COURSE_CONTENT,
  BULK_UNENROLLMENT,
  AUTO_ENROLLMENT,
  GROUPWORK_GROUPS,
  OVERVIEW,
  WEBINAR_MANAGEMENT,
  TEMPORARY_ADMIN,
  REPLICATION,
  EDIT_PROGRAM,
  ROLE_ADMIN,
  ACTIVITIES_STATUS,
  PROGRAM_TYPE,
  COURSE_PROD,
  SETTINGS,
  OPS_FEATURE,
  ROLE_OPS,
  ROLE_PROD,
  WORKSHOP_MANAGEMENT,
  MODIFY_GRADE,
  GLOBAL_ACTIVE_LOGS,
  ACTIVE_LOGS_MENU, CONTENT_MANAGEMENT, PRODUCTION_CHECKLIST,
  SYNC_SESSION_ENROLLMENTS, SINGLE_SESSION_SYNC,
  UPLOAD_GROUPWORK_ROLES, WAVE_MANAGEMENT, GROUP_ENROLLMENT,
} from '../../constants';

import ProgramCreationReportPage from '../ProgramCreationReportPage';
import TestUserCreationReportPage from '../TestUserCreationReportPage';
import MetaDataManagement from '../MetaDataManagement';
import OuterSidebar from '../../components/OuterSidebar';
import EnvironmentBanner from '../../components/EnvironmentBanner';
import ReportBanner from '../../components/CourseProdReportBody/FlowCommonComponents/ReportBanner';
import FindContentReportPage from '../FindContentReportPage';
import GroupWorkPage from '../GroupWorkPage';
import BulkUnenrollReportPage from '../BulkUnenrollReportPage';
import OverviewPage from '../OverviewPage';
import WebinarManagementPage from '../WebinarManagementPage';
import UserEnrollmentPage from '../UserEnrollment';
import ReplicationPage from '../ReplicationPage';
import EditProgramPage from '../EditProgramPage';
import AdminFragment from '../../components/AdminFragment';
import AssignmentManagementPage from '../AssignmentManagementPage';
import WorkshopManagementPage from '../WorkshopManagementPage';
import UploadGradesPage from '../UploadGradesPage';
import BulkUpdateAccountsPage from '../BulkUpdateAccountsPage';
import SettingsPage from '../SettingsPage';
import WaveManagement from '../WaveManagementPage';
import ProductionChecklist from '../ProductionChecklist';
// eslint-disable-next-line import/no-cycle
import ActivityLogs from '../ActivityLogs';
import GlobalActivityNav from '../../components/common/GlobalActivityNav';
import { VERY_LIGHT_GREY } from '../../stylesheets/colors';

import { createProgramLinks, getExperienceTypeOptions, getProgramInfo } from '../common/apis';
import { getEnvironmentFromLocalStorage } from '../../helpers/userHelper';
import { getNvtUserInfo } from '../../helpers/nvtUserInfoHelper';
import {
  updateProgramMetadata, onDownload, getExtendedEnterprise, getDeltaForEnrollment,
  onForceEnroll,
} from './apis';
import ContentManagementPage from '../ContentManagement';
import AppAlertBar from '../../components/common/AppAlertBar';
import { NotificationProvider } from '../../providers/NotificationProvider';

const exceptionalEndpoints = [EDIT_PROGRAM];

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: VERY_LIGHT_GREY,
    minHeight: '100vh',
  },
  containerWrapper: {
    display: 'flex',
    flex: 1,
  },
  contentWrapper: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  collapseContent: {
    flex: '0.98',
  },
  logWrapper: {
    maxHeight: '100%',
    flex: '0.02',
  },
  summaryBanner: {
    display: 'flex',
  },
  innerWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    margin: '4rem',
  },
  sidebarWrapper: {
    display: 'flex',
    flex: 0.15,
    marginRight: '2rem',
    minWidth: '15rem',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.85,
  },
  drawerWrapper: {
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
});

const subtypeAssignment = {
  [OVERVIEW]: OverviewPage,
  [PROGRAM_CREATION]: ProgramCreationReportPage,
  [ASSIGN_COURSE_CONTENT]: FindContentReportPage,
  [TEST_USERS_CREATION]: TestUserCreationReportPage,
  [METADATA_MANAGEMENT]: MetaDataManagement,
  [CONTENT_MANAGEMENT]: ContentManagementPage,
  [PRODUCTION_CHECKLIST]: ProductionChecklist,
  [UPLOAD_GRADE]: UploadGradesPage,
  [MODIFY_GRADE]: UploadGradesPage,
  [BULK_UPDATE_ACCOUNTS]: BulkUpdateAccountsPage,
  [GROUPWORK_GROUPS]: GroupWorkPage,
  [UPLOAD_GROUPWORK_ROLES]: GroupWorkPage,
  [GROUP_ENROLLMENT]: GroupWorkPage,
  [AUTO_ENROLLMENT]: UserEnrollmentPage,
  [BULK_UNENROLLMENT]: BulkUnenrollReportPage,
  [WEBINAR_MANAGEMENT]: WebinarManagementPage,
  [TEMPORARY_ADMIN]: AdminFragment,
  [REPLICATION]: ReplicationPage,
  [EDIT_PROGRAM]: EditProgramPage,
  [ASSIGNMENT_DOWNLOAD]: AssignmentManagementPage,
  [SETTINGS]: SettingsPage,
  [WORKSHOP_MANAGEMENT]: WorkshopManagementPage,
  [SYNC_SESSION_ENROLLMENTS]: WorkshopManagementPage,
  [SINGLE_SESSION_SYNC]: WorkshopManagementPage,
  [WAVE_MANAGEMENT]: WaveManagement,
};
export const Context = createContext();

const ReportPage = ({ match, location }) => {
  const classes = useStyles();
  const history = useHistory();
  const programId = get(match, 'params.programId');
  const programType = get(match, 'params.programType');
  const [programSubType, qs_opt] = get(match, 'params.programSubType').split('?');
  const routeTransactionId = get(match, 'params.transactionId');
  const qs = location.search || (qs_opt ? `?${qs_opt}` : '');

  const [programMetadata, setProgramMetadata] = useState(null);
  const [experienceTypeOptions, setExperienceTypeOptions] = useState([]);
  const [extendedEnterpriseTypeOptions, setExtendedEnterpriseTypeOptions] = useState([]);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [updatedConfig, setUpdatedConfig] = useState({
    updatedStatus: '',
    updatedExp: '',
    updatedPlatformUrl: '',
  });
  const [snackBarMessage, setSnackBarMessage] = useState(
    'Unable to connect to Docebo. Please try later.',
  );
  const [nvtUserInfo, setNvtUserInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(ACTIVE_LOGS_MENU[0]);
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);

  const isShow = GLOBAL_ACTIVE_LOGS.includes(programSubType);

  const toggleDrawer = (value) => {
    setOpen(value);
  };

  const onMenuItemClick = (item) => {
    setSelectedMenu(item);
  };

  const Body = subtypeAssignment[programSubType] || (() => null);

  useEffect(() => {
    window.heap.track(OPS_FEATURE, programSubType);
    console.log('tracking OPS_FEATURE => ', programSubType);
  }, [programSubType]);

  useEffect(() => {
    setUpdatedConfig({ updatedStatus: '', updatedExp: '', updatedPlatformUrl: '' });
  }, [programId]);

  useEffect(() => {
    (async () => {
      try {
        const listEE = await getExtendedEnterprise();
        setExtendedEnterpriseTypeOptions(get(listEE, 'data.results', []));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    const goToError = () => {
      history.replace(`/${ROUTE_ERROR}`);
    };

    const fetchProgramMetadata = async () => {
      try {
        const response = await getProgramInfo(programId);
        setProgramMetadata(response);
      } catch (e) {
        console.log(e);

        // If error status 404, then redirect to 404 page
        if (get(e, 'response.status', 0) === 404) {
          goToError();
        }
      }
    };

    if (!Object.keys(subtypeAssignment).includes(programSubType)) {
      goToError();
      return;
    }
    fetchProgramMetadata();
  }, [programId, setProgramMetadata, history, programSubType]);

  const onProgramMetadataRefresh = useCallback(async () => {
    const response = await getProgramInfo(programId);
    setProgramMetadata(response);
  }, [programId]);

  const transactionIds = get(
    programMetadata,
    `activities_status.${ACTIVITIES_STATUS[programSubType] ? ACTIVITIES_STATUS[programSubType] : ''
    }.${programSubType}.transaction_ids`,
    [],
  );

  // handle browser back button
  useEffect(
    () => () => {
      if (history.action === 'POP') {
        const currentSearchText = get(location, 'state.currentSearchText', '');
        history.push(`/${ROUTE_HOME}/${programType}`, { currentSearchText });
      }
    },
    [history, location, programType],
  );

  useEffect(() => {
    const currentSearchText = get(location, 'state.currentSearchText', '');
    if (
      !routeTransactionId
      && get(transactionIds, 'length') === 1
      && !exceptionalEndpoints.includes(programSubType)
    ) {
      const tid = transactionIds[0];
      history.replace(`/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}/${tid}`, {
        currentSearchText,
      });
    }
  }, [
    history,
    location,
    programId,
    programType,
    programSubType,
    transactionIds,
    routeTransactionId,
  ]);

  useEffect(() => {
    const populateExpTypes = async () => {
      try {
        const expTypeResult = await getExperienceTypeOptions();
        const options = expTypeResult.data.data.options
          .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
          .map((op) => ({ value: op, label: op }));
        setExperienceTypeOptions(options);
      } catch (e) {
        setSnackBarMessage('Unable to load experiences from Docebo. Please try later.');
        setSnackBarOpen(true);
        setExperienceTypeOptions([]);
      }
    };

    if (programMetadata) {
      populateExpTypes();
    }
  }, [setExperienceTypeOptions, programMetadata]);

  useEffect(() => {
    const inner = async () => {
      const userData = await getNvtUserInfo();
      setNvtUserInfo(userData);
    };

    if (programMetadata) {
      inner();
    }
  }, [setNvtUserInfo, programMetadata]);

  if (!programMetadata) return <div />; // Loading

  const onProgramSubtypeChange = (newProgramSubType) => {
    const currentSearchText = get(location, 'state.currentSearchText', '');
    const newTid = get(
      programMetadata,
      `activities_status.${ACTIVITIES_STATUS[newProgramSubType] ? ACTIVITIES_STATUS[newProgramSubType] : ''
      }.${newProgramSubType}.transaction_id`,
    );

    const unmappedFeatures = [TEST_USERS_CREATION, METADATA_MANAGEMENT, GROUPWORK_GROUPS];

    const typeOfUser = PROGRAM_TYPE[newProgramSubType]
      && !unmappedFeatures.includes(newProgramSubType)
      ? PROGRAM_TYPE[newProgramSubType] : programType;

    const routeToNavigate = `/${ROUTE_REPORT}/${programId}/${typeOfUser}/${newProgramSubType}/${newTid || ''}`;

    history.push(routeToNavigate, { currentSearchText });
  };

  const getUserRole = (roles) => {
    let role = ROLE_OPS;
    if (roles.includes(ROLE_ADMIN)) {
      role = ROLE_ADMIN;
    } else if (roles.includes(ROLE_PROD)) {
      role = ROLE_PROD;
    }
    return role;
  };

  const isAdmin = nvtUserInfo?.teams?.includes(ROLE_ADMIN);
  const userRoles = nvtUserInfo?.teams || [];
  const userRole = getUserRole(userRoles);
  const showAcadmeygGo = userRole === ROLE_ADMIN || userRole === ROLE_PROD;

  const onBackClick = () => {
    const currentSearchText = get(location, 'state.currentSearchText', '');
    history.push(`/${ROUTE_HOME}/${isAdmin ? COURSE_PROD : programType}`, { currentSearchText });
  };

  const environment = getEnvironmentFromLocalStorage();

  const onLinkSave = async (linkObj) => {
    try {
      await createProgramLinks(programId, linkObj);
      await onProgramMetadataRefresh();
    } catch (e) {
      setSnackBarMessage('Unable to create program links. Please try later.');
      setSnackBarOpen(true);
    }
  };

  const onExperienceTypeChange = async (newExpType) => {
    try {
      await updateProgramMetadata(programId, 'primary_experience', newExpType);
      setProgramMetadata((prevMetadata) => ({ ...prevMetadata, experience: newExpType }));
    } catch (e) {
      setSnackBarMessage('Unable to change primary experience. Please try later.');
      setSnackBarOpen(true);
    }
  };

  const onExtendedEnterpriseChange = async (newExpType) => {
    try {
      await updateProgramMetadata(programId, 'extended_enterprise', newExpType);
      setProgramMetadata((prevMetadata) => ({ ...prevMetadata, extended_enterprise: newExpType }));
    } catch (e) {
      setSnackBarMessage('Unable to change extended enterprise. Please try later.');
      setSnackBarOpen(true);
    }
  };

  const onStageChange = async (newStage) => {
    try {
      await updateProgramMetadata(programId, 'stage', newStage);
    } catch (e) {
      setSnackBarMessage('Unable to change stage. Please try later.');
      setSnackBarOpen(true);
    }
  };

  const getValueFromConfig = (updatedStatus, updatedExp, updatedPlatformUrl) => {
    setUpdatedConfig({ updatedStatus, updatedExp, updatedPlatformUrl });
  };

  const onGoStatusChange = (is_academy_go) => {
    setProgramMetadata((prevMetadata) => ({ ...prevMetadata, is_academy_go }));
  };

  const preparePageTitle = (title) => title.replaceAll('_', ' ').toUpperCase();

  return (
    <Context.Provider value={{ isActivityLogModalOpen, setIsActivityLogModalOpen }}>
      <div className={classes.wrapper}>
        <div className={classes.containerWrapper}>
          <div className={`${classes.contentWrapper} ${isShow ? classes.collapseContent : null}`}>
            <Helmet>
              <title>{preparePageTitle(programSubType)}</title>
            </Helmet>
            <EnvironmentBanner environment={environment.environment} />
            <div className={classes.summaryBanner}>
              <ReportBanner
                onBackClick={onBackClick}
                programMetadata={{ ...programMetadata, ...updatedConfig }}
                onLinkSave={onLinkSave}
                experienceTypeOptions={experienceTypeOptions}
                extendedEnterpriseTypeOptions={extendedEnterpriseTypeOptions}
                onExtendedEnterpriseChange={onExtendedEnterpriseChange}
                onExperienceTypeChange={onExperienceTypeChange}
                onStageChange={onStageChange}
                nvtUserInfo={nvtUserInfo}
                match={match}
                userRole={userRole}
              />
            </div>
            <div className={classes.innerWrapper}>
              <div className={classes.sidebarWrapper}>
                <OuterSidebar
                  isAdmin={isAdmin}
                  programType={programType}
                  programSubType={programSubType}
                  onChange={onProgramSubtypeChange}
                  programMetadata={programMetadata}
                />
              </div>
              <div className={classes.bodyWrapper}>
                <NotificationProvider>
                  <AppAlertBar />
                  <Body
                    match={match}
                    history={history}
                    location={location}
                    isAdmin={isAdmin}
                    userRole={userRole}
                    experienceTypeOptions={experienceTypeOptions}
                    extendedEnterpriseTypeOptions={extendedEnterpriseTypeOptions}
                    onProgramMetadataRefresh={onProgramMetadataRefresh}
                    transactionIds={transactionIds}
                    programMetadata={programMetadata}
                    onDownload={onDownload}
                    getDeltaForEnrollment={getDeltaForEnrollment}
                    onForceEnroll={onForceEnroll}
                    currentSearchText={get(location, 'state.currentSearchText', '')}
                    getValueFromConfig={getValueFromConfig}
                    onGoStatusChange={onGoStatusChange}
                    qs={qs}
                    isActivityLogModalOpen={isActivityLogModalOpen}
                    showAcadmeygGo={showAcadmeygGo}
                    programType={programType}
                    programId={programId}
                  />
                </NotificationProvider>
              </div>
            </div>
          </div>
          <Paper className={classes.logWrapper} component="div" elevation={3}>
            <GlobalActivityNav
              expanded={open}
              toggleDrawer={toggleDrawer}
              selectedMenu={selectedMenu}
              onMenuItemClick={onMenuItemClick}
              disabled
            />
          </Paper>
          <Drawer
            anchor="right"
            open={open}
            onClose={() => toggleDrawer(false)}
            className={classes.drawerWrapper}
          >
            <Paper className={classes.drawerIcons} elevation={3}>
              <GlobalActivityNav
                expanded={open}
                toggleDrawer={toggleDrawer}
                selectedMenu={selectedMenu}
                onMenuItemClick={onMenuItemClick}
                disabled={false}
              />
            </Paper>
            <Box className={classes.drawerContainer}>
              <ActivityLogs
                selectedMenu={selectedMenu}
                programId={programId}
                programType={programType}
                programMetadata={programMetadata}
                programSubType={programSubType}
              />
            </Box>
          </Drawer>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isSnackBarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackBarOpen(false)}
        >
          <Alert
            className={classes.alertSnackBar}
            onClose={() => setSnackBarOpen(false)}
            severity="error"
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </Context.Provider>
  );
};

ReportPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
};

export default ReportPage;
