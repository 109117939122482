import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import MaterialTable from 'material-table';
import { Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _isEqual from 'lodash/isEqual';
import { MCKINSEY_BLUE } from '../../../stylesheets/colors';
import { GROUPS_INNER_TABLE } from '../../../constants';
import CustomPopper from '../../common/Popper';
import tableStyles from '../../../stylesheets/table.styles';
import accordianStyles from '../../../stylesheets/accordian.styles';

const useStyles = makeStyles(() => ({
  ...accordianStyles,
  memberNameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& svg': {
      color: MCKINSEY_BLUE,
    },
    '& > p': {
      marginLeft: '0.5rem',
    },
  },
}));

const GroupMemberTable = ({
  group_members, handleMoveMemberClick, handleMakeGcClick,
  modifiedMember, handleUnassignGcClick, handleRemoveMemberClick,
}) => {
  const classes = useStyles();
  const { email: memberEmail = '' } = modifiedMember;
  const tableOptions = {
    ...tableStyles,
    search: false,
    toolbar: false,
    paging: false,
    sorting: false,
    draggable: false,
    selection: false,
    tableLayout: 'auto',
    maxBodyHeight: 400,
  };

  return (
    <MaterialTable
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      data={group_members}
      columns={Object.keys(GROUPS_INNER_TABLE).map(
        (column) => {
          let colObj = {};
          if (column === 'name') {
            colObj = {
              render: ({ name, email }) => (
                <Box className={classes.memberNameWrapper}>
                  {email && memberEmail === email ? (
                    <FiberManualRecordIcon fontSize="small" />
                  ) : <FiberManualRecordIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                  <Typography variant="body1">{name}</Typography>
                </Box>
              ),
            };
          }
          if (column === 'is_gc') {
            colObj = {
              render: ({ is_gc }) => {
                if (is_gc) {
                  return (
                    <Tooltip title="Group Co-ordinator" arrow placement="top">
                      <StarOutlinedIcon fontSize="medium" style={{ color: MCKINSEY_BLUE }} />
                    </Tooltip>
                  );
                }
                return null;
              },
            };
          }
          if (column === 'actions') {
            colObj = {
              render: (memberObj) => {
                const { is_gc } = memberObj;
                const list = [
                  {
                    title: 'Move member',
                    onClick: () => {
                      handleMoveMemberClick(memberObj);
                    },
                  },
                ];

                if (!is_gc) {
                  list.push({
                    title: 'Make member GC',
                    onClick: () => {
                      handleMakeGcClick(memberObj);
                    },
                  });
                } else {
                  list.push({
                    title: 'Remove member as GC',
                    onClick: () => {
                      handleUnassignGcClick(memberObj);
                    },
                  });
                }

                list.push({
                  title: 'Remove member from group',
                  onClick: () => {
                    handleRemoveMemberClick(memberObj);
                  },
                });

                return (
                  <CustomPopper list={list} />
                );
              },
            };
          }
          colObj = {
            ...colObj,
            field: column,
            title: GROUPS_INNER_TABLE[column].value,
            width: GROUPS_INNER_TABLE[column].width,
            cellStyle: {
              width: GROUPS_INNER_TABLE[column].width,
            },
            headerStyle: {
              width: GROUPS_INNER_TABLE[column].width,
            },
          };

          return colObj;
        },
      )}
      options={tableOptions}
    />
  );
};

GroupMemberTable.propTypes = {
  group_members: PropTypes.array.isRequired,
  handleMoveMemberClick: PropTypes.func.isRequired,
  handleMakeGcClick: PropTypes.func.isRequired,
  handleUnassignGcClick: PropTypes.func.isRequired,
  handleRemoveMemberClick: PropTypes.func.isRequired,
  modifiedMember: PropTypes.object.isRequired,
};

export default memo(GroupMemberTable,
  (prevProps, nextProps) => _isEqual(prevProps.group_members, nextProps.group_members)
    && _isEqual(prevProps.modifiedMember, nextProps.modifiedMember));
