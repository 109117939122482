/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogTitle, Divider } from '@mui/material';
import CustomUploadModal from '../../common/CustomUploadModal';
import { LoaderInner } from '../../Loader';
import EnrollmentStuckQueue from '../../common/EnrollmentStuckQueue';
import SyncReportModal from './SyncReportModal';
import FormattedTypography from '../../common/FormattedTypography';

import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import BlueAlertIcon from '../../../assets/icons/icon-blue-alert.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';

import MCKINSEY_SANS from '../../../stylesheets/fonts';
import {
  COMPLETED, COMPLETED_WITH_ERRORS, FAILED,
} from '../../../constants';
import { downloadFile } from '../../../containers/common/apis';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    margin: '0 auto',
  },
  avtarSection: {
    padding: '1rem 1rem 0 1rem',
    marginBottom: '0 !important',
  },
  bodyTextClass: {
    fontSize: '2rem',
    fontWeight: 'bold',
    fontFamily: MCKINSEY_SANS,
  },
  headingWrapper: {
    padding: '0 18rem !important',
  },
}));

const SyncStatusModal = ({
  open, onClose, progress, onSyncAgainClick, programId, hideSyncAgain,
}) => {
  const classes = useStyles();
  const [modalState, setModalState] = useState(null);
  const onDownloadLog = async () => {
    try {
      await downloadFile(progress?.transaction_id, programId, 'session_enrollment_sync', progress?.created_at, '', false);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const reportModalButtonProps = () => {
    if ([COMPLETED_WITH_ERRORS, FAILED].includes(progress?.status)) {
      if (hideSyncAgain) {
        return {
          primaryBtnText: 'Close',
          primaryBtnProps: { onClick: () => onClose() },
          secondaryBtnText: 'Download Log',
          secondaryBtnProps: { onClick: () => onDownloadLog() },
        };
      }
      return {
        primaryBtnText: 'Sync Again',
        primaryBtnProps: {
          onClick: () => onSyncAgainClick(),
        },
        secondaryBtnText: 'Download Log',
        secondaryBtnProps: { onClick: () => onDownloadLog() },
      };
    }
    return {
      primaryBtnText: 'Close',
      primaryBtnProps: { onClick: () => onClose() },
    };
  };

  const [modalSchema, setModalScheme] = useState({
    PROGRESS: {
      component: SyncReportModal,
      wrapperProps: {
        logo: (
          <div className={classes.progressRow}>
            <LoaderInner progress={0} scaleFactor={1.5} />
          </div>
        ),
        heading: null,
        breakpoint: 'md',
        primaryBtnText: 'Close',
        primaryBtnProps: {
          onClick: () => onClose(),
        },
      },
      props: {},
    },
    QUEUED: {
      component: EnrollmentStuckQueue,
      wrapperProps: {
        logo: <></>,
        heading: null,
        breakpoint: 'md',
      },
      props: {
        queue_position: '',
      },
    },
    REPORT: {
      component: SyncReportModal,
      wrapperProps: {
        logo: null,
        heading: null,
        breakpoint: 'md',
      },
      props: {},
    },
  });

  const applyReportModal = useCallback(() => {
    batchUpdates(() => {
      setModalState('REPORT');
      setModalScheme((schema) => ({
        ...schema,
        REPORT: {
          ...schema.REPORT,
          wrapperProps: {
            ...schema.REPORT.wrapperProps,
            logoWrapper: classes.logoIcon,
            contentWrapper: classes.contentBox,
            headingDivider: true,
            contentDivider: true,
            disableGutters: true,
            logo: (
              <img
                src={
                  progress?.status === COMPLETED
                    ? CheckRoundIcon
                    : progress?.status === FAILED
                      ? ErrorRoundIcon
                      : BlueAlertIcon
                }
                alt="status-icons"
                width={80}
                height={80}
                className={classes.avtarSection}
              />
            ),
            heading: (
              <DialogTitle className={classes.headingWrapper}>
                <FormattedTypography
                  className={classes.bodyTextClass}
                  body={
                    progress?.status === COMPLETED
                      ? 'Sync successfully completed!'
                      : progress?.status === FAILED
                        ? 'Sync failed'
                        : 'Sync is complete with errors'
                  }
                  subHeading={
                    progress?.status === COMPLETED
                      ? 'All users were successfully synced'
                      : progress?.status === COMPLETED_WITH_ERRORS
                        ? 'Some users were unable to sync. Please sync users again, don’t worry this time will be faster since we will only enroll users that were left behind.'
                        : ''
                  }
                />
              </DialogTitle>
            ),
            ...reportModalButtonProps(),
          },
          props: {
            ...schema.REPORT.props,
            progressData: { ...progress },
          },
        },
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const applyProgressModal = useCallback(() => {
    if (progress) {
      batchUpdates(() => {
        setModalState('PROGRESS');
        setModalScheme((schema) => ({
          ...schema,
          PROGRESS: {
            ...schema.PROGRESS,
            wrapperProps: {
              ...schema.PROGRESS.wrapperProps,
              heading: (
                <>
                  <DialogTitle>
                    <FormattedTypography
                      body="Syncing user enrollments in Sessions"
                      subHeading="Please check back in some time to perform actions to workshops"
                    />
                  </DialogTitle>
                  <Divider />
                </>
              ),
              logo: (
                <div className={classes.progressRow}>
                  <LoaderInner
                    progress={progress?.percentage ? progress?.percentage : 0}
                    scaleFactor={0.75}
                  />
                </div>
              ),
              primaryBtnProps: {
                ...schema.PROGRESS.wrapperProps.primaryBtnProps,
              },
            },
            props: {
              ...schema.PROGRESS.props,
              progressData: { ...progress },
            },
          },
        }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    if (open) {
      if (progress.done === true) {
        applyReportModal();
      } else {
        applyProgressModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  return (
    <>
      {ModalComponent && (
        <CustomUploadModal open={open} onClose={onClose} {...modalSchema[modalState].wrapperProps}>
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
    </>
  );
};

SyncStatusModal.defaultProps = {
  hideSyncAgain: false,
};

SyncStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
    percentage: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    transaction_id: PropTypes.number,
    created_at: PropTypes.string,
  }).isRequired,
  onSyncAgainClick: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  hideSyncAgain: PropTypes.bool,
};

export default SyncStatusModal;
