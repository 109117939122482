import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  link: {
    textDecoration: 'underline',
  },
});

const FailedPrompt = ({ fileName, onDownloadClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {fileName}
      {' '}
      file is updated with rows including errors.
      {' '}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        role="button"
        onClick={onDownloadClick}
        onKeyPress={onDownloadClick}
        tabIndex={0}
        className={classes.link}
      >
        Download
      </a>
      {' '}
      and modify to complete process.
    </div>
  );
};

FailedPrompt.propTypes = {
  fileName: PropTypes.string.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
};

export default FailedPrompt;
