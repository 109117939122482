import PropTypes from 'prop-types';
import Colors from './colors';
import '../../stylesheets/fonts.css';

export const DefaultTheme = {
  primary: Colors.MCK_BLUE,
  secondary: Colors.MCK_DARK_BLUE,
  tertiary: Colors.MCK_BLUE,
  success: Colors.IVY,
  warning: Colors.GOLD,
  danger: Colors.WARM_RED,
  information: Colors.SUPER_BLUE,
  white: Colors.WHITE,
  light_gray: Colors.LIGHT_GRAY,
  off_white: Colors.MCK_OFF_WHITE,
  dark_gray: Colors.MCK_GRAY,
  black: Colors.BLACK,
  background_gray: Colors.PALE_GRAY,
  background_blue: Colors.PALE_BLUE,
  font_family: "'Theinhardt Pan', 'Open Sans Regular', 'Helvetica', 'Arial', sans-serif;",
  font_family_tooltip: 'Theinhardt Pan', // some reason the font above doesn't work in nivo tooltip
  font_family_bower: "'Bower', 'Garamond', 'Hoefler Text', 'Times New Roman', 'Times', serif;",
  colors: Colors,
};

export const ThemeShape = PropTypes.shape({
  primary: PropTypes.string,
  secondary: PropTypes.string,
  tertiary: PropTypes.string,
  success: PropTypes.string,
  warning: PropTypes.string,
  danger: PropTypes.string,
  information: PropTypes.string,
  white: PropTypes.string,
  light_gray: PropTypes.string,
  off_white: PropTypes.string,
  dark_gray: PropTypes.string,
  black: PropTypes.string,
  background_gray: PropTypes.string,
  background_blue: PropTypes.string,
  font_family: PropTypes.string,
});
