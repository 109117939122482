import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(() => ({
  expanded: {
    fontWeight: 'bold',
  },
}));

const SideBarListItem = ({
  groupTitles, leftPad, onChange, open, programSubType, title, type,
}) => {
  const classes = useStyles();
  const isGroupTitle = groupTitles.includes(type);
  return (
    <ListItemButton
      key={type}
      sx={{ pl: leftPad }}
      onClick={() => onChange(type)}
      selected={programSubType === type}
      className={isGroupTitle && (open ? classes.expanded : null)}
    >
      <ListItemText primary={title} />
      {isGroupTitle && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
    </ListItemButton>
  );
};

SideBarListItem.propTypes = {
  groupTitles: PropTypes.array.isRequired,
  leftPad: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
  programSubType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

SideBarListItem.defaultProps = {
  leftPad: undefined,
  open: false,
};

export default memo(SideBarListItem);
