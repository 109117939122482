import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  alertBox: {
    alignItems: 'center',
    '&.MuiAlert-root': {
      alignItems: 'center',
      borderRadius: '0',
    },
    '& .MuiAlert-message': {
      fontSize: '1.25rem',
    },
  },
}));

const AlertBar = ({
  text, icon, open, setOpen, className, showCloseIcon,
}) => {
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(typeof open !== 'undefined' ? open : true);
  const updateState = setOpen || setAlertOpen;

  return (
    <div className={className}>
      <Collapse in={alertOpen}>
        <Alert
          className={classes.alertBox}
          severity={icon}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => updateState(false)}
            >
              {showCloseIcon && (<CloseIcon fontSize="inherit" />)}
            </IconButton>
          )}
        >
          {text}
        </Alert>
      </Collapse>
    </div>
  );
};

AlertBar.defaultProps = {
  className: '',
  icon: 'success',
  open: undefined,
  setOpen: undefined,
  showCloseIcon: true,
};

AlertBar.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

export default memo(AlertBar, (
  (prevProps, nextProps) => prevProps.text === nextProps.text && prevProps.icon === nextProps.icon
  && prevProps.className === nextProps.className && prevProps.open === nextProps.open
  && prevProps.setOpen === nextProps.setOpen
));
