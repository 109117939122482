import FileDownload from 'js-file-download';

import config from '../../config';
import request from '../../helpers/apiHelper';

export const downloadFile = async (transactionId, configId, createdAt) => {
  const url = `${config.ROUTES.DOWNLOAD_XLSX}/${transactionId}`;
  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;

  const createdAtFormated = new Date(createdAt).toDateString();

  // https://stackoverflow.com/a/41940307/1217998
  FileDownload(data, `${configId} - ${createdAtFormated}.xlsx`);

  return result;
};

export const ignoreErrorsForTransaction = async (transactionId) => {
  const url = `${config.ROUTES.MARK_FOR_COMPLETION}/${transactionId}`;
  const options = { url, method: 'POST' };
  const result = await request(options);

  return result;
};

export const updateProgramMetadata = async (programId, type, newMetadata) => {
  const url = `${config.ROUTES.PROGRAM}/${type}/${programId}`;
  const data = {
    [type]: newMetadata,
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);

  return result;
};

export const onDownload = async (url, fileName = 'download') => {
  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  FileDownload(result.data, `${fileName}.xlsx`);
  return result;
};

export const getExtendedEnterprise = () => {
  const url = config.ROUTES.GET_EXTENDED_ENTERPRISE;
  const options = { url, method: 'GET' };
  return request(options);
};

export const getDeltaForEnrollment = (lpId) => {
  const url = `${config.ROUTES.USERS_API}/${lpId}/delta`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const onForceEnroll = (lpId) => {
  const url = `${config.ROUTES.USERS_API}/${lpId}/enroll/force`;
  const options = { url, method: 'GET' };
  return request(options);
};
