import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';

import CloseIcon from '../../assets/icons/close-outline.svg';
import {
  TEXT_GREEN, LIGHT_GREEN, LIGHT_YELLOW, DARK_YELLOW, ERROR_RED, ERROR_RED_BG,
} from '../../stylesheets/colors';

const colorClass = {
  success: { text: TEXT_GREEN, bg: LIGHT_GREEN },
  warning: { text: DARK_YELLOW, bg: LIGHT_YELLOW },
  error: { text: ERROR_RED, bg: ERROR_RED_BG },
};

const useStyles = makeStyles(() => ({
  wrapper: (props) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colorClass[props.type].bg,
    height: '4rem',
  }),
  textMessage: (props) => ({
    color: colorClass[props.type].text,
  }),
  iconWrapper: (props) => ({
    margin: '0 1rem',
    color: colorClass[props.type].text,
  }),
  closeIconWrapper: {
    margin: '0 1rem 0 auto',
    alignSelf: 'right',
  },
}));

const DownloadSuccessBanner = ({
  text, fileName, onClose, type,
}) => {
  const classes = useStyles({ type });

  useEffect(() => {
    if (text && onClose) {
      setTimeout(() => {
        onClose();
      }, 5000);
    }
  }, [onClose, text]);

  if (!text) {
    return false;
  }

  const alertIcon = {
    success: <CheckCircleIcon className={classes.iconWrapper} />,
    warning: <WarningIcon className={classes.iconWrapper} />,
    error: <CancelIcon className={classes.iconWrapper} />,
  };

  return (
    <div className={classes.wrapper}>
      {alertIcon[type]}
      <Typography variant="body1" className={classes.textMessage}>
        {text || `‘${fileName}.zip’ has been successfully downloaded to your machine.` }
      </Typography>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={onClose}
        className={classes.closeIconWrapper}
      >
        <img src={CloseIcon} alt="check-round" />
      </IconButton>
    </div>
  );
};

DownloadSuccessBanner.defaultProps = {
  text: null,
  type: 'success',
  fileName: null,
};

DownloadSuccessBanner.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  fileName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default DownloadSuccessBanner;
