import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { MODERATE_LIGHT_GREY, SUCCESS_GREEN, ERROR_RED } from '../../stylesheets/colors';
import Calendar from '../../assets/icons/calendar.svg';

const useStyles = makeStyles({
  wrapper: {
    padding: '1.5rem 0',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.75rem',
  },
  detailWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 0 0 2.5rem',
    lineHeight: '2rem',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  highlite: {
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& > img': {
      flex: 0.05,
    },
    '& > p': {
      flex: 0.91,
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
    alignItems: 'baseline',
    '& > button': {
      marginRight: '2rem',
    },
  },
  illustration: {
    width: '1.5rem',
    marginBottom: '0.5rem',
  },
  success: {
    color: SUCCESS_GREEN,
  },
  error: {
    color: ERROR_RED,
  },
  breakText: {
    wordBreak: 'break-all',
  },
});

const TimelineStep = ({
  data: {
    // eslint-disable-next-line react/prop-types
    created_at, created_by, completed, id, destination_program_id,
  },
  link,
  openModalWithReportData,
  successMsg,
  errorMsg,
  partialMsg,
  children,
  downloadLog,
  hideView,
  isPartial,
  isCustomMsg,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const onViewDetailClick = () => {
    if (destination_program_id) {
      openModalWithReportData(id, destination_program_id);
    } else {
      history.push(link, { isViewDetails: true });
    }
  };

  const getMessage = () => {
    if (isPartial) {
      return partialMsg;
    }
    return completed ? successMsg : errorMsg;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.highlite}>
        <img className={classes.illustration} src={Calendar} alt="date" />
        <Typography variant="body1" className={classes.breakText}>
          {`${format(new Date(`${created_at}+00:00`), 'do MMM yy hh:mm a')}${
            created_by ? ` by ${created_by}` : ''
          }`}
        </Typography>
      </div>
      <div className={classes.detailWrapper}>
        {!isCustomMsg && (
          <Typography variant="body1" className={completed ? classes.success : classes.error}>
            {getMessage()}
          </Typography>
        )}

        {children}
        <div className={classes.footer}>
          {downloadLog && (
            <Button color="primary" variant="contained" onClick={downloadLog}>
              Download Log
            </Button>
          )}
          {
            !hideView && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link underline="hover" onClick={onViewDetailClick}>
                View Details
              </Link>
            )
          }
        </div>
      </div>
    </div>
  );
};

TimelineStep.defaultProps = {
  downloadLog: null,
  destination_program_id: null,
  id: null,
  hideView: false,
  isPartial: false,
  partialMsg: '',
  openModalWithReportData: () => { },
  link: '',
  isCustomMsg: false,
  successMsg: '',
  errorMsg: '',
  children: null,
};

TimelineStep.propTypes = {
  link: PropTypes.string,
  downloadLog: PropTypes.func,
  id: PropTypes.number,
  destination_program_id: PropTypes.string,
  successMsg: PropTypes.string,
  errorMsg: PropTypes.string,
  partialMsg: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    created_by: PropTypes.string,
    completed: PropTypes.bool,
  }).isRequired,
  openModalWithReportData: PropTypes.func,
  hideView: PropTypes.bool,
  isPartial: PropTypes.bool,
  isCustomMsg: PropTypes.bool,
};

export default memo(
  TimelineStep,
  (prevProps, nextProps) => prevProps.link === nextProps.link
    && prevProps.successMsg === nextProps.successMsg
    && prevProps.downloadLog === nextProps.downloadLog
    && prevProps.destination_program_id === nextProps.destination_program_id
    && prevProps.errorMsg === nextProps.errorMsg
    && _isEqual(prevProps.children, nextProps.children)
    && _isEqual(prevProps.data, nextProps.data),
);
