import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogTitle } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { LoaderInner } from '../Loader';
import FormattedTypography from '../common/FormattedTypography';
import {
  CANCELLED, COMPLETED, COMPLETED_WITH_ERRORS, FAILED,
} from '../../constants';
import { ACCORDION_GREY, MEDIUM_GREY } from '../../stylesheets/colors';
import ReportContent from '../ActivityLogs/ReportContent';
import { getStatusLogo } from '../../helpers/utils';
import StatusModalWrapper from '../ActivityLogs/StatusModalWrapper';
import { ordinalSuffix } from '../../helpers/formattingHelpers';
import EnrollmentStuckQueue from '../common/EnrollmentStuckQueue';
import GenericProgressModal from '../common/GenericProgressModal';

const useStyles = makeStyles(() => ({
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
  circularProgressPadding: {
    padding: '1rem',
  },
  logoIcon: {
    '& > :nth-child(1)': {
      margin: '1rem 0 0 0',
    },
  },
  headingWrapper: {
    paddingTop: '0 !important',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
    padding: '0.25rem 0',
  },
  headingBoldContent: {
    color: MEDIUM_GREY,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  contentBox: {
    marginBottom: '0',
  },
}));

const UnEnrollStatusModal = ({
  open, onClose, modalState, transactionId, getReport, progressData, progressErrorMsg,
  onDownloadLog, onCancelTransaction, cancellationRequested, setCancellationRequested,
}) => {
  const classes = useStyles();

  const getModalTitle = (reportStatus, fileName) => {
    let header;
    let subTitle;
    if (reportStatus === CANCELLED) {
      header = 'User unenrollment file upload was cancelled';
      subTitle = `"${fileName}" could not be uploaded as it was cancelled`;
    } else if (reportStatus === COMPLETED) {
      header = 'User unenrollment file was uploaded successfully!';
      subTitle = `"${fileName}" was uploaded successfully`;
    } else if (reportStatus === COMPLETED_WITH_ERRORS) {
      header = 'User unenrollment file upload was completed with errors';
      subTitle = `"${fileName}" was uploaded with errors`;
    } else {
      header = 'User unenrollment file upload failed!';
      subTitle = `"${fileName}" could not be uploaded`;
    }
    return {
      header,
      subTitle,
    };
  };

  const applyReportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const {
      status, file_name = '', filename = '', fail_count, success_count, total_row: total_rows, created_at,
    } = result;

    let modifiedStatus = status;
    if (status === CANCELLED && success_count) {
      modifiedStatus = COMPLETED_WITH_ERRORS;
    }

    const { header, subTitle } = getModalTitle(modifiedStatus,
      file_name || filename);

    return {
      component: ReportContent,
      wrapperProps: {
        logo: (
          <img
            src={getStatusLogo(modifiedStatus)}
            alt="status-icon"
            width={50}
            height={50}
            className={classes.circularProgressPadding}
          />
        ),
        heading: (
          <DialogTitle className={classes.headingWrapper}>
            <FormattedTypography
              variant="subtitle1"
              body={header}
              subHeading={(
                <>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={`${classes.headingBoldContent} ${classes.nestedText}`}
                  >
                    {subTitle}
                  </Typography>
                  {modifiedStatus === FAILED ? (
                    <Typography
                      variant="subtitle2"
                      className={`${classes.headingBoldContent} ${classes.infoHelperText}`}
                      align="center"
                    >
                      Please try again later.
                    </Typography>
                  ) : null}
                </>
              )}
            />
          </DialogTitle>
        ),
        primaryBtnText: 'Download Log',
        breakpoint: 'sm',
        primaryBtnProps: {
          onClick: () => {
            onDownloadLog({
              transaction_id: transactionId,
              created_at,
            });
          },
        },
        logoWrapper: classes.logoIcon,
        contentWrapper: classes.contentBox,
        headingDivider: true,
        contentDivider: true,
        disableGutters: true,
      },
      props: {
        success_count,
        fail_count,
        total_rows,
        status: modifiedStatus,
        updatedValue: 'users',
        updateType: 'unenrolled',
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReport, onClose, onDownloadLog, transactionId]);

  const applyProgressModal = useCallback(() => ({
    component: GenericProgressModal,
    wrapperProps: {
      heading: (
        <DialogTitle>
          <FormattedTypography body="User Unenrollment" />
        </DialogTitle>
      ),
      logo: (
        <div className={classes.progressRow}>
          <LoaderInner
            progress={progressData?.percentage ? progressData?.percentage : 0}
            scaleFactor={1.5}
          />
        </div>
      ),
      breakpoint: 'md',
    },
    props: {
      initiatedBy: progressData?.created_by,
      initiatedAt: progressData?.created_at,
      onCancelTransaction,
      cancellationRequested,
      setCancellationRequested,
    },
    // eslint-disable-next-line max-len, react-hooks/exhaustive-deps
  }), [cancellationRequested, classes?.progressRow, onCancelTransaction, progressData?.created_at, progressData?.created_by, progressData?.percentage, setCancellationRequested]);

  const applyQueuedModal = useCallback(() => ({
    component: EnrollmentStuckQueue,
    wrapperProps: {
      logo: <></>,
      breakpoint: 'md',
      heading: null,
    },
    props: {
      queue_position: ordinalSuffix(progressData?.queue_position),
      onCancelTransaction,
      cancellationRequested,
      setCancellationRequested,
    },
    // eslint-disable-next-line max-len, react-hooks/exhaustive-deps
  }), [cancellationRequested, onCancelTransaction, progressData?.queue_position, setCancellationRequested]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={applyProgressModal}
      reportModal={applyReportModal}
      applyQueuedModal={applyQueuedModal}
      feature="user unenrollment"
    />
  );
};

UnEnrollStatusModal.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

UnEnrollStatusModal.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
  onCancelTransaction: PropTypes.func.isRequired,
  cancellationRequested: PropTypes.bool.isRequired,
  setCancellationRequested: PropTypes.func.isRequired,
};

export default UnEnrollStatusModal;
