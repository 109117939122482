import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  CHANNELS, COURSES, LEARNING_PLAN, CATALOGUES,
} from '../../../constants';
import { DARK_MEDIUM_GREY, MODERATE_LIGHT_GREY } from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.125rem',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    padding: '1.5rem 0rem',
  },
  indexWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.02,
    fontWeight: 'bold',
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.98,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.31,
    fontWeight: 'bold',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
  },
  subtitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    justifyContent: 'flex-end',
  },
  rowsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.69,
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
  },
  rowRhsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    justifyContent: 'flex-end',
    fontWeight: 'bold',
  },
  lightSpan: {
    fontSize: '0.875rem',
    color: DARK_MEDIUM_GREY,
    paddingLeft: '0.5rem',
  },
});

const ComponentScreenRow = ({
  title, enrolled, waitlisted,
}) => {
  const classes = useStyles();

  let rhs = null;

  const enrolledOk = enrolled || enrolled === 0;

  if (enrolledOk && waitlisted) {
    rhs = (
      <span>
        <span>{enrolled}</span>
        <span className={classes.lightSpan}>Enrolled</span>
        <span style={{ padding: '0.5rem' }}>|</span>
        <span>{waitlisted}</span>
        <span className={classes.lightSpan}>Waitlisted</span>
      </span>
    );
  } else if (enrolledOk && !waitlisted) {
    rhs = (
      <span>{enrolled}</span>
    );
  }

  return (
    <div className={classes.rowWrapper}>
      <div className={classes.rowTitleWrapper}>{title}</div>

      <div className={classes.rowRhsWrapper}>{rhs}</div>
    </div>
  );
};

ComponentScreenRow.propTypes = {
  title: PropTypes.string.isRequired,
  enrolled: PropTypes.number,
  waitlisted: PropTypes.number,
};

ComponentScreenRow.defaultProps = {
  enrolled: null,
  waitlisted: null,
};

const ComponentScreenSection = ({
  index, type, rows, configId,
}) => {
  const classes = useStyles();

  // Hide empty sections
  if (!rows.length) return null;

  const title = {
    [LEARNING_PLAN]: 'Learning Plan',
    [COURSES]: 'Courses',
    [CHANNELS]: 'Channels',
    [CATALOGUES]: 'Catalogues',
  }[type];

  const subtitle = {
    [LEARNING_PLAN]: '',
    [COURSES]: '',
    [CHANNELS]: `Visible to ${configId}`,
    [CATALOGUES]: `Visible to ${configId}`,
  }[type];

  const paddedIndex = `${index + 1}`.padStart(2, '0');

  const titleFormatter = (row) => {
    if (type === COURSES) return `${row.title} ${row.connected ? '' : '(External)'}`;
    if (type === LEARNING_PLAN) return `Learning plan with code ${configId} updated with LP ID ${row.id}`;
    return row.title;
  };

  const rowsDom = rows.map((row) => (
    <ComponentScreenRow
      key={row.title}
      title={titleFormatter(row)}
      enrolled={row.enrolled}
      waitlisted={row.waitlisted}
    />
  ));

  return (
    <div className={classes.wrapper}>
      <div className={classes.indexWrapper}>{paddedIndex}</div>

      <div className={classes.mainWrapper}>
        <div className={classes.titleRow}>
          <div className={classes.titleWrapper}>{title}</div>
          <div className={classes.subtitleWrapper}>{subtitle}</div>
        </div>

        <div className={classes.rowsWrapper}>
          {rowsDom}
        </div>
      </div>
    </div>
  );
};

ComponentScreenSection.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    connected: PropTypes.bool,
    enrolled: PropTypes.number,
    waitlisted: PropTypes.number,
  })).isRequired,
  configId: PropTypes.string.isRequired,
};

export default ComponentScreenSection;
