import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Async from 'react-select/async';
import { Form } from '@unform/web';
import Select from '../common/Select';
import theme from '../../stylesheets/theme';
import {
  getOptionsAsync,
} from '../../containers/MetaDataManagement/apis';
import {
  LIGHT_MEDIUM_GREY, ACCORDION_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  textColor: {
    color: ACCORDION_GREY,
  },
  labelWrapper: {
    display: 'flex',
    flex: 0.25,
    fontWeight: 'bold',
    alignItems: 'flex-end',
  },
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    marginBottom: '1rem',
    maxHeight: '2.5rem',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
});

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: 'auto',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
};

const options = [{ value: 'Observer', label: 'Observer' }, { value: 'Participant', label: 'Participant' }];

const MetaDataEnrollment = ({ onUpdateEnrollmentInfo, doceboLpId }) => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState('');
  const [selectedUserRole, setSelectedUserRole] = useState({});
  const formRef = useRef(null);

  const isFormValid = selectedUser && Object.keys(selectedUser).length > 0;

  const debouncedChangeHandler = useMemo(
    () => _debounce((value) => setQuery(value), 1000),
    [],
  );

  const loadOptions = async (inputText, callback) => {
    const result = await getOptionsAsync(doceboLpId, inputText);
    if (!result.data.success) callback([]);
    const programs = result?.data?.data?.metadata;
    const newPrograms = programs ? programs.map(
      (item) => ({ slug: item.slug, label: item.secondary_id, value: item.value }),
    ) : [];
    callback(newPrograms);
  };

  const onConfirm = () => {
    onUpdateEnrollmentInfo(selectedUser && selectedUser.slug, selectedUserRole);
  };

  const debouncedFetch = _debounce(loadOptions, 1000);

  const getOptions = (input, callback) => debouncedFetch(input, callback);

  useEffect(() => {
    formRef.current.setFieldValue('userRole', {
      value: selectedUser ? selectedUser.value : '',
      label: selectedUser ? selectedUser.value : '',
    });
  }, [selectedUser]);

  return (
    <Box sx={{ padding: '2rem 0' }}>
      <Typography variant="body1" component="span" className={classes.textColor}>Metadata for Enrollment fields</Typography>
      <Box sx={{ mt: 2, width: '25%' }}>
        <Form ref={formRef}>
          <Box>
            <Typography variant="body1" component="span">
              Search user by username
            </Typography>
            <div className={classes.selectWrapper}>
              <Async
                styles={selectStyles}
                placeholder="Search User..."
                onInputChange={debouncedChangeHandler}
                loadOptions={getOptions}
                name="selectUser"
                onChange={(value) => setSelectedUser(value)}
                maxMenuHeight="300"
                getOptionValue={(option) => option.label}
                isClearable
                isSearchable
                menuPosition="fixed"
              />
            </div>
          </Box>
          <Select
            name="userRole"
            options={options}
            placeholder="Select an option"
            label="Set role for selected user"
            disabled
            onChange={(e) => setSelectedUserRole(e.label)}
          />
        </Form>
      </Box>
      <Button
        variant="contained"
        style={{ marginTop: '3rem' }}
        color="primary"
        onClick={onConfirm}
        disabled={!isFormValid}
      >
        Save Changes
      </Button>
    </Box>
  );
};

MetaDataEnrollment.propTypes = {
  onUpdateEnrollmentInfo: PropTypes.func.isRequired,
  doceboLpId: PropTypes.string.isRequired,
};

export default MetaDataEnrollment;
