import React, { useEffect, useState, createContext } from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import PropTypes from 'prop-types';

import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { CancelToken, isCancel } from 'axios';

import { actions as midActions } from '@cs/mid-react-saga';

// import { LOGOUT } from '@nvt/mid-react/dist/store/constants';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Header from '../../components/Header';
import EnvironmentBanner from '../../components/EnvironmentBanner';
import LandingPage from '../../components/LandingPage';
import PlatformUsersLandingPage from '../PlatformLevelUsers/LandingPage';
import ComponentMetadata from '../ComponentMetadata';
import Masterdata from '../MasterData';
// eslint-disable-next-line import/no-cycle
import ActivityLogs from '../ActivityLogs';
import {
  getEnvironmentFromLocalStorage,
  getUserFromLocalStorage,
  getUserFromMid,
  setInLocalStorage,
} from '../../helpers/userHelper';
import { getNvtUserInfo } from '../../helpers/nvtUserInfoHelper';
import { getAllPrograms, getEnvironment, getLtiToken } from './apis';
import {
  COURSE_OPS,
  COURSE_PROD,
  ENVIRONMENT_KEY,
  GROUPWORK_GROUPS, LTI_TOKEN,
  PROGRAM_CREATION,
  ROLE_ADMIN,
  ROLE_OPS,
  ROLE_PROD,
  ROUTE_HOME,
  ROUTE_UPLOAD,
  USER_INFO_KEY,
  ACTIVE_LOGS_MENU,
} from '../../constants';
import ContentLibrary from '../ContentLibrary';
import GlobalActivityNav from '../../components/common/GlobalActivityNav';

let cancelTokenSource = null;

const getProgramsHelper = async (
  searchText,
  stages,
  programType,
  programs,
  setPrograms,
  hasMore,
  setHasMore,
  currentPage,
  setCurrentPage,
  setSnackBarOpen,
  setIsLoading,
  setTotalPrograms,
) => {
  if (!stages.length) return; // Loading
  if (!hasMore) return;

  // Cancel old request
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }

  try {
    cancelTokenSource = CancelToken.source();
    setIsLoading(true);
    const nextPage = currentPage + 1;
    const result = await getAllPrograms(
      programType,
      searchText,
      nextPage,
      stages,
      cancelTokenSource.token,
    );
    if (!result.data.docebo_success) throw new Error();
    const newPrograms = result.data.items;
    const totalPrograms = result.data.totalRows;
    batchUpdates(() => {
      setPrograms(programs.concat(newPrograms));
      setHasMore(result.data.hasMore);
      setCurrentPage(nextPage);
      setTotalPrograms(totalPrograms);
      setIsLoading(false);
    });
  } catch (e) {
    if (!isCancel(e)) {
      batchUpdates(() => {
        setSnackBarOpen(true);
        setHasMore(true);
        setIsLoading(false);
      });
    }
  }
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100vh',
  },
  headerWrapper: {
    display: 'flex',
  },
  containerWrapper: {
    display: 'flex',
    flex: 1,
  },
  sidebarWrapper: {
    display: 'flex',
    flex: 1,
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 5,
  },
  contentWrapper: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerWrapper: {
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
});

export const MetaDataContext = createContext();

const BasePage = (props) => {
  const { match, logout, mid } = props;
  const programType = get(match, 'params.programType');
  const platformType = get(match, 'params.platformType');
  const programSubType = 'unused';
  const user = getUserFromMid(mid);
  const environment = getEnvironmentFromLocalStorage();

  const classes = useStyles();
  const history = useHistory();

  const [programs, setPrograms] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [stages, setStages] = useState([]);
  const [currentPage, setCurrentPage] = useState(-1);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [totalPrograms, setTotalPrograms] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(ACTIVE_LOGS_MENU[0]);
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);

  const toggleDrawer = (value) => {
    setOpen(value);
  };

  const onMenuItemClick = (item) => {
    setSelectedMenu(item);
  };

  useEffect(() => {
    setCurrentPage(-1);
    setPrograms([]);
    setHasMore(false);
    setIsLoading(true);
    const populateSidebar = async () => {
      await getProgramsHelper(
        searchText,
        stages,
        programType,
        [],
        setPrograms,
        true,
        setHasMore,
        -1,
        setCurrentPage,
        setSnackBarOpen,
        setIsLoading,
        setTotalPrograms,
      );
    };
    populateSidebar();
  }, [searchText, stages, programType]);

  const onLoadMore = async () => {
    await getProgramsHelper(
      searchText,
      stages,
      programType,
      programs,
      setPrograms,
      hasMore,
      setHasMore,
      currentPage,
      setCurrentPage,
      setSnackBarOpen,
      setIsLoading,
      setTotalPrograms,
    );
  };

  const onNavigate = (newProgramType) => {
    history.push(`/${ROUTE_HOME}/${newProgramType}`);
  };

  const defaultProgramSubTypeLookup = {
    [COURSE_PROD]: PROGRAM_CREATION,
    [COURSE_OPS]: GROUPWORK_GROUPS,
  };

  const onUserDropdownChange = (option) => {
    if (option.value === 'LOGOUT') logout();
  };

  const setLtiToken = async () => {
    const ltiToken = await getLtiToken();
    setInLocalStorage(LTI_TOKEN, ltiToken);
  };

  useEffect(() => {
    const getUserAndEnv = async () => {
      const [env, userData] = await Promise.all(
        [getEnvironment(), getNvtUserInfo()],
      );
      setInLocalStorage(ENVIRONMENT_KEY, env);
      setInLocalStorage(USER_INFO_KEY, userData);
      const teams = get(userData, 'teams', []);
      if (teams.includes(ROLE_ADMIN)) {
        setLtiToken();
        return;
      }
      if (teams.includes(ROLE_OPS)) {
        onNavigate(COURSE_OPS);
      } else if (teams.includes(ROLE_PROD)) {
        setLtiToken();
        onNavigate(COURSE_PROD);
      }
    };
    const userInfo = getUserFromLocalStorage(USER_INFO_KEY);
    if (!userInfo?.email) {
      getUserAndEnv();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateNewProgram = () => {
    history.push(`/${ROUTE_UPLOAD}/${programType}/${defaultProgramSubTypeLookup[programType]}`);
  };

  const userInfoFromLocalStorage = getUserFromLocalStorage(USER_INFO_KEY);
  const isAdmin = get(userInfoFromLocalStorage, 'teams', []).includes(ROLE_ADMIN);
  const isCpRole = get(userInfoFromLocalStorage, 'teams', []).includes(ROLE_PROD);
  const getActiveComponent = () => {
    switch (platformType) {
      case 'componentMetadata': return (<ComponentMetadata match={match} />);
      case 'contentLibrary': return (
        <ContentLibrary match={match} />
      );
      case 'master_data': return (
        <Masterdata match={match} isAdmin={isAdmin} />
      );
      case 'users': return (
        <PlatformUsersLandingPage match={match} isAdmin={isAdmin} />
      );
      default: return (
        <LandingPage
          cards={programs}
          programType={programType}
          onSearch={setSearchText}
          onStageChange={setStages}
          showCreateNewProgram={isAdmin || programType === COURSE_PROD}
          onCreateNewProgram={onCreateNewProgram}
          onLoadMore={onLoadMore}
          hasMore={hasMore}
          isLoading={isLoading}
          programCount={totalPrograms}
        />
      );
    }
  };

  return (
    <MetaDataContext.Provider value={{ isActivityLogModalOpen, setIsActivityLogModalOpen }}>
      <div className={classes.wrapper}>
        <div className={classes.containerWrapper}>
          <div className={classes.contentWrapper}>
            <EnvironmentBanner environment={environment.environment} />
            <Header
              user={user}
              programType={programType}
              programSubType={programSubType}
              onNavigate={onNavigate}
              onUserDropdownChange={onUserDropdownChange}
              isAdmin={isAdmin}
              isCpRole={isCpRole}
              platformType={platformType}
            />
            {getActiveComponent()}
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={isSnackBarOpen}
              autoHideDuration={6000}
              onClose={() => setSnackBarOpen(false)}
            >
              <Alert
                className={classes.alertSnackBar}
                onClose={() => setSnackBarOpen(false)}
                severity="error"
              >
                Unable to connect to Docebo. Please try later.
              </Alert>
            </Snackbar>
          </div>
          {['componentMetadata', 'users'].includes(platformType) ? (
            <>
              <Paper className={classes.logWrapper} component="div" elevation={3}>
                <GlobalActivityNav
                  expanded={open}
                  toggleDrawer={toggleDrawer}
                  selectedMenu={selectedMenu}
                  onMenuItemClick={onMenuItemClick}
                  disabled
                />
              </Paper>
              <Drawer
                anchor="right"
                open={open}
                onClose={() => toggleDrawer(false)}
                className={classes.drawerWrapper}
              >
                <Paper className={classes.drawerIcons} elevation={3}>
                  <GlobalActivityNav
                    expanded={open}
                    toggleDrawer={toggleDrawer}
                    selectedMenu={selectedMenu}
                    onMenuItemClick={onMenuItemClick}
                    disabled={false}
                    platformType={platformType}
                  />
                </Paper>
                <Box className={classes.drawerContainer}>
                  <ActivityLogs
                    selectedMenu={selectedMenu}
                    programSubType={platformType}
                  />
                </Box>
              </Drawer>
            </>
          )
            : null}
        </div>
      </div>
    </MetaDataContext.Provider>
  );
};

BasePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
    }),
  }).isRequired,
  mid: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mid: state.mid,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(midActions.logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasePage));
