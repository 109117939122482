import React, { useEffect, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import LabledTextField from '../common/LabledTextField';
import Loading from '../common/Loading';
import {
  ERROR_RED,
  MODERATE_LIGHT_GREY,
  SWITCH_GREY,
  ACCORDION_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '0 48px 20px',
    width: '87%',
    margin: 'auto',
  },
  width100: {
    width: '85%',
    margin: '0 auto',
  },
  modalHeight: {
    minHeight: '400px',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  red: {
    color: ERROR_RED,
  },
  rootLabelText: {
    '& > div': {
      borderRadius: 'unset',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    width: '80%',
    margin: '.8rem 0',
    '& > div': {
      borderRadius: 'unset',
    },
    '& input': {
      paddingRight: '4.5rem',
      '&:disabled': {
        backgroundColor: MODERATE_LIGHT_GREY,
        opacity: 0.5,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  save: {
    '&:disabled': {
      color: ACCORDION_GREY,
      boxShadow: 'none',
      backgroundColor: SWITCH_GREY,
    },
  },
  clientDomain: {
    margin: '0.8rem 0',
    '& .MuiChip-filled': {
      backgroundColor: '#333333',
      color: '#fff',
      height: 'auto',
      padding: '0.5rem',
      borderRadius: '3px',
    },
    '& .MuiAutocomplete-inputRoot.MuiInputBase-formControl': {
      padding: '0.4rem 0.9rem',
      borderRadius: 'unset',
    },
    '& .MuiChip-label': {
      paddingLeft: '0.8rem',
    },
    '& .MuiChip-filled svg': {
      color: '#fff',
      fontSize: '1.5rem',
      '&:hover': {
        color: '#fff',
        fontSize: '1.5rem',
      },
    },
    '& input.MuiAutocomplete-input': {
      padding: '0.6rem !important',
    },
  },
  AutocompleteLabel: {
    paddingTop: '1rem',
  },
}));

const EditClientModal = ({
  open,
  onClose,
  isLoading,
  onUpdateClientData,
  onAddClient,
  clientData,
  showDomainValidationError,
  isNewClient,
}) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});
  const filter = createFilterOptions();
  const portfolioForm = {
    heading: isNewClient ? 'Add Client Information' : 'Edit Client Information',
    subHeading: isNewClient
      ? 'Add Client information for Learning Programs'
      : 'Edit Client information for Learning Programs',
  };

  const prepareFormValues = useCallback(
    () => ({
      client_id: clientData?.client_id,
      client_name: clientData?.client_name,
      client_code: clientData?.client_code ?? '',
      domains: clientData.domains ? clientData.domains.split(',') : [],
    }),
    [clientData],
  );

  useEffect(() => {
    if (isNewClient) {
      setFormValues({
        client_id: '',
        client_name: '',
        client_code: '',
        domains: [],
      });
    } else {
      setFormValues(prepareFormValues());
    }
  }, [isNewClient, prepareFormValues]);

  const clientSchema = Yup.object({
    client_id: Yup.string()
      .required('This field is Required')
      .max(255, 'Title should not exceed 255 characters')
      .min(18, 'The Client id is invalid. It has to be 18 digits')
      .max(18, 'The Client id is invalid. It has to be 18 digits'),
    client_name: Yup.string().required('This field is Required'),
    client_code: Yup.string(),
    domains: Yup.array(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formValues,
    validationSchema: clientSchema,
    onSubmit: (values) => {
      const finalValues = {
        client_id: values.client_id,
        client_name: values.client_name,
        client_code: values.client_code,
        domains: values.domains.join(', '),
      };
      if (isNewClient) {
        onAddClient(finalValues);
      } else {
        onUpdateClientData({ ...finalValues, id: clientData.id });
      }
    },
  });

  const {
    values, handleSubmit, handleChange, setValues, resetForm, setFieldValue, errors,
  } = formik;
  useEffect(() => {
    setValues(formValues);
  }, [formValues, setValues]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  const validateDomain = (domain) => /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain);

  const onDomainChange = (e, value, reason) => {
    if (reason === 'createOption') {
      const lastDomain = value.pop();
      if (validateDomain(lastDomain)) {
        value.push(lastDomain);
        setFieldValue('domains', value);
      } else {
        showDomainValidationError();
      }
    } else if (reason === 'selectOption') {
      const lastDomain = value.pop();
      if (validateDomain(lastDomain.inputValue)) {
        value.push(lastDomain.inputValue);
        setFieldValue('domains', value);
      } else {
        showDomainValidationError();
      }
    } else if (reason === 'removeOption') {
      setFieldValue('domains', value);
    }
  };

  return (
    <ConfirmationModal
      open={open}
      isContainForm
      onClose={onClose}
      onSubmit={handleSubmit}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      modalWidth={80}
      title={(
        <FormattedTypography
          variant="subtitle1"
          subheadingVariant="body1"
          body={portfolioForm.heading}
          subHeading={portfolioForm.subHeading}
          suffixClassName={classes.bold}
        />
      )}
      content={
        isLoading ? (
          <Loading />
        ) : (
          <Grid container justifyContent="space-between" className={classes.modalWrapper}>
            <Grid item xs={12}>
              <LabledTextField
                label={(
                  <Box className={classes.label}>
                    <Typography>Client ID</Typography>
                    <span className={classes.red}>*</span>
                  </Box>
                )}
                placeholder="Select/Add a client"
                className={classes.fieldPadding}
                inputProps={{
                  name: 'client_id',
                  classes: {
                    root: classes.rootLabelText,
                  },
                }}
                value={values.client_id || ''}
                onChange={handleChange}
              />
              <Box className={classes.errorBlock}>
                {errors.client_id && (
                  <Typography className={classes.red}>{errors.client_id}</Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <LabledTextField
                label={(
                  <Box className={classes.label}>
                    <Typography>Client Name</Typography>
                    <span className={classes.red}>*</span>
                  </Box>
                )}
                placeholder="Select/Add an Client Name"
                className={classes.fieldPadding}
                inputProps={{
                  name: 'client_name',
                  classes: {
                    root: classes.rootLabelText,
                  },
                }}
                value={values.client_name || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LabledTextField
                label={(
                  <Box className={classes.label}>
                    <Typography>Client Name Abbreviation</Typography>
                  </Box>
                )}
                className={classes.fieldPadding}
                placeholder="Client Name Abbreviation"
                inputProps={{
                  name: 'client_code',
                  classes: { root: classes.rootLabelText },
                }}
                value={values.client_code || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={`${classes.AutocompleteLabel}`}>
                {'Client Domain'}
              </Typography>
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                value={values.domains || []}
                freeSolo
                disableClearable
                className={classes.clientDomain}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.title);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Add "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Client Domain"
                    name="domains"
                  />
                )}
                onChange={(e, value, reason) => {
                  onDomainChange(e, value, reason);
                }}
              />
            </Grid>
          </Grid>
        )
      }
      actions={(
        <>
          <Button
            className={classes.cancelModal}
            color="primary"
            variant="outlined"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.save}
            color="primary"
            disabled={!(values.client_id?.trim() && values.client_name?.trim())}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </>
      )}
    />
  );
};

export default EditClientModal;

EditClientModal.defaultProps = {
  clientData: {},
};

EditClientModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateClientData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showDomainValidationError: PropTypes.func.isRequired,
  clientData: PropTypes.shape({
    client_id: PropTypes.string,
    client_name: PropTypes.string,
    client_code: PropTypes.string,
    domains: PropTypes.string,
    id: PropTypes.number,
  }),
  isNewClient: PropTypes.bool.isRequired,
  onAddClient: PropTypes.func.isRequired,
};
