import React, { memo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FileInput from '../FileInput';
import MainButton from '../MainButton';
import BreadCrumbHOC from '../BreadCrumbHOC';
import {
  ROUTE_REPORT, COURSE_OPS, WAVE_MANAGEMENT, TRANSFER_COHORT_USERS_TEMPLATE,
} from '../../constants';
import {
  MODERATE_DARK_GREY,
  MCKINSEY_BLUE,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    boxShadow: 'none',
  },
  breadCrumbSection: {
    display: 'flex',
    padding: '1.25rem 2rem',
  },
  headingSection: {
    padding: '0rem 2rem 1rem',
  },

  mainWrapper: {
    flexGrow: 1,
  },
  middleWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    padding: '1rem 2rem',
  },
  boldText: {
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
  },
  userEnrollMentButton: {
    fontSize: '1.5rem',
  },
  border: {
    margin: '1.2rem 0',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
  downloadWaveDataLbl: {
    marginTop: '1rem',
  },
  transferInfo: {
    marginBottom: '1rem',
    fontWeight: 'bold',
  },
}));

const UserListWaveTransfer = ({
  programId,
  onUpload,
  isButtonDisabled,
}) => {
  const classes = useStyles();

  const onDownloadSample = async () => {
    window.open(TRANSFER_COHORT_USERS_TEMPLATE, '_blank');
  };

  const breadCrumbList = [
    { label: 'All Enrollment Cohorts', redirectionUrl: `/${ROUTE_REPORT}/${programId}/${COURSE_OPS}/${WAVE_MANAGEMENT}`, isActive: false },
    { label: 'Transfer Users', redirectionUrl: '', isActive: true },
  ];
  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      <Box className={classes.breadCrumbSection}>
        <BreadCrumbHOC
          list={breadCrumbList}
        />
      </Box>
      <Box className={classes.headingSection}>
        <Typography
          variant="h1"
        >
          Transfer users between waves for this Learning plan
        </Typography>
      </Box>
      <Divider variant="fullWidth" className={classes.divider} light />

      <Paper className={classes.wrapper}>
        <Container maxWidth={false} className={classes.middleWrapper}>
          <Typography
            variant="body1"
            className={classes.transferInfo}
          >
            Upload a list of usernames and corresponding waves for this transfer.
            Please make sure the users in this file are already
            existing in a wave within this Learning plan.
          </Typography>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Box className={classes.border}>
            <FileInput
              idx={0}
              onFileInput={onUpload}
              isDisabled={isButtonDisabled}
            >
              <MainButton
                title="Upload XLS file"
                subtitle="Make sure you have correct username and cohort title for this transfer"
                isDisabled={isButtonDisabled}
              />
            </FileInput>
          </Box>
          <Divider variant="fullWidth" className={classes.divider} light />
          <Box className={classes.downloadTempBtn}>
            <Typography variant="body1" className={classes.downloadWaveDataLbl}>Need help configuring the file? Download the template below.</Typography>
            <Button
              variant="outlined"
              style={{ marginTop: '0.75rem', width: 'fit-content' }}
              color="primary"
              onClick={onDownloadSample}
            >
              Download Cohort Transfer Template
            </Button>
          </Box>
        </Container>
      </Paper>
    </Paper>
  );
};

UserListWaveTransfer.propTypes = {
  programId: PropTypes.number.isRequired,
  onUpload: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
};
export default memo(
  UserListWaveTransfer,
  () => {},
);
