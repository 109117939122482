/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { TableCell, TableRow, TableHead } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { MODERATE_LIGHT_GREY, BLACK, LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';
import InfoTooltip from '../common/InfoTooltip';

const useStyles = makeStyles({
  wrapper: {
    '& .MuiTableCell-head': {
      backgroundColor: MODERATE_LIGHT_GREY,
      color: BLACK,
      fontSize: '1.25rem',
      zIndex: 1,
    },
  },
  headTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  checkbox: {
    height: '1.125rem',
    color: LIGHT_MEDIUM_GREY,
    opacity: 0.6,
    margin: '0 2.5rem 0 0.75rem',
    display: 'flex !important',
    justifyContent: 'end !important',
  },
  checkboxChecked: {
    opacity: 1,
  },
});

const EnhancedTableHeader = ({
  headCells, getTooltipJSX, order,
  orderBy, onRequestSort, checkboxEnable, checkboxHandler, defaultCheckBoxValue,
}) => {
  const classes = useStyles();
  const [check, setCheck] = useState(defaultCheckBoxValue);

  useEffect(() => {
    setCheck(defaultCheckBoxValue);
  }, [defaultCheckBoxValue]);

  const handleChange = (e) => {
    setCheck(e.target.checked);
    checkboxHandler(e.target.checked);
  };

  return (
    <TableHead className={classes.wrapper}>
      <TableRow>
        {
          checkboxEnable && (
            <TableCell
              key="selectAll"
              size="medium"
              align="center"
            >
              <Checkbox
                // className={classes.checkbox}
                checked={check}
                onChange={(e) => { handleChange(e); }}
                name="checkAll"
                data-testid="selectAllrow"
                color="primary"
                classes={{
                  checked: classes.checkboxChecked,
                }}
              />
            </TableCell>
          )
        }
        {headCells.map(({
          title, property, sortable, tooltip, width, ...otherProps
        }) => (
          <TableCell
            key={property}
            size="medium"
            onClick={sortable === true ? () => onRequestSort(property) : () => { }}
            style={width && { width }}
            {...otherProps}
          >
            <Box className={classes.headTitle}>
              {title}
              {sortable === true
                ? (orderBy === property
                  ? (
                    <>
                      {order === 'desc'
                        ? <ArrowDownwardIcon style={{ color: '#2251FF' }} />
                        : <ArrowUpwardIcon style={{ color: '#2251FF' }} />}
                    </>
                  )
                  : <ArrowUpwardIcon style={{ color: '#757575' }} />
                ) : null}
              {property === 'user_access' && <InfoTooltip title={getTooltipJSX()} placement="top" />}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHeader.defaultProps = {
  checkboxEnable: false,
  checkboxHandler: () => { },
  defaultCheckBoxValue: false,
  getTooltipJSX: () => { },
};

EnhancedTableHeader.propTypes = {
  headCells: PropTypes.array.isRequired,
  getTooltipJSX: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  checkboxEnable: PropTypes.bool,
  checkboxHandler: PropTypes.func,
  defaultCheckBoxValue: PropTypes.bool,
};

export default EnhancedTableHeader;
