import { useContext } from 'react';
import NotificationContext from '../providers/NotificationProvider';

function useNotifications() {
  const {
    notification,
    notificationText,
    notifySuccess,
    notifyError,
    notifyInfo,
    clear,
  } = useContext(NotificationContext);
  return {
    notification,
    notificationText,
    notifySuccess,
    notifyError,
    notifyInfo,
    clear,
  };
}

export default useNotifications;
