import React, {
  memo, useMemo, useState, useCallback,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';

import ConfirmModal from '../common/ConfirmModal';
import CustomPrompt from '../common/CustomPrompt';

import EditProgramRow from './EditProgramRow';
import EditProgramModal from './EditProgramModal';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '24px',
  },
  headingBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
  },
  actionBtn: {
    width: '16rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    fontWeight: 'bold',
    flex: 0.80,
    display: 'flex',
  },
});

const keyToHeading = {
  learning_plan: {
    heading: 'Config ID',
    accordion: false,
    willChange: true,
    modalHeading: 'Learning Plan codes',
  },
  course: {
    heading: 'All the course codes',
    accordion: true,
    willChange: true,
    modalHeading: 'all the course codes',

  },
  group: {
    heading: 'Cohort groups and rules',
    accordion: false,
    willChange: true,
    modalHeading: 'all the cohorts and rules',

  },
  channel: {
    heading: 'Channel name and visibility',
    accordion: false,
    willChange: true,
    modalHeading: 'channel and visibility',
  },
  enrollment_rule: {
    heading: 'Enrollment Rule',
    accordion: false,
    willChange: true,
    modalHeading: 'enrollemnt rules',
  },
  groupwork: {
    heading: 'Groupwork Groups if they already exist,',
    accordion: false,
    willChange: false,
  },
  catalogue: {
    heading: 'Catalogues',
    accordion: false,
    willChange: false,
  },
  enrolled_user: {
    heading: 'Already enrolled users',
    accordion: false,
    willChange: false,
    oldValueLabel: 'Count',
    newValueLabel: '',
  },
};

const EditConfirmationPage = ({
  className,
  report,
  onProceed,
  onBack,
  shouldProceed,
  showProgress,
  openProgressModal,
  setOpenProgressModal,
  progress,
  enrolledUser,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const reportData = useMemo(() => report.reduce((ini, v) => (keyToHeading[v.component] ? ({
    ...ini,
    [v.component]: {
      ...keyToHeading[v.component],
      data: [..._get(ini[v.component], 'data', []), v],
    },
  }) : ini), {}), [report]);

  const closeModal = useCallback(() => {
    setAlertModalOpen(false);
  }, [setAlertModalOpen]);

  const onConfirm = useCallback(() => {
    onBack();
    closeModal();
    window.location.reload();
  }, [closeModal, onBack]);

  return (
    <Container className={`${classes.wrapper} ${className}`}>
      <div className={classes.headingBar}>
        <Typography className={classes.heading}>Review the changes to proceed</Typography>
        <div className={classes.actionBtn}>
          <Button onClick={() => setAlertModalOpen(true)} color="primary" variant="outlined">
            Go Back
          </Button>
          <Button onClick={() => setOpenProgressModal(true)} color="primary" variant="contained">
            Proceed
          </Button>
        </div>
      </div>
      {Object.keys(reportData).map((key) => (
        <EditProgramRow
          key={key}
          willChange={reportData[key].willChange}
          heading={reportData[key].heading}
          data={reportData[key].data}
          accordion={reportData[key].accordion}
          oldValueLabel={reportData[key].oldValueLabel}
          newNameLabel={reportData[key].newNameLabel}
        />
      ))}
      {/* Adding groupwork static row */}
      <EditProgramRow
        willChange={keyToHeading.groupwork.willChange}
        heading={keyToHeading.groupwork.heading}
        data={[
          { component: 'groupwork', old_name: '0' },
        ]}
        oldValueLabel="Count"
      />
      <EditProgramRow
        willChange={keyToHeading.enrolled_user.willChange}
        heading={keyToHeading.enrolled_user.heading}
        data={[
          {
            component: 'enrolled_user',
            old_name: `${enrolledUser}`,
          },
        ]}
        oldValueLabel="Count"
      />
      <EditProgramModal
        open={openProgressModal}
        showProgress={showProgress}
        progress={progress}
        reportData={reportData}
        onProceed={onProceed}
        setOpenProgressModal={setOpenProgressModal}
      />
      <ConfirmModal
        open={alertModalOpen}
        message="All your changes until now will be lost. Are you sure you want to revert the program to its original state and start from the beginning?"
        onClose={closeModal}
        onConfirm={onConfirm}
      />
      <CustomPrompt
        message="All your changes until now will be lost. Are you sure to leave this page?"
        navigate={(path) => {
          onBack();
          history.replace(path);
        }}
        shouldBlockNavigation={(loc) => loc.pathname !== pathname && !shouldProceed}
      />
    </Container>
  );
};

EditConfirmationPage.propTypes = {
  className: PropTypes.string,
  report: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.string.isRequired,
    old_name: PropTypes.string.isRequired,
    new_name: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
  })).isRequired,
  onProceed: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  setOpenProgressModal: PropTypes.func.isRequired,
  shouldProceed: PropTypes.bool.isRequired,
  showProgress: PropTypes.bool.isRequired,
  openProgressModal: PropTypes.bool.isRequired,
  progress: PropTypes.shape({
    percentage: PropTypes.number.isRequired,
  }).isRequired,
  enrolledUser: PropTypes.number.isRequired,
};

EditConfirmationPage.defaultProps = {
  className: '',
};

export default memo(EditConfirmationPage, (prevProps, nextProps) => (
  prevProps.className === nextProps.className && prevProps.progress === nextProps.progress
  && prevProps.onProceed === nextProps.onProceed && prevProps.report === nextProps.report
  && prevProps.onBack === nextProps.onBack && prevProps.showProgress === nextProps.showProgress
  && prevProps.shouldProceed === nextProps.shouldProceed
  && prevProps.openProgressModal === nextProps.openProgressModal
  && prevProps.setOpenProgressModal === nextProps.setOpenProgressModal
));
