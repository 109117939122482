import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ComponentScreenSection from './ComponentScreenSection';
import { DARK_MEDIUM_GREY } from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // fontFamily: 'McKinseySans',
  },
  tableLegend: {
    display: 'flex',
    fontSize: '1.125rem',
    color: DARK_MEDIUM_GREY,
    justifyContent: 'flex-end',
    marginTop: '1.4rem',
  },
});

const ComponentScreen = ({ data, configId }) => {
  const classes = useStyles();

  const sectionsDom = data?.map((section, index) => (
    <ComponentScreenSection
      key={section.type}
      configId={configId}
      type={section.type}
      rows={section.rows}
      index={index}
    />
  ));

  return (
    <div className={classes.wrapper}>
      <div className={classes.tableLegend}>
        Enrollment / Visibility
      </div>
      {sectionsDom}
    </div>
  );
};

ComponentScreen.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      enrolled: PropTypes.number,
      waitlisted: PropTypes.number,
    })).isRequired,
  })).isRequired,
  configId: PropTypes.string.isRequired,
};

export default ComponentScreen;
