import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { DefaultLogo, LOGO_LOOKUP, ALT_LOOKUP } from '../../constants';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
  },
  titleLogoWrapper: {
    flex: 9,
    display: 'flex',
  },
  title: {
    fontSize: '36px',
    fontFamily: 'Bower',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '60px',
    letterSpacing: 0,
  },
  buttonWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    opacity: ({ buttonDisabled }) => (buttonDisabled ? '50%' : '100%'),
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '4rem',
    marginLeft: '2.125rem',
    height: '4rem',
  },
}));

const TitleBlock = ({
  title, courseType, onAssign, buttonDisabled,
}) => {
  const classes = useStyles({ buttonDisabled });

  const logoUri = LOGO_LOOKUP[courseType] || DefaultLogo;
  const alt = ALT_LOOKUP[courseType] || 'Unknown';

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleLogoWrapper}>
        <div className={classes.title}>{title}</div>
        <div className={classes.logoWrapper}>
          <img title={alt} src={logoUri} alt={alt} className={classes.logo} />
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={buttonDisabled}
          onClick={onAssign}
        >
          Assign Selected Content
        </Button>
      </div>
    </div>
  );
};

TitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  courseType: PropTypes.string.isRequired,
  onAssign: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
};

export default TitleBlock;
