import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DARK_GREEN1, MCKINSEY_BLUE, RED_DARK, ACCORDION_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  btnGeneral: {
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontSize: '1.25rem',
    '&:disabled': {
      color: ACCORDION_GREY,
      PointerEvent: 'none',
      cursor: 'default',
    },
  },
  btnGeneral2: {
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontSize: '1rem',
  },
  btnGeneralGreen: {
    color: DARK_GREEN1,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontSize: '1.25rem',
  },
  btnGeneralError: {
    color: RED_DARK,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontSize: '1.25rem',
  },
  btnLarge: {
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontSize: '1.5rem',
  },
  btnLargGreen: {
    color: DARK_GREEN1,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontSize: '1.5rem',
  },
}));

const LinkButton = ({
  text, onClick, variant, disabledSynchBtn, className,
}) => {
  const classes = useStyles();
  const variantClass = classes[variant] || 'btnGeneral';
  return (
    <button disabled={disabledSynchBtn} onClick={onClick} className={`${variantClass} ${className}`} type="button">
      {text}
    </button>
  );
};

LinkButton.defaultProps = {
  variant: 'btnGeneral',
  disabledSynchBtn: false,
  className: '',
};

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  disabledSynchBtn: PropTypes.bool,
  className: PropTypes.string,
};

export default LinkButton;
