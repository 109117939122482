import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@mui/material/Divider';
import Container from '@material-ui/core/Container';
import {
  ACCORDION_GREY, MEDIUM_GREY, DARK_GREEN, BLACK, LIGHT_YELLOW, DARK_YELLOW,
} from '../../../stylesheets/colors';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';

const useStyles = makeStyles(() => ({
  circularProgressPadding: {
    padding: '1rem',
  },
  logoIcon: {
    '& > :nth-child(1)': {
      margin: '1rem 0 0 0',
    },
  },
  headingWrapper: {
    paddingTop: '0',
  },
  contentListWrapper: {
    width: '100%',
    padding: '0.5rem 0',
  },
  contentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
    padding: '0.25rem 0',
  },
  infoContentText: {
    color: BLACK,
  },
  headingBoldContent: {
    color: MEDIUM_GREY,
  },
  successContentText: {
    color: DARK_GREEN,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  subHeading: {
    fontWeight: 800,
    paddingBottom: '0.5rem',
  },
  contentBox: {
    marginBottom: '0',
  },
  loCountWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 0',
  },
  alertBox: {
    backgroundColor: LIGHT_YELLOW,
    width: '65%',
    margin: '1rem auto',
    padding: '1rem 2rem',
  },
  alertText: {
    color: DARK_YELLOW,
    paddingLeft: '1rem',
  },
  errorBox: {
    marginBottom: '4rem',
  },
}));

const ImportComponentOverview = ({ report }) => {
  const classes = useStyles();

  const {
    original_lp_code, target_lp_code, original_component_title,
    original_component_code, new_component_title, new_component_code, total, succeed, failed,
  } = report;

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={(
        <ListSubheader component="div" id="nested-list-subheader" style={{ fontSize: '1.25rem', paddingTop: '1rem' }}>
          Import Summary
        </ListSubheader>
      )}
    >
      <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
        <Box className={classes.contentListWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.subHeading}
          >
            Learning Plan
          </Typography>
          <Box>
            <Typography variant="subtitle2" className={classes.infoHelperText}>
              Original Learning Plan:
              <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                {original_lp_code}
              </Typography>
            </Typography>
          </Box>
          <Box className={classes.contentList}>
            <Typography variant="subtitle2" className={classes.infoHelperText}>
              Learning Plan Imported to:
              <Typography variant="subtitle2" component="span" className={`${classes.successContentText} ${classes.nestedText}`}>
                {target_lp_code}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </ListItem>
      <Divider component="li" variant="middle" />
      <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
        <Box className={classes.contentListWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.subHeading}
          >
            Component
          </Typography>

          <Box>
            <Box>
              <Typography variant="subtitle2" className={classes.infoHelperText}>
                Original Component Title:
                <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                  {original_component_title}
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.contentList}>
              <Typography variant="subtitle2" className={classes.infoHelperText}>
                Original Component Code:
                <Typography variant="subtitle2" component="span" className={`${classes.successContentText} ${classes.nestedText}`}>
                  {original_component_code}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Divider component="li" sx={{ borderStyle: 'dashed', margin: '2rem 0' }} />
          <Box>
            <Box>
              <Typography variant="subtitle2" className={classes.infoHelperText}>
                New Component Title:
                <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                  {new_component_title}
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.contentList}>
              <Typography variant="subtitle2" className={classes.infoHelperText}>
                New Component Code:
                <Typography variant="subtitle2" component="span" className={`${classes.successContentText} ${classes.nestedText}`}>
                  {new_component_code}
                </Typography>
              </Typography>
            </Box>
          </Box>

        </Box>
      </ListItem>
      <Divider component="li" variant="middle" />
      <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
        <Box className={classes.contentListWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.subHeading}
          >
            Learning Objects
          </Typography>
          <Container>
            <Box className={classes.loCountWrapper}>
              <img
                src={CheckRoundIcon}
                alt="check-round-green"
              />
              <Typography
                variant="subtitle2"
                component="span"
                className={`${classes.infoContentText} ${classes.nestedText}`}
              >
                {`${succeed}/${total} successfully imported`}
              </Typography>
            </Box>
            {failed !== 0 && (
              <>
                <Divider component="li" />
                <Box className={classes.loCountWrapper}>
                  <img
                    src={ErrorRoundIcon}
                    alt="Error-round-red"
                  />
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={`${classes.infoContentText} ${classes.nestedText}`}
                  >
                    {`${failed}/${total} not imported successfully`}
                  </Typography>
                </Box>
              </>
            )}
          </Container>
        </Box>
      </ListItem>
    </List>
  );
};

ImportComponentOverview.propTypes = {
  report: PropTypes.shape({
    original_lp_code: PropTypes.string.isRequired,
    target_lp_code: PropTypes.string.isRequired,
    original_component_title: PropTypes.string.isRequired,
    original_component_code: PropTypes.string.isRequired,
    new_component_title: PropTypes.string.isRequired,
    new_component_code: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    succeed: PropTypes.string.isRequired,
    failed: PropTypes.string.isRequired,
  }).isRequired,
};

export default ImportComponentOverview;
