import {
  BLACK, DARK_MEDIUM_GREY, MEDIUM_GREY, WHITE,
} from '../../stylesheets/colors';

const userEnrollmentStyles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    background: WHITE,
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
  leftSpacing: {
    marginLeft: '0.5rem',
  },
  boldText: {
    color: BLACK,
  },
  cohortDialogContainer: {
    marginBottom: '2rem',
    padding: '0 15rem',
    textAlign: 'center',
    color: MEDIUM_GREY,
  },
  preflightDialogContainer: {
    marginBottom: '2rem',
    padding: '0 1rem',
  },
  logoWrapper: {
    '& > :nth-child(1)': {
      margin: '2rem 0 1.5rem 0',
    },
  },
  preflightHeading: {
    flexFlow: 'wrap',
    paddingTop: '2rem',
  },
  preflightprefixHeading: {
    flexBasis: '100%',
    textAlign: 'center',
    fontSize: '1.75rem',
    fontWeight: 'bold',
  },
  subHeadingSection: {
    paddingBottom: '2rem',
  },
  preflightSuffix: {
    fontWeight: 'bold',
    color: DARK_MEDIUM_GREY,
  },
  preflightbody: {
    fontWeight: 'normal',
    color: DARK_MEDIUM_GREY,
    marginRight: '0.5rem',
  },
};

export default userEnrollmentStyles;
