import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@mui/material/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TimelineStep from '../../common/TimelineStep';
import { zeroAppender } from '../../../helpers/formattingHelpers';
import { SUCCESS_GREEN, ERROR_RED, MEDIUM_GREY } from '../../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  messageWrapper: {
    padding: '1rem 0',
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  success: {
    color: SUCCESS_GREEN,
  },
  error: {
    color: ERROR_RED,
  },
  collapsedContent: {
    marginTop: '1rem',
  },
}));

const ComponentLogs = ({
  components,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (id) => {
    setExpanded(id !== undefined ? id : false);
  };

  const classes = useStyles();

  return (
    <>
      {components.length > 0 && components.map((data, index) => (
        <TimelineStep
          key={data?.transaction_id}
          data={{
            ...data,
            completed: data.failed === 0,
          }}
          hideView
          isCustomMsg
        >
          <Box className={classes.messageWrapper}>

            {data?.success > 0
              && (
                <Typography variant="body1" gutterBottom className={classes.success}>
                  {`Components updated: < ${zeroAppender(data.success)} Components Updated Successfully>`}
                </Typography>
              )}

            {
              data?.failed > 0
              && (
                <Box className={classes.errorWrapper}>
                  <Box className={classes.errorBox}>
                    <Typography variant="body1" className={classes.error}>
                      {`Components not updated: < ${zeroAppender(data.failed)} of Components not updated>`}
                    </Typography>
                    {expanded === `collapse_${index}`
                      ? (
                        <Button disableElevation variant="text" onClick={() => handleExpandClick(undefined)} startIcon={<ExpandLess />}>
                          Hide
                        </Button>
                      )
                      : (
                        <Button disableElevation variant="text" onClick={() => handleExpandClick(`collapse_${index}`)} endIcon={<ExpandMoreIcon />}>
                          Show
                        </Button>
                      )}
                  </Box>
                  <Collapse in={expanded === `collapse_${index}`} timeout="auto" unmountOnExit>
                    <Box className={classes.collapsedContent}>
                      <Typography variant="body1">Components</Typography>
                      <List disablePadding>
                        {data?.courses_name && data?.courses_name.length > 0
                          ? data.courses_name.map((course) => (
                            <ListItem key={course} disablePadding>
                              <ListItemIcon sx={{ minWidth: '2rem' }}>
                                <ArrowForwardIcon />
                              </ListItemIcon>
                              <ListItemText
                                sx={{
                                  text: MEDIUM_GREY,
                                }}
                                primary={course}
                              />
                            </ListItem>
                          ))
                          : (
                            <ListItem disableGutters>
                              <ListItemIcon sx={{ minWidth: '2rem' }}>
                                <ArrowForwardIcon />
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={
                                  {
                                    color: MEDIUM_GREY,
                                  }
                                }
                                primary="Unable to find components"
                              />
                            </ListItem>
                          )}
                      </List>
                    </Box>

                  </Collapse>
                </Box>
              )
            }
          </Box>
        </TimelineStep>
      ))}
    </>
  );
};

export default ComponentLogs;

ComponentLogs.defaultProps = {
  components: [],
};

ComponentLogs.propTypes = {
  components: PropTypes.array,
};
