import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import Paper from '@mui/material/Paper';
import { CancelToken, isCancel } from 'axios';
import _debounce from 'lodash/debounce';
import Typography from '@material-ui/core/Typography';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { isEmpty } from 'lodash';
import FormattedTypography from '../../../common/FormattedTypography';
import CustomModal from '../../../common/CustomModal';
import {
  ACCORDION_GREY,
  MCKINSEY_BLUE,
  MEDIUM_GREY,
  MODERATE_DARK_GREY,
  SWITCH_GREY, WHITE,
} from '../../../../stylesheets/colors';
import LtiModalComponents from '../../LtiModalComponents';
import CheckRoundIcon from '../../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../../assets/icons/error-round.svg';
import { getJournalINFO } from '../../../../containers/ContentLibrary/api';

const useStyles = makeStyles(() => ({
  modalStyles: {
    maxWidth: '72rem',
  },
  dialogContentClass: {
    padding: '0 13rem 0.5rem 13rem',
    '& > div': {
      marginBottom: '2rem',
    },

    '& > div:last-child': {
      marginBottom: '1rem',
    },
  },
  modalHeader: {
    margin: '0 auto',
    width: '100%',
    maxHeight: '50vh',
    overflow: 'auto',
    alignItems: 'center',

    '& span': {
      textAlign: 'center',
    },
  },
  divider: {
    width: '100%',
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',

    '& > button:nth-child(1)': {
      width: '11rem',

      '&  > span': {
        fontSize: '1rem',
      },
    },
    '& > button:nth-child(2)': {
      width: '11rem',
      marginLeft: '1.25rem',

      '&:disabled': {
        backgroundColor: SWITCH_GREY,
        color: ACCORDION_GREY,
      },
      '&:hover': {
        backgroundColor: MCKINSEY_BLUE,
        color: WHITE,
        opacity: 0.6,
      },

      '&  > span': {
        fontSize: '1rem',
        fontWeight: 600,
      },
    },
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  subHeading: {
    color: MEDIUM_GREY,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    fontSize: '1rem',
    fontWeight: 600,
    color: MODERATE_DARK_GREY,
    marginBottom: '0.4rem',
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  dataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      padding: '1.25rem',
      display: 'flex',
      width: '100%',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',

        '& > div:nth-child(2)': {
          marginTop: '0.75rem',
        },
      },
    },
  },
  formWrapper: {
    width: '100%',
  },
  radioOptions: {
    color: MODERATE_DARK_GREY,
    fontSize: ' 1rem',
  },
}
));

const cancelTokenSource = {};
const defaultData = {
  searchId: '',
  configId: '',
  journalTitle: '',
  journalDesc: '',
  linkedName: '',
  noOfComponents: '',
  doceboLpId: '',
};

const AddJournalModal = ({
  open, onClose, onOk, savedJournalValues, selectedJournal,
}) => {
  const classes = useStyles();
  const [isValidating, setIsValidating] = useState(false);
  const [status, setStatus] = useState('');
  const isNewJournal = isEmpty(savedJournalValues);
  const { searchId: searchText } = savedJournalValues;
  const formik = useFormik({
    initialValues: isNewJournal ? { ...defaultData } : { ...savedJournalValues },
    onSubmit: (values) => {
      onOk(values);
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit, handleChange, values, resetForm, setFieldValue,
  } = formik;
  const { journal_id } = selectedJournal;

  const checkFormDisabled = () => {
    const {
      doceboLpId, configId, linkedName, journalTitle, journalDesc,
    } = values;
    let isValid = true;
    if (doceboLpId && configId && journalTitle && journalDesc && linkedName && !isValidating) {
      isValid = false;
    }

    return isValid;
  };

  const getJournalInfo = useCallback(
    async (searchId) => {
      // Cancel old request
      setIsValidating(true);
      if (cancelTokenSource[['uuid']]) {
        cancelTokenSource[['uuid']].cancel();
      }

      setStatus('');

      if (!searchId) {
        setIsValidating(false);
        return;
      }

      try {
        cancelTokenSource[['uuid']] = CancelToken.source();
        const response = await getJournalINFO(searchId, cancelTokenSource[['uuid']].token);

        if (response?.data?.success) {
          const {
            config_id, docebo_lp_id, num_components, program_name,
          } = response?.data?.data;
          setStatus('VALID');
          setFieldValue('configId', config_id);
          setFieldValue('linkedName', program_name);
          setFieldValue('noOfComponents', num_components);
          setFieldValue('doceboLpId', docebo_lp_id);
          if (isNewJournal) {
            setFieldValue('journalTitle', `${program_name}_JO`);
          }
        } else {
          setStatus('INVALID');
        }
        setIsValidating(false);
      } catch (e) {
        if (!isCancel(e)) {
          console.error(e);
          setStatus('INVALID');
          setIsValidating(false);
        }
      }
    },
    [isNewJournal, setFieldValue],
  );

  const debouncedChange = useCallback(_debounce(getJournalInfo, 500), [getJournalInfo]);

  const onIdChange = useCallback(
    (e) => {
      batchUpdates(() => {
        setFieldValue('configId', '');
        setFieldValue('linkedName', '');
        setFieldValue('noOfComponents', '');
        setFieldValue('doceboLpId', '');
        handleChange(e);
        debouncedChange(e.target.value);
      });
    },
    [debouncedChange, handleChange, setFieldValue],
  );

  useEffect(() => {
    if (!isNewJournal) {
      onIdChange({ target: { value: searchText } });
    }
  }, [isNewJournal, onIdChange, searchText]);

  const getInputFieldIcon = () => {
    if (isValidating) {
      return (<CircularProgress size="2rem" />);
    }
    if (status === 'VALID') {
      return (<img src={CheckRoundIcon} alt="error-round" />);
    }
    if (status === 'INVALID') {
      return (<img src={ErrorRoundIcon} alt="success-round" />);
    }

    return <></>;
  };

  const handleClose = () => {
    resetForm();
    onClose();
    setStatus('');
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      modalStyle={classes.modalStyles}
    >
      <form onSubmit={handleSubmit} className={classes.formWrapper}>
        <DialogTitle disableTypography className={classes.modalHeader}>
          <FormattedTypography
            variant="subtitle2"
            body={isNewJournal ? 'Add a new journal' : `Edit ${journal_id}`}
            dense
            className={classes.heading}
          />
          <FormattedTypography
            variant="subtitle2"
            body={isNewJournal ? (
              <>
                <Typography variant="body1" component="p">
                  Add this journal to an existing Learning Plan and include
                  basic Journal details below.
                </Typography>
                <Typography variant="body1" component="p">
                  {' '}
                  Your journal will be ready to add pages and prompts.
                </Typography>
              </>
            ) : (
              <Typography variant="body1" component="p">
                Edit the basic Journal details below. The journal will be saved and updated.
              </Typography>
            )}
            dense
            className={classes.subHeading}
          />
        </DialogTitle>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogContent className={`${classes.dialogContentClass} form-content`}>
          {isNewJournal && (
          <LtiModalComponents
            inputFieldIcon={getInputFieldIcon()}
            label="Configuration ID / Docebo Learning Plan ID"
            name="searchId"
            placeHolder="Enter Configuration ID / Docebo Learning Plan ID"
            value={values.searchId}
            isRequired
            isEdit
            fieldType="text"
            handleChange={onIdChange}
            isValidating={isValidating}
          />
          )}
          <Paper className={classes.dataWrapper} square elevation={1}>
            <Box>
              <Box>
                <LtiModalComponents
                  inBox
                  label="Linked Name:"
                  name="linkedName"
                  value={values.linkedName}
                />
                <LtiModalComponents
                  inBox
                  label="Configuration ID:"
                  name="configId"
                  value={values.configId}
                />
              </Box>
              <Box>
                <LtiModalComponents
                  inBox
                  label="No. of Components:"
                  name="noOfComponents"
                  value={values.noOfComponents}
                />
                <LtiModalComponents
                  inBox
                  label="Docebo LP ID:"
                  name="doceboLpId"
                  value={values.doceboLpId}
                />
              </Box>
            </Box>
          </Paper>
          <LtiModalComponents
            label="Journal Title"
            placeHolder="My Problem Solving Journal"
            name="journalTitle"
            value={values.journalTitle}
            isRequired
            isEdit
            fieldType="text"
            handleChange={handleChange}
            charLimit={255}
          />
          <LtiModalComponents
            label="Journal Description"
            placeHolder="Enter the journal description here"
            value={values.journalDesc}
            isRequired
            fieldType="textarea"
            valueType="linkify"
            isEdit
            name="journalDesc"
            handleChange={handleChange}
            charLimit={255}
          />
        </DialogContent>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogActions className={classes.centerAction}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => { onOk(values); }} disabled={checkFormDisabled()}>
            Save and Publish
          </Button>
        </DialogActions>
      </form>
    </CustomModal>
  );
};

AddJournalModal.defaultProps = {
  selectedJournal: {},
};

AddJournalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  savedJournalValues: PropTypes.object.isRequired,
  selectedJournal: PropTypes.object,
};

export default AddJournalModal;
