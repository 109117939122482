// import { Session } from '@nvt/mid-react';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Box from '@material-ui/core/Box';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import theme from './stylesheets/theme';

import { getNvtUserInfo } from './helpers/nvtUserInfoHelper';
import { setInLocalStorage } from './helpers/userHelper';
import AuthorizeRoute from './AuthorizeRoute';
import BasePage from './containers/BasePage';
import ModifyCoursePage from './containers/ModifyCoursePage';
import ContentAssignment from './containers/ContentAssignmentPage';
import ErrorPage from './containers/ErrorPage';
import ReportPage from './containers/ReportPage';
import CreateProgramPage from './containers/CreateProgramPage';
import {
  ROUTE_HOME,
  ROUTE_UPLOAD,
  LANDING_PATH,
  ROUTE_REPORT,
  ROUTE_NVT_AUTH_CALLBACK,
  COURSE_PROD,
  PROGRAM_CREATION,
  ROUTE_CONTENT_ASSIGNMENT,
  MODIFY_COURSES,
  COURSE_OPS,
  ROLE_ADMIN,
  ROLE_OPS,
  ROLE_PROD,
  USER_INFO_KEY,
} from './constants';

const queryClient = new QueryClient({});

const Routing = ({ store, history }) => {
  const st = store.getState();
  const { mid } = st.mid;

  useEffect(() => {
    const getUserAsync = async () => {
      const userData = await getNvtUserInfo();
      setInLocalStorage(USER_INFO_KEY, userData);
    };
    getUserAsync();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ConnectedRouter history={history}>
              <Box mid={mid}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to={{ pathname: `${LANDING_PATH}` }} />}
                  />
                  <Route
                    exact
                    path="/logout"
                    render={() => <Redirect to={{ pathname: `${LANDING_PATH}` }} />}
                  />
                  <Route
                    path={`/${ROUTE_NVT_AUTH_CALLBACK}`}
                    render={() => <Redirect to={{ pathname: `${LANDING_PATH}` }} />}
                  />

                  {/* TODO: Use AuthorizeRoute for basePage
                    NVT tenant id and auth token are null for the first request
                    Remove after fixing the above issue
                */}
                  <Route
                    exact
                    path={`/${ROUTE_HOME}/:programType(${COURSE_PROD}|${COURSE_OPS})`}
                    component={BasePage}
                  />
                  <Route
                    exact
                    path={`/${ROUTE_HOME}/:programType(${COURSE_PROD}|${COURSE_OPS})/:platformType`}
                    component={BasePage}
                  />
                  <Route
                    exact
                    path={`/${ROUTE_HOME}/:programType(${COURSE_PROD}|${COURSE_OPS})/:platformType/:programSubType`}
                    component={BasePage}
                  />
                  <Route
                    exact
                    path={`/${ROUTE_HOME}/:programType(${COURSE_PROD}|${COURSE_OPS})/:platformType/:programSubType/:feature`}
                    component={BasePage}
                    roles={[ROLE_PROD, ROLE_ADMIN]}
                  />
                  <Route
                    exact
                    path={`/${ROUTE_HOME}/:programType(${COURSE_PROD}|${COURSE_OPS})/:platformType/:programSubType/:feature/:id`}
                    component={BasePage}
                    roles={[ROLE_PROD, ROLE_ADMIN]}
                  />
                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_REPORT}/:programId/:programType(${COURSE_PROD})/${PROGRAM_CREATION}/:transactionId/${ROUTE_CONTENT_ASSIGNMENT}/:courseId`}
                    component={ContentAssignment}
                    roles={[ROLE_PROD, ROLE_ADMIN]}
                  />
                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_REPORT}/:programId/:programType(${COURSE_PROD})/:programSubType`}
                    component={ReportPage}
                    roles={[ROLE_PROD, ROLE_ADMIN]}
                  />
                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_REPORT}/:programId/:programType(${COURSE_OPS})/:programSubType`}
                    component={ReportPage}
                    roles={[ROLE_OPS, ROLE_ADMIN]}
                  />
                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_REPORT}/:programId/:programType(${COURSE_PROD})/:programSubType/:transactionId`}
                    component={ReportPage}
                    roles={[ROLE_PROD, ROLE_ADMIN]}
                  />
                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_REPORT}/:programId/:programType(${COURSE_OPS})/:programSubType/:transactionId`}
                    component={ReportPage}
                    roles={[ROLE_OPS, ROLE_ADMIN]}
                  />
                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_REPORT}/:programId/:programType(${COURSE_PROD})/:programSubType/:transactionId/:uploadUsers`}
                    component={ReportPage}
                    roles={[ROLE_PROD, ROLE_ADMIN]}
                  />
                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_REPORT}/:programId/:programType(${COURSE_OPS})/:programSubType/:transactionId/:uploadUsers`}
                    component={ReportPage}
                    roles={[ROLE_OPS, ROLE_ADMIN]}
                  />

                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_UPLOAD}/:programType(${COURSE_PROD})/:programSubType/${MODIFY_COURSES}`}
                    component={ModifyCoursePage}
                    roles={[ROLE_PROD, ROLE_ADMIN]}
                  />
                  <AuthorizeRoute
                    exact
                    path={`/${ROUTE_UPLOAD}/:programType(${COURSE_PROD})/:programSubType`}
                    component={CreateProgramPage}
                    roles={[ROLE_PROD, ROLE_ADMIN]}
                  />

                  <Route path="*" component={ErrorPage} />
                </Switch>
              </Box>
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
};

Routing.propTypes = {
  store: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Routing;
