import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandedComponent from './ExpandedComponent';

const ExpandableTableRow = ({
  children, expandComponent, checkElucidatValidity, course, courseIndex,
  expandAll, coureseRow, formik, updateCourseErrorStatus, courseLimitExceeded,
  destinationConfigId, ...otherProps
}) => {
  const { course_id: courseId } = course;
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (expandAll) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [expandAll]);

  return (
    <>
      <TableRow
        {...otherProps}
      >
        {children}
        <TableCell align="right">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <ExpandedComponent
        courseId={courseId}
        formik={formik}
        isExpanded={isExpanded}
        courseIndex={courseIndex}
        updateCourseErrorStatus={updateCourseErrorStatus}
        coureseRow={coureseRow}
        isDisabled={!course?.selected}
        courseChecked={course?.selected === true}
        checkElucidatValidity={checkElucidatValidity}
        courseLimitExceeded={courseLimitExceeded}
        destinationConfigId={destinationConfigId}
      />
    </>
  );
};

export default ExpandableTableRow;

ExpandableTableRow.propTypes = {
  children: PropTypes.element.isRequired,
  expandComponent: PropTypes.element.isRequired,
  course: PropTypes.shape({
    course_id: PropTypes.number.isRequired,
    course_name: PropTypes.string.isRequired,
    course_code: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    lo: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        original_project_id: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.oneOf([null]),
        ]),
        project_id_status: PropTypes.string.isRequired,
        project_name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  expandAll: PropTypes.bool.isRequired,
  updateCourseErrorStatus: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      courses: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  courseIndex: PropTypes.string.isRequired,
  coureseRow: PropTypes.string.isRequired,
  courseLimitExceeded: PropTypes.func.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
};
