import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  BLACK, LIGHT_YELLOW, DARK_YELLOW, ACCORDION_GREY,
} from '../../stylesheets/colors';
import ConfirmationModal from '../common/ConfirmationModal';
import YellowAlertIcon from '../../assets/icons/icon-yellow-alert.svg';
import FormattedTypography from '../common/FormattedTypography';
import RefreshConfirmation from '../../assets/icons/refreshConfirmation.png';

const useStyles = makeStyles({
  infoContentText: {
    color: BLACK,
  },
  alertWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 0',
  },
  alertBox: {
    backgroundColor: LIGHT_YELLOW,
    width: '100%',
    margin: '1rem auto',
    padding: '1rem 2rem',
  },
  alertText: {
    color: DARK_YELLOW,
    paddingLeft: '1rem',
  },
  heading: {
    padding: '2rem',
  },
  headText: {
    fontWeight: '800',
  },
  subHeading: {
    '& div': {
      fontSize: '1.5rem',
    },
  },
  componentPaper: {
    padding: '1rem',
  },
  confirmImage: {
    display: 'box',
    margin: '0 auto',
  },
  assignedEopHead: {
    padding: '1rem 0',
    '& h4': {
      fontSize: '1.75rem',
    },
    '& h4,h6': {
      textAlign: 'center',
    },
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
});

const EopComponentModal = ({
  open, onClose, row, loading, onConfirm, assignedEop,
}) => {
  const classes = useStyles();

  const {
    name, code, id, eop,
  } = row;

  const isEop = eop && eop === true;

  const isAssignedEop = assignedEop && Object.keys(assignedEop)?.length > 0;

  const componentAttribute = (label, value) => (
    <>
      <Typography variant="body1" className={`${classes.headText}`}>
        {label}
        :
      </Typography>
      <Typography variant="body1" className={`${classes.headText}`}>
        {value}
      </Typography>
    </>
  );

  return (
    <ConfirmationModal
      open={open}
      isLoading={loading}
      onClose={onClose}
      breakpoint="md"
      title={isAssignedEop && assignedEop?.id !== id ? null : (
        <Container className={classes.heading}>
          {
            isEop ? (
              <FormattedTypography
                variant="h1"
                body="Unassigning the EOP component feedback for the selected component"
                subHeading="Unassigning North America Mornings as EOP Feedback Component for Impact Tracking"
                subHeadingSection={classes.subHeading}
              />
            ) : (
              <FormattedTypography
                variant="h1"
                body="Assigning the EOP component feedback to the selected component"
                subHeading="Assigning North America Mornings as EOP Feedback Component for Impact Tracking"
                subHeadingSection={classes.subHeading}
              />
            )
          }

        </Container>
      )}
      content={(
        <Container maxWidth="sm">
          {
            isAssignedEop && assignedEop?.id !== id ? (
              <>
                <img src={RefreshConfirmation} alt="confirmation" height="100" width="100" className={classes.confirmImage} />
                <Box className={classes.assignedEopHead}>
                  <Typography variant="h4" className={classes.headText}>
                    You are choosing to set a new component as the EOP
                  </Typography>
                  <Typography variant="subtitle1" className={classes.infoHelperText}>
                    EOP is already assigned to another component -
                    {' '}
                    {assignedEop?.name}
                    .
                  </Typography>
                </Box>
                <Typography variant="subtitle1" className={classes.infoHelperText} style={{ textAlign: 'center' }}>
                  Please confirm if you wish to un-assign the EOP tag from the old
                  component and assign it to the selected one -
                  {' '}
                  {name}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="subtitle1" className={`${classes.headText}`}>Selected component details</Typography>
                <Paper className={classes.componentPaper} style={{ paddingTop: '1rem' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {componentAttribute('Name', name)}
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      {componentAttribute('Code', code)}
                    </Grid>
                    <Grid item xs={6}>
                      {componentAttribute('DoceboID', id)}
                    </Grid>
                  </Grid>
                </Paper>
                <Box className={`${classes.alertWrapper} ${classes.alertBox}`}>
                  <img
                    src={YellowAlertIcon}
                    alt="Alert-round-yellow"
                  />
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.alertText}`}>
                    {
                      isEop ? 'This action will unassign the component as EOP feedback component. It can be assigned anytime.'
                        : 'This action will assign the component as EOP feedback component. It can be unassigned anytime.'
                    }
                  </Typography>
                </Box>
              </>
            )
          }
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => onConfirm(row?.id, !isEop)}>
            Confirm
          </Button>
        </>
      )}
    />
  );
};

EopComponentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  assignedEop: PropTypes.object.isRequired,
};

export default EopComponentModal;
