import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@mui/material/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormattedTypography from '../common/FormattedTypography';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import YellowAlertIcon from '../../assets/icons/icon-yellow-alert.svg';
import BlueAlertIcon from '../../assets/icons/icon-blue-alert.svg';

import CustomUploadModal from '../common/CustomUploadModal';
import {
  ACCORDION_GREY, BLACK, MEDIUM_GREY, DARK_GREEN, LIGHT_YELLOW, DARK_YELLOW,
} from '../../stylesheets/colors';
import {
  downloadFile,
} from '../../containers/common/apis';

const useStyles = makeStyles(() => ({
  circularProgressPadding: {
    padding: '1rem',
  },
  logoIcon: {
    '& > :nth-child(1)': {
      margin: '1rem 0 0 0',
    },
  },
  headingWrapper: {
    paddingTop: '0',
  },
  contentListWrapper: {
    width: '100%',
    padding: '0.5rem 0',
  },
  contentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
    padding: '0.25rem 0',
  },
  infoContentText: {
    color: BLACK,
  },
  headingBoldContent: {
    color: MEDIUM_GREY,
  },
  successContentText: {
    color: DARK_GREEN,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  subHeading: {
    fontWeight: 800,
    paddingBottom: '0.5rem',
  },
  contentBox: {
    marginBottom: '0',
  },
  loCountWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 0',
  },
  alertBox: {
    backgroundColor: LIGHT_YELLOW,
    width: '65%',
    margin: '1rem auto',
    padding: '1rem 2rem',
  },
  alertText: {
    color: DARK_YELLOW,
    paddingLeft: '1rem',
  },
  errorBox: {
    marginBottom: '4rem',
  },
}));

const getSrc = (isSuccess) => (isSuccess ? CheckRoundIcon : BlueAlertIcon);

const ReplicationOverviewModal = ({
  open, onClose, progress, redirectToreplicatedProgram, programSubType,
}) => {
  const classes = useStyles();

  const { metadata } = progress?.report;
  const {
    total_lo_count, success_lo_count, source_config_id, transaction_id,
  } = metadata;
  const failedReplicatedLos = total_lo_count - success_lo_count;

  const onDownloadLog = async () => {
    try {
      await downloadFile(transaction_id, source_config_id, programSubType, new Date(), '', true);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const body = (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={(
        <ListSubheader component="div" id="nested-list-subheader" style={{ fontSize: '1.25rem', paddingTop: '1rem' }}>
          Replication Summary
        </ListSubheader>
      )}
    >
      <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
        <Box className={classes.contentListWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.subHeading}
          >
            Learning Plan
          </Typography>
          <Box>
            <Typography variant="subtitle2" className={classes.infoHelperText}>
              Parent Program ID:
              <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                {progress?.report?.metadata?.source_config_id || ''}
              </Typography>
            </Typography>
          </Box>
          <Box className={classes.contentList}>
            <Typography variant="subtitle2" className={classes.infoHelperText}>
              Replicated Program’s ID:
              <Typography variant="subtitle2" component="span" className={`${classes.successContentText} ${classes.nestedText}`}>
                {progress?.report?.metadata?.destination_config_id || ''}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </ListItem>
      <Divider component="li" />
      {Object.keys(progress?.report?.channels || {}).length > 0 && (
        <>
          <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
            <Box className={classes.contentListWrapper}>
              <Typography
                variant="subtitle2"
                className={classes.subHeading}
              >
                {`Channel (${
                  // eslint-disable-next-line no-nested-ternary
                  progress?.report?.channels?.add_visibility_only
                    ? 'with visiblity only' : progress?.report?.channels?.link_assets
                      ? 'Duplicated channel with duplicated assets' : 'with no assets'})`}
              </Typography>
              <Box>
                <Typography variant="subtitle2" className={classes.infoHelperText}>
                  Old Name:
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                    {progress?.report?.channels?.source_channel_name || ''}
                  </Typography>
                </Typography>
              </Box>
              <Box className={classes.contentList}>
                <Typography variant="subtitle2" className={classes.infoHelperText}>
                  New Name:
                  <Typography variant="subtitle2" component="span" className={`${classes.successContentText} ${classes.nestedText}`}>
                    {progress?.report?.channels?.channel_name || ''}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </ListItem>
          <Divider component="li" />
        </>
      )}
      {progress?.report?.group?.name && (
        <>
          <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
            <Box className={classes.contentListWrapper}>
              <Typography
                variant="subtitle2"
                className={classes.subHeading}
              >
                Cohort Group Name
              </Typography>
              <Box>
                <Typography variant="subtitle2" className={classes.infoHelperText}>
                  Old Name:
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                    {progress?.report?.group?.parent_group_name}
                  </Typography>
                </Typography>
              </Box>
              <Box className={classes.contentList}>
                <Typography variant="subtitle2" className={classes.infoHelperText}>
                  New Name:
                  <Typography variant="subtitle2" component="span" className={`${classes.successContentText} ${classes.nestedText}`}>
                    {progress?.report?.group?.name}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </ListItem>
          <Divider component="li" />
        </>
      )}
      <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
        <Box className={classes.contentListWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.subHeading}
          >
            Enrollment rule
          </Typography>
          <Box>
            <Typography variant="subtitle2" className={classes.infoHelperText}>
              Old Rule:
              New Learners enrolled into:
              <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                {progress.report.metadata.source_config_id || ''}
              </Typography>
            </Typography>
          </Box>
          <Box className={classes.contentList}>
            <Typography variant="subtitle2" className={classes.infoHelperText}>
              New Learners enrolled into:
              <Typography variant="subtitle2" component="span" className={`${classes.successContentText} ${classes.nestedText}`}>
                {progress?.report?.metadata?.destination_config_id || ''}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </ListItem>
      <Divider component="li" />
      <ListItem alignItems="flex-start" sx={{ padding: '0' }}>
        <Box className={classes.contentListWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.subHeading}
          >
            Learning Objects
          </Typography>
          <Container>
            <Box className={classes.loCountWrapper}>
              <img
                src={CheckRoundIcon}
                alt="check-round-green"
              // style={{ position: 'relative', top: '8px', margin: '0 1rem' }}
              />
              <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                {`${progress.report.metadata.success_lo_count}/${progress.report.metadata.total_lo_count} successfully replicated`}
              </Typography>
            </Box>
            {failedReplicatedLos !== 0 && (
              <>
                <Divider component="li" />
                <Box className={classes.loCountWrapper}>
                  <img
                    src={ErrorRoundIcon}
                    alt="Error-round-red"
                  />
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                    {`${progress.report.metadata.total_lo_count - progress.report.metadata.success_lo_count}/${progress.report.metadata.total_lo_count} not replicated successfully`}
                  </Typography>
                </Box>
              </>
            )}
          </Container>
        </Box>
      </ListItem>
      <Divider component="li" />
    </List>
  );

  return (
    <>
      {(progress?.report?.metadata.success === false && progress?.is_config_reusable === true)
        ? (
          <CustomUploadModal
            open={open}
            onClose={onClose}
            breakpoint="sm"
            logoWrapper={classes.logoIcon}
            contentWrapper={classes.contentBox}
            logo={(
              <img
                src={getSrc(false)}
                alt="check-round-green"
                width={80}
                height={80}
                className={classes.circularProgressPadding}
              />
            )}
            heading={(
              <DialogTitle className={classes.headingWrapper}>
                <FormattedTypography
                  variant="subtitle1"
                  body="Something went wrong…"
                />
              </DialogTitle>
            )}
            contentDivider
            disableGutters
            primaryBtnText="Close"
            primaryBtnProps={{
              onClick: () => onClose(),
            }}
          >
            <Box className={classes.errorBox}>
              <Typography variant="subtitle1" className={classes.infoHelperText} align="center">
                {`We were unable to replicate the parent program
               ${progress.report.metadata.source_config_id || ''}`}
              </Typography>
              <Typography variant="subtitle1" className={classes.infoHelperText} align="center">
                You can still use the config ID to try again
              </Typography>
            </Box>
          </CustomUploadModal>
        ) : (
          <CustomUploadModal
            open={open}
            onClose={onClose}
            breakpoint="sm"
            logoWrapper={classes.logoIcon}
            contentWrapper={classes.contentBox}
            logo={(
              <img
                src={getSrc(failedReplicatedLos === 0)}
                alt="check-round-green"
                width={80}
                height={80}
                className={classes.circularProgressPadding}
              />
            )}
            heading={(
              <DialogTitle className={classes.headingWrapper}>
                <FormattedTypography
                  body="Replication Complete!"
                  subHeading={(
                    <>
                      Parent program
                      <Typography variant="subtitle1" component="span" className={`${classes.headingBoldContent} ${classes.nestedText}`}>
                        {progress.report.metadata.source_config_id || ''}
                      </Typography>
                      was successfully replicated!
                      <Typography variant="subtitle1" component="span" className={`${classes.headingBoldContent} ${classes.nestedText}`}>
                        {progress.report.metadata.destination_config_id || ''}
                      </Typography>
                      is now ready for use.
                      {failedReplicatedLos !== 0 && (
                        <Box className={`${classes.loCountWrapper} ${classes.alertBox}`}>
                          <img
                            src={YellowAlertIcon}
                            alt="Alert-round-yellow"
                          />
                          <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.alertText}`}>
                            You cannot use this config ID to create another LP
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                />
              </DialogTitle>
            )}
            headingDivider
            contentDivider
            disableGutters
            primaryBtnText="Go to Replicated Program"
            primaryBtnProps={{
              onClick: () => redirectToreplicatedProgram(),
            }}
            secondaryBtnText="Download Log"
            secondaryBtnProps={{
              onClick: () => onDownloadLog(),
            }}
          >
            {body}
          </CustomUploadModal>
        )}
    </>
  );
};

ReplicationOverviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  progress: PropTypes.object.isRequired,
  programSubType: PropTypes.string.isRequired,
  redirectToreplicatedProgram: PropTypes.func.isRequired,
};

export default ReplicationOverviewModal;
