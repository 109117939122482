import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@material-ui/core/Typography';
import { dateFormatter } from '../../helpers/formattingHelpers';

const UserEnrolledLpTableBody = ({ userEnrolledLpData }) => {
  const getTableCell = (value) => (
    <TableCell align="left">
      <Typography>{value || '-'}</Typography>
    </TableCell>
  );

  return (
    <TableBody>
      {userEnrolledLpData && userEnrolledLpData.map((lp) => {
        const {
          lp_id, learning_program, enrollment_cohort, group_name, role, start_date, end_date,
        } = lp;
        const startDate = start_date ? dateFormatter(new Date(start_date)) : 'N/A';
        const endDate = end_date ? dateFormatter(new Date(end_date)) : 'N/A';

        return (
          <TableRow key={lp_id}>
            {getTableCell(learning_program)}
            {getTableCell(enrollment_cohort)}
            {getTableCell(group_name)}
            {getTableCell(role)}
            {getTableCell(`${startDate} - ${endDate}`)}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

UserEnrolledLpTableBody.propTypes = {
  userEnrolledLpData: PropTypes.array.isRequired,
};

export default UserEnrolledLpTableBody;
