import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Grid, Box, Divider } from '@mui/material';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import LoaderIcon from '../../../assets/icons/loader-icon.svg';
import FormattedTypography from '../../common/FormattedTypography';
import MCKINSEY_SANS from '../../../stylesheets/fonts';
import { DARK_MEDIUM_GREY } from '../../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 4.25rem',
  },
  heading: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    fontFamily: MCKINSEY_SANS,
    lineHeight: '1.25',
    width: '100%',
    paddingTop: '2rem',
  },
  sessionSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1.5rem',
  },
  prefix: {
    color: DARK_MEDIUM_GREY,
    paddingRight: '0.5rem',
  },
  sufix: {
    color: DARK_MEDIUM_GREY,
    paddingLeft: '0.5rem',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  sessionCountStatusIcon: {
    padding: '1rem',
    marginBottom: '0 !important',
  },
  sessionCountStatusIconWithAnimation: {
    padding: '1rem',
    marginBottom: '0 !important',
    animation: '$spinner 2s linear infinite',
  },
  countSectionClass: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bodyTextClipedCls: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '35rem',
    whiteSpace: 'nowrap',
  },
  sessionHeaderTextCliped: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const SessionBlock = ({ sessions }) => {
  const classes = useStyles();
  const getIcon = ({ total_rows: tr, successful_rows: sr, processed_rows: pr }) => {
    if (tr === sr) {
      return { icon: CheckRoundIcon, class: classes.sessionCountStatusIcon };
    }
    if (tr !== sr && tr === pr) {
      return { icon: ErrorRoundIcon, class: classes.sessionCountStatusIcon };
    }
    return { icon: LoaderIcon, class: classes.sessionCountStatusIconWithAnimation };
  };
  return (
    <>
      {sessions.length > 0
        ? sessions.map((s, i) => (
          <Box component="div" key={s.session_id} className={classes.sessionSection}>
            <Box component="span">
              <FormattedTypography
                className={classes.bodyTextClipedCls}
                prefixClassName={classes.prefix}
                prefix={`Session ${i + 1}: `}
                body={s?.session_name || ''}
              />
            </Box>
            <Box component="span" className={classes.countSectionClass}>
              <img
                src={getIcon(s)?.icon || LoaderIcon}
                alt="status-icon"
                width={40}
                height={40}
                className={getIcon(s)?.class || ''}
              />
              <FormattedTypography
                body={`${s.successful_rows}/${s.total_rows}`}
                suffixClassName={classes.sufix}
                suffix="users enrolled"
              />
            </Box>
          </Box>
        ))
        : null}
    </>
  );
};

const SyncReportModal = ({ progressData: { progress } }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      {progress?.map((ilt) => (
        <Grid item key={ilt.course_id} className={`${classes.heading} ${classes.sessionHeaderTextCliped}`}>
          {ilt.course_name}
          <SessionBlock sessions={ilt.sessions} />
          <Divider sx={{ mt: 1 }} />
        </Grid>
      ))}
    </Grid>
  );
};

SessionBlock.propTypes = {
  sessions: PropTypes.array.isRequired,
};

SyncReportModal.propTypes = {
  progressData: PropTypes.object.isRequired,
  progress: PropTypes.array.isRequired,
};

export default SyncReportModal;
