import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import _filter from 'lodash/filter';
import FormattedTypography from '../common/FormattedTypography';
import IndividualScormOverview from './IndividualScormOverview';
import { COMPLETED, FAILED } from '../../constants';
import AlertIcon from '../../assets/icons/alert-status-icon.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import { MEDIUM_GREY } from '../../stylesheets/colors';
import { LoaderInner } from '../Loader';
import StatusModalWrapper from '../ActivityLogs/StatusModalWrapper';
import GenericProgressModal from '../common/GenericProgressModal';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    color: MEDIUM_GREY,
    textAlign: 'center',
  },
  subtitleStyles: {
    marginTop: '1.25rem',
  },
  headingWrapper: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  completedSubheading: {
    display: 'flex',
    flexDirection: 'column',
  },
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
}));

const IndividualScormReleaseStatuses = ({
  open, onClose, modalState, transactionId, getReport, progressData,
  progressErrorMsg, onDownloadLog,
}) => {
  const classes = useStyles();

  const reportInfo = {
    COMPLETED: {
      src: CheckRoundIcon,
      heading: 'Full re-release for SCORM created successfully!',
      subHeading: 'A full re-release was created for the selected training material. This update will also reflect in Elucidat.',
      primaryBtnText: 'Close',
    },
    COMPLETED_WITH_ERRORS: {
      src: AlertIcon,
      heading: 'Full re-release for SCORM completed with errors',
      subHeading: 'There was a problem when refreshing and syncing the re-release for the following SCORM, please try again.',
      primaryBtnText: 'Download Log',
    },
    FAILED: {
      src: ErrorRoundIcon,
      heading: 'Full re-release for SCORM failed',
      subHeading: 'There was a problem when refreshing and syncing the re-release for the following SCORM, please try again.',
      primaryBtnText: 'Download Log',
    },
  };

  const reportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    let updatedTmList = [];
    const status = result?.data[0]?.status;
    if (status === COMPLETED) {
      const tmList = result?.data;
      updatedTmList = _filter(tmList, { status: COMPLETED });
    }
    return ({
      component: IndividualScormOverview,
      wrapperProps: {
        breakpoint: 'md',
        disableGutters: true,
        logo: (
          <Box>
            <img src={reportInfo?.[status]?.src} alt="copied-status" width={40} height={40} />
          </Box>
        ),
        heading: (
          <DialogTitle style={{ paddingTop: 0 }}>
            <FormattedTypography
              variant="subtitle1"
              body={reportInfo?.[status]?.heading}
              subHeading={reportInfo?.[status]?.subHeading}
            />
          </DialogTitle>
        ),
        headingDivider: status !== FAILED,
        primaryBtnText: reportInfo?.[status]?.primaryBtnText,
        primaryBtnProps: {
          onClick: status === COMPLETED ? () => onClose()
            : () => onDownloadLog({
              transaction_id: transactionId,
              created_at: result?.created_at,
            }),
        },
      },
      props: {
        status,
        tmList: updatedTmList,
      },
    });
  }, [getReport, onClose, onDownloadLog, reportInfo, transactionId]);

  const progressDataModal = useCallback(() => ({
    component: GenericProgressModal,
    wrapperProps: {
      heading: (
        <DialogTitle>
          <FormattedTypography
            body="SCORM full re-release"
            subHeading={`Full re-release for ${progressData?.scorm_name} is in progress`}
            subHeadingSection={classes.subHeadingSection}
          />
        </DialogTitle>
      ),
      logo: (
        <div className={classes.progressRow}>
          <LoaderInner
            progress={progressData?.percentage ? progressData?.percentage : 0}
            scaleFactor={1.5}
          />
        </div>
      ),
      secondaryBtnText: 'Close',
      secondaryBtnProps: {
        onClick: () => onClose(),
      },
      breakpoint: 'md',
    },
    props: {
      initiatedBy: progressData?.created_by,
      initiatedAt: progressData?.created_at,
    },
  }), [classes.progressRow, classes.subHeadingSection, onClose, progressData]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={progressDataModal}
      reportModal={reportModal}
    />
  );
};

IndividualScormReleaseStatuses.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

IndividualScormReleaseStatuses.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
};

export default IndividualScormReleaseStatuses;
