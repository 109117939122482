import { watcher as midWatcher, midfetch } from '@cs/mid-react-saga';

import {
  put, takeLatest, all, fork, call,
} from 'redux-saga/effects';
import * as constants from './constants';
import Config from '../config';
import { clientDataSaga } from './actions/childSagas/clientData';
import { portfolioDataSaga } from './actions/childSagas/portfolioData';
import { platformUsersSaga } from './actions/childSagas/users';
import { platformUserDetailsSaga } from './actions/childSagas/userDetail';

export function* testFetch() {
  try {
    const fetch = yield midfetch();
    const response = yield call(fetch, `${Config.API_SERVER}/posts`);
    if (!response.ok) {
      throw new Error('Unable to fetch');
    }
    const posts = yield response.text();
    yield put({ type: constants.TEST_FETCH_SUCCESS, payload: { posts } });
  } catch (err) {
    throw new Error('Something failed during test fetch', err);
  }
}

export function* antivirusCheck({ payload }) {
  try {
    const fetch = yield midfetch();
    const endpoint = `${Config.API_SERVER}/scan`;
    const response = yield call(fetch, endpoint, {
      method: 'post',
      body: JSON.stringify({ url: payload }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Unable to fetch');
    }
    const res = yield response.text();
    yield put({ type: constants.ANTIVIRUS_CHECK_SUCCESS, payload: { res } });
  } catch (err) {
    throw new Error('Something failed during test fetch', err);
  }
}

export function* logoutSuccess() {
  yield localStorage.clear();
}

function* watcher() {
  yield takeLatest(constants.TEST_FETCH, testFetch);
  yield takeLatest(constants.ANTIVIRUS_CHECK, antivirusCheck);
  yield takeLatest(constants.LOGOUT_SUCCESS, logoutSuccess);
}

export default function* rootSaga() {
  yield all([fork(midWatcher), fork(watcher),
    clientDataSaga(), portfolioDataSaga(), platformUsersSaga(), platformUserDetailsSaga()]);
}
