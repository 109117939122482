import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import UploadIcon from '../../assets/icons/upload-icon.svg';
import { MODERATE_DARK_GREY } from '../../stylesheets/colors';

import FileInput from '../FileInput';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    margin: '10%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: ({ direction }) => direction,
    boxShadow: '0 0 1px 0 rgba(5, 28, 44, 0.15), 0 4px 8px -1px rgba(5, 28, 44, 0.2)',
    cursor: 'pointer',
  },
  uploadButton: {
    width: '15%',
    height: '100%',
    margin: '5%',
    marginLeft: '0px',
    marginRight: '15px',
  },
  textTitle: {
    fontSize: '1rem',
    letterSpacing: '0',
    textAlign: 'center',
    color: MODERATE_DARK_GREY,
  },
  textSubTitle: {
    fontSize: '1rem',
    letterSpacing: '0',
    textAlign: 'center',
    color: MODERATE_DARK_GREY,
  },
}));

const ReUploadBox = ({
  onReUpload, title, subtitle, direction,
}) => {
  const classes = useStyles({ direction });
  return (
    <FileInput onFileInput={onReUpload}>
      <div className={classes.wrapper}>
        <img src={UploadIcon} className={classes.uploadButton} alt="upload" />
        <Typography className={classes.textTitle}>{title}</Typography>
        <Typography className={classes.textSubTitle}>{subtitle}</Typography>
      </div>
    </FileInput>
  );
};

ReUploadBox.propTypes = {
  onReUpload: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  direction: PropTypes.string,
};

ReUploadBox.defaultProps = {
  title: 'Upload updated Spreadsheet',
  subtitle: 'Re-upload file to complete the process',
  direction: 'column',
};

export default ReUploadBox;
