import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';

import AssignedContentListBox from './AssignedContentListBox';
import SuccessfulAssignmentPrompt from './SuccessfulAssignmentPrompt';
import MetadataBlock from './MetadataBlock';
import LoBlock from './LoBlock';

import {
  SUCCESS_GREEN,
  ERROR_RED,
  DARK_MEDIUM_GREY,
} from '../../../stylesheets/colors';
import { DefaultLogo, LOGO_LOOKUP, ALT_LOOKUP } from '../../../constants';

const subtitleColorChooser = ({ success, marked }) => ({
  true: {
    true: SUCCESS_GREEN,
    false: SUCCESS_GREEN,
  },
  false: {
    true: DARK_MEDIUM_GREY,
    false: ERROR_RED,
  },
}[success][marked]);

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '40px',
    minHeight: '60px',
  },
  lhsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  rhsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: '15px',
    flex: 1,
  },
  contentButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoWrapper: {
    display: 'flex',
  },
  logo: {
    padding: '5px',
    width: '36px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    color: subtitleColorChooser,
    marginTop: '3px',
    fontWeight: '400',
  },
  loBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  componentName: {
    fontWeight: 'bold',
  },
  buttonWrapper: {
    display: 'flex',
  },
  assignedContentWrapper: {
    paddingTop: '10px',
  },
}));

const MainBlockCard = ({
  title,
  subtitle,
  success,
  marked,
  metadataSuccess,
  completed,
  metadata,
  logo,
  courseId,
  onAssignClick,
  contentList,
  showSuccessPrompt,
  showFindContent,
  lo,
}) => {
  const classes = useStyles({ success, marked });

  const logoUri = LOGO_LOOKUP[logo] || DefaultLogo;
  const alt = ALT_LOOKUP[logo] || 'Unknown';

  // eslint-disable-next-line max-len
  const shouldShowButton = !contentList.length && courseId && success && completed && showFindContent;

  const loDom = lo.map((obj) => <LoBlock key={obj.id} lo={obj} />);

  return (
    <div className={classes.wrapper}>
      <div className={classes.lhsWrapper}>
        <div className={classes.logoWrapper}>
          <img title={alt} src={logoUri} alt={alt} className={classes.logo} />
        </div>
      </div>
      <div className={classes.rhsWrapper}>
        <div className={classes.contentButtonWrapper}>
          <div className={classes.contentWrapper}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subtitle}>
              <span className={classes.componentName}>{subtitle.component_name}</span>
              {': '}
              {subtitle.info}
            </Typography>
            <MetadataBlock data={metadata} success={metadataSuccess} marked={marked} />
            <div className={classes.loBlock}>{loDom}</div>
          </div>
          <div className={classes.buttonWrapper}>
            {shouldShowButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => onAssignClick(courseId)}
              >
                Find Content
              </Button>
            )}
          </div>
        </div>
        <div className={classes.assignedContentWrapper}>
          <SuccessfulAssignmentPrompt show={showSuccessPrompt} />
          <AssignedContentListBox contentList={contentList} />
        </div>
      </div>
    </div>
  );
};

MainBlockCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.shape({
    component_name: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
  }).isRequired,
  success: PropTypes.bool.isRequired,
  marked: PropTypes.bool.isRequired,
  metadataSuccess: PropTypes.bool,
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      metadata: PropTypes.string.isRequired,
      success: PropTypes.bool.isRequired,
    }),
  ),
  lo: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      elucidat_project_id: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.string.isRequired,
      ]),
      replication_type: PropTypes.string.isRequired,
      release_id: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string.isRequired]),
    }),
  ),
  completed: PropTypes.bool.isRequired,
  logo: PropTypes.string.isRequired,
  onAssignClick: PropTypes.func.isRequired,
  courseId: PropTypes.number,
  contentList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      release: PropTypes.string,
    }),
  ),
  showSuccessPrompt: PropTypes.bool.isRequired,
  showFindContent: PropTypes.bool.isRequired,
};

MainBlockCard.defaultProps = {
  courseId: undefined,
  contentList: [],
  metadata: undefined,
  metadataSuccess: true,
  lo: [],
};

export default MainBlockCard;
