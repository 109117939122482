/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import MuiTextField from '@mui/material/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LabledTextField from '../common/LabledTextField';
import LabeledTextArea from '../common/LabeledTextArea';
import Select from '../common/SelectMui';
import {
  MODERATE_DARK_GREY, ACCORDION_GREY, MODERATE_LIGHT_GREY, DARK_GREY, REPORT_ERROR_RED,
  DARK_MEDIUM_GREY,
  MCKINSEY_BLUE,
} from '../../stylesheets/colors';
import NoComponentThumbnail from '../../assets/img/empty-component-title.svg';
import InfoTooltip from '../common/InfoTooltip';
import { stripHtmlTags, capitalizeFirstLetter } from '../../helpers/formattingHelpers';
import WarningInfoAlert from '../common/WarningInfoAlert';
import CustomRadio from '../UserEnrollment/common/CustomRadio';
import RedirectArrowLink from '../common/RedirectArrowLink';
import { isValidUrl } from '../../helpers/utils';

const useStyles = makeStyles({
  formLable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  width100: {
    width: '100%',
    position: 'relative',
  },
  datePickerInput: {
    width: '100%',
    '& input': {
      height: '0rem',
    },
  },
  relativeParent: {
    position: 'relative',
  },
  switchWrapper: {
    paddingBottom: '1rem',
  },
  error: {
    color: REPORT_ERROR_RED,
  },
  thumbnailImage: {
    maxWidth: '100%',
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'bottom',
  },
  required: {
    '&, &:after': {
      color: REPORT_ERROR_RED,
      content: '"*"',
    },
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
  arrowAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: '1.25rem',
    color: MODERATE_DARK_GREY,
    textTransform: 'unset',
    padding: '0.25rem 0',
  },
  subHeadingText: {
    color: DARK_MEDIUM_GREY,
  },
  subHeadingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'underline',
    color: MCKINSEY_BLUE,
  },
  chipText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
    wordBreak: 'break-word',
    fontWeight: 'normal',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '0 1rem 0rem 0px',
  },
  fldChip: {
    padding: '0.5rem 0.5rem 0.5rem 0',
    marginBottom: '1rem',
    fontWeight: 400,
  },
});

const Error = ({ errorText }) => {
  const classes = useStyles();

  return (
    <span className={classes.error}>{errorText || 'This field is required'}</span>
  );
};
Error.propTypes = {
  errorText: PropTypes.string.isRequired,
};

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const convertToSwitch = (value) => ((value === 1) ? 'On' : 'Off');

const isValue = (value) => (!!((value !== null && value !== '' && value !== undefined)));

export const ContentDetailsLabel = ({
  isRequired, label, infoText, fieldRedirectLink, fieldRedirectOnLabel,
  labelCustomCss, subHeading, subheadingVariant, subHeadingClass, subHeadingRight,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.formLable}>
        <Typography
          component="p"
          className={`${isRequired && classes.required} ${classes.label}  ${labelCustomCss}`}
        >
          {label}
        </Typography>

        {infoText.length > 0 && (
          <InfoTooltip title={infoText}>
            <InfoOutlined fontSize="medium" style={{ marginLeft: '1rem' }} />
          </InfoTooltip>
        )}
        {(fieldRedirectOnLabel && fieldRedirectLink.length > 0) && (
          <RedirectArrowLink pathname={fieldRedirectLink} />
        )}
      </Box>
      {
        subHeading && (
          <Box className={classes.subHeadingWrapper}>
            <Typography
              variant={subheadingVariant}
              component="div"
              className={`${classes.subHeadingText} ${subHeadingClass}`}
            >
              {subHeading}
            </Typography>
            {subHeadingRight && (
              <Typography
                variant={subheadingVariant}
                component="div"
                className={`${classes.subHeadingText} ${subHeadingClass}`}
              >
                {subHeadingRight}
              </Typography>
            )}

          </Box>

        )
      }
    </>
  );
};

ContentDetailsLabel.defaultProps = {
  infoText: '',
  isRequired: false,
  fieldRedirectLink: '',
  fieldRedirectOnLabel: false,
  labelCustomCss: '',
  subHeading: '',
  subheadingVariant: '',
  subHeadingClass: '',
  subHeadingRight: '',
};

ContentDetailsLabel.propTypes = {
  label: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  isRequired: PropTypes.string,
  fieldRedirectLink: PropTypes.string,
  fieldRedirectOnLabel: PropTypes.bool,
  labelCustomCss: PropTypes.string,
  subHeading: PropTypes.string,
  subheadingVariant: PropTypes.string,
  subHeadingClass: PropTypes.string,
  subHeadingRight: PropTypes.string,
};

export const ContentDetailsSection = ({
  name, label, type, value, infoText, alertText, required,
  isEdit, fieldType, handleChange, disabled, options, hasError, errorText, showTextLimit,
  maxTextLimit, addToRefs, isMulti, fieldRedirectLink, fieldRedirectOnLabel,
  havingLinkText,
}) => {
  const classes = useStyles();

  const formatDate = (date) => moment(date).format('MM/DD/YYYY');

  const handleDialogTimeChange = (newValue, fieldName) => {
    const hours = newValue
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = newValue
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const textValue = `${hours}:${minutes}`;
    handleChange(fieldName, textValue, true);
  };

  const createDateFromTextValue = (timeValue) => {
    const splitParts = timeValue && timeValue?.split(':');
    return new Date(1970, 1, 1, splitParts[0], splitParts[1]);
  };

  const getUpdatedValue = () => {
    if (isValue(value)) {
      if (fieldType === 'switch' || fieldType === 'radio') {
        if ([0, 1].includes(value)) {
          return convertToSwitch(value);
        }
        const clearedString = stripHtmlTags(value);
        return capitalizeFirstLetter(clearedString);
      }
      if (fieldType === 'calendar') {
        return formatDate(stripHtmlTags(value));
      }
      if (havingLinkText && isValidUrl(value)) {
        return (
          <a target="_blank" rel="noopener noreferrer" href={value} className={classes.link}>
            {value}
          </a>
        );
      }
      return stripHtmlTags(value);
    }
    return 'N/A';
  };

  return (
    <Grid item xs={2} sm={5} md={5}>
      <Box style={{ paddingBottom: '1rem' }}>
        <ContentDetailsLabel
          label={label}
          infoText={infoText}
          isRequired={required}
          fieldRedirectLink={fieldRedirectLink}
          fieldRedirectOnLabel={fieldRedirectOnLabel}
        />
        {!isEdit && (
          <>
            {type === 'text' && (
              <Box className={classes.arrowAlign}>
                <Typography
                  component="p"
                  style={{
                    fontStyle: 'bold', fontSize: '1.25rem', fontWeight: '800', color: ACCORDION_GREY, textTransform: 'unset', padding: '0.25rem 0', lineBreak: 'anywhere',
                  }}
                >
                  {getUpdatedValue()}
                </Typography>
                {(fieldRedirectLink.length > 0 && !fieldRedirectOnLabel) && (
                  <RedirectArrowLink pathname={fieldRedirectLink} />
                )}
              </Box>
            )}
            {type === 'image' && (
              <img src={isValue(value) ? `https:${value}` : NoComponentThumbnail} className={classes.thumbnailImage} height="60" width="60" alt="my-mage" />
            )}
            {type === 'chip' && (
              <Chip
                style={{
                  backgroundColor: MODERATE_LIGHT_GREY,
                  color: DARK_GREY,
                  fontSize: '1rem',
                  fontWeight: 500,
                  padding: '0 0.5rem',
                  borderRadius: '5px',
                }}
                label={value}
                size="small"
              />
            )}
            {type === 'chipText' && (
              <div className={classes.chipText}>
                {value.map((el, index) => (
                  <Typography component="span" key={el} className={classes.fldChip}>
                    {value.length - 1 !== index ? `${el},` : el}
                  </Typography>
                ))}
                {!value.length && 'N/A'}
              </div>
            )}
          </>
        )}

        {isEdit && (
          <>
            {fieldType === 'text' && (
              <Box className="fld-input">
                <LabledTextField
                  name={name}
                  value={value}
                  onChange={handleChange}
                  disabled={disabled}
                />
                {hasError && <Error errorText={errorText} />}
                {showTextLimit
                  && (
                    <Typography className={classes.rightText}>
                      {(value || '').length}
                      /
                      {maxTextLimit}
                    </Typography>
                  )}
              </Box>
            )}

            {fieldType === 'textarea' && (
              <Box className="fld-input">
                <LabeledTextArea
                  name={name}
                  value={value}
                  onChange={handleChange}
                  disabled={disabled}
                />
                {hasError && <Error errorText={errorText} />}
                {showTextLimit
                  && (
                    <Typography className={classes.rightText}>
                      {(value || '').length}
                      /
                      {maxTextLimit}
                    </Typography>
                  )}
              </Box>
            )}

            {fieldType === 'switch' && (
              <Box className={`${classes.formLable} ${classes.switchWrapper}`}>
                <Typography
                  component="p"
                  style={{
                    fontSize: '1.25rem', color: ACCORDION_GREY, textTransform: 'unset', padding: '0.25rem 0', marginRight: '1rem',
                  }}
                >
                  {isValue(value) ? ([0, 1].includes(value) ? convertToSwitch(value) : stripHtmlTags(value)) : '-'}
                </Typography>
                <AntSwitch
                  checked={value}
                  disabled={disabled}
                  onChange={(event) => handleChange(
                    [event.target.name], event.target.checked ? 1 : 0, true,
                  )}
                  name={name}
                />
              </Box>
            )}

            {fieldType === 'select' && (
              <div className="fld-input">
                <Select
                  name={name}
                  // defaultValue={options.find((el) => el.value === value)}
                  options={options}
                  placeholder="Select an option"
                  type="select"
                  className={`${classes.select_label}`}
                  labelClass={`${classes.labelClass}`}
                  label={required ? (
                    <Box className={classes.label}>
                      <Typography>{label}</Typography>
                      <span className={classes.red}>*</span>
                    </Box>
                  ) : label}
                  defaultValue={
                    value ? [{ value, label: value }] : null
                  }
                  selectStyles={classes.dropdown}
                  isOptionsNotLoading
                  selectRef={(el) => addToRefs(el)}
                  onChange={(v) => {
                    if (isMulti) {
                      const multiValue = v ? v.map((el) => el.value).join(',') : '';
                      handleChange(name, multiValue, true);
                    } else {
                      handleChange(name, v?.value, true);
                    }
                  }}
                  disabled={disabled}
                  isMulti={isMulti}
                />
                {hasError && <Error errorText={errorText} />}
              </div>
            )}

            {fieldType === 'calendar' && (
              <Box className={`${classes.relativeParent}`}>
                <TextField
                  className={classes.width100}
                  id="date"
                  name={name}
                  type="date"
                  variant="outlined"
                  margin="dense"
                  placeholder={value}
                  value={value}
                  onChange={(e) => handleChange(name, e.target.value, true)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {hasError && <Error errorText={errorText} />}
              </Box>
            )}

            {fieldType === 'timer' && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  name={name}
                  value={createDateFromTextValue(value)}
                  onChange={(newValue) => {
                    handleDialogTimeChange(newValue, name);
                  }}
                  renderInput={(params) => (
                    <MuiTextField
                      className={classes.datePickerInput}
                      style={{ marginTop: '0.8rem' }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            )}

            {fieldType === 'radio'
              && (
                <RadioGroup
                  row
                  className={classes.radioGroup}
                  aria-label={name}
                  name={name}
                  value={value}
                  onChange={(event, val) => handleChange(val)}
                >
                  {options.map(
                    ({
                      title, val,
                    }) => (
                      <FormControlLabel
                        key={title}
                        value={val}
                        control={<CustomRadio />}
                        label={<span className={classes.radioOptions}>{title}</span>}
                      />
                    ),
                  )}
                </RadioGroup>
              )}
          </>
        )}
        {alertText.length > 0 && (
          <WarningInfoAlert severity="info">
            {alertText}
          </WarningInfoAlert>
        )}
      </Box>
    </Grid>
  );
};

ContentDetailsSection.defaultProps = {
  infoText: '',
  alertText: '',
  disabled: false,
  name: PropTypes.string,
  isEdit: false,
  hasError: false,
  isMulti: false,
  options: [],
  errorText: '',
  required: false,
  showTextLimit: false,
  maxTextLimit: 50,
  addToRefs: () => { },
  fieldRedirectLink: '',
  fieldRedirectOnLabel: false,
  havingLinkText: false,
};

ContentDetailsSection.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  alertText: PropTypes.string,
  fieldType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isEdit: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  errorText: PropTypes.string,
  required: PropTypes.bool,
  showTextLimit: PropTypes.bool,
  maxTextLimit: PropTypes.number,
  addToRefs: PropTypes.func,
  fieldRedirectLink: PropTypes.string,
  fieldRedirectOnLabel: PropTypes.bool,
  havingLinkText: PropTypes.bool,
};
