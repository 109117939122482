import {
  KEY_LEARNING_PLAN_CREATION,
  KEY_COURSE_CREATION,
  KEY_LP_COURSE_CONNECTION,
  KEY_COHORT_GROUP_ASSIGNMENT,
  KEY_CHANNEL_CREATION,
  KEY_CATALOG_CREATION,
  KEY_ENROLLMENT_RULE_CREATION,
} from '../../../constants';

export const keyToTitleLookup = {
  [KEY_LEARNING_PLAN_CREATION]: 'Learning plan created',
  [KEY_COURSE_CREATION]: 'Course components created',
  [KEY_LP_COURSE_CONNECTION]: 'Learning plans and courses connected',
  [KEY_COHORT_GROUP_ASSIGNMENT]: 'Co-hort group assigned',
  [KEY_CHANNEL_CREATION]: 'Channels created',
  [KEY_CATALOG_CREATION]: 'Catalogues created',
  [KEY_ENROLLMENT_RULE_CREATION]: 'Enrollment rule created',
};

export const aggregrateSteps = (steps, selection) => steps.map((step) => {
  const { key } = step;
  return {
    key,
    title: keyToTitleLookup[key],
    index: key,
    success: !!step.success,
    active: key === selection,
  };
});

export const filterSteps = (steps, selection) => steps.filter((step) => step.key === selection)[0];
