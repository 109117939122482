import {
  put, call, takeLatest, takeEvery,
} from 'redux-saga/effects';
import {
  getPortfolioDataSuccess,
  getPortfolioDataFailure,
  createNewPortfolioSuccess,
  createNewPortfolioFailure,
  deletePortfolioSuccess,
  deletePortfolioFailure,
  updatePortfolioSuccess,
  updatePortfolioFailure,
  getPortfolioOfferingDataSuccess,
  getPortfolioOfferingDataFailure,
} from '../portfolioMasterData';
import {
  fetchPortfolioData,
  addNewPortfolio,
  deletePortfolio,
  updatePortfolio,
  fetchPortfolioAndOffering,
} from '../async/masterdataAsynch';

import {
  PORTFOLIO_DATA_FETCH,
  ADD_NEW_PORTFOLIO,
  DELETE_PORTFOLIO,
  UPDATE_PORTFOLIO,
  GET_PORTFOLIO_OFFERING_LIST,
} from '../../constants';

export function* getPortfolioData({ data }) {
  try {
    const response = yield call(fetchPortfolioData, data);
    yield put(getPortfolioDataSuccess(response.data));
  } catch (err) {
    yield put(getPortfolioDataFailure(err.response.data));
  }
}

export function* getPortOfferingsList({ data }) {
  try {
    const response = yield call(fetchPortfolioAndOffering, data);
    yield put(getPortfolioOfferingDataSuccess(response.data));
  } catch (err) {
    yield put(getPortfolioOfferingDataFailure(err.response.data));
  }
}

export function* createNewPortfolio({ data }) {
  try {
    const response = yield call(addNewPortfolio, data);
    yield put(createNewPortfolioSuccess(response.data));
  } catch (err) {
    yield put(createNewPortfolioFailure(err.response.data));
  }
}

export function* updatePortfolioData({ data }) {
  try {
    const response = yield call(updatePortfolio, data);
    yield put(updatePortfolioSuccess(response.data));
  } catch (err) {
    yield put(updatePortfolioFailure(err.response.data));
  }
}

export function* deletePortfolioData({ id }) {
  try {
    const response = yield call(deletePortfolio, id);
    yield put(deletePortfolioSuccess(response.data));
  } catch (err) {
    yield put(deletePortfolioFailure(err.response.data));
  }
}

export function* portfolioDataSaga() {
  yield takeLatest(PORTFOLIO_DATA_FETCH, getPortfolioData);
  yield takeLatest(ADD_NEW_PORTFOLIO, createNewPortfolio);
  yield takeLatest(DELETE_PORTFOLIO, deletePortfolioData);
  yield takeLatest(UPDATE_PORTFOLIO, updatePortfolioData);
  yield takeEvery(GET_PORTFOLIO_OFFERING_LIST, getPortOfferingsList);
}
