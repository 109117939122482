import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { MODERATE_LIGHT_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    padding: '2rem 4rem',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  heading: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    textTransform: 'unset',
  },
  flyoutWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  headingWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Header = ({
  className, heading, subHeading, children, childrenWrapper, customSubHeadingClass,
}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.wrapper} ${className}`}>
      <div className={classes.headingWrapper}>
        <Typography variant="h4" className={classes.heading}>{heading}</Typography>
        <Typography variant="subtitle1" className={customSubHeadingClass}>{subHeading}</Typography>
      </div>
      <div className={`${classes.flyoutWrapper} ${childrenWrapper}`}>
        {children}
      </div>
    </Box>

  );
};

Header.defaultProps = {
  className: '',
  children: '',
  childrenWrapper: '',
  subHeading: '',
  customSubHeadingClass: '',
};

Header.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  childrenWrapper: PropTypes.string,
  children: PropTypes.node,
  customSubHeadingClass: PropTypes.string,
};

export default memo(Header, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
    && prevProps.heading === nextProps.heading && prevProps.subHeading === nextProps.subHeading
    && prevProps.children === nextProps.children
));
