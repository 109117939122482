import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import _map from 'lodash/map';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@material-ui/core/Chip';
import Stack from '@mui/material/Stack';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditMetadata from './EditMetadata';
import BulkEditMetadata from './BulkEditMetadata';
import { DOCEBO_METADATA_LOOKUP, EDIT_METADATA_OPTIONS, METADATA_TABLE_ATTRIBUTES } from '../../constants';
import {
  ACCORDION_GREY, MODERATE_LIGHT_GREY, MODERATE_DARK_GREY, PIE_CHART_BLACK, WHITE,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  textColor: {
    color: ACCORDION_GREY,
  },
  headingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    color: ACCORDION_GREY,
  },
  courseRunnerWrapper: {
    padding: '0.2rem',
    flexWrap: 'wrap',
    maxWidth: '45rem',
    alignItems: 'center',
    height: 'auto',
  },
  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',
  },
});
const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
};

const MetaDataCourses = ({
  componentsMetaData,
  fetchMetadataCourses,
  onUpdateMetadata,
  bulkUpdateMetadata,
  isLoading,
  doceboLpId,
}) => {
  const [tableData, setTableData] = useState([]);
  const [rowData, setRowData] = useState({});
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [editMetadataModal, setEditMetadataModal] = useState(false);
  const [bulkEditMetadataModal, setBulkEditMetadataModal] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [status, setStatus] = useState('');
  const classes = useStyles();

  const onUpdateStatus = (row) => {
    setRowData(row);
    setEditMetadataModal(true);
  };

  const closeEditMetadataModal = () => {
    setEditMetadataModal(false);
  };

  const closeBulkEditMetadataModal = () => {
    setBulkEditMetadataModal(false);
  };

  const preUpdateMetadata = (values) => {
    onUpdateMetadata(values, closeEditMetadataModal);
  };

  const onBulkUpdate = (formValues) => {
    bulkUpdateMetadata(formValues, _map(selectedCourses, 'id'), closeBulkEditMetadataModal);
  };

  const getActionIcon = () => (
    <IconButton
      aria-label="more"
      aria-haspopup="true"
    >
      <MoreVertIcon />
    </IconButton>
  );

  const getCourseRunnerStack = (courseRunner) => (
    (courseRunner.length > 0)
    && (
      <Stack direction="row" className={classes.courseRunnerWrapper}>
        {
          courseRunner.map((element) => (
            <Chip
              key={element}
              label={element}
              style={{
                color: WHITE,
                backgroundColor: PIE_CHART_BLACK,
                marginRight: '0.2rem',
                marginBottom: '0.2rem',
                borderRadius: '0.5px',
              }}
              size="small"
            />
          ))
        }
      </Stack>
    )
  );

  useEffect(() => {
    fetchMetadataCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doceboLpId]);

  useEffect(() => {
    const formattedData = _map(componentsMetaData, (data) => {
      const temp = {};
      temp.courseName = data.name;
      temp.id = data.id;
      temp.code = data.code;
      _each(data.metadata, (component) => {
        temp[component.slug] = component.value;
      });
      return temp;
    });
    setTableData(formattedData);
    setSelectedCourses([]);
  }, [componentsMetaData]);

  return (
    <Box sx={{ padding: '2rem 0' }}>
      <Box className={classes.headingWrapper}>
        <Typography variant="subtitle2">Live data from Docebo</Typography>
        <Button
          variant="text"
          color="primary"
          style={{ padding: 0 }}
          onClick={() => fetchMetadataCourses(true)}
        >
          Refresh

        </Button>
        <Typography variant="subtitle2">for updated results</Typography>
      </Box>
      <Box className={classes.contentWrapper}>
        <Typography variant="body1">General metadata settings for Courses and ILTs in this Learning Plan</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setBulkEditMetadataModal(true)}
          disabled={!selectedCourses.length}
        >
          Edit metadata
        </Button>
      </Box>
      {isLoading ? <Loading /> : (
        <MaterialTable
          components={{
            // eslint-disable-next-line react/jsx-props-no-spreading
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          title=""
          columns={METADATA_TABLE_ATTRIBUTES.map(
            (attr) => {
              if (attr === 'course-runner') {
                return ({
                  title: DOCEBO_METADATA_LOOKUP[attr],
                  field: attr,
                  render: (row) => row['course-runner'] && row['course-runner'] !== null
                    && getCourseRunnerStack(row['course-runner'].indexOf(';') > -1
                      ? row['course-runner'].split(';') : [row['course-runner']]),
                });
              }
              return ({
                title: DOCEBO_METADATA_LOOKUP[attr],
                field: attr,
              });
            },
          )}
          data={tableData}
          actions={
            [
              {
                position: 'row',
                action: () => ({
                  icon: () => getActionIcon(),
                  tooltip: 'View/Edit metadata',
                  onClick: (e, row) => onUpdateStatus(row),
                }),
              },
            ]
          }
          options={{
            selection: true,
            selectionProps: () => ({
              color: 'primary',
            }),
            rowStyle: {
              fontSize: '1.125rem',
              color: MODERATE_DARK_GREY,
            },
            search: false,
            toolbar: false,
            paging: false,
            sorting: false,
            actionsColumnIndex: 6,
            headerStyle: {
              backgroundColor: MODERATE_LIGHT_GREY,
            },
          }}
          onSelectionChange={(rows) => setSelectedCourses(rows)}
        />
      )}
      <EditMetadata
        open={editMetadataModal}
        onClose={closeEditMetadataModal}
        courseName={tableData.length && tableData[0].courseName}
        data={rowData}
        options={EDIT_METADATA_OPTIONS}
        isLoading={isLoading}
        updateMetadata={preUpdateMetadata}
        isValidating={isValidating}
        setIsValidating={setIsValidating}
        status={status}
        setStatus={setStatus}
      />
      <BulkEditMetadata
        open={bulkEditMetadataModal}
        onClose={closeBulkEditMetadataModal}
        selectedData={_map(selectedCourses, 'courseName')}
        options={EDIT_METADATA_OPTIONS}
        isLoading={isLoading}
        onBulkUpdate={onBulkUpdate}
        isValidating={isValidating}
        setIsValidating={setIsValidating}
        status={status}
        setStatus={setStatus}
      />
    </Box>
  );
};

MetaDataCourses.propTypes = {
  componentsMetaData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      metadata: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          slug: PropTypes.string.isRequired,
          key: PropTypes.string.isRequired,
          value: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
  fetchMetadataCourses: PropTypes.func.isRequired,
  onUpdateMetadata: PropTypes.func.isRequired,
  bulkUpdateMetadata: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  doceboLpId: PropTypes.string.isRequired,
};

export default MetaDataCourses;
