import React from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormattedTypography from '../common/FormattedTypography';

import {
  DARK_MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  helpingHeaderText: {
    color: DARK_MEDIUM_GREY,
  },
});

const ModalHeader = ({ title, fileName, helpingText }) => {
  const classes = useStyles();

  return (
    <DialogTitle>
      <FormattedTypography
        body={title}
        subHeading={fileName.length > 0 && (
          <>
            Uploaded File
            <Typography component="span" style={{ fontSize: '1.5rem', fontWeight: '800', marginLeft: '0.25rem' }}>
              {`'${fileName}'`}
            </Typography>
          </>
        )}
        helperNode={(
          <Typography variant="subtitle1" align="center" className={classes.helpingHeaderText}>
            {helpingText}
          </Typography>
        )}
      />
    </DialogTitle>
  );
};

ModalHeader.defaultProps = {
  fileName: '',
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  helpingText: PropTypes.string.isRequired,

};

export default ModalHeader;
