import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import { zeroAppender } from '../../helpers/formattingHelpers';
import { SCORMSELECT } from '../../constants';
import {
  ACCORDION_GREY, BLACK, DARK_GREY, FILL_ERROR_RED, MODERATE_LIGHT_GREY, USER_CIRCLE_DARK_BG,
} from '../../stylesheets/colors';
import { getMaterialType } from '../../helpers/utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addMargin: {
    marginTop: '2rem',
  },
  width100: {
    width: '100%',
  },
  subHeading: {
    fontWeight: 800,
  },
  contentHeadingHelper: {
    padding: '1.5rem 0',
    borderBottom: `1px solid ${ACCORDION_GREY}`,
  },
  contentWrapper: {
    padding: '1.5rem 0',
  },
  contentListWrapper: {
    width: '100%',
    padding: '1.5rem 0',
  },
  contentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  infoContentText: {
    color: BLACK,
    wordWrap: 'break-word',
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  tmWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  updateTmDetail: {
    paddingTop: '1rem',
  },
}));

const ReplicationCourseReview = ({
  course,
}) => {
  const classes = useStyles();

  const [collapseOpen, setCollapseOpen] = useState(false);

  const handleClick = () => {
    setCollapseOpen(!collapseOpen);
  };

  return (
    <>
      <ListItem alignItems="flex-start" sx={{ padding: '0' }} key={course}>
        <Box className={classes.contentListWrapper}>
          <Box>
            <Typography variant="subtitle2" className={classes.infoHelperText}>
              Component:
              <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>{course?.course_name}</Typography>
            </Typography>
          </Box>
          <Box className={classes.contentList}>
            <Typography variant="subtitle2" className={classes.infoHelperText}>
              Component Code:
              <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>{course?.course_code}</Typography>
            </Typography>
            <Stack direction="row" spacing={1}>
              {course?.lo?.length > 0 && (
                <Chip
                  style={{
                    backgroundColor: MODERATE_LIGHT_GREY,
                    color: DARK_GREY,
                    fontSize: '1rem',
                    fontWeight: 800,
                    padding: '0 0.5rem',
                  }}
                  label={`${course?.lo.length} Training Materials`}
                  size="small"
                />
              )}
              {course?.selected === false && (
                <Chip
                  style={{
                    color: FILL_ERROR_RED,
                    borderColor: FILL_ERROR_RED,
                    fontSize: '1rem',
                    fontWeight: 500,
                  }}
                  label="Excluded"
                  size="small"
                  variant="outlined"
                />
              )}
              {course?.lo?.length > 0 && (
                <IconButton color="primary" aria-label="add to shopping cart" onClick={() => handleClick()}>
                  {collapseOpen ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
            </Stack>
          </Box>
        </Box>
      </ListItem>
      <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
        <Typography variant="subtitle2" className={`${classes.infoHelperText}`}>Training Materials</Typography>
        <List component="div" disablePadding>
          {course?.lo?.map((loObj, index) => {
            const { label, icon } = getMaterialType(loObj);
            return (
              <ListItem alignItems="flex-start" sx={{ px: '0' }} key={loObj}>
                <Box className={classes.tmWrapper}>
                  <Box className={classes.contentList} style={{ width: '100%' }}>
                    <Typography variant="subtitle2">
                      {`${zeroAppender(index + 1)}:`}
                      <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                        {loObj?.name}
                      </Typography>
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {course.selected === true && loObj.selected === false ? (
                        <Chip
                          style={{
                            color: FILL_ERROR_RED,
                            borderColor: FILL_ERROR_RED,
                            fontSize: '1rem',
                            fontWeight: 500,
                          }}
                          label="Excluded"
                          size="small"
                          variant="outlined"
                        />
                      ) : null}
                      <Chip
                        style={{
                          backgroundColor: MODERATE_LIGHT_GREY,
                          color: BLACK,
                          fontWeight: 800,
                          fontSize: '1rem',
                          padding: '0 0.5rem',
                        }}
                        icon={<img src={icon} alt="lo-type-icon" />}
                        label={label}
                        size="small"
                      />
                    </Stack>
                  </Box>
                  {loObj?.elucidatSelect && (
                    <Box className={`${classes.contentList} ${classes.updateTmDetail}`} style={{ width: '100%' }}>
                      <Typography variant="subtitle2" className={classes.infoHelperText} style={{ paddingLeft: '2rem' }}>
                        SCORM ID:
                        <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                          {`${SCORMSELECT[loObj?.elucidatSelect]} Project ID : ${loObj.elucidatInput}`}
                        </Typography>
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        {(course.selected === true && loObj?.elucidatSelect) && (
                          <Chip
                            style={{
                              color: USER_CIRCLE_DARK_BG,
                              borderColor: USER_CIRCLE_DARK_BG,
                              fontSize: '1rem',
                              fontWeight: 500,
                            }}
                            label="Updated"
                            size="small"
                            variant="outlined"
                          />
                        )}
                      </Stack>
                    </Box>
                  )}
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <Divider component="li" />
    </>
  );
};

export default ReplicationCourseReview;

ReplicationCourseReview.propTypes = {
  course: PropTypes.shape({
    course_id: PropTypes.number.isRequired,
    course_name: PropTypes.string.isRequired,
    course_code: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    lo: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        original_project_id: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.oneOf([null]),
        ]),
        project_id_status: PropTypes.string.isRequired,
        project_name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};
