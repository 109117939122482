import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import FileInput from '../FileInput';
import MainButton from '../MainButton';
import Header from '../common/Header';
import Select from '../common/SelectMui';
import commonStyles from '../../stylesheets/common.styles';
import { MCKINSEY_BLUE, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const { requiredMark } = commonStyles;
const useStyles = makeStyles({
  wrapper: {
    flex: 1,
  },
  formWrapper: {
    flex: 1,
    padding: '0 2rem',
  },
  pageHeader: {
    padding: '1rem 1.25rem 1.25rem 2rem',
  },
  reasonSection: {
    marginTop: '1.2rem',
    width: '25rem',
  },
  fileUploadSection: {
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
  downloadTemplateSection: {
    '& > p': {
      color: MODERATE_DARK_GREY,
    },
    '& > button': {
      marginTop: '0.75rem',
    },
  },
  requiredMark,
});

const unenrollmentOptions = [
  { label: 'Mistakenly enrolled', value: 'Mistakenly enrolled' },
  { label: 'End of program', value: 'End of program' },
  { label: 'Participant(s) disengaged', value: 'Participant(s) disengaged' },
  { label: 'Client(s) / Participant(s) request', value: 'Client(s) / Participant(s) request' },
];

const UserUnenrollmentOperationsPage = ({
  onUpload,
  onDownloadSample,
  showAlertBar,
}) => {
  const classes = useStyles();
  const [unenrollReason, setUnenrollReason] = useState('');

  const disableUpload = !unenrollReason || showAlertBar;

  return (
    <Paper className={classes.wrapper}>
      <Header heading="User Unenrollment" subHeading="Fill the field below before uploading your xls file for faster processing" className={classes.pageHeader} />
      <Box className={classes.formWrapper}>
        <Box className={classes.reasonSection}>
          <Box>
            <Typography variant="body1" component="span">Select a reason for Unenrollment</Typography>
            <Typography variant="h3" component="span" className={classes.requiredMark}>*</Typography>
          </Box>
          <Select
            defaultValue={unenrollmentOptions.find((el) => el.value === unenrollReason)}
            options={unenrollmentOptions}
            onChange={(v) => setUnenrollReason(v.value)}
            isSearchable={false}
            placeholder="Select a reason"
          />
        </Box>
        <Divider light flexItem sx={{ mt: '1.2rem', mb: '1.2rem' }} />
        <Box className={classes.fileUploadSection}>
          <FileInput
            idx={0}
            onFileInput={(e) => { onUpload(e, unenrollReason); }}
            isDisabled={disableUpload}
          >
            <MainButton
              isDisabled={disableUpload}
              title="Upload XLS file"
              subtitle="We will upload the users to update group enrollments for this LP"
            />
          </FileInput>
        </Box>
        <Divider light flexItem sx={{ mt: '1.2rem', mb: '1.2rem' }} />
        <Box className={classes.downloadTemplateSection}>
          <Typography variant="body1">Need help configuring the file? Download the template below.</Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={onDownloadSample}
          >
            Download Sample Template
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

UserUnenrollmentOperationsPage.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onDownloadSample: PropTypes.func.isRequired,
  showAlertBar: PropTypes.bool.isRequired,
};

export default UserUnenrollmentOperationsPage;
