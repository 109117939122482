import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { MCKINSEY_BLUE, MODERATE_DARK_GREY } from '../../../stylesheets/colors';
import { reportPageDateTimeFormatter } from '../../../helpers/formattingHelpers';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontFamily: 'McKinseySans',
  },
  titleRow: {
    fontSize: '1.25rem',
  },
  lastUpdateRow: {
    fontSize: '1rem',
    color: MODERATE_DARK_GREY,
  },
  refreshButton: {
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
  },
});

const RefreshSection = ({
  onRefresh, lastRefreshed,
}) => {
  const classes = useStyles();

  const formattedLastRefreshed = lastRefreshed ? reportPageDateTimeFormatter(new Date(lastRefreshed)) : 'Loading...';

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleRow}>
        Live data from Docebo.
        <button onClick={onRefresh} className={classes.refreshButton} type="button">Refresh</button>
        for updated results
      </div>
      <div className={classes.lastUpdateRow}>
        Last update:
        {' '}
        {formattedLastRefreshed}
      </div>
    </div>
  );
};

RefreshSection.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  lastRefreshed: PropTypes.string.isRequired,
};

export default RefreshSection;
