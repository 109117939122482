import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { cloneDeep } from 'lodash/lang';
import _findIndex from 'lodash/findIndex';
import GroupMemberTable from './GroupMemberTable';
import { HEADER_SEPARATOR, MCKINSEY_BLUE, MEDIUM_GREY } from '../../../stylesheets/colors';
import EditGroupDetailsModal from './EditGroupDetailsModal';
import MoveGroupMemberModal from './MoveGroupMemberModal';
import MakeGCModal from './MakeGCModal';
import {
  editGroupDetails,
  makeMemberGc,
  moveMemberAcrossGroup,
  removeMemberFromGroup,
} from '../../../containers/GroupWorkPage/apis';
import { getErrorMessage } from '../../../helpers/apiHelper';
import accordianStyles from '../../../stylesheets/accordian.styles';
import UnAssignGCModal from './UnAssignGCModal';
import RemoveMemberModal from './RemoveMemberModal';

const useStyles = makeStyles(() => ({
  ...accordianStyles,
  textContent: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',

    '& > span:nth-child(1)': {
      whiteSpace: 'pre',
    },

    '& > span': {
      userSelect: 'text',
      cursor: 'text',
    },
  },
  taInfo: {
    color: MEDIUM_GREY,
    marginLeft: '1.2rem',
    paddingLeft: '1.2rem',
    borderLeft: `1px solid ${HEADER_SEPARATOR}`,
  },
  noMemberTag: {
    fontWeight: 600,
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MCKINSEY_BLUE}`,
    borderRadius: '10px',
    color: MCKINSEY_BLUE,
  },
  editGroupBtn: {
    fontWeight: 'bold',
    marginLeft: '1.2rem',
    paddingLeft: '1.2rem',
    borderLeft: `1px solid ${HEADER_SEPARATOR}`,
    lineHeight: '0.5rem',
  },
  accordianMetadataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));

const GroupsTableRow = ({
  group, setSnackbarObj, setAlertBarConfig, getGroups, setGroups, groupIndex, programId,
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [showEditTaModal, setShowEditTaModal] = useState(false);
  const [showMoveMemberModal, setShowMoveMemberModal] = useState(false);
  const [showMakeGCModal, setShowMakeGCModal] = useState(false);
  const [showUnassignGCModal, setShowUnassignGCModal] = useState(false);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [modifiedMember, setModifiedMember] = useState({});

  const {
    id, group_members, group_name, ta_name, ta_email, group_number: groupNumber,
  } = group;
  const canExpand = group_members.length;

  const onEditGroupInfo = async (taDetails) => {
    try {
      const { name, email } = taDetails;
      const groupClone = cloneDeep(group);
      groupClone.ta_name = name;
      groupClone.ta_email = email;

      const payload = {
        program_id: programId,
        group_number: groupNumber,
        email,
        name,
      };
      const resp = await editGroupDetails({ data: payload });
      const { success } = resp;
      const alertBarMessage = success ? `TA Name and TA Email are now updated for ${group_name} across all assignments`
        : `TA Name and TA Email were not able to be updated for ${group_name}. Please try again later.`;
      setAlertBarConfig({
        labelText: alertBarMessage,
        variant: `${success ? 'success' : 'error'}`,
        open: true,
      });
      if (success) {
        setGroups(groupClone, groupIndex);
      }
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.log(e);
    }
  };

  const onMoveMember = async (selectedGroup) => {
    try {
      const { name, email } = selectedMember;
      const { group_name: groupName, group_number, id: newGroupId } = selectedGroup;
      const payload = {
        program_id: programId,
        new_group_number: group_number,
        old_group_number: groupNumber,
        new_group_name: groupName,
        name,
        email,
        id: newGroupId,
      };
      const resp = await moveMemberAcrossGroup({ data: payload });
      await getGroups();
      const { success, message } = resp;
      let alertBarMessage;
      if (message) {
        alertBarMessage = message;
      } else {
        alertBarMessage = success ? `Member "${name}" has been successfully moved to ${groupName}`
          : `Member "${name}" failed to move to ${groupName}`;
      }
      setAlertBarConfig({
        labelText: alertBarMessage,
        variant: `${success ? 'success' : 'error'}`,
        open: true,
      });
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.log(e);
    }
  };

  const onMakeGC = async () => {
    try {
      const { name, email } = selectedMember;
      const payload = {
        program_id: programId,
        group_number: groupNumber,
        email,
        gc_assign: true,
      };
      const resp = await makeMemberGc({ data: payload });
      const { success } = resp;
      const alertBarMessage = success ? `Member "${name}" has been made the Group Co-ordinator (GC) for ${group_name}`
        : `Member "${name}" failed to update as the new Group Co-ordinator (GC) for ${group_name}`;
      setAlertBarConfig({
        labelText: alertBarMessage,
        variant: `${success ? 'success' : 'error'}`,
        open: true,
      });
      if (success) {
        const groupClone = cloneDeep(group);// copy groups
        const groupMembersClone = cloneDeep(group_members);// copy members
        groupMembersClone.map((member) => { // make every member non GC except the modified one
          const { email: memberEmail } = member;
          member.is_gc = memberEmail === email;
          return member;
        });
        groupClone.group_members = groupMembersClone;// assign new group members to group
        setGroups(groupClone, groupIndex);
        setModifiedMember(selectedMember);
      }
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.log(e);
    }
  };

  const onUnAssignGC = async () => {
    try {
      const { name, email } = selectedMember;
      const payload = {
        program_id: programId,
        group_number: groupNumber,
        email,
        gc_assign: false,
      };
      const resp = await makeMemberGc({ data: payload });
      const { success } = resp;
      const alertBarMessage = success ? `Member '${name}' has been removed as the Group Co-ordinator (GC) for ${group_name}`
        : `Member '${name}' failed to be removed as the Group Co-ordinator (GC) for ${group_name}`;

      setAlertBarConfig({
        labelText: alertBarMessage,
        variant: `${success ? 'success' : 'error'}`,
        open: true,
      });
      if (success) {
        const groupClone = cloneDeep(group);// copy groups
        const groupMembersClone = cloneDeep(group_members);// copy members
        groupMembersClone.map((member) => { // make every member non GC
          member.is_gc = false;
          return member;
        });
        groupClone.group_members = groupMembersClone;// assign new group members to group
        setGroups(groupClone, groupIndex);
        setModifiedMember(selectedMember);
      }
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.log(e);
    }
  };

  const onRemoveMember = async () => {
    try {
      const { name, email } = selectedMember;
      const payload = {
        program_id: programId,
        group_number: groupNumber,
        email,
      };
      const resp = await removeMemberFromGroup({ data: payload });
      const { success } = resp;
      const alertBarMessage = success ? `Member '${name}' has been removed from ${group_name}`
        : `Member '${name}' failed to be removed from ${group_name}. Please try again later!`;

      setAlertBarConfig({
        labelText: alertBarMessage,
        variant: `${success ? 'success' : 'error'}`,
        open: true,
      });
      if (success) {
        const groupClone = cloneDeep(group);// copy groups
        const groupMembersClone = cloneDeep(group_members);// copy members
        // find and pop the removed member from members array
        const deletedMemberIndex = _findIndex(groupMembersClone, { email });
        if (deletedMemberIndex >= 0) {
          groupMembersClone.splice(deletedMemberIndex, 1);
        }
        groupClone.group_members = groupMembersClone;// assign new group members to group
        setGroups(groupClone, groupIndex);
        setModifiedMember(selectedMember);
      }
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
      console.log(e);
    }
  };

  const handleMoveMemberClick = (selectedMemberObj) => {
    batchUpdates(() => {
      setSelectedMember(selectedMemberObj);
      setShowMoveMemberModal(true);
    });
  };

  const handleUnassignGcClick = (selectedMemberObj) => {
    batchUpdates(() => {
      setSelectedMember(selectedMemberObj);
      setShowUnassignGCModal(true);
    });
  };

  const handleRemoveMemberClick = (selectedMemberObj) => {
    batchUpdates(() => {
      setSelectedMember(selectedMemberObj);
      setShowRemoveMemberModal(true);
    });
  };

  const handleMakeGcClick = (selectedMemberObj) => {
    batchUpdates(() => {
      setSelectedMember(selectedMemberObj);
      setShowMakeGCModal(true);
    });
  };
  const getTAJsx = useCallback(() => {
    let taJSX = null;
    if (ta_name && ta_email) {
      taJSX = `TA: ${ta_name} (${ta_email})`;
    }
    if (ta_name && !ta_email) {
      taJSX = `TA: ${ta_name}`;
    }
    if (!ta_name && ta_email) {
      taJSX = `TA: (${ta_email})`;
    }
    if (!ta_name && !ta_email) {
      taJSX = 'TA: Not Assigned';
    }
    return taJSX;
  }, [ta_email, ta_name]);

  return (
    <>
      {showEditTaModal && (
        <EditGroupDetailsModal
          open={showEditTaModal}
          onClose={() => setShowEditTaModal(false)}
          onSave={onEditGroupInfo}
          group={group}
        />
      )}
      {showMoveMemberModal && (
        <MoveGroupMemberModal
          open={showMoveMemberModal}
          onClose={() => setShowMoveMemberModal(false)}
          onSave={onMoveMember}
          selectedMember={selectedMember}
          selectedMemberGroup={group}
          programId={programId}
          setSnackbarObj={setSnackbarObj}
        />
      )}
      {showMakeGCModal && (
      <MakeGCModal
        open={showMakeGCModal}
        onClose={() => setShowMakeGCModal(false)}
        onSave={onMakeGC}
        selectedMember={selectedMember}
        selectedMemberGroup={group}
      />
      )}
      {showUnassignGCModal && (
      <UnAssignGCModal
        open={showUnassignGCModal}
        onClose={() => setShowUnassignGCModal(false)}
        onSave={onUnAssignGC}
        selectedMember={selectedMember}
        selectedMemberGroup={group}
      />
      )}
      {showRemoveMemberModal && (
      <RemoveMemberModal
        open={showRemoveMemberModal}
        onClose={() => setShowRemoveMemberModal(false)}
        onSave={onRemoveMember}
        selectedMember={selectedMember}
        selectedMemberGroup={group}
      />
      )}
      <Accordion
        className="accordionRoot"
        classes={{ root: classes.accordion, expanded: classes.expanded }}
        expanded={expand}
        square
        data-testid="expandIcon"
      >
        <AccordionSummary
          expandIcon={canExpand ? (
            <ExpandMoreIcon
              onClick={() => canExpand && setExpand((prevState) => !prevState)}
            />
          )
            : <ExpandMoreIcon style={{ visibility: 'hidden' }} />}
          className="accordionSummaryRoot"
          classes={{
            root: classes.accordionSummary,
            content: classes.accordionSummaryContent,
            expanded: classes.accordionSummaryExpanded,
          }}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <Box className={classes.textContent}>
            <Typography variant="subtitle2" component="span">{group_name}</Typography>
            <Typography variant="h3" component="span" className={classes.taInfo}>
              {getTAJsx()}
            </Typography>
          </Box>
          <Box className={classes.accordianMetadataWrapper}>
            <Box>
              {group_members.length < 1 && (
                <>
                  <Typography variant="h2" component="span" className={classes.noMemberTag}>
                    No members added
                  </Typography>
                </>
              )}
              <Button variant="textSecondary" onClick={() => { setShowEditTaModal(true); }} className={classes.editGroupBtn}>Edit Group Details</Button>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="accordionSummaryDetail" classes={{ root: classes.detail }}>
          <GroupMemberTable
            group_members={group_members}
            handleMoveMemberClick={handleMoveMemberClick}
            handleMakeGcClick={handleMakeGcClick}
            handleUnassignGcClick={handleUnassignGcClick}
            modifiedMember={modifiedMember}
            handleRemoveMemberClick={handleRemoveMemberClick}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

GroupsTableRow.propTypes = {
  group: PropTypes.object.isRequired,
  setSnackbarObj: PropTypes.func.isRequired,
  setAlertBarConfig: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  setGroups: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
};

export default memo(GroupsTableRow);
