import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Header from '../common/Header';
import FlyoutMenu from '../common/FlyoutMenu';

import { DARK_MEDIUM_GREY, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const content = [
  'Please download the existing Metadata for components after selecting few Programs. You can use the same file to upload it back after making the required changes',
  'Please download the template (with column headers only) and upload back after filling in the details.',
  'Guidelines to fill the Template:',
];
const Subcontent = [
  'The first two columns are mandatory.',
  'Please fill the Learning Plan Config Id in the first Column & Component Code in the second column',
  'Only the filled values be updated. All blank fields are ignored and nothing is updated. Hence you can keep the column blanks if you don’t want to update those fields.',
];
const useStyles = makeStyles({
  menuItems: {
    whiteSpace: 'normal',
    lineHeight: 2,
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  menusubItems: {
    whiteSpace: 'normal',
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  listItemIcon: {
    minWidth: '2.5rem',
    marginTop: '0.8rem',
    alignSelf: 'flex-start',
    color: MODERATE_DARK_GREY,
  },
  sublistItemIcon: {
    minWidth: '2.5rem',
    padding: '0 1rem 0 1.5rem',
  },
  menuSubtitleMedGrey: {
    color: DARK_MEDIUM_GREY,
  },
});

const PlatformHeader = ({ className }) => {
  const classes = useStyles();
  return (
    <Header
      className={className}
      heading="Component Metadata"
      subHeading="Edit component metadata across multiple Learning Plans"
    >
      <FlyoutMenu
        buttonTitle="Instructions"
        subTitle="This feature can be used to update the Components Additional Fields for Multiple LPs at a time"
        initialState={className}
        classes={{
          subtitle: classes.menuSubtitleMedGrey,
        }}
      >
        {content.map((item) => (
          <MenuItem className={classes.menuItems} key={`content-${item}`}>
            <ListItemIcon className={classes.listItemIcon}>
              <FiberManualRecordIcon style={{ fontSize: '0.5625rem' }} />
            </ListItemIcon>
            <Typography variant="inherit">{item}</Typography>
          </MenuItem>
        ))}
        {Subcontent.map((item) => (
          <MenuItem className={classes.menusubItems} key={`content-${item}`}>
            <ListItemIcon className={classes.sublistItemIcon}>
              <TrendingFlatIcon style={{ fontSize: '1.5rem' }} />
            </ListItemIcon>
            <Typography variant="inherit">{item}</Typography>
          </MenuItem>
        ))}
      </FlyoutMenu>
    </Header>

  );
};

PlatformHeader.defaultProps = {
  className: '',
};

PlatformHeader.propTypes = {
  className: PropTypes.string,
};

export default memo(PlatformHeader, (
  (prevProps, nextProps) => prevProps.className === nextProps.className
));
