import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useField } from '@unform/core';
import { LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';
import theme from '../../stylesheets/theme';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  labelWrapper: {
    display: 'flex',
    flex: 0.25,
    fontWeight: 'bold',
    alignItems: 'flex-end',
  },
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    maxHeight: '2.5rem',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
});

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: '32px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: 'unset',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
};

const Select = ({
  name, label, options, placeholder, maxMenuHeight, onChange,
}) => {
  const selectRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  const classes = useStyles();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => ref.state.value,
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
      clearValue: (ref) => {
        ref.select.clearValue();
      },
    });
  }, [fieldName, registerField]);

  const isLoading = !options.length;
  return (
    <div className={classes.wrapper}>
      <Typography variant="body1" className={classes.labelWrapper}>
        {label}
      </Typography>
      <div className={classes.selectWrapper}>
        <ReactSelect
          ref={selectRef}
          styles={selectStyles}
          placeholder={placeholder}
          options={options}
          defaultValue={defaultValue}
          onChange={onChange}
          isSearchable
          isLoading={isLoading}
          maxMenuHeight={maxMenuHeight}
          menuPortalTarget={document.body}
        />
      </div>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  maxMenuHeight: PropTypes.number,
  name: PropTypes.string,
};

Select.defaultProps = {
  maxMenuHeight: 300,
  placeholder: '',
  name: 'select-fld',
  label: '',
};

export default Select;
