import omit from 'lodash/omit';
import axios from 'axios';

import config from '../../config';
import request from '../../helpers/apiHelper';

export const getContentsFromElucidat = async (searchText, type, cancelToken) => {
  const url = `${config.ROUTES.GET_ELUCIDAT_COURSE_CONTENT}?search_text=${encodeURIComponent(searchText)}&type=${type}`;
  const options = { url, method: 'GET', cancelToken };
  try {
    const { data } = await request(options);
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return { aborted: true };
    }
    console.error(e);
    return e.response.data;
  }
};

export const getContentsFromDocebo = async (searchText, type, cancelToken) => {
  const url = `${config.ROUTES.GET_DOCEBO_COURSE_CONTENT}?search_text=${encodeURIComponent(searchText)}&type=${type}`;
  const options = { url, method: 'GET', cancelToken };
  try {
    const { data } = await request(options);
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return { aborted: true };
    }
    console.error(e);
    return e.response.data;
  }
};

export const postAssignedContent = async (
  transactionId,
  courseId,
  selectedElucidatContent,
  selectedDoceboContent,
) => {
  const contentCleaner = (content) => omit(content, ['id', 'title', 'checked', 'releases']);
  const typeToLowerCase = (c) => ({ ...c, type: `${c.type}`.toLowerCase() });

  const formattedElucidatContents = selectedElucidatContent
    .map(contentCleaner)
    .map(typeToLowerCase);
  const formattedDoceboContents = selectedDoceboContent.map(typeToLowerCase);
  const url = `${config.ROUTES.POST_COURSE_INFO}/${transactionId}/${courseId}`;
  const options = {
    url,
    method: 'POST',
    data: { elucidat: formattedElucidatContents, docebo: formattedDoceboContents },
  };
  try {
    const { data } = await request(options);
    return data;
  } catch (e) {
    console.error(e);
    return e.response.data;
  }
};

export const getCourseInfo = async (transactionId, courseId) => {
  const url = `${config.ROUTES.GET_COURSE_INFO}/${transactionId}/${courseId}`;
  const options = { url, method: 'GET' };
  try {
    const { data } = await request(options);
    return data;
  } catch (e) {
    console.error(e.response);
    return e.response;
  }
};
