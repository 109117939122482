import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _find from 'lodash/find';
import ExpandedTmsComponent from './ExpandedTmsComponent';
import { WHITE } from '../../../stylesheets/colors';
import LoaderIcon from '../../../assets/icons/loader-icon.svg';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import { anyScormSelectedInComponent } from '../../../helpers/utils';

const useStyles = makeStyles({
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  loaderIconCls: {
    marginBottom: '0 !important',
    marginLeft: '1rem',
    animation: '$spinner 1s linear infinite',
  },
  noDisplay: {
    visibility: 'hidden',
  },
});

const ExpandableTableTmsRow = ({
  children, course, courseIndex, programId, programType, isDDAdmin, disabledActions,
  expandAll, coureseRow, formik, tableBodyRow, updateScorm, progress, progressReport,
}) => {
  const { id: courseId } = course;
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (expandAll) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [expandAll]);

  const compnentReleaseProgress = progress && progress?.details && _find(progress?.details, ['course_id', courseId]);

  const compnentReleaseProgressPercentage = compnentReleaseProgress
    && Object.keys(compnentReleaseProgress).length > 0 && compnentReleaseProgress?.percentage;

  return (
    <Grid
      container
      spacing={1}
      component={Paper}
      style={{ marginBottom: '1rem' }}
    >
      <Grid container item xs={12} className={tableBodyRow} style={{ backgroundColor: WHITE }}>
        {children}
        <Grid item container xs={1} style={{ flexBasis: '6%' }} direction="row">
          {
            anyScormSelectedInComponent(course?.training_materials) && progress?.done === false ? (
              <>
                <img src={compnentReleaseProgressPercentage < 100 ? LoaderIcon : CheckRoundIcon} alt="progress-status" className={compnentReleaseProgressPercentage < 100 ? classes.loaderIconCls : ''} />
              </>
            ) : null
          }
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <ExpandedTmsComponent
        courseId={courseId}
        formik={formik}
        isExpanded={isExpanded}
        courseIndex={courseIndex}
        coureseRow={coureseRow}
        isDisabled={!course?.selected}
        courseChecked={course?.selected}
        courseName={course?.name}
        tableBodyRow={tableBodyRow}
        programId={programId}
        programType={programType}
        updateScorm={updateScorm}
        progressReport={progressReport}
        isDDAdmin={isDDAdmin}
        disabledActions={disabledActions}
      />
    </Grid>
  );
};

export default ExpandableTableTmsRow;

ExpandableTableTmsRow.propTypes = {
  children: PropTypes.element.isRequired,
  course: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    training_materials: PropTypes.array.isRequired,
  }).isRequired,
  expandAll: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
  }).isRequired,
  courseIndex: PropTypes.string.isRequired,
  coureseRow: PropTypes.string.isRequired,
  tableBodyRow: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  programType: PropTypes.string.isRequired,
  updateScorm: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
    percentage: PropTypes.string.isRequired,
    details: PropTypes.array.isRequired,
  }).isRequired,
  progressReport: PropTypes.array.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  disabledActions: PropTypes.bool.isRequired,
};
