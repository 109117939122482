import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Header from '../common/Header';
import FlyoutMenu from '../common/FlyoutMenu';
import { DARK_MEDIUM_GREY, MODERATE_DARK_GREY } from '../../stylesheets/colors';

const content = [
  'Select one or multiple assignments within a single course or multiple courses and click on Download',
  'All the assignment files will be downloaded on your system and will be available in a zip file in following format : LP name > Course name > Assignment name > Username_Filename_Timestamp.ppt',
  `Additionally a Meta data file will also be downloaded with following details:
  User ID, Username, Wave/Cohort, Course ID (Docebo), Course Name, Assignment ID (Docebo), Assignment Name, Groupwork, Grade value, Grade status, Comments`,
];

const useStyles = makeStyles({
  menuItems: {
    whiteSpace: 'normal',
    lineHeight: 2,
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  listItemIcon: {
    minWidth: '2.5rem',
    marginTop: '0.8rem',
    alignSelf: 'flex-start',
    color: MODERATE_DARK_GREY,
  },
  menuSubtitleMedGrey: {
    color: DARK_MEDIUM_GREY,
  },
});

const AssignmentManagementHeader = ({ shouldShow }) => {
  const classes = useStyles();
  return (
    <Header heading="Assignment Download" subHeading="Select and download assignments">
      <FlyoutMenu
        buttonTitle="Instructions"
        subTitle="This screen allows you to download assignment files in bulk that were uploaded by users for an assignment within a course"
        initialState={shouldShow}
        classes={{
          subtitle: classes.menuSubtitleMedGrey,
        }}
      >
        {content.map((item) => (
          <MenuItem className={classes.menuItems} key={`content-${item}`}>
            <ListItemIcon className={classes.listItemIcon}>
              <FiberManualRecordIcon style={{ fontSize: '0.5625rem' }} />
            </ListItemIcon>
            <Typography variant="inherit">{item}</Typography>
          </MenuItem>
        ))}
      </FlyoutMenu>
    </Header>
  );
};

AssignmentManagementHeader.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
};

export default AssignmentManagementHeader;
