import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isEmpty } from 'lodash';
import {
  ACCORDION_GREY, MCKINSEY_BLUE,
  MEDIUM_GREY, MODERATE_DARK_GREY, SWITCH_GREY, WHITE,
} from '../../../../../stylesheets/colors';
import LtiModalComponents from '../../../LtiModalComponents';
import { PROMPT_TYPE_OPTIONS } from '../../../../../constants';
import CustomModal from '../../../../common/CustomModal';
import FormattedTypography from '../../../../common/FormattedTypography';
import CustomRadio from '../../../../UserEnrollment/common/CustomRadio';

const useStyles = makeStyles(() => ({
  modalStyles: {
    maxWidth: '73rem',
  },
  dialogContentClass: {
    padding: '0 13rem 0.5rem 13rem',
    width: '100%',
    '& > div': {
      marginBottom: '2rem',
    },

    '& > div:last-child': {
      marginBottom: '1rem',
    },
  },
  modalHeader: {
    margin: '0 auto',
    width: '100%',
    maxHeight: '50vh',
    overflow: 'auto',
    alignItems: 'center',

    '& span': {
      textAlign: 'center',
    },
  },
  divider: {
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',

    '& > button:nth-child(1)': {
      width: '11rem',

      '&:hover': {
        backgroundColor: MCKINSEY_BLUE,
        color: WHITE,
        opacity: 0.6,
      },

      '&  > span': {
        fontSize: '1rem',
      },
    },
    '& > button:nth-child(2)': {
      width: '11rem',
      marginLeft: '1.25rem',

      '&:disabled': {
        backgroundColor: SWITCH_GREY,
        color: ACCORDION_GREY,
      },
      '&:hover': {
        backgroundColor: MCKINSEY_BLUE,
        color: WHITE,
        opacity: 0.6,
      },

      '&  > span': {
        fontSize: '1rem',
        fontWeight: 600,
      },
    },
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  customModalTitle: {
    margin: '1rem 0 0 0 ',
  },
  subHeading: {
    color: MEDIUM_GREY,
    fontSize: '1.125rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formWrapper: {
    width: '100%',
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  radioOptions: {
    color: MODERATE_DARK_GREY,
    fontSize: ' 1rem',
  },
}
));

const defaultData = {
  promptText: '',
  promptType: 'mandatory',
};

const AddPromptModal = ({
  open, onClose, onOk, selectedPage, journal_id, savedPromptValues, prompt_id,
}) => {
  const classes = useStyles();
  const { journal_title: pageTitle } = selectedPage;
  const isNewPrompt = isEmpty(savedPromptValues);

  const formik = useFormik({
    initialValues: !isNewPrompt ? { ...savedPromptValues } : { ...defaultData },
    onSubmit: (values) => {
      onOk(values);
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit, handleChange, values, resetForm,
  } = formik;

  const checkFormDisabled = () => {
    const { promptText, promptType } = values;
    return !(promptText && promptType);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      modalStyle={classes.modalStyles}
    >
      <form onSubmit={handleSubmit} className={classes.formWrapper}>
        <DialogTitle disableTypography className={classes.modalHeader}>
          <FormattedTypography
            body={isNewPrompt ? 'Add Prompt' : `Edit ${prompt_id}`}
            dense
            className={classes.heading}
            containerClassName={classes.customModalTitle}
          />
          <FormattedTypography
            body={isNewPrompt ? `This prompt will be saved in ${pageTitle} of ${journal_id}` : `Edit prompt details here. The prompt will be saved and updated in ${pageTitle} of ${journal_id}`}
            dense
            className={classes.subHeading}
          />
        </DialogTitle>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogContent className={`${classes.dialogContentClass} form-content`}>
          <LtiModalComponents
            label="Prompt"
            placeHolder="Enter the prompt text here"
            value={values.promptText}
            isRequired
            fieldType="textarea"
            isEdit
            name="promptText"
            handleChange={handleChange}
          />
          <LtiModalComponents
            label="Prompt Type"
            isRequired
            fieldType="custom"
          >
            <RadioGroup
              row
              className={classes.radioGroup}
              aria-label="promptType"
              name="promptType"
              value={values.promptType}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              {PROMPT_TYPE_OPTIONS.map(
                ({
                  title, value,
                }) => (
                  <>
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<CustomRadio />}
                      label={<span className={classes.radioOptions}>{title}</span>}
                    />
                  </>
                ),
              )}
            </RadioGroup>
          </LtiModalComponents>
        </DialogContent>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogActions className={classes.centerAction}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onOk(values);
            }}
            disabled={checkFormDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </CustomModal>
  );
};

AddPromptModal.defaultProps = {
  prompt_id: '',
};

AddPromptModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  selectedPage: PropTypes.object.isRequired,
  journal_id: PropTypes.string.isRequired,
  savedPromptValues: PropTypes.object.isRequired,
  prompt_id: PropTypes.string,
};

export default AddPromptModal;
