import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../common/ConfirmationModal';
import CustomSelect from '../common/CustomSelect';
import FormattedTypography from '../common/FormattedTypography';
import { testUsersOptions } from '../../constants';
import LabledTextField from '../common/LabledTextField';

const useStyles = makeStyles(() => ({
  addMargin: {
    marginBottom: '1.5rem',
  },
  width100: {
    width: '80%',
  },
  mb: {
    marginBottom: '0.875rem',
  },
}));

const CreateMoreUsersModal = ({
  open,
  onClose,
  onCreate,
  startingAt,
  setStartingAt,
  countObj,
  setCountObj,
}) => {
  const classes = useStyles();

  const onCreateInner = () => {
    onCreate();
    onClose();
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      dialogContentClass={classes.width100}
      breakpoint="sm"
      title={(
        <FormattedTypography
          variant="subtitle2"
          body="Create More Users"
          dense
        />
      )}
      content={(
        <Container>
          <div className={classes.addMargin}>
            <LabledTextField
              disabled
              label="Starting at"
              value={startingAt}
              onChange={(v) => setStartingAt(Number.parseInt(v, 10))}
              placeholder={1}
              type="number"
            />
          </div>

          <div className={classes.addMargin}>
            <CustomSelect
              labelClass={classes.mb}
              options={testUsersOptions}
              placeholder="Select an option"
              label="Count (Max: 2)"
              onChange={(e) => setCountObj(e)}
              defaultValue={countObj}
              isSearchable={false}
            />
          </div>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onCreateInner}>
            Create Users
          </Button>
        </>
      )}
    />
  );
};

CreateMoreUsersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  startingAt: PropTypes.number.isRequired,
  setStartingAt: PropTypes.func.isRequired,
  countObj: PropTypes.object.isRequired,
  setCountObj: PropTypes.func.isRequired,
};

export default CreateMoreUsersModal;
