import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ConfirmationModal from '../../common/ConfirmationModal';
import { MEDIUM_GREY } from '../../../stylesheets/colors';
import JournalingDeleteIcon from '../../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:last-child': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
}));

const RemoveGroupWorkPaxViewModal = ({
  onClose,
  onConfirm,
}) => {
  const classes = useStyles();
  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>Please note</Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            If you proceed with this action, this Training Material showing participants their
            Group View will be removed from this Docebo Learning Plan.
            Are you sure you want to go ahead with this action?
          </Typography>
        </Container>
            )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => { onConfirm(); }}>
            Confirm & Proceed
          </Button>
        </>
      )}
    />
  );
};

RemoveGroupWorkPaxViewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default RemoveGroupWorkPaxViewModal;
