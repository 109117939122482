import {
  MCKINSEY_LIGHT_BLUE,
  PURPLE,
  DARK_GREY,
  ORANGE,
} from '../../stylesheets/colors';

const defaultColor = DARK_GREY;
export const typeColorLookup = {
  PDF: MCKINSEY_LIGHT_BLUE,
  PPT: PURPLE,
  VIDEO: ORANGE,
  PACKAGE: DARK_GREY,
};

export const elucidatTypeDropdownOptions = [
  { label: 'Project', value: 'project' },
];

export const doceboTypeDropdownOptions = [
  { label: 'All', value: 'all' },
  { label: 'Authoring', value: 'slides' },
  { label: 'File Upload', value: 'file upload' },
  { label: 'HTML Page', value: 'html' },
  { label: 'Survey', value: 'survey' },
  { label: 'Scorm', value: 'scorm' },
  { label: 'Test', value: 'test' },
  { label: 'XAPI', value: 'tincan' },
  { label: 'Video', value: 'video' },
];

export const eLearningResourceDropdownOptions = [
  { label: 'Elucidat', value: 'elucidat' },
  { label: 'Docebo', value: 'docebo' },
  { label: 'All', value: 'all' },
];

export const otherResourceDropDownOptions = [
  { label: 'Docebo', value: 'docebo' },
];

export const getColorForType = (type) => typeColorLookup[type] || defaultColor;
