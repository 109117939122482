import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import _filter from 'lodash/filter';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormattedTypography from '../../common/FormattedTypography';
import IndividualScormOverview from '../IndividualScormOverview';
import {
  COMPLETED, COMPLETED_WITH_ERRORS, FAILED,
} from '../../../constants';
import AlertIcon from '../../../assets/icons/alert-status-icon.svg';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import { MEDIUM_GREY } from '../../../stylesheets/colors';
import { LoaderInner } from '../../Loader';
import StatusModalWrapper from '../../ActivityLogs/StatusModalWrapper';
import GenericProgressModal from '../../common/GenericProgressModal';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    color: MEDIUM_GREY,
    textAlign: 'center',
  },
  subtitleStyles: {
    marginTop: '1.25rem',
  },
  headingWrapper: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  completedSubheading: {
    display: 'flex',
    flexDirection: 'column',
  },
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
}));

const BulkScormReleaseStatuses = ({
  open, onClose, modalState, transactionId, configId, getReport, progressData,
  progressErrorMsg, onDownloadLog,
}) => {
  const classes = useStyles();

  const getStatus = useCallback(({ tmCount, failedTmCount, reportStatus }) => {
    if (reportStatus === COMPLETED) {
      return {
        src: CheckRoundIcon,
        heading: 'Full re-release for multiple SCORMs created successfully!',
        subHeading: (
          <Box className={classes.completedSubheading}>
            <Typography variant="subtitle2" component="span" className={classes.subtitle}>
              A full re-release was created for
              {' '}
              {tmCount}
              {' '}
              selected training materials in
              {' '}
              {configId}
              .
            </Typography>
            <Typography variant="subtitle2" component="span" className={classes.subtitle} style={{ marginTop: '0.25rem' }}>This update will also reflect in Elucidat.</Typography>
          </Box>
        ),
        primaryBtnText: 'Download Log',
      };
    }
    if (reportStatus === COMPLETED_WITH_ERRORS) {
      return {
        src: AlertIcon,
        heading: 'Full re-release for multiple SCORMs was completed with errors',
        subHeading: `The re-release for ${failedTmCount}/${tmCount} selected training materials in ${configId} was completed with errors.`,
        primaryBtnText: 'Download Log',
      };
    }

    return {
      src: ErrorRoundIcon,
      heading: 'Full re-release for multiple SCORMs failed',
      subHeading: `There was a problem when refreshing and syncing the re-release for ${tmCount} selected training materials in ${configId}, please try again.`,
      primaryBtnText: 'Download Log',
    };
  }, [classes.completedSubheading, classes.subtitle, configId]);

  const getStatusByCount = useCallback((totalScorms, failedScorms) => {
    if (failedScorms === 0) {
      return COMPLETED;
    } if (failedScorms !== 0 && failedScorms < totalScorms) {
      return COMPLETED_WITH_ERRORS;
    }
    return FAILED;
  }, []);

  const reportModal = useCallback(async () => {
    const result = await getReport(transactionId);

    const totalScorms = result?.total_scorms;
    const failedScorms = result?.failed_scorms;
    const reportStatus = getStatusByCount(totalScorms, failedScorms);
    let updatedTmList = [];
    if (reportStatus === COMPLETED_WITH_ERRORS) {
      const tmList = result?.data;
      updatedTmList = _filter(tmList, { reportStatus: COMPLETED_WITH_ERRORS });
    }

    const statusObj = getStatus(
      { tmCount: totalScorms, failedTmCount: failedScorms, reportStatus },
    );
    return ({
      component: IndividualScormOverview,
      wrapperProps: {
        disableGutters: true,
        logo: (
          <Box>
            <img src={statusObj?.src} alt="copied-status" width={40} height={40} />
          </Box>
        ),
        heading: (
          <Box className={classes.headingWrapper}>
            <Typography variant="subtitle1" component="span" className={classes.title}>{statusObj?.heading}</Typography>
            <Typography variant="subtitle2" component="span" className={`${classes.subtitle} ${classes.subtitleStyles}`}>{statusObj?.subHeading}</Typography>
          </Box>
        ),
        headingDivider: reportStatus === COMPLETED_WITH_ERRORS,
        contentDivider: true,
        primaryBtnText: statusObj?.primaryBtnText,
        primaryBtnProps: {
          onClick: () => onDownloadLog({
            transaction_id: transactionId,
            created_at: result?.created_at,
          }),
        },
        secondaryBtnText: 'Close',
        secondaryBtnProps: {
          onClick: () => onClose(),
        },
        breakpoint: 'md',
      },
      props: {
        reportStatus,
        tmList: updatedTmList,
      },
    });
  }, [getReport, transactionId, getStatus, classes.headingWrapper, onClose, onDownloadLog,
    classes.title, classes.subtitle, classes.subtitleStyles, getStatusByCount]);

  const progressDataModal = useCallback(() => ({
    component: GenericProgressModal,
    wrapperProps: {
      heading: (
        <DialogTitle>
          <FormattedTypography
            body="Bulk SCORM full re-release"
            subHeading={`Full re-release of ${progressData?.total_scorms} selected SCORMs for ${configId} is in progress`}
            subHeadingSection={classes.subHeadingSection}
          />
        </DialogTitle>
      ),
      logo: (
        <div className={classes.progressRow}>
          <LoaderInner
            progress={progressData?.percentage ? progressData?.percentage : 0}
            scaleFactor={1.5}
          />
        </div>
      ),
      secondaryBtnText: 'Close',
      secondaryBtnProps: {
        onClick: () => onClose(),
      },
      breakpoint: 'md',
    },
    props: {
      initiatedBy: progressData?.created_by,
      initiatedAt: progressData?.created_at,
    },
  }), [classes.progressRow, classes.subHeadingSection, onClose, configId, progressData]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={progressDataModal}
      reportModal={reportModal}
    />
  );
};

BulkScormReleaseStatuses.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

BulkScormReleaseStatuses.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
};

export default BulkScormReleaseStatuses;
