import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormattedTypography from '../common/FormattedTypography';
import {
  MEDIUM_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  footerText: {
    color: MEDIUM_GREY,
    padding: 0,
  },
  leftSpacing: {
    fontWeight: 'bold',
    marginLeft: '.3rem',
  },
});

const BlankDueDateWarning = ({ component }) => {
  const classes = useStyles();
  const missingDueDateComponent = component.filter((item) => !item.due_date);
  const missingDueDateComponentLength = missingDueDateComponent.length;
  return (
    <Container className={classes.footerText}>
      <FormattedTypography
        variant="body1"
        prefix={
          missingDueDateComponentLength === 1
            ? 'You have not added a due date for component'
            : 'You have not added a due date for some components.'
          }
        suffix={missingDueDateComponentLength === 1 ? ` '${missingDueDateComponent[0]?.course_code}'` : ''}
        suffixClassName={classes.leftSpacing}
      />
      <Typography variant="body1" align="center">A blank due date will prevent user progress tracking by due date.</Typography>
    </Container>
  );
};

BlankDueDateWarning.propTypes = {
  component: PropTypes.object.isRequired,
};

export default memo(BlankDueDateWarning, (() => true));
