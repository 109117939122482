import React, { memo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles(() => ({
  alertSnackBar: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SnackbarCustom = ({
  open, severity, message, onClose, autoHideDuration,
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={() => onClose()}
    >
      <Alert className={classes.alertSnackBar} onClose={() => onClose()} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

SnackbarCustom.defaultProps = {
  autoHideDuration: 4000,
};

SnackbarCustom.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
};

export default memo(
  SnackbarCustom,
  (prevProps, nextProps) => prevProps.open === nextProps.open
    && prevProps.severity === nextProps.severity
    && prevProps.message === nextProps.message,
);
