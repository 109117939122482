import React, { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { uploadXlsx } from '../common/apis';
import { VERY_LIGHT_GREY } from '../../stylesheets/colors';
import { ROUTE_HOME, ROUTE_REPORT } from '../../constants';

import EnvironmentBanner from '../../components/EnvironmentBanner';
import SimpleHeader from '../../components/SimpleHeader';
import ModifyCourse from '../../components/ModifyCourse';
import { getEnvironmentFromLocalStorage } from '../../helpers/userHelper';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: VERY_LIGHT_GREY,
  },
  breadCrumbWrapper: {
    display: 'flex',
    margin: '2rem',
  },
  uploadButtonWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  uploadButtonWrapperInner: {
    minHeight: '708px',
    flexBasis: 'auto',
    flexGrow: 1,
  },
});

const ModifyCoursePage = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const environment = getEnvironmentFromLocalStorage();
  const programId = _get(match, 'params.programId');
  const programType = _get(match, 'params.programType');
  const programSubType = _get(match, 'params.programSubType');

  // Redirect to upload screen if no data
  if (!state) {
    history.replace(`/${ROUTE_HOME}/${programType}`);
  }

  const { fileData, fileName, steps } = state;

  const onBuildProgram = useCallback(
    async (fData) => {
      if (fData === null) return;
      try {
        const formData = new FormData();
        formData.append('file', fData, fileName);
        formData.append('program_id', programId);
        const result = await uploadXlsx(programType, programSubType, formData);
        // Remove state data
        window.history.replaceState(null, '');

        // Navigate to report page
        history.replace(
          `/${ROUTE_REPORT}/${result.data.program_id}/${programType}/${programSubType}`,
        );
      } catch (e) {
        // TODO: Show toast of failure
        console.error(e);
      }
    },
    [fileName, programId, programType, programSubType, history],
  );

  return (
    <div className={classes.wrapper}>
      <EnvironmentBanner environment={environment.environment} />
      <SimpleHeader programType={programType} />
      <ModifyCourse
        fileName={fileName}
        data={fileData}
        steps={steps}
        onBuildProgram={onBuildProgram}
      />
    </div>
  );
};

ModifyCoursePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
    }),
  }).isRequired,
};

export default ModifyCoursePage;
