import React, {
  useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CustomUploadModal from '../../common/CustomUploadModal';
import MetadataComponentsOverview from '../../MetaDataManagement/MetaDataUsers/MetadataComponentsOverview';
import ActivityInitiatedInstructions from '../../MetaDataManagement/MetaDataUsers/ActivityInitiatedInstructions';
import { getProgress, getReport } from '../../../containers/common/apis';
import { LoaderInner } from '../../Loader';
import FormattedTypography from '../../common/FormattedTypography';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import BlueAlertIcon from '../../../assets/icons/icon-blue-alert.svg';

const useStyles = makeStyles({
  leftSpacing: {
    marginLeft: '0.5rem',
  },
});

const BulkMetaData = ({
  transactionId, onClose, onDownloadLog,
}) => {
  const classes = useStyles();
  const [openStatusModal, setOpenStatusModal] = useState(true);

  const [modalState, setModalState] = useState(null);
  const [modalSchema, setModalScheme] = useState({
    UPLOAD: {
      component: MetadataComponentsOverview,
      wrapperProps: {
        logo: null,
        heading: null,
        primaryBtnText: undefined,
        primaryBtnProps: {
          onClick: () => { },
        },
        contentDivider: true,
        disableGutters: true,
      },
      props: {
        successCount: 0,
        failCount: 0,
        totalCount: 0,
        fileName: '',
      },
    },
    PROGRESS: {
      component: ActivityInitiatedInstructions,
      wrapperProps: {
        logo: (
          <div>
            <LoaderInner progress={0} scaleFactor={0.75} />
          </div>
        ),
        heading: null,
        breakpoint: 'md',
        secondaryBtnText: 'Close',
        secondaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: {
        created_by: '',
        created_time: '',
      },
    },
  });

  const getHeading = (result) => {
    if (result?.fail_count === 0) {
      return (
        <FormattedTypography
          variant="h1"
          suffix="Component metadata bulk edit file upload complete!"
          suffixClassName={classes.leftSpacing}
        />
      );
    } if ((result?.success_count === 0)) {
      return (
        <FormattedTypography
          variant="h1"
          suffix="Component metadata bulk edit file upload failed!"
          suffixClassName={classes.leftSpacing}
        />
      );
    }
    return (
      <FormattedTypography
        variant="h1"
        suffix="Component metadata bulk edit file upload was completed with errors!"
        suffixClassName={classes.leftSpacing}
      />
    );
  };

  const getSrc = (result) => {
    if (result?.fail_count === 0) {
      return CheckRoundIcon;
    } if ((result?.success_count === 0)) {
      return ErrorRoundIcon;
    }
    return BlueAlertIcon;
  };

  const showReport = useCallback(({
    logo, heading, primaryBtnText, primaryBtnProps, successCount, failCount, totalCount, fileName,
  }) => {
    setModalState('UPLOAD');
    setModalScheme((schema) => ({
      ...schema,
      UPLOAD: {
        ...schema.UPLOAD,
        wrapperProps: {
          ...schema.UPLOAD.wrapperProps,
          logo,
          heading,
          primaryBtnText,
          primaryBtnProps: {
            ...schema.UPLOAD.wrapperProps.primaryBtnProps,
            ...primaryBtnProps,
          },
        },
        props: {
          successCount,
          failCount,
          totalCount,
          fileName,
        },
      },
    }));
  }, []);

  const showProgress = useCallback(({
    progressResponse,
  }) => {
    batchUpdates(() => {
      setModalState('PROGRESS');
      setModalScheme((schema) => ({
        ...schema,
        PROGRESS: {
          ...schema.PROGRESS,
          wrapperProps: {
            ...schema.PROGRESS.wrapperProps,
            heading: (
              <FormattedTypography
                body="Component Meta"
                subHeading={`Uploading component metadata file ‘${progressResponse.file_name || 'temp.xls'}`}
              />
            ),
            logo: (
              <div>
                <LoaderInner
                  progress={progressResponse.percentage || 0}
                  scaleFactor={0.75}
                />
              </div>
            ),
          },
          props: {
            ...schema.PROGRESS.props,
            created_by: progressResponse.created_by,
            created_time: progressResponse.created_at,
          },
        },
      }));
    });
  }, []);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        if (!transactionId) return;
        const res = await getProgress(transactionId);
        if (res.data.done) {
          const result = await getReport(transactionId);
          showReport({
            logo: <img src={getSrc(result)} alt="Spreadsheet" width={30} height={30} />,
            heading: getHeading(result),
            primaryBtnText: 'Download Log',
            primaryBtnProps: {
              onClick: () => onDownloadLog(result),
            },
            successCount: result.success_count,
            failCount: result.fail_count,
            totalCount: result.total_rows,
            fileName: result.filename,
          });
        } else {
          showProgress({
            progressResponse: res.data,
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        console.log(err);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const preClose = () => {
    setOpenStatusModal(false);
    onClose();
  };
  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  return (
    <>
      {
        openStatusModal && ModalComponent ? (
          <CustomUploadModal
            open={openStatusModal}
            onClose={preClose}
            breakpoint="md"
            {...modalSchema[modalState].wrapperProps}
          >
            <ModalComponent {...modalSchema[modalState].props} />
          </CustomUploadModal>
        ) : null
      }
    </>
  );
};

BulkMetaData.propTypes = {
  transactionId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
};

export default BulkMetaData;
