import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import useStyles, { loaderUpperSemiCircleStyle } from './Loader.styles';
import { CANCEL_TRANSACTION_CONFIRMATION } from '../../constants';

export const LoaderInner = ({ scaleFactor, progress }) => {
  const styles = useStyles({ scaleFactor });
  const progressFloor = Math.floor(progress);
  return (
    <div className={styles.loaderBaseCircle}>
      <div className={styles.loaderSemiCircle} />
      <div className={styles.loaderSemiCircle} style={loaderUpperSemiCircleStyle(progressFloor)} />
      <div className={styles.loaderLabelCircle}>
        <span className={styles.loaderLabelText}>{`${progressFloor}`}</span>
        %
      </div>
    </div>
  );
};

LoaderInner.propTypes = {
  progress: PropTypes.number.isRequired,
  scaleFactor: PropTypes.number.isRequired,
};

export const LoaderCancelSection = ({
  onCancelTransaction,
  cancellationRequested,
  setCancellationRequested,
}) => {
  const styles = useStyles();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleConfirmationMessage = () => {
    setShowConfirmation(!showConfirmation);
  };

  const onCancel = () => {
    setCancellationRequested(true);
    onCancelTransaction();
  };

  return (
    <>
      {showConfirmation ? (
        <>
          <div className={styles.loaderSubText}>{CANCEL_TRANSACTION_CONFIRMATION}</div>
          <div className={styles.loaderButtonsRow}>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleConfirmationMessage}
              disabled={cancellationRequested}
            >
              Continue Operation
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={styles.loaderCancelButton}
              onClick={onCancel}
              disabled={cancellationRequested}
            >
              Cancel Operation
            </Button>
          </div>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={styles.loaderCancelButton}
          onClick={toggleConfirmationMessage}
          disabled={cancellationRequested}
        >
          Cancel Operation
        </Button>
      )}
    </>
  );
};

LoaderCancelSection.propTypes = {
  onCancelTransaction: PropTypes.func.isRequired,
  cancellationRequested: PropTypes.bool.isRequired,
  setCancellationRequested: PropTypes.func.isRequired,
};

// https://stackoverflow.com/a/37096512/1217998
const secondsToHms = (dd) => {
  const d = Math.round(dd);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
};

const Loader = ({
  progress,
  hideCancel,
  showEstimatedTime,
  showLoaderSubText,
  estimatedTimeSeconds,
  onCancelTransaction,
  cancellationRequested,
  setCancellationRequested,
}) => {
  const styles = useStyles();
  const scaleFactor = 1;
  const estimatedTimeText = estimatedTimeSeconds > 1e-9 ? `Estimated time: ${secondsToHms(estimatedTimeSeconds)}` : '';
  return (
    <Paper className={styles.loaderContainer}>
      <LoaderInner progress={progress} scaleFactor={scaleFactor} />
      {showLoaderSubText && (
        <div className={styles.loaderSubText}>
          Please hold on a bit
          <br />
          {cancellationRequested ? 'Cancelling pending tasks...' : 'We are working on it...'}
        </div>
      )}
      {showEstimatedTime && <div className={styles.estimatedTimeDiv}>{estimatedTimeText}</div>}
      {!hideCancel && (
        <LoaderCancelSection
          onCancelTransaction={onCancelTransaction}
          cancellationRequested={cancellationRequested}
          setCancellationRequested={setCancellationRequested}
        />
      )}
    </Paper>
  );
};

Loader.propTypes = {
  progress: PropTypes.number,
  hideCancel: PropTypes.bool,
  showEstimatedTime: PropTypes.bool,
  showLoaderSubText: PropTypes.bool,
  estimatedTimeSeconds: PropTypes.number,
  onCancelTransaction: PropTypes.func,
  cancellationRequested: PropTypes.bool,
  setCancellationRequested: PropTypes.func,
};

Loader.defaultProps = {
  progress: 0,
  hideCancel: false,
  showEstimatedTime: true,
  showLoaderSubText: true,
  estimatedTimeSeconds: 0,
  onCancelTransaction: () => {},
  cancellationRequested: false,
  setCancellationRequested: () => {},
};

export default memo(
  Loader,
  (prevProp, nextProp) => prevProp.progress === nextProp.progress
    && prevProp.cancellationRequested === nextProp.cancellationRequested
    && prevProp.estimatedTimeSeconds === nextProp.estimatedTimeSeconds
    && prevProp.showLoaderSubText === nextProp.showLoaderSubText,
);
