import styled from 'styled-components';
import { ThemeShape, DefaultTheme } from './default-theme';

// This runs SCSS using the styledDiv library

export const GlobalHeaderStyles = styled.header`
  .header {
    display: flex;
    align-items: center;
    padding: 0px 40px;
    height: 64px;
    border-bottom: solid 1px #b3b3b3;
    background-color: ${(props) => props.theme.white};
    font-family: ${(props) => props.theme.font_family};
    color: ${(props) => props.theme.primary};
  }

  .header_item {
    height: 100%
    display: flex;
    align-items: center;
    .dsrc_icon_container, .dsrc_icon_svg {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .right_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .logo {
    &_image {
      padding-right: 24px;
      hight: 44px;
      width: 145px;
    }
  }

  .left {
    &:first-of-type {
      &::before{
        content: "";
        display: ${(props) => (props.showDivider ? 'block' : 'none')};
        width: 1px;
        height: 32px;
        background-color: #051c2c;
        margin-right: 13px;
      }
    }
    align-items: center;
    height: 100%;
    display: flex;
    .header_item {
      padding-right: 32px;
    }
  }

  .right {
    .header_item {
      padding-left: 32px;
    }
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

GlobalHeaderStyles.defaultProps = {
  theme: DefaultTheme,
};

GlobalHeaderStyles.propTypes = {
  theme: ThemeShape,
};

export { GlobalHeaderStyles as default };
