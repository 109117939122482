import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {
  ACCORDION_GREY, BLACK, MODERATE_DARK_GREY, MODERATE_LIGHT_GREY, PALE_GREY, SWITCH_GREY,
} from '../../../../../stylesheets/colors';
import { NA } from '../../../../../constants';
import { dateFormatter } from '../../../../../helpers/formattingHelpers';
import CustomPopper from '../../../../common/Popper';

const useStyles = makeStyles(() => ({
  promptBoxContainer: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    '&:nth-child(4n + 1)': {
      paddingLeft: '0.1rem',
    },
    '&:nth-child(4n)': {
      paddingRight: '0.1rem',
    },
  },
  wrapper: {
    width: '100%',
    height: '9.6rem',
    marginBottom: '1.5rem',
    padding: ' 0.625rem 1.25rem 1rem 1.25rem',
  },
  actionWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '0.625rem',
    borderBottom: `1px solid ${SWITCH_GREY}`,
    marginBottom: '0.563rem',
    '& > div': {
      display: 'flex',
      alignItems: 'center',

      '& > img': {
        cursor: 'not-allowed',
      },
    },
    '& > span': {
      padding: 0,
    },
  },
  promptTitle: {
    height: '2.8em',
    fontSize: '0.875rem',
    lineHeight: '1.4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
  },
  lowerWrapper: {
    marginTop: '0.625rem',
    display: 'flex',
    textAlign: 'center',

    '& > div': {
      display: 'flex',

      '& > p:nth-child(1)': {
        maxWidth: '70%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
      },
    },
  },
  title: {
    fontSize: '0.875rem',
    color: ACCORDION_GREY,
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginLeft: '2px',
  },
  promptIndex: {
    padding: '0.2rem 0.4rem',
    backgroundColor: PALE_GREY,
    color: BLACK,
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  optionalTag: {
    fontSize: '0.75rem',
    fontWeight: 600,
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    borderRadius: '10px',
    color: MODERATE_DARK_GREY,
  },
}));

const PromptOverviewBox = ({
  prompt, onDeletePrompt, onEditPrompt, is_locked,
}) => {
  const classes = useStyles({ });
  const {
    prompt: prompt_text, is_required, sequence_number, modified_by, modified,
  } = prompt;

  const actionMenu = [{
    title: 'Edit prompt',
    value: 'edit_prompt',
    onClick: () => { onEditPrompt(prompt); },
  },
  {
    title: 'Delete prompt',
    value: 'delete_prompt',
    onClick: () => { onDeletePrompt(prompt); },
  }];

  return (
    <Box className={classes.promptBoxContainer}>
      <Paper className={classes.wrapper} square>
        <Box className={classes.actionWrapper}>
          <Typography className={classes.promptIndex}>{sequence_number}</Typography>
          <Box>
            {!is_required && (
            <Typography className={classes.optionalTag} style={{ marginRight: '1rem' }}>
              Optional
            </Typography>
            )}
            {!is_locked ? <CustomPopper list={actionMenu} /> : null}
          </Box>
        </Box>
        <Typography className={classes.promptTitle}>{prompt_text}</Typography>
        <Box className={classes.lowerWrapper}>
          <Typography className={classes.title}>
            Updated By:
          </Typography>
          <Box>
            <Typography className={classes.value}>
              {modified_by || NA}
            </Typography>
            <Typography className={classes.value}>
              |
            </Typography>
            <Typography className={classes.value}>
              {modified ? dateFormatter(new Date(modified)) : NA}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

PromptOverviewBox.propTypes = {
  prompt: PropTypes.object.isRequired,
  onDeletePrompt: PropTypes.func.isRequired,
  onEditPrompt: PropTypes.func.isRequired,
  is_locked: PropTypes.bool.isRequired,
};

export default PromptOverviewBox;
