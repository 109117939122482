import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import _find from 'lodash/find';
import _flatMap from 'lodash/flatMap';

import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import Select from '../../components/common/CustomSelect';
// eslint-disable-next-line import/no-cycle
import Timeline from '../../components/ActivityLogs/Timeline';
import Instruction from '../../components/ActivityLogs/Instruction';
import { MODERATE_LIGHT_GREY, MCKINSEY_BLUE } from '../../stylesheets/colors';
import {
  getEnrollmentTransactions,
  getComponentMetadataTransactions,
  getUsersTransactions,
} from './api';
import SelectGroupedCollapsible from '../../components/common/SelectGroupedCollapsible';
import {
  BULK_EDIT_METADATA,
  INDIVIDUAL_SCORM_REFRESH,
  MULTIPLE_SCORM_REFRESH,
  AUTO_ENROLLMENT,
  BULK_UNENROLLMENT,
  WAVE_MANAGEMENT,
  BULK_UPDATE_ACCOUNTS,
  TRANSFER_COHORT_USERS,
  MODIFY_GRADE,
  UPLOAD_GRADE,
  SYNC_SESSION_ENROLLMENTS,
  SINGLE_SESSION_SYNC,
  WEBINAR_MANAGEMENT,
  PENDING,
  PROCESSING,
  OVERVIEW,
  CONTENT_MANAGEMENT,
  WORKSHOP_MANAGEMENT,
  TEST_USERS_CREATION,
  ASSIGNMENT_DOWNLOAD,
  GROUPWORK_GROUPS,
  REPLICATION,
  EDIT_PROGRAM,
  METADATA_MANAGEMENT,
  SETTINGS,
  COMPONENTMETADATA,
  COHORT_USER_METADATA, GROUP_ENROLLMENT, MARK_ALL_USERS_COMPLETE, USER_PROFILE, PLATFORM_USER,
} from '../../constants';

const useStyles = makeStyles({
  globalLogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    overflow: 'auto',
    minHeight: '65vh',
  },
  logHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    padding: '2rem 1rem',
  },
  headerLinks: {
    display: 'flex',
    flexDirection: 'row',
  },
  verticalDivider: {
    '&.MuiDivider-root': {
      borderColor: MCKINSEY_BLUE,
      height: '50%',
      alignSelf: 'center',
    },
  },
  filterSection: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filter: {
    maxWidth: '15rem',
  },
});

const groupedOptions = [
  { value: 'all', label: 'All' },
  {
    label: 'User Management',
    options: [
      { label: 'User Enrollment', value: AUTO_ENROLLMENT },
      { label: 'User Un-enrollment', value: BULK_UNENROLLMENT },
      { label: 'User Activation/Deactivation', value: BULK_UPDATE_ACCOUNTS },
      { label: 'Transfer Users (ECs)', value: TRANSFER_COHORT_USERS },
      { label: 'EC User Metadata', value: COHORT_USER_METADATA },
    ],
  },
  {
    label: 'Assignment Management',
    options: [
      { label: 'Evaluate Assignments', value: UPLOAD_GRADE },
      { label: 'Modify Grades', value: MODIFY_GRADE },
    ],
  },
  {
    label: 'Content Management',
    options: [
      { label: 'Individual SCORM Refresh', value: INDIVIDUAL_SCORM_REFRESH },
      { label: 'Multiple SCORM Refresh', value: MULTIPLE_SCORM_REFRESH },
    ],
  },
  {
    label: 'Workshop Management',
    options: [
      { label: 'Enrollment Sync', value: SYNC_SESSION_ENROLLMENTS },
      { label: 'Session Enrollment Sync', value: SINGLE_SESSION_SYNC },
      { label: 'Mark User As Complete', value: WEBINAR_MANAGEMENT },
      { label: 'Mark All Users Complete', value: MARK_ALL_USERS_COMPLETE },
    ],
  },
  {
    label: 'Group Management',
    options: [
      { label: 'Group Enrollment', value: GROUP_ENROLLMENT },
    ],
  },
];

const insTructionOptions = [
  { label: 'Overview', value: OVERVIEW },
  {
    label: 'User Management',
    options: [
      { label: 'User Enrollment', value: AUTO_ENROLLMENT },
      { label: 'User Un-enrollment', value: BULK_UNENROLLMENT },
      { label: 'Activate / Deactivate Users', value: BULK_UPDATE_ACCOUNTS },
      { label: 'Enrollment Cohort', value: WAVE_MANAGEMENT },
    ],
  },
  { label: 'Content Management', value: CONTENT_MANAGEMENT },
  { label: 'Workshop Management', value: WORKSHOP_MANAGEMENT },
  { label: 'Test User Management', value: TEST_USERS_CREATION },
  {
    label: 'Assignment Management',
    options: [
      { label: 'Assignment Download', value: ASSIGNMENT_DOWNLOAD },
      { label: 'Evaluation', value: UPLOAD_GRADE },
      { label: 'Group Management', value: GROUPWORK_GROUPS },
    ],
  },
  {
    label: 'Advanced Configuration',
    options: [
      { label: 'Replication', value: REPLICATION },
      { label: 'Modify Config ID', value: EDIT_PROGRAM },
      { label: 'Metadata Management', value: METADATA_MANAGEMENT },
    ],
  },
  { label: 'Settings', value: SETTINGS },
  { label: 'Component Metadata', value: COMPONENTMETADATA },
];

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'COMPLETED_WITH_ERRORS', label: 'Completed with Errors' },
];

const ActivityLog = ({
  selectedMenu, programId, programMetadata, programSubType, programType,
}) => {
  const [timeline, setTimeline] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState({ value: 'all', label: 'All' });
  const [selectedInstruction, setSelectedInstruction] = useState({ value: 'overview', label: 'Overview' });
  const [selectedStatus, setSelectedStatus] = useState({ value: 'all', label: 'All' });

  let configId = '';
  if (programId) {
    configId = get(programMetadata, 'config_id', '');
  } else if (programSubType === 'componentMetadata') {
    configId = BULK_EDIT_METADATA;
  } else {
    configId = PLATFORM_USER;
  }

  const classes = useStyles();

  const selectFeatureRef = useRef(null);
  const selectStatusRef = useRef(null);
  const selectInstructionRef = useRef(null);

  const isProcessQueue = selectedMenu.key === 'processQueue';
  const isInstruction = selectedMenu.key === 'instruction';

  const findFeature = useCallback((programtype, program) => {
    const result = _flatMap(program, (item) => {
      if (item.options) {
        return item.options;
      }
      return item;
    });
    const selectedProgram = _find(result, (item) => {
      if (item.value && item.value === programtype) {
        return item;
      }
      return false;
    });
    return selectedProgram || selectedInstruction;
  }, [selectedInstruction]);

  useEffect(() => {
    const feature = findFeature(programSubType, insTructionOptions);
    if (selectInstructionRef.current) {
      selectInstructionRef.current.select.setValue(feature);
    }
    setSelectedInstruction(feature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLogs = useCallback(async () => {
    const resp = await getEnrollmentTransactions(programId, selectedFeature.value,
      selectedStatus.value, isProcessQueue);
    setTimeline(resp.data.data);
  }, [programId, selectedStatus, selectedFeature, isProcessQueue]);

  const fetchLogsForCopmonentmeta = useCallback(async () => {
    const resp = await getComponentMetadataTransactions();
    if (resp.data.data && resp.data.data.length) {
      const filterData = resp.data.data.filter((item) => {
        item.type = BULK_EDIT_METADATA;
        if (isProcessQueue) {
          return (item.status === PROCESSING || item.status === PENDING);
        }
        if (item.status === PROCESSING || item.status === PENDING) {
          return false;
        }
        return (selectedStatus.value === 'all' || item.status === selectedStatus.value);
      });
      setTimeline(filterData);
    }
  }, [selectedStatus, isProcessQueue]);

  const fetchLogsForUsers = useCallback(async () => {
    const resp = await getUsersTransactions();
    if (resp?.data && resp?.data?.length) {
      const filterData = resp.data.filter((item) => {
        item.type = USER_PROFILE;
        if (isProcessQueue) {
          return (item.status === PROCESSING || item.status === PENDING);
        }
        if (item.status === PROCESSING || item.status === PENDING) {
          return false;
        }
        return (selectedStatus.value === 'all' || item.status === selectedStatus.value);
      });
      setTimeline(filterData);
    }
  }, [selectedStatus, isProcessQueue]);

  useEffect(() => {
    if (programId) {
      fetchLogs();
    } else if (programSubType === 'componentMetadata') {
      fetchLogsForCopmonentmeta();
    } else {
      fetchLogsForUsers();
    }
  }, [fetchLogs, fetchLogsForCopmonentmeta, fetchLogsForUsers, programId, programSubType]);

  const viewAllLogs = () => {
    setSelectedFeature({ value: 'all', label: 'All' });
    if (selectFeatureRef.current) selectFeatureRef.current.select.setValue({ value: 'all', label: 'All' });
    if (!isProcessQueue) {
      setSelectedStatus({ value: 'all', label: 'All' });
      if (selectStatusRef.current) selectStatusRef.current.select.setValue({ value: 'all', label: 'All' });
    }
  };

  const handleRefresh = useCallback(() => {
    if (programId) {
      fetchLogs();
    } else if (programSubType === 'componentMetadata') {
      fetchLogsForCopmonentmeta();
    } else {
      fetchLogsForUsers();
    }
  }, [fetchLogs, fetchLogsForCopmonentmeta, fetchLogsForUsers, programId, programSubType]);

  return (
    <Box sx={{ width: 380, borderColor: 'divider' }} className={classes.globalLogWrapper}>
      <Box className={classes.logHeader}>
        <Typography variant="subtitle1">{selectedMenu.title}</Typography>
        {!isInstruction && (
          <Box className={classes.headerLinks}>
            <Button
              variant="text"
              color="primary"
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={viewAllLogs}
            >
              View all Logs
            </Button>
            <Divider orientation="vertical" flexItem textAlign="center" classes={{ root: classes.verticalDivider }} />
            <Button
              variant="text"
              color="primary"
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
          </Box>
        )}
      </Box>
      <Box className={classes.logContainer}>
        <Box className={classes.filterSection}>
          {![BULK_EDIT_METADATA, PLATFORM_USER].includes(configId) && !isInstruction
            ? (
              <SelectGroupedCollapsible
                selectRef={selectFeatureRef}
                name="feature"
                label="Filter by Feature"
                defaultValue={selectedFeature}
                className={classes.filter}
                options={groupedOptions}
                placeholder="Select Feature..."
                onChange={(e) => setSelectedFeature(e)}
              />
            ) : null}
          {
            isInstruction && (
              <SelectGroupedCollapsible
                selectRef={selectInstructionRef}
                name="feature"
                label="Filter by Feature"
                defaultValue={selectedInstruction}
                className={classes.filter}
                options={insTructionOptions}
                placeholder="Select Feature..."
                onChange={(e) => setSelectedInstruction(e)}
              />
            )
          }
          {
            (!isProcessQueue && !isInstruction) && (
              <Select
                selectRef={selectStatusRef}
                name="status"
                defaultValue={selectedStatus}
                className={classes.filter}
                label="Filter by Status"
                placeholder="Select Status..."
                options={statusOptions}
                onChange={(e) => setSelectedStatus(e)}
              />
            )
          }
        </Box>
        {
          !isInstruction
            ? (
              <Timeline
                timeline={timeline}
                configId={configId}
                programId={programId}
                programType={programType}
              />
            )
            : <Instruction feature={selectedInstruction} />
        }
      </Box>
    </Box>
  );
};

ActivityLog.defaultProps = {
  programId: '',
  programMetadata: {},
};

ActivityLog.propTypes = {
  selectedMenu: PropTypes.shape({
    key: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  programId: PropTypes.string,
  programType: PropTypes.string.isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
  }),
  programSubType: PropTypes.string.isRequired,
};

export default ActivityLog;
