import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormattedTypography from '../../common/FormattedTypography';
import {
  COMPLETED, COMPLETED_WITH_ERRORS, ROUTE_REPORT, CONTENT_MANAGEMENT, FAILED,
} from '../../../constants';
import AlertIcon from '../../../assets/icons/alert-status-icon.svg';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import { MEDIUM_GREY } from '../../../stylesheets/colors';
import { LoaderInner } from '../../Loader';
import StatusModalWrapper from '../../ActivityLogs/StatusModalWrapper';
import GenericProgressModal from '../../common/GenericProgressModal';
import ImportComponentOverview from './ImportComponentOverview';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    color: MEDIUM_GREY,
    textAlign: 'center',
  },
  subtitleStyles: {
    marginTop: '1.25rem',
  },
  headingWrapper: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  completedSubheading: {
    display: 'flex',
    flexDirection: 'column',
  },
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
  noMarginBottom: {
    marginBottom: '0',
  },
}));

const ImportLogStatuses = ({
  open, onClose, modalState, transactionId, getReport, progressData,
  progressErrorMsg, onDownloadLog, programId, programType,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const getStatus = useCallback(({
    status, new_component_code, target_lp_code, original_component_code,
  }) => {
    if (status === COMPLETED) {
      return {
        src: CheckRoundIcon,
        heading: 'Import Complete!',
        subHeading: (
          <Box className={classes.completedSubheading}>
            <Typography variant="subtitle2" component="span" className={classes.subtitle}>
              {new_component_code}
              {' '}
              was successfully imported to
              {' '}
              {target_lp_code}
            </Typography>
          </Box>
        ),
        primaryBtnText: 'Open Imported Component',
      };
    }
    if (status === COMPLETED_WITH_ERRORS) {
      return {
        src: AlertIcon,
        heading: 'Import Completed with errors',
        subHeading: (
          <Box className={classes.completedSubheading}>
            <Typography variant="subtitle2" component="span" className={classes.subtitle}>
              {new_component_code}
              {' '}
              was partially imported to
              {' '}
              {target_lp_code}
            </Typography>
          </Box>
        ),
        primaryBtnText: 'Open Imported Component',
        secondaryBtnText: 'Download Log',
      };
    }

    return {
      src: ErrorRoundIcon,
      heading: 'Import a component copy failed!',
      subHeading: `Copy of the component ${original_component_code} could not be imported. Please try again later.`,
      primaryBtnText: 'Download Log',
    };
  }, [classes.completedSubheading, classes.subtitle]);

  const openImportedComponent = useCallback((courseId) => {
    onClose();
    history.push(`/${ROUTE_REPORT}/${programId}/${programType}/${CONTENT_MANAGEMENT}?feature=view-course&id=${courseId}&tab=settings`);
  }, [onClose, history, programId, programType]);

  const reportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const statusObj = getStatus(result);
    return ({
      component: result?.status !== FAILED ? ImportComponentOverview : () => (<></>),
      wrapperProps: {
        disableGutters: true,
        logo: (
          <Box>
            <img src={statusObj?.src} alt="copied-status" width={40} height={40} />
          </Box>
        ),
        heading: (
          <Box className={classes.headingWrapper}>
            <Typography variant="subtitle1" component="span" className={classes.title}>{statusObj?.heading}</Typography>
            <Typography variant="subtitle2" component="span" className={`${classes.subtitle} ${classes.subtitleStyles}`}>{statusObj?.subHeading}</Typography>
          </Box>
        ),
        headingDivider: result?.status !== FAILED && true,
        contentDivider: true,
        primaryBtnText: statusObj?.primaryBtnText,
        secondaryBtnText: statusObj?.secondaryBtnText,
        primaryBtnProps: {
          onClick: [COMPLETED, COMPLETED_WITH_ERRORS].includes(result?.status)
            ? () => openImportedComponent(result?.docebo_course_id)
            : () => onDownloadLog({
              transaction_id: transactionId,
              created_at: result?.created_at,
            }),
        },
        secondaryBtnProps: {
          onClick: () => onDownloadLog({
            transaction_id: transactionId,
            created_at: result?.created_at,
          }),
        },
        breakpoint: 'md',
        contentWrapper: classes.noMarginBottom,
      },
      props: {
        report: result,
      },
    });
  }, [getReport, transactionId, getStatus, classes.headingWrapper, openImportedComponent,
    onDownloadLog, classes.title, classes.subtitle, classes.subtitleStyles,
    classes.noMarginBottom]);

  const progressDataModal = useCallback(() => ({
    component: GenericProgressModal,
    wrapperProps: {
      heading: (
        <DialogTitle>
          <FormattedTypography
            body="Import a component copy"
            subHeading={`We are importing component ${progressData?.new_course_title}`}
            subHeadingSection={classes.subHeadingSection}
          />
        </DialogTitle>
      ),
      logo: (
        <div className={classes.progressRow}>
          <LoaderInner
            progress={progressData?.percentage ? progressData?.percentage : 0}
            scaleFactor={1.5}
          />
        </div>
      ),
      secondaryBtnText: 'Close',
      secondaryBtnProps: {
        onClick: () => onClose(),
      },
      breakpoint: 'md',
    },
    props: {
      initiatedBy: progressData?.created_by,
      initiatedAt: progressData?.created_at,
    },
  }), [classes.progressRow, classes.subHeadingSection, onClose, progressData]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={progressDataModal}
      reportModal={reportModal}
    />
  );
};

ImportLogStatuses.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

ImportLogStatuses.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
  programId: PropTypes.string.isRequired,
  programType: PropTypes.string.isRequired,
};

export default ImportLogStatuses;
