import React, { useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import _find from 'lodash/find';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ERROR_RED } from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  tabs: {
    alignSelf: 'flex-start',
  },
  indicator: {
    backgroundColor: ({ peExist }) => (peExist ? theme.palette.primary.main : ERROR_RED),
  },
  tab: {
    textTransform: 'none',
    minWidth: '3rem',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  notExist: {
    color: ERROR_RED,
    '&.Mui-selected': {
      color: ERROR_RED,
    },
    '&:hover': {
      color: ERROR_RED,
    },
  },
}));

const CreateTestUsersTab = ({ currentTab, tabs, experienceTypeOptions }) => {
  const { pathname } = useLocation();
  const peExist = useMemo(
    () => !experienceTypeOptions.length || !!_find(experienceTypeOptions, { value: currentTab }),
    [experienceTypeOptions, currentTab],
  );
  const classes = useStyles({ peExist });

  return (
    <div className={classes.wrapper}>
      <Tabs
        className={classes.tabs}
        value={currentTab}
        textColor="primary"
        centered
        classes={{
          indicator: classes.indicator,
        }}
      >
        {tabs.map((tab) => {
          // eslint-disable-next-line max-len
          const exist = !experienceTypeOptions.length || !!_find(experienceTypeOptions, { value: tab });
          return (
            <Tab
              component={RouterLink}
              className={`${classes.tab}${!exist ? ` ${classes.notExist}` : ''}`}
              to={{
                pathname,
                hash: `${tab}`,
              }}
              key={tab}
              label={tab}
              value={tab}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

CreateTestUsersTab.propTypes = {
  currentTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  experienceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CreateTestUsersTab;
