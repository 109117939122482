/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import WorkshopManagementSearch from '../../components/WorkshopManagement/WorkshopManagementSearch';
import WorkshopManagementCard from '../../components/WorkshopManagement/WorkshopManagementCard';

import { getWorkshops } from './apis';
import { getUserFromLocalStorage } from '../../helpers/userHelper';

import {
  ROUTE_REPORT, ROLE_ADMIN, ROLE_OPS, USER_INFO_KEY,
} from '../../constants';
import NoDataComponent from '../../components/common/NoDataComponent';
import Loading from '../../components/common/Loading';

import NoChannel from '../../assets/img/noChannel.svg';

const WorkshopManagementCoursePage = ({
  match,
  setOpenConfirmationModal,
  enableSyncAgain,
  isDisabled,
}) => {
  const history = useHistory();
  const [iltData, setIltData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const programId = get(match, 'params.programId');
  const programType = get(match, 'params.programType');
  const programSubType = get(match, 'params.programSubType');
  const [getSecondaryMessage, setSecondaryMessage] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const newData = await getWorkshops(programId);
      setIltData(newData?.data?.courses);
      setIsLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setIltData([]);
    }
  }, [programId]);

  useEffect(() => {
    setIsLoading(true);
    fetchData(programId);
  }, [programId, fetchData]);

  const goToSession = (courseId, courseName) => {
    history.push(`/${ROUTE_REPORT}/${programId}/${programType}/${programSubType}/${courseId}`, {
      courseName,
    });
  };

  const finalData = searchText
    ? iltData.filter(
      (ilt) => ilt?.name?.toLowerCase().includes(searchText.toLowerCase())
        || ilt?.course_code?.toLowerCase().includes(searchText.toLowerCase()),
    )
    : iltData;
  const isFinalDataEmpty = finalData.length > 0;

  useEffect(() => {
    const userRoles = get(getUserFromLocalStorage(USER_INFO_KEY), 'teams', []);
    const message = userRoles.includes(ROLE_ADMIN) || userRoles.includes(ROLE_OPS)
      ? 'Please contact a Course Production member for further assistance.'
      : 'Please create Workshops (ILTs) to continue further';
    setSecondaryMessage(message);
  }, []);

  const getLabelText = useCallback(() => ({
    primaryText: searchText ? 'Oops! Could not find the workshop you were looking for.' : 'This learning plan has no workshops (ILT\'s) yet.',
    secondaryText: searchText ? 'Adjust your search to see more results.' : getSecondaryMessage,
  }), [searchText, getSecondaryMessage]);

  const { primaryText, secondaryText } = getLabelText();

  return (
    <>
      <WorkshopManagementSearch
        match={match}
        onSearch={setSearchText}
        searchText={searchText}
        isFinalDataEmpty={isFinalDataEmpty}
        setOpenConfirmationModal={setOpenConfirmationModal}
        syncButtonDisabled={isDisabled}
        enableSyncAgain={enableSyncAgain}
      />

      {isFinalDataEmpty ? (
        <>
          {finalData.map((ilt) => (
            <WorkshopManagementCard
              key={ilt.idCourse}
              data={ilt}
              onClick={() => goToSession(ilt.idCourse, ilt.name)}
            />
          ))}
        </>
      ) : isLoading ? (
        <Loading />
      ) : (
        <NoDataComponent
          imgSrc={NoChannel}
          primaryText={primaryText}
          secondaryText={secondaryText}
        />
      )}
    </>
  );
};

WorkshopManagementCoursePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({
    configId: PropTypes.string,
  }).isRequired,
  setOpenConfirmationModal: PropTypes.func.isRequired,
  enableSyncAgain: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default WorkshopManagementCoursePage;
