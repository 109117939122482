import React, {
  useEffect, useState, memo, Fragment,
} from 'react';
import PropTypes from 'prop-types';

import _isEqual from 'lodash/isEqual';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import ChevronUp from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@mui/material/Grid';

import {
  MODERATE_LIGHT_GREY,
  PALE_GREY,
  LIGHT_MEDIUM_GREY,
  MODERATE_DARK_GREY,
} from '../../stylesheets/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: '1.25rem',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: PALE_GREY,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  tableHead: {
    color: MODERATE_DARK_GREY,
    backgroundColor: MODERATE_LIGHT_GREY,
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  tableRow: {
    height: '3rem',
  },
  tableCell: {
    color: MODERATE_DARK_GREY,
    padding: '0px 16px',
  },
  courseName: {
    width: '82%',
  },
  checkbox: {
    width: '1.125rem',
    height: '1.125rem',
    color: LIGHT_MEDIUM_GREY,
    opacity: 0.6,
    margin: '0 2.5rem 0 0.75rem',
  },
  checkboxChecked: {
    opacity: 1,
  },
  assignmentColumn: {
    width: '30rem',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

const AssignmentListingTableRow = ({
  row, index, checkCbk,
}) => {
  const classes = useStyles({ isEven: index % 2, isInactive: !row.status });
  const [intermediate, setIntermediate] = useState(false);
  const [open, setOpen] = useState(false);

  const handleChange = (subIndex) => (ev) => {
    setIntermediate(!intermediate);
    checkCbk(index, subIndex, ev.target.checked);
  };

  useEffect(() => {
    let count = 0;
    setIntermediate(false);
    row.Assignments.forEach((item) => {
      if (item.selected) {
        count += 1;
      }
    });
    if (count > 0 && count < row.Assignments.length) {
      setIntermediate(true);
    } else {
      setIntermediate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.selected, checkCbk]);

  const rowDom = Object.keys(row).map((col, idx) => {
    let colVal = row[col];

    if (col === 'Assignments') {
      colVal = row[col].length;
    } else if (col === 'Course Name') {
      colVal = (
        <ListItemText
          className={classes.courseName}
          primary={row[col]}
          secondary={row.Course_code}
        />
      );
    }
    return (
      (!(col === 'selected' || col === 'Course_id' || col === 'Course_code') && (
        <StyledTableCell className={col === 'Assignments' ? `${classes.tableCell} ${classes.assignmentColumn}` : classes.tableCell} key={`${row.Course_id}-${col}`}>
          <Grid container flex alignItems="center">
            <Grid item sm={2} spacing={1}>
              {!idx && (
                <Checkbox
                  className={classes.checkbox}
                  checked={!!row.selected}
                  onChange={handleChange()}
                  name={`check-${index}`}
                  color="primary"
                  classes={{
                    checked: classes.checkboxChecked,
                  }}
                  indeterminate={intermediate}
                />
              )}
            </Grid>
            <Grid item sm={10}>
              {colVal}
            </Grid>
          </Grid>
        </StyledTableCell>
      ))
    );
  });

  const rowDetailDom = row.Assignments.map((col, idx) => (
    Object.keys(col).map((column, i) => (
      (!(column === 'selected' || column === 'Course_id') && (
        <StyledTableCell className={classes.tableCell} key={`${col.id}-${column}`}>
          <Grid container flex alignItems="center">
            <Grid item sm={2} spacing={1}>
              {i === 1 && (
                <div className={classes.checkboxWrapper}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={!!col.selected}
                    onChange={handleChange(idx)}
                    name={`sub-check-${index}-${idx}`}
                    color="primary"
                    classes={{
                      checked: classes.checkboxChecked,
                    }}
                  />
                  {col[column]}
                </div>
              )}
            </Grid>
            <Grid item sm={10}>
              {i < 2 ? false : col[column]}
            </Grid>
          </Grid>
        </StyledTableCell>
      ))
    ))));

  return (
    <Fragment key={row['Course Name']}>
      <StyledTableRow className={classes.tableRow}>
        {rowDom}
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      {open && rowDetailDom.map(
        // eslint-disable-next-line react/no-array-index-key
        (item, i) => <StyledTableRow className={classes.tableRow} key={`subrow-${i}`}>{item}</StyledTableRow>,
      )}
    </Fragment>
  );
};

AssignmentListingTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  checkCbk: PropTypes.func.isRequired,
};

const AssignmentListingTable = ({
  data, setAssignmentList, setDisable, disable,
}) => {
  const classes = useStyles();
  const [check, setCheck] = useState(false);
  const [intermediate, setIntermediate] = useState(false);

  const handleChange = (checked) => {
    setIntermediate(false);
    setCheck(checked);
    data.forEach((row) => {
      row.selected = checked; // eslint-disable-line no-param-reassign
      row.Assignments.forEach((item) => {
        item.selected = checked; // eslint-disable-line no-param-reassign
      });
    });
    setDisable(!checked);
    setAssignmentList([...data]);
  };

  useEffect(() => {
    if (disable) {
      handleChange(false);
    }
  }, [disable]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateCheckBoxes = (isParent, selectedValue, index) => {
    setCheck(false);
    setIntermediate(false);
    let rowCount = 0;
    let subCount = 0;
    if (isParent) {
      data.forEach((row, i) => {
        // eslint-disable-next-line no-param-reassign
        if (i === index) row.Assignments.forEach((item) => { item.selected = selectedValue; });
      });
    }
    data.forEach((row) => {
      let count = 0;
      row.Assignments.forEach((item) => {
        if (item.selected) {
          count += 1;
          subCount += 1;
        }
      });
      if (count === row.Assignments.length) {
        row.selected = true; // eslint-disable-line no-param-reassign
        rowCount += 1;
      } else {
        row.selected = false; // eslint-disable-line no-param-reassign
      }
    });
    if (rowCount === data.length) {
      setIntermediate(false);
      setCheck(true);
      setDisable(false);
    } else if (rowCount === 0 && subCount === 0) {
      setIntermediate(false);
      setCheck(false);
      setDisable(true);
    } else {
      setIntermediate(true);
      setDisable(false);
    }
  };

  const childCheckboxCallback = (index, subIndex, selectedValue) => {
    if (!(subIndex === undefined)) {
      // eslint-disable-next-line no-param-reassign
      data[index].Assignments[subIndex].selected = selectedValue;
      validateCheckBoxes(false, selectedValue, index);
    } else {
      data[index].selected = selectedValue; // eslint-disable-line no-param-reassign
      validateCheckBoxes(true, selectedValue, index);
    }
    setAssignmentList([...data]);
  };

  const heads = Object.keys(data[0]).map((column, idx) => (
    (!(column === 'selected' || column === 'Course_id' || column === 'Course_code') && (
      <StyledTableCell className={classes.tableHead} key={column}>
        <Grid container flex alignItems="center">
          <Grid item sm={2} spacing={1}>
            {!idx && (
              <Checkbox
                className={classes.checkbox}
                checked={check}
                onChange={(e) => handleChange(e.target.checked)}
                name="checkAll"
                color="primary"
                classes={{
                  checked: classes.checkboxChecked,
                }}
                indeterminate={intermediate}
              />
            )}
          </Grid>
          <Grid item sm={10}>
            {column}
          </Grid>
        </Grid>
      </StyledTableCell>
    ))
  ));

  const body = data.map(
    (row, index) => (
      <AssignmentListingTableRow
        key={`row-${row.Course_id}`}
        row={row}
        index={index}
        check={check}
        checkCbk={childCheckboxCallback}
      />
    ),
  );

  return (
    <TableContainer className={classes.wrapper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow className={classes.tableRow}>
            {heads}
            <StyledTableCell className={classes.tableHead} />
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {body}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AssignmentListingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  setAssignmentList: PropTypes.func.isRequired,
  setDisable: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
};

export default memo(AssignmentListingTable,
  (prevProps, nextProps) => _isEqual(prevProps.data, nextProps.data)
    && prevProps.disable === nextProps.disable);
