import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { LIGHT_PURPLE, DARK_GREY } from '../../stylesheets/colors';
import { IGNORE_ERROR_BANNERS_TITLE } from '../../constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '3rem',
    backgroundColor: LIGHT_PURPLE,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    lineHeight: '24px',
    letterSpacing: '0px',
    color: DARK_GREY,
    flex: 1,
  },
  saveChangesButton: {
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
    // backgroundColor: theme.palette.primary.main,
    padding: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '2rem',
    height: '3rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const useCheckboxStyles = makeStyles(() => ({
  root: { color: `${DARK_GREY} !important` },
}));

const IgnoreErrorsBanner = ({ onSaveChanges, programSubType }) => {
  const classes = useStyles();
  const checkBoxClasses = useCheckboxStyles();
  const [checked, setChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onButtonClick = () => {
    setButtonDisabled(true);
    onSaveChanges();
  };

  return (
    <div className={classes.wrapper}>
      <Checkbox
        onClick={() => setChecked((last) => !last)}
        checked={checked}
        classes={checkBoxClasses}
      />
      <Typography variant="body1" className={classes.text}>
        {IGNORE_ERROR_BANNERS_TITLE[programSubType]}
      </Typography>
      {checked && (
        <Button
          color="primary"
          variant="contained"
          className={classes.saveChangesButton}
          onClick={onButtonClick}
          disabled={buttonDisabled}
        >
          Mark as completed
        </Button>
      )}
    </div>
  );
};

IgnoreErrorsBanner.propTypes = {
  onSaveChanges: PropTypes.func.isRequired,
  programSubType: PropTypes.string.isRequired,
};

export default IgnoreErrorsBanner;
