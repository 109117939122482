import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import get from 'lodash/get';
import { isCancel } from 'axios';
import { internalServerErrorModalLogic } from '../common/utils';
import ValidationError from '../../assets/img/validation-error.webp';
import UploadGradesHeader from '../../components/UploadGrades/UploadGradesHeader';
import UploadGradeError from '../../components/UploadGrades/UploadGradeError';
import CustomUploadModal from '../../components/common/CustomUploadModal';
import FormattedTypography from '../../components/common/FormattedTypography';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { getProgress, getReport } from '../common/apis';
import { validateUploadGradesXLSXApiCall, uploadXlsx, downloadLog } from './apis';
import {
  AUTO_HIDE_DURATION, snackBarInitialState,
} from '../../constants';
import { parseValidationResult } from '../../helpers/xlsxValidation';
import { zeroAppender } from '../../helpers/formattingHelpers';
import StatusModal from '../../components/AssignmentManagement/StatusModal';
import CustomSnackbar from '../../components/common/CustomSnackbar';
import { getErrorMessage } from '../../helpers/apiHelper';
import AlertReleaseInfo from '../../components/ContentManagement/AlertReleaseInfo';
import UploadGradesBody from './UploadGradesBody';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  modalHeight: {
    minHeight: '400px',
  },
});

const UploadGrades = ({
  match,
  programMetadata,
  transactionIds,
  isActivityLogModalOpen,
  programType,
  programId,
}) => {
  const classes = useStyles();
  const configId = get(programMetadata, 'config_id', '');
  const programSubType = get(match, 'params.programSubType');
  const initialValidationResult = { status: 'none', errors: [] };
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [progress, setProgress] = useState({ done: true, percentage: 0 });
  const [filename, setFilename] = useState('');
  const [pollProgress, setPollProgress] = useState(true);
  const [modalState, setModalState] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);
  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: UploadGradeError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Reupload File',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
        contentDivider: true,
      },
      props: initialValidationResult,
    },
  });

  const history = useHistory();
  const { done } = progress;

  const onDownloadLog = async (data) => {
    try {
      const tid = data.transaction_id;
      const createdAt = data.created_at ? data.created_at : new Date();
      await downloadLog(tid, configId, programSubType, createdAt);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const resetFileUpload = useCallback(
    (e) => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          props: initialValidationResult,
        },
      }));
      e.target.value = '';
    },
    [initialValidationResult],
  );

  const generateFormData = useCallback(
    (file) => {
      const formData = new FormData();
      formData.append('file', file, file.name);

      return formData;
    },
    [],
  );

  useEffect(() => {
    if (transactionIds.length) {
      batchUpdates(() => {
        setTransactionId(transactionIds[0]);
        setPollProgress(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionIds.length]);

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!pollProgress) return;
      try {
        if (!transactionId) return;
        const res = await getProgress(transactionId);
        const { done: progressDone, file_name, status } = res?.data;

        if (progressDone) {
          batchUpdates(() => {
            setProgress(res?.data);
            setModalState('REPORT');
            setPollProgress(false);
          });
        } else {
          batchUpdates(() => {
            setModalState(status);
            setProgress(res?.data);
            setFilename(file_name);
            setShowAlertBar(true);
          });

          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        batchUpdates(() => {
          setModalState(null);
          setShowAlertBar(false);
          setOpenStatusModal(false);
        });
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
  }, [transactionId, programSubType, history, pollProgress]);

  useEffect(() => {
    let timerObj;
    if (done) {
      timerObj = setTimeout(() => setShowAlertBar(false), AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(timerObj);
    };
  }, [done]);

  const uploadFile = useCallback(
    async (formData, update) => {
      try {
        setModalState(null);
        const uploadResult = await uploadXlsx(
          programType,
          programSubType,
          programId,
          formData,
          update,
        );
        const tid = uploadResult?.data?.transaction_id;
        if (tid) {
          setTransactionId(tid);
          setPollProgress(true);
        } else {
          setShowAlertBar(false); // sometimes tId comes null as response
        }
      } catch (e) {
        setShowAlertBar(false);
      }
    },
    [programId, programSubType, programType],
  );

  const onUpload = useCallback(
    async (event, update) => {
      const file = event.target.files[0];
      batchUpdates(() => {
        setFilename(file?.name);
        setShowAlertBar(true);
        setProgress({ done: null, percentage: 0 });
      });
      const formData = generateFormData(file);
      resetFileUpload(event);
      let result;
      try {
        result = await validateUploadGradesXLSXApiCall(
          programType,
          programSubType,
          update,
          formData,
        );
      } catch (e) {
        batchUpdates(() => {
          setShowAlertBar(false);
          setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
          // Early exit
        });
      }
      const errorMessages = parseValidationResult(result);
      const errorMessageCount = errorMessages.errors?.length || 0;
      const [extension, ...nameParts] = file.name.split('.').reverse();

      batchUpdates(() => {
        setModalScheme((schema) => ({
          ...schema,
          VALIDATION: {
            ...schema.VALIDATION,
            wrapperProps: {
              ...schema.VALIDATION.wrapperProps,
              heading: (
                <FormattedTypography
                  prefix="Scanning file&nbsp;"
                  body={nameParts.join('.')}
                  suffix={`.${extension}`}
                  subHeading={`${zeroAppender(
                    errorMessageCount,
                  )} errors found. Please make the required changes and re-upload your file.`}
                />
              ),
            },
            props: errorMessages,
          },
        }));
      });

      if (errorMessages.errors.length) {
        setModalState('VALIDATION');
        setShowAlertBar(false);
        return;
      }

      await uploadFile(formData, update);
    },
    [generateFormData, programType, programSubType, uploadFile, resetFileUpload],
  );

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  const onViewStatus = (status) => {
    batchUpdates(() => {
      setModalState(status);
      setOpenStatusModal(true);
    });
  };

  return (
    <Paper className={classes.wrapper}>
      {showAlertBar ? (
        <AlertReleaseInfo
          progress={progress}
          showElement={showAlertBar}
          progressMessage={`Uploading file "${filename}"`}
          withErrorsMessage={`File "${filename}" upload was completed with errors.`}
          failedMessage={`Failed to upload file "${filename}". Please try again.`}
          successMessage={`File "${filename}" was successfully uploaded`}
          onViewStatus={onViewStatus}
          setShowElement={setShowAlertBar}
        />
      ) : null}
      <UploadGradesHeader shouldShow={false} />
      <UploadGradesBody
        onUpload={onUpload}
        programId={programId}
        programSubType={programSubType}
        programType={programType}
        showAlertBar={showAlertBar}
      />
      {ModalComponent && !isActivityLogModalOpen && (
        <CustomUploadModal
          open={modalSchema[modalState] !== undefined}
          onClose={() => setModalState(null)}
          className={classes.modalHeight}
          breakpoint="md"
          {...modalSchema[modalState].wrapperProps}
        >
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
      {!isActivityLogModalOpen && (
        <ErrorModal
          open={isErrorModalOpen}
          onClose={onErrorModalClose}
          message="Could not update users due to some technical error."
        />
      )}
      {openStatusModal && done !== null ? (
        <StatusModal
          open={openStatusModal}
          onClose={() => {
            setOpenStatusModal(false);
          }}
          modalState={modalState}
          transactionId={transactionId}
          getReport={getReport}
          progressData={progress}
          onDownloadLog={onDownloadLog}
          type={programSubType}
        />
      ) : null}
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
    </Paper>
  );
};

UploadGrades.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
    extendedEnterprise: PropTypes.string,
  }).isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isActivityLogModalOpen: PropTypes.bool.isRequired,
  programType: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
};

export default UploadGrades;
