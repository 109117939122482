import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  bodyWrapper: {
    flex: 1,
    padding: '1.75rem 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const ConfirmModal = ({
  open, message, onClose, onConfirm,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.wrapper }}
    >
      <div className={classes.bodyWrapper}>
        <DialogTitle>{message}</DialogTitle>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            Yes
          </Button>
          <Button variant="outlined" onClick={onClose}>
            No
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default memo(ConfirmModal);
