import FileDownload from 'js-file-download';
import config from '../../config';
import request from '../../helpers/apiHelper';

export const fetchContentApi = ({ isRefresh, programId }) => {
  const url = `${config.ROUTES.OVERVIEW_COMPONENTS}/${programId}?update=${isRefresh}&content_management=true`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const getCourseDetailsById = async (courseId) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/${courseId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const updateCourseDetailsById = async (courseId, data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/update/${courseId}`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const validateCourseBeforeClone = async (programId, data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/clone/lp/${programId}/validate`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const cloneCourseById = async (courseId, programId, data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/clone/${courseId}/lp/${programId}`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const downloadFile = async (transactionId, configId, programSubType, createdAt) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/download/clone_course/${transactionId}`;

  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;

  FileDownload(data, `${configId}: ${programSubType} - ${createdAt.toDateString()}.xlsx`);

  return result;
};

export const getCoursesAndValidateLp = async (lpConfig, cancelToken) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/components/${lpConfig}`;
  const options = { url, method: 'GET', cancelToken };
  const result = await request(options);
  return result;
};

export const getTrainingMaterials = async (componentId, programId, sourceProgramId) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/component/${componentId}/training_materials?program_id=${programId}&sourceProgramId=${sourceProgramId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const triggerImport = async (data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/trigger_import`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const getScormReleaseDetails = async (courseId, scormId) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/${courseId}/${scormId}/release`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const refreshScorm = async (courseId, scormId, data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/${courseId}/${scormId}/release`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const updateScorm = async (courseId, scormId, data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/${courseId}/${scormId}/release`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const assignOrRemoveEOP = async (courseId, programId, data) => {
  const url = `${config.ROUTES.OVERVIEW_COMPONENT}/program_id/${programId}/course_id/${courseId}/update`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const getBulkScormTMs = async (programId) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/program/${programId}/scorm_release?update=true`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const postBulkScormTMs = async (data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/program/${data?.program_id}/scorm_release`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const validateCourseCode = async (data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/course/import/validate_code`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const bulkUpdateComponentStatus = async (data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/bulk_update_component_access`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const reorderTrainingMaterials = async (data) => {
  const url = `${config.ROUTES.CONTENT_MANAGEMENT}/reorder_tms`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};
