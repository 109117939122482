import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import WrappedIcon from './WrappedIcon';

import BoxIcon from '../../../assets/img/box-icon.png';
import DoceboIcon from '../../../assets/img/docebo-icon.png';
import WrikeIcon from '../../../assets/img/wrike-icon.png';
import {
  BOX_URL, DOCEBO_URL, DOCEBO_URL_2, WRIKE_URL,
} from '../../../constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const WrappedIconRow = ({
  iconWidth,
  programMetadata,
  onLinkSave,
  editable,
}) => {
  const classes = useStyles();

  const boxUrl = programMetadata.links[BOX_URL];
  const doceboUrl = programMetadata.links[DOCEBO_URL];
  // const doceboUrl2 = programMetadata.links[DOCEBO_URL_2]; // Requirement on hold
  const wrikeUrl = programMetadata.links[WRIKE_URL];

  const boxData = { [BOX_URL]: { url: boxUrl, title: 'Box Folder' } };
  const wrikeData = { [WRIKE_URL]: { url: wrikeUrl, title: 'Wrike Link' } };
  const doceboData = {
    [DOCEBO_URL]: { url: doceboUrl, title: 'Launch Checklist' },
    // [DOCEBO_URL_2]: { url: doceboUrl2, title: 'Link 2' }, // Requirement on hold
  };

  return (
    <div className={classes.wrapper}>
      <WrappedIcon
        data={boxData}
        icon={BoxIcon}
        width={iconWidth}
        onLinkSave={onLinkSave}
        editable={editable}
      />
      <WrappedIcon
        data={wrikeData}
        icon={WrikeIcon}
        width={iconWidth}
        onLinkSave={onLinkSave}
        editable={editable}
      />
      <WrappedIcon
        data={doceboData}
        icon={DoceboIcon}
        width={iconWidth}
        onLinkSave={onLinkSave}
        editable={editable}
      />
    </div>
  );
};

WrappedIconRow.propTypes = {
  iconWidth: PropTypes.string,
  programMetadata: PropTypes.shape({
    links: PropTypes.shape({
      [BOX_URL]: PropTypes.string,
      [DOCEBO_URL]: PropTypes.string,
      [DOCEBO_URL_2]: PropTypes.string,
      [WRIKE_URL]: PropTypes.string,
    }),
  }).isRequired,
  onLinkSave: PropTypes.func,
  editable: PropTypes.bool,
};

WrappedIconRow.defaultProps = {
  iconWidth: '3rem',
  onLinkSave: () => null,
  editable: false,
};

export default WrappedIconRow;
