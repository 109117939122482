import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { VictoryPie, VictoryLabel } from 'victory';
import { DARK_MEDIUM_GREY, PIE_CHART_BLUE, PIE_CHART_RED } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  svgContainer: {
    width: ({ svgViewportWidth }) => `${svgViewportWidth}px`,
  },
});

const PieChart = ({
  primaryCount,
  secondaryCount,
  tertiaryCount,
  label,
  title,
  subtitle,
  colorScale,
  svgViewportWidth,
  svgViewportHeight,
}) => {
  const classes = useStyles(({ svgViewportWidth }));

  const total = primaryCount + secondaryCount + tertiaryCount;

  const primaryDegrees = (primaryCount / total) * 360;
  const secondaryDegrees = (secondaryCount / total) * 360;
  const tertiaryDegrees = total !== 0 ? (tertiaryCount / total) * 360 : 360; // Empty pie

  const minDim = Math.min(svgViewportWidth, svgViewportHeight);

  const PIE_CHART_SIZE = svgViewportWidth;
  const PIE_CHART_RADIUS_INNER = minDim * 0.28;
  const PIE_CHART_RADIUS = minDim * 0.33;

  const TITLE_X = PIE_CHART_SIZE * 0.5;
  const TITLE_Y = svgViewportHeight * 0.45;

  const SUBTITLE_X = TITLE_X;
  const SUBTITLE_Y = svgViewportHeight * 0.55;

  const LABEL_X = TITLE_X;
  const LABEL_Y = svgViewportHeight * 0.9;

  const fontSizeScaler = minDim / 300;

  return (
    <div className={classes.wrapper}>
      <svg viewBox={`0 0 ${svgViewportWidth} ${svgViewportHeight}`} className={classes.svgContainer}>
        <VictoryPie
          standalone={false}
          width={PIE_CHART_SIZE}
          height={PIE_CHART_SIZE}
          data={[
            { x: 1, y: primaryDegrees },
            { x: 2, y: secondaryDegrees },
            { x: 3, y: tertiaryDegrees },
          ]}
          innerRadius={PIE_CHART_RADIUS_INNER}
          radius={PIE_CHART_RADIUS}
          colorScale={colorScale}
          labels={[]}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 45 * fontSizeScaler, fontFamily: 'Bower', fontWeight: 'bold' }}
          x={TITLE_X}
          y={TITLE_Y}
          text={title}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 15 * fontSizeScaler, fontFamily: 'McKinseySans', fill: DARK_MEDIUM_GREY }}
          x={SUBTITLE_X}
          y={SUBTITLE_Y}
          text={subtitle}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 18 * fontSizeScaler, fontFamily: 'McKinseySans' }}
          x={LABEL_X}
          y={LABEL_Y}
          text={label}
        />
      </svg>
    </div>
  );
};

PieChart.propTypes = {
  primaryCount: PropTypes.number.isRequired,
  secondaryCount: PropTypes.number.isRequired,
  tertiaryCount: PropTypes.number,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  colorScale: PropTypes.arrayOf(PropTypes.string),
  svgViewportWidth: PropTypes.number,
  svgViewportHeight: PropTypes.number,
};

PieChart.defaultProps = {
  colorScale: [PIE_CHART_BLUE, PIE_CHART_RED, PIE_CHART_RED],
  tertiaryCount: 0,
  svgViewportWidth: 300,
  svgViewportHeight: 300,
};

export default PieChart;
