import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CancelToken, isCancel } from 'axios';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../components/common/Header';
import FormattedTypography from '../../components/common/FormattedTypography';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import EventForm from '../../components/WorkshopManagement/WorkshopManagementForms';
import Course from './course';
import Session from './session';
import Upload from './upload';
import {
  createEvent, editEvent, listVenues, getEvent,
  syncEnrolledUsers, syncSingleSessionUsers, syncAllUsers,
} from './apis';
import {
  COMPLETED_WITH_ERRORS, FAILED, SINGLE_SESSION_SYNC, SYNC_SESSION_ENROLLMENTS,
  WORKSHOP_MANAGEMENT_MODELS,
} from '../../constants';
import DownloadSuccessBanner from '../../components/AssignmentManagement/DownloadSuccessBanner';
import AlertBarWithAction from '../../components/common/AlertBarWithAction';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import SyncConfirmationModal from '../../components/WorkshopManagement/SessionEnrollmentSyncModal/SyncConfirmationModal';
import SyncStatusModal from '../../components/WorkshopManagement/SessionEnrollmentSyncModal/SyncStatusModal';
import UploadStatusModal from '../../components/WorkshopManagement/SessionEnrollmentSyncModal/UploadStatusModal';
import { internalServerErrorModalLogic } from '../common/utils';
import { downloadFile, getProgress, getReport } from '../common/apis';
import SnackbarCustom from '../../components/common/SnackbarCustom';
import { reportPageDateTimeFormatter } from '../../helpers/formattingHelpers';
import AlertReleaseInfo from '../../components/ContentManagement/AlertReleaseInfo';
import MarkAllIUsersStatusModal from '../../components/WorkshopManagement/MarkAllIUsersStatusModal';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '3rem 4rem',
  },
  width100: {
    width: '100%',
  },
  modalHeight: {
    minHeight: '400px',
  },
});

const WorkshopManagementPage = (props) => {
  const classes = useStyles();
  const { match, programMetadata } = props;
  const [model, setModel] = useState({
    view: 'NO_MODEL',
  });
  const [infoMessage, setInfoMessage] = useState({ type: 'warning', message: '' });
  const [alertMessage, setAlertMessage] = useState({ type: 'warning', message: '' });
  const [fetchLatestData, setFetchLatestData] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [userCloseModal, setUserCloseModal] = useState(true);
  const [showElement, setShowElement] = useState(false);
  // Sync users
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [syncInitiatedSessionId, setSyncInitiatedSessionId] = useState(null);
  const [syncButtonDisabled, setSyncButtonDisabled] = useState(false);
  const [lastSyncedOn, setLastSyncedOn] = useState(null);
  const [deltaExists, setDeltaExists] = useState(false);
  const [syncTransaction, setSyncTransaction] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [deltaNotFoundMessage, setDeltaNotFoundMessage] = useState(null);
  // Mark set of users complete states
  const [openStatusModalForUpload, setopenStatusModalForUpload] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [progressforUpload, setprogressforUpload] = useState({ done: null, percentage: 0 });
  const [showUploadElement, setShowUploadElement] = useState(false);
  const [uploadUserTid, setUploadUserTid] = useState(null);
  // Mark all users complete states
  const [pollProgress, setPollProgress] = useState(true);
  const [allUsersProgress, setAllUsersProgress] = useState({ done: null, percentage: 0 });
  const [showAllUsersAlertBar, setShowAllUsersAlertBar] = useState(false);
  const [completeAllUsersTid, setCompleteAllUsersTid] = useState(null);
  const [openStatusModalForAllUsers, setOpenStatusModalForAllUsers] = useState(null);
  // Refs and constants
  const [saveAndAddButtonClicked, setSaveAndAddButtonClicked] = useState(false);
  const formRef = useRef();
  const saveAndAddButtonText = 'Save and Add another Event';
  const courseId = get(match, 'params.transactionId');
  const programId = get(match, 'params.programId');
  const uploadUsers = get(match, 'params.uploadUsers');
  const programSubType = get(match, 'params.programSubType');
  const configId = get(programMetadata, 'config_id', '');
  const history = useHistory();

  const modelsView = {
    [WORKSHOP_MANAGEMENT_MODELS.ADD_EVENT]: {
      isContainForm: true,
      title: (
        <FormattedTypography
          body="Add Event"
          subHeading="Add Event details for this session"
          dense
          suffixClassName={classes.bold}
        />
      ),
      content: (
        <EventForm
          ref={formRef}
          sessionId={model.sessionId}
          onSubmit={createEvent}
          listVenues={listVenues}
          setAlertMessage={setAlertMessage}
          setInfoMessage={setInfoMessage}
          onDone={() => {
            setFetchLatestData(true);
            setModel({
              view: 'NO_MODEL',
            });
          }}
          onDoneAndAdd={(sessionId) => {
            setTimeout(() => {
              setModel({
                view: WORKSHOP_MANAGEMENT_MODELS.ADD_EVENT,
                sessionId,
              });
            }, 500);
            setSaveAndAddButtonClicked(false);
          }}
          saveAndAddButtonClicked={saveAndAddButtonClicked}
        />
      ),
      open: true,
      onClose: () => setModel({
        view: 'NO_MODEL',
      }),
      onSubmit: (e) => formRef.current.handleSubmit(e),
      actions: (
        <>
          <Button
            variant="outlined"
            onClick={(e) => {
              setSaveAndAddButtonClicked(true);
              formRef.current.handleSubmit(e);
            }}
          >
            {saveAndAddButtonText}
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </>
      ),
    },
    [WORKSHOP_MANAGEMENT_MODELS.EDIT_EVENT]: {
      isContainForm: true,
      title: (
        <FormattedTypography
          body="Edit Event"
          subHeading="Edit Event details for this session"
          dense
          suffixClassName={classes.bold}
        />
      ),
      content: (
        <EventForm
          ref={formRef}
          sessionId={model.sessionId}
          eventId={model.eventId}
          onSubmit={editEvent}
          listVenues={listVenues}
          getEvent={getEvent}
          setAlertMessage={setAlertMessage}
          setInfoMessage={setInfoMessage}
          onDone={() => {
            setFetchLatestData(true);
            setModel({
              view: 'NO_MODEL',
            });
          }}
        />
      ),
      open: true,
      onClose: () => setModel({
        view: 'NO_MODEL',
      }),
      onSubmit: (e) => formRef.current.handleSubmit(e),
      actions: (
        <Button color="primary" variant="contained" type="submit">
          Save
        </Button>
      ),
    },
    NO_MODEL: {
      open: false,
      onClose: () => { },
    },
  };

  useEffect(() => {
    const allSessionSyncTransactionIds = get(
      programMetadata,
      'activities_status.course_ops.sync_session_enrollments.transaction_ids',
      null,
    );
    const singleSessionSyncTransactionIds = get(
      programMetadata,
      'activities_status.course_ops.single_session_sync.transaction_ids',
      null,
    );
    const markUserUploadTransactionIds = get(
      programMetadata,
      'activities_status.course_ops.webinar_management.transaction_ids',
      null,
    );
    const markAllUsersCompleteTransactionIds = get(
      programMetadata,
      'activities_status.course_ops.mark_all_users_complete.transaction_ids',
      null,
    );

    let transactionIds = {
      allSessionSync: 0,
      singleSessionSync: 0,
      markSetUsersComplete: 0,
      markAllUsersComplete: 0,
    };

    if (allSessionSyncTransactionIds && allSessionSyncTransactionIds[0]) {
      // eslint-disable-next-line prefer-destructuring
      transactionIds.allSessionSync = allSessionSyncTransactionIds[0];
    }
    if (singleSessionSyncTransactionIds && singleSessionSyncTransactionIds[0]) {
      // eslint-disable-next-line prefer-destructuring
      transactionIds.singleSessionSync = singleSessionSyncTransactionIds[0];
    }
    if (markUserUploadTransactionIds && markUserUploadTransactionIds[0]) {
      // eslint-disable-next-line prefer-destructuring
      transactionIds.markSetUsersComplete = markUserUploadTransactionIds[0];
    }
    if (markAllUsersCompleteTransactionIds && markAllUsersCompleteTransactionIds[0]) {
      // eslint-disable-next-line prefer-destructuring
      transactionIds.markAllUsersComplete = markAllUsersCompleteTransactionIds[0];
    }

    transactionIds = Object.entries(transactionIds).sort(([, a], [, b]) => a - b);
    transactionIds = transactionIds.reverse();
    async function checkActiveTid() {
      let res;
      for (let i = 0; i < transactionIds.length; i += 1) {
        const tId = transactionIds[i][1];
        const activeFeature = transactionIds[i][0];
        if (tId) {
          // eslint-disable-next-line no-await-in-loop
          res = await getProgress(tId);
          const { done } = res.data;
          if (!done) {
            if (activeFeature === 'allSessionSync') {
              setSyncTransaction(allSessionSyncTransactionIds[0]);
              break;
            }
            if (activeFeature === 'singleSessionSync') {
              setSyncTransaction(singleSessionSyncTransactionIds[0]);
              break;
            }
            if (activeFeature === 'markSetUsersComplete') {
              setUploadUserTid(markUserUploadTransactionIds[0]);
              break;
            }
            if (activeFeature === 'markAllUsersComplete') {
              setCompleteAllUsersTid(markAllUsersCompleteTransactionIds[0]);
              break;
            }
          }
        }
      }
    }
    checkActiveTid();
  }, [programMetadata]);

  useEffect(() => {
    let timer = null;

    const pollProgressApi = async () => {
      try {
        const res = await getProgress(completeAllUsersTid);
        const { done } = res.data;
        if (done) {
          batchUpdates(() => {
            setAllUsersProgress(res?.data);
            setPollProgress(false);
            setShowAllUsersAlertBar(true);
          });
        } else {
          batchUpdates(() => {
            setAllUsersProgress(res.data);
            setShowAllUsersAlertBar(true);
            setPollProgress(true);
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        batchUpdates(() => {
          setShowAllUsersAlertBar(false);
          setPollProgress(false);
          setCompleteAllUsersTid(null);
        });
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    if (completeAllUsersTid && pollProgress) {
      pollProgressApi();
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeAllUsersTid]);

  const onAllUsersProceed = useCallback(async (course_id) => {
    setShowAllUsersAlertBar(true);
    setAllUsersProgress({ done: null, percentage: 0 });
    try {
      const { data } = await syncAllUsers({ program_id: programId }, course_id);
      batchUpdates(() => {
        setPollProgress(true);
        setCompleteAllUsersTid(data?.transaction_id || null);
      });
    } catch (e) {
      setShowAllUsersAlertBar(false);
    }
  }, [programId]);

  const onSyncConfirmButtonClick = useCallback(async () => {
    setSyncButtonDisabled(true);
    setProgress({ done: null, percentage: 0 });
    try {
      const { data } = syncInitiatedSessionId
        ? await syncSingleSessionUsers(programId, syncInitiatedSessionId)
        : await syncEnrolledUsers(programId);
      batchUpdates(() => {
        setDeltaExists(!data?.delta || null);
        if (!data?.delta) {
          setSyncButtonDisabled(false);
          setDeltaNotFoundMessage(
            data?.message || 'Enrollments are up to date, sync not required.',
          );
          if (syncInitiatedSessionId) setSyncInitiatedSessionId(null);
        } else {
          setSyncTransaction(data?.transaction_id || null);
          setShowElement(true);
        }
        setOpenConfirmationModal(false);
      });
    } catch (e) {
      setSyncButtonDisabled(false);
      if (syncInitiatedSessionId) setSyncInitiatedSessionId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, syncInitiatedSessionId]);

  useEffect(() => {
    let timer = null;
    let cancelTokenSource = null;

    const pollProgressApi = async () => {
      try {
        if (cancelTokenSource) {
          cancelTokenSource.cancel();
        }

        cancelTokenSource = CancelToken.source();
        const res = await getProgress(syncTransaction, cancelTokenSource.token);
        const { done } = res.data;
        if (done) {
          if (courseId && !uploadUsers) {
            setFetchLatestData(true);
          }
          batchUpdates(() => {
            setProgress(res.data);
            setLastSyncedOn(res.data.updated_at);
            setSyncButtonDisabled(false);
            if (syncInitiatedSessionId) setSyncInitiatedSessionId(null);
          });
        } else {
          batchUpdates(() => {
            setProgress(res.data);
            setSyncButtonDisabled(true);
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    if (syncTransaction) {
      pollProgressApi();
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncTransaction, history]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        const res = await getProgress(uploadUserTid);
        const { done } = res.data;
        if (done) {
          batchUpdates(() => {
            setprogressforUpload(res.data);
            setButtonDisabled(false);
            setShowUploadElement(true);
            setShowElement(false);
            setUploadUserTid(null);
          });
        } else {
          batchUpdates(() => {
            setprogressforUpload(res.data);
            setButtonDisabled(true);
            if (res.data.status === 'QUEUED' && !userCloseModal) {
              setopenStatusModalForUpload(true);
            }
            setShowElement(false);
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    if (uploadUserTid) {
      pollProgressApi();
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadUserTid]);

  useEffect(() => {
    if (progress?.done) {
      setTimeout(() => setShowElement(false), 6000);
    }
    if (progressforUpload?.done) {
      setTimeout(() => setShowUploadElement(false), 6000);
    }
    if (allUsersProgress?.done) {
      setTimeout(() => setShowAllUsersAlertBar(false), 6000);
    }
  }, [allUsersProgress.done, progress, progressforUpload]);

  const onModalSyncAgainClick = () => {
    batchUpdates(() => {
      setOpenConfirmationModal(true);
      setOpenStatusModal(false);
      onSyncConfirmButtonClick();
    });
  };

  const onSingleSessionSyncClick = useCallback((session_id) => {
    batchUpdates(() => {
      setOpenConfirmationModal(true);
      setSyncInitiatedSessionId(session_id);
    });
  }, []);

  const markAsaUser = useCallback((transaction_id) => {
    batchUpdates(() => {
      setprogressforUpload({ done: null, percentage: 0 });
      setUploadUserTid(transaction_id);
      setUserCloseModal(false);
    });
  }, []);

  const onSyncConfirmationModalClose = useCallback(() => {
    if (syncInitiatedSessionId) setSyncInitiatedSessionId(null);
    setOpenConfirmationModal(false);
  }, [syncInitiatedSessionId]);

  const onDownloadLog = async (data) => {
    try {
      await downloadFile(
        completeAllUsersTid, configId,
        programSubType, data?.created_at || new Date(),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const AlertCompForUpload = () => {
    if (!progressforUpload?.done && progressforUpload?.done !== null) {
      return (
        <AlertBarWithAction
          variant="with-percentage"
          percentage={!progressforUpload?.done ? progressforUpload?.percentage : null}
          labelText={`Uploading Workshop Completion file "${progressforUpload.excel_file}"`}
          actionButtonText="View Status"
          onActionClick={() => setopenStatusModalForUpload(true)}
        />
      );
    }
    if (progressforUpload?.done) {
      if ([FAILED].includes(progressforUpload?.status)) {
        return (showUploadElement
          ? (
            <AlertBarWithAction
              variant="error"
              percentage={null}
              labelText={`Failed to upload Workshop Completion file "${progressforUpload.excel_file}". Please try again.`}
              actionButtonText="View Details"
              onActionClick={() => setopenStatusModalForUpload(true)}
            />
          ) : null
        );
      } if ([COMPLETED_WITH_ERRORS].includes(progressforUpload?.status)) {
        return (showUploadElement
          ? (
            <AlertBarWithAction
              variant="error"
              percentage={null}
              labelText={`Workshop Completion file "${progressforUpload.excel_file}" upload was completed with errors.`}
              actionButtonText="View Details"
              onActionClick={() => setopenStatusModalForUpload(true)}
            />
          ) : null
        );
      }
      return (showUploadElement
        ? (
          <AlertBarWithAction
            variant="success"
            percentage={null}
            labelText={`Workshop completion file "${progressforUpload.excel_file}" was successfully uploaded`}
            actionButtonIcon={
              <CloseIcon onClick={() => setShowUploadElement(false)} />
            }
          />
        ) : null
      );
    }
    return <></>;
  };
  const AlertComp = () => {
    if (!progress?.done && progress?.done !== null) {
      return (
        <AlertBarWithAction
          variant="with-percentage"
          percentage={!progress?.done ? progress?.percentage : null}
          labelText="Syncing users into Sessions"
          actionButtonText="View Status"
          onActionClick={() => setOpenStatusModal(true)}
        />
      );
    }
    if (progress?.done && !syncButtonDisabled) {
      if ([COMPLETED_WITH_ERRORS, FAILED].includes(progress?.status)) {
        return (
          <>
            {showElement ? (
              <AlertBarWithAction
                variant="error"
                percentage={null}
                labelText="Sync was completed with errors. Sync again!"
                actionButtonText="View Details"
                onActionClick={() => setOpenStatusModal(true)}
              />
            ) : null}
          </>
        );
      }
      return (
        <>
          {showElement ? (
            <AlertBarWithAction
              variant="success"
              percentage={null}
              labelText="All users were successfully synced!"
              actionButtonIcon={
                <CloseIcon onClick={() => setShowElement(false)} />
              }
            />
          ) : null}
        </>
      );
    }
    return <></>;
  };
  // eslint-disable-next-line no-nested-ternary
  const Component = courseId ? (uploadUsers ? Upload : Session) : Course;
  return (
    <>
      <Paper className={classes.wrapper}>
        {infoMessage.message && (
          <DownloadSuccessBanner
            type={infoMessage.type}
            text={infoMessage.message}
            onClose={() => {
              setInfoMessage({ ...infoMessage, message: '' });
            }}
          />
        )}
        {syncTransaction && progress && !openStatusModal ? <AlertComp /> : null}
        {!openStatusModalForUpload ? <AlertCompForUpload /> : null}
        {!openStatusModalForAllUsers && showAllUsersAlertBar
          ? (
            <AlertReleaseInfo
              setShowElement={setShowAllUsersAlertBar}
              programSubType={programSubType}
              progress={allUsersProgress}
              showElement={showAllUsersAlertBar}
              progressMessage="Updating workshop completion for all users."
              withErrorsMessage="Workshop completion for all users was updated with errors."
              failedMessage="Failed to update workshop completion for all users. Please try again."
              successMessage="Workshop completion for all users was successfully updated!"
              onViewStatus={() => { setOpenStatusModalForAllUsers(true); }}
            />
          ) : null}
        {
          !uploadUsers
        && (
        <Header
          heading="Workshop Management"
          subHeading="Manage Sessions and Events for this Learning Plan"
        >
          <Grid item className={classes.componentsBreakdown}>
            {lastSyncedOn ? `Last synced on: ${reportPageDateTimeFormatter(new Date(lastSyncedOn))}` : 'No sync initiated yet'}
          </Grid>
        </Header>
        )
        }

        <div className={classes.bodyContainer}>
          <Component
            {...props}
            setModel={setModel}
            setInfoMessage={setInfoMessage}
            fetchLatestData={fetchLatestData}
            setFetchLatestData={setFetchLatestData}
            enableSyncAgain={progress?.program_sub_type === SYNC_SESSION_ENROLLMENTS
              && [COMPLETED_WITH_ERRORS, FAILED].includes(progress?.status)}
            setOpenConfirmationModal={setOpenConfirmationModal}
            onSingleSessionSyncClick={onSingleSessionSyncClick}
            syncInitiatedSessionId={syncInitiatedSessionId}
            // eslint-disable-next-line max-len
            isDisabled={(syncButtonDisabled || (pollProgress && completeAllUsersTid) || (buttonDisabled && ![COMPLETED_WITH_ERRORS, FAILED].includes(progressforUpload?.status)))}
            markAsaUser={markAsaUser}
            onAllUsersProceed={onAllUsersProceed}
          />
        </div>
      </Paper>
      <ConfirmationModal
        dialogContentClass={classes.width100}
        minHeightClass={classes.modalHeight}
        modalWidth={85}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        {...modelsView[model.view]}
      />
      <SyncConfirmationModal
        loadingLabelText="Checking for enrollment updates, you can track progress for this activity in the process queue."
        open={!!openConfirmationModal}
        isLoading={syncButtonDisabled}
        onClose={() => onSyncConfirmationModalClose()}
        onSyncConfirmButtonClick={onSyncConfirmButtonClick}
      />
      {openStatusModalForUpload ? (
        <UploadStatusModal
          open={openStatusModalForUpload}
          onClose={() => { setopenStatusModalForUpload(false); setUserCloseModal(true); }}
          progress={progressforUpload}
          programId={programId}
        />
      ) : null}
      {openStatusModal ? (
        <SyncStatusModal
          open={openStatusModal}
          onClose={() => setOpenStatusModal(false)}
          progress={progress}
          onSyncAgainClick={onModalSyncAgainClick}
          programId={programId}
          hideSyncAgain={progress?.program_sub_type === SINGLE_SESSION_SYNC}
        />
      ) : null}
      {openStatusModalForAllUsers ? (
        <MarkAllIUsersStatusModal
          open={openStatusModalForAllUsers}
          onClose={() => { setOpenStatusModalForAllUsers(false); }}
          modalState={allUsersProgress?.done ? 'REPORT' : allUsersProgress?.status}
          transactionId={completeAllUsersTid}
          getReport={getReport}
          progressData={allUsersProgress}
          onDownloadLog={onDownloadLog}
          type={programSubType}
          configId={configId}
        />
      ) : null}
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
      {deltaExists ? (
        <SnackbarCustom
          open={deltaExists}
          message={deltaNotFoundMessage}
          severity="error"
          onClose={() => setDeltaExists(false)}
        />
      ) : null}
    </>
  );
};

WorkshopManagementPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
      programSubType: PropTypes.string,
      transactionId: PropTypes.string,
      uploadUsers: PropTypes.string,
    }),
  }).isRequired,
  programMetadata: PropTypes.object.isRequired,
};

export default WorkshopManagementPage;
