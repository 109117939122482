import React from 'react';
import PropTypes from 'prop-types';
import ChipInput from 'material-ui-chip-input';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  wrapper: {
    marginBottom: '1rem',
  },
});

const MuiChipInput = ({ value, name, onChange }) => {
  const classes = useStyles();

  const onChipAdd = (chip) => {
    const newValue = [...value, chip].join(',');
    onChange(name, newValue);
  };

  const onChipRemove = (chip, idx) => {
    const newValue = Array.from(value);
    newValue.splice(idx, 1);
    onChange(name, newValue.join(','));
  };
  return (
    <div className={classes.wrapper}>
      <ChipInput
        fullWidth
        value={value}
        onAdd={onChipAdd}
        onDelete={onChipRemove}
        onBeforeAdd={(e) => {
          if (e.length === 0 || e.length > 50) {
            return false;
          }
          return true;
        }}
      />
    </div>
  );
};

MuiChipInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MuiChipInput;
