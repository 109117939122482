import React, { useEffect, useCallback } from 'react';
import { useCustomCompareCallback } from 'use-custom-compare';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import NoResultsIllustration from '../../assets/img/landing-page-empty-illustration.svg';

import {
  DARK_GREY,
  LIGHT_MEDIUM_GREY,
  MCKINSEY_BLUE,
  VERY_LIGHT_GREY,
  WHITE,
} from '../../stylesheets/colors';
import {
  GROUPWORK_GROUPS,
  TEST_USERS_CREATION,
  BULK_UNENROLLMENT,
  ASSIGN_COURSE_CONTENT,
  PROGRAM_CREATION,
  STAGE_IN_BUILD,
  STAGE_LAUNCHED,
  STAGE_UNKNOWN,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  AUTO_ENROLLMENT,
  STAGE_DELETED,
  STAGE_REPLICATED,
  SETTINGS,
  BULK_UPDATE_ACCOUNTS,
  WORKSHOP_MANAGEMENT,
  ASSIGNMENT_MANAGEMENT,
  METADATA_MANAGEMENT,
  EDIT_PROGRAM,
  REPLICATION,
  MODIFY_GRADE,
  UPLOAD_GRADE,
  BULK_METADATA_MANAGEMENT,
  SYNC_USER_MANAGEMENT,
  SYNC_SESSION_ENROLLMENTS,
  FORCE_ENROLL_USER,
  ASSIGNMENT_DOWNLOAD,
  SINGLE_SESSION_SYNC,
  CLONE_COURSE,
  INDIVIDUAL_SCORM_REFRESH,
  MULTIPLE_SCORM_REFRESH,
  IMPORT_COURSE,
  PRODUCTION_CHECKLIST,
} from '../../constants';

import LandingPageCard from './LandingPageCard';
import CreateNewProgramCard from './CreateNewProgramCard';
import StageSelectionDropdown from './StageSelectionDropdown';
import LoadMoreComponent from '../common/LoadMore';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: VERY_LIGHT_GREY,
    padding: '4rem',
  },
  allProgramsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    fontFamily: 'Bower',
    fontSize: '3.25rem',
    fontWeight: 'bold',
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
  },
  createProgramButton: {
    width: '10rem',
    height: '2.5rem',
    backgroundColor: MCKINSEY_BLUE,
    color: WHITE,
  },
  searchRow: {
    display: 'flex',
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    minHeight: '1.5rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  searchInput: {
    flex: 0.7, // ~44rem
    height: '2.5rem',
    borderColor: LIGHT_MEDIUM_GREY,
    fontSize: '14px',
    border: `1px solid ${LIGHT_MEDIUM_GREY}`,
    paddingLeft: '10px',
    outline: 'none !important',
    outlineOffset: 'none !important',
  },
  searchButton: {
    color: WHITE,
    backgroundColor: MCKINSEY_BLUE,
    width: '5rem',
    height: '2.5rem',
    marginLeft: '0.75rem',
    fontFamily: 'McKinseySans',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  cardContainer: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 2%',
    '&:nth-child(4n + 1)': {
      paddingLeft: 0,
    },
    '&:nth-child(4n)': {
      paddingRight: 0,
    },
  },
  noResultsWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '2rem 0px',
  },
  noResultsImage: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: '2rem',
  },
  noResultsText: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'Bower',
    color: DARK_GREY,
    fontSize: '3.25rem',
    fontWeight: 'bold',
    lineHeight: '1.21',
  },
  countLabel: {
    marginRight: '1rem',
  },
  bold: {
    fontWeight: 'bold',
  },
});

// https://stackoverflow.com/a/59226455/1217998
const debouncer = debounce((f) => f(), 700);

const EmptyPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.noResultsWrapper}>
      <img
        className={classes.noResultsImage}
        src={NoResultsIllustration}
        width={500}
        height={500}
        alt="No results"
      />
      <Typography className={classes.noResultsText}>
        Oops!
        <br />
        Could not find the program you were looking for.
      </Typography>
    </div>
  );
};

const stageOptions = [
  { label: 'In Build', value: STAGE_IN_BUILD },
  { label: 'QA', value: STAGE_QA },
  { label: 'Launch Prep', value: STAGE_LAUNCH_PREP },
  { label: 'Launched', value: STAGE_LAUNCHED },
  { label: 'Unknown', value: STAGE_UNKNOWN },
  { label: 'Replicated', value: STAGE_REPLICATED },
  { label: 'Deleted', value: STAGE_DELETED },
];
const defaultStageSelection = [
  STAGE_IN_BUILD,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  STAGE_LAUNCHED,
  STAGE_UNKNOWN,
  STAGE_REPLICATED,
];

const formatProgramStatus = (programStatus) => ({
  [GROUPWORK_GROUPS]: 'Last Action: Groupwork groups creation',
  [TEST_USERS_CREATION]: 'Last Action: Test Users creation', // Both prod and ops
  [BULK_UNENROLLMENT]: 'Last Action: Users Unenroll',
  [ASSIGN_COURSE_CONTENT]: 'Last Action: Course Content Assign',
  [PROGRAM_CREATION]: 'Last Action: Program creation',
  [AUTO_ENROLLMENT]: 'Last Action: Users Enroll',
  [SETTINGS]: 'Last Action: Settings',
  [BULK_UPDATE_ACCOUNTS]: 'Last Action: Activate/Deactivate users',
  [WORKSHOP_MANAGEMENT]: 'Last Action: Workshop Management',
  [ASSIGNMENT_MANAGEMENT]: 'Last Action: Assignment Management',
  [ASSIGNMENT_DOWNLOAD]: 'Last Action: Assignment Management',
  [METADATA_MANAGEMENT]: 'Last Action: Metadata Management',
  [BULK_METADATA_MANAGEMENT]: 'Last Action: Metadata Management',
  [SYNC_USER_MANAGEMENT]: 'Last Action: Metadata Management',
  [SYNC_SESSION_ENROLLMENTS]: 'Last Action: Workshop Management',
  [SINGLE_SESSION_SYNC]: 'Last Action: Workshop Management',
  [MODIFY_GRADE]: 'Last Action: Modify grade',
  [UPLOAD_GRADE]: 'Last Action: Upload grade',
  [EDIT_PROGRAM]: 'Last Action: Modify Config ID',
  [REPLICATION]: 'Last Action: Replication',
  [FORCE_ENROLL_USER]: 'Last Action: Force User Enrollment',
  [CLONE_COURSE]: 'Last Action: Copy Component',
  [INDIVIDUAL_SCORM_REFRESH]: 'Last Action: Release Management',
  [MULTIPLE_SCORM_REFRESH]: 'Last Action: Release Management',
  [IMPORT_COURSE]: 'Last Action: Import Component',
  [PRODUCTION_CHECKLIST]: 'Last Action: Production Checklist',
}[programStatus] || '');

const LandingPage = ({
  cards,
  onSearch,
  onStageChange,
  onCreateNewProgram,
  programType,
  showCreateNewProgram,
  onLoadMore,
  hasMore,
  isLoading,
  programCount,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [searchText, setSearchText] = React.useState('');
  const [stageSelections, setStageSelections] = React.useState(defaultStageSelection);

  const onApplyFilters = useCustomCompareCallback(
    () => {
      onStageChange(stageSelections.join(','));
    },
    [stageSelections, onStageChange],
    (prevDeps, nextDeps) => _isEqual(prevDeps[0].sort(), nextDeps[0].sort()),
  );

  const setCurrentSearchText = () => {
    setSearchText(get(history, 'location.state.currentSearchText', ''));
    history.replace();
  };

  useEffect(() => {
    setCurrentSearchText();
    onApplyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => debouncer(() => {
      onSearch(searchText);
    }),
    [onSearch, searchText],
  );

  const onSearchInner = useCallback(
    (event) => {
      const newText = event.target.value;
      setSearchText(newText);
    },
    [setSearchText],
  );

  const cardsDom = cards.map((card) => (
    <div className={classes.cardContainer} key={card.program_id}>
      <LandingPageCard
        updatedBy={`${card.lastActivityFirstName} ${card.lastActivityLastName}`}
        configId={card.config_id}
        status={formatProgramStatus(card.status)}
        stage={card.stage}
        docebo={card.docebo && !card.replicated}
        programId={card.program_id}
        programType={programType}
        currentSearchText={searchText}
        isAcademyGo={card.is_academy_go}
      />
    </div>
  ));

  if (showCreateNewProgram && get(cards, 'length')) {
    cardsDom.unshift(
      <div className={classes.cardContainer} key="CREATE_NEW_PROGRAM">
        <CreateNewProgramCard onClick={onCreateNewProgram} />
      </div>,
    );
  }

  const isEmpty = get(cards, 'length') === 0 && !isLoading && !hasMore;

  return (
    <div className={classes.wrapper}>
      <div className={classes.searchRow}>
        <div className={classes.tabWrapper}>
          {programCount > 0 && !isLoading
            && (
              <Typography variant="subtitle1" className={classes.countLabel}>
                Total Programs (
                {programCount}
                )
              </Typography>
            )}
          <StageSelectionDropdown
            options={stageOptions}
            onChange={setStageSelections}
            selections={stageSelections}
            onButtonClick={onApplyFilters}
            defaultSelections={defaultStageSelection}
          />
        </div>
        <div className={classes.searchWrapper}>
          <input
            type="text"
            className={classes.searchInput}
            onChange={onSearchInner}
            value={searchText}
            placeholder="Search for a program..."
          />
          <button
            type="button"
            className={classes.searchButton}
            onClick={() => onSearch(searchText)}
          >
            Search
          </button>
        </div>
      </div>
      <div className={classes.bodyWrapper}>{!isEmpty ? cardsDom : <EmptyPage />}</div>
      <LoadMoreComponent
        totalItems={programCount}
        onLoadMore={onLoadMore}
        loadMoreText="programs"
        itemsShown={cards.length}
        hasMore={hasMore}
        isLoading={isLoading}
      />
    </div>
  );
};

LandingPage.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      config_id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      stage: PropTypes.string.isRequired,
      program_id: PropTypes.number.isRequired,
      docebo: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onSearch: PropTypes.func.isRequired,
  onStageChange: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCreateNewProgram: PropTypes.func.isRequired,
  programType: PropTypes.string.isRequired,
  showCreateNewProgram: PropTypes.bool.isRequired,
  programCount: PropTypes.number.isRequired,
};

export default LandingPage;
