import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import ConfirmationModal from '../common/ConfirmationModal';
import ForceEnrollLogo from '../../assets/img/force-enroll-logo.png';
import { MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '6.25rem',

    '& > span:nth-child(2)': {
      marginTop: '2rem',
      fontWeight: 'bold',
    },

    '& > span:nth-child(3)': {
      color: MEDIUM_GREY,
      marginTop: '0.5rem',
    },

    '& > span:nth-child(4)': {
      color: MEDIUM_GREY,
    },
  },
  modalStyle: {
    minWidth: '70rem',
  },
  dialogContent: {
    width: '100%',
  },
  noDataImage: {
    height: '8rem',
    width: '9rem',
    display: 'block',
    margin: '0 auto',
  },
}));

const AcademyGoConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  goStatus,
}) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyle}
      dialogContentClass={classes.dialogContent}
      content={(
        <Container className={classes.wrapper}>
          <img src={ForceEnrollLogo} alt="no-data" className={classes.noDataImage} />
          <Typography variant="h1" component="span">Academy GO Program</Typography>
          <Typography variant="subtitle2" component="span">
            {`This will mark the current Learning Plan to ${goStatus ? 'NOT' : ''} be an Academy Go Learning Plan.`}
          </Typography>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <Typography variant="subtitle2" component="span">Click on "Confirm" to continue</Typography>
        </Container>
      )}
      actions={(
        <Button
          color="primary"
          variant="contained"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      )}
    />
  );
};

AcademyGoConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  goStatus: PropTypes.bool.isRequired,
};

export default AcademyGoConfirmationModal;
