import FileDownload from 'js-file-download';

import config from '../../config';
import request from '../../helpers/apiHelper';

export const validateUploadGradesXLSXApiCall = async (
  automationType,
  programSubType,
  update,
  data,
) => {
  const url = `${config.ROUTES.VALIDATE_XLSX}/${automationType}/${programSubType}?update=${update}`;
  const options = { url, method: 'POST', data };
  try {
    const result = await request(options);
    return result.data;
  } catch (e) {
    if (e.response.status === 400) {
      return e.response.data;
    }
    return { errors: [{ message: e.toString() }] };
  }
};

export const uploadXlsx = async (programType, programSubType, programId, data, update = false) => {
  const url = `${config.ROUTES.AUTOMATION}/${programType}/${programSubType}/${programId}`;
  const options = { url, method: update ? 'PUT' : 'POST', data };
  const result = await request(options);
  return result;
};

export const downloadLog = async (transactionId, configId, programSubType, createdAt) => {
  const url = `${config.ROUTES.ASSIGNMENT_MANAGEMENT}/download_error_log_for_upload/${transactionId}`;

  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;

  const createdAtFormated = new Date(createdAt).toDateString();

  // https://stackoverflow.com/a/41940307/1217998
  FileDownload(data, `${configId}: ${programSubType} - ${createdAtFormated}.xlsx`);

  return result;
};
