import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';

import EditLinkTooltip from './EditLinkToolip';
import { DARK_GREY } from '../../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconButton: {
    background: 'transparent',
  },
  tooltipWrapper: {
    minWidth: '22rem',
  },
});

const WrappedIcon = ({
  data, icon, width, onLinkSave, editable,
}) => {
  const classes = useStyles();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const linkIconStyle = {
    border: `1px solid ${DARK_GREY}`,
    borderRadius: '100rem',
    width,
  };

  const handleOpen = (e) => {
    setTooltipOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setTooltipOpen(false);
    setAnchorEl(null);
  };

  const isDataEmpty = Object.keys(data).filter((k) => data[k].url).length;

  const innerDom = (
    <button className={classes.iconButton} onClick={handleOpen} type="button">
      <Badge color="error" overlap="circular" variant="dot" invisible={!!isDataEmpty}>
        <img style={linkIconStyle} src={icon} alt="Box" />
      </Badge>
    </button>
  );
  const onLinkSaveInner = (key, newUrl) => {
    onLinkSave({ [key]: newUrl });
  };

  const tooltipDom = (
    <div className={classes.tooltipWrapper}>
      {Object.keys(data).map((key) => (
        <EditLinkTooltip
          key={key}
          title={data[key].title}
          defaultUrl={data[key].url || ''}
          onSave={(newUrl) => onLinkSaveInner(key, newUrl)}
        />
      ))}
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {innerDom}
      <Popover
        open={tooltipOpen && editable}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {tooltipDom}
      </Popover>
    </div>
  );
};

WrappedIcon.propTypes = {
  data: PropTypes.shape({}).isRequired,
  icon: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  onLinkSave: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default WrappedIcon;
