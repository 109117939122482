import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../common/ConfirmationModal';
import CustomSelect from '../common/CustomSelect';
import AsyncSelect from './AsyncSelect';
import FormattedTypography from '../common/FormattedTypography';
import { testUsersOptions } from '../../constants';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addMargin: {
    marginTop: '2rem',
  },
  width100: {
    width: '100%',
  },
  modalHeight: {
    minHeight: '560px',
    height: 'auto',
  },
  mb: {
    marginBottom: '0.875rem',
  },
}));

const AddPrimaryExperienceModal = ({
  open,
  onClose,
  onCreate,
  experienceTypeOptions,
  programType,
  configId,
}) => {
  const [primaryExperience, setPrimaryExperience] = React.useState(null);
  const [testUserCountObj, setTestUserCountObj] = useState(testUsersOptions[1]);
  const classes = useStyles();
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const handlePickedPrograms = (items) => {
    setSelectedPrograms(_map(items, 'value'));
  };

  const testUserCount = testUserCountObj.value;

  const onCreateInner = () => {
    onCreate(primaryExperience, testUserCount, selectedPrograms);
    onClose();
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      breakpoint="sm"
      title={(
        <FormattedTypography
          variant="subtitle2"
          body="Add Primary Experience"
          dense
        />
      )}
      content={(
        <Container className={classes.wrapper}>
          <div className={classes.addMargin}>
            <CustomSelect
              labelClass={classes.mb}
              options={experienceTypeOptions}
              placeholder="Select an option"
              label="Please select primary experience"
              onChange={(e) => setPrimaryExperience(e.value)}
            />
          </div>

          <div className={classes.addMargin}>
            <CustomSelect
              labelClass={classes.mb}
              options={testUsersOptions}
              placeholder="Select an option"
              label="Count (Max: 2)"
              onChange={(e) => setTestUserCountObj(e)}
              defaultValue={testUserCountObj}
              isSearchable={false}
            />
          </div>

          <div className={classes.addMargin}>
            <AsyncSelect
              label="Select additional program IDs"
              placeholder="Select Programs"
              programType={programType}
              selectedItems={selectedPrograms}
              onChange={(value) => handlePickedPrograms(value)}
              configId={configId}
            />
          </div>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" disabled={!primaryExperience} onClick={onCreateInner}>
            Create Users
          </Button>
        </>
      )}
    />
  );
};

AddPrimaryExperienceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  experienceTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  programType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
};

export default AddPrimaryExperienceModal;
