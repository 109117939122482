import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import qs from 'query-string';
import { useHistory } from 'react-router-dom';

import { Grid } from '@mui/material';
import OverviewComponentPage from '../OverviewComponentPage';
import OverviewContentPage from '../OverviewContentPage';
import OverviewUserPage from '../OverviewUserPage';
import ForceEnrollConfirmation from '../../components/Overview/ForceEnrollConfirmation';
import config from '../../config';
import {
  OVERVIEW,
  OVERVIEW_COMPONENTS,
  OVERVIEW_CONTENT,
  OVERVIEW_USERS,
  ROUTE_REPORT,
} from '../../constants';
import { MCKINSEY_BLUE, MODERATE_LIGHT_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '2rem 3rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  downloadButton: {
    height: '3rem',
  },
  body: {
    display: 'flex',
    flex: 1,
  },
  hideEnrollBtn: {
    display: 'none',
  },
});

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: MCKINSEY_BLUE,
    height: '0.25rem',
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    textTransform: 'none',
    minWidth: '0px',
  },
  selected: {
    borderBottom: '0px',
  },
})(Tab);

const OverviewPage = (props) => {
  const {
    match, location, programMetadata, onDownload, currentSearchText, getDeltaForEnrollment,
    onForceEnroll,
  } = props;

  const programId = get(match, 'params.programId');
  const programType = get(match, 'params.programType');
  // const programSubType = get(match, 'params.programSubType');
  // const routeTransactionId = get(match, 'params.transactionId');

  const query = qs.parse(location.search);
  const selectedTab = get(query, 'tab', OVERVIEW_COMPONENTS);

  const history = useHistory();
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const [snackBarSeverity, setSnackBarSeverity] = useState('info');
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deltaCount, setDeltaCount] = useState(null);
  const [forceEnrollLoading, setForceEnrollLoading] = useState(null);
  const classes = useStyles();

  const onTabChange = (_, newTab) => history.push(`/${ROUTE_REPORT}/${programId}/${programType}/${OVERVIEW}?tab=${newTab}`, { currentSearchText });

  const hideSnackbar = useCallback(() => {
    setTimeout(() => {
      setSnackBarMessage(null);
    }, 6000);
  }, []);

  const downloadOverview = useCallback(async () => {
    setDisableDownloadBtn(true);
    setSnackBarSeverity('info');
    setSnackBarMessage('Generating the report, this may take a few seconds');

    try {
      await onDownload(`${config.ROUTES.OVERVIEW_DOWNLOAD}/${programId}/overview_download`);
      setSnackBarMessage(null);
      setDisableDownloadBtn(false);
    } catch (e) {
      setDisableDownloadBtn(false);
      setSnackBarSeverity('error');
      setSnackBarMessage('Unable to generate excel. Please try later');
      hideSnackbar();
    }
  }, [programId, onDownload, hideSnackbar]);

  const onForceEnrollClick = async () => {
    setSnackBarSeverity('info');
    setSnackBarMessage('Generating delta users, this may take a few seconds');
    try {
      const response = await getDeltaForEnrollment(programId);
      const respDeltaCount = response?.data?.data?.delta || 0;

      setModalOpen(true);
      if (respDeltaCount > 0) {
        setDeltaCount(response.data.data?.delta);
        setModalOpen(true);
        setSnackBarMessage(null);
      } else {
        setSnackBarSeverity('error');
        setSnackBarMessage('No delta found for this LP. Please try later');
        hideSnackbar();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setSnackBarSeverity('error');
      setSnackBarMessage('Unable to generate delta. Please try later');
      hideSnackbar();
    }
  };

  const onForceEnrollModalButtonClick = async () => {
    setModalOpen(false);
    setForceEnrollLoading(true);
    try {
      const response = await onForceEnroll(programId);
      if (response?.data?.success) {
        setSnackBarSeverity('info');
        setSnackBarMessage('Enrollment is initiated, this may take some time');
        setForceEnrollLoading(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e.response.status === 409) {
        setSnackBarSeverity('error');
        setSnackBarMessage('An enrollment job is already in progress. Please wait till it is finished.');
      }
    }
  };

  const ActiveTab = {
    [OVERVIEW_COMPONENTS]: OverviewComponentPage,
    [OVERVIEW_CONTENT]: OverviewContentPage,
    [OVERVIEW_USERS]: OverviewUserPage,
  }[selectedTab] || OverviewComponentPage;

  return (
    <>
      <Paper className={classes.wrapper}>

        <div className={classes.header}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={8}>
              <StyledTabs value={selectedTab} onChange={onTabChange}>
                <StyledTab value={OVERVIEW_COMPONENTS} label="Components" />
                <StyledTab value={OVERVIEW_CONTENT} label="Content" />
                <StyledTab value={OVERVIEW_USERS} label="Users" />
              </StyledTabs>
            </Grid>
            <Grid container xs={4} justifyContent="flex-end" direction="row" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                disabled={disableDownloadBtn || forceEnrollLoading}
                className={`${classes.downloadButton} ${classes.hideEnrollBtn}`}
                onClick={() => onForceEnrollClick()}
              >
                Force Enroll
              </Button>
              <Button
                style={{ marginLeft: '1rem' }}
                variant="contained"
                color="primary"
                disabled={disableDownloadBtn}
                className={classes.downloadButton}
                onClick={() => downloadOverview()}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.body}>
          <ActiveTab programMetadata={programMetadata} location={location} match={match} />
        </div>
      </Paper>
      <ForceEnrollConfirmation
        open={modalOpen}
        deltaCount={deltaCount}
        onClose={() => setModalOpen(false)}
        onForceEnroll={onForceEnrollModalButtonClick}
        forceEnrollLoading={forceEnrollLoading}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackBarMessage}
        onClose={() => setSnackBarMessage(null)}
      >
        <Alert onClose={() => setSnackBarMessage(null)} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

OverviewPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({}).isRequired,
  onDownload: PropTypes.func.isRequired,
  getDeltaForEnrollment: PropTypes.func.isRequired,
  currentSearchText: PropTypes.string,
  onForceEnroll: PropTypes.func.isRequired,
};

OverviewPage.defaultProps = {
  currentSearchText: '',
};

export default OverviewPage;
