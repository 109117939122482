import FileDownload from 'js-file-download';
import config from '../../config';
import request from '../../helpers/apiHelper';

export const getAssignmentList = (programId, update = false) => {
  const params = { update };
  const url = new URL(`${config.ROUTES.ASSIGNMENT_MANAGEMENT}/${programId}`);
  url.search = new URLSearchParams(params);
  const options = { url, method: 'GET' };
  return request(options);
};

export const downloadAssignments = (programId, query, delta) => {
  const params = { query: JSON.stringify(query), delta };
  const url = new URL(`${config.ROUTES.ASSIGNMENT_MANAGEMENT}/${programId}/download`);
  url.search = new URLSearchParams(params);
  const options = { url, method: 'GET' };
  return request(options);
};

export const downloadMetadata = async (programId, query, configId) => {
  const params = { query: JSON.stringify(query) };
  const url = new URL(`${config.ROUTES.ASSIGNMENT_MANAGEMENT}/${programId}/metadata_download`);
  url.search = new URLSearchParams(params);
  const options = { url, method: 'GET', responseType: 'blob' };
  const response = await request(options);
  const { data } = response;
  FileDownload(data, `metadata_${configId}.xlsx`);
};

export const getDownloadLink = (transactionId) => {
  const url = `${config.ROUTES.ASSIGNMENT_MANAGEMENT}/${transactionId}/s3_url`;
  return request({ url, method: 'GET' });
};

export const getAssignmentActivityLogs = (programId) => {
  const url = new URL(`${config.ROUTES.ASSIGNMENT_MANAGEMENT}/${programId}/list`);
  const options = { url, method: 'GET' };
  return request(options);
};

export const downloadActivityLogs = async (txnId, configId, programType, createdAt) => {
  const url = new URL(`${config.ROUTES.ASSIGNMENT_MANAGEMENT}/download_error_log/${txnId}`);
  const options = { url, method: 'GET', responseType: 'blob' };
  const response = await request(options);
  const { data } = response;
  const createdAtFormated = new Date(createdAt).toDateString();
  FileDownload(data, `${configId}: ${programType} - ${createdAtFormated}.xlsx`);

  return response;
};
