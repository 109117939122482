import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@material-ui/core';
import {
  BLACK, MEDIUM_GREY, STAGE_MODAL_LAUNCHED_COLOR, STAGE_MODAL_OTHER_COLOR, WHITE,
} from '../../stylesheets/colors';
import StatucConfirmPic from '../../assets/img/status-confirm.svg';
import StatusConfirm2Pic from '../../assets/img/status-confirm2.svg';
import ArrowPic from '../../assets/img/arrow2.svg';
import CompletedArrowPic from '../../assets/img/arrow.svg';
import StagesMap from '../StageBadge/constants';

const useStyles = makeStyles({
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4rem',
    '& > div': {
      marginBottom: '1rem',
    },
    '& > div.img': {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  },
  modalHeading: {
    fontSize: '1.5rem',
    color: BLACK,
    fontWeight: 500,
    marginBottom: '1rem',
  },
  modalSubHeading: {
    fontSize: '1.5rem',
    color: MEDIUM_GREY,
  },
  chip: ({ incoming }) => ({
    fontSize: '1.125rem',
    color: WHITE,
    backgroundColor: incoming ? STAGE_MODAL_LAUNCHED_COLOR : STAGE_MODAL_OTHER_COLOR,
    padding: '.5rem 1rem',
  }),
  mr2: {
    marginRight: '2rem',
  },
  textCenter: {
    textAlign: 'center',
  },
  modalCTA: {
    '& >button:nth-child(2)': {
      marginLeft: '1.25rem',
    },
  },
});

export default function LaunchedModal({
  incoming, onConfirm, onClose, selectedStatus,
}) {
  const classes = useStyles({ incoming });
  return (
    <div className={classes.modalWrapper}>
      <div>
        <span className={classes.modalHeading}>
          You are changing the status
          {' '}
          {incoming ? 'to' : 'from Launched to'}
          :
        </span>
      </div>
      <div className="img">
        <img src={incoming ? StatusConfirm2Pic : StatucConfirmPic} alt="info" />
      </div>
      <div>
        <span className={classes.chip}>
          {StagesMap[selectedStatus]}
        </span>
      </div>
      <div className={classes.textCenter}>
        <span className={classes.modalSubHeading}>
          {incoming ? 'This will push your program live. Please confirm if you want to continue.' : 'You are reverting the status from Launched for a Live program. Please confirm if you want to continue.'}
        </span>
      </div>
      <div className="img">
        <img src={incoming ? CompletedArrowPic : ArrowPic} alt="info" />
      </div>
      <div className={classes.modalCTA}>
        <Button color="primary" variant="outlined" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" type="button" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

LaunchedModal.propTypes = {
  incoming: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedStatus: PropTypes.string.isRequired,
};
