import {
  USERS_FETCH_SUCCESS, USERS_FETCH_FAILED, SET_LOADING, CLEAR_ALERTBAR,
} from '../../constants';
import { snackBarInitialState } from '../../../constants';
import { getErrorMessage } from '../../../helpers/apiHelper';

const initialState = {
  isLoading: true,
  result: { total_rows: 0, data: [], success: true },
  snackbarObj: snackBarInitialState,
};

function onFetchSuccess(state, { results }) {
  return {
    ...state,
    result: results,
    isLoading: false,
  };
}

function onFetchFailure(state, { response }) {
  const { message } = response;
  return {
    ...state,
    isLoading: false,
    snackbarObj: { open: true, severity: 'error', message: getErrorMessage(message) },
  };
}

function setLoading(state, { response }) {
  return {
    ...state, isLoading: response,
  };
}

function clearSnackbar(state) {
  return {
    ...state, snackbarObj: snackBarInitialState,
  };
}

export default function platformUsersData(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ALERTBAR:
      return clearSnackbar(state, action);
    case SET_LOADING:
      return setLoading(state, action);
    case USERS_FETCH_SUCCESS:
      return onFetchSuccess(state, action);
    case USERS_FETCH_FAILED:
      return onFetchFailure(state, action);
    default:
      return state;
  }
}
