import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import AlertBarWithAction from '../common/AlertBarWithAction';
import { REPORT } from '../../constants';
import ReorderTrainingMaterialsStatuses from './ReorderTrainingMaterialsStatuses';

const ReorderTmsStatuses = ({
  transactionId, statusVariant, showElement, setShowElement, onDownloadLog, getReport,
}) => {
  const [modalState, setModalState] = useState('');
  const [open, setOpen] = useState(false);

  const onViewStatus = (status) => {
    setModalState(status);
    setOpen(true);
  };

  const onInfoModalClose = () => {
    setOpen(false);
    setModalState('');
  };

  return (
    <>
      {showElement ? (
        <AlertBarWithAction
          variant={statusVariant.info}
          percentage={null}
          labelText={statusVariant.message}
          actionButtonText={statusVariant.info !== 'success' ? 'View Status' : ''}
          onActionClick={() => onViewStatus(REPORT)}
          actionButtonIcon={<CloseIcon onClick={() => setShowElement(false)} />}
        />
      ) : null}
      {
        transactionId && open && (
          <ReorderTrainingMaterialsStatuses
            transactionId={transactionId}
            modalState={modalState}
            open={open}
            onClose={onInfoModalClose}
            onDownloadLog={onDownloadLog}
            getReport={getReport}
            progressData={{}}
          />
        )
      }
    </>

  );
};

ReorderTmsStatuses.propTypes = {
  transactionId: PropTypes.string.isRequired,
  statusVariant: PropTypes.shape({
    info: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  showElement: PropTypes.bool.isRequired,
  setShowElement: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
};

export default ReorderTmsStatuses;
