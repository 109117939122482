import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUsers, clearAlertBar } from '../../store/actions/PlatformLevelUsers/users';
import PlatformUsers from './index';

const mapStateToProps = (state) => ({
  usersData: state.platformUsersData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getUsers,
    clearAlertBar,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PlatformUsers);
