import React, {
  useEffect, useState, memo, useCallback,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { format } from 'date-fns';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  ROUTE_REPORT, COURSE_OPS,
  WAVE_MANAGEMENT, DATEFORMAT, USER_TAB, COMPONENT_TAB,
} from '../../constants';
import FormattedTypography from '../common/FormattedTypography';
import UserListOnCohort from './UserListOnCohort';
import ComponentListOnCohort from './ComponentListOnCohort';
import BreadCrumbHOC from '../BreadCrumbHOC';
import {
  fetchWaveDetails, fetchComponentDueDateApi,
} from '../../containers/WaveManagementPage/api';
import {
  fetchContentApi,
} from '../../containers/ContentManagement/apis';

import {
  MODERATE_DARK_GREY,
  ACCORDION_GREY,
  LIGHT_MEDIUM_GREY,
  MCKINSEY_BLUE,
  SWITCH_GREY,
  MODERATE_LIGHT_GREY,
} from '../../stylesheets/colors';

import UserInterfacePin from '../../assets/icons/user-interface-pin.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    boxShadow: 'none',
  },
  breadCrumbSection: {
    display: 'flex',
    padding: '1.25rem 2rem',
  },
  headerWrapper: {
    margin: '0 2rem .5rem 2rem',
    boxShadow: '0 0 1px 0 rgba(5, 28, 44, 0.2), 0 2px 4px -1px rgba(5, 28, 44, 0.2)',
  },
  headerContent: {
    padding: '2rem',
  },
  descriptionSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    color: ACCORDION_GREY,
  },
  IDSection: {
    display: 'flex',
    color: ACCORDION_GREY,
  },
  formatedHeadeing: {
    marginRight: '2rem',
    justifyContent: 'flex-start',
    display: 'inline-flex',
    width: 'auto',
    marginLeft: '0',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    '&:nth-last-child(2)': {
      flex: '1',
      justifyContent: 'end',
      marginRight: '0',
    },
  },
  ClientIdSection: {
    justifyContent: 'start',
    marginBottom: '1rem',
  },
  mainWrapper: {
    flexGrow: 1,
  },
  middleWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    padding: '2rem',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '2rem 0 5rem 0',
  },
  boldText: {
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
  },
  clientIdKey: {
    flex: 0.06,
  },
  clientIdValue: {
    flex: 0.92,
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
  },
  formatText: {
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
    maxWidth: '180px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  actionWrapper: {
    minWidth: '18rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabs: {
    borderColor: 'divider',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.25rem',
  },
  editComponentBtn: {
    '&:disabled': {
      backgroundColor: SWITCH_GREY,
      color: ACCORDION_GREY,
    },
  },
  ctaWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultIconPrefix: {
    paddingRight: '.5rem',
  },
  defaultCohortContainer: {
    background: MODERATE_LIGHT_GREY,
    marginRight: '2rem',
    justifyContent: 'flex-start',
    display: 'inline-flex',
    width: 'auto',
    marginLeft: '0',
    whiteSpace: 'nowrap',
    padding: '0.4rem',
    alignItems: 'center',
    borderRadius: '6px',
    '&:nth-last-child(2)': {
      flex: '1',
      justifyContent: 'end',
      marginRight: '0',
    },
  },
}));

const getWaveDetails = async (
  waveId, setSelectedWave,
) => {
  try {
    const response = await fetchWaveDetails(waveId);
    if (!response.data.success) throw new Error();
    setSelectedWave(response.data.data);
  } catch (e) {
    console.error(e);
    setSelectedWave({});
  }
};

const CohortDetails = ({
  programId, waveId, componentDueDateSave, isComponentEdit, setIsComponentEdit,
  editMetadata, refreshUserList, setRefreshUserList, downloadUntaggedUsers,
}) => {
  const classes = useStyles();
  const [selectedWave, setSelectedWave] = useState({});
  const [value, setValue] = useState('Users');

  const [componentData, setComponentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getModifiedData = useCallback((data, dueDateData) => {
    let mergedData = [];
    mergedData = data.map((itm) => ({
      ...dueDateData.find((item) => (item.course_id === itm.id) && item),
      ...itm,
    }));
    const updatedData = [];
    mergedData.forEach((contentObj, index) => {
      updatedData.push({
        ...contentObj,
        order: index + 1 > 9 ? `${index + 1}` : `0${index + 1}`,
        component_title: contentObj.name,
        code: contentObj.course_code || '-',
        due_date: contentObj.due_date || null,
        comp_due_date: contentObj.due_date || null,
      });
    });
    return updatedData;
  }, []);

  const fetchContent = useCallback(async ({ isRefresh }) => {
    try {
      setIsLoading(true);
      const response = await fetchContentApi({ isRefresh, programId });
      const dueDateResponse = await fetchComponentDueDateApi(waveId);
      const dataLength = response?.data?.data?.length;
      const isDataEmpty = !dataLength;

      if (!isDataEmpty) {
        const data = get(response, 'data.data', []);
        const dueDateData = get(dueDateResponse, 'data.data');
        const modifiedData = data.length ? getModifiedData(data, dueDateData) : [];
        setComponentData(modifiedData);
      } else {
        setComponentData([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [getModifiedData, programId, waveId]);

  useEffect(() => {
    batchUpdates(() => {
      setSelectedWave({});
    });
    if (waveId) {
      getWaveDetails(waveId, setSelectedWave);
    }
    if (value === COMPONENT_TAB) {
      fetchContent({ isRefresh: false });
    }
  }, [fetchContent, value, waveId]);

  useEffect(() => {
    // update comp_due_date when save changes api success
    if (!isComponentEdit) {
      componentData.map((item) => {
        if ((item.comp_due_date == null && item.due_date !== null)
        || (typeof item.due_date !== typeof item.comp_due_date)) {
          item.comp_due_date = item.due_date;
        }
        return item;
      });
    }
  }, [componentData, isComponentEdit]);

  const onCancel = () => {
    componentData.map((item) => {
      if ((item.comp_due_date == null && item.due_date !== null)
      || (typeof item.due_date !== typeof item.comp_due_date)) {
        item.due_date = item.comp_due_date;
      }
      return item;
    });
    setIsComponentEdit(false);
  };

  const handleSubmit = () => {
    componentDueDateSave(componentData);
  };

  const onEdit = () => {
    setIsComponentEdit(true);
  };

  const breadCrumbList = [
    { label: 'All Enrollment Cohorts', redirectionUrl: `/${ROUTE_REPORT}/${programId}/${COURSE_OPS}/${WAVE_MANAGEMENT}`, isActive: false },
    { label: 'Cohort Details', redirectionUrl: '', isActive: true },
  ];

  const handleChange = async (event, newValue) => {
    setIsComponentEdit(false);
    setValue(newValue);
  };
  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      <Box className={classes.breadCrumbSection}>
        <BreadCrumbHOC
          list={breadCrumbList}
          separatorIcon={<NavigateNextIcon fontSize="small" style={{ color: LIGHT_MEDIUM_GREY }} />}
        />
      </Box>
      <Box className={classes.headerWrapper} data-testid="cohort-description">
        <Box className={classes.headerContent}>
          <Box className={classes.mainHeading}>
            <Typography
              variant="h4"
            >
              {selectedWave.wave_name}
            </Typography>
          </Box>
          <Box className={classes.descriptionSection}>
            <Typography component="p" style={{ flex: '.8' }}>
              {selectedWave.description}
            </Typography>
            <Box>
              <Typography
                component="p"
              >
                Period:
                <Typography
                  component="span"
                  className={classes.boldText}
                >
                  {` ${selectedWave.start_date ? format(new Date(selectedWave.start_date), DATEFORMAT) : ''} 
                - ${selectedWave.end_date ? format(new Date(selectedWave.end_date), DATEFORMAT) : ''}`}
                </Typography>
              </Typography>
              <Typography
                component="p"
                style={{ textAlign: 'right' }}
              >
                Expiration date:
                <Typography
                  component="span"
                  className={classes.boldText}
                >
                  {` ${selectedWave.expiration_date ? format(new Date(selectedWave.expiration_date), DATEFORMAT) : ''} `}
                </Typography>
              </Typography>
            </Box>

          </Box>
          <Box className={classes.IDSection}>
            <FormattedTypography
              containerClassName={classes.ClientIdSection}
              className={classes.clientIdValue}
              prefix="Client ID: "
              body={` ${selectedWave.client_id && selectedWave.client_id.length ? selectedWave.client_id.map((item) => item.client_id).join(', ') : ''}`}
              variant="body1"
              prefixClassName={classes.clientIdKey}
            />
          </Box>
          <Box className={classes.IDSection}>
            <FormattedTypography
              containerClassName={classes.formatedHeadeing}
              className={classes.formatText}
              prefix="Order ID "
              body={`: ${selectedWave.order_id || ''}`}
              variant="body1"
            />
            <FormattedTypography
              containerClassName={classes.formatedHeadeing}
              className={classes.formatText}
              prefix="PDT Digital Milestone ID "
              body={`: ${selectedWave.pdt_id || ''}`}
              variant="body1"
            />
            {
              selectedWave.is_default && (
              <FormattedTypography
                containerClassName={classes.defaultCohortContainer}
                className={classes.formatText}
                prefix={<img width={12} height={12} src={UserInterfacePin} alt="userInterfacePinIcon" />}
                prefixClassName={classes.defaultIconPrefix}
                body="Default Cohort"
                variant="h5"
              />
              )
            }

            <FormattedTypography
              containerClassName={classes.formatedHeadeing}
              className={classes.formatText}
              prefix="CST Member "
              body={`: ${selectedWave.cst_username || ''}`}
              variant="body1"
            />
          </Box>
        </Box>
      </Box>
      <Paper className={classes.wrapper}>
        <Container maxWidth={false} className={classes.middleWrapper}>
          <Box sx={{ width: '100%' }}>
            <Box
              className={classes.tabs}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                  flexBasis: '66%',
                  '& .MuiTab-root.Mui-selected': {
                    color: MCKINSEY_BLUE,
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: MCKINSEY_BLUE,
                  },
                }}
                textColor="primary"
                indicatorColor="primary"
                aria-label="basic tabs example"
              >
                <Tab label="Users" value={USER_TAB} id="user-tab" aria-controls="user-tabpanel" sx={{ borderBottom: '1px solid #e6e6e6' }} />
                <Tab label="Components" value={COMPONENT_TAB} id="component-tab" aria-controls="user-tabpanel" sx={{ borderBottom: '1px solid #e6e6e6' }} />
              </Tabs>
              {(value === COMPONENT_TAB && componentData.length) ? (
                <Box className={classes.ctaWrapper}>
                  {
                    isComponentEdit ? (
                      <Box className={classes.actionWrapper}>
                        <Button variant="outlined" onClick={onCancel}>
                          Cancel
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>
                          Save Changes
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        style={{ width: '9rem' }}
                        color="primary"
                        variant="contained"
                        disabled={!(selectedWave.start_date && selectedWave.end_date)}
                        onClick={onEdit}
                        className={classes.editComponentBtn}
                      >
                        Edit
                      </Button>
                    )
            }
                </Box>
              ) : null}
            </Box>
            {
              value === USER_TAB
                ? (
                  <UserListOnCohort
                    wave_name={selectedWave?.wave_name || ''}
                    programId={programId}
                    waveId={waveId}
                    editMetadata={editMetadata}
                    refreshUserList={refreshUserList}
                    setRefreshUserList={setRefreshUserList}
                    downloadUntaggedUsers={downloadUntaggedUsers}
                  />
                )
                : (
                  <ComponentListOnCohort
                    programId={programId}
                    waveId={waveId}
                    isEdit={isComponentEdit}
                    cohort={selectedWave}
                    componentData={componentData}
                    setComponentData={setComponentData}
                    isLoading={isLoading}
                  />
                )
            }

          </Box>
        </Container>

      </Paper>
    </Paper>
  );
};

CohortDetails.propTypes = {
  programId: PropTypes.number.isRequired,
  waveId: PropTypes.string.isRequired,
  componentDueDateSave: PropTypes.func.isRequired,
  isComponentEdit: PropTypes.bool.isRequired,
  setIsComponentEdit: PropTypes.func.isRequired,
  editMetadata: PropTypes.func.isRequired,
  refreshUserList: PropTypes.bool.isRequired,
  setRefreshUserList: PropTypes.func.isRequired,
  downloadUntaggedUsers: PropTypes.func.isRequired,
};
export default memo(
  CohortDetails,
  () => {},
);
