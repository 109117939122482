import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import CustomUploadModal from '../../common/CustomUploadModal';
import ForceEnrollLogo from '../../../assets/img/force-enroll-logo.png';
import { MEDIUM_GREY } from '../../../stylesheets/colors';
import Loading from '../../common/Loading';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    margin: '0 auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontSize: '2.25rem',
    fontWeight: '500',
  },
  subText: {
    fontSize: '1.5rem',
    color: MEDIUM_GREY,
  },
}));

const SyncConfirmationModal = ({
  open,
  onClose,
  isLoading,
  loadingLabelText,
  onSyncConfirmButtonClick,
}) => {
  const classes = useStyles();
  const container = isLoading ? (
    <>
      <Loading />
      <Typography variant="body1" className={classes.subText}>
        {loadingLabelText}
      </Typography>
    </>
  ) : (
    <>
      <Box className={classes.heading}>Sync users into sessions</Box>
      <Box className={classes.subText}>Performing this sync could take sometime.</Box>
      <Box className={classes.subText}>Actions might be disabled until the sync is complete.</Box>
      <Box className={classes.subText} sx={{ mt: 2 }}>
        Please confirm the sync if you would like to continue.
      </Box>
    </>
  );
  return (
    <CustomUploadModal
      open={open}
      onClose={onClose}
      contentDivider
      logo={(
        <Box className={classes.logoWrapper}>
          <img src={ForceEnrollLogo} alt="session-enrollment-sync" width={120} height={120} />
        </Box>
      )}
      primaryBtnText="Confirm Sync"
      primaryBtnProps={{ onClick: () => onSyncConfirmButtonClick(), disabled: isLoading }}
      secondaryBtnText="Close"
      secondaryBtnProps={{ onClick: () => onClose() }}
    >
      <Container className={classes.container}>{container}</Container>
    </CustomUploadModal>
  );
};

SyncConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSyncConfirmButtonClick: PropTypes.func.isRequired,
  loadingLabelText: PropTypes.func.isRequired,
};

export default memo(
  SyncConfirmationModal,
  (prevProps, nextProps) => prevProps.open === nextProps.open
  && prevProps.isLoading === nextProps.isLoading,
);
