import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// import { GlobalHeader } from '@dat-frontend/ds-react-components';
import GlobalHeader from '../ds-react-components/GlobalHeader';
import UserDropdown from './UserDropdown';

import mckAcademyLogo from '../../assets/img/mck-academy-logo.svg';
import './Header.css';
import { ROUTE_HOME } from '../../constants';
import Config from '../../config';

const useStyles = makeStyles({
  tab: {
    '&.MuiTab-root': {
      color: 'White',
      textDecoration: 'none',
      fontSize: '1.125rem',
      fontFamily: 'McKinseySans',
    },
    '&.MuiTab-root.Mui-selected': {
      color: 'White',
    },
  },
});

const Header = ({
  user, programType, onUserDropdownChange, platformType, isAdmin, isCpRole,
}) => {
  const classes = useStyles();

  const [isUserDropdownOpen, setUserDropdownState] = useState(false);
  const [value, setValue] = useState(platformType || 'program');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleUserDropdown = () => {
    setUserDropdownState((oldIsUserDropdownOpen) => !oldIsUserDropdownOpen);
  };

  return (
    <GlobalHeader>
      <GlobalHeader.Logo
        imageUrl={mckAcademyLogo}
        url={`${Config.BASE_PATH}${ROUTE_HOME}/${programType}`}
        title="mckAcademyLogo"
      />
      <GlobalHeader.Left>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="Programs"
            className={classes.tab}
            component={RouterLink}
            value="program"
            to={{ pathname: `/${ROUTE_HOME}/${programType}` }}
          />
          {isAdmin || isCpRole ? (
            <Tab
              label="Content Library"
              className={classes.tab}
              component={RouterLink}
              value="contentLibrary"
              to={{ pathname: `/${ROUTE_HOME}/${programType}/contentLibrary/lti/journals` }}
            />
          ) : null}
          {isAdmin && (
            <Tab
              label="Component Metadata"
              className={classes.tab}
              component={RouterLink}
              value="componentMetadata"
              to={{ pathname: `/${ROUTE_HOME}/${programType}/componentMetadata` }}
            />
          )}
          <Tab
            label="Master Data"
            className={classes.tab}
            component={RouterLink}
            value="master_data"
            to={{ pathname: `/${ROUTE_HOME}/${programType}/master_data/client` }}
          />
          <Tab
            label="Users"
            className={classes.tab}
            component={RouterLink}
            value="users"
            to={{ pathname: `/${ROUTE_HOME}/${programType}/users` }}
          />
        </Tabs>
      </GlobalHeader.Left>

      <GlobalHeader.Right>
        <UserDropdown
          user={user}
          onDropdownChange={onUserDropdownChange}
          toggleDropdown={toggleUserDropdown}
          open={isUserDropdownOpen}
        />
      </GlobalHeader.Right>
    </GlobalHeader>
  );
};

Header.defaultProps = {
  platformType: '',
};

Header.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  programType: PropTypes.string.isRequired,
  platformType: PropTypes.string,
  onUserDropdownChange: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCpRole: PropTypes.bool.isRequired,
};

export default Header;
