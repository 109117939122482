import { useState, useEffect } from 'react';
import useProgressResult from './useProgressResult';
import {
  objectHasData,
} from '../helpers/utils';

const usePolling = () => {
  const [transactionId, setTransactionId] = useState('');
  const [pollProgressValue, setPollProgressValue] = useState(false);

  const updatePollProgress = (val) => {
    setPollProgressValue(val);
  };

  const {
    progressResult, progressError, clearProgressResult, clearProgressError,
  } = useProgressResult(
    pollProgressValue, transactionId, updatePollProgress,
  );

  const { data: progress } = objectHasData(progressResult) ? progressResult : { data: {} };

  useEffect(() => {
    if (progressError !== null) {
      updatePollProgress(false);
      clearProgressResult();
      clearProgressError();
    }
    if (progress?.done) {
      updatePollProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, progressError]);

  useEffect(() => {
    if (transactionId) {
      clearProgressResult();
      updatePollProgress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const saveTransactionId = (tId) => {
    setTransactionId(tId);
  };

  return {
    transactionId, saveTransactionId, progress, progressError,
  };
};

export default usePolling;
