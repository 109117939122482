import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import PlatformUsers from './redux';
import UserDetail from './userDetailsredux';

const PlatformUsersLandingPage = (props) => {
  const { match } = props;
  const programSubType = get(match, 'params.programSubType', null);

  return (
    programSubType ? <UserDetail match={match} /> : <PlatformUsers match={match} />
  );
};
PlatformUsersLandingPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programSubType: PropTypes.string,
    }),
  }).isRequired,
};

export default PlatformUsersLandingPage;
