import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { utils } from 'xlsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { writeWS } from '../../helpers/xlsxHelper';

import { MODIFY_COURSES, PROGRAM_CREATION, CREATE_LP_SHEET1 } from '../../constants';
import CustomBreadCrumb from './CustomBreadCrumb';
import FileInfoBanner from './FileInfoBanner';
import Titlebar from './Titlebar';
import Sidebar from '../common/Sidebar';
import CustomPrompt from '../common/CustomPrompt';
import DraggableContainer from './DraggableContainer';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '3rem',
  },
  sidebarWrapper: {
    display: 'flex',
    flex: 0.28,
  },
  bodyWrapper: {
    display: 'flex',
  },
  rightContainer: {
    flex: 0.72,
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem',
  },
  dragndrop: {},
});

const cleanSheetField = (name) => name.trim().toLowerCase();

const findSheetName = (sheetNames, sheetName) => {
  const sheets = sheetNames.filter((key) => cleanSheetField(key) === sheetName);
  return sheets[0] || '';
};

const ModifyCourse = ({
  fileName, data, steps, onBuildProgram,
}) => {
  const classes = useStyles();
  const [currentData, setCurrentData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [enableBuild, setBuild] = useState(false);
  const [shouldProceed, setShouldProceed] = useState(false);

  const history = useHistory();

  const onReset = useCallback(() => {
    const correctSheetName = findSheetName(data.SheetNames, CREATE_LP_SHEET1);
    const sheetData = utils.sheet_to_json(data.Sheets[correctSheetName], {
      blankrows: false,
      defval: '',
    });

    setCurrentData(
      sheetData.map((row) => Object.entries(row).reduce((ini, [key, value]) => {
        ini[cleanSheetField(key)] = value; // eslint-disable-line no-param-reassign
        return ini;
      }, {})),
    );
  }, [data]);

  useEffect(() => {
    const unload = (e) => {
      (e || window.event).returnValue = true;
      return true;
    };
    window.addEventListener('beforeunload', unload);
    return () => {
      window.removeEventListener('beforeunload', unload);
    };
  }, []);

  useEffect(onReset, [data]);

  useEffect(() => {
    setBuild(!!updatedData.length);
  }, [updatedData]);

  const onProceed = useCallback(() => {
    setShouldProceed(true);
    const correctSheetName = findSheetName(data.SheetNames, CREATE_LP_SHEET1);
    const wbout = writeWS(data, {
      [correctSheetName]: utils.json_to_sheet(updatedData),
    });

    setBuild(false);
    onBuildProgram(new Blob([wbout], { type: 'application/octet-stream' }));
  }, [setShouldProceed, updatedData, data, onBuildProgram]);

  return (
    <>
      <div className={classes.wrapper}>
        <CustomBreadCrumb programType={PROGRAM_CREATION} programSubType={MODIFY_COURSES} />
        <FileInfoBanner name={fileName} />
        <div className={classes.bodyWrapper}>
          <div className={classes.sidebarWrapper}>
            <Sidebar heading="Automation Activities" steps={steps} />
          </div>
          <div className={classes.rightContainer}>
            {currentData[0] && (
              <Titlebar
                title={currentData[0].lp_name}
                enableBuild={enableBuild}
                onReset={onReset}
                onProceed={onProceed}
              />
            )}
            <DraggableContainer data={currentData} setUpdatedData={setUpdatedData} />
          </div>
        </div>
      </div>
      <CustomPrompt
        message="All your changes until now will be lost. Are you sure to leave this page?"
        navigate={(path) => history.replace(path)}
        shouldBlockNavigation={() => !shouldProceed}
      />
    </>
  );
};

ModifyCourse.propTypes = {
  fileName: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  onBuildProgram: PropTypes.func.isRequired,
  data: PropTypes.shape({
    SheetNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    Sheets: PropTypes.object.isRequired,
  }).isRequired,
};

export default ModifyCourse;
