import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _xorWith from 'lodash/xorWith';

export const transactionNameFormatter = (transactionName, maxLength, headLength = 3) => {
  if (transactionName.length <= maxLength) {
    return transactionName;
  }
  const tailLength = maxLength - headLength - 1;
  const head = transactionName.slice(0, headLength);
  const tail = transactionName.slice(-tailLength);
  return `${head}...${tail}`;
};

export const convertInDigits = (num, digit = 2) => {
  const numOper = { negative: num < 0, value: Math.abs(num) };
  return (numOper.negative ? '-' : '') + ((String(numOper.value).length >= digit ? String(numOper.value) : [...Array(digit - String(numOper.value).length)].reduce((ini) => `0${ini}`, numOper.value)));
};

export const zeroAppender = (appendee) => convertInDigits(appendee, 2);

export const reportPageDateTimeFormatter = (dateTime) => {
  const clientTzOffsetMinutes = new Date().getTimezoneOffset();
  const clientTime = dateTime.getTime() - clientTzOffsetMinutes * 60000;
  const clientDateTime = new Date(clientTime);
  const hours24H = clientDateTime.getHours();
  const hours12H = hours24H % 12 === 0 ? 12 : hours24H % 12;
  const minutes = clientDateTime.getMinutes();
  const date = clientDateTime.getDate();
  const month = clientDateTime.toLocaleString('default', { month: 'long' });
  const year = clientDateTime.getFullYear();
  const tzOffset = clientDateTime.getTimezoneOffset();
  const tzHour = zeroAppender(Math.floor(Math.abs(tzOffset) / 60));
  const tzMinute = zeroAppender(Math.abs(tzOffset) % 60);
  const formattedTZOffset = `${tzHour}:${tzMinute}`;
  const tzSign = tzOffset < 0 ? '+' : '-';
  const timeString = `${zeroAppender(hours12H)}:${zeroAppender(minutes)} ${hours24H < 12 ? 'AM' : 'PM'}`;
  const dateString = `${month} ${date}, ${year}`;
  const tzString = `GMT ${tzSign}${formattedTZOffset}`;
  return `${timeString}; ${dateString}; ${tzString}`;
};

export const dateFormatter = (dateTime) => {
  const clientTzOffsetMinutes = new Date().getTimezoneOffset();
  const clientTime = dateTime.getTime() - clientTzOffsetMinutes * 60000;
  const clientDateTime = new Date(clientTime);
  const date = String(clientDateTime.getDate()).padStart(2, '0');
  const month = clientDateTime.toLocaleString('default', { month: 'long' });
  const year = clientDateTime.getFullYear();
  const dateString = `${month} ${date}, ${year}`;
  return `${dateString}`;
};

export const getOrdinalNumForDate = (n) => n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const stringTrim = (str, limit, trailing = '...') => {
  let newStr = str.substr(0, limit);
  if (str.length > limit) newStr += trailing;
  return newStr;
};

export const isArrayEqual = (x, y) => _isEmpty(_xorWith(x, y, _isEqual));

export const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);

export const ordinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export const convertMinutesToHourMinutes = (mins) => {
  const hours = (mins / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  return `${convertInDigits(rhours)}:${convertInDigits(minutes)}`;
};

export const formatHoursToMinute = (n) => {
  const [hours, minutes] = n.split(':');
  return (+hours * 60) + (+minutes);
};

export const stripHtmlTags = (str) => {
  if ((str === null) || (str === '') || (str === undefined)) return false;
  str = str.toString();
  return str.replace(/<[^>]*>/g, '');
};

export const getUTCDate = ((strDate) => {
  const date = new Date(strDate);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
});

export const capitalizeFirstLetter = ((strData) => strData.charAt(0).toUpperCase()
  + strData.slice(1));
