import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import RightArrowIcon from '../../assets/icons/right-arrow-blue.svg';

const useStyles = makeStyles({
  link: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  leftMargin: {
    marginLeft: '1rem',
  },
});

const RedirectArrowLink = ({ pathname, icon }) => {
  const classes = useStyles();

  return (
    <Link
      className={classes.link}
      component={RouterLink}
      to={{ pathname }}
    >
      <img src={icon} alt="right-icon" height={10} width={10} className={classes.leftMargin} />
    </Link>
  );
};

RedirectArrowLink.defaultProps = {
  icon: RightArrowIcon,
};

RedirectArrowLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default RedirectArrowLink;
