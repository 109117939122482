import React from 'react';
import PropTypes from 'prop-types';

import Error from './Error';

const ErrorText = ({ touchedField, errorField }) => (
  <>
    {touchedField && !!errorField
      && <Error errorText={touchedField && errorField} />}
  </>
);
ErrorText.propTypes = {
  touchedField: PropTypes.string.isRequired,
  errorField: PropTypes.string.isRequired,
};

export default ErrorText;
