import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  FieldArray,
  FormikProvider,
  useField,
} from 'formik';
import _get from 'lodash/get';
import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@mui/material/Chip';
import _filter from 'lodash/filter';
import ElucidatProjectInput from '../../Replication/ElucidatProjectInput';
import { MODERATE_LIGHT_GREY, BLACK } from '../../../stylesheets/colors';
import { getMaterialType } from '../../../helpers/utils';
import WarningInfoAlert from '../../common/WarningInfoAlert';
import LtiTmWarning from './LtiTmWarning';

const useStyles = makeStyles({
  loSubtitle: {
    padding: '1.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    '& .MuiTableCell-body': {
      fontSize: '1.25rem',
      height: '1.25rem',
    },
  },
  warningBox: {
    padding: '0.5rem 0',
  },
});

const LoRow = ({
  index,
  lo,
  checkElucidatValidity,
  formik,
  updateErrorStatus,
  targetLpCode,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedField, selectedMeta, selectedHelpers] = useField({
    name: `lo[${index}.selected]`,
    type: 'checkbox',
  });

  const { label, icon } = getMaterialType(lo);
  const classes = useStyles();

  return (
    <TableRow key={lo.name}>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          id={selectedField.name}
          name={selectedField.name}
          checked={selectedField.checked}
          onClick={(event) => {
            selectedHelpers.setValue(event.target.checked);
          }}
        />
      </TableCell>
      <TableCell>
        {lo.name}
        {lo?.type?.includes('scorm') && lo?.elucidatSelect === 'duplicate' && (
          <WarningInfoAlert severity="warning" customStyles={classes.warningBox}>
            Duplicated projects are added to the elucidat folder of the parent project
            and to any additional folders that were selected
          </WarningInfoAlert>
        )}
        {
          ['groupwork', 'journal'].includes(lo?.sub_type)
            ? (
              <LtiTmWarning
                key={lo.id}
                scormInputPath={`lo[${index}].${lo.sub_type}Error`}
                lo={lo}
                formik={formik}
                updateCourseErrorStatus={updateErrorStatus}
                selected={selectedField.checked}
                targetLpCode={targetLpCode}
              />
            ) : null
        }
      </TableCell>
      <TableCell>
        <Chip
          style={{
            backgroundColor: MODERATE_LIGHT_GREY,
            color: BLACK,
            fontSize: '1rem',
            padding: '0 0.5rem',
          }}
          icon={<img src={icon} alt="lo-type-icon" />}
          label={label}
          size="small"
        />
      </TableCell>
      <TableCell>
        {
          lo?.type?.includes('scorm')
          && (
            <ElucidatProjectInput
              key={lo.id}
              scormInputPath={`lo[${index}]`}
              lo={{ ...lo, lo_id: lo?.id?.split(':')[0] }}
              formik={formik}
              updateCourseErrorStatus={updateErrorStatus}
              checkElucidatValidity={checkElucidatValidity}
              selected={selectedField.checked}
              disable={false}
              destinationConfigId={targetLpCode}
              feature="Import"
            />
          )
        }
      </TableCell>
    </TableRow>
  );
};

const TableView = ({ formik, checkElucidatValidity, targetLpCode }) => {
  // eslint-disable-next-line no-unused-vars
  const [isAllTmsValidated, isAllTmsValidatedMeta, isAllTmsValidatedHelpers] = useField({
    name: 'isAllTmsValidated',
    type: 'input',
  });

  const { errors } = formik;

  const updateErrorStatus = useCallback((err, isValidating) => {
    const loList = _get(err, 'lo', []);
    if (loList.length > 0) {
      const isContainValues = _filter(_flatten(loList), (obj) => !_isEmpty(obj));
      isAllTmsValidatedHelpers.setValue(!((isValidating || isContainValues.length > 0)));
    } else if (isValidating) {
      isAllTmsValidatedHelpers.setValue(false);
    } else {
      isAllTmsValidatedHelpers.setValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateErrorStatus(errors, false);
  }, [errors, updateErrorStatus]);

  return (
    <TableBody>
      {formik.values.lo.map((row, idx) => {
        const labelId = `enhanced-table-checkbox-${idx}`;
        return (
          <LoRow
            index={idx}
            formik={formik}
            key={row.course_id}
            lo={row}
            labelId={labelId}
            checkElucidatValidity={checkElucidatValidity}
            updateErrorStatus={updateErrorStatus}
            targetLpCode={targetLpCode}
          />
        );
      })}
    </TableBody>
  );
};

const ConfigureComponent = ({
  formik, checkElucidatValidity, targetLpCode,
}) => {
  const classes = useStyles();

  const { lo } = formik?.values;

  return (
    <Box sx={{ width: '90%', margin: 'auto' }}>
      <Typography variant="subtitle1">
        Configure the Component
      </Typography>
      <Box className={classes.loSubtitle}>
        <Typography variant="body1">
          Training Materials Included
        </Typography>
        <Typography variant="body1">
          {`${_filter(lo, { selected: true }).length} / ${lo?.length}`}
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" padding="normal" className={classes.table}>
          <FormikProvider value={formik}>
            <FieldArray name="trainingMaterial">
              {() => (
                <TableView
                  checkElucidatValidity={checkElucidatValidity}
                  formik={formik}
                  targetLpCode={targetLpCode}
                />
              )}
            </FieldArray>
          </FormikProvider>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ConfigureComponent;

ConfigureComponent.propTypes = {
  checkElucidatValidity: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      lo: PropTypes.array.isRequired,
    }).isRequired,
    validateForm: PropTypes.func.isRequired,
  }).isRequired,
  targetLpCode: PropTypes.string.isRequired,
};

TableView.propTypes = {
  checkElucidatValidity: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      lo: PropTypes.array.isRequired,
    }).isRequired,
    errors: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
  }).isRequired,
  targetLpCode: PropTypes.string.isRequired,
};

LoRow.propTypes = {
  lo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    elucidatSelect: PropTypes.string.isRequired,
    original_project_id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]),
    ]),
    name: PropTypes.string.isRequired,
    sub_type: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    project_id_status: PropTypes.string.isRequired,
    project_name: PropTypes.string.isRequired,
  }).isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      lo: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  index: PropTypes.string.isRequired,
  updateErrorStatus: PropTypes.func.isRequired,
  targetLpCode: PropTypes.string.isRequired,
};
