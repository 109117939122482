import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _find from 'lodash/find';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@mui/material/List';

import Box from '@material-ui/core/Box';
import ConfirmationModal from '../common/ConfirmationModal';
import ReplicationCourseReview from './ReplicationCourseReview';

import FormattedTypography from '../common/FormattedTypography';
import {
  ACCORDION_GREY, BLACK,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addMargin: {
    marginTop: '2rem',
  },
  width100: {
    width: '100%',
  },
  subHeading: {
    fontWeight: 800,
  },
  contentHeadingHelper: {
    padding: '1.5rem 0',
    borderBottom: `1px solid ${ACCORDION_GREY}`,
  },
  contentWrapper: {
    padding: '1.5rem 0',
  },
  contentListWrapper: {
    width: '100%',
    padding: '1.5rem 0',
  },
  contentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  infoContentText: {
    color: BLACK,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
}));

const ReplicationConfirmModal = ({
  open,
  onClose,
  onConfirm,
  report,
  destinationConfigId,
  channelConfig,
}) => {
  const classes = useStyles();
  const [componentData, setComponentData] = useState([]);
  const [channelMessage, setChannelMessage] = useState('');
  const [newChannelName, setNewChannelName] = useState('');

  useEffect(() => {
    setNewChannelName(channelConfig?.new_channel_name || channelConfig?.source_channel_name);
    // todo: make this common function and make the text consistent whereever it is used
    let channelVisibilityText = '';
    if (Object.keys(channelConfig).length > 0) {
      if (channelConfig?.link_assets !== null) {
        if (channelConfig?.add_visibility_only) {
          channelVisibilityText = '(Existing Channel with same assets)';
        } else if (channelConfig?.link_assets) {
          channelVisibilityText = '(Duplicated channel with duplicated assets)';
        } else {
          channelVisibilityText = '(New Channel With No Assets)';
        }
      }
    } else {
      channelVisibilityText = '(No Channel)';
    }
    setChannelMessage(channelVisibilityText);
  }, [channelConfig]);

  useEffect(() => {
    const data = _map(report.courses, (component) => (
      (component.selected === false
        || _find(component.lo, (tm) => {
          if (tm?.elucidatSelect) {
            return true;
          }
          if (tm?.selected === false) {
            return true;
          }
          return false;
        }))
        ? {
          ...component,
          lo: component.lo.filter(
            (loObj) => (loObj.selected === false || loObj?.elucidatSelect?.length > 0),
          ),
        } : false
    ));
    setComponentData(data);
  }, [report]);

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      breakpoint="md"
      title={(
        <FormattedTypography
          variant="subtitle1"
          body="Review and Proceed"
          subHeading="Please review all the changes you have made for this replication."
          dense
        />
      )}
      content={(
        <Container className={classes.wrapper}>
          <Box>
            <Typography variant="subtitle1" className={classes.subHeading}>Config ID & Channel for replicated program</Typography>
            <Box className={classes.contentHeadingHelper}>
              <Box>
                <Typography variant="subtitle1" className={classes.infoHelperText}>
                  Config ID:
                  <Typography variant="subtitle1" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>{destinationConfigId}</Typography>
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" className={classes.infoHelperText}>
                  Channel Config:
                  <Typography variant="subtitle1" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                    {newChannelName}
                    {' '}
                    {channelMessage}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography variant="subtitle1" style={{ paddingTop: '2rem' }} className={classes.subHeading}> Components Modifications</Typography>
          {componentData.every((v) => v === false) ? 'No component modified' : (
            <List sx={{ width: '100%', pt: 0 }}>
              {componentData.map((course) => (course
                && <ReplicationCourseReview course={course} key={course} />))}
            </List>
          )}
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Continue Editing
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            Confirm & Proceed
          </Button>
        </>
      )}
    />
  );
};

ReplicationConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
  channelConfig: PropTypes.array.isRequired,
};

export default ReplicationConfirmModal;
