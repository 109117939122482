import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import FormattedTypography from '../../common/FormattedTypography';

import {
  MODERATE_LIGHT_GREY, DARK_MEDIUM_GREY, ERROR_RED,
} from '../../../stylesheets/colors';

const useStyles = makeStyles({
  listWrapper: {
    background: MODERATE_LIGHT_GREY,
  },
  iconWrapper: {
    minWidth: '3rem',
  },
  errorIcon: {
    color: ERROR_RED,
  },
  errorListIcon: {
    height: '56px',
  },
  errorListWrapper: {
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
  bold: {
    fontWeight: 900,
  },
  footerText: {
    color: DARK_MEDIUM_GREY,
    marginTop: '2rem',
    padding: 0,
  },
});

const CheckboxRow = ({ value }) => (
  <FormControlLabel
    value={value}
    control={<Checkbox color="primary" />}
    label="Ignore errors and proceed with upload"
  />
);

CheckboxRow.propTypes = {
  value: PropTypes.string.isRequired,
};

const EnrollmentProgress = ({ totalUsers }) => {
  const classes = useStyles();

  return (
    <>
      <List className={classes.listWrapper} component="nav" aria-label="main" dense>
        <ListItem>
          <ListItemIcon className={classes.iconWrapper}>
            <ArrowForwardIcon />
          </ListItemIcon>
          <ListItemText disableTypography primary={<FormattedTypography prefix="Total users found in XLS file -&nbsp;" body={totalUsers} dense variant="subtitle2" justifyContent="left" />} />
        </ListItem>
      </List>
      <Container className={classes.footerText}>
        <Typography variant="body1">**You can close this popup and navigate away from this screen if you wish.</Typography>
        <Typography variant="body1">The process will continue in the background and we will inform you when its completed</Typography>
      </Container>
    </>
  );
};

EnrollmentProgress.propTypes = {
  totalUsers: PropTypes.number.isRequired,
};

export default memo(EnrollmentProgress, (() => true));
