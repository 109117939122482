import FileDownload from 'js-file-download';

import config from '../../config';
import request from '../../helpers/apiHelper';
import { DOCEBO_METADATA_LOOKUP } from '../../constants';

export const fetchMetadataByDoceboId = async (doceboLpId,
  includeComponents, fromDocebo = false) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/lp/${doceboLpId}?update=${fromDocebo}&include_components=${includeComponents}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const updateMetadata = async (doceboLpId, language, programId) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/lp/update`;
  const data = {
    metadata: [{
      slug: 'language',
      value: language.label,
      key: DOCEBO_METADATA_LOOKUP.language,
    },
    {
      slug: 'language-code',
      value: language.value,
      key: DOCEBO_METADATA_LOOKUP['language-code'],
    },
    ],
    program_id: programId,
    entity_ids: [
      doceboLpId,
    ],
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const updateMetadataCourses = async (metadata, entityIds, programId) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/component/update`;
  const data = {
    metadata,
    program_id: programId,
    entity_ids: entityIds,
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const getOptionsAsync = async (doceboLpId, text) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/enrollment/${doceboLpId}?username=${text}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};

export const updateEnrollmentInfo = async (doceboLpId, userSlug, role, programId) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/enrollment/update`;
  const data = {
    metadata: [{
      slug: userSlug,
      value: role,
      key: userSlug, // for enrollment, both key & slug are same
    }],
    program_id: programId,
    entity_ids: [
      doceboLpId,
    ],
  };
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result;
};

export const getDoceboId = async (programId) => {
  const url = `${config.ROUTES.OVERVIEW_COMPONENTS}/${programId}?update=false`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result;

  return data;
};

export const getActivityLogs = async (programId) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/${programId}/activity`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result;
  return data;
};

export const downloadEnrolledUsers = async (programId) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/user/${programId}/download`;

  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;

  const createdAtFormated = new Date().toDateString();
  FileDownload(data, `user_metadata_${createdAtFormated}.xlsx`);

  return result;
};

export const getMetadata = async (programId) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/user/${programId}/trigger_sync`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result?.data;
};

export const uploadXlsx = async (data) => {
  const url = `${config.ROUTES.METADATA_MANAGEMENT}/user/trigger_bulk_edit`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};
