import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import get from 'lodash/get';

import UsersScreen from '../../components/Overview/UsersScreen';
import RefreshSection from '../../components/Overview/RefreshSection';

import { getUsersActive, getUsersCohort, getUsersEnrolled } from './apis';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: '2rem',
  },
});

const fetchData = async (
  programId,
  update,
  setTotalUsers,
  setActiveUsers,
  setInactiveUsers,
  setUsers,
  setUsersWithCohortSet,
  setLoading,
  setError,
  setLastRefreshed,
) => {
  setLoading(true);
  setError(false);
  setLastRefreshed(null);

  try {
    const cohortPromise = getUsersCohort(programId, update);
    const activePromise = getUsersActive(programId, update);
    const enrolledPromise = getUsersEnrolled(programId, update);
    const cohortUsers = await cohortPromise;
    const {
      total, active, inactive, lastRefreshed,
    } = await activePromise;
    const { enrolled_users, participant_users } = await enrolledPromise;

    setTotalUsers(total);
    setActiveUsers(active);
    setInactiveUsers(inactive);
    setUsers({ enrolledUsers: enrolled_users, participantUsers: participant_users });
    setUsersWithCohortSet(cohortUsers);
    setLastRefreshed(lastRefreshed);
  } catch (e) {
    console.error(e);

    setError(true);
  }

  setLoading(false);
};

const OverviewUserPage = (props) => {
  const { match } = props;

  const classes = useStyles();
  const programId = get(match, 'params.programId');

  const [totalUsers, setTotalUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);

  const [users, setUsers] = useState(
    { enrolledUsers: 0, participantUsers: 0 },
  );
  const [usersWithCohortSet, setUsersWithCohortSet] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [lastRefreshed, setLastRefreshed] = useState(null);

  useEffect(() => {
    fetchData(
      programId,
      false,
      setTotalUsers,
      setActiveUsers,
      setInactiveUsers,
      setUsers,
      setUsersWithCohortSet,
      setLoading,
      setError,
      setLastRefreshed,
    );
  }, [programId]);

  const onRefresh = async () => {
    await fetchData(
      programId,
      true,
      setTotalUsers,
      setActiveUsers,
      setInactiveUsers,
      setUsers,
      setUsersWithCohortSet,
      setLoading,
      setError,
      setLastRefreshed,
    );
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.refreshWrapper}>
        <RefreshSection onRefresh={onRefresh} lastRefreshed={lastRefreshed} />
      </div>
      <UsersScreen
        totalUsers={totalUsers}
        activeUsers={activeUsers}
        inactiveUsers={inactiveUsers}
        totalEnrolledUsers={users?.enrolledUsers}
        participantUsers={users?.participantUsers}
        usersWithCohortSet={usersWithCohortSet}
        loading={loading}
        error={error}
      />
    </div>
  );
};

OverviewUserPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({}).isRequired,
};

export default OverviewUserPage;
