import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MEDIUM_GREY } from '../../../../../stylesheets/colors';
import ConfirmationModal from '../../../../common/ConfirmationModal';
import ConfirmModalImage from '../../../../../assets/img/force-enroll-logo.png';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      height: '9rem',
      width: 'rem',
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:nth-child(3)': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
}));

const UnlockJournalModal = ({ onClose, onConfirm, selectedJournal }) => {
  const classes = useStyles();
  const { id, journal_id } = selectedJournal;

  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img src={ConfirmModalImage} alt="delete-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>
            Unlock Journal -
            {' '}
            {journal_id}
          </Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            Unlocking this journal will allow any CP/admin user to edit it.
            No existing replications of this journal will be affected by any
            changes made. Are you sure you want to proceed?
          </Typography>
        </Container>
            )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="delete_journal_action" color="primary" variant="contained" onClick={() => { onConfirm({ journalId: id }); }}>
            Confirm
          </Button>
        </>
      )}
    />
  );
};

UnlockJournalModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedJournal: PropTypes.object.isRequired,
};

export default UnlockJournalModal;
