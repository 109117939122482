import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { CHANNELS, COURSES } from '../../../constants';
import {
  MODERATE_DARK_GREY,
  MODERATE_LIGHT_GREY,
  WHITE,
  COURSE_MATERIAL_TYPE_COLOR_LOOKUP,
  DARK_GREY,
} from '../../../stylesheets/colors';
import { getMaterialType } from '../../../helpers/utils';

const COMMON_MARGIN = '0.6875rem 0px';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontFamily: 'McKinseySans',
    fontSize: '1.125rem',
  },
  tableRoot: {
    flex: 1,
    borderCollapse: 'collapse',
    color: MODERATE_DARK_GREY,
  },
  headerTr: {
    backgroundColor: MODERATE_LIGHT_GREY,
    fontWeight: 'bold',
  },
  headerTd: {
    padding: '0.875rem 1rem',
  },
  tr: {
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  td: {
    padding: '0.875rem 1rem',
  },
  materialTypeName: {
    margin: COMMON_MARGIN,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  materialTypeBadge: {
    color: WHITE,
    margin: COMMON_MARGIN,
    width: '100%',
    height: '1.8rem',
    lineHeight: '1.8rem',
    textAlign: 'center',
    borderRadius: '3px',
  },
});

const ContentScreenTableHeaderRow = ({ type }) => {
  const classes = useStyles();
  const headerLookup = {
    [COURSES]: ['Course name', '# of users enrolled', 'Training Materials', 'Material Type'],
    [CHANNELS]: ['Channel name', 'Training Materials', 'Material Type'],
  };

  const headerDom = headerLookup[type]
    .map((s) => (<td key={s} className={classes.headerTd}>{s}</td>));

  return (
    <tr className={classes.headerTr}>
      {headerDom}
    </tr>
  );
};

ContentScreenTableHeaderRow.propTypes = {
  type: PropTypes.string.isRequired,
};

const ContentScreenTableRow = ({ type, row }) => {
  const classes = useStyles();

  const formatTmNameKey = (tm) => `${row.name}_${tm.name}`;
  const formatTmNameValue = (tm, index) => {
    const paddedIndex = `${index + 1}`.padStart(2, '0');
    return `${paddedIndex}: ${tm.name}`;
  };

  const trainingMaterialNameDom = (
    <div>
      {row.training_materials.filter((obj) => obj.type).map((tm, index) => (
        <div key={formatTmNameKey(tm)} className={classes.materialTypeName}>
          {formatTmNameValue(tm, index)}
        </div>
      ))}
    </div>
  );
  const trainingMaterialTypeDom = (
    <div>
      {row.training_materials.filter((obj) => obj.type).map((tm) => {
        const { label } = getMaterialType(tm);

        return (
          <div
            key={`${formatTmNameKey(tm)}_type`}
            className={classes.materialTypeBadge}
            style={{ backgroundColor: COURSE_MATERIAL_TYPE_COLOR_LOOKUP[tm.type] || DARK_GREY }}
          >
            {label}
          </div>
        );
      })}
    </div>
  );

  return (
    <tr className={classes.tr}>
      <td className={classes.td}>{row.name}</td>
      {type === COURSES && <td className={classes.td}>{row.enrollment_count}</td>}
      <td className={classes.td}>{trainingMaterialNameDom}</td>
      <td className={classes.td}>{trainingMaterialTypeDom}</td>
    </tr>
  );
};

ContentScreenTableRow.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    enrollment_count: PropTypes.number,
    training_materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

const ContentScreenTable = ({
  type, data,
}) => {
  const classes = useStyles();

  const rowsDom = data.map((row) => <ContentScreenTableRow type={type} row={row} key={row.name} />);

  return (
    <div className={classes.wrapper}>
      <table className={classes.tableRoot}>
        <thead>
          <ContentScreenTableHeaderRow type={type} />
        </thead>
        <tbody>{rowsDom}</tbody>
      </table>
    </div>
  );
};

ContentScreenTable.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    enrollment_count: PropTypes.number,
    training_materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};

export default ContentScreenTable;
