import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Select from '../common/SelectMui';
import LabledTextField from '../common/LabledTextField';
import LabeledTextArea from '../common/LabeledTextArea';
import InfoTooltip from '../common/InfoTooltip';
import {
  ACCORDION_GREY,
  ERROR_RED, MCKINSEY_BLUE,
  MODERATE_DARK_GREY,
} from '../../stylesheets/colors';
import MCKINSEY_SANS from '../../stylesheets/fonts';

const Error = ({ errorText }) => (
  <span className="error">{errorText || 'This field is required'}</span>
);
Error.propTypes = {
  errorText: PropTypes.string.isRequired,
};

const NA = '-';

const useStyles = makeStyles(() => ({
  formFields: {
    '& .form-cell': {
      flex: 1,
    },
    '& .form-field': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    ' & .fld-name': {
      display: 'flex',
      alignItems: 'center',
      fontSize: ({ inBox }) => (inBox ? '0.875rem' : '1rem'),
      color: ({ inBox }) => (inBox ? ACCORDION_GREY : MODERATE_DARK_GREY),
      fontFamily: MCKINSEY_SANS,
    },
    '& .fld-id': {
      fontFamily: MCKINSEY_SANS,
      fontSize: '0.75rem',
      fontWeight: 600,
      padding: '0.125rem 0.5rem',
      border: `1px solid ${MCKINSEY_BLUE}`,
      borderRadius: '10px',
      color: MCKINSEY_BLUE,
      maxWidth: '95%',
      width: 'fit-content',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: 1,
      boxOrient: 'vertical',
      marginTop: '2px',
    },
    ' & .fld-value': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: 1,
      boxOrient: 'vertical',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: ({ inBox }) => (inBox ? '0.875rem' : '1rem'),
      color: MODERATE_DARK_GREY,
      marginTop: '2px',
      paddingRight: '1.25rem',
      wordBreak: 'break-word',
      fontWeight: 'normal',
      '& p': {
        margin: 0,
      },
      '& img': {
        maxWidth: '100%',
      },
    },
    '& .fld-input': {
      fontSize: '1rem',
    },
  },
  requiredMark: {
    color: ERROR_RED,
  },
  charLimit: {
    color: MODERATE_DARK_GREY,
  },
  fieldMetaData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const LtiModalComponents = ({
  name,
  label,
  value,
  children,
  tooltipMessage,
  isEdit,
  fieldType,
  hasError,
  errorText,
  handleChange,
  options,
  disabled,
  isMulti,
  inBox,
  isRequired,
  placeHolder,
  inputFieldIcon,
  isId,
  charLimit,
}) => {
  const classes = useStyles({ inBox });

  const fieldMetaDataJsx = (
    <Box className={classes.fieldMetaData}>
      {hasError && <Error errorText={errorText} />}
      {charLimit && (
        <Typography variant="h2" component="span" className={classes.charLimit}>
          {value.length}
          /
          {charLimit}
        </Typography>
      )}
    </Box>
  );

  return (
    <div className={classes.formFields}>
      <div className="form-cell">
        <div className="form-field">
          <span className="fld-name">
            <span>{label}</span>
            {isRequired && <span className={classes.requiredMark}>*</span>}
            {tooltipMessage && <InfoTooltip title={tooltipMessage} />}
          </span>

          {!isEdit && !fieldType && (
            <div className={isId ? 'fld-id' : 'fld-value'}>
              <span>{value || NA}</span>
            </div>
          )}

          {isEdit && (
            <>

              {fieldType === 'text' && (
              <div className="fld-input">
                <LabledTextField
                  name={name}
                  value={value}
                  onChange={handleChange}
                  disabled={disabled}
                  placeholder={placeHolder}
                  inputProps={{
                    name,
                    required: isRequired,
                    InputProps: {
                      endAdornment: inputFieldIcon,
                    },
                    inputProps: {
                      name,
                      maxLength: charLimit,
                    },
                  }}
                />
                {fieldMetaDataJsx}
              </div>
              )}

              {fieldType === 'textarea' && (
              <div className="fld-input">
                <LabeledTextArea
                  name={name}
                  value={value}
                  onChange={handleChange}
                  disabled={disabled}
                  placeholder={placeHolder}
                  inputProps={{
                    name,
                    maxLength: charLimit,
                  }}
                />
                {fieldMetaDataJsx}
              </div>
              )}

              {fieldType === 'dropdown' && (
              <div className="fld-input">
                <Select
                  name={name}
                  placeholder={placeHolder}
                  defaultValue={options.find((el) => el.value === value)}
                  options={options}
                  onChange={(v) => {
                    if (isMulti) {
                      const multiValue = v ? v.map((el) => el.value).join(',') : '';
                      handleChange(name, multiValue, true);
                    } else {
                      handleChange(name, v?.value, true);
                    }
                  }}
                  disabled={disabled}
                  isMulti={isMulti}
                  isSearchable={false}
                />
                {hasError && <Error errorText={errorText} />}
              </div>
              )}
            </>
          )}

          {children}
        </div>
      </div>

    </div>
  );
};

LtiModalComponents.defaultProps = {
  name: PropTypes.string,
  tooltipMessage: '',
  isEdit: false,
  hasError: false,
  isMulti: false,
  disabled: false,
  errorText: '',
  options: [],
  isRequired: false,
  inBox: false,
  placeHolder: '',
  inputFieldIcon: (<></>),
  fieldType: '',
  handleChange: () => {},
  isId: false,
  charLimit: '',
  children: (<> </>),
};

LtiModalComponents.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.any,
  tooltipMessage: PropTypes.string,
  isEdit: PropTypes.bool,
  fieldType: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  inBox: PropTypes.bool,
  placeHolder: PropTypes.string,
  inputFieldIcon: PropTypes.elementType,
  isId: PropTypes.bool,
  charLimit: PropTypes.number,
};

export default LtiModalComponents;
