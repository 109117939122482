import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import get from 'lodash/get';
import StatusBox from '../../StatusBox';
import ReportFooterBanner from '../../ReportFooterBanner';
import ReportSidebar from '../../ReportSidebar';
import { aggregrateSteps, filterSteps } from './utils';
import MainBlock from './MainBlock';
import { MODERATE_LIGHT_GREY } from '../../../stylesheets/colors';
import {
  KEY_LEARNING_PLAN_CREATION,
  ROUTE_REPORT,
  COURSE_PROD,
  PROGRAM_CREATION,
} from '../../../constants';

const useStyles = makeStyles(() => ({
  outerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  statusWrapper: {
    display: 'flex',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  sidebarWrapper: {
    minWidth: '24rem',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  innerWrapper: {
    display: 'flex',
    flex: 1,
  },
  columnWrapper: {
    flex: 1,
    display: 'flex',
  },
}));

const ProgramCreationReportBody = ({
  programMetadata,
  data,
  onReUpload,
  onDownload,
  environmentUrl,
  onAssignClick,
  location,
  match,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    overall_success: isSuccessful,
    title: fileName,
    marked_at: markedAt,
    isLoading,
  } = data.meta;

  // Query params
  const transactionId = get(match, 'params.transactionId');
  const programId = get(match, 'params.programId');
  const query = qs.parse(location.search);
  const selectedTab = get(query, 'tab', KEY_LEARNING_PLAN_CREATION);

  // Course id on assignment
  const modifiedCourseId = Number.parseInt(get(location, 'state.courseId', '-1'), 10);

  const selectTab = (newTab) => {
    history.push(
      `/${ROUTE_REPORT}/${programId}/${COURSE_PROD}/${PROGRAM_CREATION}/${transactionId}?tab=${newTab}`,
      { courseId: modifiedCourseId },
    );
  };

  // Explicit equation to false to not allow nulls or undefineds
  const completed = !(isSuccessful === false && !markedAt);
  const isMarked = !!markedAt;

  const sidebarData = aggregrateSteps(data.steps, selectedTab);
  const filteredStep = filterSteps(data.steps, selectedTab);
  const learningPlanStep = filterSteps(data.steps, KEY_LEARNING_PLAN_CREATION);
  const {
    source_config_id: sourceConfigId,
    source_program_id: sourceProgramId,
  } = get(learningPlanStep, 'details[0]', {});

  return (
    <>
      <Paper className={classes.outerWrapper}>
        <div className={classes.statusWrapper}>
          <StatusBox
            fileName={fileName}
            isSuccessful={isSuccessful}
            markedAt={markedAt}
            onDownloadClick={onDownload}
            onReUpload={onReUpload}
            isLoading={!!isLoading}
            replicated={programMetadata.replicated}
            programDetails={filteredStep}
            sourceConfigId={sourceConfigId}
            sourceProgramId={sourceProgramId}
            showReupload
          />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.sidebarWrapper}>
            <ReportSidebar
              header="Program outline"
              data={sidebarData}
              onSelect={selectTab}
              maxTitleLength={40}
              marked={isMarked}
            />
          </div>
          <div className={classes.bodyWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.innerWrapper}>
                <div className={classes.columnWrapper}>
                  <MainBlock
                    data={filteredStep}
                    onAssignClick={onAssignClick}
                    completed={completed}
                    marked={isMarked}
                    modifiedCourseId={modifiedCourseId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      {(isSuccessful || markedAt) && <ReportFooterBanner doceboUrl={environmentUrl} />}
    </>
  );
};

ProgramCreationReportBody.propTypes = {
  programMetadata: PropTypes.shape({
    replicated: PropTypes.bool.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    meta: PropTypes.shape({
      overall_success: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      marked_at: PropTypes.string,
      isLoading: PropTypes.bool,
    }).isRequired,
    steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  onReUpload: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onAssignClick: PropTypes.func.isRequired,
  environmentUrl: PropTypes.string.isRequired,
  // transactionId: PropTypes.number.isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
    }),
  }).isRequired,
};

export default ProgramCreationReportBody;
