import config from '../../config';
import request from '../../helpers/apiHelper';

export const scanForReplication = (lpId) => {
  const url = `${config.ROUTES.REPLICATION_SCAN}/${lpId}/scorms`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const proceedWithReplication = (data) => {
  const url = `${config.ROUTES.REPLICATION_CONFIRM}`;
  // Use POST to tell cloudflare not to cache the response into CDN
  const options = { url, method: 'POST', data };
  return request(options);
};

export const getReplicationProgress = (replicationTid, cancelToken) => {
  const url = `${config.ROUTES.REPLICATION_PROGRESS}/${replicationTid}`;
  const options = { url, method: 'GET', cancelToken };
  return request(options);
};

export const checkValidityOfElucidatPid = (elucidatPid, cancelToken) => {
  const url = `${config.ROUTES.REPLICATION_ELUCIDAT_CHECK}/${elucidatPid}`;
  const options = { url, method: 'GET', cancelToken };
  return request(options);
};

export const checkNewConfigIdExists = (configId, cancelToken) => {
  const url = `${config.ROUTES.REPLICATION_CID_CHECK}/${configId}`;
  const options = { url, method: 'GET', cancelToken };
  return request(options);
};

export const replicationTimeline = (programId) => {
  const url = `${config.ROUTES.REPLICATION}/${programId}/timeline`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const getChannels = (programId) => {
  const url = `${config.ROUTES.OVERVIEW_CONTENT}/channel/${programId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const getConfigNameOfReplicatedProgram = (configId) => {
  const url = `${config.ROUTES.REPLICATION}/${configId}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const getAllFolders = (searchText) => {
  const url = `${config.ROUTES.REPLICATION_ELUCIDAT}/get_all_folders?search_text=${searchText}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const uniqueChannelName = (programId) => {
  const url = `${config.ROUTES.REPLICATION}/unique_channel_name/${programId}`;
  const options = { url, method: 'GET' };
  return request(options);
};
