import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ACCORDION_GREY, SWITCH_GREY, BLACK } from '../../stylesheets/colors';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import { COMPLETED, COMPLETED_WITH_ERRORS } from '../../constants';

const useStyles = makeStyles(() => ({
  displayContainer: {
    padding: '0 1.5rem',
  },
  displayLabelText: {
    padding: '0.25rem 0',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  contentHeadingHelper: {
    padding: '0.5rem 0',
  },
  separator: {
    borderBottom: `1px solid ${SWITCH_GREY}`,
  },
  subHeading: {
    fontWeight: 800,
  },
  infoContentText: {
    color: BLACK,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  scormHeadingWrapper: {
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorTitle: {
    marginLeft: '0.5rem',
  },
}));

const IndividualScormOverview = ({
  status, tmList, isMultipleScorm,
}) => {
  const classes = useStyles();

  return (
    <>
      {(isMultipleScorm && status === COMPLETED_WITH_ERRORS)
      || (!isMultipleScorm && status === COMPLETED) ? (
          tmList.length > 0 && tmList.map(({ component_name, tm_name }) => (
            <Box className={classes.displayContainer} key={`${component_name}_${tm_name}`}>
              <Box className={classes.scormHeadingWrapper}>
                <Typography variant="subtitle2" className={classes.subHeading}>SCORM Details:</Typography>
                {isMultipleScorm ? (
                  <Box component="div" className={classes.errorContainer}>
                    <img
                      src={ErrorRoundIcon}
                      alt="status-icon"
                      width={15}
                      height={15}
                    />
                    <Typography variant="subtitle2" className={classes.errorTitle}>Failed to refresh re-release</Typography>
                  </Box>
                ) : null}
              </Box>
              <Box className={`${classes.contentHeadingHelper} ${classes.separator}`}>
                <Box className={classes.displayLabelText}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    Component:
                    <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                      {component_name}
                    </Typography>
                  </Typography>
                </Box>
                <Box className={classes.displayLabelText}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    Training Material:
                    <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                      {tm_name}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))
        ) : null}
    </>
  );
};

IndividualScormOverview.defaultProps = {
  isMultipleScorm: false,
};

IndividualScormOverview.propTypes = {
  status: PropTypes.string.isRequired,
  tmList: PropTypes.array.isRequired,
  isMultipleScorm: PropTypes.bool,
};

export default IndividualScormOverview;
