import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  MCKINSEY_BLUE,
  SUCCESS_GREEN,
  ERROR_RED,
  MODERATE_LIGHT_GREY,
  DARK_GREY,
  DARK_MEDIUM_GREY,
} from '../../stylesheets/colors';
import RightArrowIcon from '../../assets/icons/right-arrow-blue.svg';
import { transactionNameFormatter } from '../../helpers/formattingHelpers';

const iconColorChooser = ({ success, marked }) => ({
  true: {
    true: SUCCESS_GREEN,
    false: SUCCESS_GREEN,
  },
  false: {
    true: DARK_MEDIUM_GREY,
    false: ERROR_RED,
  },
}[success][marked]);

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '48px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    width: '100%',
    padding: '0px',
  },
  icon: {
    color: iconColorChooser,
    width: '1.5rem',
    height: '1.5rem',
  },
  body: {
    color: ({ active }) => (active ? MCKINSEY_BLUE : DARK_GREY),
    fontSize: '1rem',
    overflow: 'hidden',
    marginLeft: '5px',
    marginRight: '20px',
    textAlign: 'left',
  },
  arrow: {
    backgroundColor: 'transparent',
    backgroundImage: ({ active }) => (active ? `url(${RightArrowIcon})` : null),
    backgroundSize: 'cover',
    height: '0.875rem',
    width: '0.974rem',
  },
  splitter: {
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    borderStyle: 'hidden hidden solid hidden',
    width: '100%',
  },
});

const ReportSidebarCard = ({
  success, active, title, index, onSelect, maxTitleLength, marked,
}) => {
  const classes = useStyles({ success, active, marked });

  const Icon = success ? CheckCircleOutlineIcon : ErrorOutlineIcon;

  return (
    <div className={classes.outerWrapper}>
      <div />
      <button className={classes.wrapper} type="button" onClick={() => onSelect(index)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon className={classes.icon} />
          <div className={classes.body}>{transactionNameFormatter(title, maxTitleLength)}</div>
        </div>
        <div className={classes.arrow} />
      </button>
      <div className={classes.splitter} />
    </div>
  );
};

ReportSidebarCard.propTypes = {
  success: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  marked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  maxTitleLength: PropTypes.number.isRequired,
};

export default ReportSidebarCard;
