import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LabledTextField from '../GroupWorksPages/common/LabledTextField';

const useStyles = makeStyles({
  bodyWrapper: {
    flex: 1,
    padding: '1.75rem 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    '& > h2': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& > h6': {
        fontWeight: 700,
      },
    },
  },
  detail: {
    overflow: 'unset',
    width: '50%',
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 1rem 0',
  },
  closeBtn: {
    alignSelf: 'flex-end',
  },
  labelWrapper: {
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'row',
    marginBottom: '0.875rem',
    alignItems: 'center',
  },
  description: {
    margin: 0,
    '& > div': {
      padding: '0 10px',
    },
  },
});

const ActionModal = ({
  open, title, isClone, data, onClose, onSave,
}) => {
  const classes = useStyles();
  const [formValue, setFormValue] = useState({});
  const [disableSubmit, setDisable] = useState(false);

  useEffect(() => {
    setFormValue(data.item);
  }, [data.item]);

  useEffect(() => {
    if (!_isEmpty(formValue)) {
      setDisable(formValue.course_name.trim() === '' || formValue.course_desc.trim() === '');
    }
  }, [formValue]);

  const onTextChange = (key, val) => {
    setFormValue((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  return (
    !_isEmpty(formValue) && (
      <Dialog fullWidth open={open} onClose={onClose}>
        <IconButton className={classes.closeBtn} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className={classes.bodyWrapper}>
          <DialogTitle className={classes.title}>
            <Typography component="span" variant="subtitle1">
              {title}
            </Typography>
            {isClone && <Typography>{formValue.course_code}</Typography>}
          </DialogTitle>
          <DialogContent className={classes.detail}>
            <div className={classes.formField}>
              <LabledTextField
                label="Course Name"
                value={formValue.course_name}
                onChange={(val) => onTextChange('course_name', val)}
              />
            </div>
            <div className={classes.formField}>
              <Typography variant="body1" className={classes.labelWrapper}>
                Course Description
              </Typography>
              <TextField
                className={classes.description}
                margin="dense"
                multiline
                rows={3}
                rowsMax={3}
                variant="outlined"
                value={formValue.course_desc}
                onChange={(e) => onTextChange('course_desc', e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              disabled={disableSubmit}
              onClick={() => onSave(data.droppableId, data.index, formValue)}
            >
              Save
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  );
};

ActionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isClone: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    droppableId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    item: PropTypes.shape({
      lp_config_id: PropTypes.string.isRequired,
      lp_desc: PropTypes.string.isRequired,
      lp_icon: PropTypes.string,
      lp_name: PropTypes.string.isRequired,
      connected: PropTypes.string.isRequired,
      course_code: PropTypes.string.isRequired,
      course_desc: PropTypes.string.isRequired,
      course_icon: PropTypes.string,
      course_name: PropTypes.string.isRequired,
      course_type: PropTypes.string.isRequired,
      isClone: PropTypes.bool.isRequired,
      originalCourseCode: PropTypes.string.isRequired,
      metadata: PropTypes.string,
    }).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default memo(ActionModal);
