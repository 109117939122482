import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import { BLACK } from '../../../stylesheets/colors';
import MCKINSEY_SANS from '../../../stylesheets/fonts';
import CustomTable from '../../../components/common/CustomTable';
import TrainingMaterialTableHeader from './TrainingMaterialTableHeader';
import TrainingMaterialTableBody from './TrainingMaterialTableBody';

const useStyles = makeStyles({
  modalWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '3.5rem 5.6rem 1rem 4rem',
  },
  modalHeading: {
    fontSize: '1.5rem',
    color: BLACK,
    fontWeight: 600,
    marginBottom: '2.5rem',
    fontFamily: MCKINSEY_SANS,
  },
  matTable: {
    width: '100%',
  },
});

export default function TrainingMaterialModal({ data, doceboUrl, programType }) {
  const classes = useStyles({});

  return (
    <Box className={classes.modalWrapper}>
      <Typography component="span" className={classes.modalHeading}>
        {data.component_title}
      </Typography>
      <Box className={classes.matTable}>
        <CustomTable
          headerData={<TrainingMaterialTableHeader />}
          bodyData={(
            <TrainingMaterialTableBody
              data={data.training_materials}
              doceboUrl={doceboUrl}
              courseId={data?.id}
              programType={programType}
            />
          )}
        />
      </Box>
    </Box>
  );
}

TrainingMaterialModal.propTypes = {
  data: PropTypes.object.isRequired,
  doceboUrl: PropTypes.string.isRequired,
  programType: PropTypes.string.isRequired,
};
