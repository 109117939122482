import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { LIGHT_GREEN, SUCCESS_GREEN, DARK_MEDIUM_GREY } from '../../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: LIGHT_GREEN,
    color: SUCCESS_GREEN,
    padding: '15px',
    alignItems: 'center',
  },
  checkMark: {
    marginRight: '15px',
    width: '25px',
    height: '25px',
  },
  title: {
    flex: 1,
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: '0px',
    width: '25px',
    height: '25px',
  },
  closeIcon: {
    color: DARK_MEDIUM_GREY,
    width: '25px',
    height: '25px',
  },
}));

const SuccessfulAssignmentPrompt = ({ show }) => {
  const history = useHistory();
  const classes = useStyles();

  if (!show) return <div />;

  const onClose = () => {
    const currentPage = window.location.pathname;
    history.replace(currentPage);
  };

  return (
    <div className={classes.wrapper}>
      <CheckCircleIcon className={classes.checkMark} />
      <div className={classes.title}>
        Content assigned successfully
      </div>
      <button type="button" onClick={onClose} className={classes.button}>
        <CloseIcon className={classes.closeIcon} />
      </button>
    </div>
  );
};

SuccessfulAssignmentPrompt.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default SuccessfulAssignmentPrompt;
