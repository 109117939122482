import React from 'react';
import PropTypes from 'prop-types';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MCKINSEY_BLUE, WHITE, MODERATE_LIGHT_GREY } from '../../stylesheets/colors';
import { ACTIVE_LOGS_MENU } from '../../constants';
import InfoTooltip from './InfoTooltip';

const useStyles = makeStyles({
  arrowIcon: {
    '&.MuiIconButton-root': {
      backgroundColor: MCKINSEY_BLUE,
      color: WHITE,
      borderRadius: 0,
      width: '100%',
      '&:hover': {
        backgroundColor: MCKINSEY_BLUE,
        color: WHITE,
      },
    },
  },
  navIcon: {
    width: '80%',
    margin: 'auto',
  },
  navItem: {
    '&.MuiMenuItem-root': {
      borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    },
  },
});

const GlobalActivityNav = ({
  expanded, toggleDrawer, selectedMenu, onMenuItemClick, disabled, platformType,
}) => {
  const classes = useStyles();

  return (
    <>
      {expanded
        ? (
          <IconButton aria-label="expanded" size="large" disableRipple className={classes.arrowIcon} onClick={() => toggleDrawer(false)}>
            <ArrowForwardIosIcon fontSize="inherit" />
          </IconButton>
        )
        : (
          <IconButton aria-label="collapsed" size="large" disableRipple className={classes.arrowIcon} onClick={() => toggleDrawer(true)}>
            <ArrowBackIosIcon fontSize="inherit" />
          </IconButton>
        )}
      <MenuList disablePadding>
        {ACTIVE_LOGS_MENU.map((item) => (
          <InfoTooltip title={item?.title} key={item.key}>
            <MenuItem
              selected={selectedMenu.key === item.key}
              disabled={disabled || item.disable || (platformType === 'users' && item.key === 'processQueue')}
              onClick={() => onMenuItemClick(item)}
              className={classes.navItem}
              lab
            >
              <ListItemIcon>
                <img src={item.logo} alt="process-nav" className={classes.navIcon} />
              </ListItemIcon>
            </MenuItem>
          </InfoTooltip>
        ))}
      </MenuList>
    </>
  );
};

GlobalActivityNav.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  selectedMenu: PropTypes.string.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  platformType: PropTypes.string.isRequired,
};

export default GlobalActivityNav;
