import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/GetApp';

import ReUploadBox from '../ReUploadBox';

import {
  STATUS_LOADING, STATUS_MARKED, STATUS_SUCCESS, STATUS_FAILED, STATUS_REPLICATED,
} from '../../constants';

import { DARK_MEDIUM_GREY, DARK_GREY, MCKINSEY_BLUE } from '../../stylesheets/colors';

import SuccessPrompt from './SuccessPrompt';
import FailedPrompt from './FailedPrompt';
import MarkedPrompt from './MarkedPrompt';
import ReplicationPrompt from './ReplicationPrompt';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    padding: '3rem',
    paddingBottom: '2rem',
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  reUploadWrapper: {
    display: 'flex',
  },
  statusTitle: {
    fontSize: '1.125rem',
    color: DARK_MEDIUM_GREY,
  },
  statusBody: {
    fontSize: '1.125rem',
    color: DARK_GREY,
  },
  downloadBoxWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: MCKINSEY_BLUE,
  },
  downloadIcon: {
    width: '1.3rem',
    height: '1.3rem',
    marginRight: '1rem',
  },
  downloadButton: {
    fontFamily: 'McKinseySans',
  },
});

const DownloadBox = ({ fileName, onDownloadClick }) => {
  const classes = useStyles();

  return (
    <button className={classes.downloadBoxWrapper} onClick={onDownloadClick} type="button">
      <DownloadIcon className={classes.downloadIcon} />
      <div className={classes.downloadButton}>{fileName}</div>
    </button>
  );
};

DownloadBox.propTypes = {
  fileName: PropTypes.string.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
};

const Loading = () => <div>Loading...</div>;

const StatusBox = ({
  fileName,
  isSuccessful,
  onDownloadClick,
  markedAt,
  onReUpload,
  showReupload,
  isLoading,
  replicated,
  sourceConfigId,
  sourceProgramId,
}) => {
  let statusType = STATUS_LOADING;
  if (replicated) {
    statusType = STATUS_REPLICATED;
  } else if (!isLoading) {
    statusType = STATUS_SUCCESS;
    if (!isSuccessful) {
      statusType = STATUS_MARKED;
      if (!markedAt) {
        statusType = STATUS_FAILED;
      }
    }
  }

  const classes = useStyles();
  const Prompt = {
    [STATUS_LOADING]: Loading,
    [STATUS_SUCCESS]: SuccessPrompt,
    [STATUS_FAILED]: FailedPrompt,
    [STATUS_MARKED]: MarkedPrompt,
    [STATUS_REPLICATED]: ReplicationPrompt,
  }[statusType];

  const shouldShowReupload = !(isSuccessful || markedAt) && showReupload;

  const Box = shouldShowReupload ? ReUploadBox : DownloadBox;

  return (
    <div className={classes.wrapper}>
      <div className={classes.statusWrapper}>
        <div className={classes.statusTitle}>Status:</div>
        <div className={classes.statusBody}>
          <Prompt
            fileName={fileName}
            onDownloadClick={onDownloadClick}
            markedAt={markedAt}
            replicated={replicated}
            sourceConfigId={sourceConfigId}
            sourceProgramId={sourceProgramId}
          />
        </div>
      </div>
      {!replicated && (
        <div className={classes.reUploadWrapper}>
          <Box
            fileName={fileName}
            onReUpload={onReUpload}
            onDownloadClick={onDownloadClick}
            title="Upload updated XLSX"
            subtitle=""
            direction="row"
          />
        </div>
      )}
    </div>
  );
};

StatusBox.propTypes = {
  fileName: PropTypes.string.isRequired,
  isSuccessful: PropTypes.bool.isRequired,
  markedAt: PropTypes.string,
  onDownloadClick: PropTypes.func.isRequired,
  onReUpload: PropTypes.func,
  showReupload: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  replicated: PropTypes.bool,
  sourceConfigId: PropTypes.string.isRequired,
  sourceProgramId: PropTypes.number.isRequired,
};

StatusBox.defaultProps = {
  showReupload: false,
  replicated: false,
  markedAt: null,
  onReUpload: () => null,
};

export default StatusBox;
