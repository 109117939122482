import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { keyToTitleLookup } from './utils';
import MainBlockCard from './MainBlockCard';
import EnrollmentRulesTable from './EnrollmentRulesTable';
import {
  TEXT_CONTENT_ADDED,
  KEY_CHANNEL_CREATION,
  KEY_CATALOG_CREATION,
  KEY_ENROLLMENT_RULE_CREATION,
} from '../../../constants';

import { DARK_MEDIUM_GREY } from '../../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '30px',
    marginTop: '0px',
  },
  header: {
    color: DARK_MEDIUM_GREY,
    fontSize: '1rem',
    width: '100%',
    display: 'flex',
    paddingTop: '24px',
    paddingBottom: '36px',
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentheader: {
    paddingTop: '0px',
  },
}));

const MainBlock = ({
  data,
  onAssignClick,
  completed,
  modifiedCourseId,
  marked,
  showFindContent,
}) => {
  const classes = useStyles();

  const { details, key } = data;
  const header = keyToTitleLookup[key];

  const isCatalogOrChannelCreation = key === KEY_CHANNEL_CREATION || key === KEY_CATALOG_CREATION;
  const isEnrollmentRules = key === KEY_ENROLLMENT_RULE_CREATION;
  // If transaction is marked, then dont show failed
  // const filteredDetails = details.filter((detail) => !(!detail.success && marked));

  const bullets = details.map((detail) => (
    <Fragment key={detail.id}>
      <MainBlockCard
        key={detail.id}
        courseId={detail.course_id}
        contentList={detail.assigned_content}
        title={detail.title}
        metadata={detail.metadata}
        subtitle={detail.subtitle}
        success={detail.success}
        marked={marked}
        metadataSuccess={detail.metadata_success}
        logo={detail.logo}
        onAssignClick={onAssignClick}
        completed={completed}
        showSuccessPrompt={detail.course_id === modifiedCourseId}
        showFindContent={showFindContent}
        lo={detail.lo}
      />
      {isEnrollmentRules && (
        <EnrollmentRulesTable
          key={detail.id}
          ruleType={detail.rule_type}
          lpCode={detail.lp_code}
          groupName={detail.group_name}
          allCoursesExist={detail.all_courses_exist}
          coursesDetails={detail.courses_details}
          success={detail.success}
        />
      )}
    </Fragment>
  ));
  const bulletsMeta = isCatalogOrChannelCreation ? bullets.slice(0, 1) : bullets;
  const bulletsContent = bullets.slice(1);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>{header}</div>
      <div className={classes.body}>{bulletsMeta}</div>
      {isCatalogOrChannelCreation && bulletsContent.length > 0 && (
        <div>
          <div className={`${classes.header} ${classes.contentheader}`}>{TEXT_CONTENT_ADDED}</div>
          <div className={classes.body}>{bulletsContent}</div>
        </div>
      )}
    </div>
  );
};

MainBlock.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        course_id: PropTypes.number,
        assigned_content: PropTypes.array,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        success: PropTypes.bool.isRequired,
        logo: PropTypes.string.isRequired,
        lo: PropTypes.array,
      }),
    ).isRequired,
  }),
  onAssignClick: PropTypes.func.isRequired,
  completed: PropTypes.bool.isRequired,
  marked: PropTypes.bool.isRequired,
  modifiedCourseId: PropTypes.number.isRequired,
  showFindContent: PropTypes.bool,
};

MainBlock.defaultProps = {
  data: {
    key: 'loading',
    details: [],
  },
  showFindContent: false,
};

export default MainBlock;
