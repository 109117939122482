import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import { REPORT_ERROR_RED } from '../../stylesheets/colors';
import CrossOutline from '../../assets/icons/cross-outline2.svg';

const useStyles = makeStyles(() => ({
  errorBox: {
    paddingBottom: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiTypography-root': {
      color: REPORT_ERROR_RED,
      paddingLeft: '0.5rem',
    },
  },
}));

const Error = ({ errorIcon, errorText }) => {
  const classes = useStyles();

  return (
    <Box className={classes.errorBox}>
      {errorIcon && <img src={CrossOutline} alt="cross-icon" />}
      <Typography variant="body1">{errorText}</Typography>
    </Box>
  );
};

Error.defaultProps = {
  errorIcon: false,
};

Error.propTypes = {
  errorText: PropTypes.string.isRequired,
  errorIcon: PropTypes.bool,
};

export default Error;
