import {
  PORTFOLIO_DATA_FETCH,
  PORTFOLIO_DATA_FETCH_SUCCESS,
  PORTFOLIO_DATA_FETCH_FAILED,
  ADD_NEW_PORTFOLIO,
  ADD_NEW_PORTFOLIO_SUCCESS,
  ADD_NEW_PORTFOLIO_FAILURE,
  DELETE_PORTFOLIO,
  DELETE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_FAILURE,
  UPDATE_PORTFOLIO,
  UPDATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_FAILURE,
  GET_PORTFOLIO_OFFERING_LIST,
  GET_PORTFOLIO_OFFERING_LIST_SUCCESS,
  GET_PORTFOLIO_OFFERING_LIST_FAILURE,
} from '../constants';

const initialState = {
  isLoading: true,
  isError: false,
  result: {},
  message: '',
  statusText: '',
  id: '',
  searchText: '',
  page: 0,
  portfolioList: [],
  offeringList: [],
  isPortfolioListCall: false,
};

function toArrayOfObject(arr) {
  return arr.map((a) => ({ value: a, label: a }));
}

function updateScreenState(state, { data }) {
  const { searchValue, pageNum } = data;
  if (typeof searchValue !== 'undefined' && searchValue !== state.searchText) {
    return {
      ...state, result: {}, searchText: searchValue, isLoading: true, isError: false, page: pageNum, message: '', id: '',
    };
  }
  return {
    ...state, isLoading: true, isError: false, page: pageNum || state.page, message: '', id: '',
  };
}

function onEditScreenState(state) {
  return {
    ...state, isLoading: true, isError: false, message: '', id: '',
  };
}

function getPortgolioOfferingList(state, { data }) {
  if (data) {
    return {
      ...state, isLoading: true, isError: false, message: '', isPortfolioListCall: false,
    };
  }
  return {
    ...state, isLoading: true, isError: false, message: '', isPortfolioListCall: true,
  };
}

function onFetchSuccess(state, { results }) {
  if (state.page > 1) {
    const data = state.result.data ? state.result.data.concat(results.data) : results.data;
    const result = results;
    result.data = data;
    return {
      ...state, result, message: '', id: '', isLoading: false,
    };
  }
  const result = results;
  return { ...state, result, isLoading: false };
}
function onfetchPortfolioOfferingSuccess(state, { results }) {
  const transformedData = toArrayOfObject(results.data);
  if (state.isPortfolioListCall) {
    return {
      ...state,
      isLoading: false,
      portfolioList: transformedData,
      isPortfolioListCall: false,
    };
  }
  return {
    ...state, isLoading: false, offeringList: transformedData, isPortfolioListCall: false,
  };
}

function onFetchFailure(state, { response }) {
  const { message } = response;
  return {
    ...state, message, isLoading: false, isError: true,
  };
}

function onCreateNewPortfolioSuccess(state, { response }) {
  const { message } = response;
  if (response?.data?.id) {
    const { data: { id } = {} } = response;
    return {
      ...state, message, isLoading: false, id,
    };
  }
  return { ...state, message, isLoading: false };
}

function onCreateNewPortfolioFailure(state, { response }) {
  const { message } = response;
  return {
    ...state, message, isLoading: false, isError: true,
  };
}

export default function portfolioMasterData(state = initialState, action = {}) {
  switch (action.type) {
    case PORTFOLIO_DATA_FETCH:
    case UPDATE_PORTFOLIO:
      return updateScreenState(state, action);
    case PORTFOLIO_DATA_FETCH_SUCCESS:
      return onFetchSuccess(state, action);
    case PORTFOLIO_DATA_FETCH_FAILED:
      return onFetchFailure(state, action);
    case ADD_NEW_PORTFOLIO:
    case DELETE_PORTFOLIO:
      return onEditScreenState(state, action);
    case ADD_NEW_PORTFOLIO_SUCCESS:
    case DELETE_PORTFOLIO_SUCCESS:
    case UPDATE_PORTFOLIO_SUCCESS:
      return onCreateNewPortfolioSuccess(state, action);
    case ADD_NEW_PORTFOLIO_FAILURE:
    case DELETE_PORTFOLIO_FAILURE:
    case UPDATE_PORTFOLIO_FAILURE:
    case GET_PORTFOLIO_OFFERING_LIST_FAILURE:
      return onCreateNewPortfolioFailure(state, action);
    case GET_PORTFOLIO_OFFERING_LIST:
      return getPortgolioOfferingList(state, action);
    case GET_PORTFOLIO_OFFERING_LIST_SUCCESS:
      return onfetchPortfolioOfferingSuccess(state, action);
    default:
      return state;
  }
}
