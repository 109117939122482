import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import {
  Table, TableBody, TableCell, TableRow, IconButton,
} from '@mui/material';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EnhancedTableHeader from '../Table/EnhancedTableHeader';
import { MODERATE_DARK_GREY } from '../../stylesheets/colors';

const TableRc = ({
  columns,
  data,
  customBody,
  tableStyle,
  actions,
  noDataMessage,
  onSort,
  order,
  orderBy,
}) => {
  const validElement = isValidElement(customBody);

  return (
    <Box>
      {validElement || data?.length > 0 ? (
        <TableContainer style={tableStyle}>
          <Table aria-label="simple table" padding="normal" stickyHeader>
            <EnhancedTableHeader
              headCells={actions?.length > 0 ? [...columns, { title: 'Actions', property: 'actions', width: '10%' }] : columns}
              onRequestSort={onSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {!validElement ? (
                <>
                  {data && data.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...row.style }}
                    >
                      {columns.map((cell) => (
                        <TableCell
                          key={cell}
                          sx={{ ...cell.style }}
                          component="th"
                          scope="row"
                        >
                          {(typeof row[cell.property] === 'string' || typeof row[cell.property] === 'number')
                            ? (<Typography>{row[cell.property]}</Typography>) : row[cell.property]}
                        </TableCell>
                      ))}
                      {actions.length > 0 && actions.map((action) => (
                        <TableCell
                          key={action.name}
                          component="th"
                          scope="row"
                        >
                          <IconButton
                            aria-label={action.name}
                            onClick={(e) => action?.onClick(e, row)}
                          >
                            {action?.icon}
                          </IconButton>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (<>{customBody}</>)}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="subtitle2" style={{ color: MODERATE_DARK_GREY, fontWeight: 300 }}>
          {noDataMessage}
        </Typography>
      )}
    </Box>
  );
};

TableRc.defaultProps = {
  customBody: null,
  tableStyle: {},
  actions: [],
  noDataMessage: '',
  onSort: () => { },
  order: '',
  orderBy: '',
};

TableRc.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  customBody: PropTypes.node,
  tableStyle: PropTypes.object,
  actions: PropTypes.array,
  noDataMessage: PropTypes.string,
  onSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
};
export default TableRc;
