import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Table, TableContainer } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import _map from 'lodash/map';
import { MEDIUM_GREY } from '../../stylesheets/colors';
import Header from '../../components/common/Header';
import LabledTextField from '../../components/common/LabledTextField';
import EnhancedTableHeader from '../../components/Table/EnhancedTableHeader';
import {
  PLATFORM_LEVEL_USERS_TABLE_HEAD_ATTRIBUTES, ROUTE_HOME,
} from '../../constants';
import { getComparator, tableSort } from '../../helpers/utils';
import NoDataComponent from '../../components/common/NoDataComponent';
import NoSearchedUserData from '../../assets/img/noChannel.svg';
import CustomSnackbar from '../../components/common/CustomSnackbar';
import LoadingCircle from '../../components/common/LoadingCircle/LoadingCircle';
import UsersTableBody from '../../components/PlatformLevelUsers/UsersDataTableBody';
import PaginationComponent from '../../components/PlatformLevelUsers/PaginationComponent';
import UsersFilterModal from '../../components/PlatformLevelUsers/UsersFilterModal';
import HyperlinkButton from '../../components/common/HyperlinkButton';

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  },
  containerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pageHeader: {
    padding: '2rem',
  },
  usersSearchSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1rem 0',
    width: '33rem',

  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
    },
    '& th.MuiTableCell-root:first-child > div': {
      marginLeft: '1.5rem',
    },
    '& td.MuiTableCell-root:first-child p': {
      marginLeft: '1.5rem',
    },
    '& th.MuiTableCell-root': {
      lineHeight: '1.92rem',
    },
  },
  secondaryTextCls: {
    fontSize: '2.25rem',
  },
  noDataContent: {
    boxShadow: `0px 1px 4px -2px ${MEDIUM_GREY}`,
    flex: 1,
  },
  filtersButton: {
    marginLeft: '1.25rem',
    marginBottom: '9px',
    alignSelf: 'end',
  },
  clearFilterBtn: {
    marginLeft: '1.25rem',
    alignSelf: 'end',
    marginBottom: '18px',
  },
});

const filtersObj = {
  selectedPrograms: [],
  selectedClients: [],
};

const PlatformUsers = (props) => {
  const {
    usersData, getUsers, clearAlertBar, match,
  } = props;
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [orderSort, setOrderSort] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [userFilters, setUserFilters] = useState(filtersObj);

  const { selectedPrograms, selectedClients } = userFilters;

  const searchRef = useRef();
  const pageRef = useRef();
  const userFiltersRef = useRef();

  searchRef.current = searchValue;
  pageRef.current = currentPage;
  userFiltersRef.current = userFilters;

  const { isLoading, result, snackbarObj } = usersData;
  const { data: usersList, total_rows: totalUsers } = result;
  const pageSize = 50;

  const history = useHistory();
  const programType = get(match, 'params.programType');
  const platformType = get(match, 'params.platformType');

  const getUsersFunc = useCallback(({
    searchVal, pageNum, programs, clients,
  }) => {
    const selectedProgramsValues = _map(programs || userFiltersRef.current.selectedPrograms, 'program_id');
    const selectedClientsValues = _map(clients || userFiltersRef.current.selectedClients, 'value');

    const data = {
      params: {
        search_param: searchVal || searchRef.current,
        page: pageNum || pageRef.current,
        page_size: pageSize,
      },
      body: {
        programs: (selectedProgramsValues || []),
        clients: (selectedClientsValues || []),
      },
    };
    getUsers(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsers]);

  useEffect(() => {
    getUsersFunc({});
  }, [getUsersFunc]);

  const debouncedSearchHandler = useCallback(
    _debounce((searchVal) => {
      getUsersFunc({ searchVal, pageNum: 1 });
    }, 500),
    [],
  );

  const handleSearch = (searchVal) => {
    batchUpdates(() => {
      setSearchValue(searchVal);
      setCurrentPage(1);
    });
    debouncedSearchHandler(searchVal);
  };

  const handlePageClick = useCallback((pageNum) => {
    setCurrentPage(pageNum);
    getUsersFunc({ pageNum });
  }, [getUsersFunc]);

  const getSearchIcon = () => {
    if (!searchValue) {
      return <SearchIcon />;
    }
    return <CloseIcon data-testid="clearSearch" onClick={() => handleSearch('')} style={{ cursor: 'pointer' }} />;
  };

  const handleRequestSort = () => {
    const isAsc = (orderSort && orderSort === 'asc');
    setOrderSort(isAsc ? 'desc' : 'asc');
  };

  const onUserClick = (userId) => {
    history.push(`/${ROUTE_HOME}/${programType}/${platformType}/${userId}`);
  };

  const tableJsx = () => (totalUsers ? (
    <>
      <TableContainer style={{ maxHeight: '40vh', marginBottom: '3rem', flex: 1 }}>
        <Table aria-label="Users table" className={classes.table} padding="normal" stickyHeader>
          <EnhancedTableHeader
            headCells={PLATFORM_LEVEL_USERS_TABLE_HEAD_ATTRIBUTES}
            order={orderSort}
            orderBy={orderSort ? 'user_name' : ''}
            onRequestSort={handleRequestSort}
          />
          <UsersTableBody
            tableData={orderSort ? tableSort(usersList, getComparator(orderSort, 'user_name')) : usersList}
            onUserClick={onUserClick}
          />
        </Table>
      </TableContainer>
      <PaginationComponent
        totalUsers={totalUsers}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handlePageClick={handlePageClick}
        pageSize={pageSize}
      />
    </>
  ) : (
    <Box className={classes.noDataContent}>
      <NoDataComponent
        imgSrc={NoSearchedUserData}
        primaryText="Oops!"
        secondaryText="Could not find user you were looking for."
        secondaryTextCls={classes.secondaryTextCls}
      />
    </Box>
  ));

  const handleClearAll = useCallback(async () => {
    await batchUpdates(() => {
      setUserFilters(filtersObj);
      setCurrentPage(1);
    });
    getUsersFunc({
      pageNum: 1,
      programs: [],
      clients: [],
    });
  }, [getUsersFunc]);

  const handleApplyFilters = async (updatedFiltersObj) => {
    const { selectedProgramsClone, selectedClientsClone } = updatedFiltersObj;
    batchUpdates(() => {
      setUserFilters({
        selectedPrograms: selectedProgramsClone,
        selectedClients: selectedClientsClone,
      });
      setCurrentPage(1);
      setShowFiltersModal(false);
    });
    getUsersFunc({
      programs: selectedProgramsClone,
      clients: selectedClientsClone,
      pageNum: 1,
    });
  };

  const filtersLength = selectedClients.length + selectedPrograms.length;

  return (
    <Box className={classes.wrapper}>
      <Paper className={classes.containerWrapper}>
        <Header heading="Users" subHeading="Details of all the users on the platform" className={classes.pageHeader} />
        <Box className={classes.wrapper}>
          <Typography variant="h1" component={'span'}>
            {`${totalUsers} Users`}
          </Typography>
          <Box className={`${classes.usersSearchSection}`}>
            <LabledTextField
              label="Search"
              placeholder="Search Username / Email"
              inputProps={{
                name: 'search_client',
                'data-testid': 'search_user',
                InputProps: {
                  endAdornment: getSearchIcon(),
                },
              }}
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button
              className={classes.filtersButton}
              color="primary"
              variant="contained"
              type="button"
              onClick={() => { setShowFiltersModal(true); }}
            >
              {`Filters ${filtersLength ? `(${filtersLength})` : ''}`}
            </Button>
            {filtersLength > 0 ? (
              <HyperlinkButton
                className={classes.clearFilterBtn}
                onClick={handleClearAll}
              >
                Clear Filters
              </HyperlinkButton>
            ) : null}
          </Box>
          {isLoading ? <LoadingCircle /> : tableJsx()}
        </Box>
      </Paper>
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={clearAlertBar} />
      {showFiltersModal
        && (
        <UsersFilterModal
          open={showFiltersModal}
          onClose={() => setShowFiltersModal(false)}
          programType={programType}
          handleApplyFilters={handleApplyFilters}
          selectedPrograms={selectedPrograms}
          selectedClients={selectedClients}
          isLoading={false}
          loadingText={''}
        />
        )}
    </Box>
  );
};
PlatformUsers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programId: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  getUsers: PropTypes.func.isRequired,
  clearAlertBar: PropTypes.func.isRequired,
  usersData: PropTypes.object.isRequired,
};

export default PlatformUsers;
