import React from 'react';

import Radio from '@material-ui/core/Radio';
import withStyles from '@material-ui/core/styles/withStyles';

import { LIGHT_MEDIUM_GREY, MCKINSEY_BLUE } from '../../../stylesheets/colors';

const CustomRadio = withStyles({
  root: {
    color: LIGHT_MEDIUM_GREY,
    '&$checked': {
      color: MCKINSEY_BLUE,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />); // eslint-disable-line react/jsx-props-no-spreading

export default CustomRadio;
