import React, {
  useState, memo, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  LIGHT_MEDIUM_GREY,
} from '../../stylesheets/colors';

const ITEM_HEIGHT = 48;

const useStyles = makeStyles({
  actionBtn: {
    height: '2.5rem',
    '&.MuiButton-contained:disabled': {
      backgroundColor: LIGHT_MEDIUM_GREY,
    },
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  actionMenu: {
    borderRadius: 0,
  },
  menuItem: {
    width: '100%',
  },
});

const DropdownMenu = forwardRef(({
  children, disableBtn, title, btnWidth,
}, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    handleClose,
  }));

  return (
    <>
      <Button
        className={classes.actionBtn}
        variant="contained"
        color="primary"
        style={{ width: btnWidth }}
        disabled={disableBtn}
        endIcon={anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '31ch',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'top',
        }}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.actionMenu }}
      >
        {children}
      </Menu>
    </>
  );
});

DropdownMenu.defaultProps = {
  disableBtn: false,
  btnWidth: '',
};

DropdownMenu.propTypes = {
  disableBtn: PropTypes.bool,
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  btnWidth: PropTypes.string,
};

DropdownMenu.displayName = 'DropdownMenu';

export default memo(DropdownMenu);
