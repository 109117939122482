import React from 'react';
import Typography from '@material-ui/core/Typography';
import TableCell from '@mui/material/TableCell';

const TrainingMaterialTableHeader = () => (
  <>
    <TableCell>
      <Typography key="order">Order</Typography>
    </TableCell>
    <TableCell>
      <Typography key="tmName">Training Material Name</Typography>
    </TableCell>
    <TableCell>
      <Typography key="materialType">Material Type</Typography>
    </TableCell>
    <TableCell>
      <Typography key="materialType">Actions</Typography>
    </TableCell>
  </>
);

export default TrainingMaterialTableHeader;
