import {
  BLACK,
  DROPDOWN_HOVER,
  DROPDOWN_SELECT,
  LIGHT_MEDIUM_GREY, MODERATE_DARK_GREY, MODERATE_LIGHT_GREY,
  PALE_GREY,
  WHITE,
} from '../../stylesheets/colors';
import theme from '../../stylesheets/theme';

export const getOptionsBgColor = ({ isDisabled, isFocused, isSelected }) => {
  let bgColor = WHITE;
  if (isDisabled) {
    bgColor = PALE_GREY;
  } else if (isFocused) {
    bgColor = DROPDOWN_HOVER;
  } else if (isSelected) {
    bgColor = DROPDOWN_SELECT;
  }
  return bgColor;
};

export const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: 'auto',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: MODERATE_DARK_GREY,
    padding: '0.25rem 0.5rem',
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: WHITE,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: WHITE,
  }),
  option: (provided, { isDisabled, isFocused, isSelected }) => (
    {
      ...provided,
      backgroundColor: getOptionsBgColor({ isDisabled, isFocused, isSelected }),
      cursor: isDisabled ? 'not-allowed' : 'default',
    }
  ),
};

export const selectComponentStyles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& .MuiCollapse-entered': {
      marginBottom: '1rem',
    },
  },
  labelWrapper: {
    display: 'flex',
    flex: 0.25,
    fontWeight: 'bold',
    alignItems: 'flex-end',
  },
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  dropdownOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabledOptionTag: {
    fontWeight: 'bold',
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    backgroundColor: LIGHT_MEDIUM_GREY,
    borderRadius: '10px',
    color: BLACK,
  },
};
