import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@material-ui/core';
import { BLACK, MEDIUM_GREY } from '../../stylesheets/colors';
import ConfigInfoPic from '../../assets/img/configinfo.svg';

const useStyles = makeStyles({
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4rem',
    '& > div': {
      marginBottom: '1rem',
    },
    '& > div.img': {
      marginTop: '2rem',
      marginBottom: '3rem',
    },
  },
  modalHeading: {
    fontSize: '1.5rem',
    color: BLACK,
    fontWeight: 500,
    marginBottom: '1rem',
  },
  modalSubHeading: {
    fontSize: '1.25rem',
    color: MEDIUM_GREY,
  },
  mr2: {
    marginRight: '2rem',
  },
});

export default function ConfigChangeModal({ onClose, onConfirm }) {
  const classes = useStyles();
  return (
    <div className={classes.modalWrapper}>
      <div>
        <span className={classes.modalHeading}>There are unsaved changes</span>
      </div>
      <div>
        <span className={classes.modalSubHeading}>
          You can choose to continue editing or save your changes and proceed.
        </span>
      </div>
      <div className="img">
        <img src={ConfigInfoPic} alt="info" />
      </div>
      <div>
        <Button color="primary" variant="outlined" onClick={onClose} className={classes.mr2}>
          Keep editing
        </Button>
        <Button color="primary" variant="contained" type="button" onClick={onConfirm}>
          Save and proceed
        </Button>
      </div>
    </div>
  );
}

ConfigChangeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
