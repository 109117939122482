import React, { useEffect } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import _unset from 'lodash/unset';
import WarningInfoAlert from '../../common/WarningInfoAlert';

const LtiTmWarning = ({
  scormInputPath, lo, formik, updateCourseErrorStatus, targetLpCode, selected,
}) => {
  const { errors } = formik;
  const {
    sub_type, existing_groups_view_in_lp, standard_pages_in_lp, page_type,
  } = lo;

  const [, ltiMeta, ltiHelpers] = useField({
    name: scormInputPath,
    type: 'checkbox',
  });

  const gwError = `The current Learning plan - ${targetLpCode} already contains a Group View Training Material. 
       Please uncheck the selected Group View Training Material to proceed with the Import.`;

  const journalError = `A Journal Review page cannot be copied without a Journal Standard Page. 
       Please create a Journal with at least one Standard page in this Learning Plan to proceed with the Import.`;

  useEffect(() => {
    if (selected) {
      if (sub_type === 'groupwork' && existing_groups_view_in_lp) {
        ltiHelpers.setError(gwError);
      } else if (sub_type === 'journal' && !standard_pages_in_lp && page_type === 'review') {
        ltiHelpers.setError(journalError);
      }
      updateCourseErrorStatus(errors);
    } else {
      _unset(errors, scormInputPath);
      updateCourseErrorStatus(errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const getWarning = () => {
    if (ltiMeta.error) {
      return (
        <WarningInfoAlert severity="error">
          {ltiMeta.error}
        </WarningInfoAlert>
      );
    }
    return null;
  };

  return (
    selected ? getWarning() : null
  );
};

LtiTmWarning.propTypes = {
  lo: PropTypes.shape({
    sub_type: PropTypes.string.isRequired,
    existing_groups_view_in_lp: PropTypes.bool.isRequired,
    standard_pages_in_lp: PropTypes.bool.isRequired,
    page_type: PropTypes.string,
  }).isRequired,
  formik: PropTypes.shape({
    errors: PropTypes.object.isRequired,
  }).isRequired,
  scormInputPath: PropTypes.string.isRequired,
  updateCourseErrorStatus: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  targetLpCode: PropTypes.string.isRequired,
};

export default LtiTmWarning;
