import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '@material-ui/core';
import SettingValueCell from './SettingValueCell';
import tooltipMessages from './tooltipMessages';
import { reportPageDateTimeFormatter } from '../../helpers/formattingHelpers';

const defaultSettingsValue = {
  additional_resources: '',
  tags: '',
};

const validationSchema = yup.object({
  tags: yup.string().nullable(),
  additional_resources: yup.string().nullable(),
});

const AdditionalInformationView = ({
  settings,
  isEdit,
  onSubmit,
  onEdit,
  onCancel,
}) => {
  const defaultData = settings?.program_id ? settings : defaultSettingsValue;

  const {
    values, handleSubmit, errors, touched, handleChange, setFieldValue, resetForm,
  } = useFormik({
    initialValues: { ...defaultData },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (payload) => {
      onSubmit(payload);
    },
  });

  const cancelEdit = () => {
    resetForm();
    onCancel();
  };

  const createdAt = values.created_at ? `| ${reportPageDateTimeFormatter(new Date(values.created_at))}` : '';
  const updatedAt = values.updated_at ? `| ${reportPageDateTimeFormatter(new Date(values.updated_at))}` : '';

  const createdBy = values.created_by ? `${values.created_by} ${createdAt}` : 'N/A';
  const updatedBy = values.updated_by ? `${values.updated_by} ${updatedAt}` : 'N/A';
  const tags = values?.tags ? values.tags.split(',').map((el) => el.trim()) : [];

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-header">
        <div>
          <span className="form-header-title">Additional Information</span>
        </div>
        <div>
          {!isEdit && (
            <Button color="primary" variant="contained" onClick={() => onEdit()}>
              Edit
            </Button>
          )}
          {isEdit && (
            <>
              <Button color="primary" variant="outlined" onClick={() => cancelEdit()}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Save Changes
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="form-content">
        <div className="form-group">
          <div className="form-row">
            <SettingValueCell label="Created By" value={createdBy} />
            <SettingValueCell
              label="Last Updated By (On Ops Portal)"
              value={updatedBy}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              label="Additional Resources"
              hasHTML={false}
              value={values.additional_resources}
              fieldType="textarea"
              valueType="linkify"
              isEdit={isEdit}
              tooltipMessage={tooltipMessages.ADDITIONAL_FIELDS}
              name="additional_resources"
              handleChange={handleChange}
              hasError={touched.additional_resources && !!errors.additional_resources}
              errorText={touched.additional_resources && errors.additional_resources}
            />

            <SettingValueCell
              name="tags"
              label="Tags"
              value={tags}
              valueType="chip"
              tooltipMessage={tooltipMessages.TAGS}
              isEdit={isEdit}
              fieldType="chipinput"
              hasError={touched.tags && !!errors.tags}
              errorText={touched.tags && errors.tags}
              handleChange={setFieldValue}
            />

          </div>
        </div>
      </div>
    </form>
  );
};

AdditionalInformationView.defaultProps = {
  isEdit: false,
};

AdditionalInformationView.propTypes = {
  settings: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AdditionalInformationView;
