import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import _map from 'lodash/map';
import _find from 'lodash/find';

import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import LabledTextField from '../common/LabledTextField';
import LabledTextArea from '../common/LabeledTextArea';
import AutoMultiSelect from '../common/AutoMultiSelect';
import Loading from '../common/Loading';
import { ERROR_RED, MODERATE_LIGHT_GREY } from '../../stylesheets/colors';
import CustomSelect from '../common/CustomSelect';
import { getInstructor } from '../../containers/WorkshopManagementPage/apis';

const MAX_ENROLLMENTS = 1000000000;

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '0 48px 20px',
    width: '87%',
    margin: 'auto',
  },
  width100: {
    width: '100%',
  },
  modalHeight: {
    minHeight: '400px',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  red: {
    color: ERROR_RED,
  },
  errorBlock: {
    display: 'flex',
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
  rootLabelText: {
    '& > div': {
      borderRadius: 'unset',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  highliteErrorInput: {
    '& > div': {
      border: `1px solid ${ERROR_RED}`,
    },
  },
  input: {
    width: '100%',
    margin: '8px 0',
    '& > div': {
      borderRadius: 'unset',
    },
    '& input': {
      paddingRight: '4.5rem',
      '&:disabled': {
        backgroundColor: MODERATE_LIGHT_GREY,
        opacity: 0.5,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
}));

const validate = (values) => {
  const errors = {};

  if (!values.session_name) {
    errors.session_name = 'This field is required';
  }

  if (!values.max_enrollments) {
    errors.max_enrollments = 'This field is required';
  }

  if (values.max_enrollments) {
    if (values.max_enrollments > MAX_ENROLLMENTS) {
      errors.max_enrollments = 'Enter a lower value';
    }
    if (values.max_enrollments < 1) {
      errors.max_enrollments = 'Please enter valid positive integer value';
    }
  }

  if (values.number_of_events && !Number.isInteger(values.number_of_events)) {
    errors.number_of_events = 'Only integer value is allowed';
  }

  return errors;
};

const sessionCompletionOptions = [
  { label: 'Manual', value: '3' },
  { label: 'Attendance based', value: '2' },
];

const WorkshopManagementCreateSession = ({
  open,
  onClose,
  isLoading,
  onCreateSession,
  onUpdateSession,
  isSessionEdit,
  sessionData,
  alertMessage,
  setAlertMessage,
}) => {
  const classes = useStyles();

  const [selectedPrograms, setSelectedPrograms] = useState([]);
  // const [eventType, setEventType] = useState('all');
  const [formValues, setFormValues] = useState({});
  const selectCompletionRef = useRef(null);

  const formType = isSessionEdit ? 'edit' : 'create';

  const sessionForm = {
    create: {
      heading: 'Add New Session',
      subHeading: 'Include session details for this workshop',
    },
    edit: {
      heading: 'Edit Session Details',
      subHeading: 'View and edit session details for this workshop',
    },
  };

  const prepareFormValues = useCallback(() => {
    const custom_events = Number.isInteger(sessionData?.min_attended_dates_for_completion)
      && sessionData?.min_attended_dates_for_completion > 0;
    const instructorDefaultValues = sessionData?.instructors?.length > 0
      ? _map(sessionData?.instructors, (obj) => ({ label: obj.username, value: obj.user_id }))
      : [];
    setSelectedPrograms(instructorDefaultValues);
    return {
      session_name: sessionData?.name,
      session_description: sessionData?.description || '',
      enrolled_users_count: sessionData?.enrolled_users_count || '',
      max_enrollments: sessionData?.max_enroll,
      number_of_events: custom_events ? sessionData?.min_attended_dates_for_completion : '',
      instructors: _map(instructorDefaultValues, 'value'),
      session_completion: _find(sessionCompletionOptions, {
        value: sessionData?.evaluation_type || '3',
      }),
      event_type: custom_events ? 'custom' : 'all',
    };
  }, [sessionData]);

  useEffect(() => {
    if (isSessionEdit) {
      setFormValues(prepareFormValues());
    } else {
      setFormValues({
        session_name: '',
        session_description: '',
        enrolled_users_count: '',
        max_enrollments: MAX_ENROLLMENTS,
        number_of_events: '',
        instructors: [],
        session_completion: sessionCompletionOptions[0],
        event_type: 'all',
      });
    }
  }, [sessionData, isSessionEdit, prepareFormValues]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validate,
    onSubmit: (values) => {
      const finalValues = {
        session_name: values.session_name,
        session_description: values.session_description,
        enrolled_users_count: values.enrolled_users_count,
        max_enrollments: values.max_enrollments,
        instructors: values.instructors,
        session_completion: Number(values.session_completion.value),
      };

      if (values.session_completion.value === sessionCompletionOptions[1].value) {
        if (values.event_type === 'all') {
          finalValues.number_of_events = null;
        } else {
          finalValues.number_of_events = values.number_of_events;
        }
      }

      if (isSessionEdit) {
        onUpdateSession({ ...finalValues, id: sessionData.session_id });
      } else {
        onCreateSession(finalValues);
      }
    },
  });

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    resetForm,
    errors,
    touched,
  } = formik;

  useEffect(() => {
    setValues(formValues);
  }, [formValues, setValues]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  useEffect(() => {
    if (selectCompletionRef.current && values.session_completion) {
      const setSessionCompletion = selectCompletionRef.current.select?.setValue;
      setSessionCompletion(values.session_completion);
    }
  }, [values.session_completion]);

  const handlePickedPrograms = (items) => {
    const pickedUsers = _map(items, 'value');
    setSelectedPrograms(items);
    setFieldValue('instructors', pickedUsers);
  };

  const getData = async (inputText) => {
    const result = await getInstructor(inputText);
    return result;
  };

  const handleAutoCompleteResult = (result) => {
    const newPrograms = result?.data?.users.map((data) => ({
      value: data.user_id,
      label: data.username,
    }));
    return newPrograms;
  };

  return (
    <ConfirmationModal
      open={open}
      isContainForm
      onClose={onClose}
      onSubmit={handleSubmit}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      modalWidth={85}
      title={(
        <FormattedTypography
          variant="subtitle1"
          body={sessionForm[formType].heading}
          subHeading={sessionForm[formType].subHeading}
          dense
          suffixClassName={classes.bold}
        />
      )}
      content={
        isLoading ? (
          <Loading />
        ) : (
          <Grid container justifyContent="space-between" className={classes.modalWrapper}>
            <Grid item xs={12}>
              <LabledTextField
                label={(
                  <div className={classes.label}>
                    <Typography>Session Name</Typography>
                    <span className={classes.red}>*</span>
                  </div>
                )}
                className={classes.fieldPadding}
                inputProps={{
                  name: 'session_name',
                  classes: {
                    root: `${classes.rootLabelText}${
                      errors.session_name && touched.session_name
                        ? ` ${classes.highliteErrorInput}`
                        : ''
                    }`,
                  },
                }}
                value={values.session_name || ''}
                onChange={(e) => {
                  if (e.target.value.length <= 255) {
                    handleChange(e);
                  }
                }}
              />
              <div className={classes.errorBlock}>
                {errors.session_name && touched.session_name && (
                  <Typography className={classes.red}>{errors.session_name}</Typography>
                )}
                <Typography className={classes.rightText}>
                  {(values.session_name || '').length}
                  /255
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <LabledTextArea
                label="Session Description"
                className={classes.fieldPadding}
                inputProps={{
                  name: 'session_description',
                  classes: { root: classes.rootLabelText },
                }}
                value={values.session_description || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LabledTextField
                disabled
                label="Session Enrollments"
                type="number"
                className={classes.fieldPadding}
                inputProps={{
                  name: 'enrolled_users_count',
                  classes: { root: classes.rootLabelText },
                }}
                value={values.enrolled_users_count || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LabledTextField
                label={(
                  <div className={classes.label}>
                    <Typography>Max Enrollments</Typography>
                    <span className={classes.red}>*</span>
                  </div>
                )}
                type="number"
                className={classes.fieldPadding}
                inputProps={{
                  min: 0,
                  name: 'max_enrollments',
                  classes: {
                    root: `${classes.rootLabelText} ${
                      errors.max_enrollments && touched.max_enrollments
                        ? `${classes.highliteErrorInput}`
                        : ''
                    }`,
                  },
                }}
                value={values.max_enrollments || ''}
                onChange={handleChange}
              />
              <div className={classes.errorBlock}>
                {errors.max_enrollments && touched.max_enrollments && (
                  <Typography className={classes.red}>{errors.max_enrollments}</Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <AutoMultiSelect
                label="Instructor"
                placeholder="Select Instructor"
                selectedItems={selectedPrograms}
                fetchData={getData}
                maxLimit={200}
                limitExceedMsg="Limit reached"
                handleMultiSelect={handleAutoCompleteResult}
                onChange={(value) => handlePickedPrograms(value)}
              />
              <Typography className={classes.rightText}>
                {values?.instructors?.length || 0}
                /200
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                name="session_completion"
                selectRef={selectCompletionRef}
                options={sessionCompletionOptions}
                className={classes.fieldPadding}
                defaultValue={values.session_completion}
                placeholder="Select an option"
                type="select"
                label={(
                  <div className={classes.label}>
                    <Typography>Session Completion</Typography>
                    <span className={classes.red}>*</span>
                  </div>
                )}
                onChange={(e) => setFieldValue('session_completion', e)}
              />
            </Grid>
            {values.session_completion?.value === sessionCompletionOptions[1].value ? (
              <>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="events-radio-buttons"
                      value={values.event_type}
                      onChange={handleChange}
                      name="event_type"
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All Events"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label="Custom Number of Events"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="number_of_events"
                    className={classes.input}
                    variant="outlined"
                    margin="dense"
                    value={values?.number_of_events}
                    type="number"
                    disabled={values.event_type === 'all'}
                    onChange={handleChange}
                  />
                </Grid>
                {errors.number_of_events && touched.number_of_events && (
                  <Typography className={classes.red}>{errors.number_of_events}</Typography>
                )}
              </>
            ) : null}
          </Grid>
        )
      }
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            {isSessionEdit ? 'Update session' : 'Add session'}
          </Button>
        </>
      )}
      alertMessage={alertMessage}
      setAlertMessage={setAlertMessage}
    />
  );
};

export default WorkshopManagementCreateSession;

WorkshopManagementCreateSession.defaultProps = {
  sessionData: {},
};

WorkshopManagementCreateSession.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateSession: PropTypes.func.isRequired,
  onUpdateSession: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSessionEdit: PropTypes.bool.isRequired,
  alertMessage: PropTypes.object.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  sessionData: PropTypes.shape({
    session_id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    enrolled_users_count: PropTypes.number,
    max_enroll: PropTypes.number,
    instructors: PropTypes.array,
    session_completion: PropTypes.number,
    evaluation_type: PropTypes.number,
    min_attended_dates_for_completion: PropTypes.number,
    events: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
