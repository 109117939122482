import './stylesheets/main.scss';
import './stylesheets/fonts.css';
import './stylesheets/mds-bootstrap-theme.min.css';

import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import createSagaMiddleware from 'redux-saga';
import { actions as midActions } from '@cs/mid-react-saga';
import { Mid } from '@mid/sdk';
import {
  makeMidConfig, LoginWrapper, LoginLoading, LoginTenants, LoginWelcome,
} from '@cs/mid-react';
import configureStore from './store/reducers';
import saga from './store/sagas';

import Routing from './routes';
import Config from './config';
import '@cs/mid-react/style.css';

// NOTE: Those components look best when adding MDS styling (see https://mds.mckinsey.com/)
// import '@mds/mds-bootstrap-theme/dist/mck-bootstrap.css';

const history = createBrowserHistory({
  basename: Config.BASE_PATH,
});

async function render() {
  const midConfig = await makeMidConfig({
    apiUrl: Config.GATEWAY_SERVER,
    basePath: Config.BASE_PATH,
    enableCookieAuth: Config.ENABLE_COOKIE_AUTH,
  });

  const mid = new Mid(midConfig);

  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore(history, sagaMiddleware, mid);
  sagaMiddleware.run(saga);

  ReactDOM.render(
    <LoginWrapper
      mid={mid}
      apiUrl={Config.GATEWAY_SERVER}
      loadingNode={LoginLoading}
      tenantSelectorNode={LoginTenants}
      onLogin={() => store.dispatch(midActions.login())}
      loginNode={(props) => <LoginWelcome title="Welcome to Operations Portal" {...props} />}
    >
      <Routing store={store} history={history} />
    </LoginWrapper>,
    document.getElementById('index'),
  );
}

ReactDOM.render(
  <LoginLoading />,
  document.getElementById('index'),
);

render().catch((err) => {
  console.error(err);
  ReactDOM.render(
    <p>
      {' '}
      {err.message}
    </p>, document.getElementById('index'),
  );
});
