import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import MasterDataSidebar from '../../components/MasterData/MasterDataSidebar';
import { VERY_LIGHT_GREY, WHITE } from '../../stylesheets/colors';
import ClientPage from '../../components/MasterData/ClientPage';
import PortfolioPage from '../../components/MasterData/PortfolioPage';
import {
  CLIENT_MASTER, PORTFOLIO_MASTER,
} from '../../constants';
import {
  getPortfolioData, createNewPortfolio, deletePortfolio, updatePortFolio,
  getPortfolioOfferingData,
} from '../../store/actions/portfolioMasterData';
import { getClientData, updateClientCode, addNewClient } from '../../store/actions/clientMasterData';

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: VERY_LIGHT_GREY,
    minHeight: '100vh',
  },
  containerWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  sidebarWrapper: {
    display: 'flex',
    flex: 0.15,
    minWidth: '15rem',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.85,
    margin: '2.5rem',
    background: WHITE,
  },
});

const MasterData = (props) => {
  const {
    match,
    getPortfolioData: getPortfolioMasterData,
    getPortfolioOfferingData: getPortfolioOfferingsList,
    getClientData: getClientMasterData,
    createNewPortfolio: addNewPortfolio,
    deletePortfolio: deletePortfolioData,
    updatePortFolio: updatePortfolioData,
    updateClientCode: updateClientInfo,
    addNewClient: addNewClientData,
    portfolioData,
    clientData,
    isAdmin,
  } = props;
  const classes = useStyles();
  const programType = get(match, 'params.programType');
  const programSubType = get(match, 'params.programSubType');

  const [clientMasterData, setClientMasterData] = useState({});
  const [portfolioOfferingData, setPortfolioOfferingDataData] = useState({});
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
  const [searchClientText, setSearchClientText] = useState('');
  const [searchPortfolioText, setSearchPortfolioText] = useState('');
  const pageSize = 50;
  const pageNum = 1;

  useEffect(() => {
    const data = {
      pageSize,
      pageNum,
      searchValue: '',
    };
    if (programSubType === CLIENT_MASTER) {
      getClientMasterData(data);
    } else if (programSubType === PORTFOLIO_MASTER) {
      getPortfolioMasterData(data);
    }
  }, [getClientMasterData, getPortfolioMasterData, programSubType]);

  useEffect(() => {
    const data = {
      pageSize,
      pageNum,
      searchValue: searchPortfolioText,
    };
    if (portfolioData.isError) {
      setAlertMessage({ type: 'error', message: portfolioData.message });
    } else {
      if (portfolioData.message === 'Portfolio is created') {
        getPortfolioMasterData(data);
        setAlertMessage({ type: 'success', message: 'Offerings has been added successfully.' });
      }
      if (portfolioData.message === 'Portfolio deleted successfully') {
        getPortfolioMasterData(data);
        setAlertMessage({ type: 'success', message: 'Offerings has been deleted successfully.' });
      }
      if (portfolioData.message === 'Portfolio updated') {
        getPortfolioMasterData(data);
        setAlertMessage({ type: 'success', message: 'Offerings has been updated successfully.' });
      }
    }
  }, [getPortfolioMasterData, portfolioData.isError, portfolioData.message, searchPortfolioText]);

  useEffect(() => {
    if (clientData.isError) {
      setAlertMessage({ type: 'error', message: clientData.message });
    } else if (clientData.message === 'Client updated') {
      const data = {
        pageSize,
        pageNum,
        searchValue: searchClientText,
      };
      getClientMasterData(data);
      setAlertMessage({ type: 'success', message: 'Client has been updated successfully.' });
    } else if (clientData.message === 'Client record created') {
      const data = {
        pageSize,
        pageNum,
        searchValue: searchClientText,
      };
      getClientMasterData(data);
      setAlertMessage({ type: 'success', message: 'Client has been added successfully.' });
    }
  }, [getClientMasterData, clientData.message, clientData.isError, searchClientText]);

  useEffect(() => {
    if (programSubType === CLIENT_MASTER) {
      setClientMasterData(clientData);
    } else if (programSubType === PORTFOLIO_MASTER) {
      setPortfolioOfferingDataData(portfolioData);
    }
  }, [clientData, portfolioData, programSubType]);

  const addNewOffering = (data) => {
    addNewPortfolio(data);
  };

  const updateOffering = (data) => {
    updatePortfolioData(data);
  };

  const deleteOffering = (id) => {
    deletePortfolioData(id);
  };

  const createNewClient = (data) => {
    addNewClientData(data);
  };

  const updateClient = (data) => {
    updateClientInfo(data);
  };

  const loadMoreData = useCallback((page, search) => {
    const data = {
      pageSize,
      pageNum: page,
      searchValue: search,
    };
    if (programSubType === CLIENT_MASTER) {
      getClientMasterData(data);
      setSearchClientText(search);
    } else if (programSubType === PORTFOLIO_MASTER) {
      getPortfolioMasterData(data);
      setSearchPortfolioText(search);
    }
  }, [getClientMasterData, getPortfolioMasterData, programSubType]);

  const masterDataAssignment = {
    [CLIENT_MASTER]: ClientPage,
    [PORTFOLIO_MASTER]: PortfolioPage,
  };

  const Page = masterDataAssignment[programSubType];
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.containerWrapper}>
        <Box className={classes.sidebarWrapper}>
          <MasterDataSidebar
            programType={programType}
            programSubType={programSubType}
          />
        </Box>
        <Box className={classes.bodyWrapper}>
          <Page
            clientMasterData={clientMasterData}
            portfolioOfferingData={portfolioOfferingData}
            addNewOffering={addNewOffering}
            deleteOffering={deleteOffering}
            updateOffering={updateOffering}
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            addNewClient={createNewClient}
            updateClient={updateClient}
            loadMoreData={loadMoreData}
            getPortfolioOfferingsList={getPortfolioOfferingsList}
            isAdmin={isAdmin}
          />
        </Box>
      </Box>
    </Box>
  );
};
MasterData.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programType: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  getPortfolioData: PropTypes.func.isRequired,
  getClientData: PropTypes.func.isRequired,
  createNewPortfolio: PropTypes.func.isRequired,
  deletePortfolio: PropTypes.func.isRequired,
  updatePortFolio: PropTypes.func.isRequired,
  portfolioData: PropTypes.object.isRequired,
  clientData: PropTypes.object.isRequired,
  updateClientCode: PropTypes.func.isRequired,
  addNewClient: PropTypes.func.isRequired,
  getPortfolioOfferingData: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  portfolioData: state.portfolioMasterData,
  clientData: state.clientMasterData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPortfolioData,
    getClientData,
    createNewPortfolio,
    deletePortfolio,
    updatePortFolio,
    updateClientCode,
    addNewClient,
    getPortfolioOfferingData,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MasterData);
