import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import _has from 'lodash/has';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import ConfirmationModal from '../common/ConfirmationModal';
import FormattedTypography from '../common/FormattedTypography';
import LabledTextField from '../common/LabledTextField';
import { MODERATE_DARK_GREY, REPORT_ERROR_RED } from '../../stylesheets/colors';
import { saveUserInfo, validateUserInfo } from '../../store/actions/async/platformUserAsync';
import { getErrorMessage } from '../../helpers/apiHelper';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
  },
  width60: {
    width: '60%',
  },
  greyText: {
    color: MODERATE_DARK_GREY,
  },
  modalHeight: {
    minHeight: '30rem',
  },
  errorBlock: {
    marginBottom: '1.2rem',

    '& > span': {
      color: REPORT_ERROR_RED,
    },
  },
  modalStyle: {
    maxWidth: '80rem',
  },
});

const Error = ({ errorText }) => (
  <Typography variant={'h3'} component={'span'}>
    {errorText || 'This field is required'}
  </Typography>
);
Error.propTypes = {
  errorText: PropTypes.string.isRequired,
};

const EditUserModal = ({
  open, onClose, data, loadingText,
  setUserDetailsSuccess, setSnackbar, setAlertBarConfig,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const {
    firstname, lastname, email, userId,
  } = data;

  const validationSchema = Yup.object({
    fName: Yup.string().required('Required field'),
    lName: Yup.string().required('Required field'),
    userEmail: Yup.string().email('Invalid Email').required('Required field'),
  });

  const {
    values, handleSubmit, handleChange, errors, touched, resetForm, setErrors,
  } = useFormik({
    initialValues: { fName: firstname, lName: lastname, userEmail: email },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (payload) => {
      // eslint-disable-next-line no-use-before-define
      onSave(payload);
    },
  });

  const onSave = async (formData) => {
    try {
      setIsLoading(true);
      const { fName, lName, userEmail } = formData;
      const payload = {
        firstname: fName,
        lastname: lName,
        email: userEmail,
      };

      const validateResp = await validateUserInfo(payload, userId);
      if (validateResp?.success) {
        const saveResp = await saveUserInfo(payload, userId);
        if (saveResp?.success) {
          setUserDetailsSuccess(payload);
          onClose();
          setAlertBarConfig({
            labelText: 'Changes saved successfully',
            variant: 'success',
            open: true,
          });
        } else {
          setSnackbar({ open: true, message: getErrorMessage({}, saveResp?.message), severity: 'error' });
        }
      } else if (_has(validateResp, 'error-key')) {
        if (validateResp['error-key'] === 'email') {
          setErrors({ ...errors, userEmail: validateResp?.message });
        }
      } else {
        setSnackbar({ open: true, message: getErrorMessage({}, validateResp?.message), severity: 'error' });
      }
    } catch (err) {
      setSnackbar({ open: true, message: getErrorMessage(err), severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const resetFormBeforeClose = () => {
    resetForm();
    onClose();
  };

  return (
    <ConfirmationModal
      open={open}
      isLoading={isLoading}
      onClose={resetFormBeforeClose}
      breakpoint="md"
      minHeightClass={classes.modalHeight}
      loadingText={loadingText}
      title={(
        <FormattedTypography
          body="Edit User Details"
        />
      )}
      modalStyle={classes.modalStyle}
      dialogContentClass={classes.width60}
      content={(
        <Container className={classes.wrapper}>
          <LabledTextField
            label="Firstname"
            name="fName"
            required
            labelClass={classes.greyText}
            value={values.fName}
            onChange={(e) => { handleChange(e); }}
          />
          <div className={classes.errorBlock}>
            {touched.fName && !!errors.fName
              && <Error errorText={touched.fName && errors.fName} />}
          </div>

          <LabledTextField
            label="Lastname"
            name="lName"
            required
            labelClass={classes.greyText}
            value={values.lName}
            onChange={(e) => { handleChange(e); }}

          />
          <div className={classes.errorBlock}>
            {touched.lName && !!errors.lName
              && <Error errorText={touched.lName && errors.lName} />}
          </div>

          <LabledTextField
            label="Email / Username"
            name="userEmail"
            required
            labelClass={classes.greyText}
            value={values.userEmail}
            onChange={(e) => { handleChange(e); }}
          />
          <div className={classes.errorBlock}>
            {touched.userEmail && !!errors.userEmail
              && <Error errorText={touched.userEmail && errors.userEmail} />}
          </div>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={resetFormBeforeClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </>
      )}
    />
  );
};

EditUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  loadingText: PropTypes.string.isRequired,
  setUserDetailsSuccess: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  setAlertBarConfig: PropTypes.func.isRequired,
};

export default EditUserModal;
