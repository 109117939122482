import React, {
  useEffect, useRef, useState, memo,
} from 'react';
import {
  Button, Grow, ListItem, Menu,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutline from '../../assets/icons/close-outline.svg';
import InstructionIllustration from '../../assets/img/edit-program-timeline.svg';
import {
  DROPPABLE_COLOR, SWITCH_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles({
  flyoutMenuWrapper: {
    width: '30rem',
    paddingBottom: '3rem',
  },
  button: {
    border: `1px solid ${SWITCH_GREY}`,
    backgroundColor: DROPPABLE_COLOR,
    width: '10rem',
    height: '2rem',
    fontSize: '0.875rem',
  },
  buttonIcon: {
    '&.MuiSvgIcon-fontSizeSmall': {
      fontSize: '1rem',
    },
  },
  flyoutTitleImageWrapper: {
    justifyContent: 'center',
    '& span': {
      width: '50%',
      '& img': {
        width: '100%',
      },
    },
  },
  menuSubtitle: {
    whiteSpace: 'normal',
    fontSize: '1rem',
    lineHeight: 1.5,
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  closeIconWrapper: {
    justifyContent: 'flex-end',
    paddingBottom: 0,
    '& span': {
      cursor: 'pointer',
    },
    outline: 'none',
  },
});

const FlyoutMenu = (props) => {
  const {
    buttonTitle,
    subTitle,
    initialState,
    menuAnchorOrigin,
    menuTransformOrigin,
    children,
    topImage,
    classes,
  } = props;
  const styleClasses = useStyles();
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAnchorEl(initialState ? anchorRef.current : null);
  }, [anchorRef, initialState]);

  const open = Boolean(anchorEl);

  const id = open ? 'simple-menu' : undefined;

  return (
    <div
      ref={anchorRef}
    >
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={`${styleClasses.button} ${classes.button}`}
        endIcon={<InfoOutlinedIcon fontSize="small" className={styleClasses.buttonIcon} />}
      >
        {buttonTitle}
      </Button>
      <Menu
        PaperProps={{ className: `${styleClasses.flyoutMenuWrapper} ${classes.root}` }}
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={menuAnchorOrigin}
        transformOrigin={menuTransformOrigin}
        TransitionComponent={Grow}
      >
        <ListItem className={styleClasses.closeIconWrapper}>
          <span onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={-1}>
            <img src={CloseOutline} alt="close-icon" />
          </span>
        </ListItem>

        <ListItem className={`${styleClasses.flyoutTitleImageWrapper} ${classes.image}`}>
          <span>
            <img src={topImage} alt="info-icon" />
          </span>
        </ListItem>
        <ListItem className={`${styleClasses.menuSubtitle} ${classes.subtitle}`}>{subTitle}</ListItem>
        {children}
      </Menu>
    </div>
  );
};

FlyoutMenu.defaultProps = {
  initialState: false,
  menuAnchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  menuTransformOrigin: { vertical: 'top', horizontal: 'right' },
  topImage: InstructionIllustration,
  classes: {
    root: '',
    subtitle: '',
    image: '',
    button: '',
  },
};

FlyoutMenu.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  initialState: PropTypes.bool,
  topImage: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.string,
    button: PropTypes.string,
  }),
  menuAnchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  menuTransformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default memo(FlyoutMenu, (prevProps, nextProps) => (
  prevProps.classes === nextProps.classes
  && prevProps.initialState === nextProps.initialState
  && prevProps.subTitle === nextProps.subTitle
  && prevProps.children === nextProps.children
  && prevProps.topImage === nextProps.topImage
));
